import { IOrgIntegraConfig } from '../../../models/integra/config.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = { organizationId: string };

type TypeResponse = IOrgIntegraConfig;

type TypeError = {};

export const getOrgIntegraConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }) => `/api/as-fields/admin/integra/conf/${organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
