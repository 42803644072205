import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { AgropogodaReportForm } from './components';

const AGROPOGODA_REPORT_MODAL_ID = 'agropogoda-report-modal';

const agropogodaReportModalConfig: TModalConfig = {
  id: AGROPOGODA_REPORT_MODAL_ID,
  name: AGROPOGODA_REPORT_MODAL_ID,
  type: EModalType.Custom,
  title: 'Сформировать отчёт',
  children: AgropogodaReportForm,
  closeOnClickOutside: true,

  styledProps: {
    $modalPaddings: '20px',
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '100%',
  },
};

export { AGROPOGODA_REPORT_MODAL_ID, agropogodaReportModalConfig };
