import { IInvitation } from '../../models/invitation.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  invitationId: string;

  roleId?: string;
  comment?: string;
};

type TypeResponse = IInvitation;

type TypeError = {};

export const updateUserInvite: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-profile/organizations/user/invite/${params.invitationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
