import styled from 'styled-components';

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 36px;
`;

const Styled = {
  TabWrapper,
};

export default Styled;
