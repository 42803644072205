import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import { IOrgIntegraAgrosignalChecklist } from '../../models/orgIntegraAgrosignalChecklist';
import { ORG_INTEGRA_CHECKLIST_MODAL_ID } from '../../modals';

const ChecklistTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );
      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service.deleteChecklistList({ ids: actionIdList }).then(() => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      })
    );

    return Promise.resolve();
  };

  const onOpenModal = () => {
    openModalByModalId(
      ORG_INTEGRA_CHECKLIST_MODAL_ID,
      {
        mode: 'create',
        organizationId,
        checklist: null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraAgrosignalChecklist) => {
    openModalByModalId(
      ORG_INTEGRA_CHECKLIST_MODAL_ID,
      {
        mode: 'update',
        organizationId,
        checklist: value,
      },
      () => {
        // TODO придумать более оптимальный апдейт
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraAgrosignalChecklist> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchChecklistList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraAgrosignalChecklist>
          actionButton={{ text: 'Добавить чек-лист', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID Адаптер',
                propertyName: 'id',
                placeholder: 'Введите ID Адаптера',
              },
              {
                type: 'text',
                label: 'ID “Помощник”',
                propertyName: 'checklistId',
                placeholder: 'Введите ID “Помощник”',
              },
              {
                type: 'text',
                label: 'ID “Агросигнал”',
                propertyName: 'agroChecklistId',
                placeholder: 'Введите ID “Агросигнал”',
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createStartDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createEndDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateDateTimeFrom',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateDateTimeTo',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'checklistId',
              headerName: 'ID “Помощник”',
              flex: 1,
              valueGetter: row => row.row.checklistId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'agrosChecklistId',
              headerName: 'ID “Агросигнал”',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.agrosChecklistId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 200,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 200,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ChecklistTable;
