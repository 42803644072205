import styled from 'styled-components';

import { Colors } from '../../../../constants/colors';

export const AvatarWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  min-width: 144px;
  min-height: 144px;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid #e9e6f0;
  cursor: pointer;

  input {
    display: none;
  }

  ${({ disabled }) =>
    disabled
      ? `
      cursor: default;
      pointer-events: none;    
    `
      : ''}
`;

export const Avatar = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  width: 108px;
  height: 108px;
`;

export const AvatarMockImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AvatarMockImgHovered = styled.img`
  display: none;
  height: 144px;
  width: 144px;
  min-width: 144px;
`;

export const CameraSvgWrapper = styled.div`
  position: absolute;
  left: -1px;
  bottom: -1px;
  z-index: 10;
  width: 40px;
  height: 40px;
`;

export const StyledCameraSvg = styled.img`
  min-width: 40px;
  min-height: 40px;
`;

export const StyledUserAvatarSvg = styled.img``;

export const AvatarMock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${Colors.gray};

  &:hover > ${AvatarMockImg} {
    display: none;
  }

  &:hover > ${AvatarMockImgHovered} {
    display: flex;
  }

  &:hover ${CameraSvgWrapper} {
    display: none;
  }
`;
