import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select } from 'formik-mui';
import { uniq } from 'lodash';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';

import { EAgrosignalFilterIsAuto, IAgrosignalHistoryFilter } from '../../models';
import {
  EImportOperationStatus,
  EImportOperationType,
} from '../../../../../../../../../../api/models/importOperation.model';
import { PAGE_SIZE_LIST, useAgrosignalReportHistory } from '../../../../hooks';

const NOT_SELECTED = 'Не выбрано';

const STATUS_IN_OPTION_LIST: EImportOperationStatus[] = [
  EImportOperationStatus.PENDING,
  EImportOperationStatus.IN_PROGRESS,
  EImportOperationStatus.COMPLETED,
  EImportOperationStatus.ERROR,
];

const TYPE_OPTION_LIST: EImportOperationType | string[] = [
  EImportOperationType.FILES_IMPORT,
  EImportOperationType.FULL_IMPORT,
  EImportOperationType.NOTES_IMPORT,
  NOT_SELECTED,
];

interface IProps {
  clientId: number;
}

const ReportAgrosignalHistory: FC<IProps> = ({ clientId }) => {
  const controller = useAgrosignalReportHistory({ clientId });

  const { fromDate, toDate } = useMemo(() => {
    const currentDate = new Date();

    return {
      fromDate: moment(currentDate).subtract(6, 'd').format('YYYY-MM-DD'),
      toDate: moment(currentDate).format('YYYY-MM-DD'),
    };
  }, []);

  const onSubmit = (data: IAgrosignalHistoryFilter) => {
    controller.setPage(0);
    controller.fetchHistory(data);
  };

  const onReset = () => {
    controller.fetchHistory({
      fromDate,
      toDate,
      statusIn: [],
      type: NOT_SELECTED as EImportOperationType,
      isAuto: EAgrosignalFilterIsAuto.Null,
    });
  };

  return (
    <Box>
      <Formik
        initialValues={{
          fromDate,
          toDate,
          statusIn: [],
          type: NOT_SELECTED as EImportOperationType,
          isAuto: EAgrosignalFilterIsAuto.Null,
        }}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid xs={4} item>
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    id="statusIn"
                    name="statusIn"
                    label="Статус"
                    onChange={() => null}
                    renderValue={() => values.statusIn?.join(', ')}
                    disabled={false}
                  >
                    {STATUS_IN_OPTION_LIST.map(status => (
                      <MenuItem
                        key={status}
                        value={status}
                        onClickCapture={event => {
                          event.stopPropagation();
                          if (values.statusIn.includes(status)) {
                            setFieldValue(
                              'statusIn',
                              uniq(values.statusIn.filter(item => item !== status))
                            );
                          } else {
                            setFieldValue('statusIn', uniq([...values.statusIn, status]));
                          }
                        }}
                      >
                        <Checkbox checked={values?.statusIn.includes(status)} />
                        <ListItemText primary={status} />
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    renderValue={() => values.type}
                    id="type"
                    name="type"
                    label="Тип запроса"
                    disabled={false}
                  >
                    {TYPE_OPTION_LIST.map(type => (
                      <MenuItem key={type} value={type}>
                        <ListItemText primary={type} />
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth sx={{ minWidth: 120 }}>
                  <Field
                    component={Select}
                    renderValue={value => value}
                    id="isAuto"
                    name="isAuto"
                    label="Тип выгрузки"
                    disabled={false}
                  >
                    <MenuItem value={EAgrosignalFilterIsAuto.Auto}>
                      <ListItemText primary={'Авто'} />
                    </MenuItem>
                    <MenuItem value={EAgrosignalFilterIsAuto.NotAuto}>
                      <ListItemText primary={'Не авто'} />
                    </MenuItem>
                    <MenuItem value={EAgrosignalFilterIsAuto.Null}>
                      <ListItemText primary={NOT_SELECTED} />
                    </MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <CalendarComponent
                  label="Дата заказа отчета с"
                  onChange={date => setFieldValue('fromDate', date)}
                  value={(values?.fromDate && moment(values.fromDate).format('DD.MM.YYYY')) ?? ''}
                  datePickerOptions={{ dateRange: { maxDate: moment(values?.toDate).toDate() } }}
                />
              </Grid>
              <Grid xs={4} item>
                <CalendarComponent
                  label="Дата заказа отчета по"
                  onChange={date => setFieldValue('toDate', date)}
                  value={(values?.toDate && moment(values.toDate).format('DD.MM.YYYY')) ?? ''}
                  datePickerOptions={{ dateRange: { minDate: moment(values?.fromDate).toDate() } }}
                />
              </Grid>
              <Grid xs={5} item>
                <Box display={'flex'} flexDirection={'row'} gap={2}>
                  <Button
                    sx={{ maxWidth: '120px' }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Применить
                  </Button>
                  <Button sx={{ maxWidth: '120px' }} color="error" variant="text" type="reset">
                    Сбросить
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Box height={`calc(100vh - 495px)`} marginTop={2}>
        <DataGrid
          columns={controller.gridColumnConfig}
          rows={controller.valueList}
          pagination={true}
          paginationMode={'server'}
          onPageChange={controller.setPage}
          onPageSizeChange={controller.setSize}
          loading={controller.isLoading}
          rowsPerPageOptions={PAGE_SIZE_LIST}
          page={controller.page}
          pageSize={controller.size}
          rowCount={controller.totalElements}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
        />
      </Box>
    </Box>
  );
};

export default ReportAgrosignalHistory;
