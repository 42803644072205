export enum Colors {
  white = '#FFFFFF',
  black = '#151F32',

  green = '#3FB65F',
  darkGreen = '#259A42',
  lightGreen = '#707C94',

  red = '#DF2E22',
  darkRed = '#B70C00',
  lightRed = '#F29993',

  orange = '#FD7E09',
  darkOrange = '#b15806',
  lightOrange = '#fd973a',

  gray = '#9AA0AD',
  darkGray = '#707C94',
  darknessGray = '#E9E6F0',
  lightGray = '#E8E9EB',
  lightnessGray = '#FAF9FD',

  blue = '#0770E3',
  darkBlue = '#044e9e',
  lightBlue = '#E7F1FD',

  yellow = '#FFD765',

  fieldBackground = '#FAF9FD',

  backgroundSecondaryGray = '#F2F1F4',
  labelGray = '#525252',
}
