import { Box, Button, FormControlLabel, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { Field, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { TextField, Switch } from 'formik-mui';
import { useParams } from 'react-router-dom';
import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { OrgIntegraCropwiseController, OrgIntegraCropwiseStore } from '../../../mobx';
import { useStore } from '../../../../../../../../../../shared/utils';
import {
  IOrgIntegraConfigCropwise,
  IUpdateOrgIntegraCropwiseConfig,
} from '../../../../../../../../../../../api/models/integra/config.model';
import { ERequestStatus } from '../../../../../../../../../../shared/types/requests';
import { OrganizationsStore } from '../../../../../../../../../../stores/organizations/organizations.store';
import { getNotificatorProps } from '../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../shared/constanst/notifications';
import { orgIntegraCropwiseOrganizationSchema } from '../schemas';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../modals';

const API_KEY_HIDER = '************';

const OrgIntegraCropwiseOrganization = () => {
  const store = useStore(OrgIntegraCropwiseStore);
  const orgStore = useStore(OrganizationsStore);
  const controller = useStore(OrgIntegraCropwiseController);

  const { organizationId } = useParams<{ organizationId: string }>();
  const { setNotification } = useNotificator();
  const { openModalByModalId } = useModal();

  const onSubmit = (
    data: Partial<IUpdateOrgIntegraCropwiseConfig & IOrgIntegraConfigCropwise>,
    helpers: FormikHelpers<IUpdateOrgIntegraCropwiseConfig & IOrgIntegraConfigCropwise>
  ) => {
    const handler = store.isConfigExists ? controller.updateConfig : controller.createConfig;
    const successText = store.isConfigExists ? 'обновлена' : 'создана';
    const errorText = store.isConfigExists ? 'обновления' : 'создания';

    return handler(data)
      .then(resp => {
        setNotification(
          getNotificatorProps(`Конфигурация успешно ${successText}`, ENotificationStyles.Success)
        );

        if (!store.isConfigExists) return resp;
      })
      .catch(error => {
        setNotification(
          getNotificatorProps(
            `Ошибка ${errorText} конфигурации. ${error?.message}`,
            ENotificationStyles.Error
          )
        );

        // eslint-disable-next-line no-console
        console.log(error);

        return error;
      });
  };

  const onDelete = () => {
    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () => {
      controller
        .deleteConfig(store.orgIntegraConfig.id, organizationId)
        .then(() => {
          setNotification(
            getNotificatorProps('Запись успешно удалена', ENotificationStyles.Success)
          );

          formik.setFieldValue('username', '');
          formik.setFieldValue('password', '');
          formik.setFieldValue('tenant', '');
          formik.setFieldValue('isIntegrated', false);
          formik.setTouched({});
        })
        .catch(() => {
          setNotification(getNotificatorProps('Ошибка удаления записи', ENotificationStyles.Error));
        });
    });
  };

  const formik = useFormik<Partial<IUpdateOrgIntegraCropwiseConfig & IOrgIntegraConfigCropwise>>({
    initialValues: {
      username: '',
      name: '',
      password: '',
      isIntegrated: false,
      organizationId,
      organizationInn: '',
      organizationName: '',
      tenant: '',
    },
    validationSchema: orgIntegraCropwiseOrganizationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (store.configRequestStatus === ERequestStatus.Fulfilled && store.orgIntegraConfig) {
      formik.setValues(store.orgIntegraConfig);

      if (store.isConfigExists) {
        formik.setFieldValue('password', API_KEY_HIDER);
      }
    }
  }, [store.orgIntegraConfig]);

  useEffect(() => {
    if (store.orgIntegraConfig) {
      return;
    }

    formik.setFieldValue('organizationId', orgStore.organization.organizationId ?? null);

    if (orgStore.organization.INN) {
      formik.setFieldValue('organizationInn', orgStore.organization.INN ?? null);
    }

    if (orgStore.organization.name) {
      formik.setFieldValue('organizationName', orgStore.organization.name ?? null);
      formik.setFieldValue('name', orgStore.organization.name ?? null);
    }
  }, [orgStore.organization]);

  const actionButtonText = store.isConfigExists ? 'Сохранить' : 'Создать';

  const handleDirtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    if (!name) return;

    store.addToDirtyList(name);

    formik.setFieldValue(name, name === 'isIntegrated' ? e.target.checked : e.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Box marginTop={2} maxWidth={800}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field
              component={TextField}
              fullWidth
              label="Название организации"
              name="organizationName"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={TextField}
              fullWidth
              label="ИНН организации"
              name="organizationInn"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={<Field component={Switch} type="checkbox" name="isIntegrated" />}
              label="Включить интеграцию"
              labelPlacement="start"
              name="isIntegrated"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              fullWidth
              label="Логин в системе Cropwise"
              name="username"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              fullWidth
              label="Account в системе Cropwise"
              name="tenant"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={8}>
            <Field
              component={TextField}
              fullWidth
              label="Пароль в системе Cropwise"
              name="password"
              onChange={event => {
                if (store.isConfigExists) {
                  store.addToDirtyList(event.target.name);
                  formik.setFieldValue('password', event.target.value.replaceAll('*', ''));
                } else {
                  formik.setFieldValue('password', event.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={formik.submitForm} variant="contained">
              {actionButtonText}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={!store.isConfigExists}
              onClick={onDelete}
              variant="text"
              color="error"
            >
              Удалить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormikProvider>
  );
};

export default observer(OrgIntegraCropwiseOrganization);
