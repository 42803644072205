import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';

export const ButtonsBlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  & button {
    display: block;
    text-align: center;
  }
  & > button:first-child {
    border: 1px solid ${Colors.green};
    background-color: ${Colors.white};
    color: ${Colors.green};
    margin-right: 40px;
  }
`;

export const FileUploader = styled.input`
  display: none;
`;
