import { has } from 'lodash';

import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import {
  IOrganizationIntegraTableFilter,
  IOrganizationIntegraTableFilterGroup,
  IOrganizationIntegraTableFilterItem,
} from '../../../../types/organizationIntegraTable.types';
import OrgIntegraTableFilterStore from '../stores/orgIntegraTableFilter.store';

import OrgIntegraTableFilterBuilderService from './orgIntegraTableFilterBuilder.service';

@provide.transient()
class OrgIntegraTableFilterService {
  @lazyInject(OrgIntegraTableFilterStore)
  protected store: OrgIntegraTableFilterStore;

  @lazyInject(OrgIntegraTableFilterBuilderService)
  protected builder: OrgIntegraTableFilterBuilderService;

  initiateFilter = (
    filter: IOrganizationIntegraTableFilter,
    queryFilters?: Record<string, unknown>
  ) => {
    try {
      filter?.filterCollection.forEach(item => {
        if (has(item, 'itemList')) {
          this.builder.generateGroup(item as IOrganizationIntegraTableFilterGroup);

          (item as IOrganizationIntegraTableFilterGroup).itemList.forEach(_item => {
            this.store.setConfigByPropertyName(_item.propertyName, _item);
          });
          return;
        }

        this.builder.generateItem(item as IOrganizationIntegraTableFilterItem);
        this.store.setConfigByPropertyName(
          (item as IOrganizationIntegraTableFilterItem).propertyName,
          item as IOrganizationIntegraTableFilterItem
        );
      });

      this.combineQueryFiltersWithCreatedFilters(queryFilters);

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  combineQueryFiltersWithCreatedFilters = (queryFilters?: Record<string, unknown>) => {
    if (queryFilters) {
      Object.entries(queryFilters).forEach(([propertyName, value]) => {
        const config = this.store.getConfigByPropertyName(propertyName);
        if (config) {
          if (config.type === 'select' && value) {
            const options = config.optionList as ISelectOptionExtended[];
            const selectedOption = options?.find(option => String(option?.value) === value);

            if (selectedOption) {
              this.store.addComponentValue(propertyName, {
                value: selectedOption,
                type: config.type,
                propertyName,
              });
            }

            return;
          }

          this.store.addComponentValue(propertyName, {
            value,
            type: config.type,
            propertyName,
          });
        }
      });
    }
  };
}

export default OrgIntegraTableFilterService;
