import { CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { memo, useMemo } from 'react';

import { useStore } from '../../../../../../../shared/utils';
import { AdvancedFilterStore } from '../../../../../../../stores';

import { getResultColumns } from './helpers/getResultColumns';

const SearchResult = observer(() => {
  const { fetchedDictionaryList, isLoading } = useStore(AdvancedFilterStore);

  const columns = useMemo(() => getResultColumns(), []);
  const rows = useMemo(() => toJS(fetchedDictionaryList), [fetchedDictionaryList]);

  return (
    // инлайн стили, т.к. styled "схлопывает" таблицу по высоте
    <div style={{ display: 'flex', height: '95%' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ flexGrow: 1 }}>
          <Typography variant="h6">Результаты поиска</Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            localeText={{ noRowsLabel: 'Нет результатов' }}
          />
        </div>
      )}
    </div>
  );
});

export default memo(SearchResult);
