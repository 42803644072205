import _ from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';
import { makeAutoObservable } from 'mobx';

import * as apiRaw from '../../../api/api';

import { provide } from './IoC';

export type ApiNames = keyof typeof apiRaw;
export type TypeApiRequest<ApiName extends ApiNames> = typeof apiRaw[ApiName]['request'];
export type TypeApiResponse<ApiName extends ApiNames> = typeof apiRaw[ApiName]['response'];

export enum EApiErrorTitle {
  'REF_EXISTS' = 'REF_EXISTS',
}

type TGetAxiosUrl = (config: {
  url: string;
  path?: string;
  query?: { [key: string]: any };
  formData?: FormData;
}) => string;

const getAxiosUrl: TGetAxiosUrl = ({ url, path, query }) => {
  if (path) return `${url}/${path}`;

  if (query) {
    const queryString: string = Object.entries(query)
      .map<string>(
        ([key, value]) => `${key}=${typeof value === 'string' ? value : JSON.stringify(value)}`
      )
      .join('&');

    return `${url}?${queryString}`;
  }

  return url;
};

// Technical stuff
export type TypeRequestParams = {
  route: typeof apiRaw[ApiNames];
  config: {
    omit?: Array<Partial<keyof typeof apiRaw[ApiNames]['request']>>;
    path?: string;
    query?: { [key: string]: any };
    formData?: FormData;
  };
  apiName: ApiNames;
  data?: any;
  request: any;
} & TypeApiRequest<ApiNames>;
const technicalFields = ['apiName', 'route', 'config'];

@provide.singleton()
export class Axios {
  constructor() {
    makeAutoObservable(this);
    if (!this.accessToken() || !this.refreshToken()) {
      document.dispatchEvent(new CustomEvent('logout'));
      // return;
    }

    // todo renew делать при 401й + таймер времени протухания
    // this.renewTokens();
  }

  isRenewLoading = true;
  needAdditionalInfo = false;

  setNeedAdditionalInfoMode = () => {
    this.needAdditionalInfo = true;
  };

  renewTokens = async () => {
    let response = null;
    try {
      if (this.refreshToken() && this.accessToken()) {
        response = await this.api.renewAccessAndRefreshToken({
          'refresh-token': this.refreshToken(),
          'access-token': this.accessToken(),
        });
      }
    } catch (e) {
      document.dispatchEvent(new CustomEvent('logout'));
      return;
    }

    if (!response) {
      return;
    }

    localStorage.setItem('refreshToken', response['refresh-token']);
    localStorage.setItem('accessToken', response['access-token']);
  };
  // @ts-ignore
  api: {
    [FnName in ApiNames]: (
      request: TypeApiRequest<FnName>,
      config?: {
        omit?: Array<Partial<keyof TypeApiRequest<FnName>>>;
        query?: { [key: string]: any };
        formData?: FormData;
      }
    ) => Promise<TypeApiResponse<FnName>>;
  } = _.mapValues(apiRaw, (route, apiName) => (params: any, config: any) => {
    if (params.length) {
      return this.request({ route, apiName, config, data: [...params] } as any);
    }
    return this.request({ route, apiName, config, ...params });
  });

  accessToken = () => {
    // const {
    //   location: { search },
    // } = window;
    // const searchParams = new URLSearchParams(search.slice(1));

    // console.log('searchParams:', searchParams, searchParams.get('accessToken'))
    // const accessToken = searchParams.get('accessToken');
    // if (accessToken) {
    //   localStorage.setItem('accessToken', accessToken);
    // }
    return localStorage.getItem('loginAs') || localStorage.getItem('accessToken') || '';
  };
  refreshToken = () => localStorage.getItem('refreshToken') || '';

  sendRequest = (params: TypeRequestParams) => {
    const { route, config } = params;
    const requestParams = _.omit(params, technicalFields);
    const token = this.accessToken();
    // @ts-ignore
    const url = _.isFunction(route.url) ? route.url(requestParams) : route.url;
    const axiosParams: AxiosRequestConfig = {
      url: getAxiosUrl({ url, path: config?.path, query: config?.query }),
      method: route.method,
      headers: Object.assign(
        // TODO: unsafe to use localStorage directly
        {
          'access-token': token,
          'tracking-resolution': `${window.screen.width}x${window.screen.height}`,
          'tracking-user-agent': window.navigator.userAgent,
          'tracking-language': window.navigator.language.slice(0, 2),
        },
        route.headers
      ),
      ...(route.responseType && { responseType: route.responseType }),
    };

    axiosParams[route.method === 'GET' ? 'params' : 'data'] = config?.omit
      ? _.omit(requestParams, config.omit)
      : requestParams.data
      ? requestParams.data
      : requestParams;

    if (route.method === 'POST' || route.method === 'PUT') {
      const requestData = _.isFunction(route.request)
        ? route.request(requestParams)
        : requestParams.data
        ? requestParams.data
        : requestParams;
      const clearedRequestData = config?.omit ? _.omit(requestData, config?.omit) : requestData;

      axiosParams.data = clearedRequestData;

      if (config?.formData) {
        axiosParams.data = config.formData;
      }
    }

    if (route.method === 'PUT' && requestParams.data) {
      axiosParams.data = requestParams.data;
    }

    const axiosClient = axios.create();

    return axiosClient(axiosParams).then(response => response.data);
  };

  canRenew = true;

  request = (params: TypeRequestParams) => {
    return Promise.resolve()
      .then(() => this.sendRequest(params))
      .catch(error => {
        // TODO: make appropriate handler

        if (error.response) {
          // Request was made but server responded with something
          // other than 2xx
          console.error('Status:', error.response.status);
          console.error('Data:', error.response.data);
          console.error('Headers:', error.response.headers);
          if (
            !this.needAdditionalInfo &&
            error.response.headers['x-need-additional-info'] === 'true'
          ) {
            this.setNeedAdditionalInfoMode();
          }
        } else {
          // Something else happened while setting up the request
          // triggered the error
          console.error('Error Message:', error.message);
        }

        const isRenewMethod = params.route.url === '/api/da-profile/users/v2/renew';

        if (error?.response?.status === 401 && !isRenewMethod && this.canRenew) {
          this.renewTokens();
        } else {
          throw error;
        }
      });
  };
}
