import { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { EOrgIntegraCropwiseRoutes, EOrgIntegraCropwiseTabs } from '../routers';

const useCurrentTab = () => {
  const [currentTab, setCurrentTab] = useState<EOrgIntegraCropwiseTabs>(
    EOrgIntegraCropwiseTabs.Organization
  );

  const location = useLocation();

  const getPath = (route: EOrgIntegraCropwiseRoutes) => {
    return `/organizations/:organizationId/integra/cropwise/${route}`;
  };

  const isOrganizationRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Organization));
  const isEmployeesRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Employees));
  const isFieldsRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Fields));
  const isChecklistsRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Checklists));
  const isChecklistInstancesRoute = useRouteMatch(
    getPath(EOrgIntegraCropwiseRoutes.ChecklistInstances)
  );
  const isMessageErrorRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.MessagesError));
  const isFileErrorRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.FilesError));
  const isCulturesRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Cultures));
  const isMeasurementsRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Measurements));
  const isPhotosRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Photos));
  const isAttributesRoute = useRouteMatch(getPath(EOrgIntegraCropwiseRoutes.Attributes));

  useEffect(() => {
    if (isEmployeesRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Employees);
    } else if (isFieldsRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Fields);
    } else if (isChecklistsRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Checklists);
    } else if (isChecklistInstancesRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.ChecklistInstances);
    } else if (isMessageErrorRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.MessagesError);
    } else if (isFileErrorRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.FilesError);
    } else if (isCulturesRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Cultures);
    } else if (isMeasurementsRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Measurements);
    } else if (isPhotosRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Photos);
    } else if (isAttributesRoute) {
      setCurrentTab(EOrgIntegraCropwiseTabs.Attributes);
    } else {
      setCurrentTab(EOrgIntegraCropwiseTabs.Organization);
    }
  }, [
    location,
    isOrganizationRoute,
    isEmployeesRoute,
    isFieldsRoute,
    isChecklistsRoute,
    isChecklistInstancesRoute,
    isMessageErrorRoute,
    isFileErrorRoute,
  ]);

  return { currentTab, setCurrentTab };
};

export { useCurrentTab };
