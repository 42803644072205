import { generatePath, useHistory, useParams } from 'react-router-dom';

import { AdminRoutes } from '../../../../../routes';

const useOrgIntegraNavigation = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();

  const navigateToIntegrations = () => {
    history.push(generatePath(AdminRoutes.OrganizationIntegrations, { organizationId }));
  };

  const navigateToAgrosignal = () => {
    history.push(generatePath(AdminRoutes.OrganizationIntegrationsAgrosignal, { organizationId }));
  };

  const navigateToCropwise = () => {
    history.push(generatePath(AdminRoutes.OrganizationIntegrationsCropwise, { organizationId }));
  };

  return { navigateToIntegrations, navigateToAgrosignal, navigateToCropwise };
};

export default useOrgIntegraNavigation;
