import { createContext } from 'react';

import { ISelectOptionExtended } from '../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../api/models/checklist.model';
import { IAttribute } from '../../../../../../../../api/models/checklist.attribute.model';

const FormulaContext = createContext<{
  checklistAttributeList: ISelectOptionExtended<IChecklistAttribute>[];
  checklistAttribute: IChecklistAttribute;
  attribute: IAttribute;
}>(null);

export default FormulaContext;
