import { Colors } from '@farmlink/farmik-ui';
import { AccordionSummary as MAccordionSummary, Accordion as MAccordion } from '@mui/material';
import styled from 'styled-components';

import { EODZCriteria } from '../../models';
import { COLOR_SCHEME } from '../../constants/colorScheme';

const AccordionSummary = styled(MAccordionSummary)``;

const Accordion = styled(MAccordion)<{ $isError?: boolean }>`
  box-shadow: none !important;

  & > div {
    opacity: 1 !important;
  }

  background-color: ${({ $isError }) => ($isError ? '#f5f5f5' : Colors.lightGreen)} !important;
`;

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  max-height: 200px;
`;

const Item = styled.li<{ type: EODZCriteria }>`
  cursor: pointer;

  ::before {
    content: '•';
    color: ${({ type }) => COLOR_SCHEME[type].border};
    padding-right: 8px;
  }

  & > span {
    font-weight: 400;
  }

  :hover {
    & > span {
      font-weight: 600;
    }
  }
`;

const Styled = {
  AccordionSummary,
  Accordion,
  Item,
  List,
};

export default Styled;
