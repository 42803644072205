import { TypeProvider } from '../../models/provider.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;
};

type TypeResponse = { content: TypeProvider[] } & TypeResponsePageable;

type TypeError = {};

export const getProvidersList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-quotes/refs/provider/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
