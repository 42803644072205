import { makeAutoObservable } from 'mobx';
import { v4 } from 'uuid';

import { IChecklistAttribute } from '../../../../../../../../../api/models/checklist.model';
import { provide } from '../../../../../../../../shared/utils';
import {
  EFormulaDependencyType,
  EFormulaFunctionAggregation,
  EFormulaTypes,
  IAggregationFormulaType,
  IDependencyFormulaType,
  TFormulaTypeUnion,
} from '../../../models';
import { formulaFunctionScheme } from '../../../helpers';
import { IFormulaError } from '../../../models/FormulaError.types';

@provide.singleton()
class FormulasStore {
  private _checklistAttribute: IChecklistAttribute = null;

  private _formulaCollection: Map<EFormulaTypes, TFormulaTypeUnion> = new Map();
  private _formulaDepCollection: Map<string, TFormulaTypeUnion> = new Map();

  private _serializedFormula: IAggregationFormulaType = null;
  private _serializedDepFormulaCollection: Map<string, TFormulaTypeUnion> = new Map();

  private _useAttributeDepType: Map<EFormulaDependencyType, number> = new Map();

  private _errorLog: Map<string, IFormulaError> = new Map();

  private _activeEditor: EFormulaTypes = null;

  private _isAttributeLoading = false;
  private _isChanged = false;

  constructor() {
    makeAutoObservable(this);
  }

  get checklistAttribute() {
    return this._checklistAttribute;
  }

  get isAttributeLoading() {
    return this._isAttributeLoading;
  }

  get formulaCollection() {
    return this._formulaCollection;
  }

  get formulaFunctionScheme() {
    return formulaFunctionScheme;
  }

  get serializedFormula() {
    return this._serializedFormula;
  }
  get serializedDepFormulaCollection() {
    return this._serializedDepFormulaCollection;
  }

  get errorLog() {
    return Array.from(this._errorLog.values());
  }

  get isChanged() {
    return this._isChanged;
  }

  get activeEditor() {
    return this._activeEditor;
  }

  get formulaDepCollection() {
    return Array.from(this._formulaDepCollection.entries());
  }

  get useAttributeDepType() {
    return this._useAttributeDepType;
  }

  getFormulaCollectionItem = (key: EFormulaTypes) => {
    return this._formulaCollection.get(key);
  };

  getFormulaDepCollectionItem = (key: string) => {
    return this._formulaDepCollection.get(key);
  };

  getFormulaFunctionScheme = (key: EFormulaFunctionAggregation) => {
    return formulaFunctionScheme.get(key);
  };

  getErrorBySource = (id: string) => {
    return this._errorLog.get(id);
  };

  setChecklistAttribute = (checklistAttribute: IChecklistAttribute) => {
    this._checklistAttribute = checklistAttribute;
  };

  setFormulaCollection = (key: EFormulaTypes, formula: TFormulaTypeUnion) => {
    this._formulaCollection.set(key, formula);
  };

  setFormulaDepCollection = (id: string, formula: TFormulaTypeUnion) => {
    this._formulaDepCollection.set(id, formula);
  };

  setIsAttributeLoading = (state: boolean) => {
    this._isAttributeLoading = state;
  };

  setSerializedFormula = (data: IAggregationFormulaType) => {
    this._serializedFormula = data;
  };

  setSerializedDepFormulaCollection = (data: IAggregationFormulaType) => {
    this._serializedDepFormulaCollection.set(data.clientId, data);

    this.calculateAttributeDepTypeMap();
  };

  calculateAttributeDepTypeMap = () => {
    const usedDeps = new Map<EFormulaDependencyType, number>();

    Array.from(this.serializedDepFormulaCollection.values()).forEach(
      (tuple: IDependencyFormulaType) =>
        tuple?.dependencyType &&
        usedDeps.set(tuple?.dependencyType, (usedDeps.get(tuple?.dependencyType) || 0) + 1)
    );

    this._useAttributeDepType = usedDeps;
  };

  addItemToErrorLog = (id: string, error: IFormulaError) => {
    this._errorLog.set(id, error);
  };

  removeFormulaItem = (key: EFormulaTypes) => {
    this._formulaCollection.delete(key);
  };

  removeFormulaDepItem = (key: string) => {
    this._formulaDepCollection.delete(key);
  };

  removeItemFromErrorLog = (id: string) => {
    this._errorLog.delete(id);
  };

  setIsChanged = (state: boolean) => {
    this._isChanged = state;
  };

  setActiveEditor = (key: EFormulaTypes) => {
    this._activeEditor = key;
  };

  removeSerializedDepFormulaCollection = (id: string) => {
    this._serializedDepFormulaCollection.delete(id);
  };

  clearFormulaCollection = () => {
    this._formulaCollection = new Map();
  };

  clearFormulaDepCollection = () => {
    this._formulaDepCollection = new Map();
  };

  clearChecklistAttribute = () => {
    this._checklistAttribute = null;
  };

  clearSerializedDepFormulaCollection = () => {
    this._serializedDepFormulaCollection = new Map();
  };

  clearIsAttributeLoading = () => {
    this._isAttributeLoading = false;
  };

  clearErrorLog = () => {
    this._errorLog = new Map();
  };

  clearIsChanged = () => {
    this._isChanged = false;
  };

  clearActiveEditor = () => {
    this._activeEditor = null;
  };

  clearUseAttributeDepType = () => {
    this._useAttributeDepType = new Map();
  };

  clearStore = () => {
    this.clearChecklistAttribute();
    this.clearFormulaDepCollection();
    this.clearFormulaCollection();
    this.clearIsAttributeLoading();
    this.clearSerializedDepFormulaCollection();
    this.clearUseAttributeDepType();
    this.clearErrorLog();
    this.clearIsChanged();
    this.clearActiveEditor();
  };
}

export default FormulasStore;
