type AnyObject = { [key: string]: any };

const collectDeepObjectKeys = (
  obj: AnyObject,
  prefix = '',
  keySet: Set<string> = new Set(),
  delimiter = '.'
): Set<string> => {
  if (typeof obj !== 'object' || obj === null) {
    return keySet;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const fullKey = prefix ? `${prefix}${delimiter}${key}` : key;
      keySet.add(fullKey);
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        collectDeepObjectKeys(obj[key], fullKey, keySet, delimiter);
      }
    }
  }

  return keySet;
};

export default collectDeepObjectKeys;
