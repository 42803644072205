import { makeAutoObservable } from 'mobx';

import { TDictionaryItem } from '../../../api/models/dictionaries.model';
import { IAdvancedFilter } from '../../../types/advancedFilter.types';
import { provide } from '../../shared/utils';

@provide.singleton()
export class AdvancedFilterStore {
  private _advancedFilterValues: IAdvancedFilter = null;
  private _fetchedDictionaryList: TDictionaryItem[] = null;
  private _isFilterActive = false;
  private _isFetched = false;
  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get advancedFilterValues() {
    return this._advancedFilterValues;
  }

  get fetchedDictionaryList() {
    return this._fetchedDictionaryList;
  }

  get isFilterActive() {
    return this._isFilterActive;
  }

  get isFetched() {
    return this._isFetched;
  }

  get isLoading() {
    return this._isLoading;
  }

  getAdvancedFilterValue = (property: keyof IAdvancedFilter) => {
    return this._advancedFilterValues[property] || null;
  };

  setAdvancedFilterValues = (filterValues: IAdvancedFilter) => {
    this._advancedFilterValues = filterValues;
  };

  setFetchedDictionaryList = (data: TDictionaryItem[]) => {
    this._fetchedDictionaryList = data;
  };

  setFilterActivity = (flag: boolean) => {
    this._isFilterActive = flag;
  };

  setFetchStatusResult = (flag: boolean) => {
    this._isFetched = flag;
  };

  setIsLoading = (flag: boolean) => {
    this._isLoading = flag;
  };

  clearAdvancedFilterValue = (property: keyof IAdvancedFilter) => {
    const changedAdvancedFilterValues = { ...this._advancedFilterValues };
    delete changedAdvancedFilterValues[property];

    this._advancedFilterValues = changedAdvancedFilterValues;
  };

  clearAdvancedFilters = () => {
    this._advancedFilterValues = null;
  };

  clearFetchedDictionaryList = () => {
    this._advancedFilterValues = null;
  };
}
