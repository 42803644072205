import { TypeApiRoute } from '../../models/type.api.request';

import { TDictionaryItemCrumbs } from './../../models/dictionaries.model';

type TypeRequest = {
  remoteName: string;
  id: string;
};

type TypeResponse = TDictionaryItemCrumbs;

export const getDictionaryItemBreadcrumbs: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/dictionary/${params.remoteName}/breadcrumbs/${params.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
