import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';
import { observer } from 'mobx-react';

import {
  EChecklistType,
  IChecklistSettingsEntity,
  IChecklistSettingsEntityShort,
} from '../../../../../../../../../../../api/models/checklist.model';
import { Colors } from '../../../../../../../../../../../constants/colors';
import { ChecklistCRUDController } from '../../../../../../../../../../controllers/checklist.CRUD.controller';
import { useStore } from '../../../../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../../../../routes';
import Settings from '../../../../Settings/Settings';
import { ChecklistCRUDStore } from '../../../../../../../../../../stores/checkLists/checklist.CRUD.store';
import { getNotificatorProps } from '../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../shared/constanst/notifications';

interface IRequestResult {
  success: boolean;
  message: string;
  checklistId?: string;
}

type TCopyFormState = Partial<
  IChecklistSettingsEntityShort &
    IChecklistSettingsEntity & {
      operationTypeName: string;
      cultureName: string;
      phenophaseName: string;
      type: EChecklistType;
    }
>;

const TYPE_OPTION_LIST: ISelectOption[] = [
  { value: EChecklistType.Field, label: 'Поле' },
  { value: EChecklistType.Machinery, label: 'Техника' },
  { value: EChecklistType.Point, label: 'Точка' },
];

const TYPE_NESTED_OPTION_LIST: ISelectOption[] = [
  { value: EChecklistType.Nested, label: 'Вложенный' },
];

const CopyChecklistModal = () => {
  const [copyItem, setCopyItem] = useState<TCopyFormState>(null);
  const [selectedType, setSelectedType] = useState<ISelectOption>(null);
  const [result, setResult] = useState<IRequestResult>(null);

  const { currentChecklist } = useStore(ChecklistCRUDStore);
  const { copyChecklist } = useStore(ChecklistCRUDController);

  const history = useHistory();
  const { closeModal } = useModal();
  const { setNotification } = useNotificator();

  const checklistType = useMemo(() => currentChecklist?.type, [currentChecklist?.type]);
  const isNested = useMemo(() => {
    const _isNested = checklistType === EChecklistType.Nested;

    if (_isNested) {
      setSelectedType(TYPE_NESTED_OPTION_LIST[0]);
    }

    return _isNested;
  }, [checklistType]);
  const isCopyDisabled = isNested ? false : !copyItem || !selectedType;

  const dataGridColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'operationTypeId', headerName: 'ID Тех. операции', flex: 1 },
      { field: 'operationTypeName', headerName: 'Наименование Тех. операции', flex: 1 },
      { field: 'cultureId', headerName: 'ID Культуры', flex: 1 },
      { field: 'cultureName', headerName: 'Наименование Культуры', flex: 1 },
      { field: 'phenophaseId', headerName: 'ID Фенофазы', flex: 1 },
      { field: 'phenophaseName', headerName: 'Наименование Фенофазы', flex: 1 },
    ],
    []
  );

  const handleSettingsChange = useCallback(
    (value: Partial<IChecklistSettingsEntityShort & IChecklistSettingsEntity>) => {
      setCopyItem({
        ...value,
        id: value.operationType.id,
        operationTypeName: value.operationType.name,
        cultureName: value.culture.name,
        phenophaseName: value.phenophase.name,
      });
    },
    []
  );

  const copyChecklistHandler = useCallback(() => {
    const payload = { ...copyItem, type: selectedType.value };

    if (payload?.phenophaseId === '' || !payload?.phenophase) {
      delete payload?.phenophaseId;
      delete payload?.phenophase;
    }

    copyChecklist(payload as any)
      .then(data => {
        setResult({ success: true, message: 'Чек-лист успешно скопирован', checklistId: data.id });
        setNotification(
          getNotificatorProps('Чек-лист успешно скопирован', ENotificationStyles.Success)
        );
      })
      .catch(error => {
        const isExists =
          error?.response?.data?.errors?.findIndex(err => err.title === 'REF_EXISTS') >= 0;

        if (isExists) {
          setNotification(
            getNotificatorProps('Такой чек-лист уже существует', ENotificationStyles.Error)
          );
          return setResult({ success: false, message: 'Такой чек-лист уже существует' });
        }

        setNotification(
          getNotificatorProps('Ошибка копирования чек-листа', ENotificationStyles.Error)
        );
        return setResult({ success: false, message: 'Ошибка копирования чек-листа' });
      });
  }, [copyItem, selectedType]);

  const openCopiedChecklist = useCallback((id: string) => {
    closeModal();

    const win = window.open(
      generatePath(AdminRoutes.SelectedChecklist, { checklistId: id }),
      '_blank'
    );
    win.focus();
  }, []);

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" gap="8px">
        <Button disabled={isCopyDisabled} onClick={copyChecklistHandler} variant="contained">
          Сохранить
        </Button>
        <Button onClick={closeModal} variant="text">
          Отменить
        </Button>
      </Box>
      <Box margin="12px 0">
        {!isNested && <Settings onChange={handleSettingsChange} hideTable />}

        <Autocomplete
          sx={{ maxWidth: 300, marginTop: 2 }}
          options={isNested ? TYPE_NESTED_OPTION_LIST : TYPE_OPTION_LIST}
          value={selectedType}
          onChange={(event, value) => {
            if (typeof value === 'object') {
              setSelectedType(value);
            }
          }}
          renderInput={params => <TextField {...params} label="Тип чек-листа" />}
          disablePortal
          disableClearable
        />
      </Box>

      <div style={{ display: 'flex', height: '95%', minHeight: '120px' }}>
        {!isNested && (
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={copyItem ? [copyItem] : []}
              columns={dataGridColumns}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              localeText={{ noRowsLabel: 'Нет результатов' }}
            />
          </div>
        )}
      </div>

      {result?.success && (
        <Button onClick={() => openCopiedChecklist(result?.checklistId)}>
          Открыть скопированный чек-лист
        </Button>
      )}
    </Box>
  );
};

export default observer(CopyChecklistModal);
