import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import {
  EOrganizationStatus,
  IOrganizationForUserDto,
} from '../../../../../../../api/models/organization.model';
import { EEmployeeStatus } from '../../../../../../../api/models/employee.model';
import { OrganizationNameCell } from '../components';
import { TooltipedValue } from '../../../../../modules/checklists/containers/ChecklistContainer/components/Settings/components';

export enum ETextColorScheme {
  ColorWarning = 'color-warning',
  ColorPrimary = 'color-primary',
}

export const getGridColumns = (): GridColDef<IOrganizationForUserDto>[] => [
  {
    field: 'name',
    type: 'string',
    headerName: 'Организация',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: params => (
      <OrganizationNameCell value={params?.value} orgId={params.row.organizationId} />
    ),
  },

  {
    field: 'organizationStatus',
    valueGetter: params => params?.row?.organizationStatus?.statusName ?? '-',
    type: 'string',
    headerName: 'Статус организации',
    width: 150,
    sortable: true,
    cellClassName: data =>
      (data?.row as IOrganizationForUserDto)?.organizationStatus?.statusId ===
      EOrganizationStatus.ARCHIVED
        ? ETextColorScheme.ColorWarning
        : ETextColorScheme.ColorPrimary,
  },

  {
    field: 'role',
    type: 'string',
    renderCell: params =>
      params?.row?.role?.name ? <TooltipedValue value={params?.row?.role?.name} /> : '-',
    headerName: 'Роль',
    width: 100,
    sortable: false,
  },

  {
    field: 'employeeStatus',
    type: 'string',
    headerName: 'Статус сотрудника',
    width: 150,
    sortable: false,
    cellClassName: data =>
      (data?.row as IOrganizationForUserDto)?.employeeStatus === EEmployeeStatus.FIRED
        ? ETextColorScheme.ColorWarning
        : ETextColorScheme.ColorPrimary,
  },

  {
    field: 'organizationJoinDate',
    valueGetter: params => moment(params?.row?.organizationJoinDate).format('DD.MM.YYYY') ?? '-',
    type: 'string',
    headerName: 'Дата вступления',
    width: 150,
    sortable: false,
  },
];
