import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useMemo } from 'react';

import { useStore } from '../../../../../../../../../shared/utils';
import { FormulasController, FormulasStore } from '../../../../mobx';
import { ModalTitle } from '../../../../../../../../../shared/components';
import { downloadBlobAsFile } from '../../../../../../../../../shared/utils/downloadBlobAsFile';

import Styled from './FormulaPreview.styled';

const FormulaPreview = () => {
  const store = useStore(FormulasStore);
  const controller = useStore(FormulasController);

  const { closeModal } = useModal();

  const data = useMemo(
    () => JSON.stringify(controller.removeClientId(store.serializedFormula), null, 2),
    [store.serializedFormula]
  );

  const onDownload = () => {
    const blob = new Blob([data]);

    downloadBlobAsFile(blob, `formula(${store.serializedFormula?.type}).json`);
  };

  return (
    <Box minHeight={400} width={'100%'}>
      <ModalTitle>Предпросмотр формулы</ModalTitle>
      <Box height={'100%'} display={'flex'} flexDirection={'column'} gap="20px">
        <Box maxHeight={300} overflow={'scroll'}>
          <SyntaxHighlighter language="json" wrapLines>
            {data}
          </SyntaxHighlighter>
        </Box>

        <Styled.Footer
          successButton={{ title: 'Скачать как файл', handler: onDownload }}
          denyButton={{ title: 'Закрыть', handler: closeModal }}
        />
      </Box>
    </Box>
  );
};

export default observer(FormulaPreview);
