import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraAgrosignalChecklistInstance } from '../../models/orgIntegraAgrosignalChecklistInstance';

const ChecklistInstanceTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);

  const fetchData: TFetchHandler<IOrgIntegraAgrosignalChecklistInstance> = (
    page,
    size,
    ...args
  ) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchChecklistInstanceList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      <OrganizationIntegraTable<IOrgIntegraAgrosignalChecklistInstance>
        isDisableSelection
        fetchHandler={fetchData}
        controlledPage={lastPageable?.page}
        controlledSize={lastPageable?.size}
        filter={{
          filterCollection: [
            {
              type: 'text',
              label: 'ID Адаптер',
              propertyName: 'id',
              placeholder: 'Введите ID Адаптера',
            },
            {
              type: 'text',
              label: 'ID “Помощник”',
              propertyName: 'checklistInstanceId',
              placeholder: 'Введите ID “Помощник”',
            },
            {
              type: 'text',
              label: 'ID “Агросигнал”',
              propertyName: 'agroChecklistInstanceId',
              placeholder: 'Введите ID “Агросигнал”',
            },
            {
              isDateGroupConstraints: true,
              itemList: [
                {
                  type: 'date',
                  label: 'Старт создания',
                  propertyName: 'createStartDateTime',
                  placeholder: 'Введите дату создания',
                  isGroupMin: true,
                  format: 'ISO',
                },
                {
                  type: 'date',
                  label: 'Окончание создания',
                  propertyName: 'createEndDateTime',
                  placeholder: 'Введите дату создания',
                  isGroupMax: true,
                  format: 'ISO',
                },
              ],
            },
            {
              isDateGroupConstraints: true,
              itemList: [
                {
                  type: 'date',
                  label: 'Старт обновления',
                  propertyName: 'updateDateTimeFrom',
                  placeholder: 'Введите дату обновления',
                  isGroupMin: true,
                  format: 'ISO',
                },
                {
                  type: 'date',
                  label: 'Окончание обновления',
                  propertyName: 'updateDateTimeTo',
                  placeholder: 'Введите дату обновления',
                  isGroupMax: true,
                  format: 'ISO',
                },
              ],
            },
          ],
        }}
        columnConfig={[
          {
            field: 'checklistInstanceId',
            headerName: 'ID “Помощник”',
            flex: 1,
            valueGetter: row => row.row.checklistInstanceId,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'agrosChecklistInstanceId',
            headerName: 'ID “Агросигнал”',
            flex: 1,
            valueGetter: row => row.row.agrosChecklistInstanceId,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'id',
            headerName: 'ID Адаптер',
            flex: 1,
            valueGetter: row => row.row.id,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'username',
            headerName: 'Автор',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.employee.userName,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'createDateTime',
            headerName: 'Дата и время создания',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.createDateTime,
            renderCell: row => <DateParsedValue value={row.value} />,
          },
          {
            field: 'updateDateTime',
            headerName: 'Дата и время обновления',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.updateDateTime,
            renderCell: row => <DateParsedValue value={row.value} />,
          },
        ]}
      />
    </Box>
  );
};

export default ChecklistInstanceTable;
