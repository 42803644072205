import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios, localizeValidationTitle } from '../../shared/utils';
import {
  TypeOrganization,
  initialOrganizationStatus,
  FormValueError,
} from '../../../api/models/organization.model';

import { FilterStore } from './filter.store';

@provide.singleton()
export class OrganizationsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  organizations: TypeOrganization[] = [];
  organization: TypeOrganization = {} as TypeOrganization;
  statusOptions = initialOrganizationStatus;

  formValuesErrors = {};

  page = 0;
  totalPages = 0;

  private _pageSize = 20;
  private _totalElements = 0;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get pageSize() {
    return this._pageSize;
  }

  get totalElements() {
    return this._totalElements;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setOrganizations = (
    organizations: TypeOrganization[],
    totalPages: number,
    totalElements: number
  ) => {
    runInAction(() => {
      this.organizations = organizations;
      this.totalPages = totalPages;
      this._totalElements = totalElements;
    });
  };

  setOrganization = (organization: TypeOrganization) => {
    runInAction(() => {
      this.organization = organization;
    });
  };

  setFormValueErrors = (error: FormValueError) => {
    runInAction(() => {
      this.formValuesErrors = {
        ...this.formValuesErrors,
        [error.source]: localizeValidationTitle(error.title),
      };
    });
  };

  setFormValuesErrors = (errors: FormValueError[]) => {
    runInAction(() => {
      errors.forEach(({ source, title }) => {
        this.formValuesErrors = {
          ...this.formValuesErrors,
          [source]: localizeValidationTitle(title),
        };
      });
    });
  };

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  setTotalElements = (count: number) => {
    this._totalElements = count;
  };

  clearOrganizations = () => {
    this.setOrganizations([], 0, 0);
  };

  clearOrganization = () => {
    this.setOrganization({} as TypeOrganization);
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };
}
