import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { FORMULA_PREVIEW_MODAL_ID } from '../../modals';
import { useStore } from '../../../../../../../shared/utils';
import { FormulasStore } from '../../mobx';
import { EFormulaTypes } from '../../models';

import Styled from './FormulaSidebar.styles';
import { FormulaErrorLog } from './components';

const FormulaSidebar = () => {
  const store = useStore(FormulasStore);

  const history = useHistory();
  const { openModalByModalId } = useModal();

  return (
    <Styled.Sidebar>
      <Styled.BackButton onClick={() => history.goBack()}>
        <ArrowBackIosIcon fontSize="small" />
        Вернуться назад
      </Styled.BackButton>
      <Styled.Wrapper id="aside-menu-inner">
        <Button
          disabled={store.activeEditor === EFormulaTypes.Dependency}
          onClick={() => {
            openModalByModalId(FORMULA_PREVIEW_MODAL_ID);
          }}
        >
          Предпросмотр
        </Button>

        <FormulaErrorLog errorItemList={store.errorLog} />
      </Styled.Wrapper>
    </Styled.Sidebar>
  );
};

export default observer(FormulaSidebar);
