import { v4 } from 'uuid';
import { Box } from '@mui/material';
import moment, { isMoment } from 'moment';
import { Colors } from '@farmlink/farmik-ui';

import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import {
  IOrganizationIntegraTableFilterGroup,
  IOrganizationIntegraTableFilterItem,
  IOrganizationIntegraTableFilterItemDate,
  IOrganizationIntegraTableFilterItemNumber,
  IOrganizationIntegraTableFilterItemSelect,
  IOrganizationIntegraTableFilterItemText,
} from '../../../../types/organizationIntegraTable.types';
import {
  OrgIntegraTableFilterItemSelect,
  OrgIntegraTableFilterItemText,
  OrgIntegraTableFilterItemDate,
  OrgIntegraTableFilterItemNumber,
} from '../../components';
import OrgIntegraTableFilterStore from '../stores/orgIntegraTableFilter.store';

@provide.transient()
class OrgIntegraTableFilterBuilderService {
  @lazyInject(OrgIntegraTableFilterStore)
  protected store: OrgIntegraTableFilterStore;

  generateItem = (
    item: IOrganizationIntegraTableFilterItem,
    options?: {
      datePair?: IOrganizationIntegraTableFilterItem;
      isPreventAddingToFlatCollection?: boolean;
    }
  ) => {
    switch (item.type) {
      case 'text':
        return this.generateTextItem(item as IOrganizationIntegraTableFilterItemText);
      case 'select':
        return this.generateSelectItem(item as IOrganizationIntegraTableFilterItemSelect);
      case 'date':
        return this.generateDateItem(
          item as IOrganizationIntegraTableFilterItemDate,
          options?.isPreventAddingToFlatCollection
        );
      case 'number':
        return this.generateNumberItem(
          item as IOrganizationIntegraTableFilterItemNumber,
          options?.isPreventAddingToFlatCollection
        );
      default:
        return null;
    }
  };

  generateGroup = (item: IOrganizationIntegraTableFilterGroup) => {
    const uuid = v4();

    const groupCollection = (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        padding={1}
        border={`1px solid ${Colors.borderGray}`}
        borderRadius={3}
      >
        {item.itemList.map(_item =>
          this.generateItem(_item, { isPreventAddingToFlatCollection: true })
        )}
      </Box>
    );

    this.store.addComponentToCollection(uuid, groupCollection);
  };

  generateTextItem = (item: IOrganizationIntegraTableFilterItemText, isGroup?: boolean) => {
    const uuid = item.propertyName;
    const component = <OrgIntegraTableFilterItemText item={item} id={uuid} />;

    if (item.defaultValue) {
      this.store.addComponentValue(uuid, {
        type: 'text',
        value: item.defaultValue,
        propertyName: item.propertyName,
      });
    }

    if (isGroup) return component;

    this.store.addComponentToCollection(uuid, component);

    return component;
  };

  generateSelectItem = (item: IOrganizationIntegraTableFilterItemSelect, isGroup?: boolean) => {
    const uuid = item.propertyName;
    const component = <OrgIntegraTableFilterItemSelect item={item} id={uuid} />;

    if (item.defaultValue) {
      this.store.addComponentValue(uuid, {
        type: 'select',
        value: item.defaultValue,
        propertyName: item.propertyName,
      });
    }

    if (isGroup) return component;

    this.store.addComponentToCollection(uuid, component);

    return component;
  };

  generateDateItem = (
    item: IOrganizationIntegraTableFilterItemDate,
    isGroup?: boolean,
    datePair?: IOrganizationIntegraTableFilterItem[]
  ) => {
    const uuid = item.propertyName;
    const component = <OrgIntegraTableFilterItemDate item={item} id={uuid} />;

    if (item.defaultValue) {
      this.store.addComponentValue(uuid, {
        type: 'date',
        value: isMoment(item.defaultValue) ? item.defaultValue : moment(item.defaultValue),
        propertyName: item.propertyName,
      });
    }

    if (isGroup) return component;

    this.store.addComponentToCollection(uuid, component);

    return component;
  };

  generateNumberItem = (item: IOrganizationIntegraTableFilterItemNumber, isGroup?: boolean) => {
    const uuid = item.propertyName;
    const component = <OrgIntegraTableFilterItemNumber item={item} id={uuid} />;

    if (item.defaultValue) {
      this.store.addComponentValue(uuid, {
        type: 'number',
        value: item.defaultValue,
        propertyName: item.propertyName,
      });
    }

    if (isGroup) return component;

    this.store.addComponentToCollection(uuid, component);

    return component;
  };
}

export default OrgIntegraTableFilterBuilderService;
