import { Button, TextField } from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';

import { TDependencySelectOption } from '../../../../../../../../../../../../../../../../../../api/models/dependendency.model';

export interface IAdditionalFilterItem {
  title: string;
  attribute: string;
}

interface IProps {
  additionalFilters: IAdditionalFilterItem[];
  onFilterSubmit: () => void;
  selectedDictionary: TDependencySelectOption;
  setValue: any;
  filterValues: any;
}

const DependencyFilter: FC<IProps> = ({
  additionalFilters,
  onFilterSubmit,
  selectedDictionary,
  setValue,
  filterValues,
}) => {
  const isDisabled = selectedDictionary === null;

  const handleSubmit = () => {
    onFilterSubmit();
  };

  const handleChange = useCallback<(key: string, event: ChangeEvent<HTMLInputElement>) => void>(
    (key, event) => setValue({ ...filterValues, [key]: event.target.value }),
    [filterValues]
  );

  return (
    <>
      {additionalFilters.map(filterItem => (
        <TextField
          key={filterItem.attribute}
          label={filterItem.title}
          name={filterItem.attribute}
          placeholder={filterItem.title}
          onChange={e => handleChange(filterItem.attribute, e as ChangeEvent<HTMLInputElement>)}
          disabled={isDisabled}
          value={filterValues[filterItem?.attribute] || ''}
        />
      ))}
      <Button
        type="button"
        variant="contained"
        size="small"
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Выбрать
      </Button>
    </>
  );
};

export default DependencyFilter;
