import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, useStore, Axios, getPurePhone } from '../../../../shared/utils';
import { Field } from '../../../../shared/types/Field';
import { SMS_CODE_INCORRECT } from '../../../../shared/constanst/error';
import { AuthorizationStore } from '../../../../stores/authorization/authorization.store';

const RESEND_CODE_SECONDS = 60;

@provide.singleton()
export class SignInViaPhoneStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(AuthorizationStore)
  authStore: AuthorizationStore;

  phone: Field = {
    value: '',
    error: '',
  };

  code: Field = {
    value: '',
    error: '',
  };

  countryCode = '+7';

  operationId = '';

  seconds = RESEND_CODE_SECONDS;

  timer: any;

  isCodeWasValid = false;

  currentState: PhoneSignInState = 'first_step';

  setFieldValue = (fieldName: 'phone' | 'code') => (value: string) => {
    this[fieldName].value = value;

    if (fieldName === 'code') {
      this.code.error = '';
      if (this.code.value.length === 6) {
        this.verifyCode();
      }
    }
  };

  get isAllowedToRequestCode() {
    return getPurePhone(this.phone.value, this.countryCode).length === 10;
  }

  setTimer = () => {
    this.seconds = RESEND_CODE_SECONDS;
    this.timer = setInterval(() => {
      this.seconds -= 1;

      if (this.seconds === 0) clearInterval(this.timer);
    }, 1000);
  };

  sendCodeToPhone = () => {
    this.axios2.api
      .loginByPhone({
        phoneNumber: getPurePhone(this.phone.value, this.countryCode),
        phoneCode: this.countryCode,
      })
      .then(response => {
        runInAction(() => {
          this.operationId = response.operId;
          this.currentState = 'second_step';
          this.setTimer();
        });
      })
      .catch(error => {
        console.error('sendCodeToPhone', error);
      });
  };

  get isVerifyCodeButtonAvailable() {
    return this.code.value.length === 6;
  }

  verifyCode = () => {
    this.axios2.api
      .verifySmsCodeV2({
        code: this.code.value,
        operId: this.operationId,
      })
      .then(response => {
        runInAction(() => {
          this.isCodeWasValid = true;
          localStorage.setItem('accessToken', response['access-token']);
          localStorage.setItem('refreshToken', response['refresh-token']);
          setTimeout(() => this.authStore.checkIsUserLoginned(), 100);
        });
      })
      .catch(() => {
        this.code.error = SMS_CODE_INCORRECT;
      });
  };

  reset = () => {
    this.countryCode = '+7';
    this.phone.value = '';
    this.code.value = '';
    this.isCodeWasValid = false;
    this.currentState = 'first_step';
  };

  setCurrentState = (v: PhoneSignInState) => {
    this.currentState = v;
  };
}

type PhoneSignInState = 'first_step' | 'second_step';
