export interface IOrgIntegraField {
  id: string;
  organization: {
    id: string;
    agroName: string;
    host: string;
    agroId: number;
    organizationId: string;
    organizationName: string;
    organizationInn: string;
    isIntegrated: boolean;
  };
  fieldId: string;
  fieldName: string;
  cropwiseFieldId: number;
  cropwiseFieldName: string;
  createDateTime: string;
  updateDateTime: string;
}

export enum EOrgIntegraCropwiseFieldAutoMapMode {
  Unmap = 'UNMAP',
  Map = 'MAP',
  Remap = 'REMAP',
}
