import { FC } from 'react';

import Styled from './styled';

const FormRow: FC<{ hide?: boolean; $maxWidth?: string }> = ({ children, hide, $maxWidth }) => {
  const isEmpty =
    hide || !children || (Array.isArray(children) && children.filter(Boolean).length === 0);

  return !isEmpty ? <Styled.FormRow $maxWidth={$maxWidth}>{children}</Styled.FormRow> : null;
};

export default FormRow;
