import { useMemo, useCallback } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { generatePath, useHistory } from 'react-router-dom';

import { useStore } from '../../../../../../../shared/utils';
import { DictionariesStore } from '../../../../../../../stores';
import { MainContainer } from '../../../../../../style';
import { TDictionaryName } from '../../../../../../../../api/models/dictionaries.model';
import { AdminRoutes } from '../../../../../../routes';

import Styled from './styled';

export const DictionaryList = observer(() => {
  const { dictionaryNameList, setSelectedDictionaryListRemoteName } = useStore(DictionariesStore);
  const history = useHistory();

  const handleClickOnItem = useCallback((dictionaryName: TDictionaryName) => {
    setSelectedDictionaryListRemoteName(dictionaryName);
    history.push(generatePath(AdminRoutes.DictionaryName, { name: dictionaryName.remoteName }));
  }, []);

  const renderDictionaryList = useMemo(() => {
    return dictionaryNameList?.map(item => (
      <Grid item key={item.remoteName}>
        <Styled.DictionaryNameItem variant="outlined" onClick={() => handleClickOnItem(item)}>
          <Typography fontWeight={700}>{item.title}</Typography>
          <Typography fontStyle="italic" fontSize={12}>
            {item.remoteName}
          </Typography>
        </Styled.DictionaryNameItem>
      </Grid>
    ));
  }, [dictionaryNameList]);

  return (
    <MainContainer>
      <Styled.DictionaryNameGrid>
        <Grid spacing={4} container>
          {renderDictionaryList}
        </Grid>
      </Styled.DictionaryNameGrid>
    </MainContainer>
  );
});
