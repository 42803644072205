import { lazyInject, provide, Axios } from '../shared/utils';
import { TagsStore } from '../stores/tags/tags.store';

@provide.singleton()
export class TagsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(TagsStore)
  protected tagsStore: TagsStore;

  fetchTags = async () => {
    this.tagsStore.setLoading(true);
    try {
      const { content } = await this.axios.api.getTags({ size: 100 });
      this.tagsStore.setTags(content);
    } catch (e) {
      console.log('fetchTags e.message', e.message);
    } finally {
      this.tagsStore.setLoading(false);
      this.tagsStore.setLoaded(true);
    }
  };
}
