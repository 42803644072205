import moment from 'moment';
import _ from 'lodash';
import { v4 } from 'uuid';

import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import {
  EReportConfigDecimalSeparator,
  EReportConfigDelimiter,
  ITaskReportConfig,
} from '../../../../../../../../../../../api/models/reportConfig.model';
import { AssistanceReportService } from '../../../../../../../../../../services/reports/assistance/assistanceReports.service';
import { OrganizationReportAssistanceTaskStore } from '../../stores';

@provide.transient()
class AssistanceTaskReportController {
  @lazyInject(OrganizationReportAssistanceTaskStore)
  store: OrganizationReportAssistanceTaskStore;

  @lazyInject(AssistanceReportService)
  reportService: AssistanceReportService;

  getReportsConfig = async (orgId: string): Promise<ITaskReportConfig | false> => {
    this.store.isLoading = true;

    const response = await this.reportService.getAssistanceTaskReportConfig({
      organizationId: orgId,
    });

    this.store.isLoading = false;

    if (!response) {
      return false;
    }

    response.content.forEach(config => {
      this.store.setConfigById(config.id, { ...config, state: 'update' });
    });

    return response.content[0];
  };

  createReportConfig = async (organizationId: string, config: ITaskReportConfig) => {
    const response = await this.reportService.createAssistanceTaskReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    if (response) {
      this.store.deleteConfigById(config.id);
    }

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<ITaskReportConfig>,
    defaultConfig: Partial<ITaskReportConfig>
  ) => {
    const changes: Partial<ITaskReportConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    const payload = {
      id: configId,
      ...changes,
      ...(changes?.dateFrom && { dateFrom: this.parseDateToString(changes.dateFrom) }),
      ...(changes?.dateTo && { dateTo: this.parseDateToString(changes.dateTo) }),
    };

    const response = await this.reportService.updateAssistanceTaskReportConfigBy(payload);

    return response;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleAssistanceTaskReportAutoUpload(configId, state);

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteAssistanceTaskReportConfigBy({ id: configId });

    this.store.deleteConfigById(configId);

    return response;
  };

  createNewConfigPlaceholder = () => {
    const id = v4();

    this.store.setConfigById(id, {
      seasons: [],
      delimiter: EReportConfigDelimiter.SEMICOLON,
      decimalSeparator: EReportConfigDecimalSeparator.COMMA,
      withId: true,
      reportsLocation: '',
      filesLocation: '',
      dateFrom: null,
      dateTo: null,
      enabled: false,
      state: 'create',
      id,
    });
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: ITaskReportConfig
  ): TypeApiRequest<'createAssistanceTaskReportsConfig'> => {
    const fromDate = config?.fromDate || config?.dateFrom || null;
    const toDate = config?.toDate || config?.dateTo || null;

    const output: TypeApiRequest<'createAssistanceTaskReportsConfig'> = {
      organizationId,
      ...config,
      ...(fromDate && {
        fromDate: this.parseDateToString(fromDate),
        dateFrom: this.parseDateToString(fromDate),
      }),
      ...(toDate && {
        toDate: this.parseDateToString(toDate),
        dateTo: this.parseDateToString(toDate),
      }),
    };

    delete output.id;

    return output;
  };

  private parseDateToString = (date: string): string => moment(date).format('YYYY-MM-DD');
}

export default AssistanceTaskReportController;
