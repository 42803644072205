import { AccordionDetails, Button, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Colors, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { IODZError } from '../../models';
import { FORMULA_ODZ_PREVIEW_MODAL_ID } from '../../modals';

import Styled from './FormulaErrorLog.styles';

interface IProps {
  errorCollection: Map<string, IODZError>;
}

const FormulaErrorLog: FC<IProps> = ({ errorCollection }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { openModalByModalId } = useModal();

  const errorItemList = useMemo(() => Array.from(errorCollection.values()), [
    toJS(errorCollection),
  ]);

  const errorCount = useMemo(() => errorItemList?.length, [errorItemList]);
  const hasErrors = errorCount > 0;

  useEffect(() => {
    setIsExpanded(errorCount > 0);
  }, [errorCount]);

  const onErrorItemClick = useCallback((error: IODZError) => {
    const elem = error?.ref?.current || document.getElementById(`${error.HTMLElementId}`);

    if (elem) {
      elem?.scrollIntoView({ behavior: 'smooth' });
      elem?.classList.add('highlight');

      setTimeout(() => {
        elem.classList.remove('highlight');
      }, 3000);
    }
  }, []);

  return (
    <>
      <Button fullWidth onClick={() => openModalByModalId(FORMULA_ODZ_PREVIEW_MODAL_ID)}>
        Предпросмотр
      </Button>
      <Styled.Accordion
        $isError={hasErrors}
        disabled={!hasErrors}
        expanded={isExpanded}
        onChange={(e, value) => {
          setIsExpanded(value);
        }}
      >
        <Styled.AccordionSummary>
          {hasErrors ? (
            <Typography variant="body2" color={Colors.pink}>
              Найдено ошибок: {errorCount > 9 ? '9+' : errorCount}
            </Typography>
          ) : (
            <Typography variant="body2" color={Colors.green}>
              Ошибок не найдено
            </Typography>
          )}
        </Styled.AccordionSummary>
        <AccordionDetails>
          <Styled.List>
            {errorItemList?.map((item, i) => (
              <Styled.Item type={item.criteria} key={i} onClick={() => onErrorItemClick(item)}>
                {<Typography variant="caption">{item.text}</Typography>}
              </Styled.Item>
            ))}
          </Styled.List>
        </AccordionDetails>
      </Styled.Accordion>
    </>
  );
};

export default observer(FormulaErrorLog);
