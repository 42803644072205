import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { ITaskReportConfig } from '../../../../../../../../../api/models/reportConfig.model';
import { useStore } from '../../../../../../../../shared/utils';
import { AssistanceTaskReportController } from '../mobx';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';
import { REMOVE_AGROSIGNAL_CONFIG_MODAL_ID } from '../../../modals';

const useFormActions = ({
  defaultConfig,
  organizationId,
}: {
  defaultConfig?: Partial<ITaskReportConfig>;
  organizationId?: string;
}) => {
  const controller = useStore(AssistanceTaskReportController);

  const { setNotification } = useNotificator();
  const { openModalByModalId } = useModal();

  const fetchConfig = () => {
    controller.getReportsConfig(organizationId);
  };

  const submitConfig = (data: ITaskReportConfig) => {
    if (data.state === 'update') {
      controller
        .updateReportConfig(data.id, data, defaultConfig)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно обновлена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch((error: Error) => {
          setNotification(
            getNotificatorProps(
              `Ошибка обновления конфигурации${error.message ? `. ${error.message}` : ''}`,
              ENotificationStyles.Error
            )
          );
        });
    } else {
      controller
        .createReportConfig(organizationId, data)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно создана', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка создания конфигурации', ENotificationStyles.Error)
          );
        });
    }
  };

  const deleteConfig = () => {
    openModalByModalId(REMOVE_AGROSIGNAL_CONFIG_MODAL_ID, null, () =>
      controller
        .deleteReportConfig(defaultConfig?.id)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно удалена', ENotificationStyles.Success)
          );
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления конфигурации', ENotificationStyles.Error)
          );
        })
    );
  };

  const toggleAutoUpload = (id: string, state: boolean, isCreation: boolean) => {
    if (isCreation) {
      return null;
    }

    controller
      .toggleAutoUpload(id, state)
      .then(() => {
        setNotification(
          getNotificatorProps(
            `Автоматическая выгрузка успешно ${state ? 'включена' : 'выключена'}`,
            ENotificationStyles.Success
          )
        );
        fetchConfig();
      })
      .catch(() => {
        setNotification(
          getNotificatorProps(
            `Ошибка ${state ? 'включения' : 'выключения'} автоматической выгрузки`,
            ENotificationStyles.Error
          )
        );
      });
  };

  return { submitConfig, deleteConfig, toggleAutoUpload, fetchConfig };
};

export default useFormActions;
