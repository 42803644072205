import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import { NewsStore } from '../stores/news/news.store';
import { FilterStore } from '../stores/news/filter.store';

@provide.singleton()
export class NewsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(NewsStore)
  protected newsStore: NewsStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  setPage = (v: number) => {
    this.newsStore.setPage(v);
  };

  setFilterTags = tags => {
    this.filterStore.setFilterTags(tags);
  };

  setFilterStatus = status => {
    this.filterStore.setFilterStatus(status);
  };

  setFilterContext = value => {
    this.filterStore.setFilterContext(value);
  };

  fetchNews = async () => {
    this.newsStore.setLoading(true);

    // Докер - работай

    try {
      const { content, totalPages } = await this.axios.api.getNews({
        tags: this.filterStore.filters.tags.map(tag => tag.tagId),
        status: this.filterStore.filters.status.map(({ statusId }) => statusId),
        context: this.filterStore.filters.context,
        size: 20,
        page: this.newsStore.page,
        sort: this.newsStore.sort,
      });

      this.newsStore.setNews(content, totalPages);
    } catch (e) {
      console.log('fetchNews e.message', e.message);
      this.newsStore.clearNews();
    } finally {
      this.newsStore.setLoading(false);
      this.newsStore.setLoaded(true);
    }
  };

  fetchNewsItem = async ({ newsId }: { newsId: string }) => {
    this.newsStore.setLoading(true);

    try {
      const newsItem = await this.axios.api.getNewsItem({ newsId }, { omit: ['newsId'] });

      this.newsStore.setNewsItem(newsItem);
    } catch (e) {
      console.log('fetchNewsItem e.message', e.message);
    } finally {
      this.newsStore.setLoading(false);
      this.newsStore.setLoaded(true);
    }
  };

  createNews = async () => {
    try {
      const newsId = await this.axios.api.createNewsItem({});

      return newsId;
    } catch (e) {
      console.log('createNews e.message', e.message);
    }

    return null;
  };

  updateNews = (
    payload: TypeApiRequest<'updateNewsItem'>
  ): Promise<TypeApiResponse<'updateNewsItem'>> => {
    return this.axios.api
      .updateNewsItem(
        {
          newsId: payload.newsId,
          newsStatus: payload.newsStatus,
          newsTitle: payload.newsTitle,
          pictureSmall: payload.pictureSmall,
          pictureBig: payload.pictureBig,
          newsDescription: payload.newsDescription,
          newsContent: payload.newsContent,
          pinned: payload.pinned,
          publicationDate: payload.publicationDate,
          tags: payload.tags,
        },
        { omit: ['newsId'] }
      )

      .then(response => {
        this.newsStore.clearFormValuesErrors();
        this.newsStore.setNewsItem(response);

        return response;
      })
      .catch(e => {
        const errorsArray = e.response?.data?.errors;
        const errorData = e.response?.data?.error;

        if (errorsArray) {
          this.newsStore.setFormValuesErrors(errorsArray);
        } else {
          this.newsStore.setFormValueErrors(errorData);
        }

        return Promise.reject(e);
      });
  };

  deleteNews = async ({ newsId }: { newsId: string }) => {
    this.newsStore.setLoading(true);

    try {
      await this.axios.api.deleteNewsItem({ newsId }, { omit: ['newsId'] });
      this.fetchNews();
    } catch (e) {
      console.log('deleteNews e.message', e.message);
    } finally {
      this.newsStore.setLoading(false);
      this.newsStore.setLoaded(true);
    }
  };

  uploadFile = (file: File) => {
    return this.axios.api
      .uploadFile({
        ownerId: '',
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(response => {
        return this.axios.api
          .uploadFileFinish({ data: file, url: response.uploadUrl })
          .then(() => ({ id: response.id }));
      })
      .catch(e => {
        console.error('uploadFile e.message', e.message);
      });
  };

  fetchAuthors = async () => {
    try {
      const { content } = await this.axios.api.getUsersAciveList({
        size: 20,
        page: 1,
        sort: 'regDate,desc',
        fullName: '',
      });

      this.newsStore.setAuthors(content);
    } catch (e) {
      console.log('fetchAuthors e.message', e.message);
    }
  };
}
