import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Sidebar = styled.aside`
  height: calc(100vh - 116px);
  width: 200px;
  margin-right: 20px;

  background-color: ${Colors.generalWhite};
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  color: ${Colors.grey};
  cursor: pointer;
  border: none;
  background-color: ${Colors.generalWhite};
  margin: auto;

  :hover {
    color: ${Colors.hoverGrey};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
`;

const Styled = { Sidebar, BackButton, Wrapper };

export default Styled;
