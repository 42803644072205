import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;

  /**
   * формат `YYYY-MM-DD`
   */
  dateFrom: string;
  /**
   * формат `YYYY-MM-DD`
   */
  dateTo: string;
  deviceId?: string;
};
type TypeResponse = Blob;

export const getReportPowerBIWeather: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-weather/reports/weather/power-bi/csv/zip`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
