import { FC, memo } from 'react';
import { Breadcrumbs as MBreadcrumbs, Link as MLink, Typography } from '@mui/material';
import { generatePath } from 'react-router-dom';

import { TDictionaryItemCrumbs } from '../../../../../../../../../../api/models/dictionaries.model';
import { AdminRoutes } from '../../../../../../../../routes';

import { StyledLink } from './styled';

const Breadcrumbs: FC<{
  crumbList: TDictionaryItemCrumbs;
  remoteName;
}> = ({ crumbList, remoteName }) => {
  const isNoCrumbs = !crumbList || !crumbList.crumbs.length;

  if (isNoCrumbs && !remoteName) {
    return null;
  }

  return (
    <MBreadcrumbs>
      <StyledLink to={generatePath(AdminRoutes.DictionaryName, { name: remoteName })}>
        <Typography>{remoteName}</Typography>
      </StyledLink>
      {crumbList && crumbList?.crumbs.length
        ? crumbList.crumbs.map(crumb => (
            <StyledLink
              key={crumb.code}
              to={generatePath(AdminRoutes.DictionarySelectedItemEdit, {
                itemId: crumb.id,
                name: remoteName,
              })}
            >
              {crumb.name}
            </StyledLink>
          ))
        : null}
      {crumbList?.entity?.name ? (
        <MLink underline="none">
          <Typography>{crumbList.entity.name}</Typography>
        </MLink>
      ) : null}
    </MBreadcrumbs>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default memo(Breadcrumbs);
