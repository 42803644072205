import { isEmpty } from 'lodash';
import { Moment } from 'moment';

import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import {
  TypeUserAdminRole,
  TypeUserAdminStatus,
  TypeUserAdminGender,
  EUserAdminResetPasswordStatus,
  EPlatform,
} from '../../api/models/user.admin.model';
import { UsersStore } from '../stores/users/users.store';
import { FilterStore } from '../stores/users/filter.store';
import { InterestStore } from '../stores/users/interest.store';
import { ExpertiseStore } from '../stores/users/expertise.store';
import { UserService } from '../services';

@provide.singleton()
export class UsersController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(UsersStore)
  protected usersStore: UsersStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  @lazyInject(InterestStore)
  protected interestStore: InterestStore;

  @lazyInject(ExpertiseStore)
  protected expertiseStore: ExpertiseStore;

  @lazyInject(UserService)
  protected userService: UserService;

  setPage = (v: number) => {
    this.usersStore.setPage(v);
  };

  setFilterUserId = (value: string) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterUserId(value);
  };

  setFilterFullName = (value: string) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterFullName(value);
  };

  setFilterOrgName = (value: string) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterOrgName(value);
  };

  setFilterPhoneNum = (value: string) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterPhoneNum(value);
  };

  setFilterEmail = (value: string) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterEmail(value);
  };

  setFilterRegDateFrom = (date: Moment) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterRegDateFrom(date);
  };

  setFilterRegDateTo = (date: Moment) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterRegDateTo(date);
  };

  setFilterRoles = (roles: TypeUserAdminRole[]) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterRoles(roles);
  };

  setFilterStatuses = (statuses: TypeUserAdminStatus) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterStatuses(statuses);
  };

  setFilterGenders = (genders: TypeUserAdminGender[]) => {
    this.usersStore.setPage(0);
    this.filterStore.setFilterGenders(genders);
  };

  setFilterOrganizationId = (value: string) => {
    this.filterStore.setFilterOrganizationId(value);
  };

  fetchUsers = async () => {
    this.usersStore.setLoading(true);

    const payload = {
      id: this.filterStore.filters.userId || undefined,
      fullName: this.filterStore.filters.fullName || undefined,
      phoneNum: this.filterStore.filters.phoneNum || undefined,
      regDateFrom: this.filterStore.filters.regDateFrom
        ? this.filterStore.filters.regDateFrom.format('DD.MM.yyyy')
        : undefined,
      regDateTo: this.filterStore.filters.regDateTo
        ? this.filterStore.filters.regDateTo.format('DD.MM.yyyy')
        : undefined,
      email: this.filterStore.filters.email || undefined,
      roles: !isEmpty(this.filterStore.filters.roles)
        ? this.filterStore.filters.roles.map(role => role.roleId)
        : undefined,
      statuses: !isEmpty(this.filterStore.filters.statuses)
        ? [this.filterStore.filters.statuses.statusId]
        : [],
      genders: this.filterStore.filters.genders.map(gender => gender.genderId),
      context: this.filterStore.filters.context,
      size: this.usersStore.pageSize ?? 20,
      page: this.usersStore.page,
      sort: {
        field: 'regDate',
        sort: 'desc',
      },
      organizationName: this.filterStore.filters.organizationName || undefined,
    };

    try {
      const { content, totalPages, totalElements } = this.filterStore.filters.organizationName
        ? await this.axios.api.getUsersAdminSearchEmployees(payload)
        : await this.axios.api.getUsersAdmin(payload);

      this.usersStore.setUsers(content, totalPages, totalElements);
    } catch (e) {
      console.error('fetchUsers e.message', e.message);
      this.usersStore.clearUsers();
    } finally {
      this.usersStore.setLoading(false);
      this.usersStore.setLoaded(true);
    }
  };

  fetchUsersEmployees = async () => {
    this.usersStore.setLoading(true);

    const payload = {
      id: this.filterStore.filters.userId || undefined,
      fullName: this.filterStore.filters.fullName || undefined,
      phoneNum: this.filterStore.filters.phoneNum || undefined,
      regDateFrom: this.filterStore.filters.regDateFrom
        ? this.filterStore.filters.regDateFrom.format('DD.MM.yyyy')
        : undefined,
      regDateTo: this.filterStore.filters.regDateTo
        ? this.filterStore.filters.regDateTo.format('DD.MM.yyyy')
        : undefined,
      email: this.filterStore.filters.email || undefined,
      roles: !isEmpty(this.filterStore.filters.roles)
        ? this.filterStore.filters.roles.map(role => role.roleId)
        : undefined,
      statuses: !isEmpty(this.filterStore.filters.statuses)
        ? [this.filterStore.filters.statuses.statusId]
        : [],
      genders: !isEmpty(this.filterStore.filters.genders)
        ? this.filterStore.filters.genders.map(gender => gender.genderId)
        : undefined,
      organizationId: this.filterStore.filters.organizationId,
      size: 20,
      page: this.usersStore.page,
    };

    try {
      const { content, totalPages } = await this.axios.api.getUsersAdminEmployees(payload);

      this.usersStore.setUsers(content, totalPages);
    } catch (e) {
      console.error('fetchUsersEmployees e.message', e.message);
      this.usersStore.clearUsers();
    } finally {
      this.usersStore.setLoading(false);
      this.usersStore.setLoaded(true);
    }
  };

  fetchUser = (data: TypeApiRequest<'getUserAdminById'>) => {
    this.usersStore.setLoading(true);

    return this.axios.api
      .getUserAdminById(data, { omit: ['userId'] })
      .then(user => {
        this.usersStore.setUser(user);

        if (user.experiment) {
          this.usersStore.addToActionList('experiment');
        }

        if (user.scout) {
          this.usersStore.addToActionList('scout');
        }

        return user;
      })
      .catch(e => {
        console.error('fetchUser e.message', e.message);

        return Promise.reject(e);
      })
      .finally(() => {
        this.usersStore.setLoading(false);
        this.usersStore.setLoaded(true);
      });
  };

  createUser = (
    payload: TypeApiRequest<'createUserAdmin'>
  ): Promise<TypeApiResponse<'createUserAdmin'>> => {
    return this.axios.api
      .createUserAdmin({
        firstName: payload.firstName,
        lastName: payload.lastName,
        secondName: payload.secondName,
        countryCode: payload.countryCode,
        phoneNum: payload.phoneNum,
        phoneStatus: payload.phoneStatus,
        email: payload.email,
        emailStatus: payload.emailStatus,
        password: payload.password,
        role: payload.role,
        status: payload.status,
        gender: payload.gender,
        dateOfBirth: payload.dateOfBirth,
        includedInStats: payload.includedInStats,
        avatar: payload.avatar,
        domain: payload.domain,
      })
      .then(response => {
        this.usersStore.clearFormValuesErrors();

        return response;
      })
      .catch(e => {
        const errorsArray = e.response?.data?.errors;
        const errorData = e.response?.data?.error;

        if (errorsArray) {
          this.usersStore.setFormValuesErrors(errorsArray);
        } else {
          this.usersStore.setFormValueErrors(errorData);
        }

        return Promise.reject(e);
      });
  };

  updateUser = (
    payload: TypeApiRequest<'updateUserByAdmin'>
  ): Promise<TypeApiResponse<'updateUserByAdmin'>> => {
    return this.axios.api
      .updateUserByAdmin(
        {
          userId: payload.userId,
          gender: payload.gender,
          dateOfBirth: payload.dateOfBirth,
          firstName: payload.firstName,
          lastName: payload.lastName,
          secondName: payload.secondName,
          avatar: payload.avatar,
          status: payload.status,
          isScout: payload.isScout,
          isExperiment: payload.isExperiment,
        },
        { omit: ['userId'] }
      )
      .then(() => {
        this.usersStore.clearFormValuesErrors();

        return true;
      })
      .catch(e => {
        const errorsArray = e.response?.data?.errors;
        const errorData = e.response?.data?.error;

        if (errorsArray) {
          this.usersStore.setFormValuesErrors(errorsArray);
        } else {
          this.usersStore.setFormValueErrors(errorData);
        }

        return Promise.reject(e);
      });
  };

  uploadFile = (file: File) => {
    return this.axios.api
      .uploadFile({
        ownerId: '',
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(response => {
        return this.axios.api
          .uploadFileFinish({ data: file, url: response.uploadUrl })
          .then(() => ({ id: response.id }));
      })
      .catch(e => {
        console.error('uploadFile e.message', e.message);
      });
  };

  fetchModules = (userId: string) => {
    return this.axios.api.getUserModules({ id: userId }).then(response => {
      this.usersStore.setActionList(response);
    });
  };

  fetchUserActions = (userId: string) => {
    return this.axios.api.getUserActions({ userId }).then(response => {
      this.usersStore.setUserActionList(response);
      return response;
    });
  };

  userHasAction = (actionCode: string) => {
    return this.usersStore.userActionListCodes.has(actionCode);
  };

  setUserAction = (userId: string, actionCode: string, active: boolean) => {
    return this.axios.api.updateUserAction(
      { userId, actionCode, active },
      { omit: ['userId', 'actionCode', 'active'] }
    );
  };

  confirmPhoneNumber = (
    payload: TypeApiRequest<'sendSmsCodeUserAdmin'>
  ): Promise<TypeApiResponse<'sendSmsCodeUserAdmin'>> => {
    return this.axios.api
      .sendSmsCodeUserAdmin(payload)
      .then(response => {
        this.usersStore.setOperId(response.operId);
        this.usersStore.clearFormValuesErrors();

        return response;
      })
      .catch(e => {
        const errorData = e.response?.data?.error;

        if (errorData) {
          this.usersStore.setFormValueErrors(errorData);
        }

        return Promise.reject(e);
      });
  };

  checkSMSCode = (
    payload: TypeApiRequest<'checkSmsCodeUserAdmin'>
  ): Promise<TypeApiResponse<'checkSmsCodeUserAdmin'>> => {
    return this.axios.api
      .checkSmsCodeUserAdmin(payload)
      .then(response => {
        this.usersStore.clearFormValuesErrors();
        this.usersStore.setOperId('');

        return response;
      })
      .catch(e => {
        const errorData = e.response?.data?.error;

        if (errorData) {
          this.usersStore.setFormValueErrors(errorData);
        }

        this.usersStore.setOperId('');

        return Promise.reject(e);
      });
  };

  updatePhone = (
    payload: TypeApiRequest<'updatePhoneUserAdmin'>
  ): Promise<TypeApiResponse<'updatePhoneUserAdmin'>> => {
    return this.axios.api
      .updatePhoneUserAdmin(payload, { omit: ['userId'] })
      .then(response => {
        this.usersStore.clearFormValuesErrors();

        this.usersStore.setUser({
          ...this.usersStore.user,
          phone: {
            ...this.usersStore.user.phone,
            phoneNumberCode: '+7',
            phoneNumber: payload.phoneNum,
          },
        });

        return response;
      })
      .catch(e => {
        const errorData = e.response?.data?.error;

        if (errorData) {
          this.usersStore.setFormValueErrors(errorData);
        }

        return Promise.reject(e);
      });
  };

  updateEmail = (
    payload: TypeApiRequest<'updateEmailUserAdmin'>
  ): Promise<TypeApiResponse<'updateEmailUserAdmin'>> => {
    return this.axios.api
      .updateEmailUserAdmin(payload, { omit: ['userId'] })
      .then(response => {
        this.usersStore.clearFormValuesErrors();

        this.usersStore.setUser({
          ...this.usersStore.user,
          email: {
            ...this.usersStore.user.email,
            email: payload.email,
            emailStatus: {
              statusId: payload.emailStatus,
              statusName: 'Подтвержден',
            },
          },
        });

        return response;
      })
      .catch(e => {
        const errorData = e.response?.data?.error;

        if (errorData) {
          this.usersStore.setFormValueErrors(errorData);
        }
        return Promise.reject(e);
      });
  };

  getPendingContracts = async (payload: TypeApiRequest<'getPendingContractsUserAdmin'>) => {
    try {
      const { emails } = await this.axios.api.getPendingContractsUserAdmin(payload, {
        omit: ['userId'],
      });

      if (!isEmpty(emails)) {
        this.usersStore.setPendingEmail(emails[0]);
      }
    } catch (e) {
      console.error('getPendingContracts e.message', e.message);
    }
  };

  deleteUser = async (payload: TypeApiRequest<'deleteUserAdminById'>) => {
    this.usersStore.setLoading(true);

    try {
      await this.axios.api.deleteUserAdminById(payload, { omit: ['userId'] });
      this.fetchUsers();
    } catch (e) {
      console.error('deleteUser e.message', e.message);
    } finally {
      this.usersStore.setLoading(false);
      this.usersStore.setLoaded(true);
    }
  };

  fetchExpertises = async () => {
    this.expertiseStore.setLoading(true);

    try {
      const { content } = await this.axios.api.getExpertises({
        size: 100,
        page: 0,
        search: this.expertiseStore.searchExpertise,
      });

      this.expertiseStore.setExpertises(content);
    } catch (e) {
      console.log('fetchExpertises e.message', e.message);
    } finally {
      this.expertiseStore.setLoading(false);
      this.expertiseStore.setLoaded(true);
    }
  };

  saveExpertisesUserAdmin = async (
    data: TypeApiRequest<'saveExpertisesUserAdmin'>
  ): Promise<void> => {
    try {
      await this.axios.api.saveExpertisesUserAdmin(data);
    } catch (e) {
      console.log('saveExpertisesUserAdmin e.message', e.message);
    }
  };

  fetchInterests = async () => {
    this.interestStore.setLoading(true);

    try {
      const { content } = await this.axios.api.getInterests({
        size: 100,
        page: 0,
        search: this.interestStore.searchInterest,
      });

      this.interestStore.setInterests(content);
    } catch (e) {
      console.log('fetchInterests e.message', e.message);
    } finally {
      this.interestStore.setLoading(false);
      this.interestStore.setLoaded(true);
    }
  };

  saveInterestsUserAdmin = async (
    data: TypeApiRequest<'saveInterestsUserAdmin'>
  ): Promise<void> => {
    try {
      await this.axios.api.saveInterestsUserAdmin(data);
    } catch (e) {
      console.log('saveInterestsUserAdmin e.message', e.message);
    }
  };

  resetPassword = (
    payload: TypeApiRequest<'resetPasswordUserAdmin'>
  ): Promise<TypeApiResponse<'resetPasswordUserAdmin'>> => {
    return this.axios.api
      .resetPasswordUserAdmin(payload)
      .then(response => {
        this.usersStore.setResetPasswordStatus(EUserAdminResetPasswordStatus.SUCCESS);

        return response;
      })
      .catch(e => {
        this.usersStore.setResetPasswordStatus(EUserAdminResetPasswordStatus.ERROR);

        return Promise.reject(e);
      });
  };

  loginAsUser = (
    payload: TypeApiRequest<'loginAs'>,
    platform: EPlatform
  ): Promise<TypeApiResponse<'loginAs'>> => {
    return this.axios.api
      .loginAs(payload)
      .then(response => {
        let host = 'http://localhost:3000';
        const servicesList = window._env_?.SERVICES_LIST.split(' ') || [];

        const currentStage = window._env_.CURRENT_STAGE;

        if (platform === EPlatform.assistant) {
          host = currentStage
            ? window._env_[`ASSISTANCE_${currentStage}_URL`]
            : 'http://localhost:3000';
        }

        if (platform === EPlatform.farmlink) {
          host = servicesList[1] || 'http://localhost:3000';
        }

        const timestamp = new Date(response.expirationDate).getTime();
        const url = `${host}/admin-view?id=${response.token.replaceAll(
          '.',
          '==398=='
        )}&expireAt=${timestamp}`;

        // @ts-ignore
        window.open(url, '_blank').focus();

        return response;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };

  fetchUserOrganizationsList = async (payload: TypeApiRequest<'getUserOrganizationsList'>) => {
    try {
      const response = await this.axios.api.getUserOrganizationsList(payload);
      return response;
    } catch (error) {
      console.log('fetch user organization error', error);
    }
  };

  fetchScoutList = (payload: TypeApiRequest<'getScoutList'>) => {
    return this.axios.api.getScoutList(payload);
  };

  setPageSize = (pageSize: number) => {
    this.usersStore.setPage(0);
    this.usersStore.setPageSize(pageSize);
  };
}
