import { TextField, TextFieldProps } from '@mui/material';
import { memo, useMemo, useState } from 'react';

import { EAttributeUnit } from '../../../../../../../../../../../../../../../../../../api/models/attribute.model';
import { TDictionaryItem } from '../../../../../../../../../../../../../../../../../../api/models/dictionaries.model';
import { TAttributeWithValue } from '../../../../../../../../../../../../../../../../../stores';
import Styled from '../../styled';

import { BooleanField, DoubleField, IntegerField, StringField } from './components';

interface IProps {
  data: TAttributeWithValue;
  onChange: (value: TDictionaryItem['attrs'], key: string, removeValue?: boolean) => void;
  isIndexDictionary?: boolean;
}

const defaultInputProps: TextFieldProps = {
  size: 'small',
  variant: 'outlined',
  inputProps: { style: { fontSize: 14 } },
};

const ParamRowItem = ({ data, onChange, isIndexDictionary }: IProps) => {
  const { code, name, ownerName, ownerId, units, value: inputValue } = data;

  const handleChange = (changeValue: any) => {
    onChange({ [code]: changeValue }, code);
  };

  const removeValue = () => {
    onChange({ [code]: null }, code, true);
  };

  const getFieldItem = useMemo(() => {
    switch (units) {
      case EAttributeUnit.String:
        return <StringField value={inputValue} onChangeHandler={handleChange} variant="outlined" />;
      case EAttributeUnit.Boolean:
        return <BooleanField value={Boolean(inputValue)} onChangeHandler={handleChange} />;
      case EAttributeUnit.Double:
        return <DoubleField value={inputValue} onChangeHandler={handleChange} variant="outlined" />;
      case EAttributeUnit.Integer:
        return (
          <IntegerField value={inputValue} onChangeHandler={handleChange} variant="outlined" />
        );
      default:
        return <StringField value={inputValue} onChangeHandler={handleChange} variant="outlined" />;
    }
  }, [inputValue]);

  return (
    <Styled.Grid>
      <TextField
        value={name}
        {...defaultInputProps}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        value={code}
        {...defaultInputProps}
        InputProps={{
          readOnly: true,
        }}
      />
      {isIndexDictionary ? (
        <TextField
          value={units}
          {...defaultInputProps}
          InputProps={{
            readOnly: true,
          }}
        />
      ) : (
        <>
          {' '}
          {getFieldItem}
          <TextField
            value={ownerName || ownerId}
            {...defaultInputProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </>
      )}

      {/* <Button disabled={inputValue === null} onClick={removeValue}>
        {' '}
        X{' '}
      </Button> */}
    </Styled.Grid>
  );
};

export default memo(ParamRowItem);
