import { TypeApiRoute } from '../../models/type.api.request';
import { IReference } from '../../models/reference.model';

type TypeRequest = {
  id: string;
  title?: string;
  active?: boolean;
  text?: string;
};

type TypeResponse = IReference;

export const updateReference: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (url: TypeRequest) => `/api/as-fields/references/${url.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
