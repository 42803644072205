import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const LEAVE_BEFORE_SAVE = 'leaveBeforeSaveModalId';

const leaveBeforeSaveModal: TModalConfig = {
  id: LEAVE_BEFORE_SAVE,
  name: LEAVE_BEFORE_SAVE,
  title: 'Вы уверены, что хотите покинуть страницу?',
  description: {
    value: 'Данные могут быть потеряны',
    $styles: {
      textAlign: 'center',
      margin: '12px 0',
    },
  },
  type: EModalType.Warning_Before_Living,
  successButton: {
    title: 'Покинуть',
    color: 'secondary',
  },
  denyButton: {
    title: 'Вернуться',
    color: 'default',
  },
};

export { leaveBeforeSaveModal, LEAVE_BEFORE_SAVE };
