import { IOrgIntegraField } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalFields/models';
import {
  IPageableRequest,
  TypeResponsePageable,
} from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IPageableRequest & { organizationId: string };

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraField[] };

type TypeError = {};

export const getOrgIntegraFieldListByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }) => `/api/integra-as/integra/field/byOrganizationId/${organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
