export enum Colors {
  backgroundGray = '#F2F1F4',
  labelGray = '#525252',
  layoutGray = '#E5E5E5',
  borderGray = '#EDEDED',
  white = '#ffffff',
  textSuperDark = '#161616',
  gray = '#FAF9FD',
  grayBorder = '#E9E6F0',
  black = '#151f32',
  green = '#3FB65F',
  darkGray = '#9AA0AD',
  lightGray = '#FAF9FD',
  lightnessGray = '#E9E6F0',
  red = '#F29993',
  lightRed = '#fef5f4',
  redHover = '#e76259',
  greenHover = '#259A42',
  yellow = '#FFE924',
  backgroundSecondaryGray = '#F2F1F4',
  orange = '#D97C26',
  lightOrange = '#fbf2e9',
  purple = '#831CA7',
  lightPurple = '#f3e8f6',
  blue = '#6391C3',
  lightBlue = '#eff4f9',
  turquoise = '#009FC1',
  lightTurquoise = '#e6f5f9',
  limeGreen = '#75A347',
  lightLimeGreen = '#f1f6ed',
  bluePurple = '#997DBD',
  lightBluePurple = '#f5f2f8',
  pink = '#D4569A',
  lightPink = '#fbeef5',
  darkCyan = '#369C96',
  lightDarkCyan = '#ebf5f5',
  dullGray = '#7C8298',
  lightDullGray = '#f2f3f5',
  agateGray = '#E8E9EB',
  darkRed = '#d32f2f',
}
