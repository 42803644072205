import { EOrgIntegraCropwiseFieldAutoMapMode } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseFields/models';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  mode: EOrgIntegraCropwiseFieldAutoMapMode;
};

type TypeResponse = string;

type TypeError = {};

export const automapOrgIntegraCropwiseFieldListByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }: TypeRequest) =>
    `/api/integra-cw/admin/mapping/field/mapForOrganization/${organizationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
