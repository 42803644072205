import { TypeApiRoute } from '../../../models/type.api.request';
import { IAttribute } from '../../../models/checklist.attribute.model';

type TypeRequest = {
  id: string;
};

type TypeResponse = IAttribute;

type TypeError = {};

export const getChecklistAttribute: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
