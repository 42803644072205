import { FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';

import { AdminRoutes } from '../../../../routes';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Layout } from '../../../../components';

import { UsersFilter, UsersTable } from './components';

export const UsersList: FC = observer(() => {
  const history = useHistory();
  const handleCreateUser = () => history.push(AdminRoutes.UserCreate);

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Пользователи</H1>
            <Button onClick={handleCreateUser}>Добавить пользователя</Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <UsersFilter />
          <UsersTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
