import { IOrgIntegraAgrosignalCreateUpdateChecklist } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalChecklists/models/orgIntegraAgrosignalChecklist';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IOrgIntegraAgrosignalCreateUpdateChecklist;

type TypeResponse = string;

type TypeError = {};

export const createOrgIntegraAgrosignalChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/checklist`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
