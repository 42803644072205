import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import EDIT_SVG from '../../../shared/assets/images/edit.svg';
import { PaperBlock } from '../PaperBlock/PaperBlock';

import {
  ProfileWidgetInner,
  ProfileWidgetHeader,
  ProfileWidgetTitle,
  ProfileWidgetEditLink,
  ProfileImage,
} from './style';

type Props = {
  title: string;
  editLink: string;
  editCaption: string;
  dataTestId?: string;
  dataTestIdAction?: string;
};

export const ProfileWidgetWrapper: FC<Props> = ({
  title,
  editLink,
  editCaption,
  dataTestId = '',
  dataTestIdAction = '',
  children,
}) => {
  const history = useHistory();

  return (
    <PaperBlock dataTestId={dataTestId && `${dataTestId}-container`}>
      <ProfileWidgetInner data-test-id={dataTestId && `${dataTestId}-wrapper`}>
        <ProfileWidgetHeader data-test-id={dataTestId && `${dataTestId}-header`}>
          <ProfileWidgetTitle data-test-id={dataTestId && `${dataTestId}-title`}>
            {title}
          </ProfileWidgetTitle>
          <ProfileWidgetEditLink
            onClick={() => history.push(editLink)}
            data-test-id={dataTestIdAction}
          >
            <ProfileImage
              src={EDIT_SVG}
              data-test-id={dataTestIdAction && `${dataTestIdAction}-icon`}
            />
            <p data-test-id={dataTestIdAction && `${dataTestIdAction}-caption`}>{editCaption}</p>
          </ProfileWidgetEditLink>
        </ProfileWidgetHeader>
        {children}
      </ProfileWidgetInner>
    </PaperBlock>
  );
};
