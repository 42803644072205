import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../models/type.api.request';

import { IAttribute, EChecklistAttributeType } from './../../../models/checklist.attribute.model';

type TypeRequest = {
  id?: string;
  name?: string;
  description?: string;
  searchByContext?: boolean;
  isRequired?: boolean;
  isActive?: boolean;
  checklistLink?: string;
  checklistLinkPublicId?: string;
  dictionaryLink?: string;
  isMultiselect?: boolean;
  isTitle?: boolean;
  displayParent?: boolean;
  type?: EChecklistAttributeType;
  createdBy?: string;

  page?: number;
  size?: number;
};

type TypeResponse = { content: IAttribute[] } & TypeResponsePageable;

type TypeError = {};

export const getChecklistAttributeListByParams: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request =>
    `/api/as-fields/admin/attribute/search?page=${request?.page || 0}&size=${request?.size || 20}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
