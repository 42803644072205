import {
  ICropwiseConfig,
  ICropwiseCreateConfig,
} from '../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationReports/components/OrganizationReportsCropwise/models';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = ICropwiseCreateConfig;
type TypeResponse = ICropwiseConfig;

export const createCropwiseReportConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-cropwise/reports/config/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
