import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  email: string;
  roleId: string;
  comment?: string;
  fio?: string;
};

type TypeError = {};

export const inviteUserByEmail: TypeApiRoute & {
  request: TypeRequest;
  response: string;
  error: TypeError;
} = {
  url: params => `/api/da-profile/organizations/user/invite/email/${params.organizationId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as string,
  error: {} as TypeError,
};
