import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = { id: string };

type TypeResponse = string;

type TypeError = {};

export const deleteOrgIntegraCropwiseConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: TypeRequest) => `/api/integra-cw/admin/mapping/organization/${id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
