import { Box, Button, Collapse } from '@mui/material';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStore } from '../../../../../../../../../../../../../../shared/utils';
import { OrgIntegraTableFilterController, OrgIntegraTableFilterStore } from '../../../../mobx';

const OrganizationIntegraTableFilterContainer = () => {
  const { isOpen, getBuildedComponentsCollectionAsArray } = useStore(OrgIntegraTableFilterStore);
  const { applyFilters, resetFilters } = useStore(OrgIntegraTableFilterController);

  const history = useHistory();
  const location = useLocation();

  const onFilterApply = e => {
    e.preventDefault();
    e.stopPropagation();

    const appliedFilters = applyFilters();
    const searchParams = new URLSearchParams(location.search);
    const newSearchParams = new URLSearchParams();

    if (searchParams.get('page')) {
      newSearchParams.set('page', searchParams.get('page'));
    }
    if (searchParams.get('size')) {
      newSearchParams.set('size', searchParams.get('size'));
    }

    appliedFilters.forEach((filter, key) => {
      if (filter.value !== undefined && filter.value !== null) {
        const setValue = (value: any) => {
          switch (filter.type) {
            case 'select':
              newSearchParams.set(key, value.value.toString());
              break;
            default:
              newSearchParams.set(key, value.toString());
          }
        };

        if (Array.isArray(filter.value)) {
          filter.value.forEach(value => setValue(value));
        } else {
          setValue(filter.value);
        }
      }
    });

    history.push({ pathname: location.pathname, search: newSearchParams.toString() });
  };

  const onFilterReset = () => {
    resetFilters();
    const searchParams = new URLSearchParams(location.search);

    const page = searchParams.get('page');
    const size = searchParams.get('size');

    const newSearchParams = new URLSearchParams();

    if (page) {
      newSearchParams.set('page', page);
    }
    if (size) {
      newSearchParams.set('size', size);
    }

    history.push({ pathname: location.pathname, search: newSearchParams.toString() });
  };

  const filterCollection = getBuildedComponentsCollectionAsArray().map((component, index) => (
    <Box
      key={index}
      sx={{
        breakInside: 'avoid', // Предотвращает разрыв элемента на колонки
        marginBottom: 2, // Добавляет нижний отступ для каждого элемента
      }}
    >
      {component}
    </Box>
  ));

  return (
    <Collapse in={isOpen}>
      <Box marginBottom={1}>
        <form onSubmit={onFilterApply}>
          <Box
            sx={{
              columnCount: { xs: 1, sm: 2, md: 3, lg: 4 },
              columnGap: '16px',
            }}
          >
            {filterCollection}
          </Box>

          <Box display={'flex'} gap={2} justifyContent={'flex-end'} marginTop={1}>
            <Button onClick={onFilterApply} type="submit" size="small" variant="contained">
              Применить
            </Button>
            <Button onClick={onFilterReset} size="small" color="info" variant="outlined">
              Сбросить
            </Button>
          </Box>
        </form>
      </Box>
    </Collapse>
  );
};

export default observer(OrganizationIntegraTableFilterContainer);
