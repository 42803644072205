import {
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { ISelectOption } from '../../../../../../../../types/selectOption';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../shared/utils';
import { DictionariesStore } from '../../../../../../../stores';

import Styled from './styled';

enum EFileImportStage {
  Idle,
  DryRun,
  DryRunError,
  DryRunSuccess,
  Import,
  ImportSuccess,
  ImportError,
}

const ImportModal = () => {
  const store = useStore(DictionariesStore);
  const {
    importDictionary,
    importDictionaryDryRun,
    importDictionaryDependencies,
    importDictionaryDependenciesDryRun,
  } = useStore(DictionariesController);

  const { closeModal, getModalPayload } = useModal();

  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedOption, setSelectedOption] = useState<ISelectOption>(
    store?.selectedDictionaryListRemoteName
      ? {
          label: store.selectedDictionaryListRemoteName.title,
          value: store.selectedDictionaryListRemoteName.remoteName,
        }
      : null
  );
  const [fileImportStage, setFileImportStage] = useState<EFileImportStage>(EFileImportStage.Idle);
  const [output, setOutput] = useState<string | null>(null);

  const modalPayload = getModalPayload();
  const isDependenciesExport = modalPayload?.isDependencies;

  useEffect(() => {
    setOptionList(
      store.dictionaryNameList.map(item => ({ label: item.title, value: item.remoteName }))
    );
  }, [store.dictionaryNameList]);

  const onAddFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();

    setOutput(null);

    const handler = {
      dryRun: isDependenciesExport ? importDictionaryDependenciesDryRun : importDictionaryDryRun,
      importData: isDependenciesExport ? importDictionaryDependencies : importDictionary,
    };

    const formData = new FormData();
    formData.append('source', selectedFile);

    if (fileImportStage < 3) {
      handler
        .dryRun(
          {
            remoteName: selectedOption.value,
          },
          formData
        )
        .then(data => {
          setOutput(data);
          setFileImportStage(EFileImportStage.DryRunSuccess);
        })
        .catch(data => {
          setOutput(data.response.data);
          setFileImportStage(EFileImportStage.DryRunError);
        });
    } else {
      handler
        .importData(
          {
            remoteName: selectedOption.value,
          },
          formData
        )
        .then(data => {
          setOutput(data);
          setFileImportStage(EFileImportStage.ImportSuccess);
        })
        .catch(data => {
          setOutput(data.response.data);
          setFileImportStage(EFileImportStage.ImportError);
        });
    }
  };

  const isDisabledDryRun = selectedFile === null || selectedOption === null;
  const isError =
    fileImportStage === EFileImportStage.DryRunError ||
    fileImportStage === EFileImportStage.ImportError;

  return (
    <Styled.Wrapper onSubmit={handleSubmit}>
      <Styled.Title>Импорт {isDependenciesExport ? 'зависимостей ' : ''}справочника</Styled.Title>
      <Styled.InputRow position={'relative'}>
        <Styled.CloseButton onClick={closeModal}>Закрыть</Styled.CloseButton>
      </Styled.InputRow>

      <Styled.InputRow>
        <TextField value={selectedFile?.name} InputProps={{ readOnly: true }} fullWidth />
        <Button sx={{ width: '240px' }} variant="contained" component="label">
          Выбрать файл
          <input type="file" name="file" onChange={onAddFile} accept="application/JSON" hidden />
        </Button>
      </Styled.InputRow>

      <Styled.InputRow>
        <Autocomplete
          options={optionList}
          value={selectedOption}
          renderInput={props => <TextField {...props} label="Справочник" />}
          onChange={(e, value, reason) => {
            if (typeof value === 'object' && reason !== 'clear') {
              setSelectedOption(value);
            }
          }}
          fullWidth
          disablePortal
          disableClearable
        />
      </Styled.InputRow>

      {fileImportStage === EFileImportStage.DryRunError ||
      fileImportStage === EFileImportStage.DryRunSuccess ? (
        <>
          <span>Статус проверки файла: </span>
          <Styled.ImportStatus $isError={isError}>
            {isError ? 'Ошибка' : 'Успех'}
          </Styled.ImportStatus>
        </>
      ) : null}

      {fileImportStage === EFileImportStage.ImportError ||
      fileImportStage === EFileImportStage.ImportSuccess ? (
        <>
          <span>Статус импортирования файла: </span>
          <Styled.ImportStatus $isError={isError}>
            {isError ? 'Ошибка' : 'Успех'}
          </Styled.ImportStatus>
        </>
      ) : null}

      {fileImportStage > 1 && output?.length && (
        <Styled.Output>
          <Typography>{output}</Typography>
        </Styled.Output>
      )}

      <Styled.InputRow>
        {(fileImportStage === EFileImportStage.Idle ||
          fileImportStage === EFileImportStage.DryRunError ||
          fileImportStage >= 3) &&
        fileImportStage !== EFileImportStage.ImportSuccess ? (
          <Button
            size="large"
            fullWidth
            disabled={isDisabledDryRun}
            type="submit"
            variant="contained"
          >
            {fileImportStage >= 3 ? 'Сохранить' : 'Импортировать'}
          </Button>
        ) : (
          <Button size="large" fullWidth onClick={closeModal} variant="contained">
            Закрыть
          </Button>
        )}
      </Styled.InputRow>
    </Styled.Wrapper>
  );
};

export default ImportModal;
