import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ExportModal } from './components';

const DICTIONARY_MODAL_EXPORT = 'dictionaryModalExport';

const dictionaryExportModalConfig: TModalConfig = {
  name: null,
  title: null,
  type: EModalType.Custom,
  id: DICTIONARY_MODAL_EXPORT,
  children: ExportModal,
  closeOnClickOutside: true,
  styledProps: { $size: '600px', $modalPaddings: '30px 20px', $isFullContentWidth: true },
};

export { DICTIONARY_MODAL_EXPORT, dictionaryExportModalConfig };
