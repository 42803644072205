import { Box, TextField as MTextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { IOrgIntegraCropwiseErrorFile } from '../../../../models/orgIntegraCropwiseErrorFile';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { ORG_INTEGRA_CROPWISE_OPERATION_TYPE_NAME } from '../../../../../OrgIntegraCropwiseErrorMessage/models/orgIntegraCropwiseErrorMessage';

const ErrorMessageDetailsForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const { getModalPayload, closeModal } = useModal();

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    errorMessage: IOrgIntegraCropwiseErrorFile;
  };

  const onSubmit = () => {
    return Promise.resolve();
  };

  const formik = useFormik<IOrgIntegraCropwiseErrorFile>({
    initialValues: payload?.errorMessage,
    onSubmit,
  });

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Детали</ModalTitle>
        <FormikProvider value={formik}>
          <MTextField
            label="Текст ошибки"
            value={formik?.values?.exceptionText || ''}
            InputProps={{ readOnly: true }}
            multiline
            minRows={10}
            maxRows={10}
          />
          <Box display={'flex'} gap={2}>
            <MTextField
              label="Дата и время создания"
              value={
                formik?.values?.createDateTime
                  ? moment(formik?.values?.createDateTime).format('DD.MM.YYYY HH:mm:ss')
                  : ''
              }
              InputProps={{ readOnly: true }}
            />

            <MTextField
              label="Дата и время обновления"
              value={
                formik?.values?.updateDateTime
                  ? moment(formik?.values?.updateDateTime).format('DD.MM.YYYY HH:mm:ss')
                  : ''
              }
              InputProps={{ readOnly: true }}
            />
          </Box>
        </FormikProvider>
      </Box>
      <ModalFooter
        denyButton={{
          title: 'Закрыть',
          handler: () => {
            closeModal();
          },
        }}
      />
    </Box>
  );
};

export default observer(ErrorMessageDetailsForm);
