import { groupBy, isNil } from 'lodash';

import { provide } from '../../../../../../../../shared/utils';
import {
  EFormulaDependencyType,
  EFormulaReturnType,
  EFormulaType,
  IAggregationFormulaType,
} from '../../../models';
import { formulaFunctionScheme } from '../../../helpers';
import { EErrorCollection, errorCollection } from '../../../configs';

@provide.transient()
class FormulaValidationService {
  validateFormula = (formula: IAggregationFormulaType) => {
    // this.validateFormulaItemCompletion(formula);
    // this.validateFormulaBlock(formula);
  };

  validateFormulaBlock = (formula: IAggregationFormulaType) => {
    // this.validateFormulaItemCompletion(formula);

    if (formula.type) {
      this.validateByType(formula);
    }

    if (formula.returnType) {
      this.validateByReturnType(formula);
    }

    if (formula.args) {
      this.validateSiblings(formula);

      if (formula.args.length > 0) {
        for (const innerFormula of formula.args) {
          this.validateFormulaBlock(innerFormula);
        }
      }
    }
  };

  validateFormulaItemCompletion = (formulaItem: IAggregationFormulaType) => {
    if (!formulaItem.type) {
      throw Error(errorCollection.MISSING_TYPE, {
        cause: {
          elementId: `${formulaItem.clientId}-type`,
          type: EErrorCollection.MISSING_TYPE,
        },
      });
    }

    // if (!formulaItem.returnType) {
    //   throw Error(errorCollection.MISSING_RETURN_TYPE, {
    //     cause: {
    //       elementId: `${formulaItem.clientId}-returnType`,
    //       type: EErrorCollection.MISSING_RETURN_TYPE,
    //     },
    //   });
    // }
  };

  validateSiblings = (formula: IAggregationFormulaType) => {
    const formulaFunctionConfig = formulaFunctionScheme.get(formula?.functionType);

    const returnGroupedSiblings = groupBy(formula?.args, 'returnType');
    const returnSiblingList: EFormulaReturnType[] = Object.keys(
      returnGroupedSiblings
    ) as EFormulaReturnType[];

    returnSiblingList.forEach(returnType => {
      const availableSiblingsReturnTypeList = formulaFunctionConfig.availableSiblings?.[returnType];

      formula?.args.forEach(sibling => {
        if (
          sibling.type &&
          sibling.returnType &&
          availableSiblingsReturnTypeList &&
          !availableSiblingsReturnTypeList?.includes(sibling.returnType)
        ) {
          throw Error(errorCollection.WRONG_SIBLINGS_TYPE, {
            cause: {
              elementId: `${formula.clientId}-args`,
              type: EErrorCollection.WRONG_SIBLINGS_TYPE,
            },
          });
        }
      });
    });
  };

  validateByType = (formula: IAggregationFormulaType) => {
    switch (formula.type) {
      case EFormulaType.Attribute:
        // this.validateAttributeFormula(formula);
        break;
      case EFormulaType.Const:
        this.validateConstFormula(formula);
        break;
      case EFormulaType.Dependency:
        this.validateDependencyFormula(formula);
        break;
      case EFormulaType.Function:
        this.validateFunctionFormula(formula);
        break;
      case EFormulaType.Property:
        this.validatePropertyFormula(formula);
        break;

      default:
        break;
    }
  };

  validateByReturnType = (formula: IAggregationFormulaType) => {
    switch (formula.returnType) {
      case EFormulaReturnType.Boolean:
        break;
      case EFormulaReturnType.Date:
        break;
      case EFormulaReturnType.Double:
        break;
      case EFormulaReturnType.Integer:
        break;
      case EFormulaReturnType.Object:
        break;
      case EFormulaReturnType.String:
        break;
      case EFormulaReturnType.Uuid:
        break;

      default:
        break;
    }
  };

  validateAttributeFormula = (formula: IAggregationFormulaType) => {
    if (formula?.dependencyType === EFormulaDependencyType.Attribute && !formula.attrId) {
      throw Error(errorCollection.ATTR_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-attrValue`,
          type: EErrorCollection.ATTR_NOT_SELECTED,
        },
      });
    }
  };

  validateConstFormula = (formula: IAggregationFormulaType) => {
    if (isNil(formula.constantValue)) {
      throw Error(errorCollection.VALUE_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-consValue`,
          type: EErrorCollection.VALUE_NOT_SELECTED,
        },
      });
    }
  };

  validateDependencyFormula = (formula: IAggregationFormulaType) => {
    if (!formula.dependencyType) {
      throw Error(errorCollection.VALUE_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-depValue`,
          type: EErrorCollection.VALUE_NOT_SELECTED,
        },
      });
    }

    // if (formula.dependencyType === EFormulaDependencyType.Attribute && !formula.attrId) {
    //   throw Error(errorCollection.ATTR_NOT_SELECTED, {
    //     cause: {
    //       elementId: `${formula.clientId}-depAttrValue`,
    //       type: EErrorCollection.ATTR_NOT_SELECTED,
    //     },
    //   });
    // }
  };

  validateFunctionFormula = (formula: IAggregationFormulaType) => {
    if (!formula.functionType) {
      throw Error(errorCollection.VALUE_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-funcValue`,
          type: EErrorCollection.VALUE_NOT_SELECTED,
        },
      });
    }

    // const formulaFunctionConfig = formulaFunctionScheme.get(formula?.functionType);

    // if (
    //   formula.args?.length < formulaFunctionConfig.minArguments ||
    //   formula.args?.length > formulaFunctionConfig.maxArguments
    // ) {
    //   throw Error(errorCollection.ARGUMENT_NUMBER, {
    //     cause: {
    //       elementId: `${formula.clientId}-args`,
    //       type: EErrorCollection.ARGUMENT_NUMBER,
    //     },
    //   });
    // }
  };

  validatePropertyFormula = (formula: IAggregationFormulaType) => {
    if (!formula.attrId) {
      throw Error(errorCollection.VALUE_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-depValue`,
          type: EErrorCollection.VALUE_NOT_SELECTED,
        },
      });
    }

    if (!formula.propertyName) {
      throw Error(errorCollection.VALUE_NOT_SELECTED, {
        cause: {
          elementId: `${formula.clientId}-depValue`,
          type: EErrorCollection.VALUE_NOT_SELECTED,
        },
      });
    }
  };

  // validateBooleanFormula = (formula: IAggregationFormulaType) => {};

  // validateDateFormula = (formula: IAggregationFormulaType) => {};

  // validateDoubleFormula = (formula: IAggregationFormulaType) => {};

  // validateIntegerFormula = (formula: IAggregationFormulaType) => {};

  // validateObjectFormula = (formula: IAggregationFormulaType) => {};

  // validateStringFormula = (formula: IAggregationFormulaType) => {};

  // validateUuidFormula = (formula: IAggregationFormulaType) => {};
}

export default FormulaValidationService;
