import { makeAutoObservable } from 'mobx';

import { lazyInject, provide, Axios } from '../../../../../../../../shared/utils';
import { IFieldsInfoBySeason } from '../../../../../../../../../api/models/field.model';
import { ICultureAndVariety } from '../../../../../../../../../api/models/culture.model';
import { ISeason } from '../../../../../../../../../api/models/season.model';
import { ISelectOption } from '../../../../../../../../../types/selectOption';

@provide.singleton()
export class OrganizationFieldsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }
  private _seasons: ISeason[] = [];

  private _seasonOptions: ISelectOption[] = [];

  private _fieldsInfo: IFieldsInfoBySeason;

  private _cultureList: ICultureAndVariety[] = [];

  private _loadingSeasons = false;
  private _loadingFieldsInfo = false;
  private _loadingCultureList = false;

  // Filters

  private _seasonsFilterValue: ISelectOption = null;

  get currentSeason() {
    return this._seasons.find(({ year }) => year.toString() === this._seasonsFilterValue?.value);
  }

  get seasonOptions(): ISelectOption[] {
    return this._seasonOptions;
  }

  get fieldsInfo() {
    return this._fieldsInfo;
  }

  get cultureList() {
    return this._cultureList;
  }

  get isLoadingSeasons() {
    return this._loadingSeasons;
  }

  get isLoadingFieldsInfo() {
    return this._loadingFieldsInfo;
  }

  get isLoadingCultureList() {
    return this._loadingCultureList;
  }

  get seasonsFilterValue() {
    return this._seasonsFilterValue;
  }

  setSeasons = (seasons: ISeason[]) => {
    this._seasons = seasons;
  };

  setSeasonOptions = (options: ISelectOption[]) => {
    this._seasonOptions = options;
  };

  setFieldsInfo = (info: IFieldsInfoBySeason) => {
    this._fieldsInfo = info;
  };

  setCultureList = (list: ICultureAndVariety[]) => {
    this._cultureList = list;
  };

  setIsLoadingSeasons = (loading: boolean) => {
    this._loadingSeasons = loading;
  };

  setIsLoadingFieldsInfo = (loading: boolean) => {
    this._loadingFieldsInfo = loading;
  };

  setIsLoadingCultureList = (loading: boolean) => {
    this._loadingCultureList = loading;
  };

  setSeasonsFilterValue = (season: ISelectOption) => {
    this._seasonsFilterValue = season;
  };

  clearSeasons = () => {
    this._seasons = [];
  };

  clearFieldsInfo = () => {
    this._fieldsInfo = null;
  };

  clearCultureList = () => {
    this._cultureList = [];
  };

  clearIsLoading = () => {
    this._loadingSeasons = false;
    this._loadingFieldsInfo = false;
    this._loadingCultureList = false;
  };

  clearFilters = () => {
    this._seasonsFilterValue = null;
  };

  clearStore = () => {
    this.clearSeasons();
    this.clearFieldsInfo();
    this.clearCultureList();
    this.clearIsLoading();
    this.clearFilters();
  };
}
