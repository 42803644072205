import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeError = {};

type TypeResponse = string;

export const removeDeviceOwner: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (params: TypeRequest) => `/api/as-weather/devices/${params.id}/removeOwner`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
