import { IUpdateOrgIntegraCropwiseConfig } from '../../../../models/integra/config.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = Partial<IUpdateOrgIntegraCropwiseConfig>;

type TypeResponse = string;

type TypeError = {};

export const updateOrgIntegraCropwiseConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: TypeRequest) => `/api/integra-cw/admin/mapping/organization/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
