import { TypeApiRoute } from '../../models/type.api.request';
import { TypeAlertName } from '../../models/alert.name.model';

type TypeRequest = {
  alertId: string;
};
  
type TypeResponse = TypeAlertName;

type TypeError = {};

export const getAlertNameById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/task/${params.alertId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
