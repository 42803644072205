import { EFormulaFunctionAggregation, EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const XOR: IFormulaFunction = {
  name: 'XOR',
  label: 'Исключающее ИЛИ',
  minArguments: 2,
  primitiveType: [EFormulaReturnType.Boolean],
  availableFunctionType: [
    EFormulaFunctionAggregation.EQ,
    EFormulaFunctionAggregation.GT,
    EFormulaFunctionAggregation.LT,
    EFormulaFunctionAggregation.GOE,
    EFormulaFunctionAggregation.LOE,
    EFormulaFunctionAggregation.NE,
    EFormulaFunctionAggregation.NOT,
  ],
  argsType: [EFormulaType.Function],
  returnType: [EFormulaReturnType.Boolean],
};
