import { IOrgIntegraAgrosignalAgroField } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalFields/models';
import { IOrgIntegraAgrosignalAssistanceFieldsFilter } from '../../../../../models/integra/config.model';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  size?: number;
  page?: number;
} & Partial<IOrgIntegraAgrosignalAssistanceFieldsFilter>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraAgrosignalAgroField[] };

type TypeError = {};

export const getOrgIntegraAgrosignalAgroFieldList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }: TypeRequest) =>
    `/api/integra-as/integra/field-agros/byOrganizationId/${organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
