import { GridColDef } from '@mui/x-data-grid';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { AutoTooltip } from '../../../../shared/components';

export type TGetChecklistListingGridColumns = (actions?: {
  handleEdit: (id: string) => void;
  handleLock: (id: string) => void;
  handleDelete: (id: string) => void;
}) => GridColDef[];

export const getChecklistListingGridColumns: TGetChecklistListingGridColumns = actions => [
  { field: 'id', headerName: 'ID', flex: 1, minWidth: 300 },
  {
    field: 'name',
    headerName: 'Наименование',
    flex: 1,
    minWidth: 200,
    renderCell: params => <AutoTooltip fontSize={14}>{params?.value}</AutoTooltip>,
  },
  { field: 'type', headerName: 'Тип', flex: 1, minWidth: 100 },
  {
    field: 'active',
    headerName: 'Активный',
    width: 90,
    renderCell: params => {
      if (params.value) {
        return <CheckRoundedIcon />;
      } else {
        return <CloseRoundedIcon />;
      }
    },
  },
  {
    field: 'default',
    headerName: 'Дефолтный',
    width: 90,
    renderCell: params => {
      if (params.value) {
        return <CheckRoundedIcon />;
      } else {
        return <CloseRoundedIcon />;
      }
    },
  },
];
