import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { FieldModalForm } from './components';

const ORG_INTEGRA_FIELD_MODAL_ID = 'org-integra-field-modal-id';

const fieldModalConfig: TModalConfig = {
  id: ORG_INTEGRA_FIELD_MODAL_ID,
  name: ORG_INTEGRA_FIELD_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: FieldModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { ORG_INTEGRA_FIELD_MODAL_ID, fieldModalConfig };
