import { FC } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationsController } from '../../../../../../../controllers/organizations.controller';
import { FilterStore } from '../../../../../../../stores/organizations/filter.store';
import {
  TypeOrganizationStatus,
  EFormFields,
} from '../../../../../../../../api/models/organization.model';

export const OrganizationsFilter: FC = observer(() => {
  const { setFilterNameOrInn, setFilterStatus, setFilterId } = useStore(OrganizationsController);
  const {
    statusOptions,
    filters: { nameOrInn, status, id },
  } = useStore(FilterStore);

  const handleStatusChange = (_, value: TypeOrganizationStatus) => setFilterStatus(value);

  const handleNameOrInnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterNameOrInn(event.target.value);
  };

  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterId(event.target.value);
  };

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={EFormFields.nameOrInn}
          variant="outlined"
          placeholder="Введите название или ИНН организации"
          label="Поиск по названию или ИНН"
          value={nameOrInn}
          onChange={handleNameOrInnChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CorporateFareIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={EFormFields.id}
          variant="outlined"
          placeholder="Введите id организации"
          label="Поиск по id"
          value={id}
          onChange={handleIdChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CorporateFareIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          disableClearable
          options={statusOptions}
          value={toJS(status)}
          getOptionLabel={(option: TypeOrganizationStatus) => option.statusName}
          isOptionEqualToValue={(option, value) => option.statusId === value.statusId}
          noOptionsText="Статусов не найдено"
          renderInput={params => <TextField {...params} variant="outlined" label="Статус" />}
          onChange={handleStatusChange}
        />
      </Grid>
    </Grid>
  );
});
