import { IOrgIntegraAgrosignalErrorFilePush } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalErrorFile/models/orgIntegraAgrosignalErrorFile';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraAgrosignalErrorFilePush>;

type TypeResponse = string;

type TypeError = {};

export const pushOrgIntegraAgrosignalFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/pushFile`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
