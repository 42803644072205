import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Colors } from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { FormikHelpers, useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { toJS } from 'mobx';

import 'moment/locale/ru';

import {
  EReportConfigDecimalSeparator,
  EReportConfigDelimiter,
  ITaskReportConfig,
} from '../../../../../../../../../../api/models/reportConfig.model';
import { useStore } from '../../../../../../../../../shared/utils';
import { OrganizationReportAssistanceTaskStore } from '../../mobx';
import TASK_REPORT_SCHEME from '../../../../configs/taskReportValidation.scheme';
import useFormActions from '../../hooks/useFormActions';

interface IProps {
  config: Partial<ITaskReportConfig>;
  onOpenHistory: (id: string) => void;
}

const OrganizationReportsAssistanceForm: FC<IProps> = ({ config, onOpenHistory }) => {
  const store = useStore(OrganizationReportAssistanceTaskStore);

  const [isCollapsed, setIsCollapsed] = useState(config?.state === 'update');

  const { organizationId } = useParams<{ organizationId?: string }>();

  const actions = useFormActions({ organizationId, defaultConfig: config });

  const onSubmit = (
    values: ITaskReportConfig,
    formikHelpers: FormikHelpers<Partial<ITaskReportConfig>>
  ) => {
    actions.submitConfig(values);
  };

  const formik = useFormik<Partial<ITaskReportConfig>>({
    initialValues: config,
    onSubmit,
    validationSchema: TASK_REPORT_SCHEME,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const onCollapse = () => {
    setIsCollapsed(true);
    // formik.setValues(config);
  };

  const onDelete = () => {
    if (config?.state === 'update') {
      actions.deleteConfig();
    }
    if (config?.state === 'create') {
      store.deleteConfigById(config.id);
    }
  };

  const years = useMemo<number[]>(() => {
    const currentYear = moment().add(20, 'y').get('year');
    const _years = [];

    for (let i = 0; i < 41; i++) {
      _years.unshift(currentYear - i);
    }

    return _years;
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: Colors.secondaryGray,
        padding: '12px',
        borderRadius: 2,
        position: 'relative',
      }}
    >
      {isCollapsed ? (
        <Grid item xs={5}>
          <Box
            display={'flex'}
            gap={2}
            alignItems={'center'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            position={'relative'}
          >
            <Tooltip title="Развернуть">
              <IconButton>
                <AspectRatioIcon />
              </IconButton>
            </Tooltip>

            <Box display={'flex'} flexDirection={'column'}>
              <Typography>
                <b>Автоматическая:</b>
                {formik.values?.enabled ? ' да' : ' нет'}
              </Typography>

              <Typography>
                <b>Даты выгрузок: </b>c {formik?.values?.dateFrom ?? 'Не указано'} по{' '}
                {formik?.values?.dateTo ?? 'Не указано'}
              </Typography>

              <Typography>
                <b>Сезоны: </b>
                {formik?.values?.seasons?.length > 0
                  ? toJS(formik?.values?.seasons).sort().join(', ')
                  : 'Не указано'}
              </Typography>
              <Typography fontSize={12}>
                <b>ID: </b>
                {!formik?.values?.id || formik?.values?.id === ''
                  ? 'Не указано'
                  : formik?.values?.id}
              </Typography>
            </Box>
            {formik.values.state === 'create' && (
              <Tooltip title="Эта запись ещё не сохранена" placement="top">
                <FiberNewIcon sx={{ position: 'absolute', top: '-10px', left: '8px' }} />
              </Tooltip>
            )}
          </Box>
        </Grid>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Autocomplete
                options={years}
                value={formik.values.seasons}
                multiple
                disableCloseOnSelect
                getOptionLabel={option => String(option)}
                onOpen={() => {
                  setTimeout(() => {
                    const optionEl = document.querySelector(
                      `#report-task-years-${moment().get('year')}`
                    );
                    optionEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }, 1);
                }}
                onChange={(e, value) => {
                  formik.setFieldValue('seasons', value);
                  store.addTouchedConfigFields(config.id, 'seasons');
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} id={`report-task-years-${option}`}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Сезон"
                    placeholder="Выберите сезоны"
                    error={Boolean(formik.errors?.seasons)}
                    helperText={formik.errors?.seasons}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <Tooltip title="Свернуть">
                <IconButton onClick={onCollapse}>
                  <AspectRatioIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Разделитель параметров</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  label="Разделитель параметров"
                  value={formik.values?.delimiter}
                  onChange={e => formik.setFieldValue('delimiter', e.target.value)}
                >
                  <MenuItem value={EReportConfigDelimiter.COMMA}>Запятая (,)</MenuItem>
                  <MenuItem value={EReportConfigDelimiter.TAB}>Таб (↹)</MenuItem>
                  <MenuItem value={EReportConfigDelimiter.SEMICOLON}>Точка с запятой (;)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Разделитель дробной части в числах</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  label="Разделитель дробной части в числах"
                  value={formik.values?.decimalSeparator}
                  onChange={e => formik.setFieldValue('decimalSeparator', e.target.value)}
                >
                  <MenuItem value={EReportConfigDecimalSeparator.COMMA}>Запятая (,)</MenuItem>
                  <MenuItem value={EReportConfigDecimalSeparator.DOT}>Точка (.)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="withId"
                    checked={Boolean(formik.values.withId)}
                    onChange={(e, value) => {
                      formik.setFieldValue('withId', value);
                    }}
                  />
                }
                label="Выводить ID"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    name="enabled"
                    checked={Boolean(formik.values.enabled)}
                    onChange={(e, value) => {
                      actions.toggleAutoUpload?.(
                        formik?.values?.id,
                        value,
                        config.state === 'create'
                      );

                      formik.setFieldValue('enabled', value);
                    }}
                  />
                }
                label="Автоматическая выгрузка"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Путь до папки автоматической выгрузки клиента"
                onChange={event => formik.setFieldValue('reportsLocation', event.target.value)}
                value={formik.values.reportsLocation}
                fullWidth
                error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
                helperText={formik.errors?.reportsLocation}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Путь до папки выгрузки фотоматериалов"
                onChange={event => formik.setFieldValue('filesLocation', event.target.value)}
                value={formik.values.filesLocation}
                fullWidth
                error={formik.touched.filesLocation && Boolean(formik.errors?.filesLocation)}
                helperText={formik.errors?.filesLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
              <Grid item xs={5}>
                <DatePicker
                  value={formik?.values?.dateFrom}
                  onChange={(date: Moment) => {
                    if (date) formik.setFieldValue('dateFrom', date?.toDate());
                  }}
                  maxDate={formik?.values?.dateTo ? moment(formik?.values?.dateTo) : null}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label="Дата начала автоматической выгрузки отчёта"
                      error={formik.touched.dateFrom && Boolean(formik.errors?.dateFrom)}
                      helperText={formik.errors?.dateFrom}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  value={formik?.values?.dateTo}
                  onChange={(date: Moment) => {
                    if (date) formik.setFieldValue('dateTo', date?.toDate());
                  }}
                  minDate={formik?.values?.dateFrom ? moment(formik?.values?.dateFrom) : null}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label="Дата окончания автоматической выгрузки отчёта"
                      error={formik.touched.dateTo && Boolean(formik.errors?.dateTo)}
                      helperText={formik.errors?.dateTo}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button onClick={formik.submitForm} variant="contained" fullWidth>
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button
                disabled={formik.values.state === 'create'}
                onClick={() => onOpenHistory(config.id)}
                color="info"
                variant="text"
              >
                История
              </Button>
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button onClick={onDelete} color="error" variant="text">
                Удалить
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default OrganizationReportsAssistanceForm;
