import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<{
  userId: string;
  assigneeId: number;
  assigneeName: string;
  assigneePassword: string;
  organizationId: string;
  isIntegrated: boolean;
}> & { id: string };

type TypeResponse = string;

type TypeError = {};

export const updateOrgIntegraCropwiseEmployee: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: TypeRequest) => `/api/integra-cw/admin/mapping/employee/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
