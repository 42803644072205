import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { EFormulaDependencyType, EFormulaFunctionAggregation, EFormulaType } from '../models';

export const FUNCTION_OPTION_LIST_LOGIC: ISelectOption[] = [
  {
    label: `${EFormulaFunctionAggregation.AND} / Логическое И`,
    value: EFormulaFunctionAggregation.AND,
  },
  {
    label: `${EFormulaFunctionAggregation.OR} / Логическое ИЛИ`,
    value: EFormulaFunctionAggregation.OR,
  },
  {
    label: `${EFormulaFunctionAggregation.XOR} / Исключающее ИЛИ`,
    value: EFormulaFunctionAggregation.XOR,
  },
  {
    label: `${EFormulaFunctionAggregation.EQ} / Равенство`,
    value: EFormulaFunctionAggregation.EQ,
  },
  {
    label: `${EFormulaFunctionAggregation.GT} / Больше чем`,
    value: EFormulaFunctionAggregation.GT,
  },
  {
    label: `${EFormulaFunctionAggregation.LT} / Меньше чем`,
    value: EFormulaFunctionAggregation.LT,
  },
  {
    label: `${EFormulaFunctionAggregation.GOE} / Больше или равно`,
    value: EFormulaFunctionAggregation.GOE,
  },
  {
    label: `${EFormulaFunctionAggregation.LOE} / Меньше или равно`,
    value: EFormulaFunctionAggregation.LOE,
  },
  {
    label: `${EFormulaFunctionAggregation.NE} / Не равно`,
    value: EFormulaFunctionAggregation.NE,
  },
  {
    label: `${EFormulaFunctionAggregation.NOT} / Инверсия результата`,
    value: EFormulaFunctionAggregation.NOT,
  },
];

export const FUNCTION_OPTION_LIST_MATH: ISelectOption[] = [
  {
    label: `${EFormulaFunctionAggregation.AVG} / Среднее`,
    value: EFormulaFunctionAggregation.AVG,
  },
  {
    label: `${EFormulaFunctionAggregation.MULT} / Умножение`,
    value: EFormulaFunctionAggregation.MULT,
  },
  {
    label: `${EFormulaFunctionAggregation.ADD} / Сложение`,
    value: EFormulaFunctionAggregation.ADD,
  },
  {
    label: `${EFormulaFunctionAggregation.SUBTRACT} / Вычитание`,
    value: EFormulaFunctionAggregation.SUBTRACT,
  },
  {
    label: `${EFormulaFunctionAggregation.DIVISION} / Деление`,
    value: EFormulaFunctionAggregation.DIVISION,
  },
  {
    label: `${EFormulaFunctionAggregation.PERCENT} / Процент`,
    value: EFormulaFunctionAggregation.PERCENT,
  },
];

export const FUNCTION_OPTION_LIST: ISelectOption[] = [
  ...FUNCTION_OPTION_LIST_LOGIC,
  ...FUNCTION_OPTION_LIST_MATH,
];

export const FORMULA_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Attribute, value: EFormulaType.Attribute },
  { label: EFormulaType.Const, value: EFormulaType.Const },
  { label: EFormulaType.Function, value: EFormulaType.Function },
  { label: EFormulaType.Property, value: EFormulaType.Property },
];

export const FORMULA_ROOT_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Function, value: EFormulaType.Function },
];

export const FORMULA_DEP_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Dependency, value: EFormulaType.Dependency },
];

export const DEPENDENCY_TYPE_OPTION_LIST: ISelectOption[] = [
  { value: EFormulaDependencyType.Attribute, label: EFormulaDependencyType.Attribute },
  { value: EFormulaDependencyType.CultureTask, label: EFormulaDependencyType.CultureTask },
  {
    value: EFormulaDependencyType.OperationTypeTask,
    label: EFormulaDependencyType.OperationTypeTask,
  },
  {
    value: EFormulaDependencyType.PhenophaseTask,
    label: EFormulaDependencyType.PhenophaseTask,
  },
];

export const FORMULA_ODZ_FORMULA_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Function, value: EFormulaType.Function },
];

export const ODZ_FORMULA_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Const, value: EFormulaType.Const },
  { label: EFormulaType.Function, value: EFormulaType.Function },
  { label: EFormulaType.SelfValue, value: EFormulaType.SelfValue },
];

export const ODZ_CHECKLIST_ATTRIBUTE_FORMULA_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaType.Attribute, value: EFormulaType.Attribute },
  { label: EFormulaType.Const, value: EFormulaType.Const },
  { label: EFormulaType.Function, value: EFormulaType.Function },
  { label: EFormulaType.Property, value: EFormulaType.Property },
  { label: EFormulaType.SelfValue, value: EFormulaType.SelfValue },
];

export const FORMULA_ONLY_FUNCTION_LIST: ISelectOption[] = [
  { label: EFormulaType.Function, value: EFormulaType.Function },
];

export const MATH_LOGIC_FUNCTION_OPTION_LIST_LOGIC: ISelectOption[] = [
  {
    label: `${EFormulaFunctionAggregation.EQ} / Равенство`,
    value: EFormulaFunctionAggregation.EQ,
  },
  {
    label: `${EFormulaFunctionAggregation.GT} / Больше чем`,
    value: EFormulaFunctionAggregation.GT,
  },
  {
    label: `${EFormulaFunctionAggregation.LT} / Меньше чем`,
    value: EFormulaFunctionAggregation.LT,
  },
  {
    label: `${EFormulaFunctionAggregation.GOE} / Больше или равно`,
    value: EFormulaFunctionAggregation.GOE,
  },
  {
    label: `${EFormulaFunctionAggregation.LOE} / Меньше или равно`,
    value: EFormulaFunctionAggregation.LOE,
  },
  {
    label: `${EFormulaFunctionAggregation.NE} / Не равно`,
    value: EFormulaFunctionAggregation.NE,
  },
  {
    label: `${EFormulaFunctionAggregation.NOT} / Инверсия результата`,
    value: EFormulaFunctionAggregation.NOT,
  },
];
