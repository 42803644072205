export enum EFilterFormActivity {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  None = 'None',
}

export enum EFilterFromType {
  Tips = 'TIPS',
  Modal = 'MODAL',
  All = 'All',
}

export interface IFilterForm {
  path: string;
  parentPath: string;
  activity: EFilterFormActivity;
  type: EFilterFromType;
  empty: boolean;
  page: number;
  size: number;
}
