import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = {
  cultureId: string;
  cultureName: string;
  cropwiseCropId: number;
  cropwiseHeader: string;
  organizationId: string;
};

type TypeResponse = string;

type TypeError = {};

export const createOrgIntegraCropwiseCulture: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/mapping/culture/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
