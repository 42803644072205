import { useModal } from '@farmlink/farmik-ui';
import { Button, Divider } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { isNil, orderBy } from 'lodash';

import { ChecklistCRUDController } from '../../../../../../../controllers/checklist.CRUD.controller';
import { useStore } from '../../../../../../../shared/utils';
import { ChecklistsAttributeController } from '../../../../../../../controllers/checklistAttribute.controller';
import { ChecklistAttributesStore } from '../../../../../../../stores/checkLists/attributes/checklistAttributes.store';
import { IChecklistAttribute } from '../../../../../../../../api/models/checklist.model';

import { StageList } from './components';
import {
  createAttributeModalConfig,
  createStageModalConfig,
  CREATE_STAGE_MODAL,
  CREATE_ATTRIBUTE_MODAL,
} from './modals';
import Attribute from './components/Attribute/Attribute';

const Attributes = observer(() => {
  const { registerModalList, openModalByModalId } = useModal();

  const {
    currentChecklistStageList,
    setStageCreationMode,
    clearStageModal,
    clearAttributeTab,
    currentChecklistId,
    currentChecklistPropertySwitches,
  } = useStore(ChecklistCRUDController);
  const {
    getChecklistAttributeList,
    setChecklistAttributeListWithoutStage,
    checklistAttributeListWithoutStage,
  } = useStore(ChecklistsAttributeController);
  const { addCurrentChecklistBasicAttributeIdListByStage } = useStore(ChecklistAttributesStore);

  const hasAttributesWithoutStage = checklistAttributeListWithoutStage?.length > 0;
  const isNested = currentChecklistPropertySwitches?.nested;

  useEffect(() => {
    registerModalList([createStageModalConfig, createAttributeModalConfig], 'stageCreationModals');

    if (currentChecklistId && isNested) {
      getChecklistAttributeList({ checkListId: currentChecklistId }).then(data => {
        setChecklistAttributeListWithoutStage(data.content);

        addCurrentChecklistBasicAttributeIdListByStage(
          'noStage',
          data.content
            .filter(attribute => isNil(attribute?.stageId) && attribute?.isActive)
            .flatMap(attribute => attribute.attribute.id)
        );
      });
    }

    return () => {
      clearAttributeTab();
    };
  }, []);

  const attributesWithoutStageList = useMemo(
    () => orderBy(checklistAttributeListWithoutStage, ['order', 'id']),
    [checklistAttributeListWithoutStage]
  );

  const parentMap = useMemo(() => {
    const newParentMap = new Map<string, IChecklistAttribute[]>();

    attributesWithoutStageList?.forEach(attribute => {
      if (attribute?.position?.parentId) {
        const childArr = newParentMap.get(attribute.position.parentId) || [];

        newParentMap.set(attribute.position.parentId, [...childArr, attribute]);
      }
    });

    return newParentMap;
  }, [attributesWithoutStageList]);

  return (
    <div>
      {isNested ? (
        <Button
          disabled={!currentChecklistId}
          onClick={() => {
            clearStageModal();
            openModalByModalId(CREATE_ATTRIBUTE_MODAL);
          }}
        >
          + Добавить атрибут
        </Button>
      ) : (
        <Button
          disabled={!currentChecklistId}
          onClick={() => {
            clearStageModal();
            openModalByModalId(CREATE_STAGE_MODAL);

            setStageCreationMode(true);
          }}
        >
          + Добавить группировку атрибутов
        </Button>
      )}
      <StageList stageList={currentChecklistStageList} />
      {hasAttributesWithoutStage && currentChecklistStageList.length ? (
        <Divider sx={{ margin: '12px 0' }} />
      ) : null}
      {hasAttributesWithoutStage &&
        attributesWithoutStageList.map(data => {
          if (data?.position?.parentId) {
            return null;
          }

          return <Attribute key={data.id} data={data} nestedIndex={0} parentMap={parentMap} />;
        })}
    </div>
  );
});

export default Attributes;
