import { Moment } from 'moment';

export enum EUserAdminStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
}

export enum EUserAdminGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum EUserAdminRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  UNDEFINED = 'UNDEFINED',
  ANONYMOUS = 'ANONYMOUS',
  SERVICE = 'SERVICE',
  TEST = 'TEST',
  ACTUATOR = 'ACTUATOR',
  TEMPORARY = 'TEMPORARY',
}

export enum EUserAdminEmailStatus {
  NOTCONFIRMED = 'NOT_CONFIRMED',
  REQUESTSENT = 'REQUEST_SENT',
  CONFIRMED = 'CONFIRMED',
}

export enum EUserAdminPhoneStatus {
  NOTCONFIRMED = 'NOT_CONFIRMED',
  REQUESTSENT = 'REQUEST_SENT',
  CONFIRMED = 'CONFIRMED',
}

export enum EUserAdminResetPasswordStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum EPlatform {
  assistant = 'assistant',
  farmlink = 'farmlink',
}

// Докер-работай

export type TypeUserAdminSort = {
  field: string;
  sort: string;
};

export type FormValueError = {
  source: string;
  title: string;
};

export type TypeUserAdminRole = { roleId: EUserAdminRole; roleName: string };
export type TypeUserAdminStatus = { statusId: EUserAdminStatus; statusName: string };
export type TypeUserAdminGender = { genderId: EUserAdminGender; genderName: string };

export interface Interest {
  interestId: string;
  interestName: string;
}

export interface Expertise {
  id: string;
  expertiseId: string;
  expertiseName: string;
  confirmations: number;
  confirmedByMe: boolean;
}

export interface PhoneNumberStatus {
  statusId: EUserAdminPhoneStatus;
  statusName: string;
}

export interface Phone {
  phoneNumberCode: string;
  phoneNumber: string;
  phoneNumberStatus: PhoneNumberStatus;
}

export interface EmailStatus {
  statusId: EUserAdminEmailStatus;
  statusName: string;
}

export interface Email {
  email: string;
  emailStatus: EmailStatus;
}

export interface Gender {
  genderEnum: string;
  genderShort: string;
  genderName: string;
}

export interface Avatar {
  id: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  downloadUrl: string;
}

export type TypeUserAdmin = {
  id: string;
  publicId: string;
  firstName: string;
  secondName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  bio: string;
  phone: Phone;
  email: Email;
  role: TypeUserAdminRole;
  status: string;
  gender: Gender;
  dateOfBirth: string;
  regDate: string;
  interests: Interest[];
  expertises: Expertise[];
  avatar: Avatar;
  agreement: boolean;
  agreementDate: string;
  includedInStats: boolean;
  scout?: boolean;
  experiment?: boolean;
};

export const initialUserAdminRole: TypeUserAdminRole[] = [
  {
    roleId: EUserAdminRole.ADMINISTRATOR,
    roleName: 'Администратор',
  },
  {
    roleId: EUserAdminRole.UNDEFINED,
    roleName: 'Нет роли',
  },
  {
    roleId: EUserAdminRole.ANONYMOUS,
    roleName: 'Анонимный',
  },
  {
    roleId: EUserAdminRole.SERVICE,
    roleName: 'Сервисный',
  },
  {
    roleId: EUserAdminRole.TEST,
    roleName: 'Тестовый',
  },
  {
    roleId: EUserAdminRole.ACTUATOR,
    roleName: 'Актуализатор',
  },

  {
    roleId: EUserAdminRole.TEMPORARY,
    roleName: 'Временный',
  },
];

export const initialUserAdminStatus: TypeUserAdminStatus[] = [
  {
    statusId: EUserAdminStatus.ACTIVE,
    statusName: 'Активный',
  },
  {
    statusId: EUserAdminStatus.BANNED,
    statusName: 'Забанен',
  },
];

export const initialUserAdminGender: TypeUserAdminGender[] = [
  {
    genderId: EUserAdminGender.MALE,
    genderName: 'Мужской',
  },
  {
    genderId: EUserAdminGender.FEMALE,
    genderName: 'Женский',
  },
];

export enum EFormFields {
  lastName = 'lastName',
  firstName = 'firstName',
  secondName = 'secondName',
  fullName = 'fullName',
  gender = 'gender',
  role = 'role',
  dateOfBirth = 'dateOfBirth',
  countryCode = 'countryCode',
  phoneNumber = 'phoneNumber',
  phoneNum = 'phoneNum',
  password = 'password',
  smsCode = 'smsCode',
  typeSubmit = 'typeSubmit',
  email = 'email',
  avatar = 'avatar',
  includedInStats = 'includedInStats',
  domain = 'domain',
}

export interface IFormEditValues {
  [EFormFields.lastName]: string;
  [EFormFields.firstName]: string;
  [EFormFields.secondName]: string;
  [EFormFields.gender]: TypeUserAdminGender;
  [EFormFields.dateOfBirth]: Moment;
  [EFormFields.phoneNumber]: string;
  [EFormFields.email]: string;
  [EFormFields.avatar]: string;
}

export interface IFormCreateValues {
  [EFormFields.lastName]: string;
  [EFormFields.firstName]: string;
  [EFormFields.secondName]: string;
  [EFormFields.phoneNum]: string;
  [EFormFields.email]: string;
  [EFormFields.password]: string;
  [EFormFields.role]: TypeUserAdminRole;
  [EFormFields.gender]: TypeUserAdminGender;
  [EFormFields.dateOfBirth]: Moment;
  [EFormFields.avatar]: string;
  [EFormFields.includedInStats]: boolean;
  [EFormFields.domain]: string;
}
