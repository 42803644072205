import { TypeApiRoute } from '../../models/type.api.request';
import { RoleInfo } from '../../models/employee.model';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = [{ role: RoleInfo }];

type TypeError = {};

export const getOrganizationUserRoles: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: '/api/da-profile/access/role/list',
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
