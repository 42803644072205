import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { FC, useRef, useState } from 'react';

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

interface IProps {
  /**
   * Первый элемент - основная кнопка.
   *
   * @param isHidden первого элемента игнорируется
   */
  buttonList: Array<{
    label: string;
    onClickHandler: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
    dataTestId?: string;
  }>;
}

export const SplitDropdownButton: FC<IProps> = ({ buttonList }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const isSingleButton =
    buttonList.length === 1 || buttonList.filter(button => !button.isHidden).length === 1;

  const buttonComponentList = buttonList.map((option, index) =>
    index === 0 ? null : option.isHidden ? null : (
      <Button
        variant="contained"
        onClick={() => {
          buttonList[index]?.onClickHandler();
          setOpen(false);
        }}
        disabled={buttonList[index]?.isDisabled}
        data-test-id={buttonList[index]?.dataTestId ?? `split-dropdown-button-${index}`}
      >
        {buttonList[index]?.label}
      </Button>
    )
  );

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          onClick={buttonList[0]?.onClickHandler}
          disabled={buttonList[0]?.isDisabled}
          data-test-id={buttonList[0]?.dataTestId ?? 'split-dropdown-button-0'}
        >
          {buttonList[0]?.label}
        </Button>
        {!isSingleButton && (
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      {!isSingleButton && (
        <Popper
          sx={{
            zIndex: 1000,
          }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ padding: '0 8px' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem>{buttonComponentList}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default SplitDropdownButton;
