import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Content = styled.div`
  min-height: 200px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.generalDark};
  white-space: pre-wrap;
`;

const Styled = {
  Wrapper,
  Content,
  Row,
  Title,
};

export default Styled;
