import { EOrgIntegraFieldDataRefresh } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalFields/models';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = {
  organizationIds: string[];
  dataRefresh: EOrgIntegraFieldDataRefresh;
};

type TypeResponse = [
  {
    organizationId: string;
    ids: string[];
  }
];

type TypeError = {};

export const getOrgIntegraAgrosignalAgroFields: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/getAgrosFields`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
