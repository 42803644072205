import moment from 'moment';

import { TypeQuote } from '../../../../../../../api/models/quotes.model';

export const getRows = (quotes: TypeQuote[]) =>
  quotes.map(item => ({
    id: item.id,
    culture: item.culture?.name || '',
    class: item.cultureClass?.name || '',
    basis: item.basis?.name || '',
    region: item.economicRegion?.name || '',
    date: item.dateOfObs && moment(item.dateOfObs).format('DD.MM.YYYY'),
    units: item.units?.name || '',
    cost: item.price || '',
  }));
