import { orderBy, uniq, has, isEmpty } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { ChangeEvent } from 'react';
import { v4 } from 'uuid';

import { ChecklistService } from '../services';
import { lazyInject, provide } from '../shared/utils';
import {
  IChecklist,
  EChecklistTabs,
  IChecklistSettingsEntity,
  IChecklistSettingsEntityShort,
  IChecklistOrganizationItem,
  EChecklistType,
} from '../../api/models/checklist.model';
import {
  ChecklistCRUDStore,
  ICurrentEditChecklistAttributeWithBaseAttribute,
} from '../stores/checkLists/checklist.CRUD.store';
import { TypeOrganization } from '../../api/models/organization.model';
import { IChecklistStage } from '../../api/models/checklist.stage.model';

import { ChecklistAttributesStore } from './../stores/checkLists/attributes/checklistAttributes.store';
import { OrganizationsController } from './organizations.controller';

@provide.singleton()
export class ChecklistCRUDController {
  @lazyInject(ChecklistCRUDStore)
  protected checklistCRUDStore: ChecklistCRUDStore;

  @lazyInject(ChecklistService)
  protected checklistService: ChecklistService;

  @lazyInject(ChecklistAttributesStore)
  protected checklistAttributesStore: ChecklistAttributesStore;

  @lazyInject(OrganizationsController)
  protected organizationsController: OrganizationsController;

  constructor() {
    makeAutoObservable(this);
  }

  get currentChecklist() {
    return this.checklistCRUDStore?.currentChecklist;
  }

  get currentChecklistId() {
    return this.checklistCRUDStore?.currentChecklist?.id;
  }

  get currentChecklistSettingsList() {
    return this.checklistCRUDStore?.currentChecklist?.checkListSettings;
  }

  get currentChecklistOrganizationsList() {
    return this.checklistCRUDStore?.currentChecklist?.organizations;
  }

  get currentChecklistName() {
    return this.checklistCRUDStore?.currentChecklist?.name;
  }

  /**
   * Флаги отображают состояние поля `type`
   *
   * Используется для обратной совместимости с флагами
   *
   * @readonly
   * @memberof ChecklistCRUDController
   */
  get currentChecklistPropertySwitches() {
    return {
      machinery: this.checklistCRUDStore?.currentChecklist?.type === EChecklistType.Machinery,
      nested: this.checklistCRUDStore?.currentChecklist?.type === EChecklistType.Nested,
      field: this.checklistCRUDStore?.currentChecklist?.type === EChecklistType.Field,
      default: this.checklistCRUDStore?.currentChecklist?.default,
    };
  }

  get currentChecklistAvailableTabs(): Set<EChecklistTabs> {
    const checklist = this.checklistCRUDStore.currentChecklist;

    if (!checklist) {
      return new Set([
        EChecklistTabs.Settings,
        EChecklistTabs.Attributes,
        EChecklistTabs.Organizations,
      ]);
    }

    if (this.currentChecklistPropertySwitches.nested) {
      return new Set([EChecklistTabs.Attributes]);
    }

    if (
      this.currentChecklistPropertySwitches.machinery &&
      this.currentChecklistPropertySwitches.default
    ) {
      return new Set([EChecklistTabs.Attributes, EChecklistTabs.Settings]);
    }

    if (this.currentChecklistPropertySwitches.default) {
      return new Set([EChecklistTabs.Settings, EChecklistTabs.Attributes]);
    }

    return new Set([
      EChecklistTabs.Settings,
      EChecklistTabs.Attributes,
      EChecklistTabs.Organizations,
    ]);
  }

  get currentChecklistClientOrganizationList() {
    return this.checklistCRUDStore.currentChecklistOrganizationList;
  }

  get isEditChecklist() {
    return this.checklistCRUDStore.isEditMode;
  }

  get stageData() {
    return this.checklistCRUDStore.currentEditStage;
  }

  get currentChecklistStageList() {
    return orderBy(toJS(this.checklistCRUDStore.currentChecklistStageList), 'order');
  }

  get currentEditChecklistAttribute() {
    return this.checklistCRUDStore.currentEditChecklistAttribute;
  }

  get currentLoadedChecklist() {
    return this.checklistCRUDStore.currentLoadedChecklist;
  }

  createChecklistSettingsItem = (item: Partial<IChecklistSettingsEntity>) => {
    const isExist =
      this.currentChecklistSettingsList?.findIndex(settingItem => {
        const newItemHash = item.operationType.id + item.culture.id + item.phenophase.id;
        let elementHash;

        if (item?.id) {
          elementHash = item.operationType.id + item.culture.id + item.phenophase.id;
        } else {
          elementHash =
            // @ts-ignore
            settingItem.operationTypeId + settingItem.cultureId + settingItem.phenophaseId;
        }

        return newItemHash === elementHash;
      }) >= 0;

    if (isExist) {
      return;
    }

    this.checklistCRUDStore.changeCurrentChecklistValue(
      'checkListSettings',
      uniq([...(this.currentChecklistSettingsList || []), { ...item, id: v4() }])
    );
  };

  removeChecklistSettingsItem = (itemIdList: string[]) => {
    this.checklistCRUDStore.changeCurrentChecklistValue(
      'checkListSettings',
      this.currentChecklistSettingsList.filter(item => {
        if (item?.id) {
          return !itemIdList.includes((item as IChecklistSettingsEntity).id);
        } else {
          return !itemIdList.includes((item as IChecklistSettingsEntityShort)?.operationTypeId);
        }
      })
    );
  };

  changeCurrentChecklistBase = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.name === 'nested' && e.currentTarget?.checked) {
      this.checklistCRUDStore.changeCurrentChecklistValue('default', false);
      this.checklistCRUDStore.changeCurrentChecklistValue('machinery', false);
    }

    if (
      e.currentTarget?.name === 'default' &&
      e.currentTarget?.checked &&
      this.checklistCRUDStore.currentChecklist.type === EChecklistType.Nested
    ) {
      this.checklistCRUDStore.changeCurrentChecklistValue('type', null);
    }

    this.checklistCRUDStore.changeCurrentChecklistValue(
      e.currentTarget?.name as keyof IChecklist,
      e.currentTarget?.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value
    );
  };

  changeCurrentChecklistType = (e: ChangeEvent<HTMLInputElement>) => {
    this.checklistCRUDStore.changeCurrentChecklistValue('type', e.target?.value as EChecklistType);
  };

  createChecklistSettingsOrganization = (item: IChecklistOrganizationItem) => {
    if (
      !item ||
      this.currentChecklistOrganizationsList?.findIndex(
        value => value.organizationId === item.organizationId
      ) >= 0
    ) {
      return;
    }

    this.checklistCRUDStore.changeCurrentChecklistValue('organizations', [
      ...(this.currentChecklistOrganizationsList || []),
      { name: item.name, organizationId: item.organizationId, INN: item.INN },
    ]);

    this.checklistCRUDStore.setCurrentChecklistOrganizationList([
      ...(this.currentChecklistClientOrganizationList || []),
      item,
    ]);
  };

  removeChecklistSettingsOrganization = (idList: string[]) => {
    this.checklistCRUDStore.changeCurrentChecklistValue(
      'organizations',
      this.currentChecklistOrganizationsList?.filter(item => !idList.includes(item.organizationId))
    );

    this.checklistCRUDStore.setCurrentChecklistOrganizationList(
      this.currentChecklistClientOrganizationList.filter(
        item => !idList.includes(item.organizationId)
      )
    );
  };

  validateChecklist = (checklistData: Partial<IChecklist>, action: 'create' | 'change') => {
    const tabs = this.currentChecklistAvailableTabs;

    if (!checklistData?.name) {
      throw new Error('Укажите название чек-листа');
    }

    if (!checklistData?.type) {
      throw new Error('Укажите тип чек-листа');
    }

    switch (action) {
      case 'create':
      default:
        if (
          tabs.has(EChecklistTabs.Organizations) &&
          this.checklistCRUDStore?.currentChecklistOrganizationList?.length === 0
        ) {
          throw new Error('Добавьте как минимум одну организацию');
        }

        if (
          tabs.has(EChecklistTabs.Settings) &&
          (!checklistData?.checkListSettings || checklistData?.checkListSettings?.length < 1)
        ) {
          throw new Error('Добавьте как минимум одну настройку');
        }
        break;

      case 'change':
        if (!checklistData || isEmpty(checklistData)) {
          return Promise.reject(Error('Изменений в чек-листе нет'));
        }

        if (
          (checklistData.type as EChecklistType) !== EChecklistType.Nested &&
          !checklistData?.organizations.length &&
          !checklistData?.checkListSettings.length
        ) {
          return Promise.reject(
            Error('Чек-лист должен иметь хотя бы одну организацию и настройку')
          );
        }
        break;
    }

    return true;
  };

  createChecklist = () => {
    const checklistData = toJS(this.checklistCRUDStore.currentChecklist);

    this.validateChecklist(checklistData, 'create');

    return this.checklistService.createChecklist({
      name: checklistData.name,
      type: checklistData.type,
      checkListSettings:
        checklistData.checkListSettings?.map(
          (item: IChecklistSettingsEntity & IChecklistSettingsEntityShort) => ({
            cultureId: item?.cultureId || item?.culture?.id,
            operationTypeId: item?.operationTypeId || item?.operationType?.id,
            phenophaseId: item?.phenophaseId || item?.phenophase?.id || null,
          })
        ) || [],
      organizationIds: this.checklistCRUDStore.currentChecklistOrganizationList.flatMap(
        item => item.organizationId
      ),
      isDefault: checklistData?.default || false,
    });
  };

  changeChecklist = () => {
    const changedChecklist = { ...this.currentChecklist } as any;

    this.validateChecklist(changedChecklist, 'change');

    changedChecklist.organizationIds = changedChecklist?.organizations.flatMap(
      item => item.organizationId
    );

    changedChecklist.checkListSettings = changedChecklist.checkListSettings.map(item => {
      return {
        operationTypeId: item.operationType.id,
        cultureId: item.culture.id,
        ...(item.phenophase !== null ? { phenophaseId: item.phenophase.id } : {}),
      };
    });

    if ((changedChecklist as Partial<IChecklist>)?.default) {
      changedChecklist.isDefault = (changedChecklist as Partial<IChecklist>)?.default;
    }

    if ((changedChecklist?.type as EChecklistType) === EChecklistType.Nested) {
      changedChecklist.checkListSettings = [];
      changedChecklist.organizationIds = [];
      changedChecklist.organizations = [];
    }

    return this.checklistService.changeChecklist({
      ...changedChecklist,
      id: this.currentChecklist.id,
    });
  };

  changeChecklistActivity = () => {
    return this.checklistService
      .changeChecklist({
        id: this.currentChecklist.id,
        isActive: !this.currentChecklist.active,
      })
      .then(() => {
        this.checklistCRUDStore.changeCurrentChecklistValue(
          'active',
          !this.currentChecklist.active
        );
      });
  };

  setEditMode = (state: boolean) => {
    this.checklistCRUDStore.setEditMode(state);
  };

  setCurrentLoadedChecklist = (checklist: IChecklist) => {
    this.checklistCRUDStore.setCurrentLoadedChecklist(checklist);
  };

  getChecklistById = (id: string) => {
    return this.checklistService.getChecklist({ id });
  };

  fetchChecklistById = (id: string) => {
    return this.checklistService.getChecklist({ id }).then(data => {
      this.checklistCRUDStore.setCurrentChecklist(data);
      this.checklistCRUDStore.setCurrentLoadedChecklist(data);

      if (data?.organizations?.length) {
        this.checklistCRUDStore.setCurrentChecklistOrganizationList(data?.organizations);

        this.checklistAttributesStore.setCurrentChecklistAttributeActiveOrg({
          label: data.organizations[0].name,
          value: data.organizations[0].organizationId,
        });
      }

      this.fetchChecklistStageList({ checklistId: id }).then(res => {
        this.checklistCRUDStore.setCurrentChecklistStageList(res);
      });

      return data;
    });
  };

  fetchOrganizationsDataByIdList = (idList: string[]): Promise<TypeOrganization[]> => {
    const result = Promise.all(
      idList.map(async id => {
        const data = await this.organizationsController.fetchOrganization({ organizationId: id });
        return data;
      })
    );

    return result;
  };

  changeCurrentEditStage = <K extends keyof typeof this.stageData>(
    field: K,
    value: typeof this.stageData[K]
  ) => {
    this.checklistCRUDStore.changeCurrentEditStage(field, value);
  };

  changeStageData = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    this.checklistCRUDStore.changeCurrentEditStage(
      target.name as keyof IChecklistStage,
      target.value
    );
  };

  setStageCreationMode = (state: boolean) => {
    this.checklistCRUDStore.changeCurrentEditStage('isCreationMode', state);
  };

  setCurrentStageData = (stageData: IChecklistStage) => {
    this.checklistCRUDStore.setCurrentEditStage(stageData);
  };

  checkStageValidity = () => {
    if (!this.checklistCRUDStore?.currentEditStage?.order) {
      return Promise.reject(Error('Укажите порядковый номер'));
    }

    if (!this.checklistCRUDStore?.currentEditStage?.name) {
      return Promise.reject(Error('Укажите наименование группировки'));
    }

    if (
      this.checklistCRUDStore.currentChecklistStageList.findIndex(
        stage =>
          Number(stage.order) === Number(this.checklistCRUDStore.currentEditStage.order) &&
          stage?.id !== this.checklistCRUDStore.currentEditStage?.id
      ) >= 0
    ) {
      return Promise.reject(Error('Порядковый номер уже занят'));
    }
  };

  createChecklistStage = () => {
    const validationError = this.checkStageValidity();

    if (validationError) {
      return validationError;
    }

    const payload = {
      ...this.checklistCRUDStore.currentEditStage,
      order: Number(this.checklistCRUDStore.currentEditStage.order),
      checklistId: this.checklistCRUDStore?.currentChecklist?.id || null,
    };

    delete payload.isCreationMode;

    return this.checklistService.createChecklistStage(payload).then(data => {
      this.addStageToCurrentChecklistStageList(data);
      this.checklistCRUDStore.clearCurrentEditStage();
    });
  };

  changeChecklistStage = () => {
    const validationError = this.checkStageValidity();

    if (validationError) {
      return validationError;
    }

    const payload = { ...this.checklistCRUDStore.currentEditStage };

    if (typeof payload?.picLinkDefault === 'object') {
      payload.picLinkDefault = payload.picLinkDefault.id;
    }

    if (typeof payload?.picLinkError === 'object') {
      payload.picLinkDefault = payload.picLinkError.id;
    }

    if (typeof payload?.picLinkSuccess === 'object') {
      payload.picLinkDefault = payload.picLinkSuccess.id;
    }

    return this.checklistService
      .changeChecklistStage({
        ...payload,
        order: Number(this.checklistCRUDStore.currentEditStage.order),
      })
      .then(data => {
        const changedStageIndex = this.checklistCRUDStore.currentChecklistStageList.findIndex(
          stage => stage.id === data.id
        );

        if (changedStageIndex >= 0) {
          const changedStages = [...toJS(this.checklistCRUDStore.currentChecklistStageList)];
          changedStages[changedStageIndex] = data;

          this.checklistCRUDStore.setCurrentChecklistStageList(changedStages);
        }
      });
  };

  addStageToCurrentChecklistStageList = (stage: IChecklistStage) => {
    return this.checklistCRUDStore.addStageToCurrentChecklistStageList(stage);
  };

  fetchChecklistStageList = (payload: { checklistId: string }) => {
    return this.checklistService.getChecklistStageList(payload);
  };

  deleteChecklistStage = (id: string) => {
    return this.checklistService.deleteChecklistStage({ id }).then(() => {
      this.checklistCRUDStore.setCurrentChecklistStageList(
        this.checklistCRUDStore.currentChecklistStageList.filter(stage => stage.id !== id)
      );
    });
  };

  setCurrentEditChecklistAttribute = (data: ICurrentEditChecklistAttributeWithBaseAttribute) => {
    this.checklistCRUDStore.setCurrentEditChecklistAttribute(data);
  };

  copyChecklist = ({
    operationTypeId,
    cultureId,
    phenophaseId,
    type,
  }: {
    operationTypeId: string;
    cultureId: string;
    phenophaseId: string;
    type: EChecklistType;
  }) => {
    const payload =
      type === EChecklistType.Nested
        ? {
            data: {
              type,
            },
            id: this.currentChecklistId,
          }
        : {
            data: {
              type,
              settings: [{ operationTypeId, cultureId, phenophaseId, id: this.currentChecklistId }],
            },
            id: this.currentChecklistId,
          };

    return this.checklistService.copyChecklist(payload as any);
  };

  deleteChecklist = (id: string) => {
    return this.checklistService.deleteChecklist({ id });
  };

  clearAttributeTab = () => {
    this.checklistAttributesStore.clearChecklistAttributeListWithStages();
    this.checklistAttributesStore.clearChecklistAttributeListWithoutStage();
    this.checklistAttributesStore.clearCurrentChecklistAttributeEditFileList();
    this.checklistAttributesStore.clearCurrentChecklistAttributeUserDictionaryLinkValueList();

    this.checklistCRUDStore.clearCurrentEditStage();
    this.checklistCRUDStore.clearCurrentEditChecklistAttribute();
  };

  clearStageModal = () => {
    this.checklistCRUDStore.clearCurrentEditStage();
  };

  clearCreatingChecklist = () => {
    this.checklistCRUDStore.clearCurrentChecklist();
    this.checklistCRUDStore.clearCurrentChecklistOrganizationList();
  };
}
