import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';

import { useStore, isFormValuesErrors } from '../../../../../../../shared/utils';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../../../stores/users/users.store';
import { EUserAdminEmailStatus } from '../../../../../../../../api/models/user.admin.model';
import { ReactComponent as MailIco } from '../../assets/mail.svg';

interface IProps {
  userId: string;
  open: boolean;
  onClose: () => void;
}

enum EFormFields {
  email = 'email',
}

interface InitialValues {
  [EFormFields.email]: string;
}

export const UserEmailEditModal: FC<IProps> = observer(({ userId, open, onClose }) => {
  const { updateEmail } = useStore(UsersController);
  const { user, formValuesErrors, clearFormValuesErrors } = useStore(UsersStore);

  const handleSubmitForm = (values: InitialValues, action: FormikHelpers<InitialValues>): void => {
    updateEmail({
      userId,
      email: values[EFormFields.email],
      emailStatus: EUserAdminEmailStatus.CONFIRMED,
    })
      .then(() => {
        action.setStatus({
          type: 'success',
          messages: `На ваш E-mail ${values[EFormFields.email]} была отправлена ссылка на создание`,
        });
      })
      .finally(() => {
        action.setSubmitting(false);
      });
  };

  useEffect(() => {
    return () => {
      clearFormValuesErrors();
    };
  }, []);

  const schema = Yup.object().shape({
    [EFormFields.email]: Yup.string()
      .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
      .email('Невалидный адрес почты')
      .required('Поле обязательно для заполнения'),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        validationSchema={schema}
        initialValues={{
          email: user?.email?.email || '',
        }}
        onSubmit={handleSubmitForm}
      >
        {({ values, touched, errors, status, submitForm }) => {
          return (
            <>
              {status && status.type === 'success' ? (
                <>
                  <DialogTitle>
                    <MailIco />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText className="description">{status.messages}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose}>Хорошо</Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>Изменение email</DialogTitle>
                  <DialogContent>
                    <DialogContentText className="divider">
                      На указанный Вами email придет ссылка для подтверждения почты
                    </DialogContentText>
                    <Form>
                      <Field
                        component={TextField}
                        fullWidth
                        autoFocus
                        margin="dense"
                        name={EFormFields.email}
                        value={values[EFormFields.email]}
                        placeholder="Введите E-mail пользователя"
                        label="E-mail"
                        error={
                          (touched[EFormFields.email] && Boolean(errors[EFormFields.email])) ||
                          isFormValuesErrors(EFormFields.email, formValuesErrors)
                        }
                        helperText={
                          (touched[EFormFields.email] && errors[EFormFields.email]) ||
                          (isFormValuesErrors(EFormFields.email, formValuesErrors) &&
                            formValuesErrors[EFormFields.email])
                        }
                        type="email"
                        variant="outlined"
                      />
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={submitForm}>Продолжить</Button>
                    <Button onClick={onClose}>Отмена</Button>
                  </DialogActions>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
});
