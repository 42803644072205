import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../shared/utils';
import { IReference } from '../../../../../../../../api/models/reference.model';

@provide.singleton()
class ReferenceStore {
  private _referenceCollection: Map<string, IReference> = new Map();
  private _activeReference: IReference = null;
  private _activeEditorState = null;

  private _isPreventChangeRef = false;

  constructor() {
    makeAutoObservable(this);
  }

  get referenceList() {
    return Array.from(this._referenceCollection.values());
  }

  get activeReference() {
    return this._activeReference;
  }

  get activeEditorState() {
    return this._activeEditorState;
  }

  get isPreventChangeRef() {
    return this._isPreventChangeRef;
  }

  getReferenceByPath = (path: string) => {
    return this._referenceCollection.get(path);
  };

  setActiveReference = (ref: IReference) => {
    this.setIsPreventChangeRef(false);

    this._activeReference = ref;
  };

  setReferenceCollection = (refList: IReference[]) => {
    refList.forEach(item => this._referenceCollection.set(item.path, item));
  };

  addReferenceToCollection = (ref: IReference) => {
    this._referenceCollection.set(ref.path, ref);
  };

  setActiveEditorState = value => {
    if (!this.isPreventChangeRef) this.setIsPreventChangeRef(true);

    this._activeEditorState = value;
  };

  setIsPreventChangeRef = (state: boolean) => {
    this._isPreventChangeRef = state;
  };

  updateActiveReference = (value: Partial<IReference>) => {
    if (!this.isPreventChangeRef) this.setIsPreventChangeRef(true);

    this._activeReference = { ...this._activeReference, ...value };
  };

  clearReferenceCollection = () => {
    this._referenceCollection = new Map();
  };

  clearActiveReference = () => {
    this._activeReference = null;
  };

  clearEditorState = () => {
    this._activeEditorState = null;
  };

  clearIsPreventChangeRef = () => {
    this._isPreventChangeRef = false;
  };

  clearStore = () => {
    this.clearReferenceCollection();
    this.clearActiveReference();
    this.clearEditorState();
    this.clearIsPreventChangeRef();
  };
}

export default ReferenceStore;
