import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { toJS } from 'mobx';

import { ChecklistCRUDController } from '../../../../../../../controllers/checklist.CRUD.controller';
import { AutocompleteWithSearch } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils';
import { OrganizationsController } from '../../../../../../../controllers/organizations.controller';
import { TypeOrganization } from '../../../../../../../../api/models/organization.model';
import { getSubstringName } from '../../../../helpers/getSubstringName';
import { IChecklistOrganizationItem } from '../../../../../../../../api/models/checklist.model';

const Organizations = observer(() => {
  const {
    currentChecklistOrganizationsList,
    currentChecklistClientOrganizationList,
    createChecklistSettingsOrganization,
    removeChecklistSettingsOrganization,
  } = useStore(ChecklistCRUDController);
  const { fetchOrganizationListByParams } = useStore(OrganizationsController);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSettings, setSelectedSettings] = useState<GridSelectionModel>([]);

  const dataGridColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'INN', headerName: 'ИНН Организации', flex: 1 },
      {
        field: 'name',
        headerName: 'Наименование Организации',
        flex: 1,
        renderCell: params =>
          (params.value as string)?.includes(' / ') ? getSubstringName(params.value) : params.value,
      },
      { field: 'organizationId', headerName: 'ID Организации', flex: 1 },
    ],
    []
  );

  const dataGridRows = useMemo(() => [...(toJS(currentChecklistClientOrganizationList) || [])], [
    currentChecklistClientOrganizationList,
    currentChecklistOrganizationsList,
  ]);

  const removeSettingsItem = useCallback(
    () => removeChecklistSettingsOrganization(selectedSettings as string[]),
    [selectedSettings]
  );

  return (
    <div>
      <Box display="flex" gap={1}>
        <AutocompleteWithSearch
          selectedValue={selectedOrganization}
          setSelectedOption={setSelectedOrganization}
          fetchHandler={fetchOrganizationListByParams}
          additionalRequestPayload={{ page: 0, size: 20 }}
          searchPropertyKey={'nameOrInn'}
          responseMappingHandler={(data: TypeOrganization[]) =>
            data.map(item => ({
              label: `${item?.name ? `${item.name} ${item?.INN ? ' / ' : ''}` : ''}${
                item?.INN || ''
              }`,
              name: item.name,
              value: item.organizationId,
              INN: item.INN,
              organizationId: item.organizationId,
            }))
          }
          label="Наименование или ИНН"
          fullWidth
        />
        <TextField
          disabled
          label="ID организации"
          value={selectedOrganization?.value || ''}
          fullWidth
        />
        <Button
          onClick={() => {
            createChecklistSettingsOrganization(selectedOrganization);
            setSelectedOrganization(null);
          }}
          variant="contained"
          fullWidth
        >
          Добавить
        </Button>
      </Box>
      <Box display="flex" marginTop={1}>
        <Button variant="text" color="error" onClick={removeSettingsItem}>
          Удалить выбранное
        </Button>
      </Box>
      <div style={{ display: 'flex', height: '95%', minHeight: '500px' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={dataGridRows || []}
            columns={dataGridColumns}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            localeText={{ noRowsLabel: 'Нет результатов' }}
            checkboxSelection
            getRowId={(item: IChecklistOrganizationItem) => item.organizationId}
            onSelectionModelChange={newSelectionModel => {
              setSelectedSettings(newSelectionModel);
            }}
            selectionModel={selectedSettings}
          />
        </div>
      </div>
    </div>
  );
});

export default Organizations;
