import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, Button, Typography } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  EHasAdditionalAttribute,
  IAdvancedFilter,
} from '../../../../../../../../types/advancedFilter.types';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../shared/utils';
import { AdvancedFilterStore } from '../../../../../../../stores';
import { ISelectOptionExtended } from '../../../../../../../../types/selectOption';
import { IAttributeItem } from '../../../../../../../../api/models/attribute.model';

import getFilterFormConfig from './form/advancedFilterForm.config';
import { generateFilterForm } from './form/generateFilterForm';

type TAdvancedFilterProps = {
  goBackHandler: () => void;
};

export enum EInputType {
  String = 'String',
  Switch = 'Switch',
  Select = 'Select',
}

const AdvancedFilter: FC<TAdvancedFilterProps> = ({ goBackHandler }) => {
  const { name: remoteName } = useParams<{ name: string }>();
  const { getAttributeList, fetchDictionariesItemsByFilter } = useStore(DictionariesController);
  const { setFilterActivity, setFetchStatusResult } = useStore(AdvancedFilterStore);

  const [attributeList, setAttributeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: IAdvancedFilter = {
    hasAdditionalAttribute: { value: EHasAdditionalAttribute.NotSelected, label: 'Не выбрано' },
  };

  useEffect(() => {
    setFilterActivity(true);

    return () => {
      setFilterActivity(false);
    };
  });

  useEffect(() => {
    getAttributeList(remoteName).then(list => {
      setAttributeList(list);
    });
  }, [remoteName]);

  const onSubmit = e => {
    // e.preventDefault();
    fetchDictionariesItemsByFilter(remoteName, e).then(() => {
      setFetchStatusResult(true);
    });
  };

  const formik: FormikProps<IAdvancedFilter> = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const formConfig = useMemo(
    () =>
      getFilterFormConfig(attributeList, (value: ISelectOptionExtended<IAttributeItem>) => {
        formik.setFieldValue('additionalAttributeType', value?.rawData?.units);
      }),
    [initialValues, attributeList]
  );

  const handleGoBack = () => {
    formik.resetForm({});

    goBackHandler();
    setFetchStatusResult(false);
  };

  const handleReset = useCallback(() => {
    formik.resetForm({});
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 100);
  }, []);

  const filterForm = useMemo(
    () => generateFilterForm(formConfig, initialValues, onSubmit, formik, handleReset),
    [initialValues, onSubmit]
  );

  return (
    <Box>
      <Button size="small" onClick={handleGoBack} startIcon={<ChevronLeft />}>
        Назад
      </Button>
      <Typography variant="h6" align="center">
        Расширенный фильтр
      </Typography>
      <Box>{!isLoading && filterForm}</Box>
    </Box>
  );
};

export default AdvancedFilter;
