import {
  Autocomplete,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  TextField as MTextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { Colors } from '@farmlink/farmik-ui';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';
import { observer } from 'mobx-react';
import { get } from 'lodash';

import { FormRow } from '../../../../../../../../../common';
import {
  EChecklistAttributePositionWidth,
  EChecklistAttributeType,
} from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { TFormikDataType } from '../../models/form.model';
import { EPositionOptionProp } from '../../models/positionOptionConfig.model';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';

import Styled from './PositionOption.styles';
import { usePositionOption } from './hooks';

const WIDTH_OPTIONS: ISelectOption[] = [
  { label: EChecklistAttributePositionWidth.Full, value: EChecklistAttributePositionWidth.Full },
  { label: EChecklistAttributePositionWidth.Half, value: EChecklistAttributePositionWidth.Half },
];

const PositionOption: FC<
  PropsWithChildren<{
    fetchBooleanAttrList: (payload: any) => Promise<any>;
    isPreviousFileLink?: boolean;
  }>
> = ({ fetchBooleanAttrList, isPreviousFileLink }) => {
  const { values, setFieldValue } = useFormikContext<TFormikDataType>();

  const { positionOptionConfig, checkAndSetValue } = usePositionOption({
    values,
    setValue: setFieldValue,
    isPreviousFileLink,
  });

  const [activeBooleanList, setActiveBooleanList] = useState([]);

  useEffect(() => {
    fetchBooleanAttrList({
      checkListId: values.checkListId,
      activeOnly: true,
      attributeType: values?.type !== EChecklistAttributeType.File_link ? 'BOOLEAN' : null,
      size: 100,
      ...(values.stageId && { stageId: values.stageId }),
    }).then(data => {
      setActiveBooleanList(
        data?.content
          ?.filter((item: IChecklistAttribute) => item.order < values.order)
          ?.map((item: IChecklistAttribute) => ({
            label: `${item.attribute.name} / ${item.id}`,
            value: item.id,
            rawData: item,
          }))
      );
    });

    checkAndSetValue();
  }, []);

  const widthError = useMemo(
    () =>
      !values?.position?.width || values?.position?.width?.length < 4 ? 'Заполните это поле' : null,
    [values?.position?.width]
  );

  return (
    <Box bgcolor={Colors.lightGrey} width="100%" padding={2} borderRadius={2}>
      <Typography sx={{ marginBottom: 2 }}>Расположение атрибута</Typography>
      <FormRow>
        <Styled.SelectInputWrapper>
          <Autocomplete
            options={activeBooleanList}
            value={get(values, EPositionOptionProp.ParentId, '')}
            onChange={(event, value) => {
              if (value === null) {
                setFieldValue(EPositionOptionProp.ParentId, null);

                checkAndSetValue();
                return;
              }

              if (typeof value === 'object') {
                setFieldValue(EPositionOptionProp.ParentId, value?.value);
              }

              checkAndSetValue();
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value || option.value === value.value;
            }}
            filterOptions={options => options}
            disabled={positionOptionConfig[EPositionOptionProp.ParentId]?.isDisabled}
            renderInput={params => <MTextField {...params} label="Родительский аттрибут" />}
            disablePortal
          />
        </Styled.SelectInputWrapper>
      </FormRow>
      <FormRow>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(values?.position?.separate)}
              onChange={(e, val) => {
                setFieldValue(EPositionOptionProp.Separate, val);
                checkAndSetValue();
              }}
              type="checkbox"
              name={EPositionOptionProp.Separate}
              disabled={positionOptionConfig[EPositionOptionProp.Separate].isDisabled}
            />
          }
          label="Независимый атрибут"
        />

        {values?.type === EChecklistAttributeType.File_link && (
          <Box display="flex" alignItems="center">
            <Typography fontSize={'12px'} color="error">
              {isPreviousFileLink
                ? 'Предыдущий атрибут FILE_LINK'
                : 'Убедитесь что предыдущий атрибут НЕ FILE_LINK. Изменить значение можно при редактировании атрибута.'}
            </Typography>
          </Box>
        )}
      </FormRow>
      <FormRow>
        <FormControlLabel
          control={
            <Switch
              checked={values?.position?.newLine}
              onChange={(e, val) => {
                setFieldValue(EPositionOptionProp.NewLine, val);
                checkAndSetValue();
              }}
              type="checkbox"
              name={EPositionOptionProp.NewLine}
              disabled={positionOptionConfig[EPositionOptionProp.NewLine].isDisabled}
            />
          }
          label="Вывод с новой строки"
        />
      </FormRow>
      <FormRow>
        <Styled.SelectInputWrapper>
          <Autocomplete
            options={WIDTH_OPTIONS}
            value={values?.position?.width}
            inputValue={values?.position?.width || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, value) => {
              if (typeof value === 'object') {
                setFieldValue('position.width', value.value);
                checkAndSetValue();
              }
            }}
            filterOptions={options => options}
            disabled={positionOptionConfig[EPositionOptionProp.Width].isDisabled}
            renderInput={params => (
              <MTextField
                {...params}
                label="Ширина"
                error={Boolean(widthError)}
                helperText={widthError}
              />
            )}
            disablePortal
            disableClearable
          />
        </Styled.SelectInputWrapper>
      </FormRow>
    </Box>
  );
};

export default observer(PositionOption);
