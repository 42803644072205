import { CalendarComponent } from '@farmlink/farmik-ui';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo } from 'react';

import { TypeBasis } from '../../../../../../../../api/models/basis.model';
import { TypeCulture } from '../../../../../../../../api/models/culture.model';
import { TypeCultureClass } from '../../../../../../../../api/models/cultureClass.model';
import { TypeEconomicRegion } from '../../../../../../../../api/models/economicRegion.model';
import { TypeProvider } from '../../../../../../../../api/models/provider.model';
import { TypeUnit } from '../../../../../../../../api/models/units.model';
import { useStore } from '../../../../../../../shared/utils';
import {
  AddQuotesStore,
  TypeQuoteSimple,
} from '../../../../../../../stores/quotes/add.quotes.store';
import { notSelectedOption } from '../../../QuotesList/components/QuotesFilter';
import binIcon from '../../../../assets/images/bin.svg';
import { EditQuoteStore } from '../../../../../../../stores/quotes/edit.quote.store';

import {
  BinIcon,
  DeleteBlock,
  DeleteLabel,
  Header,
  NewQuoteBlockTitle,
  NewQuoteBlockWrapper,
  StyledGridItem,
} from './EditQuoteBlock.style';

export type EditQuotesBlockProps = {
  quote: TypeQuoteSimple;
  onChange: (quote: TypeQuoteSimple) => void;
};

export const EditQuoteBlock: FC<EditQuotesBlockProps> = observer(({ quote }) => {
  const editQuoteStore = useStore(EditQuoteStore);

  useEffect(() => {
    editQuoteStore.setIsLoading(true);
    (async () => {
      console.log('before loading all');
      await Promise.all([
        editQuoteStore.fetchBasis(),
        editQuoteStore.fetchCultureList(),
        editQuoteStore.fetchCultureClassList(),
        editQuoteStore.fetchEconomicRegionsList(),
        editQuoteStore.fetchProvidersList(),
        editQuoteStore.fetchCurrencyList(),
        editQuoteStore.fetchUnitsList(),
      ]);
      editQuoteStore.setIsLoading(false);
    })();
  }, []);

  const cultureValue = useMemo(() => {
    const result = Boolean(quote.cultureId)
      ? {
          id: quote.cultureId,
          name: editQuoteStore.cultureList.find(item => item.id === quote.cultureId)?.name,
        }
      : notSelectedOption;
    return result;
  }, [quote.cultureId, editQuoteStore.cultureList]);

  const cultureClassValue = useMemo(
    () =>
      Boolean(quote.cultureClassId)
        ? {
            id: quote.cultureClassId,
            name: editQuoteStore.cultureClassList.find(item => item.id === quote.cultureClassId)
              ?.name,
          }
        : notSelectedOption,
    [quote.cultureClassId, editQuoteStore.cultureClassList]
  );

  const basisValue = useMemo(
    () =>
      Boolean(quote.basisId)
        ? {
            id: quote.basisId,
            name: editQuoteStore.basisList.find(item => item.id === quote.basisId)?.name,
          }
        : notSelectedOption,
    [quote.basisId, editQuoteStore.basisList]
  );

  const regionValue = useMemo(
    () =>
      Boolean(quote.economicRegionId)
        ? {
            id: quote.economicRegionId,
            name: editQuoteStore.economicRegionsList.find(
              item => item.id === quote.economicRegionId
            )?.name,
          }
        : notSelectedOption,
    [quote.economicRegionId, editQuoteStore.economicRegionsList]
  );

  const providerValue = useMemo(
    () =>
      Boolean(quote.providerId)
        ? {
            id: quote.providerId,
            name: editQuoteStore.providersList.find(item => item.id === quote.providerId)?.name,
          }
        : notSelectedOption,
    [quote.providerId, editQuoteStore.providersList]
  );

  const unitValue = useMemo(
    () =>
      Boolean(quote.unitsId)
        ? {
            id: quote.unitsId,
            name: editQuoteStore.unitsList.find(item => item.id === quote.unitsId)?.name,
          }
        : notSelectedOption,
    [quote.unitsId, editQuoteStore.unitsList]
  );

  const currencyValue = useMemo(
    () =>
      Boolean(quote.currencyId)
        ? {
            id: quote.currencyId,
            name: editQuoteStore.currencyList.find(item => item.id === quote.currencyId)?.name,
          }
        : notSelectedOption,
    [quote.currencyId, editQuoteStore.currencyList]
  );

  const dateOfObsValue = useMemo(
    () =>
      quote.dateOfObs ? moment(quote.dateOfObs, 'YYYY-MM-DD').format('DD.MM.YYYY') : undefined,
    [quote.dateOfObs]
  );

  const cultureOptions = useMemo(() => {
    const result = editQuoteStore.cultureList.map(culture => ({
      id: culture.id,
      name: culture.name,
    }));
    return result;
  }, [editQuoteStore.cultureList]);

  const cultureClassOptions = useMemo(() => {
    const result = [notSelectedOption, ...editQuoteStore.cultureClassList];
    return result;
  }, [editQuoteStore.cultureClassList]);

  const basisOptions = useMemo(() => {
    const result = [...editQuoteStore.basisList];
    return result;
  }, [editQuoteStore.basisList]);

  const economicRegionsOptions = useMemo(() => {
    const result = [...editQuoteStore.economicRegionsList];
    return result;
  }, [editQuoteStore.economicRegionsList]);

  const currencyOptions = useMemo(() => {
    const result = [...editQuoteStore.currencyList];
    return result;
  }, [editQuoteStore.currencyList]);

  const providersOptions = useMemo(() => {
    const result = [...editQuoteStore.providersList];
    return result;
  }, [editQuoteStore.providersList]);

  const unitsOptions = useMemo(() => {
    const result = [...editQuoteStore.unitsList];
    return result;
  }, [editQuoteStore.unitsList]);

  const onSetCulture = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, cultureId: value?.id || '' });
    },
    [quote]
  );

  const onSetCultureClass = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, cultureClassId: value?.id || '' });
    },
    [quote]
  );

  const onSetCurrency = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, currencyId: value?.id || '' });
    },
    [quote]
  );

  const onSetBasis = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, basisId: value?.id || '' });
    },
    [quote]
  );

  const onSetEconomicRegion = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, economicRegionId: value?.id || '' });
    },
    [quote]
  );

  const onSetProvider = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, providerId: value?.id || '' });
    },
    [quote]
  );

  const onSetUnit = useCallback(
    (event, value) => {
      editQuoteStore.onQuoteChange({ ...quote, unitsId: value?.id || '' });
    },
    [quote]
  );

  const onSetPrice = useCallback(
    event => {
      editQuoteStore.onQuoteChange({
        ...quote,
        price: event.target.value
          ? parseFloat((event.target.value as string).replace(/\D/g, ''))
          : 0,
      });
    },
    [quote]
  );

  const onSetDateOfObs = useCallback(
    date => {
      editQuoteStore.onQuoteChange({ ...quote, dateOfObs: moment(date).format('YYYY-MM-DD') });
    },
    [quote]
  );

  return (
    <NewQuoteBlockWrapper>
      <Header>
        <NewQuoteBlockTitle>Котировка&nbsp;{+1}</NewQuoteBlockTitle>
      </Header>
      <Grid container spacing={2} mb={4}>
        <StyledGridItem item xs={3}>
          <Autocomplete
            options={cultureOptions}
            value={cultureValue}
            getOptionLabel={(option: TypeCulture) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Культуры не найдены"
            onChange={onSetCulture}
            renderInput={params => <TextField {...params} variant="outlined" label="Культуры" />}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={cultureClassOptions}
            value={cultureClassValue}
            getOptionLabel={(option: TypeCultureClass) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Классы не найдены"
            onChange={onSetCultureClass}
            renderInput={params => <TextField {...params} variant="outlined" label="Классы" />}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={currencyOptions}
            value={currencyValue}
            getOptionLabel={(option: any) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Валюты не найдены"
            onChange={onSetCurrency}
            renderInput={params => {
              return <TextField {...params} variant="outlined" label="Валюта" />;
            }}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={economicRegionsOptions}
            value={regionValue}
            getOptionLabel={(option: TypeEconomicRegion) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Регионы не найдены"
            onChange={onSetEconomicRegion}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Экономический регион" />
            )}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={providersOptions}
            value={providerValue}
            getOptionLabel={(option: TypeProvider) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Провайдеры не найдены"
            onChange={onSetProvider}
            renderInput={params => <TextField {...params} variant="outlined" label="Провайдер" />}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={unitsOptions}
            value={unitValue}
            getOptionLabel={(option: TypeUnit) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Единицы не найдены"
            onChange={onSetUnit}
            renderInput={params => <TextField {...params} variant="outlined" label="Единицы" />}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <TextField
            fullWidth
            name={'quotePrice'}
            variant="outlined"
            placeholder="Введите цену"
            label="Цена"
            value={quote.price}
            onChange={onSetPrice}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </StyledGridItem>

        <StyledGridItem item xs={3}>
          <Autocomplete
            options={basisOptions}
            value={basisValue}
            getOptionLabel={(option: TypeBasis) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Базисы не найдены"
            onChange={onSetBasis}
            renderInput={params => <TextField {...params} variant="outlined" label="Базис" />}
          />
        </StyledGridItem>
        <StyledGridItem item xs={3}>
          <CalendarComponent
            error={''}
            placeholder={'Дата'}
            label={''}
            value={dateOfObsValue}
            onChange={onSetDateOfObs}
            datePickerOptions={{}}
            dataTestId={'dateOfObs'}
            readOnly={false}
            isDisabled={false}
            isBlocked={false}
            isCloseOnChange={true}
          />
        </StyledGridItem>
      </Grid>
    </NewQuoteBlockWrapper>
  );
});
