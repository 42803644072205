import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ChecklistExportModalContainer } from '../..';

export const getChecklistExportModalConfig = (): TModalConfig => ({
  name: 'checklistModalExport',
  title: 'Экспорт чек-листов',
  type: EModalType.Custom,
  id: 'checklistModalExport',
  children: ChecklistExportModalContainer,
  styledProps: { $size: '600px', $modalPaddings: '30px 20px', $isFullContentWidth: true },
});
