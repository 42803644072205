import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CultureListModal } from './components';

const CULTURE_LIST_MODAL_ID = 'culture-list-modal';

const cultureListModalConfig: TModalConfig = {
  id: CULTURE_LIST_MODAL_ID,
  name: CULTURE_LIST_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  children: CultureListModal,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: EModalSize.Large,
  },
};

export { cultureListModalConfig, CULTURE_LIST_MODAL_ID };
