import { FC } from 'react';
import { observer } from 'mobx-react';

import { NewsFilter, NewsTable } from '..';

export const NewsAllList: FC = observer(() => (
  <>
    <NewsFilter />
    <NewsTable />
  </>
));
