export enum EOrgIntegraAgrosignalRoutes {
  Organization = 'organization',
  Employees = 'employees',
  Fields = 'fields',
  Checklists = 'checklists',
  ChecklistInstances = 'checklist-instances',
  MessagesError = 'messages-error',
  FilesError = 'files-error',
}

export enum EOrgIntegraAgrosignalErrorRoutes {
  MessagesError = 'messages-error',
  FilesError = 'files-error',
}

export enum EOrgIntegraAgrosignalTabs {
  Organization = 'organization',
  Employees = 'employees',
  Fields = 'fields',
  Checklists = 'checklists',
  ChecklistInstances = 'checklist-instances',
  MessagesError = 'messages-error',
  FilesError = 'files-error',
}
