import { Colors } from '@farmlink/farmik-ui';
import { Box, Typography } from '@mui/material';
import { FC, memo } from 'react';

import { EFormulaFunctionAggregation, EFormulaReturnType } from '../../../../../../models';

interface IProps {
  additionalTitle?: string;
  returnType?: EFormulaReturnType;
  functionType?: EFormulaFunctionAggregation;
  functionTitle?: string;
  formulaType?: string;
}

const FormulaTitle: FC<IProps> = ({
  additionalTitle,
  returnType,
  formulaType,
  functionType,
  functionTitle,
}) => {
  return (
    <Box>
      <Typography data-test-id="formula-block-title">{`${additionalTitle ?? ''} ${formulaType} ${
        functionType ?? ''
      }${functionTitle ? ` (${functionTitle}) ` : ''} `}</Typography>
      {returnType ? (
        <Typography
          sx={{ fontSize: 12, color: Colors.grey }}
          data-test-id="formula-block-subtitle-return-type"
        >{`Возвращаемое значение: ${returnType}`}</Typography>
      ) : (
        <Box height={18} />
      )}
    </Box>
  );
};

export default memo(FormulaTitle);
