import {
  EImportOperationStatus,
  IPogodaVPoleImportOperationModel,
} from '../../models/importOperation.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;

  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  statusIn: EImportOperationStatus[];
};
type TypeResponse = TypeResponsePageable & { content: IPogodaVPoleImportOperationModel[] };

export const getPogodaVPoleReportImportOperationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-weather/importOperations/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
