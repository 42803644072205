import { Autocomplete, Box, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { useModalForm } from '../../hooks';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { IOrgIntegraAgrosignalChecklist } from '../../../../models/orgIntegraAgrosignalChecklist';
import { ISelectOption } from '../../../../../../../../../../../../../../types/selectOption';

const ChecklistModalForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const { getModalPayload, closeModal, getModalRuntimeHandlers } = useModal();
  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId: string;
    checklist: IOrgIntegraAgrosignalChecklist;
  };
  const handlers = getModalRuntimeHandlers();

  const {
    optionList,
    agroChecklistId,
    setAgroChecklistId,
    checklist,
    setChecklist,
    isDisabled,
    createChecklist,
    updateChecklist,
  } = useModalForm(payload.organizationId, payload?.checklist?.id);

  useEffect(() => {
    if (payload?.checklist?.checklistId)
      setChecklist({
        label: payload?.checklist?.checklistId,
        value: payload?.checklist?.checklistId,
      });

    if (payload?.checklist?.agrosChecklistId)
      setAgroChecklistId(payload.checklist.agrosChecklistId);
  }, [payload]);

  const onSuccess = () => {
    if (payload?.mode === 'create') {
      return createChecklist().then(data => {
        handlers?.onSuccess?.();
        closeModal();

        return data;
      });
    }

    if (payload?.mode === 'update') {
      return updateChecklist().then(data => {
        handlers?.onSuccess?.();
        closeModal();

        return data;
      });
    }
  };

  const onDeny = () => {
    closeModal();
  };

  const titleText = `${payload.mode === 'create' ? 'Добавление' : 'Детали'} чек-листа`;

  return (
    <Box>
      <ModalTitle>{titleText}</ModalTitle>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />
        <Autocomplete
          options={optionList}
          fullWidth
          renderInput={props => (
            <TextField
              {...props}
              label={'ID “Помощник”'}
              placeholder={'Введите ID иди имя чек-листа из системы “Помощник”'}
            />
          )}
          disableClearable
          disablePortal
          value={checklist}
          onChange={(e, value) => {
            setChecklist(value as ISelectOption);
          }}
        />
        <TextField
          label="ID “Агросигнал”"
          placeholder="Введите ID поля из системы “Агросигнал”"
          value={agroChecklistId || ''}
          onChange={e => setAgroChecklistId(Number(e.target.value))}
          type="number"
          fullWidth
        />
        <ModalFooter
          successButton={{
            title: 'Сохранить',
            color: 'primary',
            handler: onSuccess,
            disabled: isDisabled,
          }}
          denyButton={{ title: 'Отменить', color: 'default', handler: onDeny }}
        />
      </Box>
    </Box>
  );
};

export default observer(ChecklistModalForm);
