import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ChecklistCRUDController } from '../../../../../controllers/checklist.CRUD.controller';
import { MenuItemsProps } from '../../../../../shared/menu/type';
import { useStore } from '../../../../../shared/utils';
import { Layout } from '../../../../components';
import { AdminRoutes } from '../../../../routes';
import { MainContainer, HeaderWrapper, TopSection, H1, MainBody } from '../../../../style';
import { getBasicChecklistLayoutMenu } from '../../helpers/getBasicChecklistLayoutMenu';

import { Checklist } from './components';

const ChecklistContainer = () => {
  const { checklistId } = useParams<{ checklistId: string }>();
  const isEditMode = Boolean(checklistId);
  const history = useHistory();

  const { setEditMode, fetchChecklistById } = useStore(ChecklistCRUDController);
  useEffect(() => {
    setEditMode(isEditMode);
    if (isEditMode && checklistId) {
      // TODO переделать логику сборки атрибутов на карту из ChecklistAttributeFacade
      fetchChecklistById(checklistId).catch(() => {
        history.push(AdminRoutes.Checklists);
      });
    }
  }, [isEditMode]);

  const menuItems: MenuItemsProps = useMemo(
    () => getBasicChecklistLayoutMenu({ goBackLink: AdminRoutes.Checklists }),
    []
  );

  return (
    <Layout menuItems={menuItems} sidebarComponent={null}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Чек-листы. Общая информация</H1>
          </TopSection>
        </HeaderWrapper>
        <MainBody minHeight="calc(100vh - 205px)">
          <Checklist />
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default ChecklistContainer;
