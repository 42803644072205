import { Box, Button } from '@mui/material';
import { FC, useState } from 'react';

import { DependAttribute } from '../DependAttribute';
import { CurrentEnumValueSelector } from '../CurrentEnumValueSelector';
import { SelectedEnumValueSelector } from '../SelectedEnumValueSelector';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { EnumDependencyStore } from '../../mobx';
import { EEnumDepsTab, TFormData } from '../../models';

const DEFAULT_DATA = {
  attribute: null,
  currentEnumValue: null,
  selectedEnumValue: null,
};

interface IProps {
  onSave: (data: TFormData) => Promise<any>;
}

const EnumDepend: FC<IProps> = ({ onSave }) => {
  const store = useStore(EnumDependencyStore);

  const [isExpand, setIsExpand] = useState(false);
  const [data, setData] = useState<TFormData>(DEFAULT_DATA);
  const [isProcessing, setIsProcessing] = useState(false);

  const cancelAdding = () => {
    setIsExpand(false);
    setData(DEFAULT_DATA);
    store.clearOptions();
  };

  const handleSave = () => {
    setIsProcessing(true);

    onSave(data)
      .then(() => cancelAdding())
      .finally(() => {
        setIsProcessing(false);
      });
  };

  if (!isExpand) {
    return <Button onClick={() => setIsExpand(true)}>+ Добавить зависимость</Button>;
  }

  return (
    <Box>
      <Box display="flex" gap={2} flexDirection="row">
        <CurrentEnumValueSelector
          value={data.currentEnumValue}
          type={EEnumDepsTab.Depend}
          onChange={value => setData({ ...data, currentEnumValue: value })}
        />
        <DependAttribute
          value={data.attribute}
          type={EEnumDepsTab.Depend}
          onChange={value => setData({ ...data, attribute: value, selectedEnumValue: null })}
        />
        <SelectedEnumValueSelector
          value={data.selectedEnumValue}
          type={EEnumDepsTab.Depend}
          onChange={value => setData({ ...data, selectedEnumValue: value })}
        />
      </Box>
      <Box display="flex" gap={2} flexDirection="row" marginTop={2}>
        <Button disabled={isProcessing} variant="contained" onClick={handleSave}>
          Сохранить
        </Button>
        <Button color="error" onClick={cancelAdding}>
          Отменить
        </Button>
      </Box>
    </Box>
  );
};

export default EnumDepend;
