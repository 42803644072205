import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../shared/components';
import { IOrgIntegraCropwiseChecklist } from '../../../models/orgIntegraCropwiseChecklist';

const CultureListModal = () => {
  const { closeModal, getModalPayload } = useModal();

  const payload = useMemo(
    () => getModalPayload() as { cultureList: IOrgIntegraCropwiseChecklist['cultures'] },
    []
  );

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <ModalTitle>Список культур</ModalTitle>
      <Box maxHeight={500} display={'flex'} flexDirection={'column'} gap={2} overflow={'auto'}>
        {payload?.cultureList?.map(item => (
          <Box padding={'6px 10px'}>
            <Typography>{item?.cultureName ?? 'Название не указано'}</Typography>
            <Typography fontSize={12}>{item.cultureId}</Typography>
          </Box>
        ))}
      </Box>
      <ModalFooter denyButton={{ title: 'Закрыть', handler: closeModal }} />
    </Box>
  );
};

export default CultureListModal;
