import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
  Tooltip,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { debounce, isEqual, unionBy } from 'lodash';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import { ISelectOption, ISelectOptionExtended } from '../../../../../../../../types/selectOption';
import { ApiNames, TypeApiRequest, TypeApiResponse } from '../../../../../../../shared/utils';

import Item, { ITooltipConfig } from './components/Item/Item';

export interface IAutocompleteProps {
  itemKey: string;
  optionsFetchHandler: (payload: TypeApiRequest<ApiNames>) => Promise<TypeApiResponse<ApiNames>>;
  searchingKey?: string;
  optionsMappingScheme?:
    | ISelectOption
    | ((value: TypeApiResponse<ApiNames>) => ISelectOptionExtended)
    | null;
  onChangeMappingKey?: string;
  searchingParams?: Record<string, any>;
  isOnMountOptionsFetch?: boolean;
  disabled?: boolean;
  attributeFormItemsScheme?: any;
  reFetchOnUpdate?: boolean;
  isClearable?: boolean;
  value?: ISelectOptionExtended | string;
  fetchValueOnMount?: Record<string, any>;
  onSelect?: (item: ISelectOptionExtended) => void;
  optionItem?: {
    tooltip?: ITooltipConfig;
  };
  pagination?: {};
  isMultiselect?: boolean;
}

const AutocompleteInput: FC<IAutocompleteProps> = ({
  itemKey,
  optionsFetchHandler,
  optionsMappingScheme,
  searchingKey,
  onChangeMappingKey,
  searchingParams,
  isOnMountOptionsFetch,
  reFetchOnUpdate,
  disabled,
  attributeFormItemsScheme,
  onSelect,
  isClearable,
  value,
  fetchValueOnMount,
  optionItem,
  pagination,
  isMultiselect,
}) => {
  const { setFieldValue, touched, errors, isSubmitting, values, status } = useFormikContext();
  const [optionList, setOptionList] = useState<ISelectOptionExtended[]>([]);
  const [inputValue, setInputValue] = useState(
    (typeof value === 'string' ? value : value?.label) || values?.[itemKey] || ''
  );
  const [isDisableDataFetch, setIsDisableDataFetch] = useState(false);
  const [previousInput, setPreviousInput] = useState('');
  const [previousSearchParams, setPreviousSearchParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moreData, setMoreData] = useState<number | false>(false);
  const [page, setPage] = useState(0);
  const [listboxNode, setListboxNode] = useState<HTMLDivElement>(null);
  const [position, setPosition] = useState(0);
  const [isWheelDown, setIsWheelDown] = useState(false);

  const onInputChange = useCallback(
    (e, _value) => {
      setIsDisableDataFetch((e === null && !reFetchOnUpdate) || e?.type === 'click');
      setInputValue(_value);
      setPage(0);
    },
    [reFetchOnUpdate]
  );

  useEffect(() => {
    if (isOnMountOptionsFetch && previousInput.length === 0) {
      handleFetch('', fetchValueOnMount).then(response => {
        if (fetchValueOnMount) {
          const valueList = Array.isArray(response) ? response : response.content;
          const fetchedValue = handleCreateOptionListItem(valueList[0] || null)?.label;

          if (fetchedValue) {
            setFieldValue(itemKey, handleCreateOptionListItem(valueList[0] || null)?.label);
            setInputValue(fetchedValue);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    setOptionList([]);
  }, [optionsFetchHandler]);

  useEffect(() => {
    if (listboxNode) {
      listboxNode.scrollTop = position;
    } else {
      setPage(0);
    }
  }, [position, listboxNode]);

  const handleCreateOptionListItem = useCallback(item => {
    if (typeof optionsMappingScheme === 'function') {
      return optionsMappingScheme(item);
    } else {
      return {
        label:
          item[optionsMappingScheme?.label] ||
          item[optionsMappingScheme?.value] ||
          item?.name ||
          item?.title ||
          item?.id,
        value: item[optionsMappingScheme?.value] || item?.id || item?.remoteName,
        rawData: item,
      };
    }
  }, []);

  const handleFetch = (
    _value,
    additionalParams?: Record<string, any>,
    storedList?: ISelectOptionExtended[]
  ) => {
    if (isDisableDataFetch) {
      return;
    }
    setIsLoading(true);
    if (isOnMountOptionsFetch && optionList.length > 0) {
      return;
    }
    const payload: Record<string, any> = { ...searchingParams, ...additionalParams, ...pagination };

    if (searchingKey) {
      payload[searchingKey] = _value;
    }

    return optionsFetchHandler(payload)
      ?.then(data => {
        if (!pagination) {
          setOptionList([]);
        }

        const newData = (Array.isArray(data) ? data : data.content)
          .map(item => handleCreateOptionListItem(item))
          .filter(item => item !== null);

        const valueList = [...(pagination && storedList ? storedList : []), ...newData];

        if (data?.content && data?.totalElements > data?.size) {
          setMoreData(data?.totalElements);
        } else {
          setMoreData(false);
        }

        // TODO: разобраться с двойным запросом с page 0

        setOptionList(unionBy(valueList, 'value'));

        return data;
      })
      ?.finally(() => {
        setIsLoading(false);
      });
  };

  const handleFetchData = useCallback(debounce(handleFetch, 500), []);

  useEffect(() => {
    if (
      (isEqual(searchingParams, previousSearchParams) && inputValue === previousInput) ||
      isDisableDataFetch
    ) {
      return;
    }

    setPreviousSearchParams(searchingParams);
    setPreviousInput(inputValue);
    handleFetchData?.(inputValue);
  }, [inputValue, searchingParams, previousSearchParams, isDisableDataFetch, optionsFetchHandler]);

  const handleScroll = event => {
    if (event.currentTarget) {
      setListboxNode(event.currentTarget);
    }

    if (!listboxNode || !isWheelDown) {
      return;
    }

    const y = listboxNode?.scrollTop + listboxNode?.clientHeight;

    if (listboxNode.scrollHeight - y <= 1) {
      const newPage = page + 1;

      handleFetchData?.(inputValue, { page: newPage }, optionList);
      setPosition(y);
      setPage(newPage);
    }
  };

  const handleWheel = (event: WheelEvent) => {
    setIsWheelDown(event.deltaY > 0);
  };

  if (isSubmitting || disabled) {
    return <TextField sx={{ minWidth: 418 }} disabled value={value || values[itemKey] || null} />;
  }

  console.log(optionList, values[itemKey]);

  return (
    <Field
      name={itemKey}
      component={Autocomplete}
      options={optionList}
      style={{ minWidth: 418 }}
      value={value || values[itemKey] || undefined}
      onInputChange={onInputChange}
      inputValue={inputValue}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option?.label || option.value
      }
      disableClearable={!isClearable}
      disabled={attributeFormItemsScheme[itemKey]?.disabled || disabled}
      renderOption={(props, option, { selected }) =>
        isMultiselect ? (
          <li {...props} id={`item-${option}`}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {typeof option === 'string' ? option : option?.label || option?.value}
          </li>
        ) : (
          <Item key={option.value} props={props} value={option} tooltip={optionItem?.tooltip} />
        )
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue?.map((optionValue, index) => {
          const option = optionList?.find(opt => opt?.value === optionValue);
          const label = option ? option?.label : optionValue;

          return (
            <Chip
              label={typeof label === 'string' ? label : label?.label || null}
              {...getTagProps({ index })}
            />
          );
        })
      }
      disablePortal
      noOptionsText="Вариантов не найдено"
      onChange={(e, _value: ISelectOptionExtended) => {
        if (onChangeMappingKey) {
          setFieldValue(onChangeMappingKey, _value === null ? null : _value?.value);
          setFieldValue(itemKey, value === null ? null : _value?.value);
        }

        onSelect?.(_value);
      }}
      ListboxProps={{
        onScroll: pagination ? handleScroll : null,
        onWheel: pagination ? handleWheel : null,
        role: 'list-box',
      }}
      multiple={isMultiselect}
      disableCloseOnSelect={isMultiselect}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={attributeFormItemsScheme[itemKey]?.label}
          variant="outlined"
          // @ts-ignore
          error={Boolean(errors?.[itemKey])}
          // @ts-ignore
          helperText={errors?.[itemKey]}
          value={value}
          disabled={attributeFormItemsScheme[itemKey]?.disabled || disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {moreData ? (
                  <Tooltip title={`Найдено записей: ${moreData}. Сузьте поисковой запрос.`}>
                    <ReadMoreIcon />
                  </Tooltip>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...(isMultiselect
        ? {
            isOptionEqualToValue: (option, _value) => {
              if (typeof option === 'string') {
                return option === _value;
              } else if (typeof _value === 'string') {
                return option.value === _value;
              } else {
                return option.value === _value.value;
              }
            },
          }
        : {})}
    />
  );
};

AutocompleteInput.displayName = 'AutocompleteInput';

export default memo(AutocompleteInput);
