import { ISelectOption } from '../../../../../../../../types/selectOption';

export interface ICoreSynchronizationForm {
  typesEntities: Set<ETypesEntities>;
  userId?: ISelectOption[];
  organizationId: ISelectOption[];
  startTime: string;
  endTime?: string;
}

export interface IPushEventsForm {
  entityTypes: ETypesEntities[];
  organizationIds: string[];
  userIds: string[];
  startTime: string;
  endTime: string;
}

export enum ETypesEntities {
  CHECKLIST_INSTANCE = 'CHECKLIST_INSTANCE',
  CHECKLIST = 'CHECKLIST',
  TASK = 'TASK',
  FIELD = 'FIELD',
}

export interface IIntegraPushEvent {
  entityType: ETypesEntities;
  ids: string[];
}

export interface IPushEventsResponse {
  events: IIntegraPushEvent[];
}
