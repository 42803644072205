import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';

import { ISelectOption } from '../../../../../../../../types/selectOption';
import { useStore } from '../../../../../../../shared/utils';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { DictionariesStore } from '../../../../../../../stores';
import {
  EDictionaryItemStatus,
  IDictionaryExportOptions,
} from '../../../../../../../../api/models/dictionaries.model';

import Styled from './styled';

const ExportModal: FC = () => {
  const store = useStore(DictionariesStore);
  const { exportDictionary, exportDictionaryDependencies } = useStore(DictionariesController);
  const { closeModal, getModalPayload } = useModal();

  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ISelectOption>(
    store?.selectedDictionaryListRemoteName
      ? {
          label: store.selectedDictionaryListRemoteName.title,
          value: store.selectedDictionaryListRemoteName.remoteName,
        }
      : null
  );
  const [exportOptions, setExportOptions] = useState<IDictionaryExportOptions>({
    status: null,
    originalOnly: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const modalPayload = getModalPayload();
  const isDependenciesExport = modalPayload?.isDependencies;

  useEffect(() => {
    setOptionList(
      store.dictionaryNameList.map(item => ({ label: item.title, value: item.remoteName }))
    );
  }, [store.dictionaryNameList]);

  const onDownloadClick = file => {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `exported${isDependenciesExport ? '_dependencies' : ''}_${selectedOption?.label}${
      exportOptions?.status ? `_${exportOptions.status}` : ''
    }${exportOptions?.originalOnly ? '_originalOnly' : ''}.json`;

    a.style.display = 'none';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
  };

  const updateExportOptions = useCallback<(options: IDictionaryExportOptions) => void>(
    options => {
      setExportOptions({ ...exportOptions, ...options });
    },
    [exportOptions]
  );

  const exportChecklists = useCallback(() => {
    setIsLoading(true);

    if (isDependenciesExport) {
      exportDictionaryDependencies(selectedOption?.value, exportOptions)
        .then(data => {
          onDownloadClick(data);
        })
        .finally(() => setIsLoading(false));
    } else {
      exportDictionary(selectedOption?.value, exportOptions)
        .then(data => {
          onDownloadClick(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedOption, exportOptions, isDependenciesExport]);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Title>
          Экспорт {isDependenciesExport ? 'зависимостей ' : ''}справочника
        </Styled.Title>
        <Autocomplete
          options={optionList}
          value={selectedOption}
          renderInput={props => <TextField {...props} label="Справочник" />}
          onChange={(e, value, reason) => {
            if (typeof value === 'object' && reason !== 'clear') {
              setSelectedOption(value);
            }
          }}
          fullWidth
          disablePortal
          disableClearable
        />
        <Divider sx={{ margin: '12px 0' }} />
        <Styled.Row>
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="status-id">Статус</InputLabel>
            <Select
              labelId="status-id"
              value={exportOptions?.status ?? ''}
              label="Статус"
              onChange={event => {
                updateExportOptions({ status: event?.target?.value as EDictionaryItemStatus });
              }}
            >
              <MenuItem value={EDictionaryItemStatus.Active}>Активный</MenuItem>
              <MenuItem value={EDictionaryItemStatus.Inactive}>Неактивный</MenuItem>
              <MenuItem value={null}>Не указывать</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <>
                <Switch
                  checked={Boolean(exportOptions?.originalOnly)}
                  id="originalOnly"
                  onChange={(e, checked) => {
                    updateExportOptions({ originalOnly: checked });
                  }}
                />
              </>
            }
            label="Только оригинальные"
            sx={{ width: '48%' }}
            name="originalOnly"
          />
        </Styled.Row>
        <Box height="4px" paddingTop={1}>
          {isLoading && <LinearProgress />}
        </Box>
      </Styled.Content>

      <ModalFooter
        successButton={{
          title: 'Выгрузить',
          color: 'primary',
          handler: () => exportChecklists(),
          disabled: !Boolean(selectedOption),
        }}
        denyButton={{
          title: 'Отменить',
          color: 'secondary',
          handler: () => closeModal(),
        }}
      />
    </Styled.Wrapper>
  );
};

export default ExportModal;
