import { TypeApiRoute } from '../../models/type.api.request';
import { TAction } from '../../models/action.model';

type TypeRequest = {
  userId: string;
};

type TypeResponse = TAction[];

type TypeError = {};

export const getUserActions: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/admin/access/action/global/list/`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
