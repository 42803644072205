import * as yup from 'yup';

import { ICreateOrgIntegraAgrosignalConfig } from '../../../../../../../../../../../api/models/integra/config.model';

const orgIntegraAgrosignalOrganizationSchema: yup.SchemaOf<
  Partial<ICreateOrgIntegraAgrosignalConfig>
> = yup.object().shape({
  apiKey: yup.string().min(1).required('Поле обязательно для заполнения'),
  clientId: yup.number().nullable(),
  agroId: yup
    .number()
    .required('Поле обязательно для заполнения')
    .moreThan(0, 'Поле должно быть отлично от ноля'),
  host: yup.string().min(1).required('Поле обязательно для заполнения'),
  name: yup.string().nullable(),
  agroName: yup.string().min(1).required('Поле обязательно для заполнения'),
  organizationId: yup.string().required('Поле обязательно для заполнения'),
});

export { orgIntegraAgrosignalOrganizationSchema };
