import styled from 'styled-components';

import { FontSizes } from '../../../../../../../../../../../../../../../../constants/sizes';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 0.8fr) 2fr 1fr 0.2fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 8px;
`;

const HeadTitle = styled.p`
  ${FontSizes.large}
`;

const Styled = {
  Grid,
  HeadTitle,
};

export default Styled;
