import { useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../shared/utils';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { OrgIntegraCropwiseService } from '../../../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';

const useModalForm = (organizationId: string, id: string) => {
  const service = useStore(OrgIntegraCropwiseService);

  const { setNotification } = useNotificator();

  const [assistanceField, setAssistanceField] = useState<ISelectOptionExtended>(null);
  const [cropwiseField, setCropwiseField] = useState<ISelectOptionExtended>(null);

  const fetchAssistanceField = service.getOrgIntegraAssistanceFieldList;
  const fetchCropwiseField = service.getOrgIntegraCropwiseFieldList;

  const createField = () => {
    if (!organizationId || !assistanceField || !cropwiseField) {
      setNotification(
        getNotificatorProps('Проверьте заполненность полей', ENotificationStyles.Error)
      );

      return Promise.reject(new Error());
    }

    return service
      .createField({
        assistanceFieldId: String(assistanceField.value),
        cropwiseFieldId: String(cropwiseField.value),
      })
      .then(data => {
        setNotification(getNotificatorProps('Поле успешно создано', ENotificationStyles.Success));

        return data;
      })
      .catch(e => {
        setNotification(getNotificatorProps('Ошибка создания поля', ENotificationStyles.Error));
      });
  };

  return {
    assistanceField,
    setAssistanceField,
    createField,
    cropwiseField,
    setCropwiseField,
    fetchAssistanceField,
    fetchCropwiseField,
  };
};

export { useModalForm };
