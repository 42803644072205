import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../shared/utils';
import { ERequestStatus } from '../../../../../../../../../shared/types/requests';
import { IOrgIntegraConfigExtended } from '../../../../../../../../../../api/models/integra/config.model';

@provide.singleton()
class OrgIntegraAgrosignalStore {
  private _configRequestStatus: ERequestStatus = ERequestStatus.Idle;
  private _isConfigExists = false;

  private _orgIntegraConfig: IOrgIntegraConfigExtended = null;

  private _dirtyFieldList: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  get configRequestStatus() {
    return this._configRequestStatus;
  }

  get orgIntegraConfig() {
    return this._orgIntegraConfig;
  }

  get isConfigExists() {
    return this._isConfigExists;
  }

  get dirtyFieldList() {
    return this._dirtyFieldList;
  }

  setConfigRequestStatus = (status: ERequestStatus) => {
    this._configRequestStatus = status;
  };

  setOrgIntegraConfig = (config: IOrgIntegraConfigExtended) => {
    this._orgIntegraConfig = config;
  };

  setConfigExists = (state: boolean) => {
    this._isConfigExists = state;
  };

  addToDirtyList = (fieldName: string) => {
    this._dirtyFieldList.add(fieldName);
  };

  clearOrgConfig = () => {
    this._orgIntegraConfig = null;
  };

  clearDirtyList = () => {
    this._dirtyFieldList = new Set();
  };

  clearStore = () => {
    this._configRequestStatus = ERequestStatus.Idle;
    this._isConfigExists = false;

    this.clearDirtyList();
    this.clearOrgConfig();
  };
}

export default OrgIntegraAgrosignalStore;
