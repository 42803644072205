import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { toJS } from 'mobx';

import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { OrganizationRolesSidebar } from '../OrganizationRolesSidebar';
import { OrganizationRoleStore } from '../../mobx';
import { useStore } from '../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../routes';

import getRoleListColumns from './helpers/getRoleListColumns';

export const OrganizationRoleList: FC = () => {
  const store = useStore(OrganizationRoleStore);

  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();

  const handleSelectRole = (roleId: string) => {
    history.push(generatePath(AdminRoutes.OrganizationRoleItem, { organizationId, roleId }));
  };

  const rows = useMemo(() => toJS(store.roleList), [store.roleList]);
  const columns = useMemo(() => getRoleListColumns(handleSelectRole), []);

  return (
    <Layout menuItems={null} sidebarComponent={OrganizationRolesSidebar}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Роли организации</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody height={'calc(100vh - 205px)'}>
          <DataGrid
            rows={rows}
            columns={columns}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            getRowId={params => params.role.id}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            hideFooter
          />
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationRoleList);
