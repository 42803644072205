import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../shared/utils';
import { IFieldReportConfig } from '../../../../../../../../../../api/models/reportConfig.model';

@provide.singleton()
export class OrganizationReportAssistanceFieldsStore {
  private _configMap: Map<string, Partial<IFieldReportConfig>> = new Map();
  private _isLoading = false;
  private _touchedConfigMap: Map<string, Set<keyof Partial<IFieldReportConfig>>> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get configMap() {
    return this._configMap;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  get hasActiveCreation() {
    return Array.from(this._configMap.values()).some(config => config.state === 'create');
  }

  getConfigById = (id: string): Partial<IFieldReportConfig> | undefined => {
    return this._configMap.get(id);
  };

  getTouchedConfigFields = (id: string) => {
    return this._touchedConfigMap.get(id);
  };

  get configList(): Partial<IFieldReportConfig>[] {
    return Array.from(this._configMap.values());
  }

  setConfigById = (id: string, config: Partial<IFieldReportConfig>) => {
    this._configMap.set(id, config);
  };

  addTouchedConfigFields = (configId: string, fieldName: keyof Partial<IFieldReportConfig>) => {
    if (!this._touchedConfigMap?.has(configId)) {
      this._touchedConfigMap?.set(configId, new Set());
    }

    this._touchedConfigMap.get(configId)?.add(fieldName);
  };

  cleanConfigTouchedFields = (configId: string) => {
    this._touchedConfigMap.delete(configId);
  };

  deleteConfigById = (id: string) => {
    this._configMap.delete(id);
  };

  clearConfigMap = () => {
    this._configMap = new Map();
  };

  clearStore = () => {
    this.clearConfigMap();
    this._isLoading = false;
    this._touchedConfigMap = new Map();
  };
}
