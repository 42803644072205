import { Box, TextField as MTextField } from '@mui/material';
import { ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';

import {
  AutocompleteWithSearch,
  ModalTitle,
} from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { getNotificatorProps } from '../../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../../shared/constanst/notifications';
import { OrgIntegraCropwiseService } from '../../../../../../mobx';
import { IOrgIntegraCropwiseCulture } from '../../../../models/orgIntegraCropwiseCulture';
import {
  ISelectOption,
  ISelectOptionExtended,
} from '../../../../../../../../../../../../../../types/selectOption';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import {
  EDictionaryItemStatus,
  TDictionaryItem,
} from '../../../../../../../../../../../../../../api/models/dictionaries.model';

const CultureModalForm = () => {
  const orgStore = useStore(OrganizationsStore);
  const service = useStore(OrgIntegraCropwiseService);
  const { getAllDictionariesByParams } = useStore(DictionariesController);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const [isProcessing, setIsProcessing] = useState(false);

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    culture: IOrgIntegraCropwiseCulture;
  };
  const handlers = getModalRuntimeHandlers();

  const onPromiseProcess = (promise: Promise<any>) => {
    return promise
      .then(resp => {
        setNotification(
          getNotificatorProps('Ошибка успешно обновлена', ENotificationStyles.Success)
        );

        handlers?.onSuccess?.();

        closeModal();

        return resp;
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка обновления', ENotificationStyles.Error));
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onSubmit = (data: IOrgIntegraCropwiseCulture) => {
    setIsProcessing(true);

    if (payload.mode === 'create') {
      return onPromiseProcess(
        service.createCulture({
          organizationId: orgStore.organization.organizationId,
          cropwiseCropId: data.cropwiseCropId,
          cropwiseHeader: data.cropwiseHeader,
          cultureId: data.cultureId,
          cultureName: data.cultureName.replaceAll(` / ${data.cultureId}`, ''),
        })
      );
    }

    if (payload.mode === 'update') {
      return onPromiseProcess(
        service.updateCulture({
          id: payload?.culture?.id,
          organizationId: orgStore.organization.organizationId,
          cropwiseCropId: data.cropwiseCropId,
          cropwiseHeader: data.cropwiseHeader,
          cultureId: data.cultureId,
          cultureName: data.cultureName.replaceAll(` / ${data.cultureId}`, ''),
        })
      );
    }
  };

  const formik = useFormik<Partial<IOrgIntegraCropwiseCulture>>({
    initialValues: payload?.culture || {},
    onSubmit,
  });

  const updateCulture = (newCulture: ISelectOptionExtended | string) => {
    if (typeof newCulture === 'object') {
      formik.setFieldValue('cultureId', newCulture?.value);
      formik.setFieldValue('cultureName', newCulture?.label);
    } else {
      formik.setFieldValue('cultureId', newCulture);
      formik.setFieldValue('cultureName', newCulture);
    }
  };

  const cultureOption = useMemo<ISelectOptionExtended>(
    () =>
      formik?.values?.cultureId
        ? { label: formik?.values?.cultureName, value: formik?.values?.cultureId }
        : null,
    [formik.values?.cultureId, formik.values?.cultureName]
  );

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Детали</ModalTitle>

        <MTextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          disabled
        />

        <AutocompleteWithSearch
          selectedValue={cultureOption}
          setSelectedOption={updateCulture}
          fetchHandler={_payload =>
            getAllDictionariesByParams('culture', {
              ..._payload,
              remoteName: 'culture',
              latestVersion: true,
              status: EDictionaryItemStatus.Active,
              originalOnly: true,
              size: 100,
              page: 0,
            })
          }
          responseMappingHandler={(data: TDictionaryItem[]) =>
            data.map(item => ({
              label: `${item?.name ? `${item.name} / ` : ''}${item.id}`,
              value: item.id,
              rawData: item,
            }))
          }
          label="Культура в Помощнике"
          fullWidth
          searchPropertyKey="nameFilter"
          isClearable
        />
        <MTextField
          label="ID 'Cropwise'"
          value={formik?.values?.cropwiseCropId || ''}
          onChange={e => formik.setFieldValue('cropwiseCropId', e.target.value)}
          type="number"
          fullWidth
        />
        <MTextField
          label="Наименование 'Cropwise'"
          value={formik?.values?.cropwiseHeader || ''}
          onChange={e => formik.setFieldValue('cropwiseHeader', e.target.value)}
          fullWidth
        />
      </Box>

      <ModalFooter
        successButton={{
          title: 'Сохранить',
          handler: () => {
            formik.submitForm();
          },
          disabled: isProcessing,
        }}
        denyButton={{
          title: 'Закрыть',
          handler: () => {
            closeModal();
          },
        }}
      />
    </Box>
  );
};

export default observer(CultureModalForm);
