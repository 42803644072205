import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import 'moment/locale/ru';
import { observer } from 'mobx-react';
import { Colors } from '@farmlink/farmik-ui';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { toJS } from 'mobx';

import { ICropwiseConfigForm } from '../../models';
import { useStore } from '../../../../../../../../../shared/utils';
import { CropwiseReportStore } from '../../mobx';
import { DEFAULT_FORM_CROPWISE_CONFIG } from '../../constants';
import useCropwiseConfig from '../../hooks/useCropwiseConfig';

interface IProps {
  id: string;
  onOpenHistory: (id: string) => void;
}

// @ts-ignore
const validationSchema: yup.SchemaOf<Partial<ICropwiseConfigForm>> = yup.object().shape({
  organizationId: yup.string().nullable(),
  username: yup.string().required('Заполните это поле'),
  password: yup.string().required('Заполните это поле'),
  enabled: yup.boolean().required('Заполните это поле'),
  seasons: yup.array().required('Заполните это поле').min(1, 'Выберите хотя бы один сезон'),
  fromDate: yup.string().required('Заполните это поле'),
  toDate: yup.string(),
  reportsLocation: yup.string().required('Заполните это поле'),
  filesLocation: yup.string().required('Заполните это поле'),
  canDelete: yup.boolean().notRequired(),
  state: yup.string().oneOf(['create', 'edit']).notRequired(),
  id: yup.string(),
  organizationName: yup.string().notRequired(),
  passwordFilled: yup.boolean().notRequired(),
});

const ReportCropwiseConfigForm: FC<IProps> = ({ id, onOpenHistory }) => {
  const store = useStore(CropwiseReportStore);
  const { organizationId } = useParams<{ organizationId: string }>();

  const values = store.getConfigById(id) || DEFAULT_FORM_CROPWISE_CONFIG;

  const [isCollapsed, setIsCollapsed] = useState(values?.state === 'edit');

  const formik = useFormik<Partial<ICropwiseConfigForm>>({
    initialValues: values,
    onSubmit: () => {
      console.log('13');
    },
    validate: () => {
      return null;
    },
    validationSchema,
  });

  const actions = useCropwiseConfig({ config: formik.values, organizationId });

  const years = useMemo<number[]>(() => {
    const currentYear = moment().add(20, 'y').get('year');
    const _years = [];

    for (let i = 0; i < 41; i++) {
      _years.unshift(currentYear - i);
    }

    return _years;
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: Colors.secondaryGray,
        padding: '12px',
        borderRadius: 2,
        position: 'relative',
      }}
    >
      {isCollapsed ? (
        <Grid item xs={5}>
          <Box
            display={'flex'}
            gap={2}
            alignItems={'center'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            position={'relative'}
          >
            <Tooltip title="Развернуть">
              <IconButton>
                <AspectRatioIcon />
              </IconButton>
            </Tooltip>

            <Box display={'flex'} flexDirection={'column'}>
              <Typography>
                <b>Логин: </b>
                {!formik?.values?.username || formik?.values?.username === ''
                  ? 'Не указано'
                  : formik?.values?.username}
              </Typography>
              <Typography>
                <b>Даты выгрузок: </b>c {formik?.values?.fromDate ?? 'Не указано'} по{' '}
                {formik?.values?.toDate ?? 'Не указано'}
              </Typography>

              <Typography>
                <b>Сезоны: </b>
                {formik?.values?.seasons?.length > 0
                  ? toJS(formik?.values?.seasons).sort().join(', ')
                  : 'Не указано'}
              </Typography>
              <Typography fontSize={12}>
                <b>ID: </b>
                {!formik?.values?.id || formik?.values?.id === ''
                  ? 'Не указано'
                  : formik?.values?.id}
              </Typography>
            </Box>
            {formik.values.state === 'create' && (
              <Tooltip title="Эта запись ещё не сохранена" placement="top">
                <FiberNewIcon sx={{ position: 'absolute', top: '-10px', left: '8px' }} />
              </Tooltip>
            )}
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              name="id"
              label="ID"
              value={formik?.values?.id || ''}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Tooltip title="Свернуть">
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <AspectRatioIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={5}>
            <TextField
              label="Логин в системе Cropwise"
              value={formik.values.username || ''}
              onChange={event => {
                formik.setFieldValue('username', event.target.value);
                store.addTouchedConfigFields(id, 'username');
              }}
              fullWidth
              error={Boolean(formik.errors?.username)}
              helperText={formik.errors?.username}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Пароль в системе Cropwise"
              onChange={event => {
                formik.setFieldValue('password', event.target.value);
                store.addTouchedConfigFields(id, 'password');
              }}
              value={formik.values?.password ?? ''}
              onFocus={() => {
                if (formik.values?.passwordFilled) {
                  formik.setFieldValue('password', formik.values?.password.replaceAll('**', ''));
                }
              }}
              fullWidth
              error={Boolean(formik.errors?.password)}
              helperText={formik.errors?.password}
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={years}
              value={formik.values.seasons}
              multiple
              disableCloseOnSelect
              getOptionLabel={option => String(option)}
              onOpen={() => {
                setTimeout(() => {
                  const optionEl = document.querySelector(
                    `#report-cropwise-years-${moment().get('year')}`
                  );
                  optionEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 1);
              }}
              onChange={(e, value) => {
                formik.setFieldValue('seasons', value);
                store.addTouchedConfigFields(id, 'seasons');
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} id={`report-cropwise-years-${option}`}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Сезон"
                  placeholder="Выберите сезоны"
                  error={Boolean(formik.errors?.seasons)}
                  helperText={formik.errors?.seasons}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              control={
                <Switch
                  name="enabled"
                  checked={Boolean(formik.values.enabled)}
                  onChange={(e, value) => {
                    actions.toggleAutoUpload?.(value);

                    formik.setFieldValue('enabled', value);
                    store.addTouchedConfigFields(id, 'enabled');
                  }}
                />
              }
              label="Автоматическая выгрузка"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Путь до папки автоматической выгрузки клиента"
              onChange={event => {
                formik.setFieldValue('reportsLocation', event.target.value);
                store.addTouchedConfigFields(id, 'reportsLocation');
              }}
              value={formik.values.reportsLocation}
              fullWidth
              error={Boolean(formik.errors?.reportsLocation)}
              helperText={formik.errors?.reportsLocation}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Путь до папки выгрузки фотоматериалов"
              onChange={event => {
                formik.setFieldValue('filesLocation', event.target.value);
                store.addTouchedConfigFields(id, 'filesLocation');
              }}
              value={formik.values.filesLocation}
              fullWidth
              error={Boolean(formik.errors?.filesLocation)}
              helperText={formik.errors?.filesLocation}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
            <Grid item xs={5}>
              <DatePicker
                value={formik?.values?.fromDate}
                onChange={(date: Moment) => {
                  if (date === null) {
                    formik.setFieldValue('fromDate', null);
                    store.addTouchedConfigFields(id, 'fromDate');
                  }

                  if (date) {
                    formik.setFieldValue('fromDate', date?.toDate());
                    store.addTouchedConfigFields(id, 'fromDate');
                  }
                }}
                maxDate={formik?.values?.toDate ? moment(formik?.values?.toDate) : null}
                clearable
                renderInput={props => (
                  <TextField
                    {...props}
                    label="Дата начала автоматической выгрузки отчёта"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={formik?.values?.toDate}
                onChange={(date: Moment) => {
                  if (date === null) {
                    formik.setFieldValue('toDate', null);
                    store.addTouchedConfigFields(id, 'toDate');
                  }

                  if (date) {
                    formik.setFieldValue('toDate', date?.toDate());
                    store.addTouchedConfigFields(id, 'toDate');
                  }
                }}
                clearable
                minDate={formik?.values?.fromDate ? moment(formik?.values?.fromDate) : null}
                renderInput={props => (
                  <TextField
                    {...props}
                    label="Дата окончания автоматической выгрузки отчёта"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={2} gap={2}>
            <Button onClick={() => actions.handleSubmit()} variant="contained" fullWidth>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={10} gap={2}>
            <Box display={'flex'} gap={2} justifyContent={'space-between'}>
              <Button
                disabled={formik.values.state === 'create'}
                onClick={() => onOpenHistory(id)}
                color="info"
                variant="text"
              >
                История
              </Button>
              <Button
                disabled={formik.values.state === 'edit' && !formik.values.canDelete}
                onClick={actions.handleDelete}
                color="error"
                variant="text"
              >
                Удалить
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default observer(ReportCropwiseConfigForm);
