import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Tooltip } from '@mui/material';
import { FC, memo } from 'react';

interface IProps {
  isLockOpen?: boolean;
  /**
   * Клик на открытый замочек
   */
  onLockClick?: () => void;
  /**
   * Клик на закрытый замочек
   */
  onUnlockClick?: () => void;
}

const LockAndSyncButton: FC<IProps> = ({ isLockOpen, onLockClick, onUnlockClick }) => {
  return (
    <Tooltip title={`${isLockOpen ? 'Заблокировать' : 'Разблокировать'} и синхронизировать СТО`}>
      <IconButton onClick={isLockOpen ? onLockClick : onUnlockClick}>
        {isLockOpen ? (
          <LockOpenIcon sx={{ transform: 'scale(0.36)' }} color="primary" />
        ) : (
          <LockIcon sx={{ transform: 'scale(0.36)' }} color="error" />
        )}
        {
          <SyncIcon
            color={isLockOpen ? 'primary' : 'error'}
            sx={{ position: 'absolute', transform: 'scale(1.3) rotate(45deg)' }}
          />
        }
      </IconButton>
    </Tooltip>
  );
};

export default memo(LockAndSyncButton);
