import { Colors } from '@farmlink/farmik-ui';
import { Box, Typography } from '@mui/material';
import { styled as MStyled } from '@mui/styles';
import styled from 'styled-components';

const Label = MStyled(Typography)({
  fontSize: 12,
  fontWeight: 600,
  color: Colors.darkGrey,
  textAlign: 'left',
});

const Value = MStyled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: Colors.generalBlack,
  textAlign: 'left',
});

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1;
`;

const ODZItem = MStyled(Box)({
  backgroundColor: Colors.generalWhite,
  '&:hover': {
    backgroundColor: Colors.secondaryGray,
  },
  padding: '8px 10px',
  borderBottom: `1px solid ${Colors.darkGrey}`,
});

const getDotColorByType = (type: 'red' | 'yellow' | 'green') => {
  if (type === 'red') return '#ff4949';
  if (type === 'yellow') return '#ffe500';
  if (type === 'green') return '#51da58';
};

const Dot = styled.span<{ type: 'red' | 'yellow' | 'green' }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ type }) => getDotColorByType(type)};
`;

const Styled = { Label, Value, Group, ODZItem, Dot };

export default Styled;
