import { useEffect, useState } from 'react';
import { has } from 'lodash';
import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { TypeApiRequest, useStore } from '../../../../../../../../../../../../shared/utils';
import {
  ISelectOption,
  ISelectOptionExtended,
} from '../../../../../../../../../../../../../types/selectOption';
import { EmployeeController } from '../../../../../../../../../../../../controllers/employees.controller';
import {
  EEmployeeStatus,
  TypeEmployee,
} from '../../../../../../../../../../../../../api/models/employee.model';
import { OrgIntegraCropwiseService } from '../../../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';

const useModalForm = (organizationId: string, mode: 'create' | 'update', userId?: string) => {
  const service = useStore(OrgIntegraCropwiseService);
  const employeeService = useStore(EmployeeController);

  const { setNotification } = useNotificator();
  const { closeModal } = useModal();

  const [optionList, setOptionList] = useState<ISelectOptionExtended<TypeEmployee>[]>([]);
  const [agroUserName, setAgroUserName] = useState<string>(null);
  const [password, setPassword] = useState<string>(null);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [astUser, setAstUser] = useState<ISelectOption>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dirtyList, setDirtyList] = useState(new Set());

  useEffect(() => {
    if (mode === 'create') {
      setIsDisabled(
        !(
          password?.length > 0 &&
          agroUserName?.length > 0 &&
          organizationId &&
          astUser?.value?.length > 0 &&
          isIntegrated
        )
      );

      return;
    }

    if (mode === 'update') {
      setIsDisabled(!(organizationId && userId?.length > 0 && dirtyList.size > 0));
    }
  }, [agroUserName, password, astUser, isIntegrated, dirtyList]);

  const fetchHandler = () => {
    employeeService
      .fetchEmployees({ organizationID: organizationId, status: EEmployeeStatus.ACTIVE })
      .then(data => {
        setOptionList(
          data?.map(item => ({
            label: `${item.userName}/${item.userId}`,
            value: item.userId,
            rawData: item,
          }))
        );
      });
  };

  const createEmployee = () => {
    if (
      !(
        password.length > 0 &&
        agroUserName.length > 0 &&
        organizationId &&
        astUser?.value?.length > 0
      )
    ) {
      setNotification(
        getNotificatorProps(
          'Ошибка валидации. Проверьте заполненность полей',
          ENotificationStyles.Error
        )
      );

      return;
    }

    return service
      .createEmployee({
        assigneePassword: password,
        assigneeName: agroUserName,
        organizationId,
        userId: astUser.value,
      })
      .then(data => {
        setNotification(
          getNotificatorProps('Пользователь успешно добавлен', ENotificationStyles.Success)
        );

        closeModal();
        setDirtyList(new Set());

        return data;
      })
      .catch(e => {
        setNotification(
          getNotificatorProps('Ошибка добавления пользователя', ENotificationStyles.Error)
        );

        return e;
      });
  };

  const updateEmployee = () => {
    if (dirtyList.size === 0) {
      setNotification(getNotificatorProps('Нет изменений', ENotificationStyles.Error));
    }

    const payload = {
      id: userId,
      organizationId,
    } as TypeApiRequest<'updateOrgIntegraCropwiseEmployee'>;

    if (dirtyList.has('astUser')) payload.userId = astUser.value;
    if (dirtyList.has('agroUserName')) payload.assigneeName = agroUserName;
    if (dirtyList.has('agroApiKey')) payload.assigneePassword = password;
    if (dirtyList.has('isIntegrated')) payload.isIntegrated = isIntegrated;

    return service.updateEmployee(payload).then(data => {
      setDirtyList(new Set());

      closeModal();

      return data;
    });
  };

  const addDirty = (field: string) => {
    setDirtyList(new Set(dirtyList).add(field));
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  return {
    optionList,
    agroUserName,
    setAgroUserName,
    password,
    setPassword,
    isIntegrated,
    setIsIntegrated,
    astUser,
    setAstUser,
    createEmployee,
    updateEmployee,
    isDisabled,
    addDirty,
  };
};

export { useModalForm };
