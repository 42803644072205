import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

import { TypeCulture } from '../../../../../../../api/models/culture.model';
import { QuotesController } from '../../../../../../controllers/quotes.controller';
import { useStore } from '../../../../../../shared/utils';
import { FilterStore } from '../../../../../../stores/quotes/filter.store';
import { QuotesStore } from '../../../../../../stores/quotes/quotes.store';

export const notSelectedOption = { name: 'Не выбрано', id: null };

export const withoutCultureClasOption = { name: 'Без класса', id: null };

export const QuotesFilter: FC = observer(() => {
  const quotesStore = useStore(QuotesStore);
  const {
    filters: { cultureId, cultureClassId, basisId, currencyId, economicRegionId },
    clearFilters,
  } = useStore(FilterStore);

  const {
    setBasisFilter,
    setCultureClassFilter,
    setCultureFilter,
    setCurrencyFilter,
    setEconomicRegionFilter,
  } = useStore(QuotesController);

  useEffect(() => {
    clearFilters();
  }, []);

  useEffect(() => {
    quotesStore.setPage(0);
    quotesStore.fetchQuotes();
  }, [cultureId, cultureClassId, basisId, currencyId, economicRegionId]);

  const cultureValue = useMemo(() => {
    return cultureId !== null
      ? {
          id: cultureId,
          name: quotesStore.cultureList.find(item => item.id === cultureId)?.name,
        }
      : notSelectedOption;
  }, [cultureId, quotesStore.cultureList]);

  const cultureClassValue = useMemo(
    () =>
      cultureClassId !== null
        ? {
            id: cultureClassId,
            name: quotesStore.cultureClassList.find(item => item.id === cultureClassId)?.name,
          }
        : notSelectedOption,
    [cultureClassId, quotesStore.cultureClassList]
  );

  const basisValue = useMemo(
    () =>
      basisId !== null
        ? {
            id: basisId,
            name: quotesStore.basisList.find(item => item.id === basisId)?.name,
          }
        : notSelectedOption,
    [basisId, quotesStore.basisList]
  );

  const regionValue = useMemo(
    () =>
      economicRegionId !== null
        ? {
            id: economicRegionId,
            name: quotesStore.economicRegionsList.find(item => item.id === economicRegionId)?.name,
          }
        : notSelectedOption,
    [economicRegionId, quotesStore.economicRegionsList]
  );

  const cultureOptions = useMemo(() => {
    const result = [...quotesStore.cultureList];
    console.log('cultureOptions:', result);
    return result;
  }, [quotesStore.cultureList]);

  const cultureClassOptions = useMemo(() => {
    const result = [withoutCultureClasOption, ...quotesStore.cultureClassList];
    console.log('cultureClassOptions:', result);
    return result;
  }, [quotesStore.cultureClassList]);

  const basisOptions = useMemo(() => {
    const result = [notSelectedOption, ...quotesStore.basisList];
    console.log('basisOptions:', basisOptions);
    return result;
  }, [quotesStore.basisList]);

  const economicRegionsOptions = useMemo(() => {
    const result = [notSelectedOption, ...quotesStore.economicRegionsList];
    console.log('economicRegionsOptions: ', result);
    return result;
  }, [quotesStore.economicRegionsList]);

  const onSetCultureFilter = useCallback((event, value) => {
    if (value) {
      setCultureFilter(value.id);
    }
  }, []);

  const onSetCultureClassFilter = useCallback((event, value) => {
    if (value) {
      setCultureClassFilter(value.id);
    }
  }, []);

  const onSetBasisFilter = useCallback((event, value) => {
    if (value) {
      setBasisFilter(value.id);
    }
  }, []);

  const onSetEconomicRegionFilter = useCallback((event, value) => {
    if (value) {
      setEconomicRegionFilter(value.id);
    }
  }, []);

  const cultureClearIcon = useMemo(
    () => (
      <CloseIcon
        fontSize="small"
        onClick={() => {
          setCultureFilter('');
        }}
      />
    ),
    []
  );

  const cultureClassClearIcon = useMemo(
    () => (
      <CloseIcon
        fontSize="small"
        onClick={() => {
          setCultureClassFilter('');
        }}
      />
    ),
    []
  );

  const basisClearIcon = useMemo(
    () => (
      <CloseIcon
        fontSize="small"
        onClick={() => {
          setBasisFilter('');
        }}
      />
    ),
    []
  );

  const economicRegionClearIcon = useMemo(
    () => (
      <CloseIcon
        fontSize="small"
        onClick={() => {
          setEconomicRegionFilter('');
        }}
      />
    ),
    []
  );

  return (
    <Grid container spacing={2} direction={'row'} columns={5}>
      <StyledGridItem item>
        <Autocomplete
          // disableCloseOnSelect
          options={quotesStore.cultureList}
          value={cultureValue}
          getOptionLabel={(option: TypeCulture) => option.name || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          clearIcon={cultureClearIcon}
          noOptionsText="Культуры не найдены"
          onChange={onSetCultureFilter}
          renderInput={params => <TextField {...params} variant="outlined" label="Культуры" />}
        />
      </StyledGridItem>
      <StyledGridItem item>
        <Autocomplete
          // disableCloseOnSelect
          options={cultureClassOptions}
          value={cultureClassValue}
          getOptionLabel={(option: TypeCulture) => option.name || ''}
          clearIcon={cultureClassClearIcon}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="Классы не найдены"
          onChange={onSetCultureClassFilter}
          renderInput={params => <TextField {...params} variant="outlined" label="Классы" />}
        />
      </StyledGridItem>

      <StyledGridItem item>
        <Autocomplete
          // disableCloseOnSelect
          options={quotesStore.basisList}
          value={basisValue}
          getOptionLabel={(option: TypeCulture) => option.name || ''}
          clearIcon={basisClearIcon}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="Базисы не найдены"
          onChange={onSetBasisFilter}
          renderInput={params => <TextField {...params} variant="outlined" label="Базис" />}
        />
      </StyledGridItem>

      <StyledGridItem item>
        <Autocomplete
          // disableCloseOnSelect
          options={[{ id: 'rub', name: 'Rub' }]}
          value={{ id: 'rub', name: 'Rub' }}
          getOptionLabel={(option: TypeCulture) => option.name || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          clearIcon={false}
          noOptionsText="Валюты не найдены"
          onChange={null}
          disabled={true}
          renderInput={params => <TextField {...params} variant="outlined" label="Валюта" />}
        />
      </StyledGridItem>

      <StyledGridItem item>
        <Autocomplete
          // disableCloseOnSelect
          options={quotesStore.economicRegionsList}
          value={regionValue}
          clearIcon={economicRegionClearIcon}
          getOptionLabel={(option: TypeCulture) => option.name || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="Регионы не найдены"
          onChange={onSetEconomicRegionFilter}
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Экономический регион" />
          )}
        />
      </StyledGridItem>
    </Grid>
  );
});

const StyledGridItem = styled(Grid)`
  flex-grow: 1;
`;
