import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useStore } from '../../../../../../../shared/utils';
import { NewsController } from '../../../../../../../controllers/news.controller';
import { TagsController } from '../../../../../../../controllers/tags.controller';
import { FilterStore } from '../../../../../../../stores/news/filter.store';
import { TagsStore } from '../../../../../../../stores/tags/tags.store';
import { TypeStatus } from '../../../../../../../../api/models/status.model';
import { TypeTag } from '../../../../../../../../api/models/tags.model';

export const NewsFilter: FC = observer(() => {
  const { setFilterTags, setFilterStatus, setFilterContext } = useStore(NewsController);
  const { fetchTags } = useStore(TagsController);
  const { tags, isLoaded } = useStore(TagsStore);

  const { statusOptions, filters } = useStore(FilterStore);

  const handleTagChange = (_, value: TypeTag[]) => setFilterTags(value);

  const handleStatusChange = (_, value: TypeStatus[]) => setFilterStatus(value);

  const handleContextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterContext(event.target.value);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="context"
          variant="outlined"
          label="Наименование новости"
          placeholder="Поиск по наименованию новости"
          value={filters.context}
          onChange={handleContextChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          multiple
          limitTags={2}
          disableCloseOnSelect
          options={statusOptions}
          value={toJS(filters.status)}
          getOptionLabel={(option: TypeStatus) => option.statusName}
          isOptionEqualToValue={(option, value) => option.statusId === value.statusId}
          noOptionsText="Статусов не найдено"
          onChange={handleStatusChange}
          renderInput={params => <TextField {...params} variant="outlined" label="Все статусы" />}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          multiple
          limitTags={2}
          disableCloseOnSelect
          options={tags}
          value={toJS(filters.tags)}
          getOptionLabel={(option: TypeTag) => option.tagName}
          isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
          loading={isLoaded}
          loadingText="Загрузка..."
          noOptionsText="Тегов не найдено"
          onChange={handleTagChange}
          renderInput={params => <TextField {...params} variant="outlined" label="Теги" />}
        />
      </Grid>
    </Grid>
  );
});
