import { Box, IconButton, Tooltip } from '@mui/material';
import { FC, useState, MouseEvent, memo } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { SvgIconComponent } from '@mui/icons-material';

interface IAdditionalAdornmentButton {
  icon: SvgIconComponent;
  onClick?: () => void;
  tooltip?: string;
  isDisabled?: boolean;
  isHided?: boolean;
}

interface IProps {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onLockClick?: () => void;
  onUnlockClick?: () => void;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hideLockToggler?: boolean;
  isLockOpen?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disableLockToggler?: boolean | { isLockOpen?: boolean; isLockClose?: boolean };
  tooltipConfig?: { lockToggler?: { isLockOpen?: string; isLockClose?: string } };
  isReverseDeleteButton?: boolean;
  additionalButtonList?: IAdditionalAdornmentButton[];
}

const AdornmentButtons: FC<IProps> = ({
  hideDelete,
  hideEdit,
  onDeleteClick,
  onEditClick,
  hideLockToggler,
  isLockOpen,
  onLockClick,
  onUnlockClick,
  disableDelete,
  disableEdit,
  disableLockToggler,
  tooltipConfig,
  isReverseDeleteButton,
  additionalButtonList,
}) => {
  const [isDeletingStage, setIsDeletingStage] = useState(false);

  const handleActionClick = (action?: () => void) => (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    action?.();
    setIsDeletingStage(false);
  };

  const renderLockButton = () => {
    const isDisabled =
      typeof disableLockToggler === 'object'
        ? isLockOpen
          ? disableLockToggler.isLockOpen
          : disableLockToggler.isLockClose
        : disableLockToggler;
    const Icon = isLockOpen ? LockOpenOutlinedIcon : LockOutlinedIcon;
    const color = isLockOpen ? 'primary' : 'error';
    const onClick = isLockOpen ? onLockClick : onUnlockClick;
    const tooltip = isLockOpen
      ? tooltipConfig?.lockToggler?.isLockOpen ?? 'Заблокировать'
      : tooltipConfig?.lockToggler?.isLockClose ?? 'Разблокировать';

    return (
      <Tooltip title={tooltip}>
        <IconButton onClick={handleActionClick(onClick)} color={color} disabled={isDisabled}>
          <Icon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Box
      gap={0}
      display="flex"
      flexDirection={isDeletingStage && isReverseDeleteButton ? 'row-reverse' : 'row'}
    >
      {isDeletingStage ? (
        <>
          <IconButton onClick={handleActionClick(onDeleteClick)}>
            <DoneIcon />
          </IconButton>
          <IconButton onClick={() => setIsDeletingStage(false)}>
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <>
          {additionalButtonList?.map(
            ({ icon: Icon, isDisabled, isHided, onClick, tooltip }, i) =>
              !isHided && (
                <Tooltip key={i} title={tooltip ?? ''}>
                  <IconButton onClick={onClick} disabled={isDisabled}>
                    <Icon />
                  </IconButton>
                </Tooltip>
              )
          )}
          {!hideEdit && (
            <IconButton onClick={handleActionClick(onEditClick)} disabled={disableEdit}>
              <CreateIcon />
            </IconButton>
          )}
          {!hideDelete && (
            <IconButton onClick={() => setIsDeletingStage(true)} disabled={disableDelete}>
              <DeleteIcon />
            </IconButton>
          )}
          {!hideLockToggler && renderLockButton()}
        </>
      )}
    </Box>
  );
};

export default memo(AdornmentButtons);
