import styled from 'styled-components';

const Title = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: #151f32;
  white-space: pre-wrap;
`;

const Styled = { Title };

export default Styled;
