import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { ICoreIntegrationForm } from '../../models';

import 'moment/locale/ru';

moment.locale('ru');

const CoreIntegrationForm: FC<{
  formikConfig: FormikProps<Partial<ICoreIntegrationForm>>;
}> = ({ formikConfig }) => {
  const { values } = formikConfig;

  const isStartTimeError = values.isEnabled && !values.startTime;

  return (
    <Box>
      <Box display={'flex'} gap={2} maxWidth={800}>
        <TextField
          required
          disabled
          value={values?.organizationName ?? ''}
          label="Название организации"
          fullWidth
        />
        <TextField
          required
          disabled
          value={values?.organizationINN ?? ''}
          label="ИНН организации"
          fullWidth
        />
      </Box>
      <Box display={'flex'} gap={2} maxWidth={800} marginTop={2}>
        <FormControlLabel
          control={
            <Switch
              checked={values.isEnabled || false}
              onChange={(_, val) => formikConfig.setFieldValue('isEnabled', val)}
            />
          }
          label="Включить интеграцию"
          labelPlacement="start"
        />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <DateTimePicker
            value={values?.startTime ? moment(values?.startTime) : null}
            onChange={(date: Moment) => {
              formikConfig.setFieldValue('startTime', date ? date?.toISOString(true) : null);
            }}
            maxDateTime={
              formikConfig?.values?.endTime ? moment(formikConfig?.values?.endTime) : null
            }
            maxTime={formikConfig?.values?.endTime ? moment(formikConfig?.values?.endTime) : null}
            renderInput={props => (
              <TextField
                {...props}
                label="Дата и время активации"
                error={isStartTimeError}
                fullWidth
              />
            )}
            disabled={!values?.isEnabled}
          />
          <DateTimePicker
            value={values?.endTime ? moment(values?.endTime) : null}
            onChange={(date: Moment) => {
              formikConfig.setFieldValue('endTime', date ? date?.toISOString(true) : null);
            }}
            minDateTime={
              formikConfig?.values?.startTime ? moment(formikConfig?.values?.startTime) : null
            }
            minTime={
              formikConfig?.values?.startTime ? moment(formikConfig?.values?.startTime) : null
            }
            renderInput={props => (
              <TextField {...props} label="Дата и время деактивации" fullWidth />
            )}
            disabled={!values?.isEnabled}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default CoreIntegrationForm;
