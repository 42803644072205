import { TypeApiRoute } from '../../models/type.api.request';

import { IChecklist } from './../../models/checklist.model';

type TypeRequest = {
  id: string;
};

type TypeResponse = IChecklist;

type TypeError = {};

export const getChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/checklist/${params.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
