import { EChecklistAttributeType } from '../../../../../../../api/models/checklist.attribute.model';
import { EFormulaType, IAggregationFormulaType } from '../models';

export const formulaTypeRequiring: Record<
  EFormulaType,
  Set<keyof Partial<IAggregationFormulaType>>
> = {
  ATTRIBUTE: new Set(['type', 'returnType', 'attrId']),
  CONST: new Set(['type', 'returnType', 'constantValue']),
  DEPENDENCY: new Set(['type', 'returnType', 'dependencyType']),
  FUNCTION: new Set(['type', 'returnType', 'functionType', 'args']),
  PROPERTY: new Set(['type', 'returnType', 'attrId', 'propertyName']),
  SELF_VALUE: new Set(['type', 'returnType', 'property']),
};

export const AVAILABLE_CALCULATE_ATTRIBUTE_TYPE: EChecklistAttributeType[] = [
  EChecklistAttributeType.Int,
  EChecklistAttributeType.Double,
];

export const AVAILABLE_DEPENDENCY_ATTRIBUTE_TYPE: EChecklistAttributeType[] = [
  EChecklistAttributeType.Enum,
  EChecklistAttributeType.DictionaryLink,
];

export const AVAILABLE_ODZ_ATTRIBUTE_TYPE: EChecklistAttributeType[] = [
  EChecklistAttributeType.Int,
  EChecklistAttributeType.Double,
  EChecklistAttributeType.Enum,
  EChecklistAttributeType.DictionaryLink,
];
