import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../constants/colors';

export const StyledLink = styled(Link)`
  color: ${Colors.black};

  :hover {
    ${Colors.gray}
  }
`;
