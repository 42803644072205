import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import { ReactComponent as DATitleSvg } from '../../../../static/da-title.svg';

import { PhoneSignIn } from './components/PhoneSignIn';
import { EmailSignIn } from './components/EmailSignIn';
import { FormWrapper, LogoWrapper } from './style';

export type SignInType = 'email' | 'phone';

export const SignInPage: FC = observer(() => {
  const [type, setType] = useState<SignInType>('phone');

  const renderSignInType = (v: SignInType) => {
    const variants: Record<SignInType, any> = {
      email: <EmailSignIn onChangeType={() => setType('phone')} />,
      phone: <PhoneSignIn onChangeType={() => setType('email')} />,
    };

    return (
      <FormWrapper>
        <LogoWrapper>
          <DATitleSvg />
        </LogoWrapper>

        {variants[v]}
      </FormWrapper>
    );
  };

  return renderSignInType(type);
});
