import { useModal } from '@farmlink/farmik-ui';
import { v4 } from 'uuid';
import { runInAction } from 'mobx';

import { BEFORE_DELETE_ODZ_MODAL } from '../modals';
import { AttributeODZStore } from '../mobx';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { EODZCriteria } from '../models';
import { IFormulaError } from '../../../../../../../../modules/Formulas/models';

const useODZContainer = () => {
  const store = useStore(AttributeODZStore);

  const { openModalByModalId } = useModal();

  const removeODZ = (type: EODZCriteria) => {
    openModalByModalId(BEFORE_DELETE_ODZ_MODAL, null, () => {
      switch (type) {
        case EODZCriteria.Red:
          runInAction(() => {
            store.setRedCriteria(null);
            store.setRedSerializedCriteria(null);
          });
          break;

        case EODZCriteria.Yellow:
          store.setYellowCriteria(null);
          store.setYellowSerializedCriteria(null);
          break;

        case EODZCriteria.Green:
          store.setGreenCriteria(null);
          store.setGreenSerializedCriteria(null);
          break;

        default:
          break;
      }
      _clearErrorsAfterRemoveODZ(type);
    });
  };

  const _clearErrorsAfterRemoveODZ = (type: EODZCriteria) => {
    store.errorLog.forEach((error, errorId) => {
      if (error.criteria === type) {
        store.removeErrorFromLog(errorId);
      }
    });
  };

  const addError = (type: EODZCriteria, error: IFormulaError, id?: string) => {
    const errorId = id || v4();

    store.addErrorToLog(errorId, { ...error, criteria: type });

    return errorId;
  };

  const removeError = (errorId: string) => {
    store.removeErrorFromLog(errorId);
  };

  return { removeODZ, addError, removeError };
};

export default useODZContainer;
