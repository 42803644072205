import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CropwiseReportForm } from './components';

const CROPWISE_REPORT_MODAL_ID = 'cropwise-report-modal';

const cropwiseReportModalConfig: TModalConfig = {
  id: CROPWISE_REPORT_MODAL_ID,
  name: CROPWISE_REPORT_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  children: CropwiseReportForm,

  styledProps: {
    $modalPaddings: '20px',
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '100%',
  },
};

export { CROPWISE_REPORT_MODAL_ID, cropwiseReportModalConfig };
