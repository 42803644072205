import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const GridWrapper = styled.div`
  height: calc(100vh - 303px);
`;

const PhoneCell = styled.a`
  color: ${Colors.black};
`;

const PhoneCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Styled = { GridWrapper, PhoneCell, PhoneCellWrapper, ActionButtonWrapper };

export default Styled;
