import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = { ids: string[] };
type TypeResponse = string;

export const deleteCropwiseReportOperationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-cropwise/importOperations/deleteAllInBatch`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
