import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_DELETE_ODZ_MODAL = 'before-delete-ODZ-modal-id';

const beforeDeleteODZModal: TModalConfig = {
  id: BEFORE_DELETE_ODZ_MODAL,
  name: BEFORE_DELETE_ODZ_MODAL,
  type: EModalType.Warning_Before_Living,
  title: 'Вы уверены, что хотите удалить формулу?',
  description: {
    value: 'Комментарий будет сохранён',
    $styles: {
      textAlign: 'center',
      marginBottom: '12px',
    },
  },
  successButton: {
    title: 'Удалить',
    color: 'primary',
  },
  denyButton: {
    title: 'Закрыть',
    color: 'secondary',
  },
};

export { BEFORE_DELETE_ODZ_MODAL, beforeDeleteODZModal };
