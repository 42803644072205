import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  userId: string;
  ids: string[];
};

type TypeResponse = {};

type TypeError = {};

export const saveInterestsUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-profile/users/admin/userInterests/${request.userId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
