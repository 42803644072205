import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Styled = {
  Row,
};

export default Styled;
