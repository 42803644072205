import { useEffect, useState } from 'react';

import { useStore } from '../../../../../../../../../../../shared/utils';
import { FormulasController } from '../../../../../../mobx';
import { IAggregationFormulaType, IFormulaError } from '../../../../../../models';

const useErrorHandler = (
  errorContainerId: string,
  formula: IAggregationFormulaType,
  handleSetError: (error: IFormulaError, id?: string) => string,
  handleRemoveError: (id: string) => void
) => {
  const controller = useStore(FormulasController);
  const [currentError, setCurrentError] = useState<IFormulaError>(null);

  useEffect(() => {
    return () => {
      handleRemoveError(errorContainerId);
    };
  }, []);

  useEffect(() => {
    // const error = controller.validateFormulaBlock(formula);
    // if (error) {
    //   handleSetError(error);
    //   setCurrentError(error);
    // } else {
    //   setCurrentError(null);
    //   handleRemoveError(errorContainerId);
    // }
  }, [formula]);

  return { currentError };
};

export default useErrorHandler;
