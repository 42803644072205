import { ReactElement } from 'react';
import { GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';

export const getColumns = (renderActions: (params: GridRowParams) => ReactElement[]) => [
  {
    field: 'culture',
    type: 'string',
    headerName: 'Культура',
    width: 150,
    sortable: false,
  },
  {
    field: 'class',
    type: 'string',
    headerName: 'Класс',
    flex: 1,
    minWidth: 300,
    sortable: false,
  },
  {
    field: 'basis',
    type: 'string',
    headerName: 'Базис',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'region',
    type: 'string',
    headerName: 'Регион',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },

  {
    field: 'date',
    type: 'string',
    headerName: 'Дата',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'units',
    type: 'string',
    headerName: 'Единицы',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'cost',
    type: 'string',
    headerName: 'Стоимость',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Действия',
    width: 200,
    sortable: false,
    getActions: renderActions,
  },
];
