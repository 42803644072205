import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { FC } from 'react';

interface IProps<K> {
  mode: 'depend' | 'dependent';
  columns: GridColumns<K>;
  rows: K[];
}

const DependenciesGrid: FC<IProps<Record<string, any>>> = ({ columns, rows }) => {
  return (
    <div style={{ display: 'flex', height: '95%', minHeight: '500px' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          columns={columns}
          rows={rows}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          localeText={{ noRowsLabel: 'Нет результатов' }}
        />
      </div>
    </div>
  );
};

export default DependenciesGrid;
