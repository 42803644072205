import { FC } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Grid, Autocomplete, TextField } from '@mui/material';

import { useStore } from '../../../../../../../shared/utils';
import { FilterStore } from '../../../../../../../stores/checkLists/filter.store';
import {
  TypeCheckListStatus,
} from '../../../../../../../../api/models/check.list.model';
import { CheckListsController } from '../../../../../../../controllers/check.list.controller';

export const CheckListFilter: FC = observer(() => { 
  const { setFilterStatus } = useStore(CheckListsController);
  
  const {
    statusOptions,
    filters: { status },
  } = useStore(FilterStore);

  const handleStatusChange = (_, value: TypeCheckListStatus[]) => setFilterStatus(value)

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={6}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          disableClearable
          options={statusOptions}
          value={toJS(status)}
          getOptionLabel={(option: TypeCheckListStatus) => option.statusName}
          isOptionEqualToValue={(option, value) => option.statusId === value.statusId}
          noOptionsText="Статусов не найдено"
          renderInput={params => <TextField {...params} variant="outlined" label="Статус" />}
          onChange={handleStatusChange}
        />
      </Grid>
    </Grid>
  );
});
