import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  alertId: string;
  status: string;
};

type TypeResponse = {
    status: string;
};

type TypeError = {};

export const changeAlertStatus: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/as-fields/queue/task/admin/${request.alertId}/${request.status}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
