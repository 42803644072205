enum EImportOperationType {
  NOTES_IMPORT = 'NOTES_IMPORT',
  FILES_IMPORT = 'FILES_IMPORT',
  FULL_IMPORT = 'FULL_IMPORT',
}

enum EImportOperationStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

interface IImportOperationModel {
  id: string;
  type: EImportOperationType;
  isAuto: boolean;
  clientId: number;
  name: string;
  host: string;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  reportsLocation: string;
  filesLocation: string;
  creationDate: string;
  operationStatus: EImportOperationStatus;
  message: string;
  canDelete: boolean;
}

interface IPogodaVPoleImportOperationModel {
  id: string;
  dateFrom: string;
  dateTo: string;
  creationDate: string;
  status: EImportOperationStatus;
  message: string;
}

interface IAgropogodaImportOperationModel {
  id: string;
  ownerId: string;
  fromDate: string;
  toDate: string;
  reportLocation: string;
  creationDate: string;
  status: EImportOperationStatus;
  message: string;
  isAuto: boolean;
  zondId: string;
}

interface IAssistanceFieldsOperationHistoryModel {
  id: string;
  organizationId: string;
  seasons: string[];
  reportsLocation: string;
  creationDate: string;
  startDate: string;
  endDate: string;
  operationStatus: EImportOperationStatus;
  message: string;
  config: {
    id: string;
    organizationId: string;
    seasons: string[];
    enabled: true;
    reportsLocation: string;
  };
}

export type {
  IImportOperationModel,
  IPogodaVPoleImportOperationModel,
  IAgropogodaImportOperationModel,
  IAssistanceFieldsOperationHistoryModel,
};

export { EImportOperationType, EImportOperationStatus };
