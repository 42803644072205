import { EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const NE: IFormulaFunction = {
  name: 'NE',
  label: 'Неравенство',
  argsType: [
    EFormulaType.Attribute,
    EFormulaType.Const,
    EFormulaType.Function,
    EFormulaType.Property,
  ],
  minArguments: 2,
  maxArguments: 2,
  primitiveType: [
    EFormulaReturnType.Boolean,
    EFormulaReturnType.Date,
    EFormulaReturnType.Double,
    EFormulaReturnType.Integer,
    EFormulaReturnType.String,
    EFormulaReturnType.Uuid,
  ],
  availableSiblings: {
    [EFormulaReturnType.Boolean]: [EFormulaReturnType.Boolean],
    [EFormulaReturnType.Double]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
    [EFormulaReturnType.Integer]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
    [EFormulaReturnType.String]: [EFormulaReturnType.String],
    [EFormulaReturnType.Date]: [EFormulaReturnType.Date],
    [EFormulaReturnType.Uuid]: [EFormulaReturnType.Uuid],
  },
  returnType: [EFormulaReturnType.Boolean],
};
