import { EChecklistType, IChecklistSettingsEntityShort } from '../../models/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  name: string;
  type: EChecklistType;
  checkListSettings: IChecklistSettingsEntityShort[];
  organizationIds: string[];
  machinery?: boolean;
  isDefault?: boolean;
  isNested?: boolean;
};

type TypeResponse = string;

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const createChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/checklist/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
