import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { Button, IconButton, Tab, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { isNil, maxBy, omitBy } from 'lodash';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReplyIcon from '@mui/icons-material/Reply';
import { generatePath } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toJS } from 'mobx';

import {
  EChecklistAttributePositionWidth,
  EChecklistAttributeType,
  IAttribute,
} from '../../../../../../../../../../../api/models/checklist.attribute.model';
import {
  EChecklistType,
  IChecklistAttribute,
} from '../../../../../../../../../../../api/models/checklist.model';
import { ChecklistCRUDController } from '../../../../../../../../../../controllers/checklist.CRUD.controller';
import { ChecklistsAttributeController } from '../../../../../../../../../../controllers/checklistAttribute.controller';
import { DictionariesController } from '../../../../../../../../../../controllers/dictionaries.controller';
import {
  deepObjectsDiff,
  getFormikDirtyValues,
  useStore,
} from '../../../../../../../../../../shared/utils';
import { FormInput, FormRow } from '../../../../../../../common';
import {
  attributeInChecklistFormItemsScheme,
  checklistsAttributeBuilderSchemeMap,
} from '../../../AttributeBuilderForm.config';
import { Colors } from '../../../../../../../../../../../constants/colors';
import { AdminRoutes } from '../../../../../../../../../routes';
import { ChecklistAttributesStore } from '../../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';
import SplitDropdownButton from '../../../../../../../../../../shared/components/SplitDropdownButton/SplitDropdownButton';

import { PositionOption } from './components';
import UserDictionaryLinkTab from './components/UserDictionaryLinkTab/UserDictionaryLinkTab';
import { TFormikDataType } from './models/form.model';

const CreateAttributeModal = observer(() => {
  const { closeModal } = useModal();
  const { setNotification } = useNotificator();

  const {
    fetchChecklistStageList,
    currentChecklistPropertySwitches,
    currentChecklist,
    stageData,
    getChecklistById,
  } = useStore(ChecklistCRUDController);
  const { getAllDictionaries } = useStore(DictionariesController);
  const {
    currentChecklistAttributeEdit,
    checklistAttributeListByStageId,
    addAttributeListToStage,
    addChecklistAttributeListWithoutStage,
    changeAttributeInChecklist,
    clearCurrentChecklistAttributeEditFileList,
    checklistAttributeListByAllStages,
    checklistAttributeListWithoutStage,
    fetchCurrentChecklistAttributeUserDictionaryLinkValueList,
    getActiveChecklistAttributeList,
    getChecklistAttributeList,
    createAttributeInChecklist,
    getAttributeInChecklist,
  } = useStore(ChecklistsAttributeController);
  const {
    currentChecklistBasicAttributeIdListByStage,
    getExistsBasicAttributeIdListInStage,
    addCurrentChecklistBasicAttributeIdListByStage,
  } = useStore(ChecklistAttributesStore);

  const [defaultAttribute, setDefaultAttribute] = useState<IAttribute>(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tab, setTab] = useState('1');

  const {
    id,
    attribute,
    displayParent,
    isActive,
    isRequired,
    isTitle,
    max,
    min,
    normal,
    order,
    toolTip,
    position,
    stageId,
  } = currentChecklistAttributeEdit || {};

  const maxOrder = useMemo(() => {
    return currentChecklist?.type === EChecklistType.Nested
      ? maxBy(checklistAttributeListWithoutStage.flatMap(item => item?.order || 0))
      : stageId
      ? maxBy(checklistAttributeListByStageId.get(stageId).flatMap(item => item?.order || 0))
      : maxBy(checklistAttributeListByAllStages.flatMap(item => item?.order || 0)) || 0;
  }, [
    checklistAttributeListByStageId,
    checklistAttributeListByAllStages,
    checklistAttributeListWithoutStage,
    stageId,
  ]);

  const isBasicAttributeExisted = useCallback(
    (basicId: string) =>
      getExistsBasicAttributeIdListInStage(stageData?.id ?? 'noStage')?.has(basicId),
    [stageData, currentChecklistBasicAttributeIdListByStage]
  );

  const initialValues = useMemo<TFormikDataType>(
    () => ({
      id: id || null,
      attributeId: attribute?.id || null,
      checkListId: currentChecklist?.id || null,
      // dependencyAttributeId: dependencyAttributeId || null,
      // dependencyType: (dependencyType as EAttributeInChecklistDependencyType) || null,
      displayParent: displayParent || null,
      isActive: isActive || null,
      isRequired: isRequired || null,
      isTitle: isTitle || null,
      isMultiselect: Boolean(attribute?.isMultiselect),
      max: max || null,
      min: min || null,
      normal: normal || null,
      order: isNil(order) && order !== '' ? maxOrder + 1 : order,
      stageId: stageData?.id || currentChecklistAttributeEdit?.stageId || null,
      stage: stageData?.name || null,
      toolTip: toolTip || null,
      type: attribute?.type || null,
      name: attribute?.name || null,
      calculationType: attribute?.calculationType || null,
      checklistLink: attribute?.checklistLink || null,
      position: {
        parentId: position?.parentId ?? null,
        separate: position?.separate ?? true,
        newLine: position?.newLine ?? false,
        width: position?.width ?? EChecklistAttributePositionWidth.Full,
      },
    }),
    []
  );

  const isDefault = currentChecklistPropertySwitches?.default;
  const isEditMode = Boolean(id);
  const isDisplayUserDictionaryLinkParams =
    isEditMode && attribute.type === EChecklistAttributeType.UserDictionaryLink;

  const formik = useFormik<TFormikDataType>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (formData, { setSubmitting }) => {
      // TODO Sanitize formData

      setErrorMessage(null);
      if (isEditMode) {
        const changedData: any = getFormikDirtyValues(formData, initialValues);

        if (changedData?.position) {
          changedData.position = formData.position;
        }

        changeAttributeInChecklist(
          { ...changedData, id, order: values?.order },
          formData.type,
          setNotification
        )
          .then(data => {
            if (data?.stageId) {
              addAttributeListToStage(data?.stageId, [data] as IChecklistAttribute[]);
              addCurrentChecklistBasicAttributeIdListByStage(data.stageId, [data.attribute.id]);
            }

            closeModal();
          })
          .catch((e: (Error | AxiosError) & { cause: any }) => {
            if (e.cause) {
              setFieldError(e.cause as string, e.message);
            } else {
              setErrorMessage((e as AxiosError)?.response?.data?.message || e.message);
            }
          })
          .finally(() => {
            setSubmitting(false);
            setFieldValue('syncSto', false);
          });
      } else {
        const purifiedData = omitBy(formData, item => isNil(item)) as TFormikDataType;

        if (stageData?.id) {
          purifiedData.stageId = stageData.id;
        }

        purifiedData.checkListId = currentChecklist.id;

        delete purifiedData.validationComment;
        delete purifiedData.validationRedCriteria;
        delete purifiedData.validationYellowCriteria;
        delete purifiedData.validationGreenCriteria;
        delete purifiedData.isCriteriaEnabled;

        createAttributeInChecklist(purifiedData)
          ?.then(data => {
            if (stageData?.id) {
              addAttributeListToStage(stageData.id, [data] as IChecklistAttribute[]);
              addCurrentChecklistBasicAttributeIdListByStage(stageData.id, [data.attribute.id]);
              closeModal();
            } else {
              addChecklistAttributeListWithoutStage([data]);
              addCurrentChecklistBasicAttributeIdListByStage('noStage', [data.attribute.id]);
              closeModal();
            }
          })
          .catch((e: (Error | AxiosError) & { cause: any }) => {
            if (e.cause) {
              setFieldError(e.cause as string, e.message);
            } else {
              setErrorMessage((e as AxiosError)?.response?.data?.message || e.message);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const { submitForm, setFieldValue, values, resetForm, setFieldError, setValues } = formik;

  const selectedAttributeScheme = useMemo(
    () => (values?.type ? checklistsAttributeBuilderSchemeMap[values.type] : new Set()),
    [values?.type]
  );

  useEffect(() => {
    if (
      currentChecklistAttributeEdit?.attribute?.type === EChecklistAttributeType.UserDictionaryLink
    ) {
      fetchCurrentChecklistAttributeUserDictionaryLinkValueList({
        attributeId: currentChecklistAttributeEdit.attribute.id,
        organizationId: currentChecklist.organizations[0]?.organizationId,
      });
    }

    if (currentChecklistAttributeEdit?.attribute?.type === EChecklistAttributeType.DictionaryLink) {
      const remoteName = currentChecklistAttributeEdit?.attribute?.dictionaryLink;

      getAllDictionaries().then(data => {
        setFieldValue('dictionaryLink', remoteName);
        setFieldValue(
          'dictionaryLinkAlias',
          data.content.find(dictItem => dictItem.remoteName === remoteName)?.title || ''
        );
      });
    }

    if (currentChecklistAttributeEdit?.position?.parentId) {
      getAttributeInChecklist({ id: currentChecklistAttributeEdit?.position?.parentId }).then(
        response => {
          if (response?.attribute) {
            setFieldValue(
              'parentAttribute',
              `${response.attribute.name} / ${currentChecklistAttributeEdit?.position?.parentId}`
            );
          }
        }
      );
    }

    if (currentChecklistAttributeEdit?.attribute?.checklistLink) {
      getChecklistById(currentChecklistAttributeEdit?.attribute?.checklistLink).then(resp => {
        setFieldValue('nestedChecklistName', resp.name);
      });
    }
  }, [currentChecklistAttributeEdit]);

  // Убрал поддержку мультиселекта H15-4163
  // const isMultiselectAvailableByCalculationTypeDisabled =
  //   selectedAttributeScheme.has('isMultiselect') &&
  //   selectedAttributeScheme.has('calculationType') &&
  //   values?.calculationType === null;

  const onSelectBaseAttribute = useCallback(
    item => {
      resetForm();

      if (item?.rawData) {
        if (isBasicAttributeExisted(item.rawData.id)) {
          setFieldError('name', 'Такой базовый атрибут уже имеется в этом стейдже');
          return;
        }

        const defaultData = { ...stageData, ...item?.rawData };

        delete defaultData.id;

        setValues(defaultData);

        setFieldValue('type', item.rawData.type);
        setFieldValue('attributeId', item.rawData.id);
        setFieldValue('order', maxOrder + 1);

        setFieldValue('position', {
          parentId: position?.parentId ?? null,
          separate: position?.separate ?? true,
          newLine: position?.newLine ?? false,
          width: EChecklistAttributePositionWidth.Half,
        });

        if (stageData?.id) {
          setFieldValue('stageId', stageData?.id);
        }

        setDefaultAttribute(item.rawData);

        if (
          item.rawData.type === EChecklistAttributeType.DictionaryLink &&
          item?.rawData?.dictionaryLink
        ) {
          const remoteName = item.rawData.dictionaryLink;

          getAllDictionaries().then(data => {
            setFieldValue('dictionaryLink', remoteName);
            setFieldValue(
              'dictionaryLinkAlias',
              data.content.find(dictItem => dictItem.remoteName === remoteName)?.title || ''
            );
          });
        }
      }
    },
    [values, stageData, isBasicAttributeExisted]
  );

  const fetchBooleanAttrList = async payload => {
    const response = await getChecklistAttributeList(payload);

    response.content = response.content.filter(item => item.id !== initialValues?.id);

    return response;
  };

  const useDefaultAttributeValue = useCallback(
    (fieldName: 'toolTip' | 'placeholder') => {
      if (defaultAttribute || attribute) {
        setFieldValue(fieldName, defaultAttribute?.[fieldName] || attribute?.[fieldName] || '');
      }
    },
    [attribute, defaultAttribute]
  );

  const handleChangeTab = (event: SyntheticEvent, tabId: string) => {
    setTab(tabId);
  };

  const handleOpenBaseAttribute = useCallback(() => {
    window
      .open(
        generatePath(AdminRoutes.ChecklistBasicAttribute, { attributeId: values?.attributeId }),
        '_blank'
      )
      .focus();
  }, [values?.attributeId]);

  const handleOpenNtestedChecklist = useCallback(() => {
    window
      .open(
        generatePath(AdminRoutes.SelectedChecklist, { checklistId: values?.checklistLink }),
        '_blank'
      )
      .focus();
  }, [values?.checklistLink]);

  const isPreviousFileLink = useMemo(() => {
    const _stageId = formik?.values?.stageId;
    let attrList;

    if (_stageId) {
      attrList = toJS(checklistAttributeListByStageId.get(_stageId));
    } else {
      attrList = toJS(checklistAttributeListWithoutStage);
    }

    if (!isEditMode) {
      attrList.push(currentChecklistAttributeEdit);
    }

    attrList.sort((a, b) => Number(a?.order) - Number(b?.order));

    const _index = checklistAttributeListByAllStages
      .sort((a, b) => Number(a.order) - Number(b.order))
      .findIndex(item => Number(item?.order) === Number(formik?.values?.order));

    const prevAttribute = checklistAttributeListByAllStages[_index - 1];

    return (
      formik?.values?.type === EChecklistAttributeType.File_link &&
      prevAttribute &&
      prevAttribute.attribute.type === EChecklistAttributeType.File_link
    );
  }, [formik?.values?.order, formik?.values?.type, formik?.values?.stageId, isEditMode]);

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <SplitDropdownButton
          buttonList={[
            {
              label: 'Сохранить',
              onClickHandler: submitForm,
            },
            {
              label: 'Сохранить и применить в СТО',
              isHidden: !isEditMode,
              onClickHandler: () => {
                setFieldValue('syncSto', true);
                submitForm();
              },
            },
          ]}
        />
        <Button
          onClick={() => {
            closeModal();
            clearCurrentChecklistAttributeEditFileList();
          }}
          variant="text"
        >
          Закрыть
        </Button>
      </Box>
      {errorMessage && (
        <Typography fontSize={12} color={Colors.red}>
          {errorMessage}
        </Typography>
      )}

      <Box maxHeight="80vh" overflow="auto" width="100%" marginBottom={2} paddingTop={2}>
        <FormikProvider value={formik}>
          {/* <Button onClick={() => setFieldValue('type', 'ALL')}>All</Button> */}
          <TabContext value={tab}>
            <TabList onChange={handleChangeTab}>
              <Tab label="Параметры атрибута" value="1" />
              {isDisplayUserDictionaryLinkParams && <Tab label="Значения атрибута" value="2" />}
            </TabList>
            <TabPanel value="1">
              <Box>
                <FormRow>
                  <FormInput
                    itemKey="name"
                    attributeScheme={attributeInChecklistFormItemsScheme}
                    disabled={Boolean(id)}
                    autocompleteConfig={{
                      optionsFetchHandler: getActiveChecklistAttributeList,
                      isOnMountOptionsFetch: true,
                      onSelect: onSelectBaseAttribute,
                      searchingKey: 'name',
                      isClearable: true,
                      optionItem: {
                        tooltip: {
                          fieldName: 'description',
                          placement: 'top',
                        },
                      },
                      pagination: { size: 100 },
                    }}
                  />
                  <FormInput itemKey="type" attributeScheme={attributeInChecklistFormItemsScheme} />
                  <FormInput
                    itemKey="attributeId"
                    attributeScheme={attributeInChecklistFormItemsScheme}
                  />
                  {values?.attributeId && (
                    <Tooltip title="Открыть базовый атрибут в новом окне">
                      <IconButton onClick={handleOpenBaseAttribute}>
                        <ReplyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('calculationType') && (
                    <FormInput
                      itemKey="calculationType"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('nestedChecklistName') && (
                    <FormInput
                      itemKey="nestedChecklistName"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('checklistLink') && (
                    <FormInput
                      itemKey="checklistLink"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {values?.checklistLink && (
                    <Tooltip title="Открыть вложенный чек-лист в новом окне">
                      <IconButton onClick={handleOpenNtestedChecklist}>
                        <ReplyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('dictionaryLink') && (
                    <FormInput
                      itemKey="dictionaryLink"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('dictionaryLinkAlias') && (
                    <FormInput
                      itemKey="dictionaryLinkAlias"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('displayParent') && (
                    <FormInput
                      itemKey="displayParent"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow hide={isDefault}>
                  {selectedAttributeScheme.has('stage') && (
                    <FormInput
                      itemKey="stage"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      autocompleteConfig={{
                        optionsFetchHandler: fetchChecklistStageList,
                        isOnMountOptionsFetch: true,
                        onChangeMappingKey: 'stageId',
                        value: stageData?.name,
                        searchingParams: {
                          checklistId: currentChecklist.id,
                        },
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('stageId') && (
                    <FormInput
                      itemKey="stageId"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('order') && (
                    <FormInput
                      itemKey="order"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('id') && (
                    <FormInput itemKey="id" attributeScheme={attributeInChecklistFormItemsScheme} />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isTitle') && (
                    <FormInput
                      itemKey="isTitle"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isRequired') && (
                    <FormInput
                      itemKey="isRequired"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isMultiselect') && (
                    <FormInput
                      itemKey="isMultiselect"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      disabled={true}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('min') && (
                    <FormInput
                      itemKey="min"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('normal') && (
                    <FormInput
                      itemKey="normal"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('max') && (
                    <FormInput
                      itemKey="max"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow $maxWidth="300px">
                  {selectedAttributeScheme.has('precision') && (
                    <FormInput
                      itemKey="precision"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      disabled
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('toolTip') && (
                    <Box display="flex" flexDirection="column" width="100%">
                      <FormInput
                        itemKey="toolTip"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                      />
                      <Button
                        onClick={() => useDefaultAttributeValue('toolTip')}
                        variant="text"
                        size="small"
                        sx={{ maxWidth: 260 }}
                      >
                        Подставить из базового атрибута
                      </Button>
                    </Box>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('placeholder') && (
                    <Box display="flex" flexDirection="column" width="100%">
                      <FormInput
                        itemKey="placeholder"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                      />
                      <Button
                        onClick={() => useDefaultAttributeValue('placeholder')}
                        variant="text"
                        size="small"
                        sx={{ maxWidth: 260 }}
                      >
                        Подставить из базового атрибута
                      </Button>
                    </Box>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('description') && (
                    <FormInput
                      itemKey="description"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      disabled
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('position') && (
                    <PositionOption
                      fetchBooleanAttrList={fetchBooleanAttrList}
                      isPreviousFileLink={isPreviousFileLink}
                    />
                  )}
                </FormRow>
                <FormRow hide={!Boolean(id)}>
                  <TextField
                    disabled
                    label="Дата создания"
                    value={
                      currentChecklistAttributeEdit?.creationDate
                        ? moment(currentChecklistAttributeEdit?.creationDate).format('DD.MM.YYYY')
                        : ''
                    }
                    fullWidth
                  />
                  <TextField
                    disabled
                    label="Автор"
                    value={currentChecklistAttributeEdit?.createdBy?.fullName}
                    fullWidth
                  />
                  <TextField
                    disabled
                    label="Статус"
                    value={currentChecklistAttributeEdit?.isActive ? 'Активный' : 'Неактивный'}
                    fullWidth
                  />
                </FormRow>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <UserDictionaryLinkTab />
            </TabPanel>
          </TabContext>
        </FormikProvider>
      </Box>
    </>
  );
});

export default CreateAttributeModal;
