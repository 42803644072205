import { TypeApiRoute } from '../../../models/type.api.request';
import { IUserDictionaryItemValue } from '../../../models/checklist.attribute.model';
import { TypeResponsePageable } from '../../../models/response.pageable.model';

type TypeRequest = {
  attributeId: string;
  organizationId?: string;
  valueLike?: string;
};

type TypeResponse = TypeResponsePageable & { content: IUserDictionaryItemValue[] };

type TypeError = {};

export const getChecklistAttributeUserDictionaryList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/${request.attributeId}/userDictionaryList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
