import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router';
import { Button, Grid, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { isEmpty, includes } from 'lodash';

import { useStore, getLink } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { ExpertiseStore } from '../../../../../stores/users/expertise.store';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { ExpertiseType } from '../../../../../../api/models/expertise.model';
import { AdminRoutes, ERegexp } from '../../../../routes';
import IconDelete from '../../../../../shared/assets/images/delete.svg';
import { ExpertiseWrapper, Competition, DeleteIcon } from '../../style';
import { Layout } from '../../../../components';

export const UserExpertises: FC = observer(() => {
  const { fetchUser, fetchExpertises, saveExpertisesUserAdmin } = useStore(UsersController);
  const {
    expertises,
    searchExpertise,
    setSearchExpertise,
    isLoading,
    expertise,
    setExpertise,
    selectedExpertises,
    setSelectedExpertises,
    setUserExpertisesToSelected,
    deleteExpertise,
    reset,
  } = useStore(ExpertiseStore);
  const history = useHistory();
  const { userId } = useParams<{ userId?: string }>();

  const handleSearch = (_, value: string) => setSearchExpertise(value);
  const handleChange = (_, value: ExpertiseType) => setExpertise(value);
  const handleSetSelectedExpertises = () => {
    setSelectedExpertises(expertise);
    setExpertise(null);
  };

  const handleSaveExpertises = async () => {
    const ids = selectedExpertises.map(item => item.expertiseId);
    await saveExpertisesUserAdmin({ userId, ids });
    reset();
    history.push(
      getLink(AdminRoutes.UserEdit, {
        [userId]: ERegexp.USERID,
      })
    );
  };

  const handleCancel = () => {
    reset();
    history.push(
      getLink(AdminRoutes.UserEdit, {
        [userId]: ERegexp.USERID,
      })
    );
  };

  useEffect(() => {
    fetchUser({ userId }).then(user => {
      if (!isEmpty(user)) {
        setUserExpertisesToSelected(user.expertises);
      }
    });
  }, []);

  useEffect(() => {
    fetchExpertises();
  }, [searchExpertise]);

  const isOptionDisabled = (option: ExpertiseType) => {
    if (isEmpty(selectedExpertises)) {
      return false;
    }

    const disabledExpertises = selectedExpertises.map(({ expertiseId }) => expertiseId);
    return includes(disabledExpertises, option.expertiseId);
  };

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Компетенции</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <Grid container spacing={2} mb={20}>
            <Grid item xs={4}>
              <Autocomplete
                options={expertises}
                getOptionLabel={(option: ExpertiseType) => option.expertiseName}
                isOptionEqualToValue={(option: ExpertiseType, value) =>
                  option.expertiseName === value.expertiseName
                }
                getOptionDisabled={(option: ExpertiseType) => isOptionDisabled(option)}
                loading={isLoading}
                loadingText="Загрузка..."
                noOptionsText="Компетенций не найдено"
                value={expertise}
                onChange={handleChange}
                onInputChange={handleSearch}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Добавление новой компетенции"
                    placeholder={'Например, “Садоводство”'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8}>
              <Button
                variant="contained"
                color="primary"
                disabled={isEmpty(expertise)}
                onClick={handleSetSelectedExpertises}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>

          <ExpertiseWrapper data-test-id="edit-competitions-list">
            {!isEmpty(selectedExpertises)
              ? selectedExpertises.map(item => (
                  <Competition key={item.expertiseId} data-test-id="edit-competitions-item">
                    <DeleteIcon
                      src={IconDelete}
                      onClick={() => deleteExpertise(item.expertiseId)}
                      data-test-id="edit-competitions-item-icon"
                    />
                    <p data-test-id="edit-competitions-item-caption">{item.expertiseName}</p>
                  </Competition>
                ))
              : 'Не выбраны компетенции'}
          </ExpertiseWrapper>

          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSaveExpertises}>
                Сохранить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleCancel}>
                Отменить
              </Button>
            </Grid>
          </Grid>
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
