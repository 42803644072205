import { EChecklistType, IChecklist } from '../../models/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  settings: Array<{ operationTypeId: string; cultureId: string; phenophaseId: string }>;
  type: EChecklistType;
};

type InputData = {
  data: TypeRequest;
  id: string;
  type: EChecklistType;
};

type TypeResponse = IChecklist;

type TypeError = {};

export const copyChecklist: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/checklist/${params.id}/copy`,
  method: 'POST',
  headers: {},
  request: request => ({ settings: request.data.settings, type: request.data.type }),
  response: {} as TypeResponse,
  error: {} as TypeError,
};
