import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getOrganizationMenu } from '../../../../../shared/menu';
import { Layout } from '../../../../components';

export const OrganizationNews: FC = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  return (
    <Layout menuItems={menuItems}>
      <div>OrganizationNews</div>
    </Layout>
  );
};
