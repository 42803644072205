import { makeAutoObservable, runInAction } from 'mobx';

import { TypeBasis } from '../../../api/models/basis.model';
import { TypeCulture } from '../../../api/models/culture.model';
import { TypeCultureClass } from '../../../api/models/cultureClass.model';
import { TypeCurrency } from '../../../api/models/currency.model';
import { TypeEconomicRegion } from '../../../api/models/economicRegion.model';
import { TypeProvider } from '../../../api/models/provider.model';
import { TypeQuote } from '../../../api/models/quotes.model';
import { TypeUnit } from '../../../api/models/units.model';
import { Axios } from '../../shared/utils';
import { lazyInject, provide } from '../../shared/utils/IoC';

import { TypeQuoteSimple } from './add.quotes.store';

@provide.singleton()
export class EditQuoteStore {
  @lazyInject(Axios)
  protected axios: Axios;

  quoteForEdit: TypeQuoteSimple;

  isLoading = false;

  cultureList: TypeCulture[] = [];

  cultureClassList: TypeCultureClass[] = [];

  basisList: TypeBasis[] = [];

  economicRegionsList: TypeEconomicRegion[] = [];

  currencyList: TypeCurrency[] = [];

  unitsList: TypeUnit[] = [];

  providersList: TypeProvider[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (value: boolean) => (this.isLoading = value);

  onQuoteChange = (quote: TypeQuoteSimple) => {
    console.log(123);
    this.quoteForEdit = { ...quote };
  };

  fetchQuote = async (id: string) => {
    this.isLoading = true;
    try {
      const response = (await this.axios.api.getQuoteById({ id })) as TypeQuote;
      this.quoteForEdit = {
        id: response.id,
        cultureId: response.culture?.id,
        cultureClassId: response?.cultureClass?.id,
        basisId: response?.basis?.id,
        economicRegionId: response?.economicRegion?.id,
        currencyId: response?.currency?.id,
        providerId: response?.provider?.id,
        unitsId: response?.units?.id,
        qualityMin: response?.qualityMin,
        qualityMax: response?.qualityMax,
        price: response?.price,
        vat: response?.vat,
        dateOfObs: response?.dateOfObs,
      };
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      console.error(e);
    }
  };

  updateQuote = async () => {
    try {
      const response = (await this.axios.api.updateQuote(this.quoteForEdit, {
        omit: ['id'],
      })) as TypeQuote;
    } catch (e) {
      console.error(e);
    }
  };

  clearStore = () => {
    this.quoteForEdit = undefined;
    this.basisList = [];
    this.cultureClassList = [];
    this.economicRegionsList = [];
    this.cultureList = [];
    this.currencyList = [];
    this.providersList = [];
    this.unitsList = [];
  };

  setCultureList = (cultureList: TypeCulture[]) => {
    runInAction(() => {
      this.cultureList = cultureList;
    });
  };

  setBasisList = (basisList: TypeBasis[]) => {
    runInAction(() => {
      this.basisList = basisList;
    });
  };

  setCultureClassList = (cultureClassList: TypeCultureClass[]) => {
    runInAction(() => {
      this.cultureClassList = cultureClassList;
    });
  };

  setEconomicRegionsList = (economicRegionsList: TypeEconomicRegion[]) => {
    runInAction(() => {
      this.economicRegionsList = economicRegionsList;
    });
  };

  setProvidersList = (providersList: TypeProvider[]) => {
    runInAction(() => {
      this.providersList = providersList;
    });
  };

  setCurrencyList = (currencyList: TypeCurrency[]) => {
    runInAction(() => {
      this.currencyList = currencyList;
    });
  };

  setUnitsList = (unitsList: TypeUnit[]) => {
    runInAction(() => {
      this.unitsList = unitsList;
    });
  };

  fetchBasis = async () => {
    try {
      const response = await this.axios.api.getBasisList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setBasisList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureList = async () => {
    try {
      const response = await this.axios.api.getCultureList({
        attrs: { useInQuotes: true },
        hasAttributes: true,
        originalOnly: true,
        latestVersion: true,
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureClassList = async () => {
    try {
      const response = await this.axios.api.getCultureClassList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureClassList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCurrencyList = async () => {
    try {
      const response = await this.axios.api.getCurrencyList({
        page: 0,
        size: 10000,
        latestVersion: true,
      });
      if (response.content) {
        this.setCurrencyList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchEconomicRegionsList = async () => {
    try {
      const response = await this.axios.api.getEconomicRegionsList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setEconomicRegionsList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchProvidersList = async () => {
    try {
      const response = await this.axios.api.getProvidersList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setProvidersList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchUnitsList = async () => {
    try {
      const response = await this.axios.api.getUnitsList({
        page: 0,
        size: 10000,
        latestVersion: true,
      });
      if (response.content) {
        this.setUnitsList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
