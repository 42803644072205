import { EModalSize, EModalType } from '@farmlink/farmik-ui';

import PreviewPhotoModal from './components/PreviewPhotoModal/PreviewPhotoModal';

const CROPWISE_INTEGRA_PREVIEW_PHOTO_MODAL_ID = 'cropwise-integra-preview-photo-modal';

const previewPhotoModalConfig = {
  id: CROPWISE_INTEGRA_PREVIEW_PHOTO_MODAL_ID,
  name: CROPWISE_INTEGRA_PREVIEW_PHOTO_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: EModalSize.Large,
  },
  children: PreviewPhotoModal,
};

export { previewPhotoModalConfig, CROPWISE_INTEGRA_PREVIEW_PHOTO_MODAL_ID };
