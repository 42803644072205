/* eslint-disable import/order */
import { InputProps, TextField } from '@mui/material';
import { FC, memo } from 'react';

import { StringInput, SwitchInput, SelectInput, AutocompleteInput } from '../..';
import { Colors } from '../../../../../../../../constants/colors';
import { ISelectOption, ISelectOptionExtended } from '../../../../../../../../types/selectOption';
import { ApiNames, TypeApiRequest, TypeApiResponse } from '../../../../../../../shared/utils';
import { attributeFormItemsScheme } from '../../../../containers/atttibutes/AttributeContainer/components/AttributeBuilderFrom/AttributeBuilderForm.config';
import { ITooltipConfig } from '../AutocompleteInput/components/Item/Item';

export interface IFormInput {
  itemKey: string;
  disabled?: boolean;
  attributeScheme?: any;
  onChangeHandler?: (value: any) => void;
  autocompleteConfig?: IFormInputAutocompleteConfig;
  stringConfig?: IFormInputStringInputConfig;
  inputProps?: InputProps;
}

export interface IFormInputStringInputConfig {
  nullifyEmptyString?: boolean;
  maxLength?: number;
  regExp?: RegExp;
}

export interface IFormInputAutocompleteConfig {
  optionsFetchHandler: (payload: TypeApiRequest<ApiNames>) => Promise<TypeApiResponse<ApiNames>>;
  /**
   * Настройки маппинга (какие поля взять как `value` и `label`)
   *
   * `null` - для удаления элемента из выборки
   */
  optionsMappingScheme?:
    | ISelectOption
    | ((value: TypeApiResponse<ApiNames>) => ISelectOptionExtended)
    | null;
  /**
   * Ключ по которому будет происходить поиск на сервере
   */
  searchingKey?: string;
  /**
   * Formik-ключ, которому также нужно присвоить значение
   */
  onChangeMappingKey?: string;
  searchingParams?: Record<string, any>;
  isOnMountOptionsFetch?: boolean;
  reFetchOnUpdate?: boolean;
  isClearable?: boolean;
  value?: ISelectOptionExtended | string;
  fetchValueOnMount?: Record<string, any>;
  onSelect?: (item: ISelectOptionExtended<TypeApiResponse<ApiNames>>) => void;
  optionItem?: {
    tooltip?: ITooltipConfig;
  };
  pagination?: {};
  isMultiselect?: boolean;
}

const FormInput: FC<IFormInput> = ({
  itemKey,
  autocompleteConfig,
  disabled,
  attributeScheme,
  onChangeHandler,
  stringConfig,
  inputProps,
}) => {
  const scheme = attributeScheme || attributeFormItemsScheme;

  const currentItem = scheme[itemKey];

  if (currentItem) {
    switch (currentItem.type) {
      case 'String':
        return (
          <StringInput
            itemKey={itemKey}
            attributeFormItemsScheme={scheme}
            {...stringConfig}
            disabled={disabled}
            onChangeHandler={onChangeHandler}
            inputProps={inputProps}
          />
        );

      case 'Number':
        return (
          <StringInput
            itemKey={itemKey}
            attributeFormItemsScheme={scheme}
            {...stringConfig}
            type="number"
            disabled={disabled}
            onChangeHandler={onChangeHandler}
            inputProps={inputProps}
          />
        );

      case 'Switch':
        return (
          <SwitchInput
            itemKey={itemKey}
            attributeFormItemsScheme={scheme}
            disabled={disabled}
            onChangeHandler={onChangeHandler}
          />
        );

      case 'Select':
        return (
          <SelectInput
            itemKey={itemKey}
            attributeFormItemsScheme={scheme}
            disabled={disabled}
            onChangeHandler={onChangeHandler}
          />
        );

      case 'Textarea':
        return (
          <StringInput
            itemKey={itemKey}
            attributeFormItemsScheme={scheme}
            isTextarea
            {...stringConfig}
            disabled={disabled}
            onChangeHandler={onChangeHandler}
          />
        );

      case 'Autocomplete':
        if (autocompleteConfig?.optionsFetchHandler) {
          return (
            <AutocompleteInput
              itemKey={itemKey}
              attributeFormItemsScheme={scheme}
              {...autocompleteConfig}
              disabled={disabled}
            />
          );
        } else {
          return (
            <TextField
              value="Ошибка загрузки данных для отображения"
              disabled
              error
              sx={{ borderColor: Colors.red }}
              fullWidth
            />
          );
        }

      default:
        return (
          <StringInput itemKey={itemKey} attributeFormItemsScheme={scheme} disabled={disabled} />
        );
    }
  }
};

export default memo(FormInput);
