import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { IChecklist } from '../../models/checklist.model';

type TypeRequest = {
  id?: string;
  publicId?: string;
  name?: string;
  operationTypeId?: string;
  cultureId?: string;
  phenophaseId?: string;
  organizationIds?: string[];
  machinery?: boolean;
  isDefault?: boolean;
  active?: boolean;
  isNested?: boolean;
  page?: number;
  size?: number;
};

type TypeResponse = { content: IChecklist[] } & TypeResponsePageable;

type TypeError = {};

export const getChecklistListByParams: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => {
    let url = `/api/as-fields/admin/checklist/list`;

    if (params.size || params.page) {
      url += '?';
      if (params.size) {
        url += `size=${params.size}`;
      }

      if (params.page) {
        url += `${params.size ? `&` : ''}page=${params.page}`;
      }
    }

    return url;
  },
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
