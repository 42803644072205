import { Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../../../../routes';
import { OrganizationIntegrations } from '../containers';
import { OrgIntegraAgrosignalRouter } from '../modules/OrganizationIntegrationsAgrosignal/routers';
import { OrgIntegraCropwiseRouter } from '../modules/OrganizationIntegrationsCropwise/routers';

const OrganizationIntegrationsRouter = () => {
  return (
    <Switch>
      <Route
        path={AdminRoutes.OrganizationIntegrationsAgrosignal}
        component={OrgIntegraAgrosignalRouter}
      />
      <Route
        path={AdminRoutes.OrganizationIntegrationsCropwise}
        component={OrgIntegraCropwiseRouter}
      />
      <Route path={AdminRoutes.OrganizationIntegrations} component={OrganizationIntegrations} />
    </Switch>
  );
};

export default OrganizationIntegrationsRouter;
