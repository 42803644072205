import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useModal } from '@farmlink/farmik-ui';
import { TabContext, TabPanel } from '@mui/lab';
import { observer } from 'mobx-react';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { useStore } from '../../../../../../../shared/utils';
import { cropwiseReportModalConfig, removeAgrosignalConfig } from '../../modals';
import { beforeDeleteModal } from '../../../../../../../shared/modals';

import {
  ReportCropwiseConfigEmptyState,
  ReportCropwiseConfigForm,
  ReportCropwiseHistory,
} from './components';
import { CropwiseReportStore } from './mobx';
import useUploadTriggers from './hooks/useUploadTriggers';
import useCropwiseConfig from './hooks/useCropwiseConfig';

const OrganizationReportsCropwise = () => {
  const store = useStore(CropwiseReportStore);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const ref = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const { registerModalList } = useModal();

  const [tab, setTab] = useState<'history' | 'config'>('config');
  const [currentConfigId, setCurrentConfigId] = useState(null);

  const { fetchConfig } = useCropwiseConfig({ organizationId });

  const { downloadReport } = useUploadTriggers({
    organizationId,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    if (newValue === 'config') {
      setCurrentConfigId(null);
    }

    setTab(newValue);
  };

  useEffect(() => {
    fetchConfig();

    registerModalList(
      [removeAgrosignalConfig, cropwiseReportModalConfig, beforeDeleteModal],
      'cropwise-reports'
    );

    return () => {
      store.clearStore();
    };
  }, []);

  const handleOpenHistory = (id: string) => {
    setCurrentConfigId(id);
    setTab('history');
  };

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);
  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  const onCreateNewConfig = () => {
    setTimeout(() => {
      ref.current?.scrollTo({ behavior: 'smooth', top: ref.current.scrollHeight });
    }, 300);
  };

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex" width={'100%'}>
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Box flexGrow={1}>
                <H1>Отчеты BI. Cropwise</H1>
              </Box>
              <Box display={'flex'} gap={2} alignItems={'center'}>
                <Button onClick={downloadReport} variant="contained" size="small">
                  Сформировать отчёт
                </Button>
              </Box>
            </Box>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Настройки" value={'config'} />
                <Tab label="История" value={'history'} />
              </Tabs>
            </Box>

            <TabPanel value="config">
              {store.isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={3}
                    sx={{ overflowY: 'auto', maxHeight: '58svh' }}
                    ref={ref}
                  >
                    {store.configList.map(config => (
                      <ReportCropwiseConfigForm
                        id={config.id}
                        key={config.id}
                        onOpenHistory={handleOpenHistory}
                      />
                    ))}
                  </Box>
                  <ReportCropwiseConfigEmptyState onCreate={onCreateNewConfig} />
                </>
              )}
            </TabPanel>
            <TabPanel value="history">
              <ReportCropwiseHistory configId={currentConfigId} />
            </TabPanel>
          </TabContext>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationReportsCropwise);
