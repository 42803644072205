import { SyntheticEvent } from 'react';
import moment from 'moment';

import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { TypeApiRequest, lazyInject, provide, Axios } from '../../../../../../../../shared/utils';
import { downloadBlobAsFile } from '../../../../../../../../shared/utils/downloadBlobAsFile';
import { calcActualSeason } from '../../helpers';
import { OrganizationFieldsStore } from '../../stores';

@provide.singleton()
export class OrganizationFieldsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationFieldsStore)
  protected organizationFieldsStore: OrganizationFieldsStore;

  changeSeasonsFilter = (_: SyntheticEvent, value: NonNullable<string | ISelectOption>) => {
    if (typeof value === 'object') {
      this.organizationFieldsStore.setSeasonsFilterValue(value);
    }
  };

  fetchCultureList = async ({
    year,
    organizationId,
  }: TypeApiRequest<'getCultureZoneListBySeason'>) => {
    try {
      this.organizationFieldsStore.setIsLoadingCultureList(true);

      const response = await this.axios.api.getCultureZoneListBySeason(
        { year, organizationId },
        { omit: ['year'] }
      );

      this.organizationFieldsStore.setCultureList(response.cultureAndVarietyList);
    } catch (e) {
      this.organizationFieldsStore.setCultureList([]);
    } finally {
      this.organizationFieldsStore.setIsLoadingCultureList(false);
    }
  };

  fetchSeasons = async ({ organizationId, sort, allowStub }: TypeApiRequest<'getSeasons'>) => {
    this.organizationFieldsStore.setIsLoadingSeasons(true);

    const response = await this.axios.api.getSeasons({ organizationId, sort, allowStub });

    this.organizationFieldsStore.setSeasons(response);

    const actualSeason = calcActualSeason(response);

    if (actualSeason) {
      this.organizationFieldsStore.setSeasonsFilterValue({
        label: actualSeason.toString(),
        value: actualSeason.toString(),
      });
    }

    const seasonOptions = response.map(({ year }) => ({
      label: year.toString(),
      value: year.toString(),
    }));
    this.organizationFieldsStore.setSeasonOptions(seasonOptions);

    this.organizationFieldsStore.setIsLoadingSeasons(false);
  };

  fetchFieldsInfo = async ({
    seasonYear,
    organizationId,
  }: TypeApiRequest<'getFieldsInfoBySeason'>) => {
    this.organizationFieldsStore.setIsLoadingFieldsInfo(true);

    const response = await this.axios.api.getFieldsInfoBySeason({ seasonYear, organizationId });

    this.organizationFieldsStore.setFieldsInfo(response);

    this.organizationFieldsStore.setIsLoadingFieldsInfo(false);
  };

  fetchFieldsReport = async (
    { seasonYear, organizationIdIn }: TypeApiRequest<'getFieldsInfoReportCsvBySeason'>,
    fileName?: string
  ) => {
    const response = await this.axios.api.getFieldsInfoReportCsvBySeason({
      seasonYear,
      organizationIdIn,
    });

    downloadBlobAsFile(response, fileName ? fileName : 'exportedFields.csv');
  };
}
