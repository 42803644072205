import { TypeApiRoute } from '../../models/type.api.request';
import { EUserAdminEmailStatus } from '../../models/user.admin.model';

type TypeRequest = {
  userId: string;
  email: string;
  emailStatus: EUserAdminEmailStatus;
};

type TypeResponse = {};

type TypeError = {};

export const updateEmailUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-profile/users/admin/updateEmail/${request.userId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
