import { Box, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import {
  AutocompleteWithSearch,
  ModalTitle,
} from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { useModalForm } from '../../hooks';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { OrgIntegraCropwiseStore } from '../../../../../../mobx';

const FieldModalForm = () => {
  const orgStore = useStore(OrganizationsStore);
  const configStore = useStore(OrgIntegraCropwiseStore);

  const [isProcessing, setIsProcessing] = useState(false);

  const { getModalPayload, closeModal, getModalRuntimeHandlers } = useModal();
  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId: string;
    id?: string;
    fieldId?: string;
    fieldName?: string;
    cropwiseFieldId?: string;
    cropwiseFieldName?: string;
  };
  const {
    assistanceField,
    setAssistanceField,
    createField,
    cropwiseField,
    setCropwiseField,
    fetchAssistanceField,
    fetchCropwiseField,
  } = useModalForm(orgStore?.organization?.organizationId, payload?.id);

  const handlers = getModalRuntimeHandlers();
  const { orgIntegraConfig } = configStore;

  useEffect(() => {
    if (payload.fieldId)
      setAssistanceField({ label: payload.fieldName || payload?.fieldId, value: payload.fieldId });
    if (payload.cropwiseFieldId)
      setCropwiseField({
        label: payload.cropwiseFieldName || payload.cropwiseFieldId,
        value: payload.cropwiseFieldId,
      });
  }, [payload]);

  const onSuccess = () => {
    setIsProcessing(true);

    createField()
      .then(() => {
        handlers?.onSuccess?.();

        closeModal();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onDeny = () => {
    closeModal();
  };

  const titleText = `${payload.mode === 'create' ? 'Добавление' : 'Детали'} поля`;

  return (
    <Box>
      <ModalTitle>{titleText}</ModalTitle>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />

        <AutocompleteWithSearch<'getOrgIntegraAssistanceFieldList'>
          fetchHandler={fetchAssistanceField}
          responseMappingHandler={data =>
            data.content.map(item => ({ label: item.name, value: item.id }))
          }
          selectedValue={assistanceField}
          setSelectedOption={(value: ISelectOption) => setAssistanceField(value)}
          defaultRequestPayload={{
            page: 0,
            size: 100,
            organizationId: orgStore?.organization?.organizationId,
            isMapped: false,
          }}
          label={'ID “Помощник”'}
          searchPropertyKey="name"
          placeholder={'Введите ID поля из системы “Помощник”'}
        />

        <AutocompleteWithSearch<'getOrgIntegraCropwiseFieldList'>
          fetchHandler={fetchCropwiseField}
          responseMappingHandler={data =>
            data.content.map(item => ({ label: item.name, value: item.id }))
          }
          selectedValue={cropwiseField}
          setSelectedOption={(value: ISelectOption) => setCropwiseField(value)}
          defaultRequestPayload={{
            page: 0,
            size: 100,
            ...(orgIntegraConfig?.id && {
              mapOrganizationId: orgIntegraConfig?.id,
            }),
          }}
          searchPropertyKey="name"
          label="ID “Cropwise”"
          placeholder="Введите ID поля из системы “Cropwise”"
        />

        <ModalFooter
          successButton={
            payload.mode === 'create'
              ? {
                  title: 'Сохранить',
                  color: 'primary',
                  handler: onSuccess,
                  disabled: isProcessing,
                }
              : null
          }
          denyButton={{ title: 'Закрыть', color: 'default', handler: onDeny }}
        />
      </Box>
    </Box>
  );
};

export default observer(FieldModalForm);
