import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, InputAdornment, LinearProgress, TextField } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SearchIcon from '@mui/icons-material/Search';

import { getUserMenu } from '../../../../../shared/menu';
import { Layout } from '../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../style';
import { useStore } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { IOrganizationForUserDto } from '../../../../../../api/models/organization.model';

import { getGridColumns } from './helpers';
import Styled from './UserOrganizations.styles';
import { NoOrganizations } from './components';

export const UserOrganizations: FC = () => {
  const { userId } = useParams<{ userId?: string }>();

  const { fetchUserOrganizationsList } = useStore(UsersController);

  const [nameOrInn, setNameOrInn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rows, setRows] = useState<IOrganizationForUserDto[]>([]);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    fetchUserOrganizationsList({
      id: userId,
      nameOrInn,
      page,
      size: pageSize,
      sort: 'employer.name,asc',
    })
      .then(data => {
        if (data?.content) {
          setRows(data?.content);
          setRowCount(data?.totalElements);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [nameOrInn, page, pageSize]);

  useEffect(() => {
    setPage(0);
  }, [nameOrInn]);

  const columns = useMemo(() => getGridColumns(), []);

  const menuItems = useMemo(() => getUserMenu(userId), [userId]);

  const rowsPerPageOptions = [10, 20, 50, 100];

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Организации пользователя</H1>
          </TopSection>
        </HeaderWrapper>

        {rowCount === 0 && nameOrInn.length === 0 && !isLoading ? (
          <MainBody>
            <NoOrganizations />
          </MainBody>
        ) : (
          <MainBody>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CorporateFareIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Организация"
              placeholder="Введите название или ИНН"
              value={nameOrInn}
              onChange={event => setNameOrInn(event?.target?.value)}
              sx={{ marginBottom: 2 }}
              fullWidth
            />

            <Container disableGutters maxWidth={false}>
              <Styled.TableWrapper>
                <DataGrid
                  disableSelectionOnClick
                  hideFooterSelectedRowCount
                  disableColumnMenu
                  columns={columns}
                  rows={rows}
                  pagination={true}
                  paginationMode={'server'}
                  onPageChange={param => setPage(param)}
                  onPageSizeChange={param => setPageSize(param)}
                  loading={isLoading}
                  rowsPerPageOptions={rowsPerPageOptions}
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCount}
                  getRowId={item => item.organizationId}
                  localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                />
              </Styled.TableWrapper>
            </Container>
          </MainBody>
        )}
      </MainContainer>
    </Layout>
  );
};

export default observer(UserOrganizations);
