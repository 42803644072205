import { TypeApiRoute } from '../../models/type.api.request';
import { IDictionaryUniqueItem } from '../../models/dictionaries.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = { remoteName: string; page?: number; size?: number };

type TypeResponse = TypeResponsePageable & { content: IDictionaryUniqueItem[] };

export const getUniqueDictionaryAttributeList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (url: TypeRequest) => `/api/da-dictionary/admin/attributes/${url.remoteName}/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
