import { FC, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  DataGrid,
  GridRenderCellParams,
  GridActionsCellItem,
  GridRowId,
  GridColumns,
} from '@mui/x-data-grid';
import { Container, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';

import { useStore, getLink } from '../../../../../../../shared/utils';
import { NewsController } from '../../../../../../../controllers/news.controller';
import { NewsStore } from '../../../../../../../stores/news/news.store';
import { FilterStore } from '../../../../../../../stores/news/filter.store';
import { getColumns, getRows } from '../../../../utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';

export const NewsTable: FC = observer(() => {
  const history = useHistory();

  const { fetchNews, deleteNews, setPage } = useStore(NewsController);
  const { news, page, totalPages, isLoading } = useStore(NewsStore);
  const {
    filters: { tags, status, context },
  } = useStore(FilterStore);

  useEffect(() => {
    fetchNews();
  }, [page, tags, status, context]);

  const handleDeleteNewsItem = useCallback(
    (id: GridRowId) => () => {
      deleteNews({ newsId: String(id) });
    },
    []
  );

  const handleEditNewsItem = useCallback(
    (id: GridRowId) => () => {
      history.push(getLink(AdminRoutes.NewsEdit, { [id]: ERegexp.NEWSID }));
    },
    []
  );

  const renderActions = (params: GridRenderCellParams<string>) => [
    <Tooltip title="Удалить">
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Удалить"
        onClick={handleDeleteNewsItem(params.id)}
      />
    </Tooltip>,
    <Tooltip title="В архив">
      <GridActionsCellItem
        icon={<ArchiveIcon />}
        label="В архив"
        onClick={handleDeleteNewsItem(params.id)}
      />
    </Tooltip>,
    <Tooltip title="Редактировать">
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Редактировать"
        onClick={handleEditNewsItem(params.id)}
      />
    </Tooltip>,
  ];

  const rows = useMemo(() => getRows(news), [news]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(() => getColumns(renderActions), [
    handleDeleteNewsItem,
    handleEditNewsItem,
  ]);

  return (
    <Container disableGutters maxWidth={false}>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          rows={rows}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          loading={isLoading}
          rowsPerPageOptions={[20]}
          page={page}
          pageSize={20}
          rowCount={10 * totalPages}
        />
      </div>
    </Container>
  );
});
