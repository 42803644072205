import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  roleId: string;
  employeeIds: string[];
};

type TypeResponse = string;

type TypeError = {};

export const assignUserToRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-profile/access/role/${request.roleId}/assign`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
