import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AdminRoutes } from '../../routes';

import { UsersList, UserCreate } from './pages';
import { UserContainer } from './containers';

export const UsersRouter: FC<any> = () => {
  return (
    <Switch>
      <Route exact path={AdminRoutes.UserCreate} component={UserCreate} />
      <Route path={AdminRoutes.UserItem} component={UserContainer} />
      <Route path={AdminRoutes.Users} component={UsersList} />
      <Redirect to={AdminRoutes.Users} />
    </Switch>
  );
};
