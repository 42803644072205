import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { TabContext, TabPanel } from '@mui/lab';
import { observer } from 'mobx-react';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { useStore } from '../../../../../../../shared/utils';
import { agrosignalReportModalConfig, removeAgrosignalConfig } from '../../modals';

import {
  OrganizationReportsAssistanceEmptyState,
  OrganizationReportsAssistanceForm,
  OrganizationReportsAssistanceHistory,
} from './components';
import { AssistanceTaskReportController, OrganizationReportAssistanceTaskStore } from './mobx';

const OrganizationReportsAssistanceTasks = () => {
  const controller = useStore(AssistanceTaskReportController);
  const store = useStore(OrganizationReportAssistanceTaskStore);

  const ref = useRef<HTMLDivElement | null>(null);

  const [tab, setTab] = useState<'history' | 'config'>('config');
  const [activeConfigId, setActiveConfigId] = useState<string>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    setTab(newValue);
  };

  const { organizationId } = useParams<{ organizationId?: string }>();
  const history = useHistory();
  const { registerModalList } = useModal();

  const onCreateNewConfig = () => {
    setTimeout(() => {
      ref.current?.scrollTo({ behavior: 'smooth', top: ref.current.scrollHeight });
    }, 300);
  };

  const fetchConfig = () => {
    controller.getReportsConfig(organizationId);
  };

  useEffect(() => {
    fetchConfig();

    registerModalList([removeAgrosignalConfig, agrosignalReportModalConfig], 'agrosignal-reports');

    return () => {
      store.clearStore();
    };
  }, []);

  const handleOpenHistory = (id: string) => {
    setActiveConfigId(id);
    setTab('history');
  };

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);
  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex" width={'100%'}>
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Box flexGrow={1}>
                <H1>Отчеты BI. Помощник.Задачи</H1>
              </Box>
              <Box display={'flex'} gap={2} alignItems={'center'}></Box>
            </Box>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Настройки" value={'config'} />
                <Tab label="История" value={'history'} />
              </Tabs>
            </Box>
            <TabPanel value="config">
              {store.isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={3}
                    sx={{ overflowY: 'auto', maxHeight: '58svh', padding: '4px 0' }}
                    ref={ref}
                  >
                    {store.configList.map(config => (
                      <OrganizationReportsAssistanceForm
                        config={config}
                        key={config.id}
                        onOpenHistory={handleOpenHistory}
                      />
                    ))}
                  </Box>
                  <OrganizationReportsAssistanceEmptyState onCreate={onCreateNewConfig} />
                </>
              )}
            </TabPanel>
            <TabPanel value="history">
              <OrganizationReportsAssistanceHistory configId={activeConfigId} />
            </TabPanel>
          </TabContext>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationReportsAssistanceTasks);
