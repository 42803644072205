import { FormEvent, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { EFilterFormActivity, IFilterForm } from '../../../../../types/filterForm.types';
import { useStore } from '../../../../../../../../shared/utils';
import { ReferenceController, ReferenceStore } from '../../../mobx';
import { BEFORE_EXIT_SERVICE_REFERENCE } from '../../../modals';

const useSidebarListing = () => {
  const controller = useStore(ReferenceController);
  const { referenceList, isPreventChangeRef } = useStore(ReferenceStore);

  const [filterState, setFilterState] = useState<Partial<IFilterForm>>({
    activity: EFilterFormActivity.Active,
  });
  const [isAllowedLoadMore, setIsAllowedLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { openModalByModalId } = useModal();

  const updateState = (value: Partial<IFilterForm>) => {
    setFilterState({ ...filterState, ...value });
  };

  const clearForm = () => {
    setFilterState({ activity: EFilterFormActivity.Active });
    submitFilter(null, { activity: EFilterFormActivity.Active }, true);
  };

  const submitFilter = (
    e?: FormEvent<HTMLFormElement>,
    newState?: typeof filterState,
    isClear?: boolean
  ) => {
    e?.preventDefault();
    setIsLoading(true);

    controller
      .fetchReferenceList(newState || filterState, isClear)
      .then(data => {
        setIsAllowedLoadMore(!data.last);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setActiveRef = (ref: string, changeHandler?: () => void) => {
    if (isPreventChangeRef) {
      openModalByModalId(BEFORE_EXIT_SERVICE_REFERENCE, null, () => {
        changeHandler?.();
        controller.setActiveRef(ref);
      });
    } else {
      changeHandler?.();
      controller.setActiveRef(ref);
    }
  };

  return {
    filterState,
    updateState,
    clearForm,
    submitFilter,
    referenceList,
    isAllowedLoadMore,
    isLoading,
    setActiveRef,
  };
};

export default useSidebarListing;
