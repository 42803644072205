import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationFieldsController, OrganizationFieldsStore } from '../../mobx';

export const OrganizationFieldsInfo = observer(() => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const store = useStore(OrganizationFieldsStore);
  const controller = useStore(OrganizationFieldsController);

  useEffect(() => {
    if (!store.seasonsFilterValue?.value) {
      return;
    }

    controller.fetchFieldsInfo({ organizationId, seasonYear: store.seasonsFilterValue?.value });
  }, [organizationId, store.seasonsFilterValue?.value]);

  const seasonPeriod = useMemo(() => {
    if (!store.currentSeason) return '';

    return `${moment(store.currentSeason?.startDate).format('DD.MM.YYYY')} - ${moment(
      store.currentSeason?.endDate
    ).format('DD.MM.YYYY')}`;
  }, [store.currentSeason]);

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={12}>
        Период сезона: {seasonPeriod}
      </Grid>
      <Grid item xs={4}>
        Количество полей: {store.fieldsInfo?.fieldsCount}
      </Grid>
      <Grid item xs={4}>
        Количество культурных зон: {store.fieldsInfo?.cultureZoneCount}
      </Grid>
      <Grid item xs={4}>
        Количество опытных участков: {store.fieldsInfo?.cultureZoneExperimentCount}
      </Grid>
      <Grid item xs={4}>
        Площадь полей: {store.fieldsInfo?.fieldsArea}
      </Grid>
      <Grid item xs={4}>
        Площадь культур: {store.fieldsInfo?.cultureZoneArea}
      </Grid>
      <Grid item xs={4}>
        Площадь опытных участков: {store.fieldsInfo?.cultureZoneExperimentArea}
      </Grid>
    </Grid>
  );
});
