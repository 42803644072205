import { DataGrid, ruRU } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { toJS } from 'mobx';
import {
  ENotificationType,
  ENotificatorTheme,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationInvitesStore } from '../../mobx';
import { IInvitation } from '../../../../../../../../api/models/invitation.model';
import { DELETE_INVITATION_MODAL_ID, EDIT_INVITATION_MODAL_ID } from '../../modals';
import { OrganizationInvitesController } from '../../mobx/controllers';

import { getColumns } from './helpers';
import Styled from './OrganizationInvitesList.styles';

const OrganizationInvitesList = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const store = useStore(OrganizationInvitesStore);
  const controller = useStore(OrganizationInvitesController);

  const { openModalByModalId, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const handleEditInvite = (invite: IInvitation) => {
    openModalByModalId(EDIT_INVITATION_MODAL_ID, { invitation: invite, organizationId });
  };

  const handleDeleteInvite = (invite: IInvitation) => {
    openModalByModalId(DELETE_INVITATION_MODAL_ID, null, () =>
      controller
        .cancelInvite(invite.invitationId)
        .then(() => {
          setNotification({
            message: 'Приглашение успешно отозвано',
            style: {
              placement: 'bottom-left',
              type: ENotificationType.Success,
              theme: ENotificatorTheme.Dark,
            },
          });

          controller.fetchInviteList(organizationId);
          closeModal();
        })
        .catch(() => {
          setNotification({
            message: 'Ошибка отозвания приглашения',
            style: {
              placement: 'bottom-left',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        })
    );
  };

  const columns = useMemo(
    () =>
      getColumns({
        actions: { edit: { handler: handleEditInvite }, delete: { handler: handleDeleteInvite } },
      }),
    []
  );
  const rows = useMemo(() => toJS(store.inviteList), [store.inviteList]);

  return (
    <Styled.GridWrapper>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={store.isLoading}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        page={store.page}
        rowCount={store.totalElements}
        pageSize={store.pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginationMode="server"
        onPageChange={newPage => {
          store.setPage(newPage);
        }}
        onPageSizeChange={newPageSize => {
          store.setPageSize(newPageSize);
        }}
        getRowId={row => row.invitationId}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        pagination
      />
    </Styled.GridWrapper>
  );
};

export default observer(OrganizationInvitesList);
