import { Box, Button, FormControlLabel, IconButton, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Colors, useNotificator } from '@farmlink/farmik-ui';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';

import { FormulasController, FormulasStore } from '../../mobx';
import { useStore } from '../../../../../../../shared/utils';
import { EFormulaTypes, TFormulaTypeUnion } from '../../models';
import { EChecklistAttributeType } from '../../../../../../../../api/models/checklist.attribute.model';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';

import { FormulaBlock } from './components/FormulaBlock';

interface IProps {
  implementationType: EFormulaTypes;
  attributeType?: EChecklistAttributeType;
  autoComplete?: boolean;
  setAutoComplete?: (state: boolean) => void;
}

const FormulaBuilder: FC<IProps> = ({
  implementationType,
  attributeType,
  autoComplete,
  setAutoComplete,
}) => {
  const store = useStore(FormulasStore);
  const controller = useStore(FormulasController);

  const { setNotification } = useNotificator();

  const isDependency = implementationType === EFormulaTypes.Dependency;

  const formula = isDependency
    ? store.formulaDepCollection
    : store.getFormulaCollectionItem(implementationType);

  const addDependency = () => {
    const id = v4();
    store.setFormulaDepCollection(id, { clientId: id });
  };

  const removeDependency = (id: string) => {
    store.removeFormulaDepItem(id);
    store.removeSerializedDepFormulaCollection(id);

    if ((formula as [string, TFormulaTypeUnion][]).length === 1) {
      const lastFormula = formula[0][1];

      delete lastFormula.clientId;

      if (isEmpty(lastFormula)) {
        setAutoComplete(false);

        if (autoComplete)
          setNotification(
            getNotificatorProps(
              'Удалена последняя формула зависимости. Автозаполнение отключено',
              ENotificationStyles.Success
            )
          );
      }
    }
  };

  const isAddDepDisabled =
    attributeType === EChecklistAttributeType.Enum &&
    store.serializedDepFormulaCollection.size >= 1;

  return (
    <Box>
      {isDependency ? (
        <>
          <FormControlLabel
            sx={{ marginBottom: 2 }}
            control={
              <Switch checked={autoComplete} onChange={(_, state) => setAutoComplete(state)} />
            }
            label="Автозаполнение"
          />
          <br />
          <Button onClick={addDependency} disabled={isAddDepDisabled}>
            + Добавить зависимость
          </Button>

          {(formula as [string, TFormulaTypeUnion][]).map(([key, _formula]) => {
            return (
              <Box
                key={key}
                display={'flex'}
                flexDirection={'row'}
                position={'relative'}
                marginBottom={2}
              >
                <Box flexGrow={1}>
                  <FormulaBlock
                    key={key}
                    implementationType={implementationType}
                    formulaItem={_formula}
                    handleSerializedData={controller.setFormulaSerializedData}
                    handleDeepSerializedData={controller.setFormulaDepSerializedData}
                    handleSetError={controller.addError}
                    handleRemoveError={controller.removeError}
                  />
                </Box>
                <Box position={'absolute'} right={-16} top={-16}>
                  <IconButton
                    onClick={() => {
                      removeDependency(key);
                    }}
                    size="small"
                    sx={{ backgroundColor: Colors.generalWhite }}
                    data-test-id="formula-remove-dependency"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </>
      ) : (
        <FormulaBlock
          key={implementationType}
          implementationType={implementationType}
          formulaItem={formula as TFormulaTypeUnion}
          handleSerializedData={controller.setFormulaSerializedData}
          handleDeepSerializedData={controller.setFormulaDepSerializedData}
          handleSetError={controller.addError}
          handleRemoveError={controller.removeError}
          data-test-id={`formula-block-parent-${implementationType}`}
        />
      )}
    </Box>
  );
};

export default observer(FormulaBuilder);
