import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID = 'before-delete-argument-formula-modal-id';

const beforeDeleteArgumentFormulaModalConfig: TModalConfig = {
  id: BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID,
  name: BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value: 'Вы уверены, что хотите удалить аргумент? Все дочерние элементы будут потеряны!',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Удалить',
    color: 'primary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID, beforeDeleteArgumentFormulaModalConfig };
