import {
  Axios,
  TypeApiRequest,
  lazyInject,
  provide,
} from '../../../../../../../../../../../shared/utils';

@provide.transient()
class OrgIntegraAgrosignalErrorMessageService {
  @lazyInject(Axios)
  protected axios: Axios;

  pushMessage = (payload: TypeApiRequest<'pushOrgIntegraAgrosignalMessage'>) => {
    return this.axios.api.pushOrgIntegraAgrosignalMessage(payload);
  };
}

export { OrgIntegraAgrosignalErrorMessageService };
