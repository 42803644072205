import { TypeApiRoute } from '../../models/type.api.request';
import { TypeOrganizationType } from '../../models/organization.model';

type TypeRequest = {};

type TypeResponse = TypeOrganizationType[];

type TypeError = {};

export const getOrganizationsTypes: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: '/api/da-profile/orgType/all',
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
