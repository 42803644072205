import { TypeApiRoute } from '../../models/type.api.request';
import { IPageableRequest, TypeResponsePageable } from '../../models/response.pageable.model';
import { EReferenceType, IReference } from '../../models/reference.model';

type TypeRequest = IPageableRequest & {
  path?: string;
  parentPath?: string;
  active?: boolean;
  empty?: boolean;
  type?: EReferenceType;
};

type TypeResponse = TypeResponsePageable & {
  content: IReference[];
};

export const getReferenceList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/references/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
