import { Field, Form, Formik } from 'formik';
import { Divider, TextField as MTextField } from '@mui/material';
import { Autocomplete, TextField } from 'formik-mui';
import {
  ENotificationType,
  ENotificatorTheme,
  ModalFooter,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../shared/utils';
import { OrganizationInvitesStore } from '../../../../mobx';
import {
  EInvitationCreateForm,
  invitationCreateFormSchema,
} from '../../../../../../../../../shared/schema/invitationCreateFormSchema';
import { OrganizationInvitesController } from '../../../../mobx/controllers';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';
import { H1, HeaderWrapper, TopSection } from '../../../../../../../../style';

import Styled from './CreateInvitationModal.styles';

const INITIAL_VALUE: { email: string; role: ISelectOption; comment?: string } = {
  email: '',
  role: null,
  comment: '',
};

const CreateInvitationModal = () => {
  const store = useStore(OrganizationInvitesStore);
  const controller = useStore(OrganizationInvitesController);

  const { closeModal, getModalPayload } = useModal();
  const { setNotification } = useNotificator();

  const { organizationId } = getModalPayload();

  const handleSubmit = (data: typeof INITIAL_VALUE, { setSubmitting }) => {
    controller
      .createNewInvite(organizationId, data.role.value, data.email, data?.comment)
      .then(() => {
        setNotification({
          message: 'Приглашение успешно отправлено',
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });

        controller.fetchInviteList(organizationId);
        closeModal();
      })
      .catch(error => {
        const errorTitle = error?.response?.data?.error?.title || error?.response?.data?.title;

        setNotification({
          message: `Ошибка отправки приглашения${errorTitle ? `: ${errorTitle}` : ''}`,
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <HeaderWrapper>
        <TopSection>
          <H1>Добавление сотрудника</H1>
        </TopSection>
      </HeaderWrapper>
      <Divider sx={{ margin: '16px 0 12px' }} />
      <Formik
        initialValues={INITIAL_VALUE}
        onSubmit={handleSubmit}
        validationSchema={invitationCreateFormSchema}
      >
        {({ values, touched, errors, submitForm, isSubmitting, setSubmitting }) => {
          return (
            <Form>
              <Styled.Form>
                <Field
                  component={TextField}
                  fullWidth
                  name={EInvitationCreateForm.email}
                  value={values[EInvitationCreateForm.email]}
                  placeholder="Укажите e-mail"
                  label="E-mail *"
                  error={
                    touched[EInvitationCreateForm.email] && errors[EInvitationCreateForm.email]
                  }
                />
                <Field
                  component={Autocomplete}
                  fullWidth
                  name={EInvitationCreateForm.role}
                  value={values[EInvitationCreateForm.role]}
                  placeholder="Укажите роль"
                  label="Роль *"
                  error={touched[EInvitationCreateForm.role] && errors[EInvitationCreateForm.role]}
                  options={store.roleValueList ?? []}
                  disablePortal
                  renderInput={params => (
                    <MTextField
                      {...params}
                      fullWidth
                      name={EInvitationCreateForm.role}
                      placeholder="Выберите роль"
                      label="Роль *"
                      error={
                        touched[EInvitationCreateForm.role] &&
                        Boolean(errors[EInvitationCreateForm.role])
                      }
                      variant="outlined"
                    />
                  )}
                />
                <Field
                  component={TextField}
                  fullWidth
                  name={'comment'}
                  value={values[EInvitationCreateForm.comment]}
                  placeholder="Укажите комментарий"
                  label="Сообщение"
                  error={
                    touched[EInvitationCreateForm.comment] && errors[EInvitationCreateForm.comment]
                  }
                  multiline
                  sx={{ height: 125 }}
                  maxRows={3}
                />
                <ModalFooter
                  denyButton={{ title: 'Закрыть', handler: () => closeModal() }}
                  successButton={{
                    title: 'Пригласить пользователя',
                    handler: () => submitForm(),
                    disabled: isSubmitting,
                  }}
                />
              </Styled.Form>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(CreateInvitationModal);
