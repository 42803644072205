import { IconButton } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';

export const getDepListColumns = (onDelete: (params) => void): GridColumns => {
  return [
    {
      field: 'dependencyName',
      headerName: 'Справочник от',
      minWidth: 200,
    },
    {
      field: 'dependencyRecordName',
      headerName: 'От кого зависит',
      minWidth: 200,
    },
    {
      field: 'dependencyRecordId',
      headerName: 'ID от кого',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'dependencyPath',
      headerName: 'Path от кого',
      width: 100,
    },
    {
      field: 'dictionaryName',
      headerName: 'Справочник кто',
      width: 150,
    },
    {
      field: 'targetPathName',
      headerName: 'Кто зависит',
      width: 200,
    },
    {
      field: 'targetPath',
      headerName: 'Path кто зависит',
      width: 150,
    },
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: params => (
        <IconButton size="small" onClick={() => onDelete(params)}>
          <CloseIcon />
        </IconButton>
      ),
    },
  ];
};
