import { GridColDef } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { TooltipedValue } from '../../../../../../../../../shared/components';
import { IZondModel } from '../../../../../../../../../../api/models/zond.model';

const getColumnConfig: (
  removeDeviceHandler: (device: IZondModel) => void,
  ownerName: string
) => GridColDef<IZondModel>[] = (removeDeviceHandler, ownerName) => [
  {
    field: 'displayedName',
    headerName: 'Отображаемое имя',
    flex: 1,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'numberInDb',
    headerName: 'Номер в базе',
    flex: 1,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'owner',
    headerName: 'Владелец',
    flex: 1,
    valueGetter: () => ownerName || '',
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'actions',
    headerName: '',
    width: 34,
    renderCell: params => (
      <Tooltip title="Удалить привязку">
        <IconButton size="small" onClick={() => removeDeviceHandler(params.row)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ),
  },
];

export default getColumnConfig;
