import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { InterestType } from '../../../api/models/interest.model';
import { UserInterestType } from '../../../api/models/user.model';

@provide.singleton()
export class InterestStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  interests: InterestType[] = [];
  interest: InterestType = null as InterestType;
  selectedInterests: InterestType[] = [];
  searchInterest = '';

  formValuesErrors = {};

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setInterests = (interests: InterestType[]) => {
    runInAction(() => {
      this.interests = interests;
    });
  };

  setInterest = (interest: InterestType) => {
    runInAction(() => {
      this.interest = interest;
    });
  };

  setSearchInterest = (value: string) => {
    runInAction(() => {
      this.searchInterest = value;
    });
  };

  setSelectedInterests = (interest: InterestType) => {
    runInAction(() => {
      this.selectedInterests = [...this.selectedInterests, interest];
    });
  };

  setUserInterestsToSelected = (userInterests: UserInterestType[]) => {
    runInAction(() => {
      this.selectedInterests = userInterests.map(item => ({
        interestId: item.interestId,
        interestName: item.interestName,
      }));
    });
  };

  deleteExpertise = (id: string) => {
    this.selectedInterests = this.selectedInterests.filter(item => item.interestId !== id);
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };

  reset = () => {
    runInAction(() => {
      this.interests = [];
      this.interest = null;
      this.selectedInterests = [];
      this.searchInterest = '';
    });
  };
}
