import { Box, Button, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils';
import OwnerConfigService from '../../../../../../../../../services/ownerConfig/ownerConfig.service';
import { getNotificatorProps } from '../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../shared/constanst/notifications';

const AutoReportPath = () => {
  const ownerConfigService = useStore(OwnerConfigService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { setNotification } = useNotificator();

  const [path, setPath] = useState('');

  useEffect(() => {
    ownerConfigService.getOwnerConfigByOwnerId({ ownerId: organizationId })?.then(data => {
      if (data?.path) setPath(data.path);
    });
  }, []);

  const handlePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPath(event.target?.value ?? '');
  };

  const savePath = useCallback(() => {
    ownerConfigService
      .setOwnerConfigByOwnerId({ ownerId: organizationId, path })
      .then(() => {
        setNotification(getNotificatorProps('Путь успешно сохранён', ENotificationStyles.Success));
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка сохранения пути', ENotificationStyles.Error));
      });
  }, [organizationId, path]);

  const deletePath = useCallback(() => {
    ownerConfigService
      .deleteOwnerConfigByOwnerId({ ownerId: organizationId })
      .then(() => {
        setNotification(getNotificatorProps('Путь успешно удалён', ENotificationStyles.Success));
        setPath('');
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка удаления пути', ENotificationStyles.Error));
      });
  }, [organizationId]);

  return (
    <Box display={'flex'} flexDirection={'row'} gap={2}>
      <TextField
        label="Пусть до папки автоматической выгрузки"
        fullWidth
        value={path}
        onChange={handlePathChange}
        sx={{ maxWidth: '80%' }}
      />
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Button variant="contained" color="primary" onClick={savePath}>
          Сохранить
        </Button>
        <Button variant="text" color="error" onClick={deletePath} disabled={!path}>
          Удалить
        </Button>
      </Box>
    </Box>
  );
};

export default AutoReportPath;
