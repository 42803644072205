import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';

import { AlertsTable, CheckListTable } from './components';
import { AlertsFilter } from './components/AlertsFilter/AlertsFilter';
import { CheckListFilter } from './components/CheckListFilter/CheckListFilter';

export const AlertsList: FC = observer(() => {
  const [value, setValue] = useState<string>('1');

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <HeaderWrapper>
        <TopSection>
          <H1>Алерты</H1>
        </TopSection>
      </HeaderWrapper>
      <MainBody>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Задачи" value="1" />
              <Tab label="Чек листы" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <AlertsFilter /> 
            <AlertsTable/>
          </TabPanel>
          <TabPanel value="2">
            <CheckListFilter /> 
            <CheckListTable/>
          </TabPanel>
        </TabContext>
      </MainBody>
    </MainContainer>
  );
});
