import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CircularProgress } from '@mui/material';

import { IAttribute } from '../../../../../../../api/models/checklist.attribute.model';
import { ChecklistsAttributeController } from '../../../../../../controllers/checklistAttribute.controller';
import { MenuItemsProps } from '../../../../../../shared/menu/type';
import { useStore } from '../../../../../../shared/utils';
import { Layout } from '../../../../../components';
import { AdminRoutes } from '../../../../../routes';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../style';
import { getBasicChecklistLayoutMenu } from '../../../helpers/getBasicChecklistLayoutMenu';

import { AttributeBuilderFrom } from './components';

const AttributeContainer = () => {
  const { attributeId } = useParams<{ attributeId: string }>();
  const [attributeData, setAttributeData] = useState<IAttribute>(null);
  const [isLoading, setLoading] = useState(false);
  const { getChecklistAttribute } = useStore(ChecklistsAttributeController);

  useEffect(() => {
    if (attributeId) {
      setLoading(true);
      getChecklistAttribute({ id: attributeId })
        .then(data => {
          const attribute: IAttribute & { usingDictionary?: string } = { ...data };

          if (attribute?.dictionaryLink) {
            attribute.usingDictionary = attribute.dictionaryLink;
          }

          setAttributeData(attribute);
        })
        .finally(() => setLoading(false));
    }
  }, [attributeId]);

  const menuItems: MenuItemsProps = useMemo(
    () => getBasicChecklistLayoutMenu({ goBackLink: AdminRoutes.ChecklistAttributes }),
    []
  );

  return (
    <Layout menuItems={menuItems} sidebarComponent={null}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Базовый атрибут. {attributeData?.name}</H1>
          </TopSection>
        </HeaderWrapper>
        <MainBody minHeight="calc(100vh - 205px)">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <AttributeBuilderFrom attributeData={attributeData} />
          )}
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(AttributeContainer);
