import { IFormulaError } from '../../../../../../../../modules/Formulas/models';

enum EODZCriteria {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
}

interface IODZError extends IFormulaError {
  criteria: EODZCriteria;
}

export { EODZCriteria };
export type { IODZError };
