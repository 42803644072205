import {
  EOrgIntegraCropwiseErrorMessageEntityType,
  EOrgIntegraCropwiseErrorMessageOperationType,
  IOrgIntegraCropwiseErrorMessage,
} from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseErrorMessage/models/orgIntegraCropwiseErrorMessage';
import {
  IPageableRequest,
  TypeResponsePageable,
} from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IPageableRequest &
  Partial<{
    entityTypes: EOrgIntegraCropwiseErrorMessageEntityType[];
    operationTypes: EOrgIntegraCropwiseErrorMessageOperationType[];
    entityIds: string[];
    organizationIds: string[];
    userIds: string[];
    startTime: string;
    endTime: string;
    maxAttempts: number;
  }>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraCropwiseErrorMessage[] };

type TypeError = {};

export const getOrgIntegraCropwiseErrorMessagesByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/integration/messageError/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
