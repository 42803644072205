import styled from 'styled-components';

const SearchForm = styled.div`
  display: flex;
  gap: 12px;
`;

const SearchWrapper = styled.div`
  min-width: 230px;
  max-width: 400px;
  width: 100%;
`;

const Styled = {
  SearchWrapper,
  SearchForm,
};

export default Styled;
