import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useCallback, useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import {
  ERROR_FILE_DETAILS_ID,
  ERROR_FILE_MULTIPLE_PUSH_ID,
  ERROR_FILE_SINGLE_PUSH_ID,
} from '../../modals';
import { IOrgIntegraAgrosignalErrorFile } from '../../models/orgIntegraAgrosignalErrorFile';
import { BooleanParsedValue } from '../../../../../../../../../../../shared/components/BooleanParsedValue';

// const TYPE_ENTITY_SCHEME: Record<
//   EOrgIntegraAgrosignalErrorMessageEntityType,
//   { text: string; color: string }
// > = {
//   [EOrgIntegraAgrosignalErrorMessageEntityType.Checklist]: { text: 'Шаблон', color: 'green' },
//   [EOrgIntegraAgrosignalErrorMessageEntityType.ChecklistInstance]: {
//     text: 'Чек-лист',
//     color: 'yellow',
//   },
//   [EOrgIntegraAgrosignalErrorMessageEntityType.Task]: { text: 'Задача', color: 'blue' },
// };

// const TYPE_OPERATION_SCHEME: Record<
//   EOrgIntegraAgrosignalErrorMessageOperationType,
//   { text: string; color: string }
// > = {
//   [EOrgIntegraAgrosignalErrorMessageOperationType.Create]: { text: 'Создание', color: 'green' },
//   [EOrgIntegraAgrosignalErrorMessageOperationType.Delete]: {
//     text: 'Удаление',
//     color: 'red',
//   },
//   [EOrgIntegraAgrosignalErrorMessageOperationType.Update]: { text: 'Обновление', color: 'blue' },
// };

const ErrorFileTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteErrorMessageList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = (_, selectionList: string[]) => {
    openModalByModalId(
      selectionList?.length > 0 ? ERROR_FILE_MULTIPLE_PUSH_ID : ERROR_FILE_SINGLE_PUSH_ID,
      {
        mode: 'create',
        organizationId,
        selectionList: selectionList || null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraAgrosignalErrorFile) => {
    openModalByModalId(
      ERROR_FILE_DETAILS_ID,
      {
        mode: 'update',
        organizationId,
        errorMessage: value,
      },
      () => {
        // TODO придумать более оптимальный апдейт
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraAgrosignalErrorFile> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchErrorFileList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraAgrosignalErrorFile>
          actionButton={{ text: 'Обработка', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID чек-лист точки “Помощник”',
                propertyName: 'checklistInstanceId',
                placeholder: 'Введите ID чек-лист точки “Помощник”',
              },
              {
                type: 'text',
                label: 'ID файла “Помощник”',
                propertyName: 'fileId',
                placeholder: 'Введите ID файла “Помощник”',
              },
              {
                type: 'select',
                label: 'Загружен',
                propertyName: 'isUpload',
                placeholder: 'Выберите сущность',
                optionList: [
                  { label: 'Да', value: 'true' },
                  { label: 'Нет', value: 'false' },
                ],
              },
              {
                type: 'select',
                label: 'Удален',
                propertyName: 'isDeleted',
                placeholder: 'Выберите сущность',
                optionList: [
                  { label: 'Да', value: 'true' },
                  { label: 'Нет', value: 'false' },
                ],
              },
              {
                type: 'number',
                label: 'Минимальное количество попыток',
                propertyName: 'countAttemptsMin',
                placeholder: 'Введите минимальное количество попыток',
              },
              {
                type: 'number',
                label: 'Максимальное количество попыток',
                propertyName: 'countAttemptsMax',
                placeholder: 'Введите максимальное количество попыток',
              },

              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createStartDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createEndDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateStartDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateEndDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'checklistInstanceId',
              headerName: 'ID Чек-лист точки “Помощник”',
              flex: 1,
              valueGetter: row => row.row?.checklistInstance?.checklistInstanceId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'fileId',
              headerName: 'ID файла “Помощник”',
              flex: 1,
              valueGetter: row => row.row?.fileId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'urlDownload',
              headerName: 'URL',
              flex: 1,
              valueGetter: row => row.row?.urlDownload,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'isUpload',
              headerName: 'Загружен',
              flex: 1,
              maxWidth: 50,
              valueGetter: row => row.row.isUpload,
              renderCell: row => <BooleanParsedValue value={row.value} />,
            },
            {
              field: 'isDeleted',
              headerName: 'Удален',
              flex: 1,
              maxWidth: 50,
              valueGetter: row => row.row.isDeleted,
              renderCell: row => <BooleanParsedValue value={row.value} />,
            },
            {
              field: 'countAttempts',
              headerName: 'Количество попыток',
              flex: 1,
              maxWidth: 50,
              valueGetter: row => row.row.countAttempts,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ErrorFileTable;
