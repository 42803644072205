import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EditInvitationModal } from './components';

const EDIT_INVITATION_MODAL_ID = 'edit-invitation-modal';

const editInvitationModalConfig: TModalConfig = {
  id: EDIT_INVITATION_MODAL_ID,
  name: EDIT_INVITATION_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '650px',
  },
  children: EditInvitationModal,
};

export { EDIT_INVITATION_MODAL_ID, editInvitationModalConfig };
