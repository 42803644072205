import moment from 'moment';

import { TypeCheckList } from '../../../../../../../../../api/models/check.list.model';
import { STATUSES } from '../../../../../../../../../constants/statuses';

export const getRows = (checkLists: TypeCheckList[]) => 
  checkLists.map(item => ({
    name: item.name,
    timestamp: moment(item.timestamp).format('DD.MM.yyyy'),
    id: item.id,
    error: item.error,
    status: STATUSES[item.status],
  }));
