import { GridColDef } from '@mui/x-data-grid';
import { has } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../shared/utils';
import { EAdvancedGridFilterItemType, IAdvancedGridFilterItem } from '../../AdvancedGrid.type';

import { ISelectOptionExtended } from './../../../../../../../../types/selectOption';

export interface IAdvancedGridMapItem {
  gridColumns: GridColDef[];
  gridRows?: Record<string, any>[];
  filter?: IAdvancedGridFilterItem<EAdvancedGridFilterItemType>[];
}

type TCurrentGridFilterValues = Record<string, string | ISelectOptionExtended | boolean>;

@provide.singleton()
export class AdvancedGridStore {
  private _gridMap: Map<string, IAdvancedGridMapItem> = new Map();
  private _currentGridId: string | null = null;
  private _currentGridFilterValues: TCurrentGridFilterValues = {};
  private _isLoading = false;
  private _isFiltersDirty = false;

  constructor() {
    makeAutoObservable(this);
  }

  get gridMap() {
    return this._gridMap;
  }

  get currentGridId() {
    return this._currentGridId;
  }

  get currentGridFilterValues() {
    return this._currentGridFilterValues;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isFiltersDirty() {
    return this._isFiltersDirty;
  }

  getGridMapItem = (id: string) => {
    return this._gridMap.get(id);
  };

  getCurrentGridFilterValue = (
    key: string
  ): string | ISelectOptionExtended | number | boolean | null => {
    if (!this._currentGridFilterValues) {
      return null;
    }

    return this._currentGridFilterValues[key] || null;
  };

  setGridMapItem = (id: string, item: IAdvancedGridMapItem) => {
    return this._gridMap.set(id, item);
  };

  setCurrentGridId = (id: string) => {
    this._currentGridId = id;
  };

  setCurrentGridFilterValue = (value: TCurrentGridFilterValues) => {
    this._currentGridFilterValues = { ...this._currentGridFilterValues, ...value };
    this._isFiltersDirty = true;
  };

  setIsLoading = (status: boolean) => {
    this._isLoading = status;
  };

  setIsFiltersDirty = (status: boolean) => {
    this._isFiltersDirty = status;
  };

  removeGridItem = (id: string) => {
    return this._gridMap.delete(id);
  };

  removeValueFromFilter = (key: string) => {
    const updatedValue = { ...this._currentGridFilterValues };

    if (has(updatedValue, key)) {
      delete updatedValue[key];
      this._currentGridFilterValues = updatedValue;
    }
  };

  clearGridMap = () => {
    this._gridMap = new Map();
  };

  clearFilters = () => {
    this._currentGridFilterValues = {};
    this._isFiltersDirty = false;
  };
}
