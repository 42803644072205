import * as yup from 'yup';

const AGROSIGNAL_REPORT_SCHEME = yup.object().shape({
  clientId: yup
    .number()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  name: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  host: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  apiKey: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  reportsLocation: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  filesLocation: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  fromDate: yup
    .date()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  toDate: yup.date().nullable(),
});

export default AGROSIGNAL_REPORT_SCHEME;
