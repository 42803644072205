import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CultureModalForm } from './components';

const CULTURE_CROPWISE_MODAL_ID = 'culture-cropwise-modal-id';

const cultureModalConfig: TModalConfig = {
  id: CULTURE_CROPWISE_MODAL_ID,
  name: CULTURE_CROPWISE_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: CultureModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: '600px',
    $fullHeight: true,
    $height: '750px',
  },
};

export { cultureModalConfig, CULTURE_CROPWISE_MODAL_ID };
