import styled from 'styled-components';

const SelectInputWrapper = styled.div`
  min-width: 300px;
  max-width: 512px;
`;

const Styled = {
  SelectInputWrapper,
};

export default Styled;
