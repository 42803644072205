import { IconButton, Menu } from '@mui/material';
import { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';

import { ContextMenu } from '../../../../../DictionarySidebar/components';
import { TDictionaryItem } from '../../../../../../../../../../api/models/dictionaries.model';

const TableContextMenu: FC<{ id: string; value: TDictionaryItem }> = ({ id, value }) => {
  const { name: remoteName } = useParams<{ name: string }>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={openMenu}
        size="small"
        className="context-menu-button"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ContextMenu id={id} remoteName={remoteName} value={value} />
      </Menu>
    </>
  );
};

export default TableContextMenu;
