import { FC } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { FormControlLabel, Tooltip } from '@mui/material';

import Styled from './RoleItemForm.styles';

enum EFormFields {
  Name = 'name',
  IsExperimentForUser = 'isExperimentForUser',
  IsExperimentForOrganization = 'isExperimentForOrganization',
  IsScoutForOrganization = 'isScoutForOrganization',
  IsScoutForUser = 'isScoutForUser',
  IsOwner = 'isOwner',
}

const LABEL_SCHEME = {
  [EFormFields.Name]: 'Название *',
  [EFormFields.IsExperimentForUser]: 'Роль только для опытных пользователей',
  [EFormFields.IsExperimentForOrganization]: 'Роль только для контрагентов по опытам',
  [EFormFields.IsScoutForOrganization]: 'Роль только для контрагентов скаутинга',
  [EFormFields.IsScoutForUser]: 'Роль только для скаутов',
  [EFormFields.IsOwner]: 'Роль владельца',
};

const RoleItemForm: FC<{ isCreation: boolean }> = ({ isCreation }) => {
  const { values, touched, errors } = useFormikContext();

  return (
    <div>
      <Form>
        <Field
          component={TextField}
          fullWidth
          name={EFormFields.Name}
          value={values[EFormFields.Name] ?? ''}
          placeholder="Укажите название"
          label={LABEL_SCHEME[EFormFields.Name]}
          error={Boolean(errors[EFormFields.Name])}
          helperText={errors[EFormFields.Name]}
        />
        <Styled.GridWrapper>
          <Styled.GridGroup>
            <FormControlLabel
              label={LABEL_SCHEME[EFormFields.IsExperimentForUser]}
              control={
                <Field
                  component={Switch}
                  name={EFormFields.IsExperimentForUser}
                  value={Boolean(values[EFormFields.IsExperimentForUser])}
                  checked={Boolean(values[EFormFields.IsExperimentForUser])}
                  error={
                    touched[EFormFields.IsExperimentForUser] &&
                    errors[EFormFields.IsExperimentForUser]
                  }
                />
              }
            />
            <FormControlLabel
              label={LABEL_SCHEME[EFormFields.IsExperimentForOrganization]}
              control={
                <Field
                  component={Switch}
                  name={EFormFields.IsExperimentForOrganization}
                  value={Boolean(values[EFormFields.IsExperimentForOrganization])}
                  checked={Boolean(values[EFormFields.IsExperimentForOrganization])}
                  error={
                    touched[EFormFields.IsExperimentForOrganization] &&
                    errors[EFormFields.IsExperimentForOrganization]
                  }
                />
              }
            />
            <FormControlLabel
              label={LABEL_SCHEME[EFormFields.IsScoutForOrganization]}
              control={
                <Field
                  component={Switch}
                  name={EFormFields.IsScoutForOrganization}
                  value={Boolean(values[EFormFields.IsScoutForOrganization])}
                  checked={Boolean(values[EFormFields.IsScoutForOrganization])}
                  error={
                    touched[EFormFields.IsScoutForOrganization] &&
                    errors[EFormFields.IsScoutForOrganization]
                  }
                />
              }
            />
            <FormControlLabel
              label={LABEL_SCHEME[EFormFields.IsScoutForUser]}
              control={
                <Field
                  component={Switch}
                  name={EFormFields.IsScoutForUser}
                  value={Boolean(values[EFormFields.IsScoutForUser])}
                  checked={Boolean(values[EFormFields.IsScoutForUser])}
                  error={touched[EFormFields.IsScoutForUser] && errors[EFormFields.IsScoutForUser]}
                />
              }
            />
          </Styled.GridGroup>

          {!isCreation && (
            <FormControlLabel
              label={LABEL_SCHEME[EFormFields.IsOwner]}
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  disabled
                  name={EFormFields.IsOwner}
                  defaultChecked={values[EFormFields.IsOwner]}
                  error={touched[EFormFields.IsOwner] && errors[EFormFields.IsOwner]}
                />
              }
            />
          )}
        </Styled.GridWrapper>
      </Form>
    </div>
  );
};

export default RoleItemForm;
