import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  ownerId: string;
  path: string;
};

type TypeError = {};

type TypeResponse = string;

export const setOwnerConfigByOwnerId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (params: TypeRequest) => `/api/as-weather/ownerConfig/setByOwner/${params.ownerId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
