import { useEffect, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';

import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { TypeApiRequest, useStore } from '../../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseErrorMessageService } from '../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';
import { IOrgIntegraEmployee } from '../../../../OrgIntegraCropwiseEmployees/models';
import { OrgIntegraCropwiseService } from '../../../../../mobx';
import {
  EOrgIntegraCropwiseErrorMessageEntityType,
  EOrgIntegraCropwiseErrorMessageOperationType,
} from '../../../../OrgIntegraCropwiseErrorMessage/models/orgIntegraCropwiseErrorMessage';

const useMessagePushForm = (organizationId: string) => {
  const service = useStore(OrgIntegraCropwiseService);
  const pushService = useStore(OrgIntegraCropwiseErrorMessageService);

  const [employeeList, setEmployeeList] = useState<ISelectOptionExtended<IOrgIntegraEmployee>[]>(
    []
  );
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<
    ISelectOptionExtended<IOrgIntegraEmployee>[]
  >([]);
  const [maxDate, setMaxDate] = useState<string>(null);
  const [minDate, setMinDate] = useState<string>(null);
  const [entityTypeList, setEntityTypeList] = useState<ISelectOptionExtended[]>(null);
  const [operationTypeList, setOperationTypeList] = useState<ISelectOptionExtended[]>(null);
  const [checklistInstanceIdList, setChecklistInstanceIdList] = useState<string[]>([]);

  const { setNotification } = useNotificator();

  useEffect(() => {
    if (!organizationId) return;

    service.fetchEmployeeList({ organizationId }).then(data => {
      setEmployeeList(
        data.content.map(item => ({
          label: item.userName || item.agroName || item.userId,
          value: item.userId,
          rawData: item,
        }))
      );
    });
  }, []);

  const addChecklistInstanceId = (id: string) => {
    setChecklistInstanceIdList([...checklistInstanceIdList, id]);
  };

  const removeChecklistInstanceId = (id: string) => {
    setChecklistInstanceIdList(checklistInstanceIdList.filter(item => item !== id));
  };

  const pushMessage = () => {
    if (!minDate) {
      setNotification(getNotificatorProps('Не заполнена дата начала', ENotificationStyles.Error));

      return Promise.reject(new Error());
    }

    const payload: TypeApiRequest<'pushOrgIntegraCropwiseErrorFile'> = {
      startDateTime: minDate,
    };

    if (maxDate) {
      payload.endDateTime = maxDate;
    }

    if (selectedEmployeeList?.length > 0) {
      payload.userIds = selectedEmployeeList?.flatMap(item => item.value as string);
    }

    if (checklistInstanceIdList?.length > 0) {
      payload.checkListInstanceIds = checklistInstanceIdList;
    }
    // if (entityTypeList?.length > 0) {
    //   payload.entityTypes = entityTypeList?.flatMap(
    //     item => item.value as EOrgIntegraCropwiseErrorMessageEntityType
    //   );
    // }

    // if (operationTypeList?.length > 0) {
    //   payload.operationTypes = operationTypeList?.flatMap(
    //     item => item.value as EOrgIntegraCropwiseErrorMessageOperationType
    //   );
    // }

    return pushService
      .pushMessage(payload)
      .then(data => {
        setNotification(
          getNotificatorProps('Сообщения успешно отправлены', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(e => {
        setNotification(
          getNotificatorProps('Ошибка отправки сообщений', ENotificationStyles.Error)
        );

        return e;
      });
  };

  return {
    employeeList,
    setSelectedEmployeeList,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    pushMessage,
    setEntityTypeList,
    setOperationTypeList,
    addChecklistInstanceId,
    removeChecklistInstanceId,
    checklistInstanceIdList,
  };
};

export { useMessagePushForm };
