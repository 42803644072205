import { has, pickBy } from 'lodash';

import {
  ICreateOrgIntegraAgrosignalConfig,
  IOrgIntegraConfigExtended,
  IUpdateOrgIntegraAgrosignalConfig,
} from '../../../../../../../../../../api/models/integra/config.model';
import { ERequestStatus } from '../../../../../../../../../shared/types/requests';
import { lazyInject, provide } from '../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../services';
import { OrgIntegraAgrosignalStore } from '../stores';

@provide.transient()
class OrgIntegraAgrosignalController {
  @lazyInject(OrgIntegraAgrosignalStore)
  protected store: OrgIntegraAgrosignalStore;

  @lazyInject(OrgIntegraAgrosignalService)
  protected service: OrgIntegraAgrosignalService;

  fetchConfig = (orgId: string) => {
    return this.service.fetchConfigById(orgId);
  };

  createConfig = (
    formData: Partial<IUpdateOrgIntegraAgrosignalConfig & IOrgIntegraConfigExtended>
  ) => {
    if (
      !has(formData, 'agroId') ||
      !has(formData, 'organizationId') ||
      !has(formData, 'agroName') ||
      !has(formData, 'host') ||
      !has(formData, 'apiKey')
    ) {
      return Promise.reject(new Error('Проверьте заполненность полей'));
    }

    const payload: ICreateOrgIntegraAgrosignalConfig = {
      ...(formData as ICreateOrgIntegraAgrosignalConfig),
      clientId: formData.agroId || formData.clientId,
      name: formData.agroName || formData.name,
    };

    return this.service.createConfig(payload).then(data => {
      this.fetchConfig(formData.organizationId);
      this.store.clearDirtyList();

      return data;
    });
  };

  updateConfig = (
    formData: Partial<IUpdateOrgIntegraAgrosignalConfig & IOrgIntegraConfigExtended>
  ) => {
    const { dirtyFieldList } = this.store;

    const payload: Partial<IUpdateOrgIntegraAgrosignalConfig> = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => dirtyFieldList.has(key as string))
    );

    payload.organizationId = formData.organizationId;
    payload.id = formData.id;

    return this.service.updateConfig(payload).then(data => {
      this.fetchConfig(formData.organizationId);
      this.store.clearDirtyList();

      return data;
    });
  };

  clearOnUnmount = () => {
    this.store.clearStore();
  };
}

export default OrgIntegraAgrosignalController;
