import { Chip } from '@farmlink/farmik-ui';
import { FC } from 'react';

const ParsedActivityCell: FC<{ value?: boolean }> = ({ value }) => {
  return (
    <Chip size="medium" color={value ? 'primary' : 'secondary'}>
      {value ? 'Активен' : 'Не активен'}
    </Chip>
  );
};

export default ParsedActivityCell;
