import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { AdminRoutes } from '../../../../routes';
import { useStore } from '../../../../../shared/utils';

import { OrganizationRoleCreate, OrganizationRoleItem, OrganizationRoleList } from './components';
import { OrganizationRoleController as Controller } from './mobx';
import { deleteRoleModalConfig } from './modals';

const OrganizationRolesRouter = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const controller = useStore(Controller);
  const { registerModalList } = useModal();

  useEffect(() => {
    controller.fetchRoleList(organizationId);

    registerModalList([deleteRoleModalConfig], 'orgRoleModals');
  }, []);

  return (
    <Switch>
      <Route exact path={AdminRoutes.OrganizationRoleList} component={OrganizationRoleList} />
      <Route exact path={AdminRoutes.OrganizationRoleItem} component={OrganizationRoleItem} />
      <Route exact path={AdminRoutes.OrganizationRoleCreate} component={OrganizationRoleCreate} />
      <Redirect to={AdminRoutes.OrganizationRoleList} />
    </Switch>
  );
};

export default observer(OrganizationRolesRouter);
