import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ContextMenu } from '../ContextMenu';
import { TDictionaryItem } from '../../../../../../../../api/models/dictionaries.model';

import Styled from './styled';

const TreeNodeTitle: FC<{
  isSelected?: boolean;
  remoteName: string;
  id: string;
  value: TDictionaryItem;
  isMenuDisabled?: boolean;
}> = ({ children, remoteName, id, value, isMenuDisabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    // event.preventDefault();

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Styled.TitleWrapper className="dictionary-title">
      <Typography padding="6px 0">{children}</Typography>
      {!isMenuDisabled && (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClickCapture={openMenu}
            size="small"
            className="context-menu-button"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ContextMenu id={id} remoteName={remoteName} value={value} />
          </Menu>
        </>
      )}
    </Styled.TitleWrapper>
  );
};

export default TreeNodeTitle;
