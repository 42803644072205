import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  employeeId: string;
};

type TypeResponse = {};

type TypeError = {};

export const unblockUserOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ employeeId }) => `/api/da-profile/organizations/user/reinstate/${employeeId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
