import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { BorderRadius } from '../../../../../../../../constants/sizes';

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.generalDark};
  white-space: pre-wrap;
`;

const OrgList = styled.ul`
  padding-inline-start: 0;
  margin-block-start: 0;
  list-style-type: none;
  margin-top: 16px;
  height: 260px;
`;

const OrgItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 4px 18px;
  border-radius: ${BorderRadius.default};
  background-color: ${Colors.hoverGray};

  & + & {
    margin-top: 8px;
  }
`;

const Styled = { Title, OrgList, OrgItem };

export default Styled;
