import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { FC, memo, useMemo } from 'react';

const DateParsedValue: FC<{ value: string; fontSize?: number; format?: string }> = ({
  value,
  fontSize,
  format,
}) => {
  const parsedValue = useMemo(() => {
    if (!value || value.length < 1) return '';

    const date = moment(value);

    return date.isValid() ? moment(value).format(format ?? 'DD.MM.YYYY') : value;
  }, [value, format]);

  return (
    <Tooltip title={value}>
      <Typography fontSize={fontSize ?? 14} noWrap>
        {parsedValue}
      </Typography>
    </Tooltip>
  );
};

export default memo(DateParsedValue);
