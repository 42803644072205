import { FC } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import { ISelectOptionExtended } from '../../../../../../../../../../types/selectOption';
import { IAttributeInChecklist } from '../../../../../../../../../../api/models/checklist.attribute.model';

import Styled from './Item.styles';

export interface ITooltipConfig {
  /**
   * Указатель какое поле из `rawData` использовать как тултип.
   */
  fieldName?: string;
  placement?: TooltipProps['placement'];
}

const Item: FC<{
  props: React.HTMLAttributes<HTMLLIElement>;
  value: ISelectOptionExtended;
  tooltip?: ITooltipConfig;
}> = ({ props, value, tooltip }) => {
  return (
    <Tooltip title={value?.rawData?.[tooltip?.fieldName] || ''} placement={tooltip?.placement}>
      <Styled.Item {...props}>{value.label}</Styled.Item>
    </Tooltip>
  );
};

export default Item;
