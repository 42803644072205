import { IRoleModel } from '../../models/role.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  name: string;
  order?: number;
  isScoutForUser?: boolean;
  isScoutForOrganization?: boolean;
  isExperimentForUser?: boolean;
  isExperimentForOrganization?: boolean;
  actionCodes?: string[];
};

type TypeResponse = IRoleModel;

type TypeError = {};

export const createAccessRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/access/role`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
