import { Axios, TypeApiRequest, lazyInject, provide } from '../../../../../../../shared/utils';

@provide.transient()
class OrgIntegraService {
  @lazyInject(Axios)
  protected axios: Axios;

  createOrgIntegraConfig = (payload: TypeApiRequest<'createOrgIntegraConfig'>) => {
    return this.axios.api.createOrgIntegraConfig(payload);
  };

  getOrgIntegraConfig = (payload: TypeApiRequest<'getOrgIntegraConfig'>) => {
    return this.axios.api.getOrgIntegraConfig(payload, { omit: ['organizationId'] });
  };

  updateOrgIntegraConfig = (payload: TypeApiRequest<'updateOrgIntegraConfig'>) => {
    return this.axios.api.updateOrgIntegraConfig(payload);
  };

  deleteOrgIntegraConfig = (organizationId: string) => {
    return this.axios.api.deleteOrgIntegraConfig({ organizationId });
  };

  pushEventsIntegra = (payload: TypeApiRequest<'pushEventsOrgIntegraConfig'>) => {
    return this.axios.api.pushEventsOrgIntegraConfig(payload);
  };
}

export default OrgIntegraService;
