import { ReactElement } from 'react';
import { GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';

import { EEmployeeStatus } from '../../../../../../../api/models/employee.model';
import { Phone } from '../../../../../../../api/models/user.admin.model';
import { TooltipedValue } from '../../../../../../shared/components';

export const getColumns = (
  renderActions: (params: GridRowParams) => ReactElement[],
  renderStatus: (params: GridRenderCellParams<EEmployeeStatus>) => ReactElement,
  renderPhone: (params: GridRenderCellParams<Phone>) => ReactElement
) => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    minWidth: 110,
    sortable: false,
    renderCell: renderStatus,
  },

  {
    field: 'userName',
    type: 'string',
    headerName: 'Имя',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: params => <TooltipedValue value={params.value} />,
  },

  {
    field: 'role',
    type: 'string',
    headerName: 'Роль',
    minWidth: 200,
    sortable: false,
    renderCell: params => <TooltipedValue value={params.value} />,
  },

  {
    field: 'phone',
    type: 'string',
    headerName: 'Номер телефона',
    minWidth: 150,
    sortable: false,
    renderCell: renderPhone,
  },

  {
    field: 'email',
    type: 'string',
    headerName: 'e-mail',
    minWidth: 200,
    sortable: false,
    renderCell: params => <TooltipedValue value={params.value} />,
  },

  {
    field: 'joinDate',
    type: 'string',
    headerName: 'Дата вступления',
    minWidth: 140,
    sortable: false,
  },

  {
    field: 'actions',
    type: 'actions',
    width: 100,
    getActions: renderActions,
  },
];
