import { IOrgIntegraConfig } from '../../../models/integra/config.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraConfig>;

type TypeResponse = IOrgIntegraConfig;

type TypeError = {};

export const updateOrgIntegraConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }: TypeRequest) => `/api/as-fields/admin/integra/conf/${organizationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
