import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ErrorMessageDetailsForm } from './components';

const ERROR_FILE_DETAILS_ID = 'errorFileDetails';

const errorMessageDetailsForm: TModalConfig = {
  id: ERROR_FILE_DETAILS_ID,
  name: ERROR_FILE_DETAILS_ID,
  title: '',
  type: EModalType.Custom,
  children: ErrorMessageDetailsForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: '800px',
    $fullHeight: true,
    $height: '750px',
  },
};

export { errorMessageDetailsForm, ERROR_FILE_DETAILS_ID };
