import styled from 'styled-components';

import { Colors } from '../../../../../../../../constants/colors';

const SwitchLabel = styled.label`
  display: flex;
  flex: 1;
  min-width: 256px;
  max-width: 512px;
  align-items: center;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: ${Colors.red};
  margin: 0;
`;

const Styled = {
  SwitchLabel,
  ErrorText,
};

export default Styled;
