import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Button,
  Grid,
  LinearProgress,
  TextField as MuiTextField,
  Stack,
  Link,
  FormHelperText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { DatePicker } from 'formik-mui-lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import EditIcon from '@mui/icons-material/Edit';

import { useStore, isFormValuesErrors } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../stores/users/users.store';
import { userFormEditSchema } from '../../../../../shared/schema/userFormEditSchema';
import {
  EFormFields,
  IFormEditValues,
  TypeUserAdminGender,
  EUserAdminStatus,
  EPlatform,
} from '../../../../../../api/models/user.admin.model';
import { Uploader } from '../../../../../shared/components/Uploader';
import { getUserMenu } from '../../../../../shared/menu';
import { TextMaskField } from '../../../../../shared/components/TextMaskField';
import { getEmail, getGender, getPhoneNumberCodeMask } from '../../utils';
import { ExpertisesInterestsBlock } from '../../style';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import {
  Layout,
  ExpertiseWidget,
  InteresWidget,
  Snackbar,
  ButtonGroup,
} from '../../../../components';
import { ActionList } from '../../../../../shared/components/ActionsList/ActionList';
import { TAction } from '../../../../../../api/models/action.model';

import {
  UserPhoneEditModal,
  UserEmailEditModal,
  BlockUserDialog,
  ResetPasswordDialog,
} from './components';
import { ChangeActionModal } from './components/ChangeActionModal/ChangeActionModal';

export const UserEdit: FC = observer(() => {
  const {
    updateUser,
    uploadFile,
    resetPassword,
    loginAsUser,
    fetchModules,
    fetchUserActions,
    userHasAction,
    setUserAction,
  } = useStore(UsersController);
  const {
    user,
    pendingEmail,
    isLoading,
    isLoaded,
    gendersOptions,
    resetPasswordStatus,
    formValuesErrors,
    actionList,
    clearUsersStoreOnLeaveUserPage,
  } = useStore(UsersStore);

  const [showPhoneEditForm, setShowPhoneEditForm] = useState<boolean>(false);
  const [showEmailEditForm, setShowEmailEditForm] = useState<boolean>(false);

  const [showBlockUserModal, setShowBlockUserModal] = useState<boolean>(false);
  const [showUnlockUserModal, setShowUnlockUserModal] = useState<boolean>(false);

  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);

  const [changeActionModalMessage, setChangeActionModalMessage] = useState<null | {
    message: string;
    code: string;
  }>(null);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const [isActionsLoading, setIsActionsLoading] = useState(true);

  const { userId } = useParams<{ userId?: string }>();

  const handleShowPhoneEditForm = useCallback(() => {
    setShowPhoneEditForm(true);
  }, []);
  const handleClosePhoneEditForm = useCallback(() => {
    setShowPhoneEditForm(false);
  }, []);

  const handleShowEmailEditForm = useCallback(() => {
    setShowEmailEditForm(true);
  }, []);

  const handleCloseEmailEditForm = useCallback(() => {
    setShowEmailEditForm(false);
  }, []);

  const handleShowBlockUserModal = useCallback(() => {
    setShowBlockUserModal(true);
  }, []);

  const handleShowUnlockUserModal = useCallback(() => {
    setShowUnlockUserModal(true);
  }, []);

  const handleCloseChangeActionDialog = useCallback(() => {
    setChangeActionModalMessage(null);
  }, []);

  const handleActionSetAgreement = useCallback((flag: boolean) => {
    updateUser({ userId, isScout: flag });
  }, []);

  const handleCloseBlockUserModal = () => {
    setShowBlockUserModal(false);
  };

  const handleCloseUnlockUserModal = () => {
    setShowUnlockUserModal(false);
  };

  const handleShowResetPasswordModal = useCallback(() => {
    setResetPasswordModal(true);
  }, []);

  const handleCloseResetPasswordModal = () => {
    setResetPasswordModal(false);
  };

  const handleShowSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const handleResetPassword = () => {
    resetPassword({ userId }).then(() => {
      handleShowResetPasswordModal();
    });
  };

  const handleLoginAsUser = (platform: EPlatform) => () => {
    loginAsUser({ id: userId }, platform).catch(() => handleShowSnackbar());
  };

  const handleSubmitForm = (
    values: IFormEditValues,
    action: FormikHelpers<IFormEditValues>
  ): void => {
    const payload = {
      userId,
      gender: values.gender ? values.gender.genderId : null,
      dateOfBirth: values.dateOfBirth ? values.dateOfBirth.format('DD.MM.yyyy') : null,
      firstName: values.firstName,
      lastName: values.lastName,
      secondName: values.secondName,
      avatar: values.avatar,
    };

    updateUser(payload).finally(() => {
      action.setSubmitting(false);
    });
  };

  useEffect(() => {
    Promise.all([fetchModules(userId), fetchUserActions(userId)]).finally(() =>
      setIsActionsLoading(false)
    );

    return () => {
      clearUsersStoreOnLeaveUserPage();
    };
  }, []);

  const menuItems = useMemo(() => getUserMenu(userId), [userId]);
  const gender = useMemo(() => getGender(user.gender), [user]);
  const email = useMemo(() => getEmail(user.email), [user]);
  const phoneNumberCodeMask = useMemo(() => getPhoneNumberCodeMask(user.phone), [user]);

  const handleToggleAction = (
    entityId: string,
    action: TAction,
    state: boolean,
    isScout?: boolean
  ) => {
    if (isScout) {
      return updateUser({ userId, [action.id]: state });
    } else {
      return setUserAction(userId, action.code, state);
    }
  };

  if (isLoading) {
    return <p>Загрузка</p>;
  }

  const options = [
    {
      title: 'Assistant',
      onClick: handleLoginAsUser(EPlatform.assistant),
    },
    {
      title: 'Farmlink',
      onClick: handleLoginAsUser(EPlatform.farmlink),
    },
  ];

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        {isLoaded && (
          <>
            <HeaderWrapper>
              <TopSection>
                <H1>Личные данные пользователя</H1>
              </TopSection>
            </HeaderWrapper>

            <MainBody>
              <Grid container>
                <Formik
                  validationSchema={userFormEditSchema}
                  initialValues={{
                    lastName: user.lastName || '',
                    firstName: user.firstName || '',
                    secondName: user.secondName || '',
                    dateOfBirth: user.dateOfBirth
                      ? moment(user.dateOfBirth, 'DD.MM.yyyy')
                      : moment(),

                    gender,
                    phoneNumber: !isEmpty(user.phone) ? user.phone.phoneNumber : '',
                    email,
                    avatar: user.avatar ? user.avatar.id : '',
                  }}
                  enableReinitialize
                  onSubmit={handleSubmitForm}
                >
                  {({ values, touched, errors, setFieldValue, isSubmitting, submitForm }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Form>
                          <Grid container spacing={2}>
                            {isSubmitting && (
                              <Grid item xs={12}>
                                <LinearProgress />
                              </Grid>
                            )}

                            <Grid item xs={2}>
                              <Uploader
                                fileId={values.avatar}
                                onFileChosen={file =>
                                  uploadFile(file).then(({ id }: { id: string }) =>
                                    setFieldValue(EFormFields.avatar, id)
                                  )
                                }
                              />
                            </Grid>

                            <Grid item xs={10} container spacing={2}>
                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  name={EFormFields.lastName}
                                  value={values[EFormFields.lastName]}
                                  placeholder="Введите фамилию пользователя"
                                  label="Фамилия *"
                                  error={
                                    (touched[EFormFields.lastName] &&
                                      Boolean(errors[EFormFields.lastName])) ||
                                    isFormValuesErrors(EFormFields.lastName, formValuesErrors)
                                  }
                                  helperText={
                                    (touched[EFormFields.lastName] &&
                                      errors[EFormFields.lastName]) ||
                                    (isFormValuesErrors(EFormFields.lastName, formValuesErrors) &&
                                      formValuesErrors[EFormFields.lastName])
                                  }
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  name={EFormFields.firstName}
                                  value={values[EFormFields.firstName]}
                                  placeholder="Введите имя пользователя"
                                  label="Имя *"
                                  error={
                                    (touched[EFormFields.firstName] &&
                                      Boolean(errors[EFormFields.firstName])) ||
                                    isFormValuesErrors(EFormFields.firstName, formValuesErrors)
                                  }
                                  helperText={
                                    (touched[EFormFields.firstName] &&
                                      errors[EFormFields.firstName]) ||
                                    (isFormValuesErrors(EFormFields.firstName, formValuesErrors) &&
                                      formValuesErrors[EFormFields.firstName])
                                  }
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  name={EFormFields.secondName}
                                  value={values[EFormFields.secondName]}
                                  placeholder="Введите отчество пользователя"
                                  label="Отчество"
                                  error={
                                    touched[EFormFields.secondName] &&
                                    Boolean(errors[EFormFields.secondName])
                                  }
                                  helperText={
                                    touched[EFormFields.secondName] &&
                                    errors[EFormFields.secondName]
                                  }
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={4} alignSelf="flex-end">
                                <Field
                                  component={DatePicker}
                                  ampm
                                  name={EFormFields.dateOfBirth}
                                  value={values[EFormFields.dateOfBirth]}
                                  onChange={newValue =>
                                    setFieldValue(EFormFields.dateOfBirth, newValue)
                                  }
                                  inputFormat="DD.MM.yyyy"
                                  mask="__.__.____"
                                  textField={{
                                    fullWidth: true,
                                    placeholder: 'Дата рождения',
                                    label: 'Дата рождения',
                                    variant: 'outlined',
                                    error:
                                      (touched[EFormFields.dateOfBirth] &&
                                        Boolean(errors[EFormFields.dateOfBirth])) ||
                                      isFormValuesErrors(EFormFields.dateOfBirth, formValuesErrors),
                                    helperText:
                                      (touched[EFormFields.dateOfBirth] &&
                                        errors[EFormFields.dateOfBirth]) ||
                                      (isFormValuesErrors(
                                        EFormFields.dateOfBirth,
                                        formValuesErrors
                                      ) &&
                                        formValuesErrors[EFormFields.dateOfBirth]),
                                  }}
                                />
                              </Grid>

                              <Grid item xs={4} alignSelf="flex-end">
                                <Stack alignItems="flex-end" spacing={1}>
                                  <Link
                                    href="#"
                                    underline="none"
                                    onClick={handleShowPhoneEditForm}
                                    className="action-edit"
                                  >
                                    Изменить <EditIcon />
                                  </Link>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    name={EFormFields.phoneNumber}
                                    value={values[EFormFields.phoneNumber]}
                                    placeholder="Введите номер телефона"
                                    label="Номер телефона"
                                    variant="outlined"
                                    inputProps={{
                                      mask: `${phoneNumberCodeMask} (000) 000-00-00`,
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                      inputComponent: TextMaskField as any,
                                    }}
                                  />
                                </Stack>
                              </Grid>

                              <Grid item xs={4} alignSelf="flex-end">
                                <Stack alignItems="flex-end" spacing={1}>
                                  <Link
                                    href="#"
                                    underline="none"
                                    onClick={handleShowEmailEditForm}
                                    className="action-edit"
                                  >
                                    Изменить <EditIcon />
                                  </Link>
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    name={EFormFields.email}
                                    value={values[EFormFields.email]}
                                    placeholder="Введите E-mail пользователя"
                                    label="E-mail"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />

                                  {!isEmpty(pendingEmail) && (
                                    <FormHelperText error style={{ alignSelf: 'flex-start' }}>
                                      E-mail не подтвержден
                                    </FormHelperText>
                                  )}
                                </Stack>
                              </Grid>

                              <Grid item xs={4}>
                                <Field
                                  fullWidth
                                  name={EFormFields.gender}
                                  component={Autocomplete}
                                  options={gendersOptions}
                                  getOptionLabel={(option: TypeUserAdminGender) =>
                                    option.genderName
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.genderName === value.genderName
                                  }
                                  renderInput={params => (
                                    <MuiTextField
                                      {...params}
                                      fullWidth
                                      name={EFormFields.gender}
                                      placeholder="Ввыберите пол"
                                      label="Пол"
                                      error={
                                        (touched[EFormFields.gender] &&
                                          Boolean(errors[EFormFields.gender])) ||
                                        isFormValuesErrors(EFormFields.gender, formValuesErrors)
                                      }
                                      helperText={
                                        (touched[EFormFields.gender] &&
                                          errors[EFormFields.gender]) ||
                                        (isFormValuesErrors(EFormFields.gender, formValuesErrors) &&
                                          formValuesErrors[EFormFields.gender])
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={isSubmitting}
                                  onClick={submitForm}
                                >
                                  Сохранить
                                </Button>
                              </Grid>

                              <Grid item xs={3}>
                                {user.status === EUserAdminStatus.ACTIVE && (
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleShowBlockUserModal}
                                  >
                                    Заблокировать
                                  </Button>
                                )}

                                {user.status === EUserAdminStatus.BANNED && (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleShowUnlockUserModal}
                                  >
                                    Разблокировать
                                  </Button>
                                )}
                              </Grid>

                              <Grid item xs={3}>
                                {isEmpty(pendingEmail) && (
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleResetPassword}
                                  >
                                    Сброс пароля
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      </LocalizationProvider>
                    );
                  }}
                </Formik>

                <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={3}>
                    <ButtonGroup title="Login As" options={options} popperStyle={{ width: 200 }} />
                  </Grid>
                </Grid>

                <UserPhoneEditModal
                  userId={userId}
                  open={showPhoneEditForm}
                  onClose={handleClosePhoneEditForm}
                />

                <UserEmailEditModal
                  userId={userId}
                  open={showEmailEditForm}
                  onClose={handleCloseEmailEditForm}
                />

                <BlockUserDialog
                  status={EUserAdminStatus.BANNED}
                  open={showBlockUserModal}
                  onClose={handleCloseBlockUserModal}
                  message="Вы уверены, что хотите заблокировать пользователя?"
                  successMessage="Пользователь заблокирован"
                  actionButtonName="Заблокировать"
                  dataTestId="block-user-dialog"
                />

                <BlockUserDialog
                  status={EUserAdminStatus.ACTIVE}
                  open={showUnlockUserModal}
                  onClose={handleCloseUnlockUserModal}
                  message="Вы уверены, что хотите разблокировать пользователя?"
                  successMessage="Пользователь разблокирован"
                  actionButtonName="Разблокировать"
                  dataTestId="unlock-user-dialog"
                />

                <ResetPasswordDialog
                  status={resetPasswordStatus}
                  open={resetPasswordModal}
                  onClose={handleCloseResetPasswordModal}
                  dataTestId="reset-password-dialog"
                />

                <ChangeActionModal
                  open={Boolean(changeActionModalMessage)}
                  message={changeActionModalMessage?.message}
                  code={changeActionModalMessage?.code}
                  onClose={handleCloseChangeActionDialog}
                  handleActionSetAgreement={handleActionSetAgreement}
                />

                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                  message="Не удалось зайти под пользователя"
                />
              </Grid>
            </MainBody>
          </>
        )}
      </MainContainer>

      <MainContainer>
        {isActionsLoading ? (
          <LinearProgress />
        ) : (
          <ActionList
            actionList={actionList}
            getActionValue={userHasAction}
            setModalMessage={setChangeActionModalMessage}
            options={{
              itemOptions: {
                entityId: userId,
                changeHandler: {
                  default: {
                    update: (entityId, action, state, isScout) =>
                      handleToggleAction(entityId, action as TAction, state, isScout),
                  },
                  forScout: {
                    update: (entityId, action, state, isScout) =>
                      handleToggleAction(entityId, action as TAction, state, isScout),
                  },
                },
              },
            }}
          />
        )}
      </MainContainer>

      <ExpertisesInterestsBlock data-test-id="user-page-expertises-interests-widget">
        <ExpertiseWidget userId={userId} items={user?.expertises} />
        <InteresWidget userId={userId} items={user?.interests} />
      </ExpertisesInterestsBlock>
    </Layout>
  );
});
