import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Container } from '@mui/material';
import { GridColumns, GridRowId, DataGrid } from '@mui/x-data-grid';

import { CheckListStore } from '../../../../../../../stores/checkLists/check.list.store';
import { CheckListsController } from '../../../../../../../controllers/check.list.controller';
import { FilterStore } from '../../../../../../../stores/checkLists/filter.store';
import { useStore, getLink } from '../../../../../../../shared/utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';

import { getColumns, getRows } from './utils';

export const CheckListTable: FC = observer(() => {
  const history = useHistory();

  const { checkLists, setPage, page, totalPages, isLoading } = useStore(CheckListStore);
  const { fetchCheckLists } = useStore(CheckListsController);
  const {
    filters: { status },
  } = useStore(FilterStore);

  const handleOpenCheckListTask = (id: GridRowId) => {
    history.push(getLink(AdminRoutes.CheckListTask, { [id]: ERegexp.CHECKLISTID }));
  };

  useEffect(() => {
    fetchCheckLists();
  }, [page, status]);

  const rows = useMemo(() => getRows(checkLists), [checkLists]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(() => getColumns(), []);

  return (
    <Container disableGutters maxWidth={false}>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableColumnMenu
          hideFooterSelectedRowCount
          columns={columns}
          rows={rows}
          onRowClick={e => handleOpenCheckListTask(e.id)}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          loading={isLoading}
          rowsPerPageOptions={[20]}
          page={page}
          pageSize={20}
          rowCount={10 * totalPages}
        />
      </div>
    </Container>
  );
});
