import { TypeApiRoute } from '../../models/type.api.request';
import { TypeCheckList } from '../../models/check.list.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  statusIn: string;
  size: number;
  page: number;
};

type TypeResponse = { content: TypeCheckList[] } & TypeResponsePageable;

type TypeError = {};

export const getCheckLists: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/queue/checkListInstance/admin/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
