import { useShowTooltip } from '@farmlink/farmik-ui';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

interface IProps extends TypographyProps {
  children: string;
}

const AutoTooltip: FC<IProps> = props => {
  const { isShowTooltip, ref } = useShowTooltip();

  return (
    <Tooltip title={isShowTooltip ? props?.children : ''}>
      <Typography noWrap ref={ref} {...props}>
        {props?.children}
      </Typography>
    </Tooltip>
  );
};

export default AutoTooltip;
