import { IAttributeItem } from '../../models/attribute.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
};

type TypeResponse = TypeResponsePageable & { content: IAttributeItem[] };

export const getAttributesByRemoteName: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/admin/attributes/${params.remoteName}/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
