import { Button } from '@mui/material';
import { uniqueId } from 'lodash';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  EAttributeUnit,
  IAttributeItem,
} from '../../../../../../../../../../../../../../../../api/models/attribute.model';
import { TAttributeWithValue } from '../../../../../../../../../../../../../../../stores';

import { NewParam } from './components';

interface IProps {
  newAttributesMap: Map<string, IAttributeItem>;
  setNewAttributeValue: (id: string, newAttribute: IAttributeItem) => void;
  removeNewAttribute: (id: string) => void;
  changeAttribute: (id: string, newAttribute: IAttributeItem) => void;
  saveAttribute: (id: string) => Promise<any>;
  isIndexDictionary?: boolean;
}

const AddParam: FC<IProps> = ({
  newAttributesMap,
  setNewAttributeValue,
  removeNewAttribute,
  changeAttribute,
  saveAttribute,
  isIndexDictionary,
}) => {
  const { itemId } = useParams<{ name: string; itemId: string }>();

  const defaultNewParamItem = useMemo<TAttributeWithValue>(
    () => ({
      code: '',
      name: '',
      units: EAttributeUnit.String,
    }),
    [itemId]
  );

  const removeNewParam = (removeId: string) => {
    removeNewAttribute(removeId);
  };

  const addAnotherParam = () => {
    setNewAttributeValue(uniqueId(), defaultNewParamItem);
  };

  return (
    <div>
      {Array.from(newAttributesMap.entries())?.map(([clientId, paramItem]) => (
        <NewParam
          paramData={paramItem}
          removeParamHandler={removeNewParam}
          clientId={clientId}
          changeAttribute={changeAttribute}
          saveAttribute={saveAttribute}
          key={clientId}
          isDisableAddToParent={isIndexDictionary}
        />
      ))}
      <Button size="small" onClick={addAnotherParam} sx={{ fontSize: 16 }}>
        <span>+</span> Добавить тип параметра
      </Button>
    </div>
  );
};

export default AddParam;
