import { FC, useMemo } from 'react';
import { AutocompleteRenderOptionState, Box } from '@mui/material';
import { isNil } from 'lodash';
import { useNotificator } from '@farmlink/farmik-ui';

import { IChecklistAttributeODZ } from '../../../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { getNotificatorProps } from '../../../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../../../shared/constanst/notifications';

import Styled from './styles';

const CopyODZItem: FC<{
  ODZ: IChecklistAttributeODZ;
  onSelect: (value: IChecklistAttributeODZ) => void;
  state: AutocompleteRenderOptionState;
}> = ({ onSelect, ODZ, state }) => {
  const notificator = useNotificator();

  const isRedCriteria = !isNil(ODZ?.validationRedCriteria);
  const isYellowCriteria = !isNil(ODZ?.validationYellowCriteria);
  const isGreenCriteria = !isNil(ODZ?.validationGreenCriteria);

  return (
    <Styled.ODZItem
      onClick={() => {
        onSelect(ODZ);
        notificator.setNotification(
          getNotificatorProps(
            `ОДЗ успешно скопировано. Включен флаг использования ОДЗ. 
            Не забудьте сохранить.`,
            ENotificationStyles.Success
          )
        );
      }}
      display={'flex'}
      flexDirection={'column'}
      gap={'3px'}
    >
      <Styled.Group>
        <Box display={'flex'} flexDirection={'row'}>
          {isRedCriteria && <Styled.Dot type="red" />}
          {isYellowCriteria && <Styled.Dot type="yellow" />}
          {isGreenCriteria && <Styled.Dot type="green" />}
        </Box>

        <Styled.Label>СТО</Styled.Label>
        <Styled.Value>{ODZ?.sto?.name ?? '-'}</Styled.Value>
      </Styled.Group>
      <Styled.Group>
        <Styled.Label>Организация</Styled.Label>
        <Styled.Value>{ODZ?.sto?.organization?.name ?? '-'}</Styled.Value>
      </Styled.Group>
      <Styled.Group>
        <Styled.Label>Культура</Styled.Label>
        <Styled.Value>{ODZ?.sto?.culture?.name ?? '-'}</Styled.Value>
      </Styled.Group>
      <Styled.Group>
        <Styled.Label>ID атрибута</Styled.Label>
        <Styled.Value>{ODZ.stoChecklistAttributeId ?? '-'}</Styled.Value>
      </Styled.Group>
    </Styled.ODZItem>
  );
};

export default CopyODZItem;
