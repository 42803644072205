import moment from 'moment';

import { TypeNews } from '../../../../../api/models/news.model';

export const getRows = (news: TypeNews[]) =>
  news.map(item => ({
    id: item.newsId,
    status: item.status.statusName,
    title: item.newsTitle,
    author: `${item.author?.firstName || ''} ${item.author?.lastName || ''} `,
    tags: item.newsTags?.map(i => i.tagName).join(', ') || '',
    views: item.views,
    publicationDate: moment(item.publicationDate).format('DD.MM.yyyy HH:mm'),
    pinned: item.pinned,
  }));
