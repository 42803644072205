import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ErrorMessageMultiplePushForm } from './components';

const ERROR_MESSAGE_MULTIPLE_PUSH_ID = 'errorMessageMultiplePush';

const errorMessageMultiplePush: TModalConfig = {
  id: ERROR_MESSAGE_MULTIPLE_PUSH_ID,
  name: ERROR_MESSAGE_MULTIPLE_PUSH_ID,
  title: '',
  type: EModalType.Custom,
  children: ErrorMessageMultiplePushForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { errorMessageMultiplePush, ERROR_MESSAGE_MULTIPLE_PUSH_ID };
