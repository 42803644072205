import { useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { useStore } from '../../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../../mobx';
import { EOrgIntegraFieldDataRefresh } from '../../../models';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';

const LOAD_TYPE: ISelectOption[] = [
  { label: 'Полное', value: 'FULL' },
  { label: 'Частичное', value: 'MIX' },
];

const useModalForm = organizationId => {
  const [value, setValue] = useState<ISelectOption>(null);

  const service = useStore(OrgIntegraAgrosignalService);

  const { setNotification } = useNotificator();

  const handleRequest = () => {
    return service
      .refreshAgrosignalFields({
        organizationIds: [organizationId],
        dataRefresh: value.value as EOrgIntegraFieldDataRefresh,
      })
      .then(data => {
        setNotification(
          getNotificatorProps('Запрос синхронизации успешно отправлен', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(error => {
        setNotification(
          getNotificatorProps('Ошибка отправки запроса синхронизации', ENotificationStyles.Error)
        );

        return error;
      });
  };

  return { optionList: LOAD_TYPE, value, setValue, handleRequest };
};

export { useModalForm };
