import { GridColDef } from '@mui/x-data-grid';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { TooltipedValue } from '../../../../shared/components';

export type TGetChecklistAttributesListingGridColumns = () => GridColDef[];

export const getChecklistAttributesListingGridColumns: TGetChecklistAttributesListingGridColumns = () => [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    minWidth: 300,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Наименование',
    flex: 1,
    minWidth: 200,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'type',
    headerName: 'Тип',
    minWidth: 120,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'description',
    headerName: 'Описание',
    flex: 1,
    minWidth: 80,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'isActive',
    headerName: 'Статус',
    width: 80,
    sortable: false,
    renderCell: params => {
      if (params.value) {
        return <CheckRoundedIcon />;
      } else {
        return <CloseRoundedIcon />;
      }
    },
  },
  {
    field: 'isRequired',
    headerName: 'Обязательность',
    width: 80,
    sortable: false,
    renderCell: params => {
      if (params.value) {
        return <CheckRoundedIcon />;
      } else {
        return <CloseRoundedIcon />;
      }
    },
  },
  {
    field: 'isMultiselect',
    headerName: 'Мультивыбор',
    width: 80,
    sortable: false,
    renderCell: params => {
      if (params.value) {
        return <CheckRoundedIcon />;
      } else {
        return <CloseRoundedIcon />;
      }
    },
  },
];
