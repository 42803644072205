import { isArray, isEmpty } from 'lodash';
import { runInAction } from 'mobx';
import moment from 'moment';
import { MutableRefObject } from 'react';

import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import {
  IOrganizationIntegraTableFilter,
  IOrganizationIntegraTableFilterItemValue,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../types/organizationIntegraTable.types';
import OrgIntegraTableFilterService from '../services/orgIntegraTableFilter.service';
import OrgIntegraTableFilterStore from '../stores/orgIntegraTableFilter.store';

@provide.transient()
class OrgIntegraTableFilterController {
  @lazyInject(OrgIntegraTableFilterStore)
  protected store: OrgIntegraTableFilterStore;

  @lazyInject(OrgIntegraTableFilterService)
  protected service: OrgIntegraTableFilterService;

  openFilter = () => {
    this.store.isOpen = true;
  };

  closeFilter = () => {
    this.store.isOpen = false;
  };

  initiateFilter = async (
    filter: IOrganizationIntegraTableFilter,
    queryFilters?: Record<string, unknown>,
    filterConfigRef?: MutableRefObject<Map<string, TOrganizationIntegraTableFilterItemUnion>>
  ) => {
    this.store.isInitializing = true;

    this.store.rawFilterConfig = filter;

    await this.service.initiateFilter(filter, queryFilters).then(() => {
      runInAction(() => {
        if (!isEmpty(queryFilters)) {
          this.applyFilters();
        }
      });

      if (filterConfigRef) {
        filterConfigRef.current = this.store.propertyNameToConfig;
      }
    });

    this.store.isInitializing = false;
    this.store.isInitialized = true;
  };

  applyFilters = () => {
    return runInAction(() => {
      const appliedFilters = new Map<string, IOrganizationIntegraTableFilterItemValue>();

      this.store.buildedComponentsValues.forEach((value, key) => {
        const scheme = this.store.getConfigByPropertyName(key);

        switch (scheme.type) {
          case 'date':
            if (scheme.format) {
              appliedFilters.set(
                scheme.propertyName,
                this.prepareValueToSet({
                  ...value,
                  value:
                    scheme?.format === 'ISO'
                      ? moment(value.value).toISOString(true)
                      : moment(value.value).format(scheme.format),
                })
              );
            } else {
              appliedFilters.set(
                scheme.propertyName,
                this.prepareValueToSet({
                  ...value,
                  value: moment(value.value).format('YYYY-MM-DD'),
                })
              );
            }
            break;

          default:
            appliedFilters.set(scheme.propertyName, this.prepareValueToSet(value));
            break;
        }
      });

      this.store.setAppliedFilters(appliedFilters);

      return appliedFilters;
    });
  };

  private prepareValueToSet = (value: IOrganizationIntegraTableFilterItemValue) => {
    const scheme = this.store.getConfigByPropertyName(value.propertyName);

    if (scheme.isMultiple && !isArray(value.value)) {
      return {
        ...value,
        value: [value.value],
      };
    }

    return value;
  };

  resetFilters = () => {
    this.clearAppliedFilters();
    this.store.clearFilterValues();
  };

  clearAppliedFilters = () => {
    this.store.clearAppliedFilters();
  };

  clearOnUnmount = () => {
    this.store.clearFilterStore();
  };
}

export default OrgIntegraTableFilterController;
