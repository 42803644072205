import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { useStore } from '../../../../../shared/utils/IoC';
import { AddQuotesStore, TypeQuoteSimple } from '../../../../../stores/quotes/add.quotes.store';
import { AdminRoutes } from '../../../../routes';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../../../../../shared/styles/dotsLoader';

import { NewQuoteBlock } from './components/NewQuoteBlock/NewQuoteBlock';
import { ActionsBlock, AddNewQuoteButton, QuotesAddWrapper } from './QuotesAdd.style';

export const QuotesAdd: FC = observer(() => {
  const addQuotesStore = useStore(AddQuotesStore);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      addQuotesStore.setIsLoading(true);
      await Promise.all([
        addQuotesStore.fetchBasis(),
        addQuotesStore.fetchCultureList(),
        addQuotesStore.fetchCultureClassList(),
        addQuotesStore.fetchEconomicRegionsList(),
        addQuotesStore.fetchProvidersList(),
        addQuotesStore.fetchCurrencyList(),
        addQuotesStore.fetchUnitsList(),
      ]);
      addQuotesStore.setIsLoading(false);
    })();

    if (history.location.pathname === AdminRoutes.QuotesAdd) {
      addQuotesStore.addQuote({
        cultureId: '',
        cultureClassId: '',
        basisId: '',
        economicRegionId: '',
        providerId: '',
        price: 0,
        currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
        dateOfObs: undefined,
        qualityMax: 0,
        qualityMin: 0,
        unitsId: '',
        vat: true,
      });
    }

    return () => {
      addQuotesStore.clearStore();
    };
  }, []);

  const onAddNewQuote = useCallback(() => {
    addQuotesStore.addQuote({
      cultureId: '',
      cultureClassId: '',
      basisId: '',
      economicRegionId: '',
      providerId: '',
      price: 0,
      currencyId: '97d96670-6d3a-11eb-8d42-dd19d7aaf478',
      dateOfObs: undefined,
      qualityMax: 0,
      qualityMin: 0,
      unitsId: '',
      vat: true,
    });
  }, []);

  const isPublishButtonDisabled = useMemo(() => {
    const result = addQuotesStore.addingQuotes.some(
      ({
        cultureId,
        basisId,
        economicRegionId,
        providerId,
        currencyId,
        dateOfObs,
        unitsId,
        price,
      }) =>
        !(
          cultureId &&
          basisId &&
          economicRegionId &&
          providerId &&
          currencyId &&
          dateOfObs &&
          unitsId &&
          price
        )
    );
    return result;
  }, [addQuotesStore.addingQuotes]);

  const allowDeleteAlways = useMemo(
    () => history.location.pathname === AdminRoutes.QuotesAddByImport,
    [history.location.pathname]
  );

  const onCancel = useCallback(() => history.push(AdminRoutes.Quotes), []);

  const onPublish = useCallback(async () => {
    try {
      await addQuotesStore.onPublishQuotes();
      history.push(AdminRoutes.Quotes);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return addQuotesStore.isLoading ? (
    <SpinnerLoaderWrapper>
      <SpinnerLoader data-test-id="dots-loader">
        <span />
      </SpinnerLoader>
    </SpinnerLoaderWrapper>
  ) : (
    <QuotesAddWrapper>
      {addQuotesStore.addingQuotes.map((quote: TypeQuoteSimple, index) => (
        <NewQuoteBlock
          quote={quote}
          index={index}
          allowDeleteAlways={allowDeleteAlways}
          key={index}
        />
      ))}
      <AddNewQuoteButton onClick={onAddNewQuote}>+ Добавить котировку</AddNewQuoteButton>
      <ActionsBlock>
        <Button
          color={'default'}
          type={'button'}
          styleType={'outlined'}
          alignment={'center'}
          onClick={onCancel}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          styleType={'outlined'}
          alignment={'center'}
          onClick={onPublish}
          disabled={isPublishButtonDisabled}
        >
          Опубликовать
        </Button>
      </ActionsBlock>
    </QuotesAddWrapper>
  );
});
