export type TypeCheckList = {
  id: string;
  name: string;
  timestamp: string;
  status: string;
  error: string;
};

export enum EFormFields {
  name = 'name',
  id = 'id',
  timestamp = 'timestamp',
  error = 'error',
  status = 'status',
}

export type TypeCheckListStatus = {
  statusId: ECheckListStatus;
  statusName: string;
};

export enum ECheckListStatus {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export const initialCheckListStatus: TypeCheckListStatus[] = [
  {
    statusId: ECheckListStatus.NEW,
    statusName: 'Новая',
  },
  {
    statusId: ECheckListStatus.COMPLETED,
    statusName: 'Выполнено',
  },
  {
    statusId: ECheckListStatus.ERROR,
    statusName: 'Ошибка',
  },
  {
    statusId: ECheckListStatus.CANCELED,
    statusName: 'Отменено',
  },
];
