import { Colors, ModalFooter, useModal } from '@farmlink/farmik-ui';
import { Autocomplete, Box, Checkbox, LinearProgress, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import Styled from './CropwiseReportModal.styles';

const CropwiseReportForm = () => {
  const [fromDate, setFromDate] = useState<Date>(null);
  const [toDate, setToDate] = useState<Date>(null);
  const [yearList, setYearList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { closeModal, getModalRuntimeHandlers } = useModal();

  const { onSuccess } = getModalRuntimeHandlers();

  const isReportDisabled = !fromDate || !toDate || isLoading || yearList.length === 0;

  const generateReport = () => {
    setIsLoading(true);

    const parsedDateFrom = moment(fromDate).format('YYYY-MM-DD');
    const parsedDateTo = moment(toDate).format('YYYY-MM-DD');

    onSuccess?.({ fromDate: parsedDateFrom, toDate: parsedDateTo, seasons: yearList });
  };

  const years = useMemo<number[]>(() => {
    const currentYear = moment().add(20, 'y').get('year');
    const _years = [];

    for (let i = 0; i < 41; i++) {
      _years.unshift(currentYear - i);
    }

    return _years;
  }, []);

  return (
    <Box>
      <Styled.Title>Выгрузка Cropwise</Styled.Title>
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        width="100%"
        marginBottom={2}
        justifyContent={'space-between'}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Box width={'100%'}>
            <DatePicker
              value={moment(fromDate)}
              onChange={(date: Moment) => setFromDate(date?.toDate())}
              maxDate={toDate ? moment(toDate) : null}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата начала" error={false} fullWidth />
              )}
            />
          </Box>
          <Box width={'100%'}>
            <DatePicker
              value={moment(toDate)}
              onChange={(date: Moment) => setToDate(date?.toDate())}
              minDate={fromDate ? moment(fromDate) : null}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата окончания" error={false} fullWidth />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Autocomplete
        options={years}
        multiple
        fullWidth
        disablePortal
        disableCloseOnSelect
        onOpen={() => {
          setTimeout(() => {
            const optionEl = document.querySelector(
              `#report-cropwise-years-${moment().get('year')}`
            );
            optionEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 1);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} id={`report-cropwise-years-${option}`}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        getOptionLabel={option => String(option)}
        onChange={(e, val) => setYearList(val.map(Number))}
        renderInput={params => (
          <TextField {...params} label="Сезоны" placeholder="Выберите сезоны" />
        )}
      />
      {isLoading ? (
        <>
          <Typography sx={{ fontSize: 12, color: Colors.grey }}>
            Иногда выгрузка отчёта может занять время
          </Typography>
          <LinearProgress />
        </>
      ) : (
        <Box height={22} />
      )}
      <br />

      <ModalFooter
        successButton={{
          title: 'Сформировать отчёт',
          disabled: isReportDisabled,
          handler: generateReport,
        }}
        denyButton={{ title: 'Отменить', handler: closeModal }}
      />
    </Box>
  );
};

export default CropwiseReportForm;
