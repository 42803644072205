import { Fragment, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid, Autocomplete, TextField, CircularProgress } from '@mui/material';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationFieldsController, OrganizationFieldsStore } from '../../mobx';
import { ISelectOption } from '../../../../../../../../types/selectOption';

export const OrganizationFieldsFilter: FC = observer(() => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const store = useStore(OrganizationFieldsStore);

  const controller = useStore(OrganizationFieldsController);

  useEffect(() => {
    controller.fetchSeasons({ organizationId, sort: 'DESC', allowStub: false });
  }, []);

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={3}>
        <Autocomplete
          value={store.seasonsFilterValue}
          options={store.seasonOptions}
          onChange={controller.changeSeasonsFilter}
          renderInput={params => (
            <TextField
              {...params}
              label="Сезон"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {store.isLoadingSeasons ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          loading={store.isLoadingSeasons}
          disableClearable
        />
      </Grid>
    </Grid>
  );
});
