import { IUpdateOrgIntegraAgrosignalConfig } from '../../../../models/integra/config.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = Partial<IUpdateOrgIntegraAgrosignalConfig>;

type TypeResponse = string;

type TypeError = {};

export const updateOrgIntegraAgrosignalConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: IUpdateOrgIntegraAgrosignalConfig) => `/api/integra-as/integra/organization/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
