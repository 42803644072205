import { GridColDef } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { IDeviceModel } from '../../../../../../../../../../api/models/device.model';
import { TooltipedValue } from '../../../../../../../../../shared/components';

const getColumnConfig: (
  removeDeviceHandler: (device: IDeviceModel) => void
) => GridColDef<IDeviceModel>[] = removeDeviceHandler => [
  {
    field: 'name',
    headerName: 'Идентификатор',
    flex: 1,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'label',
    headerName: 'Название',
    flex: 1,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'owner',
    headerName: 'Владелец',
    flex: 1,
    valueGetter: value => value.row.client.name,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'actions',
    headerName: '',
    width: 34,
    renderCell: params => (
      <Tooltip title="Удалить привязку">
        <IconButton size="small" onClick={() => removeDeviceHandler(params.row)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ),
  },
];

export default getColumnConfig;
