import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { ChecklistTable } from '../components';
import { checklistModalConfig } from '../modals';

const OrgIntegraAgrosignalChecklists = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList([checklistModalConfig], 'OrgIntegraAgrosignalChecklists');
  }, []);

  return (
    <Box>
      <ChecklistTable />
    </Box>
  );
};

export default OrgIntegraAgrosignalChecklists;
