import styled from 'styled-components';

import { Colors } from '../../../../../../../../constants/colors';

const ItemDetails = styled.div`
  background-color: ${Colors.white};
  padding: 16px;
  height: calc(100vh - 180px);
  overflow-y: scroll;
`;

const Styled = {
  ItemDetails,
};

export default Styled;
