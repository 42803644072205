import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { useStore } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { AdminRoutes } from '../../../../routes';
import {
  UserEdit,
  User,
  UserOrganizations,
  UserNews,
  UserJournal,
  UserExpertises,
  UserInterests,
} from '../../pages';

const UserContainer: FC = () => {
  const { fetchUser, getPendingContracts } = useStore(UsersController);

  const { userId } = useParams<{ userId?: string }>();

  useEffect(() => {
    fetchUser({ userId });
    getPendingContracts({ userId });
  }, []);

  return (
    <Switch>
      <Route exact path={AdminRoutes.UserEdit} component={UserEdit} />
      <Route exact path={AdminRoutes.UserItem} component={User} />
      <Route exact path={AdminRoutes.UserOrganizations} component={UserOrganizations} />
      <Route exact path={AdminRoutes.UserNews} component={UserNews} />
      <Route exact path={AdminRoutes.UserJournal} component={UserJournal} />
      <Route exact path={AdminRoutes.EditExpertises} component={UserExpertises} />
      <Route exact path={AdminRoutes.EditInterests} component={UserInterests} />
    </Switch>
  );
};

export default observer(UserContainer);
