import { isEmpty } from 'lodash';

import { Gender, EUserAdminGender } from '../../../../../api/models/user.admin.model';

export const getGender = (gender: Gender) => {
  if (isEmpty(gender)) {
    return null;
  }

  return {
    genderId: gender.genderEnum as EUserAdminGender,
    genderName: gender.genderName,
  };
};
