import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils';

import { TDictionaryItem } from './../../../api/models/dictionaries.model';

@provide.singleton()
export class TreeStore {
  private _treeMap: Map<string, TDictionaryItem[]> = new Map();
  private _selectedTreeNode: TDictionaryItem | null = null;
  private _preOpenedTree: string[] = null;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedTreeNode() {
    return this._selectedTreeNode;
  }

  get treeMap(): Map<string, TDictionaryItem[] | null> {
    return this._treeMap;
  }

  getTreeNode = (nodeId: string): TDictionaryItem[] | null => {
    return this._treeMap.get(nodeId) || null;
  };

  get preOpenedTree() {
    return this._preOpenedTree;
  }

  setSelectedTreeNode = (treeNode: TDictionaryItem) => {
    this._selectedTreeNode = treeNode;
  };

  setTreeMap = (treeMap: typeof this._treeMap) => {
    this._treeMap = treeMap;
  };

  setTreeNode = (nodeId: string, nodeData) => {
    this._treeMap.set(nodeId, nodeData);
  };

  setPreOpenedTree = (treeNodeList: string[]) => {
    this._preOpenedTree = treeNodeList;
  };

  clearSelectedTreeNode = () => {
    this._selectedTreeNode = null;
  };

  clearTreeMap = () => {
    this._treeMap = new Map();
  };
}
