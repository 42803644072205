export interface IOrgIntegraAgrosignalErrorMessage {
  id: string;
  entityType: EOrgIntegraAgrosignalErrorMessageEntityType;
  entityId: string;
  operationType: EOrgIntegraAgrosignalErrorMessageOperationType;
  data: Record<string, string>;
  employee: {
    id: string;
    userName: string;
    userId: string;
    agroName: string;
    agroId: number;
    organization: {
      id: string;
      agroName: string;
      host: string;
      agroId: number;
      organizationId: string;
      organizationName: string;
      organizationInn: string;
      isIntegrated: boolean;
    };
    integrated: boolean;
  };
  organization: {
    id: string;
    agroName: string;
    host: string;
    agroId: number;
    organizationId: string;
    organizationName: string;
    organizationInn: string;
    isIntegrated: boolean;
  };
  exceptionText: string;
  createDateTime: string;
  updateDateTime: string;
  countAttempts: number;
}

export interface IOrgIntegraAgrosignalErrorMessageUpdate {
  entityType: EOrgIntegraAgrosignalErrorMessageEntityType;
  entityId: string;
  operationType: EOrgIntegraAgrosignalErrorMessageOperationType;
  data: Record<string, string>;
  employeeId: string;
  organizationId: string;
  exceptionText: string;
  id: string;
  countAttempts: number;
}

export enum EOrgIntegraAgrosignalErrorMessageEntityType {
  ChecklistInstance = 'CHECKLIST_INSTANCE',
  Checklist = 'CHECKLIST',
  Task = 'TASK',
  Field = 'FIELD',
}

export enum EOrgIntegraAgrosignalErrorMessageOperationType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export interface IOrgIntegraAgrosignalErrorFileUpdate {
  id: string;
  checklistInstanceId: string;
  fileId: string;
  urlDownload: string;
  isUpload: boolean;
  isDeleted: boolean;
}
