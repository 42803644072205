import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';

import { useStore, isFormValuesErrors } from '../../../../../../../shared/utils';
import { getPhoneNumberCodeMask } from '../../../../utils';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../../../stores/users/users.store';
import { EUserAdminPhoneStatus } from '../../../../../../../../api/models/user.admin.model';
import { SmsCode } from '../../../../../../../shared/components/SmsCode';
import { TextMaskField } from '../../../../../../../shared/components/TextMaskField';
import { ReactComponent as PhoneIco } from '../../assets/phone.svg';

interface IProps {
  userId: string;
  open: boolean;
  onClose: () => void;
}

enum TypeSubmit {
  checkSMSCode = 'checkSMSCode',
  updatePhoneNumber = 'updatePhoneNumber',
}

enum EFormFields {
  phoneNum = 'phoneNum', // Error in updatePhone method
  phoneNumber = 'phoneNumber',
  smsCode = 'smsCode',
  typeSubmit = 'typeSubmit',
}

interface InitialValues {
  [EFormFields.phoneNumber]: string;
  [EFormFields.smsCode]: string;
  [EFormFields.typeSubmit]: string;
}

export const UserPhoneEditModal: FC<IProps> = observer(({ userId, open, onClose }) => {
  const { confirmPhoneNumber, checkSMSCode, updatePhone } = useStore(UsersController);

  const { user, setUser, operId, setOperId, formValuesErrors, clearFormValuesErrors } = useStore(
    UsersStore
  );
  const [seconds, setSeconds] = useState<number>(60);

  const handleConfirmPhoneNumber = useCallback(
    values => () => {
      confirmPhoneNumber({
        userId,
        phoneNumber: values[EFormFields.phoneNumber],
        countryCode: '+7',
      });
    },
    [userId]
  );

  const handleSubmitForm = (values: InitialValues, action: FormikHelpers<InitialValues>): void => {
    if (values[EFormFields.typeSubmit] === TypeSubmit.checkSMSCode) {
      checkSMSCode({ operId, code: values[EFormFields.smsCode] })
        .then(() => {
          action.setStatus({
            type: 'success',
            messages: `Новый номер ${values[EFormFields.phoneNumber]}`,
          });

          setUser({
            ...user,
            phone: {
              ...user.phone,
              phoneNumberCode: '+7',
              phoneNumber: values[EFormFields.phoneNumber],
            },
          });
        })
        .finally(() => {
          action.setSubmitting(false);
          action.setFieldValue(EFormFields.smsCode, '');
        });
    }

    if (values[EFormFields.typeSubmit] === TypeSubmit.updatePhoneNumber) {
      updatePhone({
        userId,
        phoneNum: values[EFormFields.phoneNumber],
        countryCode: '+7',
        phoneStatus: EUserAdminPhoneStatus.CONFIRMED,
      })
        .then(() => {
          action.setStatus({
            type: 'success',
            messages: `Новый номер ${values[EFormFields.phoneNumber]}`,
          });
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setOperId('');
      setSeconds(60);
      clearFormValuesErrors();
    };
  }, []);

  useEffect(() => {
    if (operId) {
      setSeconds(seconds - 1);
    }
  }, [operId]);

  useEffect(() => {
    if (operId) {
      if (seconds > 0 && seconds < 60) {
        setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
      }
    } else if (seconds !== 60) {
      setSeconds(60);
    }
  }, [seconds]);

  const phoneNumberCodeMask = useMemo(() => getPhoneNumberCodeMask(user.phone), [user]);

  const schema = Yup.object().shape({
    [EFormFields.phoneNumber]: Yup.string()
      .matches(/^[0-9]+$/, {
        message: 'В поле должны быть только цифры',
        excludeEmptyString: true,
      })
      .length(10, ({ length }) => `Длина поля составляет ${length} символов`)
      .required('Поле обязательно для заполнения'),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        validationSchema={schema}
        initialValues={{
          [EFormFields.phoneNumber]: user?.phone?.phoneNumber || '',
          [EFormFields.smsCode]: '',
          [EFormFields.typeSubmit]: '',
        }}
        onSubmit={handleSubmitForm}
      >
        {({ values, touched, errors, status, setFieldValue, submitForm }) => {
          return (
            <>
              {status && status.type === 'success' ? (
                <>
                  <DialogTitle>
                    <PhoneIco />
                    Телефон успешно изменен
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText className="description">
                      Новый номер {values[EFormFields.phoneNumber]}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose}>Хорошо</Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>Изменить номер телефона</DialogTitle>
                  <DialogContent>
                    <DialogContentText className="divider">
                      На указанный Вами номер придет смс-сообщение с кодом подтверждения
                    </DialogContentText>
                    <Form>
                      <Field
                        component={TextField}
                        fullWidth
                        autoFocus
                        margin="dense"
                        name={EFormFields.phoneNumber}
                        value={values[EFormFields.phoneNumber]}
                        placeholder="Введите номер телефона1"
                        label="Номер телефона"
                        error={
                          (touched[EFormFields.phoneNumber] &&
                            Boolean(errors[EFormFields.phoneNumber])) ||
                          isFormValuesErrors(EFormFields.phoneNumber, formValuesErrors) ||
                          isFormValuesErrors(EFormFields.phoneNum, formValuesErrors)
                        }
                        helperText={
                          (touched[EFormFields.phoneNumber] && errors[EFormFields.phoneNumber]) ||
                          (isFormValuesErrors(EFormFields.phoneNumber, formValuesErrors) &&
                            formValuesErrors[EFormFields.phoneNumber]) ||
                          (isFormValuesErrors(EFormFields.phoneNum, formValuesErrors) &&
                            formValuesErrors[EFormFields.phoneNum])
                        }
                        variant="outlined"
                        inputProps={{
                          mask: `${phoneNumberCodeMask} (000) 000-00-00`,
                        }}
                        InputProps={{
                          inputComponent: TextMaskField as any,
                        }}
                      />

                      {operId && (
                        <SmsCode
                          error=""
                          label="Код подтверждения"
                          placeholder="000000"
                          value={values[EFormFields.smsCode]}
                          onChange={(value: string) => setFieldValue(EFormFields.smsCode, value)}
                          onSendCodeAgain={handleConfirmPhoneNumber(values)}
                          seconds={seconds}
                        />
                      )}
                    </Form>
                  </DialogContent>
                  <DialogActions>
                    {operId ? (
                      <Button
                        onClick={() => {
                          setFieldValue(EFormFields.typeSubmit, TypeSubmit.checkSMSCode);
                          submitForm();
                        }}
                      >
                        Продолжить
                      </Button>
                    ) : (
                      <Button onClick={handleConfirmPhoneNumber(values)}>Отправить код</Button>
                    )}

                    <Button
                      onClick={() => {
                        setFieldValue(EFormFields.typeSubmit, TypeSubmit.updatePhoneNumber);
                        submitForm();
                      }}
                    >
                      Изменить без подтверждения
                    </Button>
                    <Button onClick={onClose}>Отмена</Button>
                  </DialogActions>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
});
