import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { ActionList } from '../../../../../../../../../shared/components/ActionsList/ActionList';
import { useStore } from '../../../../../../../../../shared/utils';
import { OrganizationRoleController, OrganizationRoleStore } from '../../../../mobx';
import { TAction } from '../../../../../../../../../../api/models/action.model';

const RoleItemActionList: FC<{ roleId }> = ({ roleId }) => {
  const store = useStore(OrganizationRoleStore);
  const controller = useStore(OrganizationRoleController);

  const { organizationId } = useParams<{ organizationId: string }>();

  useEffect(() => {
    controller.fetchModuleList({ organizationId });
  }, []);

  useEffect(() => {
    controller.fetchRoleById(roleId);
  }, [roleId]);

  return (
    <div>
      {store.isModalListLoading || store.isRoleLoading ? (
        <LinearProgress />
      ) : (
        <ActionList
          actionList={store.moduleList}
          getActionValue={store.roleHasAction}
          options={{
            isDisableUserScoutActions: true,
            itemOptions: {
              entityId: roleId,
              changeHandler: {
                default: {
                  update: (entityId, action, state) =>
                    controller.toggleAction(action as TAction, state, true),
                },
                forScout: {
                  update: (entityId, action, state) =>
                    controller.toggleAction(action as TAction, state, true),
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default observer(RoleItemActionList);
