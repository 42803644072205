import { Button, FormControlLabel, Snackbar, Switch } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC, memo, useCallback, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { DictionariesController } from '../../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../../shared/utils';
import { EFormModule, TCurrentDictionaryExtended } from '../../../../../../../../../stores';
import { AdminRoutes } from '../../../../../../../../routes';
import { SelectNameOrId, FormTabs } from '../DictForm/components';
import Styled from '../DictForm/styled';

enum EFormFields {
  Id = 'id',
  Path = 'path',
  ParentId = 'parentId',
  OriginalLink = 'originalLink',
  Name = 'name',
  Description = 'description',
  EditDate = 'editDate',
  Status = 'status',
  Original = 'original',
  IsChild = 'isChild',
  Code = 'code',
}

const CreateDictElementForm: FC<{
  onSubmit: (any) => void;
  data: TCurrentDictionaryExtended;
  parentId: string;
  isCopyMode?: boolean;
}> = ({ onSubmit, data, parentId: parent, isCopyMode }) => {
  const { changeFormModule, createDictionaryItem } = useStore(DictionariesController);
  const history = useHistory();
  const { name: remoteName, itemId: id } = useParams<{ name: string; itemId?: string }>();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [tempData, setTempData] = useState<Partial<TCurrentDictionaryExtended>>({
    ...(isCopyMode ? data : { parentId: parent, isChild: Boolean(parent) }),
  });

  const schema = yup.object().shape({
    [EFormFields.IsChild]: yup.boolean(),
    [EFormFields.ParentId]: yup
      .string()
      .nullable()
      .when([EFormFields.IsChild], {
        is: true,
        then: yup
          .string()
          .required('Укажите дочерний элемент')
          .typeError('Укажите дочерний элемент'),
      }),
    [EFormFields.Code]: yup.string().required('Укажите код записи'),
    [EFormFields.Name]: yup.string().required('Укажите наименование записи'),
  });

  const onFormChange = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const target = e.target as HTMLInputElement;

      setTempData({
        ...tempData,
        ...{ [target.name]: target.type === 'checkbox' ? target.checked : target.value },
      });
    },
    [tempData]
  );

  const changeTempData = useCallback(
    (name: string, value: string) => {
      setTempData({
        ...tempData,
        ...{ [name]: value },
      });
    },
    [tempData]
  );

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const handleCancel = () => {
    if (parent) {
      changeFormModule(EFormModule.Edit);
    } else {
      history.push(generatePath(AdminRoutes.DictionaryName, { name: remoteName }));
      changeFormModule(null);
    }
  };

  const handleCreateDictItem = fromData => {
    createDictionaryItem(fromData)
      .then(val => {
        history.push(
          generatePath(AdminRoutes.DictionarySelectedItem, {
            name: remoteName,
            itemId: val as string,
          })
        );
      })
      .catch(() => {
        setSnackbarMessage('Ошибка создания записи');
        setShowSnackbar(true);
      });
  };

  return (
    <>
      <Formik
        initialValues={tempData}
        onSubmit={onSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, touched, errors, status, setFieldValue, submitForm }) => (
          <Form onChange={onFormChange}>
            <Styled.ActionsRow>
              <Styled.ActionRowGroup>
                <Button variant="contained" onClick={() => handleCreateDictItem(values)}>
                  Создать
                </Button>
              </Styled.ActionRowGroup>
              <Styled.ActionRowGroup>
                <Button onClick={handleCancel} variant="contained">
                  Отменить
                </Button>
              </Styled.ActionRowGroup>
            </Styled.ActionsRow>
            <Styled.FormRow>
              <Field
                component={() => (
                  <FormControlLabel
                    control={<Switch id="isChild" />}
                    label="Является дочерней от"
                    sx={{ width: '60%' }}
                    checked={tempData?.isChild}
                    name="isChild"
                  />
                )}
                defaultChecked={Boolean(tempData?.parentId)}
                name="isChild"
                type="checkbox"
                fullWidth
              />
              <SelectNameOrId
                name="parentId"
                isDisabled={!tempData?.isChild}
                value={tempData.parentId}
                error={errors[EFormFields.ParentId] && String(errors[EFormFields.ParentId])}
                setFieldValue={setFieldValue}
                changeValueCallback={changeTempData}
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={TextField}
                name={[EFormFields.Code]}
                value={tempData?.code}
                label="Код"
                error={errors[EFormFields.Code] && String(errors[EFormFields.Code])}
                fullWidth
              />
              <Field
                component={TextField}
                name={[EFormFields.Name]}
                value={tempData?.name}
                label="Наименование"
                error={errors[EFormFields.Name] && String(errors[EFormFields.Name])}
                fullWidth
              />
            </Styled.FormRow>
            <Styled.FormRow>
              <Field
                component={TextField}
                name={[EFormFields.Description]}
                value={tempData?.description}
                label="Описание"
                error={errors[EFormFields.Description] && String(errors[EFormFields.Description])}
                fullWidth
                rows={3}
              />
            </Styled.FormRow>
            <FormTabs attrOnly isCreateChild />
          </Form>
        )}
      </Formik>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  );
};

export default memo(CreateDictElementForm);
