import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const ModalTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.generalDark};
  white-space: pre-wrap;
`;

export default ModalTitle;
