import { Box, IconButton, Tooltip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { FC } from 'react';
import { useShowTooltip } from '@farmlink/farmik-ui';
import { Link, generatePath } from 'react-router-dom';

import { AdminRoutes } from '../../../../../../routes';

const OrganizationNameCell: FC<{ value: string; orgId: string }> = ({ value, orgId }) => {
  const { isShowTooltip, ref } = useShowTooltip();

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={1}
      maxWidth={'inherit'}
      width={'100%'}
      position={'relative'}
    >
      <Box ref={ref} overflow={'hidden'} textOverflow={'ellipsis'} paddingRight={'32px'}>
        <Tooltip title={isShowTooltip ? value : ''}>
          <span>{value}</span>
        </Tooltip>
      </Box>

      <Box position={'absolute'} right={'6px'}>
        <Tooltip title="Открыть организацию в отдельном окне">
          <Link
            to={generatePath(AdminRoutes.OrganizationItem, { organizationId: orgId })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton size="small">
              <ReplyIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default OrganizationNameCell;
