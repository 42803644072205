import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toJS } from 'mobx';
import { uniqBy } from 'lodash';
import { useNotificator } from '@farmlink/farmik-ui';

import { EEnumDepsTab } from '../../models';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { EnumDependencyController, EnumDependencyStore } from '../../mobx';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import IEnumDependency, {
  IEnumDependencyLink,
} from '../../../../../../../../../../../../../api/models/enumDependency.model';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';

import { getGridColumConfig } from './conigs';

interface IProps {
  type: EEnumDepsTab;
  currentAttrId: string;
}

const DepsGrid: FC<IProps> = ({ type, currentAttrId }) => {
  const controller = useStore(EnumDependencyController);
  const store = useStore(EnumDependencyStore);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAttr, setSelectedAttr] = useState<ISelectOptionExtended<IEnumDependencyLink>>(
    null
  );

  const { setNotification } = useNotificator();

  const payload =
    type === EEnumDepsTab.Depends
      ? { dependencyAttributeId: currentAttrId }
      : { targetAttributeId: currentAttrId };

  useEffect(() => {
    setIsLoading(true);

    controller.fetchDependencyList(type, payload);

    setIsLoading(false);
  }, [type, selectedAttr]);

  const depList = useMemo(() => toJS(store.getDepCollectionByType(type)), [
    store.depCollection.values(),
    type,
  ]);

  const queryKey: keyof IEnumDependency =
    type === EEnumDepsTab.Depends ? 'targetAttribute' : 'dependencyAttribute';

  const optionList = useMemo<ISelectOptionExtended<IEnumDependencyLink>[]>(
    () =>
      uniqBy(
        depList.map(item => {
          return {
            rawData: item,
            label: item.dependency[queryKey].name,
            value: item.dependency[queryKey].id,
          };
        }),
        'value'
      ),
    [depList, type]
  );

  const gridRows = useMemo(() => {
    if (!selectedAttr) {
      return depList;
    }

    return depList.filter(item => {
      return item.dependency[queryKey].id === (selectedAttr?.value as string) ?? currentAttrId;
    });
  }, [optionList, selectedAttr, type]);

  const handleDeleteDep = (id: string) => {
    return controller
      .deleteEnumDependencyLink(id)
      .then(() => {
        setNotification(getNotificatorProps('Связь успешно удалена', ENotificationStyles.Success));
        controller.fetchDependencyList(type, payload);
      })
      .catch(() =>
        setNotification(getNotificatorProps('Ошибка удаления связи', ENotificationStyles.Error))
      );
  };

  const gridColumnConfig = getGridColumConfig(handleDeleteDep);

  return (
    <Box>
      <Box maxWidth={300}>
        <Autocomplete
          options={optionList}
          value={selectedAttr}
          disablePortal
          fullWidth
          renderInput={params => (
            <TextField
              {...params}
              label="Аттрибут"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={String(option.value)}>
              <Box>
                <Typography>{option.label}</Typography>
              </Box>
            </li>
          )}
          onChange={(e, _value) => {
            if (typeof _value === 'object') {
              setSelectedAttr(_value);
            }
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', height: '446px', marginTop: 2 }}>
        <DataGrid
          rows={gridRows || []}
          columns={gridColumnConfig}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          localeText={{ noRowsLabel: 'Нет результатов' }}
        />
      </Box>
    </Box>
  );
};

export default observer(DepsGrid);
