import { ITreeNode, ITreeNodeExtended } from '../AbstractTreeView.types';

export const useAsyncTreeElement = () => {
  const getAsyncTreeNode = (config: {
    getChildHandler?: (any) => any;
    nodeData: {
      label: ITreeNodeExtended['label'] | string;
      nodeId: ITreeNodeExtended['nodeId'];
      parentNode: ITreeNodeExtended['parentNode'];
      path: ITreeNodeExtended['path'];
      hasChild: ITreeNodeExtended['hasChild'];
      alias: ITreeNodeExtended['alias'];
    };
  }): ITreeNodeExtended => {
    const { label, nodeId, parentNode, path, hasChild, alias } = config.nodeData;

    return {
      label,
      nodeId,
      parentNode,
      getTreeChild: config.getChildHandler,
      path,
      hasChild,
      alias,
    };
  };

  return { getAsyncTreeNode };
};
