import { EFormulaFunctionAggregation } from './operations.types';

export enum EFormulaReturnType {
  Boolean = 'BOOLEAN',
  Double = 'DOUBLE',
  Integer = 'INTEGER',
  String = 'STRING',
  Date = 'DATE',
  Uuid = 'UUID',
  Object = 'OBJECT',
  Void = 'VOID',
}

export enum EFormulaType {
  Attribute = 'ATTRIBUTE',
  Const = 'CONST',
  Function = 'FUNCTION',
  Dependency = 'DEPENDENCY',
  Property = 'PROPERTY',
  'SelfValue' = 'SELF_VALUE',
}

export enum EFormulaDependencyType {
  Attribute = 'ATTRIBUTE',
  CultureTask = 'CULTURE_TASK',
  OperationTypeTask = 'OPERATION_TYPE_TASK',
  PhenophaseTask = 'PHENOPHASE_TASK',
}

export enum EFormulaImplementationType {
  Calculation = 'calculation',
  Visibility = 'visibility',
}

export interface IFormulaType {
  type: EFormulaType;
  returnType: EFormulaReturnType;
  clientId: string;
}

export interface IAttributeFormulaType extends IFormulaType {
  type: EFormulaType.Attribute;
  attrId: string;
}

export interface IConstantFormulaType extends IFormulaType {
  type: EFormulaType.Const;
  constantValue: string;
}

export interface IPropertyFormulaType extends IFormulaType {
  type: EFormulaType.Property;
  attrId: string;
  propertyName: string;
}

export interface IDependencyFormulaType extends IFormulaType {
  type: EFormulaType.Dependency;
  attrId: string;
  dependencyType: EFormulaDependencyType;
}

export interface IFunctionFormulaType extends IFormulaType {
  type: EFormulaType.Function;
  args: TFormulaTypeUnion[];
  functionType;
}

export interface ISelfValueFormulaType extends IFormulaType {
  type: EFormulaType.Function;
  args: TFormulaTypeUnion[];
  propertyName: string;
}

export interface IAggregationFormulaType extends Partial<IFormulaType> {
  clientId: string;
  constantValue?: string;
  attrId?: string;
  propertyName?: string;
  dependencyType?: EFormulaDependencyType;
  args?: IAggregationFormulaType[];
  functionType?: EFormulaFunctionAggregation;
  property?: string;
}

export type TFormulaTypeUnion =
  | IAttributeFormulaType
  | IConstantFormulaType
  | IPropertyFormulaType
  | IDependencyFormulaType
  | IFunctionFormulaType
  | IAggregationFormulaType
  | ISelfValueFormulaType;
