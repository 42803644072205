import styled from 'styled-components';

const SwitchGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 8px;
  padding: 0 20px;
`;

const Styled = {
  SwitchGroup,
};

export default Styled;
