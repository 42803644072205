import { Button, Drawer, List, ListItem, ListItemButton, Toolbar, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import {
  NavLink,
  generatePath,
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { observer } from 'mobx-react';
import { Colors, Scrollbar } from '@farmlink/farmik-ui';

import { OrganizationRoleStore } from '../../mobx';
import { useStore } from '../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../routes';
import { AutoTooltip } from '../../../../../../../shared/components';

const OrganizationRolesSidebar: FC = () => {
  const { organizationId, roleId } = useParams<{ organizationId: string; roleId?: string }>();
  const history = useHistory();
  const location = useLocation();

  const store = useStore(OrganizationRoleStore);
  const isRoleListingPage = Boolean(
    matchPath(location.pathname, { path: AdminRoutes.OrganizationRoleList, exact: true })
  );

  const goBackLink = useMemo(
    () =>
      isRoleListingPage
        ? generatePath(AdminRoutes.OrganizationItem, { organizationId })
        : generatePath(AdminRoutes.OrganizationRoleList, { organizationId }),
    [organizationId, isRoleListingPage]
  );

  const onAddNewRole = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationRoleCreate, { organizationId })),
    [organizationId]
  );

  const onRoleSelect = useCallback(
    (id: string) =>
      history.push(generatePath(AdminRoutes.OrganizationRoleItem, { organizationId, roleId: id })),
    [organizationId]
  );

  return (
    <div>
      <Drawer variant="permanent">
        <Toolbar>
          <NavLink to={goBackLink}>
            <KeyboardArrowLeftIcon /> Вернуться назад
          </NavLink>
        </Toolbar>

        <Scrollbar>
          <List>
            <ListItem>
              <Button fullWidth onClick={onAddNewRole} variant="contained">
                + Добавить роль
              </Button>
            </ListItem>
            {store.roleList?.map(({ role, employeeCount }) => {
              const isSelected = role.id === roleId;

              return (
                <ListItem key={role.id}>
                  <ListItemButton selected={isSelected} onClick={() => onRoleSelect(role.id)}>
                    <AutoTooltip color={isSelected ? Colors.green : Colors.black}>
                      {role.name}
                    </AutoTooltip>
                    &nbsp;
                    <Typography
                      noWrap
                      overflow={'visible'}
                      color={isSelected ? Colors.green : Colors.black}
                    >{`(${employeeCount ?? 0})`}</Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Scrollbar>
      </Drawer>
    </div>
  );
};

export default observer(OrganizationRolesSidebar);
