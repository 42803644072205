import moment from 'moment';
import _ from 'lodash';

import { ReportService } from '../../../../../../../../../../services';
import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import {
  EAgrosignalFilterIsAuto,
  IAgrosignalConfig,
  IAgrosignalHistoryFilter,
} from '../../../models';
import { IReportConfig } from '../../../../../../../../../../../api/models/reportConfig.model';

const HIDED_API_KEY = '****************';

@provide.transient()
class AgrosignalReportController {
  @lazyInject(ReportService)
  reportService: ReportService;

  getReportsConfigByOrgId = async (orgId: string): Promise<IAgrosignalConfig | false> => {
    const response = await this.reportService.getReportsConfigByOrgId({ id: orgId });

    if (!response) {
      return false;
    }

    return this.parseReportConfigToClientConfig(response);
  };

  createReportConfig = async (organizationId: string, config: IAgrosignalConfig) => {
    const response = await this.reportService.createReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<IAgrosignalConfig>,
    defaultConfig: IAgrosignalConfig
  ) => {
    const changes: Partial<IAgrosignalConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    if (_.isEmpty(changes)) {
      return Promise.reject(new Error('Нет изменений'));
    }

    const payload = {
      id: configId,
      ...changes,
      ...(changes?.fromDate && { fromDate: this.parseDateToString(changes.fromDate) }),
      ...(changes?.toDate && { toDate: this.parseDateToString(changes.toDate) }),
    };

    if (payload?.apiKey === HIDED_API_KEY) {
      delete payload.apiKey;
    }

    const response = await this.reportService.updateReportConfig(payload);

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteReportConfig({ id: configId });

    return response;
  };

  triggerDataReport = async (payload: TypeApiRequest<'triggerDataReport'>) => {
    const response = await this.reportService.triggerDataReport(payload);

    return response;
  };

  triggerFilesReport = async (payload: TypeApiRequest<'triggerFilesReport'>) => {
    const response = await this.reportService.triggerFilesReport(payload);

    return response;
  };

  downloadAgrosignalReport = async (payload: TypeApiRequest<'downloadAgrosignalReport'>) => {
    const response = await this.reportService.downloadAgrosignalReport(payload);

    return response;
  };

  getReportImportOperationList = async (
    filterData: IAgrosignalHistoryFilter,
    page: number,
    size: number,
    clientId: number
  ) => {
    const parsedData = {
      ...filterData,
      ...(filterData?.fromDate && {
        fromDate: this.parseDateToString(moment(filterData.fromDate).toDate()),
      }),
      ...(filterData?.toDate && {
        toDate: this.parseDateToString(moment(filterData.toDate).toDate()),
      }),
      clientId,
      page,
      size,
    };

    // @ts-ignore
    if (filterData?.type && filterData?.type === 'Не выбрано') {
      delete parsedData.type;
    }

    switch (filterData?.isAuto) {
      case EAgrosignalFilterIsAuto.Auto:
        parsedData.isAuto = true;

        break;

      case EAgrosignalFilterIsAuto.NotAuto:
        parsedData.isAuto = false;
        break;

      default:
        delete parsedData.isAuto;
        break;
    }
    // @ts-ignore
    const payload: TypeApiRequest<'getReportImportOperationList'> = {
      ...parsedData,
    };

    const response = await this.reportService.getReportImportOperationList(payload);

    return response;
  };

  private parseReportConfigToClientConfig = (config: IReportConfig): IAgrosignalConfig => {
    const outputData: IAgrosignalConfig = {
      ...config,
      apiKey: HIDED_API_KEY,
      ...(config.fromDate ? { fromDate: moment(config.fromDate).toDate() } : { fromDate: null }),
      ...(config.toDate ? { toDate: moment(config.toDate).toDate() } : { toDate: null }),
    };

    return outputData;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: IAgrosignalConfig
  ): TypeApiRequest<'createReportConfig'> => {
    const output: TypeApiRequest<'createReportConfig'> = {
      organizationId,
      ...config,
      ...(config?.fromDate && { fromDate: this.parseDateToString(config.fromDate) }),
      ...(config?.toDate && { toDate: this.parseDateToString(config.toDate) }),
    };

    return output;
  };

  private parseDateToString = (date: Date): string => moment(date).format('YYYY-MM-DD');
}

export default AgrosignalReportController;
