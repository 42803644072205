import { TAction } from '../../../../../../../../api/models/action.model';
import { OrganizationsController } from '../../../../../../../controllers/organizations.controller';
import { AccessService } from '../../../../../../../services/access/access.service';
import { TypeApiRequest, lazyInject, provide } from '../../../../../../../shared/utils';
import { OrganizationRoleStore } from '../stores';

@provide.singleton()
class OrganizationRoleController {
  @lazyInject(OrganizationRoleStore)
  store: OrganizationRoleStore;

  @lazyInject(AccessService)
  accessService: AccessService;

  @lazyInject(OrganizationsController)
  organizationsController: OrganizationsController;

  fetchRoleList = async (organizationId: string, isSilent?: boolean) => {
    if (!isSilent) {
      this.store.setIsRoleListLoading(true);
    }

    try {
      const roleList = await this.accessService.fetchAccessRoleList({ organizationId });

      const map = new Map();

      roleList.forEach(role => {
        map.set(role.role.id, role);
      });

      this.store.setRoleItemByRoleId(map);

      return roleList;
    } catch (error) {
      console.log('fetch role list error');
    } finally {
      if (!isSilent) {
        this.store.setIsRoleListLoading(false);
      }
    }
  };

  createRole = async (payload: TypeApiRequest<'createAccessRole'>) => {
    const newRole = await this.accessService.createAccessRole(payload);

    return newRole;
  };

  updateRole = async (payload: TypeApiRequest<'updateAccessRole'>) => {
    const updatedRole = await this.accessService.updateAccessRole({
      ...payload,
      actionCodes: Array.from(this.store.currentRoleActionMap.values())?.flatMap(
        action => action.code
      ),
    });

    return updatedRole;
  };

  deleteRole = async (payload: TypeApiRequest<'deleteAccessRole'>) => {
    const response = await this.accessService.deleteAccessRole(payload);

    return response;
  };

  fetchModuleList = async (payload: TypeApiRequest<'getAccessModuleAll'>) => {
    try {
      this.store.setIsModuleListLoading(true);

      const response = await this.accessService.fetchAccessModuleList(payload);

      this.store.setModuleList(response);

      return response;
    } catch (error) {
      console.log('fetch modules list error', error);
    } finally {
      this.store.setIsModuleListLoading(false);
    }
  };

  fetchRoleById = async (roleId: string) => {
    try {
      this.store.setIsRoleLoading(true);

      const role = await this.accessService.fetchAccessRoleById({ id: roleId, withActions: true });

      this.store.setCurrentRoleData(role);

      const map = new Map();

      role?.actions?.forEach(action => {
        map.set(action.code, action);
      });

      this.store.setCurrentRoleActionMap(map);

      return role;
    } catch (error) {
      console.log('fetch role error', error);
    } finally {
      this.store.setIsRoleLoading(false);
    }
  };

  toggleAction = (action: TAction, state: boolean, isPromisified?: boolean) => {
    if (state) {
      this.store.addCurrentRoleAction(action.code, action);
    } else {
      this.store.removeCurrentRoleAction(action.code);
    }

    if (isPromisified) {
      return Promise.resolve(true);
    }
  };

  fetchRoleUserList = async (
    organizationId: string,
    roleId: string,
    page?: number,
    size?: number
  ) => {
    this.store.setIsUserListLoading(true);

    try {
      const userList = await this.organizationsController.fetchUserOrganizationList({
        organizationID: organizationId,
        roleId,
        page,
        size,
      });

      this.store.updateUserListGridParams({
        rowCount: userList.totalElements,
      });

      this.store.setCurrentRoleUserList(userList.content);

      return userList;
    } catch (error) {
      console.log('fetch user list error', error);
    } finally {
      this.store.setIsUserListLoading(false);
    }
  };

  assignUserToRole = (payload: TypeApiRequest<'assignUserToRole'>) => {
    return this.accessService.assignUserToRole(payload);
  };

  assignScoutToRole = (payload: TypeApiRequest<'assignScoutToRole'>) => {
    return this.accessService.assignScoutToRole(payload);
  };
}

export default OrganizationRoleController;
