import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EmployeeModalForm } from './components';

const ORG_INTEGRA_EMPLOYEE_CROPWISE_MODAL_ID = 'org-integra-employee-cropwise-modal-id';

const employeeModalConfig: TModalConfig = {
  id: ORG_INTEGRA_EMPLOYEE_CROPWISE_MODAL_ID,
  name: ORG_INTEGRA_EMPLOYEE_CROPWISE_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: EmployeeModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { ORG_INTEGRA_EMPLOYEE_CROPWISE_MODAL_ID, employeeModalConfig };
