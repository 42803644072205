export enum EStatus {
  PUBLIC = 'PUBLIC',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  LITE_DRAFT = 'LITE_DRAFT',
}

export type TypeStatus = { statusId: EStatus; statusName: string };

export const initialStatus: TypeStatus[] = [
  {
    statusId: EStatus.DRAFT,
    statusName: 'Черновик',
  },
  {
    statusId: EStatus.PUBLIC,
    statusName: 'Публикация',
  },
  {
    statusId: EStatus.ARCHIVED,
    statusName: 'Архивная',
  },
  {
    statusId: EStatus.LITE_DRAFT,
    statusName: 'Пустой черновик',
  },
];
