import { Box, FormControlLabel, TextField as MTextField, Switch } from '@mui/material';
import { ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { IOrgIntegraAgrosignalErrorFile } from '../../../../models/orgIntegraAgrosignalErrorFile';
import {
  getFormikDirtyValues,
  useStore,
} from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { getNotificatorProps } from '../../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../../shared/constanst/notifications';
import { OrgIntegraAgrosignalService } from '../../../../../../mobx';

const ErrorMessageDetailsForm = () => {
  const orgStore = useStore(OrganizationsStore);
  const service = useStore(OrgIntegraAgrosignalService);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const [isProcessing, setIsProcessing] = useState(false);

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    errorMessage: IOrgIntegraAgrosignalErrorFile;
  };
  const handlers = getModalRuntimeHandlers();

  const onSubmit = (data: IOrgIntegraAgrosignalErrorFile) => {
    setIsProcessing(true);

    const changes = getFormikDirtyValues(data, payload.errorMessage);

    return service
      .updateErrorFile({
        id: payload?.errorMessage?.id,
        ...changes,
      })
      .then(resp => {
        setNotification(
          getNotificatorProps('Ошибка успешно обновлена', ENotificationStyles.Success)
        );

        handlers?.onSuccess?.();

        closeModal();

        return resp;
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка обновления', ENotificationStyles.Error));
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const formik = useFormik<IOrgIntegraAgrosignalErrorFile>({
    initialValues: payload?.errorMessage,
    onSubmit,
  });

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Детали</ModalTitle>
        <FormikProvider value={formik}>
          <MTextField
            label="Наименование организации"
            value={orgStore?.organization?.name || ''}
            disabled
          />
          <MTextField
            label="ID Чек-лист точки “Помощник”"
            value={formik?.values?.checklistInstance?.checklistInstanceId || ''}
            disabled
          />
          <MTextField label="ID файла “Помощник”" value={formik?.values?.fileId || ''} disabled />
          <MTextField label="URL" value={formik?.values?.urlDownload || ''} disabled />

          <FormControlLabel
            control={
              <Switch onChange={(event, checked) => formik.setFieldValue('isUpload', checked)} />
            }
            label={'Загружен'}
            checked={Boolean(formik?.values?.isUpload)}
          />
          <FormControlLabel
            control={
              <Switch onChange={(event, checked) => formik.setFieldValue('isDeleted', checked)} />
            }
            label={'Удалён'}
            checked={Boolean(formik?.values?.isDeleted)}
          />

          <MTextField
            label={'Количество попыток'}
            value={formik?.values?.countAttempts}
            type="number"
            onChange={e =>
              formik.setFieldValue(
                'countAttempts',
                e.target.value === '' ? '' : Number(e.target.value)
              )
            }
          />
        </FormikProvider>
      </Box>
      <ModalFooter
        successButton={{
          title: 'Сохранить',
          handler: () => {
            formik.submitForm();
          },
          disabled: isProcessing,
        }}
        denyButton={{
          title: 'Закрыть',
          handler: () => {
            closeModal();
          },
        }}
      />
    </Box>
  );
};

export default observer(ErrorMessageDetailsForm);
