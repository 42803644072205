import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FC, memo, useContext, useState } from 'react';
import { v4 } from 'uuid';
import { Colors } from '@farmlink/farmik-ui';

import { HighlightBox } from '../../../../../../../../../../../shared/components';
import { FormulaContext } from '../../../../../../contexts';

interface IProps {
  value: string;
  onChangeProperty: (value: string) => void;
}

const FormulaBasicAttributeProperty: FC<IProps> = ({ value, onChangeProperty }) => {
  const [uuid] = useState(v4());

  const { attribute, checklistAttribute } = useContext(FormulaContext);

  const uniqueKeys = Object.keys(attribute || checklistAttribute || {});

  return (
    <Box display="flex">
      <HighlightBox marginTop={2} width={300} id={`${uuid}-propValue`}>
        <Autocomplete
          value={value ?? null}
          options={uniqueKeys}
          data-test-id="formula-block-property-selector"
          renderInput={params => (
            <>
              <TextField
                {...params}
                data-test-id="formula-block-property-input"
                label={'Свойство базового аттрибута'}
              />
            </>
          )}
          renderOption={(props, option) => {
            const isNested = option?.includes('|');

            return (
              <li {...props} key={String(option)}>
                <Box>{isNested ? option.split('|').join(' | ') : option}</Box>
              </li>
            );
          }}
          onChange={(e, option) => {
            onChangeProperty(option);
          }}
        />
        <Typography fontSize="12px">
          Если не указан параметр, то используется value в текущем атрибуте
        </Typography>
      </HighlightBox>
      <Typography
        marginTop={2}
        marginLeft={2}
        maxWidth={300}
        fontSize="12px"
        color={Colors.accentOrange}
      >
        Убедитесь, что типы возвращаемого значения и атрибута соответствуют, иначе формула не будет
        рассчитываться
      </Typography>
    </Box>
  );
};

export default memo(FormulaBasicAttributeProperty);
