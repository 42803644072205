import { Autocomplete, Box, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { useModalForm } from '../../hooks';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';

const FieldLoadModalForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const [isProcessing, setIsProcessing] = useState(false);

  const { closeModal, getModalRuntimeHandlers } = useModal();
  const { optionList, value, setValue, handleRequest } = useModalForm(
    orgStore?.organization?.organizationId
  );

  const handlers = getModalRuntimeHandlers();

  const onSuccess = () => {
    setIsProcessing(true);

    handleRequest()
      .then(() => {
        handlers?.onSuccess?.();

        closeModal();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onDeny = () => {
    closeModal();
  };

  return (
    <Box>
      <ModalTitle>Загрузка полей системы "Агросигнал"</ModalTitle>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />
        <Autocomplete
          options={optionList}
          fullWidth
          renderInput={props => (
            <TextField
              {...props}
              label={'Порядок обновления'}
              placeholder={'Выберите порядок обновления'}
            />
          )}
          disableClearable
          disablePortal
          onChange={(e, val) => setValue(val as ISelectOption)}
        />
        <ModalFooter
          successButton={{
            title: 'Сохранить',
            color: 'primary',
            handler: onSuccess,
            disabled: isProcessing || !value,
          }}
          denyButton={{ title: 'Отменить', color: 'default', handler: onDeny }}
        />
      </Box>
    </Box>
  );
};

export default observer(FieldLoadModalForm);
