import {
  Button,
  ButtonGroup,
  ButtonProps,
  ButtonPropsColorOverrides,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { FC, memo, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type TDropdownOption = {
  title: string;
  handler: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
} & MenuItemProps;

type TProps = {
  title: string;
  optionList: TDropdownOption[];
} & ButtonProps;

const DropdownButton: FC<TProps> = ({ title, optionList, ...props }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    callback: TDropdownOption['handler']
  ) => {
    setOpen(false);
    callback();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef}>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          endIcon={<ArrowDropDownIcon />}
          {...props}
        >
          {title}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {optionList.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      disabled={index === 2}
                      onClick={event => handleMenuItemClick(event, index, option.handler)}
                      {...option}
                    >
                      <Typography color={option.color}>{option.title}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownButton.displayName = 'DropdownButton';

export default memo(DropdownButton);
