import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import FormulaPreview from './components/FormulaPreview/FormulaPreview';

const FORMULA_PREVIEW_MODAL_ID = 'formula-preview-modal-id';

const formulaPreviewModalConfig: TModalConfig = {
  id: FORMULA_PREVIEW_MODAL_ID,
  name: FORMULA_PREVIEW_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: EModalSize.Large,
  },
  children: FormulaPreview,
};

export { FORMULA_PREVIEW_MODAL_ID, formulaPreviewModalConfig };
