import { FC } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Moment } from 'moment';
import { Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SearchIcon from '@mui/icons-material/Search';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import { useStore } from '../../../../../../../shared/utils';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { FilterStore } from '../../../../../../../stores/users/filter.store';
import {
  TypeUserAdminRole,
  TypeUserAdminStatus,
  TypeUserAdminGender,
} from '../../../../../../../../api/models/user.admin.model';
import { TextMaskField } from '../../../../../../../shared/components/TextMaskField';

export const UsersFilter: FC = observer(() => {
  const {
    setFilterUserId,
    setFilterFullName,
    setFilterPhoneNum,
    setFilterEmail,
    setFilterRegDateFrom,
    setFilterRegDateTo,
    setFilterRoles,
    setFilterStatuses,
    setFilterGenders,
    setFilterOrgName,
  } = useStore(UsersController);
  const {
    rolesOptions,
    statusesOptions,
    gendersOptions,
    filters: {
      userId,
      phoneNum,
      email,
      fullName,
      regDateFrom,
      regDateTo,
      roles,
      statuses,
      genders,
      organizationName,
    },
  } = useStore(FilterStore);

  const handleRolesChange = (_, value: TypeUserAdminRole[]) => setFilterRoles(value);

  const handleStatusesChange = (_, value: TypeUserAdminStatus) => setFilterStatuses(value);

  const handleGendersChange = (_, value: TypeUserAdminGender[]) => setFilterGenders(value);

  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterUserId(event.target.value);
  };

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFullName(event.target.value);
  };

  const handleOrgNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOrgName(event.target.value);
  };

  const handlePhoneNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterPhoneNum(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterEmail(event.target.value);
  };

  const handleUserOrganizationIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOrganizationId(event.target.value);
  };

  const handleRegDateFromChange = (date: Moment) => {
    setFilterRegDateFrom(date);
  };

  const handleRegDateToChange = (date: Moment) => {
    setFilterRegDateTo(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name="userId"
            variant="outlined"
            placeholder="Введите id пользователя"
            label="Поиск по id пользователя"
            value={userId}
            onChange={handleUserIdChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            name="phoneNum"
            value={phoneNum}
            placeholder="Введите номер телефона"
            label="Поиск по телефону"
            onChange={handlePhoneNumChange}
            InputProps={{
              inputComponent: TextMaskField as any,
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            inputProps={{
              mask: '+7 (000) 000-00-00',
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            name="email"
            value={email}
            placeholder="Введите e-mail пользователя"
            label="Поиск по E-mail"
            onChange={handleEmailChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactMailIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            name="fullName"
            variant="outlined"
            placeholder="Введите ФИО пользователя"
            label="Поиск по ФИО"
            value={fullName}
            onChange={handleFullNameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePicker
            value={regDateFrom}
            inputFormat="DD.MM.yyyy"
            mask="__.__.____"
            onChange={handleRegDateFromChange}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                placeholder="Введите дату регистрации с"
                label="Дате регистрации с"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <DatePicker
            value={regDateTo}
            inputFormat="DD.MM.yyyy"
            mask="__.__.____"
            onChange={handleRegDateToChange}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                placeholder="Введите дату регистрации до"
                label="Дате регистрации до"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            multiple
            limitTags={2}
            disableCloseOnSelect
            options={rolesOptions}
            value={toJS(roles)}
            getOptionLabel={(option: TypeUserAdminRole) => option.roleName}
            isOptionEqualToValue={(option, value) => option.roleId === value.roleId}
            noOptionsText="Ролей не найдено"
            renderInput={params => <TextField {...params} variant="outlined" label="Роль" />}
            onChange={handleRolesChange}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            disableClearable
            options={statusesOptions}
            value={toJS(statuses)}
            getOptionLabel={(option: TypeUserAdminStatus) => option.statusName}
            isOptionEqualToValue={(option, value) => option.statusId === value.statusId}
            noOptionsText="Статусов не найдено"
            renderInput={params => <TextField {...params} variant="outlined" label="Статус" />}
            onChange={handleStatusesChange}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            multiple
            limitTags={2}
            disableCloseOnSelect
            options={gendersOptions}
            value={toJS(genders)}
            getOptionLabel={(option: TypeUserAdminGender) => option.genderName}
            isOptionEqualToValue={(option, value) => option.genderId === value.genderId}
            noOptionsText="Пол не найден"
            renderInput={params => <TextField {...params} variant="outlined" label="Пол" />}
            onChange={handleGendersChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name="organizationName"
            variant="outlined"
            placeholder="Введите название организации"
            label="Поиск по организации"
            value={organizationName}
            onChange={handleOrgNameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});
