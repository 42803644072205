import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useStore } from '../../../../../../../shared/utils';
import { EmployeeController } from '../../../../../../../controllers/employees.controller';
import { FilterStore } from '../../../../../../../stores/employee/filter.store';
import {
  TypeEmployeeStatus,
  EFormFields,
  TypeRoleOption,
} from '../../../../../../../../api/models/employee.model';
import { EmployeeStore } from '../../../../../../../stores/employee/employee.store';

export const EmployeesFilter: FC = observer(() => {
  const { setFilterFullname, setFilterStatus, setFilterRole, fetchEmployeesRoles } = useStore(
    EmployeeController
  );
  const {
    statusOptions,
    rolesOptions,
    filters: { fullname, status, role },
  } = useStore(FilterStore);
  const { loading } = useStore(EmployeeStore);
  const { organizationId } = useParams<{ organizationId?: string }>();

  const handleStatusChange = (_, value: TypeEmployeeStatus) => setFilterStatus(value);

  const handleRoleChange = (_, value: TypeRoleOption) => setFilterRole(value);

  const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFullname(event.target.value);
  };

  useEffect(() => {
    fetchEmployeesRoles({ organizationId });
  }, []);

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={EFormFields.fullname}
          variant="outlined"
          placeholder="Введите ФИО"
          label="Поиск по ФИО"
          value={fullname}
          onChange={handleFullnameChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Autocomplete
          disableClearable
          options={statusOptions}
          value={toJS(status)}
          getOptionLabel={(option: TypeEmployeeStatus) => option.statusName}
          isOptionEqualToValue={(option, value) => option.statusId === value.statusId}
          noOptionsText="Статусов не найдено"
          renderInput={params => <TextField {...params} variant="outlined" label="Статус" />}
          onChange={handleStatusChange}
        />
      </Grid>

      <Grid item xs={3}>
        <Autocomplete
          loading={loading}
          disableClearable
          options={rolesOptions}
          value={toJS(role)}
          getOptionLabel={(option: TypeRoleOption) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="Статусов не найдено"
          renderInput={params => <TextField {...params} variant="outlined" label="Роль" />}
          onChange={handleRoleChange}
        />
      </Grid>
    </Grid>
  );
});
