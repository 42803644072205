enum EErrorCollection {
  WRONG_TYPE = 'WRONG_TYPE',
  WRONG_SIBLINGS_TYPE = 'WRONG_SIBLINGS_TYPE',
  MISSING_TYPE = 'MISSING_TYPE',
  MISSING_RETURN_TYPE = 'MISSING_RETURN_TYPE',
  ATTR_NOT_SELECTED = 'ATTR_NOT_SELECTED',
  VALUE_NOT_SELECTED = 'VALUE_NOT_SELECTED',
  ARGUMENT_NUMBER = 'ARGUMENT_NUMBER',
  ATTR_TYPE_MISMATCH = 'ATTR_TYPE_MISMATCH',
  ATTR_POTENTIALLY_TYPE_MISMATCH = 'ATTR_POTENTIALLY_TYPE_MISMATCH',
  DEP_TYPE_DUPLICATION = 'DEP_TYPE_DUPLICATION',
  FORMULA_FUNCTION_NOT_SELECTED = 'FORMULA_FUNCTION_NOT_SELECTED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

const errorCollection: Record<EErrorCollection, string> = {
  WRONG_TYPE: 'Ошибка типа',
  WRONG_SIBLINGS_TYPE: 'Ошибка сочетания типов аргументов или аргумент не валиден',
  MISSING_TYPE: 'Отсутствует тип',
  MISSING_RETURN_TYPE: 'Отсутствует тип возвращаемого значения',
  ATTR_NOT_SELECTED: 'Аттрибут не указан',
  VALUE_NOT_SELECTED: 'Значение не указано',
  ARGUMENT_NUMBER: 'Ошибка количества аргументов',
  ATTR_TYPE_MISMATCH: 'Ошибка типа аттрибута',
  ATTR_POTENTIALLY_TYPE_MISMATCH:
    'Убедитесь что атрибут возможно перевести к заданному типу возвращаемого значения, иначе формула не будет рассчитываться',
  DEP_TYPE_DUPLICATION: 'Дублирование зависимостей',
  FORMULA_FUNCTION_NOT_SELECTED: 'Функция не выбрана',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
};

export { errorCollection, EErrorCollection };
