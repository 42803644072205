import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Chip, Colors, Icon } from '@farmlink/farmik-ui';
import { has } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { IconButton, Tooltip } from '@mui/material';

import {
  EInvitationStatus,
  EUserPhoneStatus,
  IInvitation,
} from '../../../../../../../../../api/models/invitation.model';
import Styled from '../OrganizationInvitesList.styles';
import { TooltipedValue } from '../../../../../../../modules/checklists/containers/ChecklistContainer/components/Settings/components';
import { STATUS_SCHEME } from '../../../mobx/stores/OrganizationInviteStore/OrganizationInvite.store';

interface IOptions {
  actions: {
    edit: {
      handler: (invite: IInvitation) => void;
    };
    delete: {
      handler: (invite: IInvitation) => void;
    };
  };
}

const getColumns = (options: IOptions): GridColDef<IInvitation>[] => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    renderCell: params => {
      const status = has(STATUS_SCHEME, params?.value)
        ? STATUS_SCHEME[params.value]
        : { text: params?.value ?? 'Другое', color: 'default' };

      return (
        <Chip size="medium" color={status.color}>
          {status.text}
        </Chip>
      );
    },
    width: 130,
    sortable: true,
  },
  {
    field: 'userName',
    type: 'string',
    headerName: 'Имя',
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 200,
    sortable: true,
  },
  {
    field: 'role',
    type: 'string',
    headerName: 'Роль',
    valueGetter: params => params.row?.role?.name ?? 'Не указано',
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
  {
    field: 'phone',
    type: 'string',
    headerName: 'Номер телефона',
    renderCell: params =>
      params?.row?.phone ? (
        <Styled.PhoneCellWrapper>
          {params?.row?.phone?.phoneNumberStatus.statusId === EUserPhoneStatus.CONFIRMED ? (
            <Tooltip title="Номер подтверждён">
              <CheckIcon color="primary" />
            </Tooltip>
          ) : (
            <Tooltip title="Номер не подтверждён">
              <QuestionMarkIcon color="warning" />
            </Tooltip>
          )}
          <Styled.PhoneCell
            href={`tel:${params.row?.phone.phoneNumberCode}${params?.row?.phone?.phoneNumber}`}
          >
            {`${params.row?.phone.phoneNumberCode} ${params?.row?.phone?.phoneNumber}`}
          </Styled.PhoneCell>
        </Styled.PhoneCellWrapper>
      ) : (
        'Не указано'
      ),
    width: 150,
    sortable: false,
  },
  {
    field: 'userEmail',
    type: 'string',
    headerName: 'e-mail',
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    width: 200,
    sortable: false,
  },
  {
    field: 'creationDate',
    type: 'date',
    headerName: 'Дата приглашения',
    valueFormatter: params =>
      params?.value ? moment(params.value).format('DD.MM.YYYY') : 'Не указано',
    width: 150,
    sortable: false,
  },
  {
    field: 'columnActions',
    type: 'string',
    headerName: '',
    renderCell: params =>
      params.row.status === EInvitationStatus.SENT ? (
        <Styled.ActionButtonWrapper>
          <IconButton onClick={() => options.actions.edit.handler(params.row)}>
            <Icon icon="edit" size="medium" />
          </IconButton>
          <IconButton onClick={() => options.actions.delete.handler(params.row)}>
            <Icon icon="bin" size="medium" fill={Colors.red} />
          </IconButton>
        </Styled.ActionButtonWrapper>
      ) : null,
    width: 90,
    sortable: false,
  },
];

export default getColumns;
