import { FC } from 'react';
import { observer } from 'mobx-react';
import { Dialog } from '@mui/material';

import { EmployeesDialogContent } from './EmployeesDialogContent';

type Props = {
  organizationID: string;
  open: boolean;
  onClose: () => void;
  className?: string;
  dataTestId?: string;
};

export const EmployeesDialog: FC<Props> = observer(
  ({ organizationID, open, onClose, dataTestId = '' }) => {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" data-test-id={dataTestId}>
        <EmployeesDialogContent organizationID={organizationID} onClose={onClose} />
      </Dialog>
    );
  }
);
