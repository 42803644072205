import { IRoleEmployeeCount } from '../../models/role.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = IRoleEmployeeCount[];

type TypeError = {};

export const getAccessRoleList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/access/role/list/`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
