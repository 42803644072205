import { TypeApiRoute } from '../../models/type.api.request';
import { ExpertiseType } from '../../models/expertise.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  size: number;
  page: number;
  search: string;
};

type TypeResponse = { content: ExpertiseType[] } & TypeResponsePageable;

type TypeError = {};

export const getExpertises: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/expertise/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
