import { ReactElement } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';

export const getColumns = (
  renderActions: (params: GridRenderCellParams<string>) => ReactElement[]
) => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    width: 150,
    sortable: false,
  },
  {
    field: 'title',
    type: 'string',
    headerName: 'Заголовок',
    width: 300,
    sortable: false,
  },
  {
    field: 'author',
    type: 'string',
    headerName: 'Автор',
    width: 200,
    sortable: false,
  },
  {
    field: 'tags',
    type: 'string',
    headerName: 'Теги',
    width: 200,
    sortable: false,
  },
  {
    field: 'views',
    type: 'number',
    headerName: 'Просмотры',
    width: 120,
    sortable: false,
  },
  {
    field: 'publicationDate',
    type: 'string',
    headerName: 'Дата публикации',
    width: 200,
    sortable: false,
  },
  {
    field: 'pinned',
    type: 'boolean',
    headerName: 'Прикреплена?',
    width: 120,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Действия',
    width: 200,
    sortable: false,
    getActions: renderActions,
  },
];
