import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { unionBy } from 'lodash';

import { useStore } from '../../../../../../../../../../../../shared/utils';
import { ChecklistsAttributeController } from '../../../../../../../../../../../../controllers/checklistAttribute.controller';
import { EnumElementList } from '../../../../../../../../atttibutes/AttributeContainer/components/AttributeBuilderFrom/components';
import { ChecklistCRUDStore } from '../../../../../../../../../../../../stores/checkLists/checklist.CRUD.store';
import { ChecklistAttributesStore } from '../../../../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';
import { IChecklistOrganizationItem } from '../../../../../../../../../../../../../api/models/checklist.model';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { IChecklistAttributeEnumItemExtended } from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { SelectOrgByNameOrInn } from '../../../../../../../../ChecklistImportModalContainer/components';

const UserDictionaryLinkTab = () => {
  const {
    currentChecklistAttributeUserDictionaryLinkValueList,
    setItemToCurrentChecklistAttributeUserDictionaryLinkValueList,
    fetchCurrentChecklistAttributeUserDictionaryLinkValueList,
    currentChecklistAttributeEdit,
  } = useStore(ChecklistsAttributeController);

  const {
    currentChecklistAttributeActiveOrgId,
    setCurrentChecklistAttributeActiveOrg: setCurrentChecklistAttributeActiveOrgId,
  } = useStore(ChecklistAttributesStore);

  const { currentChecklist } = useStore(ChecklistCRUDStore);

  const [optionList, setOptionList] = useState<ISelectOptionExtended<IChecklistOrganizationItem>[]>(
    []
  );
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const isSearchInAllOrgs = currentChecklist?.default || currentChecklist?.nested;

  useEffect(() => {
    if (!isSearchInAllOrgs) {
      setOptionList(
        currentChecklist.organizations.map(item => ({
          label: `${item?.INN ? `${item.INN} / ` : ''}${item?.name}`,
          value: item.organizationId,
        }))
      );
    }

    return () => {
      setOptionList([]);
      setCurrentChecklistAttributeActiveOrgId(null);
    };
  }, []);

  useEffect(() => {
    if (currentChecklistAttributeActiveOrgId?.value) {
      fetchCurrentChecklistAttributeUserDictionaryLinkValueList({
        attributeId: currentChecklistAttributeEdit.attribute.id,
        organizationId: currentChecklistAttributeActiveOrgId?.value as string,
      });
    }
  }, [currentChecklistAttributeEdit.attribute.id, currentChecklistAttributeActiveOrgId?.value]);

  const handleAddEnumListElement = useCallback(
    (itemList: IChecklistAttributeEnumItemExtended[], isReplace?: boolean) => {
      if (isReplace) {
        return setItemToCurrentChecklistAttributeUserDictionaryLinkValueList(
          unionBy(itemList, 'id')
        );
      }

      const dataList = [...currentChecklistAttributeUserDictionaryLinkValueList, ...itemList];

      setItemToCurrentChecklistAttributeUserDictionaryLinkValueList(unionBy(dataList, 'id'));
    },
    [currentChecklistAttributeUserDictionaryLinkValueList]
  );

  const valueList = useMemo(() => {
    return currentChecklistAttributeUserDictionaryLinkValueList.filter(valueItem => {
      return valueItem.organizationId === currentChecklistAttributeActiveOrgId?.value;
    });
  }, [
    currentChecklistAttributeActiveOrgId?.value,
    toJS(currentChecklistAttributeUserDictionaryLinkValueList),
  ]);

  return (
    <div>
      <Typography paddingBottom={1} fontSize={12}>
        Для просмотра значений выберите организацию
      </Typography>
      {isSearchInAllOrgs ? (
        <SelectOrgByNameOrInn
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={value => {
            setSelectedOrganization(value);
            setCurrentChecklistAttributeActiveOrgId(value);
          }}
        />
      ) : (
        <Autocomplete
          options={optionList}
          value={currentChecklistAttributeActiveOrgId?.label as string}
          onChange={(event, value) => {
            if (typeof value === 'object') {
              setCurrentChecklistAttributeActiveOrgId(value);
            }
          }}
          renderInput={params => <TextField {...params} label="Организация" />}
          disablePortal
          disableClearable
        />
      )}
      <EnumElementList
        enumList={valueList}
        setEnumList={handleAddEnumListElement}
        additionalItemData={{ organizationId: currentChecklistAttributeActiveOrgId?.value }}
      />
    </div>
  );
};

export default observer(UserDictionaryLinkTab);
