import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep, isEqual, isNil, uniqueId } from 'lodash';
import { v4 } from 'uuid';
import { Colors } from '@farmlink/farmik-ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  EFormulaDependencyType,
  EFormulaTypes,
  IAggregationFormulaType,
  IFormulaError,
  TFormulaTypeUnion,
} from '../../../../models';
import { EErrorCollection, formulaTypeRequiring } from '../../../../configs';
import { formulaFunctionScheme } from '../../../../helpers';

import {
  FormulaAttributeSelector,
  FormulaBasicAttributeProperty,
  FormulaConstValue,
  FormulaDependencyType,
  FormulaFunctionType,
  FormulaProperty,
  FormulaReturnType,
  FormulaTitle,
  FormulaType,
} from './components';
import Styled from './FormulaBlock.styles';
import { useErrorHandler, useUpdateFormulaData, useValidation } from './hooks';

interface IProps {
  formulaItem?: TFormulaTypeUnion;
  additionalTitle?: string;
  isChild?: boolean;
  parentBlockConfig?: IAggregationFormulaType;
  implementationType?: EFormulaTypes;

  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;

  serializeData?: (node: IAggregationFormulaType) => void;
  removeChildData?: (id: string) => void;
  updateChildState?: (id: string, state: IAggregationFormulaType) => void;
  handleSerializedData?: (data: IAggregationFormulaType) => void;
  handleDeepSerializedData?: (data: IAggregationFormulaType) => void;

  handleUserChange?: (data: IAggregationFormulaType) => void;
}

const FormulaBlock: FC<IProps> = ({
  formulaItem,
  additionalTitle,
  isChild,
  serializeData,
  removeChildData,
  updateChildState,
  parentBlockConfig,
  implementationType,
  handleSerializedData,
  handleDeepSerializedData,
  handleSetError,
  handleRemoveError,
  handleUserChange,
}) => {
  const [isActive, setIsActive] = useState(Boolean(formulaItem));
  const [blockConfig, setBlockConfig] = useState<IAggregationFormulaType>(
    { ...formulaItem, ...(!formulaItem?.clientId && { clientId: v4() }) } ?? null
  );

  const requires = formulaTypeRequiring[blockConfig?.type] || new Set();

  const prevBlockConfigRef = useRef<IAggregationFormulaType>();

  const formulaFunctionConfig = formulaFunctionScheme.get(blockConfig?.functionType);

  const {
    onSelectType,
    onAttributeSelect,
    onSelectReturnType,
    onSelectFunctionType,
    onChangeValueType,
    onChangePropertyName,
    onChangeDependencyType,
    onChangeProperty,
    addArgument,
    removeArgument,
    allowedFormulaFunctionList,
    allowedFormulaReturnType,
    allowedFormulaType,
    allowedFormulaDependency,
    attrTypeFilter,
    attrTypeFilterIdList,
    selectedBlockAttribute,
    isBasicAttributeODZ,
  } = useUpdateFormulaData(
    blockConfig,
    setBlockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    implementationType,
    isNil(isChild) || isChild === false,
    handleUserChange
  );

  useValidation({
    blockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    handleSetError,
    handleRemoveError,
  });

  const { currentError } = useErrorHandler(
    blockConfig?.clientId,
    blockConfig,
    handleSetError,
    handleRemoveError
  );

  useEffect(() => {
    if (!formulaItem) {
      return;
    }

    const _blockConfig = cloneDeep(formulaItem);

    const traverseAggregation = (obj: IAggregationFormulaType) => {
      // Добавление нового поля clientId для нод без id
      if (!obj.clientId) {
        obj.clientId = v4();
      }

      if (obj.args && obj.args.length > 0) {
        for (const arg of obj.args) {
          traverseAggregation(arg); // Рекурсивный вызов для обхода объектов в поле args
        }
      }
    };

    traverseAggregation(_blockConfig);

    setBlockConfig(_blockConfig);
  }, [formulaItem]);

  useEffect(() => {
    serializeData?.(blockConfig);

    if (updateChildState && !isEqual(prevBlockConfigRef.current, blockConfig)) {
      updateChildState(blockConfig.clientId, blockConfig);

      prevBlockConfigRef.current = blockConfig;
    }

    if (!isChild) {
      if (implementationType === EFormulaTypes.Dependency) {
        return handleDeepSerializedData(blockConfig);
      }

      handleSerializedData(blockConfig);
    }
  }, [blockConfig, handleSerializedData, handleDeepSerializedData]);

  const onUpdateChildState = (id: string, updatedChildState) => {
    setBlockConfig(prevState => {
      const newArgs = prevState.args.map(arg => {
        if (arg.clientId === id) {
          return updatedChildState;
        } else {
          return arg;
        }
      });

      return { ...prevState, args: newArgs };
    });
  };

  const createFormula = () => {
    setIsActive(true);
  };

  const uniqueInt = useMemo(() => Number(uniqueId()), []);

  return (
    <Box marginTop={isChild ? 2 : 0} id={blockConfig?.clientId}>
      {isActive ? (
        <>
          <Styled.Accordion $colorModifier={uniqueInt}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
              >
                {blockConfig?.type ? (
                  <FormulaTitle
                    additionalTitle={additionalTitle}
                    formulaType={blockConfig?.type}
                    functionTitle={formulaFunctionConfig?.label}
                    functionType={blockConfig?.functionType}
                    returnType={blockConfig?.returnType}
                  />
                ) : (
                  <Typography color={Colors.pink} data-test-id={`formula-block-no-type`}>
                    Тип не указан
                  </Typography>
                )}
                <Box>
                  {isChild && (
                    <IconButton
                      size="small"
                      onClickCapture={e => {
                        removeChildData(blockConfig.clientId);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      data-test-id={`formula-block-delete-button`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box gap={1} display={'flex'}>
                <FormulaType
                  type={blockConfig?.type}
                  onSelectType={onSelectType}
                  clientId={blockConfig?.clientId}
                  availableTypeList={allowedFormulaType}
                  handleRemoveError={handleRemoveError}
                  handleSetError={handleSetError}
                />
                <FormulaReturnType
                  type={blockConfig?.returnType}
                  onSelectReturnType={onSelectReturnType}
                  allowedOptionList={allowedFormulaReturnType}
                  clientId={blockConfig?.clientId}
                  selectedFunction={blockConfig?.functionType}
                  handleRemoveError={handleRemoveError}
                  handleSetError={handleSetError}
                />
              </Box>

              <Box>
                {requires.has('functionType') && (
                  <FormulaFunctionType
                    functionType={blockConfig?.functionType}
                    onSelectFunction={onSelectFunctionType}
                    allowedOptionList={allowedFormulaFunctionList}
                    handleRemoveError={handleRemoveError}
                    handleSetError={handleSetError}
                  />
                )}

                {requires.has('constantValue') && (
                  <FormulaConstValue
                    value={blockConfig?.constantValue}
                    onChangeConstValue={onChangeValueType}
                    blockConfig={blockConfig}
                    siblingArgumentList={parentBlockConfig?.args}
                    handleRemoveError={handleRemoveError}
                    handleSetError={handleSetError}
                    isBasicAttributeODZ={isBasicAttributeODZ}
                  />
                )}

                {requires.has('dependencyType') && (
                  <FormulaDependencyType
                    dependencyType={blockConfig?.dependencyType}
                    onSelectDependencyType={onChangeDependencyType}
                    availableOptionList={allowedFormulaDependency}
                    handleRemoveError={handleRemoveError}
                    handleSetError={handleSetError}
                  />
                )}

                {requires.has('property') && (
                  <FormulaBasicAttributeProperty
                    value={blockConfig?.propertyName}
                    onChangeProperty={onChangeProperty}
                  />
                )}

                {(requires.has('attrId') ||
                  (requires.has('dependencyType') &&
                    blockConfig?.dependencyType === EFormulaDependencyType.Attribute)) && (
                  <FormulaAttributeSelector
                    value={blockConfig?.attrId}
                    returnType={blockConfig?.returnType}
                    onAttributeSelect={onAttributeSelect}
                    clientId={blockConfig?.clientId}
                    filters={{ type: attrTypeFilter, ignoredIdList: attrTypeFilterIdList }}
                    handleRemoveError={handleRemoveError}
                    handleSetError={handleSetError}
                  />
                )}

                {requires.has('propertyName') && (
                  <FormulaProperty
                    value={blockConfig?.propertyName}
                    attrId={blockConfig?.attrId}
                    onChangeProperty={onChangePropertyName}
                    selectedBlockAttribute={selectedBlockAttribute}
                  />
                )}
              </Box>

              {requires.has('args') && (
                <Styled.ArgumentList
                  marginTop={2}
                  padding={'20px 10px'}
                  borderRadius={1}
                  bgcolor={Colors.secondaryGray}
                  boxShadow={'inset 0px 0px 8px #ebe9f2'}
                  id={`${blockConfig?.clientId}-args`}
                  data-test-id={`formula-block-argument-list`}
                >
                  <Typography paddingLeft={1} fontSize="16px">
                    Аргументы
                  </Typography>

                  {(blockConfig?.args?.length < formulaFunctionConfig?.maxArguments ||
                    !formulaFunctionConfig?.maxArguments) && (
                    <Button
                      onClick={addArgument}
                      disabled={!blockConfig?.functionType}
                      type="button"
                      variant="text"
                    >
                      + Добавить аргумент
                    </Button>
                  )}

                  {blockConfig?.args?.length < formulaFunctionConfig?.minArguments && (
                    <Typography paddingLeft={1} color={'error'} fontSize="12px">
                      Минимальное количество аргументов: {formulaFunctionConfig?.minArguments}
                    </Typography>
                  )}

                  {currentError?.errorType === EErrorCollection.WRONG_SIBLINGS_TYPE && (
                    <Typography paddingLeft={1} color={'error'} fontSize="12px">
                      {currentError.text}
                    </Typography>
                  )}

                  {blockConfig?.args?.map((item, i) => (
                    <FormulaBlock
                      key={item.clientId || i}
                      additionalTitle={`Аргумент ${i + 1}`}
                      formulaItem={item}
                      removeChildData={removeArgument}
                      serializeData={serializeData}
                      updateChildState={onUpdateChildState}
                      parentBlockConfig={blockConfig}
                      implementationType={implementationType}
                      isChild
                      data-test-id={`formula-block-child`}
                      handleSetError={handleSetError}
                      handleRemoveError={handleRemoveError}
                      handleUserChange={handleUserChange}
                    />
                  ))}
                </Styled.ArgumentList>
              )}
            </AccordionDetails>
          </Styled.Accordion>
        </>
      ) : (
        <>
          {implementationType !== EFormulaTypes.Dependency && (
            <Button
              onClick={createFormula}
              type="button"
              variant="text"
              data-test-id={`formula-block-add-formula-button`}
            >
              + Добавить формулу
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(FormulaBlock);
