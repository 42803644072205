import styled from 'styled-components';

const ModuleList = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  gap: 22px;
  padding: 0 20px;
  margin-bottom: 16px;
`;

const Module = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModuleGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Styled = {
  Module,
  ModuleList,
  ModuleGroup,
};

export default Styled;
