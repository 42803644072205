import { useModal } from '@farmlink/farmik-ui';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, MenuItem, Snackbar, Switch, Tab, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { EChecklistTabs, EChecklistType } from '../../../../../../../../api/models/checklist.model';
import { ChecklistCRUDController } from '../../../../../../../controllers/checklist.CRUD.controller';
import { useStore } from '../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../routes';
import Attributes from '../Attributes/Attributes';
import { copyChecklistModal, COPY_CHECKLIST_MODAL } from '../Attributes/modals';
import Organizations from '../Organizations/Organizations';
import Settings from '../Settings/Settings';

import Styled from './styled';

const Checklist = observer(() => {
  const history = useHistory();
  const { registerModalList, openModalByModalId } = useModal();
  const [currentTab, setCurrentTab] = useState(EChecklistTabs.Organizations);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const {
    currentChecklist,
    currentChecklistName,
    currentChecklistPropertySwitches,
    clearCreatingChecklist,
    changeCurrentChecklistBase,
    currentChecklistAvailableTabs,
    createChecklist,
    isEditChecklist,
    changeChecklist,
    changeChecklistActivity,
    deleteChecklist,
    changeCurrentChecklistType,
  } = useStore(ChecklistCRUDController);
  const { default: defaultSwitch, nested } = currentChecklistPropertySwitches;

  const handleCancelChecklistCreation = useCallback(() => {
    history.push(AdminRoutes.Checklists);
  }, []);

  const onChangeTab = useCallback((e, key: EChecklistTabs) => {
    setCurrentTab(key);
  }, []);

  useEffect(() => {
    registerModalList([copyChecklistModal], 'checklistModals');

    return () => {
      clearCreatingChecklist();
    };
  }, []);

  useEffect(() => {
    if (!currentChecklistAvailableTabs.has(currentTab) || !Boolean(currentTab)) {
      setCurrentTab(Array.from(currentChecklistAvailableTabs.values())[0]);
    }
  }, [currentChecklistAvailableTabs, currentTab]);

  const handleCloseSnackbar = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setShowSnackbar(false);
    },
    []
  );

  useEffect(() => {
    if (Boolean(currentChecklist?.id)) {
      setCurrentTab(EChecklistTabs.Attributes);
    }
  }, [currentChecklist?.id]);

  const createChecklistHandler = useCallback(() => {
    try {
      createChecklist()
        .then(id => {
          setSnackbarMessage('Чек-лист успешно создан');
          setShowSnackbar(true);
          history.push(generatePath(AdminRoutes.SelectedChecklist, { checklistId: id }));
        })
        .catch(() => {
          setSnackbarMessage('Ошибка создания чек-листа');
          setShowSnackbar(true);
        });
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessage(error.message);
        setShowSnackbar(true);
      } else {
        setSnackbarMessage('Ошибка создания чек-листа');
        setShowSnackbar(true);
      }
    }
  }, []);

  const saveChecklistHandler = useCallback(() => {
    try {
      changeChecklist()
        .then(() => {
          setSnackbarMessage('Чек-лист успешно изменён');
          setShowSnackbar(true);
        })
        .catch(error => {
          if (error instanceof Error) {
            setSnackbarMessage(error.message);
            setShowSnackbar(true);
          } else {
            setSnackbarMessage('Ошибка изменения чек-листа');
            setShowSnackbar(true);
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessage(error.message);
        setShowSnackbar(true);
      } else {
        setSnackbarMessage('Ошибка создания чек-листа');
        setShowSnackbar(true);
      }
    }
  }, []);

  const changeChecklistActivityHandler = useCallback(() => {
    changeChecklistActivity()
      .then(() => {
        setSnackbarMessage(
          `Чек-лист ${currentChecklist?.active ? 'заблокирован' : 'разблокирован'}`
        );
        setShowSnackbar(true);
      })
      .catch(error => {
        if (error instanceof Error) {
          setSnackbarMessage(error.message);
          setShowSnackbar(true);
        } else {
          setSnackbarMessage('Ошибка изменения чек-листа');
          setShowSnackbar(true);
        }
      });
  }, [currentChecklist?.active]);

  const deleteChecklistHandler = useCallback(() => {
    if (!currentChecklist?.id) {
      return;
    }

    deleteChecklist(currentChecklist.id)
      .then(() => {
        setSnackbarMessage('Чек-лист успешно удалён');
        setShowSnackbar(true);

        history.push(generatePath(AdminRoutes.Checklists));
      })
      .catch(error => {
        if (error instanceof Error) {
          setSnackbarMessage(error.message);
          setShowSnackbar(true);
        } else {
          setSnackbarMessage('Ошибка удаления чек-листа');
          setShowSnackbar(true);
        }
      });
  }, [currentChecklist?.id]);

  return (
    <div>
      <Styled.InputGroup>
        {isEditChecklist ? (
          <Styled.InputGroup>
            <Button onClick={saveChecklistHandler} variant="contained">
              Сохранить
            </Button>
            <Button onClick={() => openModalByModalId(COPY_CHECKLIST_MODAL)} variant="contained">
              Копировать
            </Button>
          </Styled.InputGroup>
        ) : (
          <Button onClick={createChecklistHandler} variant="contained">
            Создать
          </Button>
        )}

        {isEditChecklist ? (
          <Styled.InputGroup>
            <Button
              onClick={changeChecklistActivityHandler}
              variant="contained"
              color={currentChecklist?.active ? 'error' : 'success'}
            >
              {currentChecklist?.active ? 'Блокировать' : 'Разблокировать'}
            </Button>
            <Button onClick={deleteChecklistHandler} variant="contained" color="error">
              Удалить
            </Button>
          </Styled.InputGroup>
        ) : (
          <Button onClick={handleCancelChecklistCreation} variant="contained">
            Отменить
          </Button>
        )}
      </Styled.InputGroup>
      <Styled.InputGroup marginTop={2}>
        <TextField
          label="Название чек-листа"
          value={currentChecklistName || ''}
          name={'name'}
          onChange={changeCurrentChecklistBase}
          required
          fullWidth
        />

        {isEditChecklist && (
          <>
            <TextField
              label="ID"
              value={currentChecklist?.id || ''}
              name={'ID'}
              disabled
              fullWidth
            />
            <TextField
              label="public_id"
              value={currentChecklist?.publicId || '-'}
              name={'public_id'}
              disabled
              fullWidth
            />
          </>
        )}
      </Styled.InputGroup>

      <Styled.InputGroup marginTop={2}>
        <Styled.SelectWrapper>
          <TextField
            name="type"
            value={currentChecklist?.type ?? ''}
            onChange={changeCurrentChecklistType}
            select
            fullWidth
            label="Тип"
            placeholder="Тип"
          >
            <MenuItem value={EChecklistType.Field}>Поле</MenuItem>
            <MenuItem value={EChecklistType.Machinery}>Техника</MenuItem>
            {!defaultSwitch && <MenuItem value={EChecklistType.Nested}>Вложенный</MenuItem>}
            <MenuItem value={EChecklistType.Point}>Точка</MenuItem>
          </TextField>
        </Styled.SelectWrapper>

        <Styled.SwitchLabel>
          <Switch
            checked={defaultSwitch || false}
            name={'default'}
            onChange={changeCurrentChecklistBase}
            disabled={nested}
          />
          Чек-лист дефолтный
        </Styled.SwitchLabel>
      </Styled.InputGroup>

      <TabContext value={currentTab || EChecklistTabs.Settings}>
        <Box>
          <TabList onChange={onChangeTab}>
            {currentChecklistAvailableTabs.has(EChecklistTabs.Organizations) && (
              <Tab label="Организации" value={EChecklistTabs.Organizations} />
            )}
            {currentChecklistAvailableTabs.has(EChecklistTabs.Settings) && (
              <Tab label="Настройки" value={EChecklistTabs.Settings} />
            )}
            {currentChecklistAvailableTabs.has(EChecklistTabs.Attributes) && (
              <Tab label="Атрибуты" value={EChecklistTabs.Attributes} />
            )}
          </TabList>
        </Box>
        <TabPanel value={EChecklistTabs.Organizations}>
          <Organizations />
        </TabPanel>
        <TabPanel value={EChecklistTabs.Settings}>
          <Settings />
        </TabPanel>
        {!isEditChecklist && nested ? null : (
          <TabPanel value={EChecklistTabs.Attributes}>
            <Attributes />
          </TabPanel>
        )}
      </TabContext>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
});

export default Checklist;
