import { NotificatorProps } from '@farmlink/farmik-ui';

import { ENotificationStyles, NOTIFICATION_SCHEME } from '../constanst/notifications';

export const getNotificatorProps = (
  message: string,
  type: ENotificationStyles
): NotificatorProps => {
  const props: NotificatorProps = { message, style: NOTIFICATION_SCHEME[type] };

  return props;
};
