import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../routes';

import { EditQuote } from './pages/EditQuote/EditQuote';
import { QuotesAdd } from './pages/QuotesAdd/QuotesAdd';
import { QuotesList } from './pages/QuotesList/QuotesList';

export const QuotesRouter: FC<any> = () => {
  return (
    <Switch>
      <Route exact path={AdminRoutes.Quotes} component={QuotesList} />
      <Route path={AdminRoutes.QuotesAdd} component={QuotesAdd} />
      <Route path={`${AdminRoutes.EditQuote}/:quoteId`} component={EditQuote} />

      <Redirect to={AdminRoutes.Quotes} />
    </Switch>
  );
};
