import styled from 'styled-components';

type TSidebarTabProps = {
  $display: boolean;
};

const SidebarTab = styled.div<TSidebarTabProps>`
  display: ${({ $display }) => ($display ? 'block' : 'none')};
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Styled = {
  SidebarTab,
  SearchWrapper,
};

export default Styled;
