import { Box } from '@mui/material';
import styled from 'styled-components';

const ReportGroup = styled(Box)`
  padding: 8px;
  display: flex;
  border: 1px solid #e9e6f0;
  border-radius: 4px;
  position: relative;
  align-items: center;
  gap: 8px;
`;

const GroupName = styled.p`
  font-size: 12px;
  position: absolute;
  background-color: #fff;
  top: -8px;
  margin: 0;
`;

const Styled = {
  ReportGroup,
  GroupName,
};

export default Styled;
