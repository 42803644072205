import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = { ids: string[] };

type TypeResponse = string;

type TypeError = {};

export const deleteOrgIntegraAgrosignalErrorMessageList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/messageError/deleteInBatch`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
