import { TextField, OutlinedTextFieldProps, IconButton } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IProps extends OutlinedTextFieldProps {
  value: string | number | null | boolean;
  onChangeHandler: (value: string | number) => void;
}

const DoubleField: FC<IProps> = ({ value, onChangeHandler }) => {
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState(value === null ? '' : value);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetValue = event.target.value;

    if (
      isNull(targetValue) ||
      isEmpty(targetValue) ||
      RegExp(/^(-)?\d+(\,|\.)?(\d+)?$/).test(targetValue) ||
      targetValue === '-'
    ) {
      setIsError(false);
      setInputValue(targetValue);
      onChangeHandler(targetValue);
    } else {
      setIsError(true);
      setTimeout(() => setIsError(false), 1000);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={onChange}
      label="DOUBLE"
      error={isError}
      inputProps={{
        maxLength: 16,
      }}
      InputProps={{
        endAdornment: inputValue ? (
          <IconButton
            size="small"
            onClick={() => {
              setInputValue('');
              onChangeHandler(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : undefined,
      }}
    />
  );
};

export default DoubleField;
