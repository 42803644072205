import { Colors } from '@farmlink/farmik-ui';
import { AccordionProps, Box, Accordion as MAccordion } from '@mui/material';
import styled, { keyframes } from 'styled-components';

interface IAccordionProps extends AccordionProps {
  $colorModifier: number;
}

const blink = keyframes`
   50% { background-color: ${Colors.lightPink} } 
`;

const Accordion = styled(MAccordion)<IAccordionProps>`
  border-left: 3px solid ${({ $colorModifier }) => getColor($colorModifier)};
`;

const ArgumentList = styled(Box)`
  &.highlight {
    animation: ${blink} 1s steps(1) infinite;
  }
`;

const getColor = number => {
  const hue = number * 137.508; // золотой угол
  return `hsl(${hue},50%,75%)`;
};

const Styled = {
  Accordion,
  ArgumentList,
};

export default Styled;
