import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = {};

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const deleteChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/checklist/${params.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
