import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const SwitchLabel = styled.label`
  display: flex;
  flex: 1;
  min-width: 256px;
  max-width: 512px;
  align-items: center;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: ${Colors.red};
  margin: 0;
`;

const SelectInputWrapper = styled.div`
  & .MuiFormControl-root {
    min-width: 300px;
    max-width: 512px;
  }
`;

const Styled = { SwitchLabel, ErrorText, SelectInputWrapper };

export default Styled;
