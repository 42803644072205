import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { TableCell, TableContextMenu } from '../components';

export const getResultColumns = (): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 180,
      renderCell: params => <TableCell value={params.value} isMiddleEllipsis isDisplayTooltip />,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'code',
      headerName: 'Код',
      minWidth: 60,
    },
    {
      field: 'path',
      headerName: 'Path',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Статус',
      width: 100,
    },
    {
      field: 'attrs',
      headerName: 'Доп. атрибуты',
      minWidth: 150,
      maxWidth: 300,
      renderCell: params => {
        if (!params.value) {
          return null;
        }

        return (
          <Tooltip title={JSON?.stringify(params.value, null, 2)?.slice(1, -1)}>
            {<Typography noWrap>{Object.keys(params.value).join(', ')}</Typography>}
          </Tooltip>
        );
      },
    },
    {
      field: 'original',
      headerName: 'Оригинал',
      width: 100,
      renderCell: params => {
        if (params.value) {
          return <CheckRoundedIcon />;
        } else {
          return <CloseRoundedIcon />;
        }
      },
    },
    {
      field: 'editDate',
      headerName: 'Дата обновления',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Действие',
      sortable: false,
      width: 150,
      renderCell: params => <TableContextMenu id={params.row.id} value={params.row} />,
    },
  ];
};
