import { Autocomplete, TextField } from '@mui/material';
import { FC, memo, useEffect } from 'react';

import { EFormulaType, IFormulaError } from '../../../../../../models';
import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { HighlightBox } from '../../../../../../../../../../../shared/components';
import { EErrorCollection, errorCollection } from '../../../../../../configs';

interface IProps {
  type: EFormulaType;
  onSelectType: (EFormulaType) => void;
  clientId: string;
  availableTypeList: ISelectOption[];
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const FormulaType: FC<IProps> = ({
  type,
  onSelectType,
  clientId,
  availableTypeList,
  handleSetError,
  handleRemoveError,
}) => {
  useEffect(() => {
    if (!type) {
      handleSetError?.(
        {
          errorType: EErrorCollection.MISSING_TYPE,
          text: errorCollection.MISSING_TYPE,
          HTMLElementId: `${clientId}-type`,
        },
        `${clientId}-type`
      );
    } else {
      handleRemoveError?.(`${clientId}-type`);
    }
  }, [type]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(`${clientId}-type`);
    };
  }, []);

  return (
    <HighlightBox width={200} id={`${clientId}-type`}>
      <Autocomplete
        disableClearable
        value={type ?? null}
        isOptionEqualToValue={(option, value) => option.value === type}
        options={availableTypeList || []}
        onChange={(e, value) => {
          onSelectType((value as ISelectOption).value);
        }}
        data-test-id={`formula-block-formula-type`}
        renderInput={params => (
          <>
            <TextField
              {...params}
              label={'Тип формулы'}
              data-test-id={`formula-block-formula-type-input`}
            />
          </>
        )}
      />
    </HighlightBox>
  );
};

export default memo(FormulaType);
