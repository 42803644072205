import { EFormulaFunctionAggregation, EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const AND: IFormulaFunction = {
  name: 'AND',
  label: 'Логическое И',
  argsType: [EFormulaType.Function],
  primitiveType: [EFormulaReturnType.Boolean],
  minArguments: 2,
  availableFunctionType: [
    EFormulaFunctionAggregation.EQ,
    EFormulaFunctionAggregation.GT,
    EFormulaFunctionAggregation.LT,
    EFormulaFunctionAggregation.GOE,
    EFormulaFunctionAggregation.LOE,
    EFormulaFunctionAggregation.NE,
    EFormulaFunctionAggregation.NOT,
  ],
  returnType: [EFormulaReturnType.Boolean],
};
