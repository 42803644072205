import { IOrgIntegraCropwiseChecklist } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseChecklists/models/orgIntegraCropwiseChecklist';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<{
  size: number;
  page: number;
  isActive: boolean;
  idIn: string[];
  organizationIdIn: string[];
  cropwiseChecklistIdIn: number[];
  checkListIdIn: string[];
  cultureIdIn: string[];
  createDateTimeTo: string;
  createDateTimeFrom: string;
  updateDateTimeFrom: string;
  updateDateTimeTo: string;
}>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraCropwiseChecklist[] };

type TypeError = {};

export const getOrgIntegraCropwiseChecklistByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/mapping/checkList/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
