import { Grid } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../constants/colors';

export const a = 1;

export const NewQuoteBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & .react-datepicker__input-container input {
    background-color: ${Colors.backgroundSecondaryGray};
    border-color: rgba(0, 0, 0, 0.23);

    ::placeholder {
      color: ${Colors.black};
    }
  }
`;

export const NewQuoteBlockTitle = styled.h3``;

export const StyledGridItem = styled(Grid)`
  flex-grow: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DeleteBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
`;

export const BinIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`;

export const DeleteLabel = styled.div`
  color: ${Colors.red};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
`;
