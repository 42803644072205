import { Button, Input, Password, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { Redirect, useHistory } from 'react-router-dom';
import { useMemo } from 'react';

import { SignInViaEmailStore } from '../../stores/sign-in.via-email.store';
import { useStore } from '../../../../../shared/utils';
import { ApplicationRoutes } from '../../../../../routes';
import { BackButton } from '../BackButton';

import { Wrapper, HeaderWrapper, Heading, FormContent, FieldWrapper, ButtonWrapper } from './style';

type Props = {
  onChangeType: () => void;
};

export const EmailSignIn = observer(({ onChangeType }: Props) => {
  const {
    email,
    isLoggedIn,
    password,
    setFieldValue,
    attemptLogin,
    isFormButtonAvailable,
  } = useStore(SignInViaEmailStore);

  const history = useHistory();
  const setters = useMemo(
    () => ({
      setEmail: setFieldValue('email'),
      setPassword: setFieldValue('password'),
    }),
    []
  );

  const handleSumbit = (e: any) => {
    e.preventDefault();
    attemptLogin();
  };

  const handleBack = () => {
    onChangeType();
  };

  if (isLoggedIn) {
    return <Redirect to={ApplicationRoutes.Admin} />;
  }

  return (
    <Wrapper>
      <BackButton onClick={handleBack} />
      <HeaderWrapper>
        <Heading>Вход по email</Heading>
      </HeaderWrapper>

      <FormContent>
        <form onSubmit={handleSumbit}>
          <FieldWrapper>
            <Input
              value={email.value}
              error={email.error}
              onChange={setters.setEmail}
              placeholder="info@mail.com"
              label="E-mail"
              focus={false}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Password
              label="Пароль"
              placeholder="Пароль"
              value={password.value}
              onChange={setters.setPassword}
              error={password.error}
            />
          </FieldWrapper>

          <ButtonWrapper>
            <Button color="primary" type="submit" disabled={!isFormButtonAvailable}>
              Войти
            </Button>
          </ButtonWrapper>
        </form>
      </FormContent>
    </Wrapper>
  );
});
