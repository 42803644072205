import { FC, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';

import { AdminRoutes } from '../../../../../../../../../../../../../../routes';

import Styled from './styled';

const LinkedDictionaryItem: FC<{
  title: string;
  nodeId: string;
  remoteName: string;
  isHideButton?: boolean;
}> = props => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(
      generatePath(AdminRoutes.DictionarySelectedItem, {
        itemId: props.nodeId,
        name: props.remoteName,
      })
    );
  }, []);

  return (
    <Styled.Row>
      {props.title}
      {!props.isHideButton && (
        <Tooltip title="Перейти к записи">
          <IconButton size="small" onClick={handleClick}>
            <ReplyIcon />
          </IconButton>
        </Tooltip>
      )}
    </Styled.Row>
  );
};

export default LinkedDictionaryItem;
