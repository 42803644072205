import { Paper } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../constants/colors';

const DictionaryNameItem = styled(Paper)`
  padding: 10px 20px;
  transition: background-color 0.2s ease-in-out !important;
  background-color: ${Colors.white};

  &:hover {
    background-color: ${Colors.lightnessGray};
    cursor: pointer;
  }
`;

const DictionaryNameGrid = styled('div')`
  padding: 16px;
`;

const Styled = {
  DictionaryNameItem,
  DictionaryNameGrid,
};

export default Styled;
