import { FC } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useParams } from 'react-router';

import { ReactComponent as MailIco } from '../../assets/mail.svg';
import { useStore } from '../../../../../../../shared/utils';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../../../stores/users/users.store';

interface IProps {
  open: boolean;
  message: string | null;
  code: string | null;
  onClose: () => void;
  handleActionSetAgreement: (flag: boolean) => void;
}

export const ChangeActionModal: FC<IProps> = observer(({ open, onClose, message, code }) => {
  const { updateUser, fetchUser, fetchUserActions } = useStore(UsersController);
  const { clearUserActionList } = useStore(UsersStore);

  const { userId } = useParams<{ userId: string }>();

  const onSuccess = () => {
    updateUser({ userId, [code]: false });
    onClose();

    setTimeout(() => {
      clearUserActionList();
      fetchUser({ userId });
      fetchUserActions(userId);
    }, 500);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <MailIco />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSuccess}>
          Хорошо
        </Button>
        <Button variant="contained" color="error" onClick={onClose}>
          Нет
        </Button>
      </DialogActions>
    </Dialog>
  );
});
