import { memo, useCallback, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { serviceListConfig } from '../../configs';

import Styled from './styled';

const OrganizationReportsServiceList = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const history = useHistory();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  const handleClick = useCallback(
    (path: string) => {
      history.push(generatePath(path, { organizationId }));
    },
    [organizationId]
  );

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1 sx={{ marginLeft: '28px' }}>Отчеты BI. Сервисы</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          <Box display="flex" gap={2} flexWrap="wrap">
            {serviceListConfig.map(({ name, icon: Icon, path, isGroup, childrenList }) => {
              if (isGroup || childrenList?.length > 0) {
                return (
                  <Styled.ReportGroup key={`${name}-group`}>
                    <Styled.GroupName>{name}</Styled.GroupName>

                    {/* @ts-ignore */}
                    {Icon && <Icon />}
                    {childrenList?.map(item => {
                      return (
                        <Button
                          variant="outlined"
                          size="small"
                          key={item?.name}
                          onClick={() => handleClick(item?.path)}
                          // @ts-ignore
                          {...(item?.icon && { startIcon: <item.icon /> })}
                        >
                          {item?.name}
                        </Button>
                      );
                    })}
                  </Styled.ReportGroup>
                );
              }

              return (
                <Button
                  variant="outlined"
                  key={name}
                  onClick={() => handleClick(path)}
                  // @ts-ignore
                  startIcon={Icon && <Icon />}
                >
                  {name}
                </Button>
              );
            })}
          </Box>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default memo(OrganizationReportsServiceList);
