import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CreateStageModal } from '../components';

export const CREATE_STAGE_MODAL = 'createStageModal';

export const createStageModalConfig: TModalConfig = {
  id: CREATE_STAGE_MODAL,
  name: CREATE_STAGE_MODAL,
  type: EModalType.Custom,
  title: 'Параметры группировки',
  closeOnClickOutside: true,
  children: CreateStageModal,
  styledProps: {
    $isFullContentWidth: true,
    $size: '800px',
  },
};
