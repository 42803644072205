import { IReportConfig } from '../../models/reportConfig.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = Omit<IReportConfig, 'isAuto' | 'id'>;
type TypeResponse = IReportConfig;

export const createReportConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agrosignal/reports/config/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
