import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.transient()
class FieldsService {
  @lazyInject(Axios)
  protected axios: Axios;

  getFieldList = (payload: TypeApiRequest<'getFieldList'>) => {
    return this.axios.api.getFieldList(payload);
  };
}

export default FieldsService;
