import { IReportConfig } from '../../models/reportConfig.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = Partial<IReportConfig> & { id: string };
type TypeResponse = string;

export const updateReportConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/as-agrosignal/reports/config/${params.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
