import moment from 'moment';
import _ from 'lodash';
import { v4 } from 'uuid';

import { ReportService } from '../../../../../../../../../../services';
import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import {
  ECropwiseFilterIsAuto,
  ICropwiseConfig,
  ICropwiseConfigForm,
  ICropwiseCreateConfig,
  ICropwiseHistoryFilter,
} from '../../../models';
import { CropwiseReportStore } from '../../stores';
import { DEFAULT_FORM_CROPWISE_CONFIG } from '../../../constants';

const HIDED_API_KEY = '****************';

@provide.transient()
class CropwiseReportController {
  @lazyInject(ReportService)
  reportService: ReportService;

  @lazyInject(CropwiseReportStore)
  store: CropwiseReportStore;

  /**
   *
   * @deprecated
   */
  getReportsConfigByOrgId = async (orgId: string): Promise<ICropwiseConfigForm | false> => {
    const response = await this.reportService.getCropwiseReportsConfigByOrgId({ id: orgId });

    if (!response) {
      return false;
    }

    return this.parseReportConfigToClientConfig(response);
  };

  getCropwiseReportsConfigList = async ({
    orgId,
    page,
    size,
  }: {
    orgId: string;
    page: number;
    size: number;
  }) => {
    try {
      this.store.isLoading = true;
      this.store.clearConfigMap();

      const response = await this.reportService.getCropwiseReportsConfigList({
        organizationId: orgId,
        page,
        size,
      });

      if (!response) {
        return false;
      }

      response.content.forEach(config => {
        this.store.setConfigById(config.id, this.parseReportConfigToClientConfig(config));
      });

      return response;
    } catch (error) {
      console.log('fetch reports config list error');
    } finally {
      this.store.isLoading = false;
    }
  };

  createReportConfig = async (
    organizationId: string,
    config: ICropwiseCreateConfig & { id?: string }
  ) => {
    const response = await this.reportService.createCropwiseReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    if (response) {
      this.store.deleteConfigById(config?.id);
    }

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<ICropwiseConfig>,
    defaultConfig: Partial<ICropwiseConfig>
  ) => {
    const changes: Partial<ICropwiseConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    if (_.isEmpty(changes)) {
      return Promise.reject(new Error('Нет изменений'));
    }

    if (!config?.seasons?.length) {
      return Promise.reject(new Error('Сезоны не выбраны'));
    }

    const payload = {
      id: configId,
      ...changes,
      ...(changes?.fromDate && { fromDate: this.parseDateToString(changes.fromDate) }),
      ...(changes?.toDate && { toDate: this.parseDateToString(changes.toDate) }),
    };

    const touchedPayload: Partial<ICropwiseConfigForm> = {};

    const touchedFields = this.store.getTouchedConfigFields(configId);

    if (!touchedFields || touchedFields?.size === 0) {
      return Promise.reject(new Error('Нет изменений'));
    }

    touchedFields.forEach((field: any) => {
      touchedPayload[field] = payload[field];
    });

    if (touchedFields.has('fromDate')) {
      touchedPayload.fromDate = payload.fromDate ? this.parseDateToString(payload.fromDate) : null;
    }

    if (touchedFields.has('toDate')) {
      touchedPayload.toDate = payload.toDate ? this.parseDateToString(payload.toDate) : null;
    }

    const response = await this.reportService.updateCropwiseReportConfig({
      ...touchedPayload,
      id: configId,
    });

    this.store.cleanConfigTouchedFields(configId);

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteCropwiseReportConfig({ id: configId });

    return response;
  };

  triggerDataReport = async (payload: TypeApiRequest<'triggerDataReport'>) => {
    const response = await this.reportService.triggerDataReport(payload);

    return response;
  };

  triggerFilesReport = async (payload: TypeApiRequest<'triggerFilesReport'>) => {
    const response = await this.reportService.triggerFilesReport(payload);

    return response;
  };

  downloadCropwiseReport = async (payload: TypeApiRequest<'downloadCropwiseReport'>) => {
    const response = await this.reportService.downloadCropwiseReport(payload);

    return response;
  };

  getReportImportOperationList = async (
    filterData: Partial<ICropwiseHistoryFilter>,
    page: number,
    size: number
  ) => {
    const parsedData = {
      ...filterData,
      ...(filterData?.fromDate && {
        fromDate: this.parseDateToString(moment(filterData.fromDate).toDate()),
      }),
      ...(filterData?.toDate && {
        toDate: this.parseDateToString(moment(filterData.toDate).toDate()),
      }),
      page,
      size,
    };

    // @ts-ignore
    if (filterData?.type && filterData?.type === 'Не выбрано') {
      delete parsedData.type;
    }

    switch (filterData?.isAuto) {
      case ECropwiseFilterIsAuto.Auto:
        parsedData.isAuto = true;

        break;

      case ECropwiseFilterIsAuto.NotAuto:
        parsedData.isAuto = false;
        break;

      default:
        delete parsedData.isAuto;
        break;
    }
    // @ts-ignore
    const payload: TypeApiRequest<'getCropwiseReportImportOperationList'> = {
      ...parsedData,
    };

    const response = await this.reportService.getCropwiseReportImportOperationList(payload);

    return response;
  };

  private parseReportConfigToClientConfig = (config: ICropwiseConfig) => {
    const outputData: ICropwiseConfigForm & { password: string } = {
      ...config,
      password: config?.passwordFilled && HIDED_API_KEY,
      fromDate: config?.fromDate ?? null,
      toDate: config?.toDate ?? null,
      state: 'edit',
    };

    return outputData;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: ICropwiseCreateConfig & Partial<ICropwiseConfig>
  ): TypeApiRequest<'createCropwiseReportConfig'> => {
    delete config.organizationId;
    delete config.id;
    delete config.organizationName;
    delete config.passwordFilled;

    const output: TypeApiRequest<'createCropwiseReportConfig'> = {
      ...config,
      organizationId,
      ...(config?.fromDate && { fromDate: this.parseDateToString(config.fromDate) }),
      ...(config?.toDate && { toDate: this.parseDateToString(config.toDate) }),
    };

    return output;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleCropwiseAutoReportUpload(configId, state);

    return response;
  };

  createNewConfigPlaceholder = () => {
    const uniqueId = v4();

    this.store.setConfigById(uniqueId, { ...DEFAULT_FORM_CROPWISE_CONFIG, id: uniqueId });
  };

  removeNewConfigPlaceholder = (id: string) => {
    this.store.deleteConfigById(id);
  };

  private parseDateToString = (date: any): string => moment(date).format('YYYY-MM-DD');

  deleteCropwiseReportOperationList = (idList: string[]) => {
    return this.reportService.deleteCropwiseReportOperationList({ ids: idList });
  };
}

export default CropwiseReportController;
