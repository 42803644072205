import { Box } from '@mui/material';

import { PhotosTable } from '../components';

const OrgIntegraCropwisePhotos = () => {
  return (
    <Box>
      <PhotosTable />
    </Box>
  );
};

export default OrgIntegraCropwisePhotos;
