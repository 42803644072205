import { EAttributeUnit } from '../api/models/attribute.model';
import { EDictionaryItemStatus } from '../api/models/dictionaries.model';

import { ISelectOption } from './selectOption';

export enum ESearchPath {
  /**
   * Точное совпадение
   */
  PathEquals = 'pathEquals',
  /**
   * Все дерево
   */
  PathPrefix = 'pathPrefix',
}

export enum EHasAdditionalAttribute {
  HasAttribute = 'hasAttribute',
  NoAttribute = 'noAttribute',
  NotSelected = 'notSelected',
}

interface IAdvancedFilter {
  id?: string;
  parentId?: string;
  name?: string;
  code?: string;
  path?: string;
  searchPath?: ESearchPath;
  isLastVersion?: boolean;
  isOriginal?: boolean;
  level?: number;
  status?: { value: EDictionaryItemStatus; label: string };
  hasAdditionalAttribute?: ISelectOption;
  filterAttribute?: ISelectOption;
  additionalAttribute?: Record<string, string | boolean | number>;
  additionalAttributeType?: EAttributeUnit;
}

export type { IAdvancedFilter };
