import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { throttle } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  TDictionaryItem,
  TDictionaryItemResponse,
} from '../../../../../../api/models/dictionaries.model';
import { Colors } from '../../../../../../constants/colors';
import { ISelectOption } from '../../../../../../types/selectOption';
import { DictionariesController } from '../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../shared/utils';

export type TDictionaryOption = ISelectOption & { rawData: TDictionaryItem };

interface IAutocompleteProps {
  /**
   * Если не будет передан, компонент попытается использовать :name из URL
   */
  remoteName?: string;
  label?: string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string | TDictionaryOption>
  ) => void;
  isDisplayId?: boolean;
}

const AutocompleteSearchByNameOrId: FC<IAutocompleteProps> = ({
  remoteName,
  onChange,
  label,
  isDisplayId,
}) => {
  const { getDictionaryItemListByNameOrId } = useStore(DictionariesController);
  const [optionList, setOptionList] = useState<TDictionaryOption[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const { name } = useParams<{ name: string }>();

  const currentRemoteName = useMemo(() => {
    if (remoteName) {
      return remoteName;
    } else {
      return name;
    }
  }, [remoteName, name]);

  const throttledFetch = useMemo(
    () =>
      throttle(
        (
          request: () => Promise<TDictionaryItemResponse>,
          onFetch: (data: TDictionaryItemResponse) => void
        ) => {
          setIsLoading(true);
          request()
            .then(data => {
              onFetch(data);
            })
            .finally(() => setIsLoading(false));
        },
        1000
      ),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getDictionaryItemListByNameOrId(currentRemoteName, '')
      .then(data => {
        setOptionList(
          data.content.map(value => ({ label: value.name, value: value.id, rawData: value }))
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    throttledFetch(
      () => getDictionaryItemListByNameOrId(currentRemoteName, inputValue),
      data =>
        setOptionList(
          data.content.map(value => ({ label: value.name, value: value.id, rawData: value }))
        )
    );
  }, [inputValue, currentRemoteName]);

  const handleOnChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: NonNullable<string | TDictionaryOption>
    ) => {
      if (onChange) {
        onChange(event, value);
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      fullWidth
      options={optionList}
      renderInput={params => <TextField {...params} label={label || 'Элемент справочника'} />}
      onInputChange={(e, value) => setInputValue(value)}
      loading={isLoading}
      loadingText="Загрузка элементов"
      noOptionsText="Подходящих элементов не найдено"
      filterSelectedOptions
      onChange={handleOnChange}
      {...(isDisplayId
        ? {
            renderOption: (props, option: TDictionaryOption) => (
              <li {...props}>
                <Box>
                  <Typography>{option.label}</Typography>
                  <Typography sx={{ fontSize: 12, color: Colors.gray }}>{option.value}</Typography>
                </Box>
              </li>
            ),
          }
        : {})}
    />
  );
};

export default memo(AutocompleteSearchByNameOrId);
