export enum EOrgIntegraCropwiseRoutes {
  Organization = 'organization',
  Employees = 'employees',
  Fields = 'fields',
  Checklists = 'checklists',
  ChecklistInstances = 'checklist-instances',
  MessagesError = 'messages-error',
  FilesError = 'files-error',
  Cultures = 'cultures',
  Attributes = 'attributes',
  Measurements = 'measurements',
  Photos = 'photos',
}

export enum EOrgIntegraCropwiseErrorRoutes {
  MessagesError = 'messages-error',
  FilesError = 'files-error',
}

export enum EOrgIntegraCropwiseTabs {
  Organization = 'organization',
  Employees = 'employees',
  Fields = 'fields',
  Checklists = 'checklists',
  ChecklistInstances = 'checklist-instances',
  MessagesError = 'messages-error',
  FilesError = 'files-error',
  Cultures = 'cultures',
  Attributes = 'attributes',
  Measurements = 'measurements',
  Photos = 'photos',
}
