import { styled } from '@mui/material/styles';

import { Colors } from '../../../../constants/colors';

export const Content = styled('div')`
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled('label')`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  padding: 10px 0;
  display: block;
  user-select: none;
  cursor: pointer;
  color: ${Colors.labelGray};
`;

type RowProps = {
  flexDirection?: 'column' | 'row';
};

export const Row = styled('div')<RowProps>(({ flexDirection }) => ({
  marginBottom: '15px',
  width: '100%',
  display: 'flex',
  flexDirection,
}));

export const BottomSection = styled('div')`
  height: 40px;
  width: 100%;
`;

export const RowSpaced = styled(Row)`
  margin-top: 15px;
  justify-content: space-between;
`;

export const DisabledSelection = styled('div')`
  user-select: none;
`;

export const ErrorText = styled('span')`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: ${Colors.darkRed};
`;

export const ExpertisesInterestsBlock = styled('div')`
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-gap: 8px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ExpertiseWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

export const Competition = styled('div')`
  padding: 6px 12px;
  user-select: none;
  border: 0.5px solid ${Colors.darknessGray};
  color: ${Colors.darkGray};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 8px;
  margin-right: 8px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }
  background: ${Colors.lightnessGray};
`;

export const DeleteIcon = styled('img')`
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-right: 9px;
  margin-left: 1px;
`;
