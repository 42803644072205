import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
  id: string;
  name?: string;
  translation?: string;
  description?: string;
  picLink?: string;
  code?: string;
  attrs?: Record<string, unknown>;
  originalLink?: string;
};

type TypeResponse = {};

type TypeError = {};

export const rewriteDictionaryItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-dictionary/admin/dictionary/${request.remoteName}/update/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
