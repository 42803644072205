import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';
import { isNil, omitBy } from 'lodash';

import { useStore } from '../../../../../../../shared/utils';
import { IImportOperationModel } from '../../../../../../../../api/models/importOperation.model';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { agrosignalGridColumns } from '../../configs';
import { CropwiseReportController } from '../../components/OrganizationReportsCropwise/mobx';
import { ICropwiseHistoryFilter } from '../../components/OrganizationReportsCropwise/models';

const PAGE_SIZE_LIST = [10, 20, 50, 100];

const useCropwiseReportHistory = (configId: string) => {
  const controller = useStore(CropwiseReportController);
  const { setNotification } = useNotificator();

  const { organizationId } = useParams<{ organizationId: string }>();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formikValue, setFormikValue] = useState<Partial<ICropwiseHistoryFilter>>(
    configId ? { configId } : null
  );
  const [valueList, setValueLst] = useState<IImportOperationModel[]>([]);

  const gridColumnConfig = useMemo<GridColDef<IImportOperationModel>[]>(
    () => agrosignalGridColumns,
    []
  );

  useEffect(() => {
    fetchHistory(formikValue);
  }, [page, size]);

  const fetchHistory = (data: Partial<ICropwiseHistoryFilter>, forcedPage?: number) => {
    setIsLoading(true);

    const payload = omitBy(data, isNil);

    controller
      .getReportImportOperationList({ ...payload, organizationId }, forcedPage ?? page, size)
      .then(response => {
        setValueLst(response.content);
        setTotalElements(response.totalElements);
        setFormikValue(data);
      })
      .catch(error => {
        console.error(error);
        setNotification(getNotificatorProps('Ошибка получения истории', ENotificationStyles.Error));
      })
      .finally(() => setIsLoading(false));
  };

  const deleteOperations = (idList: string[]) => {
    return controller
      .deleteCropwiseReportOperationList(idList)
      .then(data => {
        setNotification(
          getNotificatorProps('Операции успешно удалены', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(error => {
        setNotification(getNotificatorProps('Ошибка удаления операций', ENotificationStyles.Error));
      });
  };

  return {
    page,
    setPage,
    size,
    setSize,
    totalElements,
    isLoading,
    valueList,
    gridColumnConfig,
    fetchHistory,
    deleteOperations,
  };
};

export { useCropwiseReportHistory, PAGE_SIZE_LIST };
