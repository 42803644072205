import { styled } from '@mui/material/styles';

import BackgroundImage from '../../static/illustration.svg';

export const Layout = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(${BackgroundImage});
  background-size: cover;
`;

export const LayoutInner = styled('div')`
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;

  @media (max-width: 479px) {
    padding: 0 13px;
  }

  @media (min-width: 480px) {
    padding-left: calc(50% - 206px);
    padding-right: calc(50% - 206px);
  }
`;

export const Heading = styled('p')`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
`;

export const BackButtonWrapper = styled('div')`
  position: absolute;
  left: 60px;
  top: 24px;

  @media (max-width: 767px) {
    left: 16px;
  }

  p {
    font-weight: 400;
  }
`;
