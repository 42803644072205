import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { Box, TextField } from '@mui/material';
import { useMemo, useState } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseErrorMessageService } from '../../../../mobx';

const ErrorMessageMultiplePushForm = () => {
  const service = useStore(OrgIntegraCropwiseErrorMessageService);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();

  const [isProcessing, setIsProcessing] = useState(false);

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    selectionList: string[];
  };
  const handlers = getModalRuntimeHandlers();

  const handleSuccess = () => {
    setIsProcessing(true);

    service
      .pushMessage({ entityIds: payload.selectionList })
      .then(() => {
        handlers?.onSuccess?.();
      })
      .finally(() => {
        setIsProcessing(false);
        closeModal();
      });
  };

  const handleCancel = () => {
    closeModal();
  };

  const content = useMemo(() => payload.selectionList?.join(',\n'), []);

  return (
    <Box>
      <ModalTitle>Обработка ошибок</ModalTitle>
      <TextField
        title="ID сущностей на обработку"
        fullWidth
        multiline
        minRows={10}
        maxRows={12}
        value={content || ''}
        InputProps={{
          readOnly: true,
        }}
        sx={{ marginBottom: 2 }}
      />
      <ModalFooter
        successButton={{
          title: 'Подтвердить',
          disabled: isProcessing,
          handler: () => {
            handleSuccess();
          },
        }}
        denyButton={{
          title: 'Отменить',
          handler: () => {
            handleCancel();
          },
        }}
      />
    </Box>
  );
};

export default ErrorMessageMultiplePushForm;
