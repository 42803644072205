import { useNotificator } from '@farmlink/farmik-ui';

import { IChecklistAttribute } from '../../../../../../../api/models/checklist.model';
import { useStore } from '../../../../../../shared/utils';
import { FormulasController, FormulasStore } from '../mobx';
import { EFormulaTypes } from '../models';
import { getNotificatorProps } from '../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../shared/constanst/notifications';
import { AttributeODZController } from '../../../containers/atttibutes/AttributeContainer/components/AttributeBuilderFrom/components/ODZ/mobx';

interface IUseFormulasOptions {
  checklistAttributeId: string;
  checklistAttribute: IChecklistAttribute;
  formulaType: EFormulaTypes;
  autoComplete?: boolean;
}

const useFormulas = ({
  checklistAttributeId,
  checklistAttribute,
  formulaType,
  autoComplete,
}: IUseFormulasOptions) => {
  const controller = useStore(FormulasController);
  const store = useStore(FormulasStore);
  const { getPreparedODZData } = useStore(AttributeODZController);

  const { setNotification } = useNotificator();

  const saveFormula = ({
    onSuccessCallback,
    syncSto,
  }: {
    onSuccessCallback: () => void;
    syncSto?: boolean;
  }) => {
    if (formulaType === EFormulaTypes.ODZ) {
      const payload = getPreparedODZData();

      controller
        .updateChecklistAttributeFormula(
          checklistAttributeId,
          store.activeEditor,
          autoComplete,
          payload,
          syncSto
        )
        .then(() => {
          setNotification(
            getNotificatorProps('Формула успешно обновлена', ENotificationStyles.Success)
          );

          onSuccessCallback?.();
        });
      return;
    }

    controller
      .updateChecklistAttributeFormula(
        checklistAttributeId,
        store.activeEditor,
        autoComplete,
        null,
        syncSto
      )
      .then(() => {
        setNotification(
          getNotificatorProps('Формула успешно обновлена', ENotificationStyles.Success)
        );

        onSuccessCallback?.();
      })
      .catch(() => {
        setNotification(
          getNotificatorProps('Ошибка обновления формулы', ENotificationStyles.Error)
        );
      });
  };

  const deleteFormula = ({ onSuccessCallback }: { onSuccessCallback: () => void }) => {
    controller
      .deleteChecklistAttributeFormula(checklistAttributeId, store.activeEditor)
      .then(() => {
        setNotification(
          getNotificatorProps('Формула успешно удалена', ENotificationStyles.Success)
        );

        onSuccessCallback?.();
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка удаления формулы', ENotificationStyles.Error));
      });
  };

  return { saveFormula, deleteFormula };
};

export default useFormulas;
