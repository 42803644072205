import { TypeApiRoute } from '../../models/type.api.request';

import { TDictionaryItem } from './../../models/dictionaries.model';

type TypeRequest = {
  remoteName: string;
  id: string;
};

type TypeResponse = TDictionaryItem;

export const getDictionaryItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/dictionary/${params.remoteName}/${params.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
