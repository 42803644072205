import { Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';

const Selector = styled(Autocomplete)`
  max-width: 300px;
  min-width: 200px;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const DictionaryMain = styled('div')`
  height: calc(100vh - 100px);
`;

const Styled = {
  Selector,
  ButtonGroup,
  DictionaryMain,
};

export default Styled;
