import styled, { StyledComponentBase } from 'styled-components';

interface IInteresEmptyStyled extends StyledComponentBase<any, {}> {
  Icon?: any;
  Text?: any;
}

export const WrapperInteres = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    padding: 20px 16px 0 16px;
  }

  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }
`;

export const InteresEmptyStyled: IInteresEmptyStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 12px;
  padding-bottom: 32px;
  flex-wrap: wrap;
`;

InteresEmptyStyled.Icon = styled.p`
  margin-bottom: 28px;
  text-align: center;

  svg {
    width: 42px;
    height: 42px;
  }
`;

InteresEmptyStyled.Text = styled.p`
  text-align: center;

  font-size: 14px;
  line-height: 20px;
`;
