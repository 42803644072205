import * as functionCollection from '../configs/functions/index';
import { EFormulaFunctionAggregation } from '../models';
import { IFormulaFunction } from '../models/FormulaFunction.types';

export type formulaFunctionName = keyof typeof functionCollection;

export const formulaFunctionScheme = new Map<EFormulaFunctionAggregation, IFormulaFunction>(
  Object.entries(functionCollection) as [EFormulaFunctionAggregation, IFormulaFunction][]
);

export { functionCollection };
