import { has } from 'lodash';

import { lazyInject, provide } from '../../../../../../../shared/utils';
import { ICoreIntegrationForm, ICoreSynchronizationForm, IPushEventsForm } from '../../models';

import OrgIntegraService from './orgIntegra.service';

@provide.transient()
class OrgIntegraConfigService {
  @lazyInject(OrgIntegraService)
  protected service: OrgIntegraService;

  createOrgIntegraConfig = (formData: Partial<ICoreIntegrationForm>) => {
    if (!has(formData, 'startTime') || !has(formData, 'isEnabled')) {
      return Promise.reject(new Error('Заполните данные для создания конфигурации'));
    }

    const payload = {
      startTime: formData.startTime,
      endTime: formData.endTime,
      organizationId: formData.organizationId,
    };

    return this.service.createOrgIntegraConfig(payload).then(data => {
      return data;
    });
  };

  fetchOrgIntegraConfig = (organizationId: string) => {
    return this.service.getOrgIntegraConfig({ organizationId });
  };

  deleteOrgIntegraConfig = (organizationId: string) => {
    return this.service.deleteOrgIntegraConfig(organizationId);
  };

  updateIntegraConfig = (formData: Partial<ICoreIntegrationForm>) => {
    if (!has(formData, 'startTime') || !has(formData, 'isEnabled')) {
      return Promise.reject(new Error('Заполните данные для обновления конфигурации'));
    }

    return this.service.updateOrgIntegraConfig({
      organizationId: formData.organizationId,
      endTime: formData.endTime,
      startTime: formData.startTime,
    });
  };

  pushIntegraConfig = (formData: ICoreSynchronizationForm) => {
    if (
      formData.typesEntities?.size < 1 ||
      !has(formData, 'startTime') ||
      !has(formData, 'organizationId')
    ) {
      return Promise.reject(new Error('Заполните данные для запуска синхронизации'));
    }

    const payload: Partial<IPushEventsForm> = {
      organizationIds: formData.organizationId.flatMap(item => item.value),
      entityTypes: Array.from(formData.typesEntities.values()),
      userIds: formData.userId.flatMap(item => item.value),
      startTime: formData.startTime,
      endTime: formData.endTime,
    };

    return this.service.pushEventsIntegra(payload);
  };
}

export default OrgIntegraConfigService;
