import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ExportOrganizationsFieldsModal } from './components';

const EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID = 'exportOrganizationsFieldsModalId';

const exportOrganizationsFieldsModalConfig: TModalConfig = {
  id: EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID,
  name: EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $modalPaddings: '40px',
    $height: '650px',
  },
  children: ExportOrganizationsFieldsModal,
};

export { EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID, exportOrganizationsFieldsModalConfig };
