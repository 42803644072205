export enum EFormulaFunctionAggregation {
  ADD = 'ADD',
  AND = 'AND',
  AVG = 'AVG',
  DIVISION = 'DIVISION',
  EQ = 'EQ',
  GOE = 'GOE',
  GT = 'GT',
  LOE = 'LOE',
  LT = 'LT',
  MULT = 'MULT',
  NE = 'NE',
  NOT = 'NOT',
  OR = 'OR',
  PERCENT = 'PERCENT',
  SUBTRACT = 'SUBTRACT',
  XOR = 'XOR',
}
