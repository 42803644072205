import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';
import { isNil, omitBy } from 'lodash';
import moment from 'moment';

import { useStore } from '../../../../../../../../shared/utils';
import { IImportOperationModel } from '../../../../../../../../../api/models/importOperation.model';
import { AssistanceReportService } from '../../../../../../../../services/reports/assistance/assistanceReports.service';
import { IOrganizationReportsAssistanceHistoryFilter } from '../../OrganizationReportsCropwise/models';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';
import { TooltipedValue } from '../../../../../../../../shared/components';

const PAGE_SIZE_LIST = [10, 20, 50, 100];

const useAssistanceTaskReportHistory = (configId: string) => {
  const service = useStore(AssistanceReportService);
  const { setNotification } = useNotificator();

  const { organizationId } = useParams<{ organizationId: string }>();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formikValue, setFormikValue] = useState<
    Partial<IOrganizationReportsAssistanceHistoryFilter>
  >(configId ? { configId } : null);
  const [valueList, setValueLst] = useState<IImportOperationModel[]>([]);

  const gridColumnConfig = useMemo<GridColDef<IImportOperationModel>[]>(
    () => [
      {
        field: 'fromDate',
        type: 'string',
        headerName: 'Дата начала для выборки данных',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 120,
      },
      {
        field: 'toDate',
        type: 'string',
        headerName: 'Дата окончания для выборки данных',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 130,
      },
      {
        field: 'host',
        type: 'string',
        headerName: 'URL',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'reportsLocation',
        type: 'string',
        headerName: 'Папка выгрузки отчёта',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'filesLocation',
        type: 'string',
        headerName: 'Папка выгрузки фотоматериалов',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'creationDate',
        type: 'string',
        headerName: 'Дата заказа выгрузки',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 120,
      },
      {
        field: 'operationStatus',
        type: 'string',
        headerName: 'Статус',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'message',
        type: 'string',
        headerName: 'Ошибка',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'type',
        type: 'string',
        headerName: 'Тип запроса',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 130,
      },
    ],
    []
  );

  useEffect(() => {
    fetchHistory(formikValue);
  }, [page, size]);

  const fetchHistory = (
    data: Partial<IOrganizationReportsAssistanceHistoryFilter>,
    forcedPage?: number
  ) => {
    setIsLoading(true);

    const payload = omitBy(data, isNil);

    if (data?.fromDate) {
      payload.fromDate = moment(data.fromDate).format('YYYY-MM-DD');
    }

    if (data?.toDate) {
      payload.toDate = moment(data.toDate).format('YYYY-MM-DD');
    }

    service
      .getAssistanceTaskReportList({ ...payload, organizationId, page: forcedPage ?? page, size })
      .then(response => {
        setValueLst(response.content);
        setTotalElements(response.totalElements);
        setFormikValue(data);
      })
      .catch(error => {
        console.error(error);
        setNotification(getNotificatorProps('Ошибка получения истории', ENotificationStyles.Error));
      })
      .finally(() => setIsLoading(false));
  };

  return {
    page,
    setPage,
    size,
    setSize,
    totalElements,
    isLoading,
    valueList,
    gridColumnConfig,
    fetchHistory,
  };
};

export { useAssistanceTaskReportHistory, PAGE_SIZE_LIST };
