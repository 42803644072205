import { Colors } from '@farmlink/farmik-ui';
import { Box } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
   50% { opacity: 0.5; } 
`;

const HighlightBox = styled(Box)`
  position: relative;

  &.highlight::before {
    position: absolute;
    top: -7px;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${Colors.lightPink};
    border-radius: 8px;
    animation: ${blink} 1s steps(1) infinite;
    transform: scaleY(1.5) scaleX(1.05) translateY(10%);
  }
`;

export default HighlightBox;
