import { pick } from 'lodash';

import {
  IRoleExtendsEmployee,
  IRoleInputBase,
} from '../../../../../../../../api/models/role.model';

const getBaseFormDataFromRole = (role: IRoleExtendsEmployee): IRoleInputBase => {
  return pick(role, [
    'name',
    'isScoutForUser',
    'isScoutForOrganization',
    'isExperimentForUser',
    'isExperimentForOrganization',
    'isOwner',
  ]);
};

export default getBaseFormDataFromRole;
