import { Autocomplete, Box, TextField } from '@mui/material';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { EFormulaFunctionAggregation, IFormulaError } from '../../../../../../models';
import { FUNCTION_OPTION_LIST } from '../../../../../../constants';
import { EErrorCollection, errorCollection } from '../../../../../../configs';

interface IProps {
  functionType: EFormulaFunctionAggregation;
  onSelectFunction: (EFormulaType) => void;
  allowedOptionList?: ISelectOption[];
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const FormulaFunctionType: FC<IProps> = ({
  functionType,
  onSelectFunction,
  allowedOptionList,
  handleRemoveError,
  handleSetError,
}) => {
  const [error, setError] = useState<{ text: string; id: string }>(null);
  const [uuid] = useState(v4());

  const ref = useRef();

  useEffect(() => {
    if (!functionType) {
      handleSetError?.(
        {
          text: errorCollection.FORMULA_FUNCTION_NOT_SELECTED,
          errorType: EErrorCollection.FORMULA_FUNCTION_NOT_SELECTED,
          HTMLElementId: `${uuid}-functionType`,
        },
        uuid
      );
      return;
    } else {
      handleRemoveError(uuid);
    }

    if (
      functionType &&
      allowedOptionList?.length &&
      !allowedOptionList?.find(item => item.value === functionType)
    ) {
      const errorId = handleSetError?.({
        ref,
        errorType: EErrorCollection.WRONG_TYPE,
        text: errorCollection.WRONG_TYPE,
      });

      setError({ id: errorId, text: errorCollection.WRONG_TYPE });
    } else {
      handleRemoveError?.(error?.id);
      setError(null);
    }
  }, [functionType, allowedOptionList, ref]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(error?.id);
      handleRemoveError?.(uuid);
    };
  }, [error]);

  return (
    <Box width={200} marginTop={2}>
      <Autocomplete
        disableClearable
        value={functionType ?? null}
        isOptionEqualToValue={option => option.value === functionType}
        options={allowedOptionList || FUNCTION_OPTION_LIST || []}
        onChange={(e, value) => {
          onSelectFunction((value as ISelectOption).value);
        }}
        data-test-id={`formula-block-function-type`}
        renderInput={params => (
          <>
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error?.text}
              label={'Функция'}
              data-test-id={`formula-block-function-type-input`}
            />
          </>
        )}
      />
    </Box>
  );
};

export default memo(FormulaFunctionType);
