import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeCheckListStatus, initialCheckListStatus } from '../../../api/models/check.list.model';

type TypeFilters = {
  status: TypeCheckListStatus[];
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  statusOptions = initialCheckListStatus;

  filters: TypeFilters = {
    status: [],
  };

  setFilterStatus = (status: TypeCheckListStatus[]) => {
    runInAction(() => {
      this.filters.status = status;
    });
  };

  clearFilter = () => {
    runInAction(() => {
      this.filters.status = [];
    });
  };
}
