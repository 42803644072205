import { EEmployeeRole, EEmployeeStatus, RoleInfo } from './employee.model';

export enum EOrganizationStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type TypeOrganizationStatus = { statusId: EOrganizationStatus; statusName: string };
export type TypeOrganizationType = { orgTypeId: number; OrgType: string };

export type TypeOrganizationSort = {
  field: string;
  sort: string;
};

export type FormValueError = {
  source: string;
  title: string;
};

interface Logo {
  id: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  downloadUrl: string;
  fileUploadDate: string;
  fileUploadUserId: string;
}

type Specialization = {
  id: number;
  name: string;
  description: string;
  priority: number;
};

type Owner = {
  userId: string;
  userName: string;
};

type AddressFact = {
  index: string;
  countryISOCode: string;
  region: string;
  cityType: string;
  city: string;
  cityDistrictType: string;
  cityDistrict: string;
  streetType: string;
  street: string;
  houseType: string;
  house: string;
  blockType: string;
  block: string;
  flatType: string;
  flat: string;
  kladrCode: string;
  addressfull: string;
};

type AddressJur = {
  index: string;
  countryISOCode: string;
  region: string;
  cityType: string;
  city: string;
  cityDistrictType: string;
  cityDistrict: string;
  streetType: string;
  street: string;
  houseType: string;
  house: string;
  blockType: string;
  block: string;
  flatType: string;
  flat: string;
  kladrCode: string;
  addressfull: string;
};

export type TypeOrganization = {
  INN: string;
  KPP: string;
  OGRN: string;
  OKPO: string;
  OKVED: string;
  addressFact: AddressFact;
  addressJur: AddressJur;
  countEmployees: number;
  creationDate: string;
  name: string;
  organizationId: string;
  serviceProvider: boolean;
  status: TypeOrganizationStatus;
  type: TypeOrganizationType;
  owner: Owner;
  countFields: number;
  contact: string;
  email: string;
  phone: string;
  specialization: Specialization;
  logo: Logo;
  isScout: boolean;
  isExperiment: boolean;
  isSTOEnabled: boolean;
};

export interface IOrganizationForUserDto {
  name: string;
  contact: string;
  specialization: Specialization;
  logo: Logo;
  addressFact: AddressFact;
  addressJur: AddressJur;
  isScout: boolean;
  isExperiment: boolean;
  manager: {
    id: string;
    fullName: string;
  };
  representativeFullName: string;
  representativeMail: string;
  representativePhone: string;
  representativePosition: string;
  email: string;
  phone: string;
  organizationId: string;
  organizationStatus: TypeOrganizationStatus;
  serviceProvider: boolean;
  employeeStatus: EEmployeeStatus;
  employeeRole: EEmployeeRole;
  role: RoleInfo;
  organizationJoinDate: string;
  INN: string;
  OGRN: string;
  KPP: string;
  OKPO: string;
  OKVED: string;
  type: TypeOrganizationType;
}

export const initialOrganizationStatus: TypeOrganizationStatus[] = [
  {
    statusId: EOrganizationStatus.ACTIVE,
    statusName: 'Активный',
  },
  {
    statusId: EOrganizationStatus.ARCHIVED,
    statusName: 'В архиве',
  },
];

export enum EFormFields {
  name = 'name',
  ogrn = 'ogrn',
  okved = 'okved',
  okpo = 'okpo',
  kpp = 'kpp',
  inn = 'inn',
  addressJur = 'addressJur',
  addressFact = 'addressFact',
  contact = 'contact',
  email = 'email',
  phone = 'phone',
  logo = 'logo',
  nameOrInn = 'nameOrInn',
  creationDate = 'creationDate',
  status = 'status',
  serviceProvider = 'serviceProvider',
  userId = 'userId',
  employeeId = 'employeeId',
  organizationType = 'organizationType',
  id = 'id',
}

export interface IFormEditValues {
  [EFormFields.name]: string;
  [EFormFields.ogrn]: string;
  [EFormFields.okved]: string;
  [EFormFields.okpo]: string;
  [EFormFields.kpp]: string;
  [EFormFields.inn]: string;
  [EFormFields.addressJur]: string;
  [EFormFields.addressFact]: string;
  [EFormFields.contact]: string;
  [EFormFields.phone]: string;
  [EFormFields.email]: string;
  [EFormFields.logo]: string;
  [EFormFields.organizationType]: TypeOrganizationType;
}
