import styled from 'styled-components';

const Form = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const Styled = { Form };

export default Styled;
