import {
  EImportOperationStatus,
  IAgropogodaImportOperationModel,
} from '../../../models/importOperation.model';
import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;

  ownerId?: string;
  /**
   * формат `YYYY-MM-DD`
   */
  startDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  endDate: string;
  statuses: EImportOperationStatus[];
};
type TypeResponse = TypeResponsePageable & { content: IAgropogodaImportOperationModel[] };

export const getAgropogodaReportImportOperationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agropogoda/reports/importOperations/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
