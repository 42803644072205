import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistStage } from '../../../models/checklist.stage.model';

type TypeRequest = { id: string };

type TypeResponse = IChecklistStage;

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const deleteChecklistStage: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (params: TypeRequest) => `/api/as-fields/checklist/stage/${params.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
