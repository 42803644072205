import { makeAutoObservable, runInAction } from 'mobx';

import {
  EInvitationStatus,
  IInvitation,
} from '../../../../../../../../../api/models/invitation.model';
import { provide } from '../../../../../../../../shared/utils';
import { ISelectOption } from '../../../../../../../../../types/selectOption';

export const STATUS_LIST = [
  { label: 'Отправлено', value: 'SENT' },
  { label: 'Принято', value: 'ACCEPTED' },
  { label: 'Отклонено', value: 'DECLINED' },
  { label: 'Отменено', value: 'CANCELLED' },
  { label: 'Не учитывать', value: '' },
];

export const STATUS_SCHEME: Record<
  EInvitationStatus,
  { text: string; color: 'info' | 'primary' | 'warning' | 'secondary' | 'default' }
> = {
  SENT: { text: 'Отправлено', color: 'info' },
  ACCEPTED: { text: 'Принято', color: 'primary' },
  DECLINED: { text: 'Отклонено', color: 'warning' },
  CANCELLED: { text: 'Отменено', color: 'secondary' },
};

@provide.singleton()
class OrganizationInvitesStore {
  private _inviteList: IInvitation[] = [];

  private _isLoading = false;

  // Filters

  private _roleFilterValueList: ISelectOption[] = [];
  private _statusFilterValueList: ISelectOption[] = STATUS_LIST;

  private _nameFilterValue = '';
  private _statusFilterValue: ISelectOption = null;
  private _roleFilterValue: ISelectOption = null;

  // Pagination

  private _page = 0;
  private _pageSize = 20;
  private _totalElements = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get inviteList() {
    return this._inviteList;
  }

  get isLoading() {
    return this._isLoading;
  }

  get nameFilterValue() {
    return this._nameFilterValue;
  }

  get statusFilterValue() {
    return this._statusFilterValue;
  }

  get roleFilterValue() {
    return this._roleFilterValue;
  }

  get roleValueList(): ISelectOption[] {
    return this._roleFilterValueList;
  }

  get statusValueList(): ISelectOption[] {
    return this._statusFilterValueList;
  }

  get page() {
    return this._page;
  }

  get pageSize() {
    return this._pageSize;
  }

  get totalElements() {
    return this._totalElements;
  }

  setInviteList = (newInviteList: IInvitation[]) => {
    this._inviteList = newInviteList;
  };

  setIsLoading = (state?: boolean) => {
    this._isLoading = state;
  };

  setRoleFilterValueList = (roleList: ISelectOption[]) => {
    this._roleFilterValueList = roleList;
  };

  setRoleFilterValue = (value: ISelectOption) => {
    this._roleFilterValue = value;
  };

  setNameFilterValue = (value: string) => {
    this._nameFilterValue = value;
  };

  setStatusFilterValue = (value: ISelectOption) => {
    runInAction(() => {
      this._statusFilterValue = value;
    });
  };

  setPage = (page: number) => {
    this._page = page;
  };

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  setTotalElements = (count: number) => {
    this._totalElements = count;
  };

  clearInviteList = () => {
    this._inviteList = [];
  };

  clearIsLoading = () => {
    this._isLoading = false;
  };

  clearStatusFilterValue = () => {
    this._statusFilterValue = null;
  };

  clearPage = () => {
    this._page = 0;
  };

  clearPagination = () => {
    this.clearPage();
    this._pageSize = 20;
    this._totalElements = 0;
  };

  clearFilters = () => {
    this._nameFilterValue = '';
    this._roleFilterValue = null;
    this.clearStatusFilterValue();
  };

  clearStore = () => {
    this.clearInviteList();
    this.setIsLoading();
    this.clearFilters();
    this.clearPagination();
  };
}

export default OrganizationInvitesStore;
