import {
  ICreateOrgIntegraCropwiseConfig,
  IUpdateOrgIntegraCropwiseConfig,
} from '../../../../../../../../../../api/models/integra/config.model';
import { ERequestStatus } from '../../../../../../../../../shared/types/requests';
import {
  Axios,
  TypeApiRequest,
  lazyInject,
  provide,
} from '../../../../../../../../../shared/utils';
import { EOrgIntegraCropwiseFieldAutoMapMode } from '../../modules/OrgIntegraCropwiseFields/models';
import { OrgIntegraCropwiseStore } from '../stores';

@provide.transient()
class OrgIntegraCropwiseService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrgIntegraCropwiseStore)
  protected store: OrgIntegraCropwiseStore;

  fetchConfigById = (organizationId: string) => {
    return this.axios.api
      .getOrgIntegraCropwiseConfigByOrgId({ organizationId }, { omit: ['organizationId'] })
      .then(data => {
        if (data) {
          this.store.setOrgIntegraConfig(data);

          this.store.setConfigRequestStatus(ERequestStatus.Fulfilled);
          this.store.setConfigExists(true);

          return data;
        } else {
          this.store.setConfigRequestStatus(ERequestStatus.Rejected);
          this.store.setConfigExists(false);
        }
      })
      .catch(e => {
        this.store.setConfigRequestStatus(ERequestStatus.Rejected);
        this.store.setConfigExists(false);

        return e;
      });
  };

  createConfig = (data: ICreateOrgIntegraCropwiseConfig) => {
    return this.axios.api.createOrgIntegraCropwiseConfig(data);
  };

  updateConfig = (data: Partial<IUpdateOrgIntegraCropwiseConfig>) => {
    return this.axios.api.updateOrgIntegraCropwiseConfig(data);
  };

  deleteConfig = (payload: TypeApiRequest<'deleteOrgIntegraCropwiseConfig'>) => {
    return this.axios.api.deleteOrgIntegraCropwiseConfig(payload);
  };

  fetchFieldList = (payload: TypeApiRequest<'getOrgIntegraCropwiseFieldListByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseFieldListByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  createField = (payload: TypeApiRequest<'createOrgIntegraCropwiseField'>) => {
    return this.axios.api.createOrgIntegraCropwiseField(payload);
  };

  deleteFieldList = (payload: TypeApiRequest<'deleteOrgIntegraCropwiseFieldList'>) => {
    return this.axios.api.deleteOrgIntegraCropwiseFieldList(payload);
  };

  fetchEmployeeList = (payload: TypeApiRequest<'getOrgIntegraCropwiseEmployeeListByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseEmployeeListByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  createEmployee = (payload: TypeApiRequest<'createOrgIntegraCropwiseEmployee'>) => {
    return this.axios.api.createOrgIntegraCropwiseEmployee(payload);
  };

  updateEmployee = (payload: TypeApiRequest<'updateOrgIntegraCropwiseEmployee'>) => {
    return this.axios.api.updateOrgIntegraCropwiseEmployee(payload);
  };

  deleteEmployeeList = (payload: TypeApiRequest<'deleteOrgIntegraCropwiseEmployeeList'>) => {
    return this.axios.api.deleteOrgIntegraCropwiseEmployeeList(payload);
  };

  fetchChecklistList = (payload: TypeApiRequest<'getOrgIntegraCropwiseChecklistByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseChecklistByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  fetchCulturesList = (payload: TypeApiRequest<'getOrgIntegraCropwiseCulturesByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseCulturesByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  createCulture = (payload: TypeApiRequest<'createOrgIntegraCropwiseCulture'>) => {
    return this.axios.api.createOrgIntegraCropwiseCulture(payload);
  };

  updateCulture = (payload: TypeApiRequest<'updateOrgIntegraCropwiseCulture'>) => {
    return this.axios.api.updateOrgIntegraCropwiseCulture(payload);
  };

  deleteCultureList = (payload: TypeApiRequest<'deleteOrgIntegraCropwiseCultureList'>) => {
    return this.axios.api.deleteOrgIntegraCropwiseCultureList(payload);
  };

  fetchAttributeListList = (
    payload: TypeApiRequest<'getOrgIntegraCropwiseAttributeListByOrgId'>
  ) => {
    return this.axios.api.getOrgIntegraCropwiseAttributeListByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  fetchChecklistInstanceList = (
    payload: TypeApiRequest<'getOrgIntegraCropwiseChecklistInstancesByOrgId'>
  ) => {
    return this.axios.api.getOrgIntegraCropwiseChecklistInstancesByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  fetchErrorMessageList = (
    payload: TypeApiRequest<'getOrgIntegraCropwiseErrorMessagesByOrgId'>
  ) => {
    return this.axios.api.getOrgIntegraCropwiseErrorMessagesByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  pushOrgIntegraCropwiseMessage = (payload: TypeApiRequest<'pushOrgIntegraCropwiseMessage'>) => {
    return this.axios.api.pushOrgIntegraCropwiseMessage(payload);
  };

  deleteErrorMessageList = (
    payload: TypeApiRequest<'deleteOrgIntegraCropwiseErrorMessageList'>
  ) => {
    return this.axios.api.deleteOrgIntegraCropwiseErrorMessageList(payload);
  };

  fetchPhotoList = (payload: TypeApiRequest<'getOrgIntegraCropwisePhotoList'>) => {
    return this.axios.api.getOrgIntegraCropwisePhotoList(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  syncCropwiseFieldsByOrgId = (organizationId: string) => {
    return this.axios.api.syncOrgIntegraCropwiseFieldListByOrgId(
      { organizationId },
      { omit: ['organizationId'] }
    );
  };

  automapCropwiseFieldsByOrgId = (
    organizationId: string,
    mode: EOrgIntegraCropwiseFieldAutoMapMode
  ) => {
    return this.axios.api.automapOrgIntegraCropwiseFieldListByOrgId(
      { organizationId, mode },
      { omit: ['organizationId'] }
    );
  };

  getOrgIntegraCropwiseFieldList = (payload: TypeApiRequest<'getOrgIntegraCropwiseFieldList'>) => {
    return this.axios.api.getOrgIntegraCropwiseFieldList(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  getOrgIntegraAssistanceFieldList = (
    payload: TypeApiRequest<'getOrgIntegraAssistanceFieldList'>
  ) => {
    return this.axios.api.getOrgIntegraAssistanceFieldList(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  fetchMeasurements = (payload: TypeApiRequest<'getOrgIntegraCropwiseMeasurementsByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseMeasurementsByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  fetchErrorFileList = (payload: TypeApiRequest<'getOrgIntegraCropwiseErrorFilesByOrgId'>) => {
    return this.axios.api.getOrgIntegraCropwiseErrorFilesByOrgId(payload, {
      query: { size: payload.size, page: payload.page },
      omit: ['size', 'page'],
    });
  };

  deleteErrorFileList = (payload: TypeApiRequest<'deleteOrgIntegraCropwiseErrorFileList'>) => {
    return this.axios.api.deleteOrgIntegraCropwiseErrorFileList(payload);
  };
}

export default OrgIntegraCropwiseService;
