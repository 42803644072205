import { TypeUserAdmin } from '../../models/user.admin.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size?: number;
  page?: number;
  fullName?: string;
  notCurrentUser?: boolean;
  notRoleId?: string;
  notOwnerOrganizationId?: string;
};

type TypeResponse = { content: TypeUserAdmin[] } & TypeResponsePageable;

type TypeError = {};

export const getScoutList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/users/scouts`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
