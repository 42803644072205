import { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { EOrgIntegraAgrosignalRoutes, EOrgIntegraAgrosignalTabs } from '../routers';

const useCurrentTab = () => {
  const [currentTab, setCurrentTab] = useState<EOrgIntegraAgrosignalTabs>(
    EOrgIntegraAgrosignalTabs.Organization
  );

  const location = useLocation();

  const getPath = (route: EOrgIntegraAgrosignalRoutes) => {
    return `/organizations/:organizationId/integra/agrosignal/${route}`;
  };

  const isOrganizationRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.Organization));
  const isEmployeesRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.Employees));
  const isFieldsRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.Fields));
  const isChecklistsRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.Checklists));
  const isChecklistInstancesRoute = useRouteMatch(
    getPath(EOrgIntegraAgrosignalRoutes.ChecklistInstances)
  );
  const isMessageErrorRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.MessagesError));
  const isFileErrorRoute = useRouteMatch(getPath(EOrgIntegraAgrosignalRoutes.FilesError));

  useEffect(() => {
    if (isEmployeesRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.Employees);
    } else if (isFieldsRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.Fields);
    } else if (isChecklistsRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.Checklists);
    } else if (isChecklistInstancesRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.ChecklistInstances);
    } else if (isMessageErrorRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.MessagesError);
    } else if (isFileErrorRoute) {
      setCurrentTab(EOrgIntegraAgrosignalTabs.FilesError);
    } else {
      setCurrentTab(EOrgIntegraAgrosignalTabs.Organization);
    }
  }, [
    location,
    isOrganizationRoute,
    isEmployeesRoute,
    isFieldsRoute,
    isChecklistsRoute,
    isChecklistInstancesRoute,
    isMessageErrorRoute,
    isFileErrorRoute,
  ]);

  return { currentTab, setCurrentTab };
};

export { useCurrentTab };
