import { TabPanel as MTabPanel } from '@mui/lab';
import styled from 'styled-components';

const Form = styled.form`
  margin-top: 24px;
`;

const TabPanel = styled(MTabPanel)`
  &[role='tabpanel'] {
    padding: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 8px;
`;

const Styled = {
  Form,
  TabPanel,
  ButtonGroup,
};

export default Styled;
