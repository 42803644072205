import { isEmpty } from 'lodash';

import { TypeOrganization } from '../../../../../api/models/organization.model';

export const getValues = (organization: TypeOrganization) => ({
  name: organization.name || '',
  ogrn: organization.OGRN || '',
  okved: organization.OKVED || '',
  okpo: organization.OKPO || '',
  kpp: organization.KPP || '',
  inn: organization.INN || '',
  addressJur: !isEmpty(organization.addressJur) ? organization.addressJur.addressfull : '',
  addressFact: !isEmpty(organization.addressFact) ? organization.addressFact.addressfull : '',
  contact: organization.contact || '',
  email: organization.email || '',
  phone: organization.phone || '',
  logo: organization.logo ? organization.logo.id : '',
  organizationType: !isEmpty(organization.type) ? organization.type : null,
});
