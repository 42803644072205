import { useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Box, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useModal } from '@farmlink/farmik-ui';

import {
  OrgIntegraCropwiseAttributes,
  OrgIntegraCropwiseChecklistInstances,
  OrgIntegraCropwiseChecklists,
  OrgIntegraCropwiseCultures,
  OrgIntegraCropwiseEmployees,
  OrgIntegraCropwiseErrorFile,
  OrgIntegraCropwiseErrorMessage,
  OrgIntegraCropwiseFields,
  OrgIntegraCropwiseMeasurements,
  OrgIntegraCropwiseOrganization,
  OrgIntegraCropwisePhotos,
} from '../modules';
import { Layout } from '../../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../../style';
import { getOrganizationMenu } from '../../../../../../../../shared/menu';
import useOrgIntegraNavigation from '../../../hooks/useOrgIntegraNavigation';
import { useCurrentTab } from '../hooks';
import { useStore } from '../../../../../../../../shared/utils';
import { beforeDeleteModalOrgIntegra } from '../../../modals';
import { OrgIntegraCropwiseController, OrgIntegraCropwiseService } from '../mobx';
import { cultureListModalConfig } from '../modules/OrgIntegraCropwiseChecklists/modals/CultureList/CultureListModal.config';
import { previewPhotoModalConfig } from '../modules/OrgIntegraCropwisePhotos/modals';

import { EOrgIntegraCropwiseRoutes, EOrgIntegraCropwiseTabs } from './OrgIntegraCropwise.routes';

const OrgIntegraCropwiseRouter = () => {
  const { path } = useRouteMatch();
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();
  const controller = useStore(OrgIntegraCropwiseController);
  const service = useStore(OrgIntegraCropwiseService);

  const { navigateToIntegrations } = useOrgIntegraNavigation();
  const { currentTab } = useCurrentTab();
  const { registerModalList } = useModal();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  useEffect(() => {
    controller.fetchConfig(organizationId).then(() => {
      service.fetchEmployeeList({ organizationId, page: 0, size: 1000 });
    });

    registerModalList(
      [beforeDeleteModalOrgIntegra, cultureListModalConfig, previewPhotoModalConfig],
      'OrgIntegraCropwise'
    );

    return () => {
      controller.clearOnUnmount();
    };
  }, []);

  const getPath = (route: EOrgIntegraCropwiseRoutes) => {
    return generatePath(`${path}/${route}`, { organizationId });
  };

  const getRoutePath = (route: EOrgIntegraCropwiseRoutes) => {
    return `${path}/${route}`;
  };

  const onTabChange = (tabRoute: EOrgIntegraCropwiseRoutes) => history.push(getPath(tabRoute));

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex">
              <Tooltip title="Назад к конфигурации">
                <IconButton
                  onClick={navigateToIntegrations}
                  sx={{ maxHeight: '28px', maxWidth: '28px' }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <H1>Интеграция. Cropwise</H1>
            </Box>
          </TopSection>
        </HeaderWrapper>
        <MainBody>
          <Tabs variant="scrollable" value={currentTab} sx={{ marginBottom: 2, maxWidth: '75vw' }}>
            <Tab
              label="Организация"
              value={EOrgIntegraCropwiseTabs.Organization}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Organization);
              }}
            />
            <Tab
              label="Сотрудники"
              value={EOrgIntegraCropwiseTabs.Employees}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Employees);
              }}
            />
            <Tab
              label="Поля"
              value={EOrgIntegraCropwiseTabs.Fields}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Fields);
              }}
            />
            <Tab
              label="Культуры"
              value={EOrgIntegraCropwiseTabs.Cultures}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Cultures);
              }}
            />
            <Tab
              label="Шаблоны"
              value={EOrgIntegraCropwiseTabs.Checklists}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Checklists);
              }}
            />
            <Tab
              label="Атрибуты"
              value={EOrgIntegraCropwiseTabs.Attributes}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Attributes);
              }}
            />
            <Tab
              label="Чек-лист точки"
              value={EOrgIntegraCropwiseTabs.ChecklistInstances}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.ChecklistInstances);
              }}
            />
            <Tab
              label="Измерения"
              value={EOrgIntegraCropwiseTabs.Measurements}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Measurements);
              }}
            />
            <Tab
              label="Фото"
              value={EOrgIntegraCropwiseTabs.Photos}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.Photos);
              }}
            />
            <Tab
              label="Ошибки обработки сообщений"
              value={EOrgIntegraCropwiseTabs.MessagesError}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.MessagesError);
              }}
            />
            <Tab
              label="Ошибки обработки фото"
              value={EOrgIntegraCropwiseTabs.FilesError}
              onClick={() => {
                onTabChange(EOrgIntegraCropwiseRoutes.FilesError);
              }}
            />
          </Tabs>

          <Switch>
            <Route
              path={getPath(EOrgIntegraCropwiseRoutes.Organization)}
              children={<OrgIntegraCropwiseOrganization />}
            />

            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Employees)}
              children={<OrgIntegraCropwiseEmployees />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Fields)}
              children={<OrgIntegraCropwiseFields />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Cultures)}
              children={<OrgIntegraCropwiseCultures />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Checklists)}
              children={<OrgIntegraCropwiseChecklists />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Attributes)}
              children={<OrgIntegraCropwiseAttributes />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.ChecklistInstances)}
              children={<OrgIntegraCropwiseChecklistInstances />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Measurements)}
              children={<OrgIntegraCropwiseMeasurements />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.Photos)}
              children={<OrgIntegraCropwisePhotos />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.MessagesError)}
              children={<OrgIntegraCropwiseErrorMessage />}
            />
            <Route
              path={getRoutePath(EOrgIntegraCropwiseRoutes.FilesError)}
              children={<OrgIntegraCropwiseErrorFile />}
            />

            <Redirect to={getRoutePath(EOrgIntegraCropwiseRoutes.Organization)} />
          </Switch>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export { OrgIntegraCropwiseRouter };
