import { GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import moment from 'moment';

import { IPogodaVPoleImportOperationModel } from '../../../../../../../../api/models/importOperation.model';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { pogodaVPoleHistoryGridColumns } from '../../configs';
import { useStore } from '../../../../../../../shared/utils';
import { ReportService } from '../../../../../../../services';
import { IPogodaVPoleHistoryFilter } from '../../components/OrganizationReportsPogodavpole/models';

const usePogodaVPoleReportHistory = () => {
  const service = useStore(ReportService);
  const { setNotification } = useNotificator();

  const [page, _setPage] = useState(0);
  const [size, _setSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0);
  const [filterState, setFilterState] = useState<IPogodaVPoleHistoryFilter>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [valueList, setValueLst] = useState<IPogodaVPoleImportOperationModel[]>([]);

  const gridColumnConfig = useMemo<GridColDef<IPogodaVPoleImportOperationModel>[]>(
    () => pogodaVPoleHistoryGridColumns,
    []
  );

  const parseDateToString = (date: Date): string => moment(date).format('YYYY-MM-DD');

  const setPage = (_page: number) => {
    _setPage(_page);

    fetchHistory(filterState, _page);
  };

  const setSize = (_size: number) => {
    _setSize(_size);

    fetchHistory(filterState, null, _size);
  };

  const fetchHistory = (
    filterData: IPogodaVPoleHistoryFilter,
    forcedPage?: number,
    forcedSize?: number
  ) => {
    setIsLoading(true);
    service
      .getPogodaVPoleReportImportOperationList({
        ...filterData,
        ...(filterData?.fromDate && {
          fromDate: parseDateToString(moment(filterData.fromDate).toDate()),
        }),
        ...(filterData?.toDate && {
          toDate: parseDateToString(moment(filterData.toDate).toDate()),
        }),
        page: forcedPage ?? page,
        size: forcedSize ?? size,
      })
      .then(response => {
        setValueLst(response.content);
        setTotalElements(response.totalElements);
        setFilterState(filterData);
      })
      .catch(error => {
        console.error(error);
        setNotification(getNotificatorProps('Ошибка получения истории', ENotificationStyles.Error));
      })
      .finally(() => setIsLoading(false));
  };

  return {
    page,
    setPage,
    size,
    setSize,
    totalElements,
    isLoading,
    valueList,
    gridColumnConfig,
    fetchHistory,
  };
};

export { usePogodaVPoleReportHistory };
