import { useFormikContext } from 'formik';
import { Switch as MSwitch } from '@mui/material';
import { FC, memo } from 'react';
import { Box } from '@mui/system';

import Styled from './styled';

const SwitchInput: FC<{
  itemKey: string;
  value?: boolean;
  disabled?: boolean;
  attributeFormItemsScheme: any;
  onChangeHandler?: (value: any) => void;
}> = ({ itemKey, disabled, attributeFormItemsScheme, onChangeHandler }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const error = errors?.[itemKey];

  return (
    <Box display="flex" flexDirection="column">
      <Styled.SwitchLabel>
        <MSwitch
          name={itemKey}
          onChange={(e, checked) => {
            setFieldValue(itemKey, checked);
            onChangeHandler?.(checked);
          }}
          checked={Boolean(values[itemKey]) || false}
          disabled={attributeFormItemsScheme[itemKey]?.disabled || disabled}
        />
        {attributeFormItemsScheme[itemKey]?.label}
      </Styled.SwitchLabel>
      {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
    </Box>
  );
};

export default memo(SwitchInput);
