import { TypeApiRoute } from '../../models/type.api.request';
import { TypeNews } from '../../models/news.model';
import { EStatus } from '../../models/status.model';

type TypeRequest = {
  newsId: string;
  newsStatus: EStatus;
  newsTitle: string;
  pictureSmall?: string;
  pictureBig?: string;
  newsDescription: string;
  newsContent: string;
  pinned: boolean;
  publicationDate?: string;
  tags: string[];
};

type TypeResponse = TypeNews;

type TypeError = {
  response?: {
    data?: { errors?: Array<{ source: any; title: string }> };
  };
};

export const updateNewsItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/${request.newsId}`,
  headers: {},
  method: 'PUT',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
