import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const DELETE_ROLE_MODAL_ID = 'delete-role-modal';

const deleteRoleModalConfig: TModalConfig = {
  id: DELETE_ROLE_MODAL_ID,
  name: DELETE_ROLE_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value:
      'Вы уверены, что хотите удалить приглашение? Всем сотрудникам этой роли будет назначена роль «Сотрудник»',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { DELETE_ROLE_MODAL_ID, deleteRoleModalConfig };
