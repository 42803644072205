import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { CultureTable } from '../components';
import { cultureModalConfig } from '../modals';

const OrgIntegraCropwiseCultures = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList([cultureModalConfig], 'OrgIntegraCropwiseCultures');
  }, []);

  return (
    <Box>
      <CultureTable />
    </Box>
  );
};

export default OrgIntegraCropwiseCultures;
