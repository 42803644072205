import { IOrgIntegraAgrosignalErrorFileUpdate } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalErrorMessage/models/orgIntegraAgrosignalErrorMessage';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraAgrosignalErrorFileUpdate> & { id: string };

type TypeResponse = string;

type TypeError = {};

export const updateOrgIntegraAgrosignalErrorFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: TypeRequest) => `/api/integra-as/integra/fileError/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
