import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import moment, { Moment } from 'moment';

import { TypeBasis } from '../../../api/models/basis.model';
import { TypeCulture } from '../../../api/models/culture.model';
import { TypeCultureClass } from '../../../api/models/cultureClass.model';
import { TypeCurrency } from '../../../api/models/currency.model';
import { TypeEconomicRegion } from '../../../api/models/economicRegion.model';
import { TypeProvider } from '../../../api/models/provider.model';
import { TypeQuote } from '../../../api/models/quotes.model';
import { TypeUnit } from '../../../api/models/units.model';
import { Axios, lazyInject, provide } from '../../shared/utils';

export type TypeQuoteSimple = {
  id?: string;
  cultureId: string;
  cultureClassId: string;
  basisId: string;
  economicRegionId: string;
  currencyId: string;
  providerId: string;
  unitsId: string;
  qualityMin?: number;
  qualityMax?: number;
  price: number;
  vat?: boolean;
  dateOfObs?: string;
};

@provide.singleton()
export class AddQuotesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  addingQuotes: TypeQuoteSimple[] = [];

  cultureList: TypeCulture[] = [];

  cultureClassList: TypeCultureClass[] = [];

  basisList: TypeBasis[] = [];

  economicRegionsList: TypeEconomicRegion[] = [];

  currencyList: TypeCurrency[] = [];

  unitsList: TypeUnit[] = [];

  providersList: TypeProvider[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setAddingQuotesList = (addingQuotes: TypeQuoteSimple[]) => {
    this.addingQuotes = addingQuotes;
  };

  addQuote = (quote: TypeQuoteSimple) => {
    const updatedAddingQuotes = [...this.addingQuotes];
    updatedAddingQuotes.push(quote);
    this.addingQuotes = updatedAddingQuotes;
  };

  onChangeQuote = (quote: TypeQuoteSimple, index: number) => {
    const modifiedQuotesToAdd = this.addingQuotes.slice();
    modifiedQuotesToAdd[index] = {
      ...quote,
    };
    this.addingQuotes = modifiedQuotesToAdd;
  };

  clearStore = () => {
    this.addingQuotes = [];
    this.basisList = [];
    this.cultureClassList = [];
    this.economicRegionsList = [];
    this.cultureList = [];
    this.currencyList = [];
    this.providersList = [];
    this.unitsList = [];
  };

  setCultureList = (cultureList: TypeCulture[]) => {
    runInAction(() => {
      this.cultureList = cultureList;
    });
  };

  setBasisList = (basisList: TypeBasis[]) => {
    runInAction(() => {
      this.basisList = basisList;
    });
  };

  setCultureClassList = (cultureClassList: TypeCultureClass[]) => {
    runInAction(() => {
      this.cultureClassList = cultureClassList;
    });
  };

  setEconomicRegionsList = (economicRegionsList: TypeEconomicRegion[]) => {
    runInAction(() => {
      this.economicRegionsList = economicRegionsList;
    });
  };

  setProvidersList = (providersList: TypeProvider[]) => {
    runInAction(() => {
      this.providersList = providersList;
    });
  };

  setCurrencyList = (currencyList: TypeCurrency[]) => {
    runInAction(() => {
      this.currencyList = currencyList;
    });
  };

  setUnitsList = (unitsList: TypeUnit[]) => {
    runInAction(() => {
      this.unitsList = unitsList;
    });
  };

  fetchBasis = async () => {
    try {
      const response = await this.axios.api.getBasisList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setBasisList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureList = async () => {
    try {
      const response = await this.axios.api.getCultureList({
        attrs: { useInQuotes: true },
        hasAttributes: true,
        originalOnly: true,
        latestVersion: true,
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureClassList = async () => {
    try {
      const response = await this.axios.api.getCultureClassList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureClassList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCurrencyList = async () => {
    try {
      const response = await this.axios.api.getCurrencyList({
        page: 0,
        size: 10000,
        latestVersion: true,
      });
      if (response.content) {
        this.setCurrencyList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchEconomicRegionsList = async () => {
    try {
      const response = await this.axios.api.getEconomicRegionsList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setEconomicRegionsList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchProvidersList = async () => {
    try {
      const response = await this.axios.api.getProvidersList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setProvidersList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchUnitsList = async () => {
    try {
      const response = await this.axios.api.getUnitsList({
        page: 0,
        size: 10000,
        latestVersion: true,
      });
      if (response.content) {
        this.setUnitsList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  onPublishQuotes = async () => {
    try {
      await this.axios.api.createQuotesBatch(this.addingQuotes);
    } catch (e) {
      console.error(e);
    }
  };

  deleteAddingQuote = (index: number) => {
    const updatedAddingQuotes = [...this.addingQuotes];
    updatedAddingQuotes.splice(index, 1);
    this.addingQuotes = updatedAddingQuotes;
  };

  importQuotes = async (file, onSuccess, onReject) => {
    try {
      const formData = new FormData();
      formData.append('source', file);
      this.setIsLoading(true);
      // ToDo: Перенести в api
      const response = await axios.post(
        '/api/da-quotes/quotes/import',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'access-token': this.axios.accessToken(),
          },
        }
      );
      this.setIsLoading(false);
      onSuccess();
      this.setAddingQuotesList(response.data as TypeQuoteSimple[]);
    } catch (e) {
      onReject();
      console.error(e);
    }
  };
}
