import styled from 'styled-components';

import { FontSizes } from '../../../../../../../../../../../../../../constants/sizes';

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-evenly;
  min-height: 300px;
`;

const Column = styled.div`
  width: 100%;
`;

const ColumnTitle = styled.h6`
  ${FontSizes.largeBold};
  text-align: center;
  margin: 0 0 12px;
`;

const Styled = {
  ColumnGroup,
  Column,
  ColumnTitle,
};

export default Styled;
