import { action, makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';

import { provide } from '../../../shared/utils';
import { ISelectOptionExtended } from '../../../../types/selectOption';

import { IChecklistAttribute } from './../../../../api/models/checklist.model';
import {
  IAttribute,
  IChecklistAttributeEnumItemExtended,
  IFileLinkInstanceClientOnly,
  IUserDictionaryItemValue,
} from './../../../../api/models/checklist.attribute.model';

@provide.singleton()
export class ChecklistAttributesStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _selectedAttribute: IChecklistAttribute = null;
  private _checklistAttributeListByStageId: Map<string, IChecklistAttribute[]> = new Map();
  private _checklistAttributeListWithoutStage: IChecklistAttribute[] = [];
  private _currentChecklistAttributeEdit: IChecklistAttribute = null;
  private _currentChecklistAttributeEditFileList: IFileLinkInstanceClientOnly[] = [];
  private _currentChecklistAttributeActiveFileId: string | string[] = null;
  private _currentChecklistBasicAttributeIdListByStage: Map<string, Set<string>> = new Map();

  private _currentChecklistAttributeUserDictionaryLinkValueList: IChecklistAttributeEnumItemExtended[] = [];
  private _currentChecklistAttributeActiveOrg: ISelectOptionExtended = null;

  get selectedAttribute() {
    return this._selectedAttribute;
  }

  get checklistAttributeListByStageId() {
    return this._checklistAttributeListByStageId;
  }

  get checklistAttributeListWithoutStage() {
    return this._checklistAttributeListWithoutStage;
  }

  get currentChecklistAttributeEdit() {
    return this._currentChecklistAttributeEdit;
  }

  get currentChecklistAttributeEditFileList() {
    return this._currentChecklistAttributeEditFileList;
  }

  get currentChecklistAttributeActiveFileId() {
    return this._currentChecklistAttributeActiveFileId;
  }

  get currentChecklistAttributeUserDictionaryLinkValueList() {
    return this._currentChecklistAttributeUserDictionaryLinkValueList;
  }

  get currentChecklistAttributeActiveOrgId() {
    return this._currentChecklistAttributeActiveOrg;
  }

  get currentChecklistBasicAttributeIdListByStage() {
    return this._currentChecklistBasicAttributeIdListByStage;
  }

  getAttributeListInStage = (stageId: string) => {
    return this._checklistAttributeListByStageId
      .get(stageId)
      ?.filter(attribute => attribute.checkListId === stageId);
  };

  getExistsBasicAttributeIdListInStage = (stageId: string) => {
    return this._currentChecklistBasicAttributeIdListByStage.get(stageId);
  };

  setSelectedAttribute = (attribute: IChecklistAttribute) => {
    this._selectedAttribute = attribute;
  };

  setChecklistAttributeListByStageId = (map: typeof this._checklistAttributeListByStageId) => {
    this._checklistAttributeListByStageId = map;
  };

  setChecklistAttributeListWithoutStage = (
    collection: typeof this._checklistAttributeListWithoutStage
  ) => {
    this._checklistAttributeListWithoutStage = collection;
  };

  setCurrentChecklistAttributeEdit = (attribute: IChecklistAttribute) => {
    this._currentChecklistAttributeEdit = attribute;
  };

  changeSelectedAttributeValues = (changes: Partial<IChecklistAttribute>) => {
    this._selectedAttribute = { ...this._selectedAttribute, ...changes };
  };

  addAttributeListByStageId = action((stageId: string, attributeList: IChecklistAttribute[]) => {
    runInAction(() => {
      this._checklistAttributeListByStageId.set(stageId, attributeList);
    });
  });

  addAttributeWithoutStage = action((attribute: IChecklistAttribute) => {
    runInAction(() => {
      this._checklistAttributeListWithoutStage.push(attribute);
    });
  });

  setCurrentChecklistAttributeEditFileList = (fileList: IFileLinkInstanceClientOnly[]) => {
    runInAction(() => {
      this._currentChecklistAttributeEditFileList = fileList;
    });
  };

  setCurrentChecklistAttributeActiveFileId = (id: string | string[]) => {
    this._currentChecklistAttributeActiveFileId = id;
  };

  setCurrentChecklistAttributeUserDictionaryLinkValueList = (list: IUserDictionaryItemValue[]) => {
    this._currentChecklistAttributeUserDictionaryLinkValueList = list;
  };

  setCurrentChecklistAttributeActiveOrg = (org: ISelectOptionExtended) => {
    this._currentChecklistAttributeActiveOrg = org;
  };

  addCurrentChecklistBasicAttributeIdListByStage = (
    stageId: string | 'noStage',
    basicAttributeIdList: string[],
    isReplace?: boolean
  ) => {
    const attributeIdList = this._currentChecklistBasicAttributeIdListByStage.get(stageId);

    if (!attributeIdList || isReplace) {
      this._currentChecklistBasicAttributeIdListByStage.set(stageId, new Set(basicAttributeIdList));
    } else {
      basicAttributeIdList.forEach(id => {
        attributeIdList.add(id);
      });
    }
  };

  isBasicAttributeExistedInStage = (stageId: string, basicAttributeId: string) => {
    return this._currentChecklistBasicAttributeIdListByStage.get(stageId)?.has(basicAttributeId);
  };

  removeCurrentChecklistBasicAttributeIdListByStageAndId = (
    stageId: string,
    basicAttributeId: string
  ) => {
    const attributeIdList = this._currentChecklistBasicAttributeIdListByStage.get(stageId);

    attributeIdList?.delete(basicAttributeId);
  };

  clearCurrentChecklistAttributeUserDictionaryLinkValueList = () => {
    this._currentChecklistAttributeUserDictionaryLinkValueList = [];
  };

  clearCurrentChecklistAttributeEditFileList = () => {
    this._currentChecklistAttributeEditFileList = [];
    this._currentChecklistAttributeActiveFileId = null;
  };

  clearCurrentChecklistAttributeEdit = () => {
    this._currentChecklistAttributeEdit = null;
  };

  clearChecklistAttributeListWithStages = () => {
    this._checklistAttributeListByStageId = new Map();
  };

  clearChecklistAttributeListWithoutStage = () => {
    this._checklistAttributeListWithoutStage = [];
  };

  clearSelectedAttribute = () => {
    this._selectedAttribute = null;
  };

  clearCurrentChecklistBasicAttributeIdListByStage = () => {
    this._currentChecklistBasicAttributeIdListByStage = new Map();
  };
}
