import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import { TypeBasis } from '../../../api/models/basis.model';
import { TypeCulture } from '../../../api/models/culture.model';
import { TypeCultureClass } from '../../../api/models/cultureClass.model';
import { TypeEconomicRegion } from '../../../api/models/economicRegion.model';
import { quotesPageSize, TypeQuote } from '../../../api/models/quotes.model';
import { Axios, lazyInject, provide } from '../../shared/utils';

import { FilterStore } from './filter.store';

@provide.singleton()
export class QuotesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  loading = false;

  page = 0;

  totalPages = 0;

  quotes: TypeQuote[] = [];

  cultureList: TypeCulture[] = [];

  cultureClassList: TypeCultureClass[] = [];

  basisList: TypeBasis[] = [];

  economicRegionsList: TypeEconomicRegion[] = [];

  get isLoading() {
    return this.loading;
  }

  constructor() {
    makeAutoObservable(this);
  }

  deleteQuote = async (id: string) => {
    await this.axios.api.deleteQuote({ id });
    this.setPage(0);
    await this.fetchQuotes();
  };

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setQuotes = (quotes: TypeQuote[], totalPages: number) => {
    runInAction(() => {
      this.quotes = quotes;
      this.totalPages = totalPages;
    });
  };

  setCultureList = (cultureList: TypeCulture[]) => {
    runInAction(() => {
      this.cultureList = cultureList;
    });
  };

  setBasisList = (basisList: TypeBasis[]) => {
    runInAction(() => {
      this.basisList = basisList;
    });
  };

  setCultureClassList = (cultureClassList: TypeCultureClass[]) => {
    runInAction(() => {
      this.cultureClassList = cultureClassList;
    });
  };

  setEconomicRegionsList = (economicRegionsList: TypeEconomicRegion[]) => {
    runInAction(() => {
      this.economicRegionsList = economicRegionsList;
    });
  };

  fetchQuotes = async () => {
    try {
      this.setLoading(true);
      const response = await this.axios.api.getQuotesFulllist({
        ...this.filterStore.filters,
        page: this.page,
        size: quotesPageSize,
        noCultureClass: this.filterStore.filters.cultureClassId === null,
      });
      this.setLoading(false);
      if (response.content) {
        this.setQuotes(response.content, response.totalPages);
        // this.setPage(this.page + 1);
      }
    } catch (e) {
      this.setLoading(false);
      console.error(e);
    }
  };

  fetchBasis = async () => {
    try {
      const response = await this.axios.api.getBasisList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setBasisList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureList = async () => {
    try {
      const response = await this.axios.api.getCultureList({
        attrs: { useInQuotes: true },
        hasAttributes: true,
        originalOnly: true,
        latestVersion: true,
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCultureClassList = async () => {
    try {
      const response = await this.axios.api.getCultureClassList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setCultureClassList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchEconomicRegionsList = async () => {
    try {
      const response = await this.axios.api.getEconomicRegionsList({
        page: 0,
        size: 10000,
      });
      if (response.content) {
        this.setEconomicRegionsList(response.content);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
