import moment from 'moment';
import { Chip, Colors } from '@farmlink/farmik-ui';
import { GridColDef } from '@mui/x-data-grid';
import { has } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import { TypeEmployee } from '../../../../../../../../../../../../../api/models/employee.model';
import { EUserPhoneStatus } from '../../../../../../../../../../../../../api/models/invitation.model';
import { TooltipedValue } from '../../../../../../../../../../../../shared/components';

const STATUS_SCHEME = {
  FIRED: {
    text: 'Исключён',
    color: 'secondary',
  },
  ACTIVE: {
    text: 'Активен',
    color: 'primary',
  },
};

const PhoneCell = styled.a`
  color: ${Colors.black};
`;

const PhoneCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Styled = { PhoneCell, PhoneCellWrapper };

const getColumns = (): GridColDef<TypeEmployee>[] => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    renderCell: params => {
      const status = has(STATUS_SCHEME, params?.value)
        ? STATUS_SCHEME[params.value]
        : { text: params?.value ?? 'Другое', color: 'default' };

      return (
        <Chip size="medium" color={status.color}>
          {status.text}
        </Chip>
      );
    },
    width: 130,
    sortable: true,
  },
  {
    field: 'userName',
    type: 'string',
    headerName: 'Имя',
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 200,
    sortable: true,
  },
  {
    field: 'phone',
    type: 'string',
    headerName: 'Номер телефона',
    renderCell: params =>
      params?.row?.phone ? (
        <Styled.PhoneCellWrapper>
          {params?.row?.phone?.phoneNumberStatus.statusId === EUserPhoneStatus.CONFIRMED ? (
            <Tooltip title="Номер подтверждён">
              <CheckIcon color="primary" />
            </Tooltip>
          ) : (
            <Tooltip title="Номер не подтверждён">
              <QuestionMarkIcon color="warning" />
            </Tooltip>
          )}
          <Styled.PhoneCell
            href={`tel:${params.row?.phone.phoneNumberCode} ${params?.row?.phone?.phoneNumber}`}
          >
            {`${params.row?.phone.phoneNumberCode} ${params?.row?.phone?.phoneNumber}`}
          </Styled.PhoneCell>
        </Styled.PhoneCellWrapper>
      ) : (
        'Не указано'
      ),
    width: 150,
    sortable: false,
  },
  {
    field: 'email',
    type: 'string',
    headerName: 'e-mail',
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    width: 200,
    sortable: false,
  },
  {
    field: 'organizationJoinDate',
    type: 'date',
    headerName: 'Дата приглашения',
    valueFormatter: params =>
      params?.value ? moment(params.value).format('DD.MM.YYYY') : 'Не указано',
    width: 150,
    sortable: false,
  },
];

export default getColumns;
