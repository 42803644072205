import { IOrgIntegraConfigCropwise } from '../../../../models/integra/config.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = { organizationId: string };

type TypeResponse = IOrgIntegraConfigCropwise;

type TypeError = {};

export const getOrgIntegraCropwiseConfigByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }) =>
    `/api/integra-cw/admin/mapping/organization/byOrganizationId/${organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
