import { EChecklistType } from '../../../../../api/models/checklist.model';
import {
  EAdvancedGridFilterItemType,
  IAdvanceGridConfig,
} from '../common/AdvancedGrid/AdvancedGrid.type';

import { ISelectOption } from './../../../../../types/selectOption';

export const getChecklistFilterConfig: (
  dataFetchHandler: IAdvanceGridConfig<'getChecklistListByParams'>['dataFetchHandler'],
  options: {
    cultureOptions: ISelectOption[];
    operationTypeOptions: ISelectOption[];
    phenophaseOptions: ISelectOption[];
    organizationOptions: ISelectOption[];
  },
  onSelectRow?: (id: string) => void,
  fetchOrganizationOnChange?: (value: string) => Promise<ISelectOption[]>
) => IAdvanceGridConfig<'getChecklistListByParams'> = (
  dataFetchHandler,
  { cultureOptions, operationTypeOptions, organizationOptions, phenophaseOptions },
  onSelectRow,
  fetchOrganizationOnChange
) => {
  return {
    dataFetchHandler,
    onSelectRow,
    gridId: 'checklists',
    filter: [
      {
        key: 'name',
        label: 'Наименование',
        type: EAdvancedGridFilterItemType.String,
      },
      { key: 'id', label: 'ID', type: EAdvancedGridFilterItemType.String },
      { key: 'publicId', label: 'publicId', type: EAdvancedGridFilterItemType.String },

      {
        key: 'operationTypeId',
        label: 'Тип операции',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: operationTypeOptions,
          },
        },
      },
      {
        key: 'cultureId',
        label: 'Культура',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: cultureOptions,
          },
        },
      },
      {
        key: 'phenophaseId',
        label: 'Фенофаза',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: phenophaseOptions,
          },
        },
      },
      {
        key: 'active',
        label: 'Статус',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            isToggler: true,
            itemList: [
              { value: 'true', label: 'Активный' },
              { value: 'false', label: 'Неактивный' },
            ],
          },
        },
      },
      {
        key: 'type',
        label: 'Тип',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: [
              { value: EChecklistType.Field, label: `Поле/${EChecklistType.Field}` },
              { value: EChecklistType.Machinery, label: `Техника/${EChecklistType.Machinery}` },
              { value: EChecklistType.Nested, label: `Вложенный/${EChecklistType.Nested}` },
              { value: EChecklistType.Point, label: `Точка/${EChecklistType.Point}` },
            ],
          },
        },
      },
      {
        key: 'isDefault',
        label: 'Дефолтный',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            isToggler: true,
            itemList: [
              { value: 'true', label: 'Да' },
              { value: 'false', label: 'Нет' },
            ],
          },
        },
      },
      {
        key: 'organizationIds',
        label: 'Название организации или ИНН',
        type: EAdvancedGridFilterItemType.Select,
        filterValueTransform: value => {
          return [value];
        },
        config: {
          select: {
            itemList: organizationOptions,
            onInputChangeSearch: fetchOrganizationOnChange,
          },
        },
      },
    ],
  };
};
