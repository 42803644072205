import { ChangeEvent, SyntheticEvent } from 'react';
import { AutocompleteChangeReason } from '@mui/material';

import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../shared/utils';
import { OrganizationInvitesStore as Store } from '../../stores';
import { STATUS_LIST } from '../../stores/OrganizationInviteStore/OrganizationInvite.store';
import { AccessService } from '../../../../../../../../services/access/access.service';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import OrganizationService from '../../../../../../../../services/organization/organization.service';
import { EInvitationStatus } from '../../../../../../../../../api/models/invitation.model';

@provide.transient()
class OrganizationInvitesController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(AccessService)
  protected accessService: AccessService;

  @lazyInject(OrganizationService)
  protected organizationService: OrganizationService;

  changeNameFilter = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.store.setNameFilterValue(event.target.value.trim());
    this.store.setPage(0);
  };

  changeStatusFilter = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = STATUS_LIST.find(item => item.value === event.target.value);

    this.store.setStatusFilterValue(value);
    this.store.setPage(0);
  };

  fetchRoleValueList = async (organizationId: string) => {
    const roleList = await this.accessService.fetchAccessRoleList({ organizationId });

    this.store.setRoleFilterValueList(
      roleList?.map(item => ({
        label: item.role.name,
        value: item.role.id,
      }))
    );
  };

  changeRoleFilter = (
    event: SyntheticEvent,
    value: NonNullable<string | ISelectOption>,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof value === 'object') {
      this.store.setRoleFilterValue(value);
    }
    this.store.setPage(0);
  };

  fetchInviteList = async (organizationId: string, sort?: string) => {
    this.store.setIsLoading(true);

    const payload: TypeApiRequest<'getOrganizationApplicationList'> = {
      organizationId,
      roleId: this.store.roleFilterValue?.value,
      status: this.store.statusFilterValue?.value as EInvitationStatus,
      page: this.store.page,
      size: this.store.pageSize,
      sort,
      ...(this.store.nameFilterValue ? { fullName: this.store.nameFilterValue } : {}),
    };

    const inviteList = await this.organizationService.fetchOrganizationApplicationList(payload);

    this.store.setInviteList(inviteList?.content ?? []);
    this.store.setTotalElements(inviteList?.totalElements ?? 0);
    this.store.setIsLoading(false);
  };

  createNewInvite = (organizationId: string, roleId: string, email: string, comment?: string) => {
    return this.organizationService.inviteUserByEmail({ email, organizationId, roleId, comment });
  };

  cancelInvite = (invitationId: string) => {
    return this.organizationService.cancelUserInvite({ invitationId });
  };

  editInvite = (invitationId: string, roleId: string, comment: string) => {
    return this.organizationService.updateUserInvite({
      invitationId,
      roleId,
      comment,
    });
  };
}

export default OrganizationInvitesController;
