import { TypeApiRoute } from '../../models/type.api.request';
import {
  TypeUserAdmin,
  EUserAdminStatus,
  EUserAdminGender,
  EUserAdminRole,
  TypeUserAdminSort,
} from '../../models/user.admin.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  id: string;
  fullName: string;
  phoneNum: string;
  email: string;
  regDateFrom?: string;
  regDateTo?: string;
  roles: EUserAdminRole[];
  statuses: EUserAdminStatus[];
  genders: EUserAdminGender[];
  size: number;
  page: number;
  sort?: TypeUserAdminSort;
};

type TypeResponse = { content: TypeUserAdmin[] } & TypeResponsePageable;

type TypeError = {};

export const getUsersAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/users/admin/search?page=${request.page}&size=${request.size}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
