import { ICropwiseConfig } from '../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationReports/components/OrganizationReportsCropwise/models';
import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  size?: number;
  page?: number;
  organizationId?: string;
};
type TypeResponse = TypeResponsePageable & { content: ICropwiseConfig[] };

export const getCropwiseReportsConfigList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-cropwise/reports/config/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
