import { useParams } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { useStore } from '../../../../../../shared/utils';
import OrgIntegraConfigService from '../mobx/services/orgIntegraConfig.service';
import { ICoreIntegrationForm } from '../models';
import { getNotificatorProps } from '../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../shared/constanst/notifications';

const useIntegraConfigAction = () => {
  const { createOrgIntegraConfig, deleteOrgIntegraConfig, updateIntegraConfig } = useStore(
    OrgIntegraConfigService
  );

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { setNotification } = useNotificator();

  const [isLoading, setIsLoading] = useState(false);

  const createConfig = (formData: Partial<ICoreIntegrationForm>) => {
    if (isLoading) return;

    setIsLoading(true);

    return createOrgIntegraConfig(formData)
      .then(() => {
        setNotification(
          getNotificatorProps('Конфигурация успешно создана', ENotificationStyles.Success)
        );
      })
      .catch((e: Error) => {
        setNotification(
          getNotificatorProps(
            e?.message ?? 'Ошибка создания конфигурации',
            ENotificationStyles.Error
          )
        );

        throw e;
      })
      .finally(() => setIsLoading(false));
  };

  const deleteConfig = () => {
    return deleteOrgIntegraConfig(organizationId)
      .then(() => {
        setNotification(
          getNotificatorProps('Конфигурация успешно удалена', ENotificationStyles.Success)
        );
      })
      .catch((e: Error) => {
        setNotification(
          getNotificatorProps(
            e?.message ?? 'Ошибка удаления конфигурации',
            ENotificationStyles.Error
          )
        );

        throw e;
      })
      .finally(() => setIsLoading(false));
  };

  const updateConfig = (formData: Partial<ICoreIntegrationForm>) => {
    return updateIntegraConfig(formData)
      .then(() => {
        setNotification(
          getNotificatorProps('Конфигурация успешно обновлена', ENotificationStyles.Success)
        );
      })
      .catch((e: Error) => {
        setNotification(
          getNotificatorProps(
            e?.message ?? 'Ошибка обновления конфигурации',
            ENotificationStyles.Error
          )
        );
        throw e;
      })
      .finally(() => setIsLoading(false));
  };

  return { createConfig, deleteConfig, updateConfig };
};

export default useIntegraConfigAction;
