import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ImportModal } from './components';

const DICTIONARY_MODAL_IMPORT = 'dictionaryModalImport';

const dictionaryImportModalConfig: TModalConfig = {
  name: null,
  title: null,
  type: EModalType.Custom,
  id: DICTIONARY_MODAL_IMPORT,
  children: ImportModal,
  closeOnClickOutside: true,
  styledProps: { $size: '600px', $modalPaddings: '30px 20px', $isFullContentWidth: true },
};

export { DICTIONARY_MODAL_IMPORT, dictionaryImportModalConfig };
