import moment from 'moment';

import { TypeUserAdmin } from '../../../../../api/models/user.admin.model';

export const getRows = (news: TypeUserAdmin[]) =>
  news.map(item => ({
    id: item.id,
    status: item.status,
    fullName: item.fullName,
    role: item.role,
    regDate: moment(item.regDate).format('DD.MM.yyyy HH:mm'),
  }));
