import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthorizationStore } from '../stores/authorization/authorization.store';
import { useStore } from '../shared/utils';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../shared/styles/dotsLoader';
import { ApplicationRoutes } from '../routes';

import { Layout, LayoutInner } from './style';
import { AuthorizationRoutes } from './routes';
import { SignInPage } from './pages/sign-in/SignInPage';

export const AuthorizationRouter: FC<{ history?: any }> = observer(() => {
  const { checkIsUserLoginned, isLoggedIn, isLoading, isLoaded } = useStore(AuthorizationStore);

  useEffect(() => {
    checkIsUserLoginned();
  }, []);

  if (isLoggedIn) {
    return <Redirect to={ApplicationRoutes.Admin} />;
  }

  if (isLoading) {
    return (
      <SpinnerLoaderWrapper>
        <SpinnerLoader data-test-id="dots-loader">
          <span />
        </SpinnerLoader>
      </SpinnerLoaderWrapper>
    );
  }

  return (
    <>
      {isLoaded && (
        <Layout>
          <LayoutInner>
            <Switch>
              <Route path={AuthorizationRoutes.signIn} component={SignInPage} />
              <Route
                path={AuthorizationRoutes.logout}
                component={() => <Redirect to={AuthorizationRoutes.signIn} />}
              />
              <Redirect to={AuthorizationRoutes.signIn} />
            </Switch>
          </LayoutInner>
        </Layout>
      )}
    </>
  );
});
