import { EFormulaFunctionAggregation, EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const NOT: IFormulaFunction = {
  name: 'NOT',
  label: 'Отрицание',
  argsType: [EFormulaType.Function],
  availableFunctionType: [
    EFormulaFunctionAggregation.EQ,
    EFormulaFunctionAggregation.GT,
    EFormulaFunctionAggregation.LT,
    EFormulaFunctionAggregation.GOE,
    EFormulaFunctionAggregation.LOE,
    EFormulaFunctionAggregation.NE,
    EFormulaFunctionAggregation.NOT,
  ],
  minArguments: 1,
  maxArguments: 1,
  primitiveType: [EFormulaReturnType.Boolean],
  returnType: [EFormulaReturnType.Boolean],
};
