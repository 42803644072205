import { FC, memo, useEffect, useRef, useState } from 'react';
import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';
import { convertFromRaw } from 'draft-js';
import moment from 'moment';
import { useNotificator } from '@farmlink/farmik-ui';

import { EReferenceType, IReference } from '../../../../../../../../api/models/reference.model';
import {
  convertStateFromHTML,
  convertStateToHTML,
  useStore,
} from '../../../../../../../shared/utils';
import { ReferenceStore } from '../../mobx';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';

import Styled from './styled';

const ReferenceForm: FC<{
  reference: IReference;
  setValue: (val: Partial<IReference>) => void;
}> = ({ reference, setValue }) => {
  const { setActiveEditorState } = useStore(ReferenceStore);

  const editorRef = useRef<TMUIRichTextEditorRef>();

  const { setNotification } = useNotificator();

  const [editorState, setEditorState] = useState<any>(null);

  useEffect(() => {
    try {
      const html = convertStateFromHTML(reference?.text);

      // Необходимо для проверки валидности получаемых HTML данных

      if (!JSON.stringify(html)?.includes('blocks')) {
        throw new Error();
      }

      setEditorState(html);
    } catch (error) {
      setNotification(
        getNotificatorProps(
          'Ошибка парсинга содержимого редактора. Контент помещён в редактор как есть',
          ENotificationStyles.Error
        )
      );
      setEditorState(convertStateFromHTML(`<p> ${reference?.text}</p>`));
    }
  }, [reference?.text]);

  const onBlur = () => {
    editorRef.current.save();
  };

  const handleSave = data => {
    const content = convertFromRaw(JSON.parse(data));

    if (!content.hasText()) {
      console.log('empty');
    }

    setActiveEditorState(convertStateToHTML(data));
  };

  const controls = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'undo',
    'redo',
    'link',
    'numberList',
    'bulletList',
    'quote',
    'clear',
    'save',
  ];

  return (
    <div>
      <TextField
        label="Путь"
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        value={reference?.path || ''}
      />

      <Box display="flex" marginTop={1} gap={1}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(reference?.active)}
              onChange={(e, checked) => setValue({ active: checked })}
            />
          }
          label="Активный"
        />

        <TextField
          label="Тип"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={reference?.type || ''}
          sx={{ margin: '8px 0' }}
        />
      </Box>

      <TextField
        label="Заголовок"
        onChange={e => setValue({ title: e.target?.value })}
        disabled={reference?.type !== EReferenceType.Modal}
        fullWidth
        value={reference?.title || ''}
        sx={{ margin: '8px 0' }}
      />

      {reference?.type === EReferenceType.Modal ? (
        <Styled.EditorWrapper>
          {/** @ts-ignore */}
          <MUIRichTextEditor
            defaultValue={editorState}
            ref={editorRef}
            inlineToolbar={true}
            onBlur={onBlur}
            onSave={handleSave}
            controls={controls}
            label="Введите текст подсказки"
          />
        </Styled.EditorWrapper>
      ) : (
        <TextField
          defaultValue={reference?.text}
          label="Содержимое подсказки"
          multiline
          fullWidth
          minRows={3}
          maxRows={6}
          onChange={e => setActiveEditorState(e.target.value)}
        />
      )}

      <Box display="flex" gap={1} marginTop={1}>
        <TextField
          label="Автор"
          fullWidth
          value={reference?.createdBy?.fullName || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Дата редактировано"
          fullWidth
          value={
            reference?.updateDate ? moment(reference?.updateDate).format('DD-MM-YYYY HH:mm') : ''
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </div>
  );
};

export default memo(ReferenceForm);
