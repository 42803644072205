export enum EAttributeUnit {
  String = 'STRING',
  Integer = 'INTEGER',
  Double = 'DOUBLE',
  Boolean = 'BOOLEAN',
}
interface IAttributeItem {
  name: string;
  units: EAttributeUnit;
  code: string;
  ownerId?: string;
  ownerPath?: string;
  ownerName?: string;
}

export type { IAttributeItem };
