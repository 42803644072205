import { Autocomplete, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';

import { ISelectOption, ISelectOptionExtended } from '../../../../types/selectOption';
import { ApiNames, TypeApiRequest, TypeApiResponse } from '../../utils';

interface IAutocompleteWithSearchProps<K extends ApiNames> {
  selectedValue: ISelectOptionExtended | string;
  setSelectedOption: (value: ISelectOption | string) => void;
  fetchHandler: (payload?: TypeApiRequest<K>) => Promise<TypeApiResponse<K>>;
  responseMappingHandler: (data: TypeApiResponse<K>) => ISelectOptionExtended[];
  searchPropertyKey?: string;
  defaultRequestPayload?: TypeApiRequest<K> & Record<string, any>;
  additionalRequestPayload?: Record<string, any>;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  isClearable?: boolean;
}

const AutocompleteWithSearch = <K extends ApiNames>({
  selectedValue,
  setSelectedOption,
  fetchHandler,
  defaultRequestPayload,
  responseMappingHandler,
  searchPropertyKey,
  label,
  placeholder,
  fullWidth,
  additionalRequestPayload,
  isClearable,
}: IAutocompleteWithSearchProps<K>) => {
  const [optionList, setOptionList] = useState<ISelectOptionExtended[]>([]);
  const [inputValue, setInputValue] = useState('');

  const onInputChange = useCallback((e, value) => {
    setInputValue(value);
  }, []);

  const handleFetch = searchValue => {
    const payload = { ...defaultRequestPayload, ...(additionalRequestPayload || {}) };

    if (searchPropertyKey) {
      payload[searchPropertyKey] = searchValue;
    }

    fetchHandler(payload).then(data => setOptionList(responseMappingHandler(data)));
  };

  const handleFetchOrganization = useCallback(debounce(handleFetch, 500), [
    additionalRequestPayload,
  ]);

  const handleOnChange = useCallback((e, value: ISelectOption | string) => {
    if (typeof value === 'object') {
      setSelectedOption(value);
    }
  }, []);

  useEffect(() => {
    handleFetchOrganization(inputValue);
  }, [inputValue, additionalRequestPayload]);

  return (
    <Autocomplete
      options={optionList}
      fullWidth={fullWidth}
      renderInput={props => (
        <TextField
          {...props}
          label={label || 'Поиск'}
          placeholder={placeholder || 'Введите значение для поиска'}
        />
      )}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'string') {
          return option === value;
        } else if (typeof value === 'string') {
          return option.value === value;
        } else {
          return option.value === value.value;
        }
      }}
      disableClearable={!isClearable}
      disablePortal
      inputValue={inputValue}
      value={selectedValue}
      onChange={handleOnChange}
      onInputChange={onInputChange}
    />
  );
};

export default AutocompleteWithSearch;
