import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Button } from '@mui/material';
import { useHistory } from 'react-router';

import { AdminRoutes } from '../../../../routes';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';

import { NewsAllList } from './components';

export const NewsList: FC = observer(() => {
  const [value, setValue] = useState<string>('1');

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const history = useHistory();
  const handleCreateNews = () => history.push(AdminRoutes.NewsCreate);

  return (
    <MainContainer>
      <HeaderWrapper>
        <TopSection>
          <H1>Новости</H1>
          <Button onClick={handleCreateNews}>Добавить новость</Button>
        </TopSection>
      </HeaderWrapper>

      <MainBody>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Все" value="1" />
              <Tab label="Мои новости" value="2" />
              <Tab label="Отложенные публикации" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <NewsAllList />
          </TabPanel>
          <TabPanel value="2">Мои новости</TabPanel>
          <TabPanel value="3">Отложенные публикации</TabPanel>
        </TabContext>
      </MainBody>
    </MainContainer>
  );
});
