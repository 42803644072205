import { Route, Switch } from 'react-router-dom';
import _, { flatMapDeep } from 'lodash';
import { useMemo } from 'react';

import { AdminRoutes } from '../../../../../../routes';
import { serviceListConfig } from '../../configs';
import { OrganizationReportsServiceList } from '../../components';
import { IServiceItem } from '../../models';

const OrganizationReportsRouter = () => {
  const getRoute = (item: IServiceItem) => {
    if (!item.childrenList || !item.childrenList.length) {
      return item;
    }
    return [item, flatMapDeep(item.childrenList, getRoute)];
  };

  const routeList = useMemo(() => flatMapDeep(serviceListConfig, getRoute), []);

  return (
    <Switch>
      {routeList.map(({ path, isGroup, component: Component }) => {
        if (isGroup) return null;

        return <Route path={path} key={path} exact component={Component} />;
      })}
      <Route path={AdminRoutes.OrganizationReports} component={OrganizationReportsServiceList} />
    </Switch>
  );
};

export default OrganizationReportsRouter;
