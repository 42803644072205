import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../shared/utils';
import {
  IRoleEmployeeCount,
  IRoleModelExtendedActions,
} from '../../../../../../../../api/models/role.model';
import { TAction, TModule } from '../../../../../../../../api/models/action.model';
import { IPaginationOptions } from '../../../../../../../shared/types/requests';
import { TypeEmployee } from '../../../../../../../../api/models/employee.model';

@provide.singleton()
class OrganizationRoleStore {
  private _isRoleListLoading = false;
  private _isModuleListLoading = false;
  private _isRoleLoading = false;
  private _isUserListLoading = false;

  private _roleItemByRoleId: Map<string, IRoleEmployeeCount> = new Map<
    string,
    IRoleEmployeeCount
  >();
  private _moduleList: TModule[] = [];

  private _currentRoleData: IRoleModelExtendedActions = null;
  private _currentRoleActionMap: Map<string, TAction> = new Map();
  private _currentRoleActiveActionSet: Set<string> = new Set();
  private _currentRoleUserList: TypeEmployee[] = [];

  private _userListGridParams: IPaginationOptions = {
    page: 0,
    pageSize: 20,
    rowCount: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isRoleListLoading() {
    return this._isRoleListLoading;
  }

  get isModalListLoading() {
    return this._isModuleListLoading;
  }

  get isRoleLoading() {
    return this._isRoleLoading;
  }

  get isUserListLoading() {
    return this._isUserListLoading;
  }

  get roleItemByRoleId() {
    return this._roleItemByRoleId;
  }

  get roleList() {
    return Array.from(this._roleItemByRoleId.values());
  }

  get moduleList() {
    return this._moduleList;
  }

  get currentRoleData() {
    return this._currentRoleData;
  }

  get currentRoleActionMap() {
    return this._currentRoleActionMap;
  }

  get currentRoleActiveActionSet() {
    return this._currentRoleActiveActionSet;
  }

  get currentRoleUserList() {
    return this._currentRoleUserList;
  }

  get userListGridParams() {
    return this._userListGridParams;
  }

  getRoleInfo = (roleId: string) => {
    return this._roleItemByRoleId.get(roleId);
  };

  getRoleActionById = (actionCode: string) => {
    return this._currentRoleActionMap.get(actionCode);
  };

  roleHasAction = (actionCode: string) => {
    return this._currentRoleActionMap.has(actionCode);
  };

  setIsRoleListLoading = (state: boolean) => {
    this._isRoleListLoading = state;
  };

  setIsModuleListLoading = (state: boolean) => {
    this._isModuleListLoading = state;
  };

  setIsRoleLoading = (state: boolean) => {
    this._isRoleLoading = state;
  };

  setIsUserListLoading = (state: boolean) => {
    this._isUserListLoading = state;
  };

  setRoleItemByRoleId = (map: typeof this._roleItemByRoleId) => {
    this._roleItemByRoleId = map;
  };

  setModuleList = (list: TModule[]) => {
    this._moduleList = list;
  };

  setCurrentRoleData = (data: IRoleModelExtendedActions) => {
    this._currentRoleData = data;
  };

  setCurrentRoleActionMap = (map: typeof this._currentRoleActionMap) => {
    this._currentRoleActionMap = map;
  };

  addCurrentRoleAction = (actionCode: string, action: TAction) => {
    this._currentRoleActionMap.set(actionCode, action);
  };

  removeCurrentRoleAction = (actionCode: string) => {
    this._currentRoleActionMap.delete(actionCode);
  };

  setCurrentRoleActiveAction = (set: typeof this._currentRoleActiveActionSet) => {
    this._currentRoleActiveActionSet = set;
  };

  changeCurrentRoleActiveAction = (actionCode: string, state: boolean) => {
    if (state) {
      this._currentRoleActiveActionSet.add(actionCode);
    } else {
      this._currentRoleActiveActionSet.delete(actionCode);
    }
  };

  updateUserListGridParams = (params: Partial<IPaginationOptions>) => {
    this._userListGridParams = { ...this._userListGridParams, ...params };
  };

  setCurrentRoleUserList = (list: TypeEmployee[]) => {
    this._currentRoleUserList = list;
  };

  clearCurrentRole = () => {
    this._currentRoleData = null;
    this._currentRoleActionMap = new Map();
    this._currentRoleActiveActionSet = new Set();
    this._currentRoleUserList = [];
  };

  clearRoleItemByRoleId = () => {
    this._roleItemByRoleId = new Map();
  };

  clearUserListGridParams = () => {
    this._userListGridParams = {
      page: 0,
      pageSize: 20,
      rowCount: 0,
    };
  };

  clearStore = () => {
    this.clearRoleItemByRoleId();
    this.clearCurrentRole();
    this.clearUserListGridParams();
  };
}

export default OrganizationRoleStore;
