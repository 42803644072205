import { AdminRoutes } from '../../../routes';
import { ReferencesRouter } from '../modules/references';
import { IServiceItem } from '../types/serviceItem.type';

const serviceConfig: IServiceItem[] = [
  {
    component: <ReferencesRouter />,
    entryPath: AdminRoutes.ReferencesService,
    title: 'Сервис подсказок',
  },
];

export default serviceConfig;
