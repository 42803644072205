import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useMemo, useRef, useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import {
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import {
  ERROR_MESSAGE_DETAILS_ID,
  ERROR_MESSAGE_MULTIPLE_PUSH_ID,
  ERROR_MESSAGE_SINGLE_PUSH_ID,
} from '../../modals';
import {
  EOrgIntegraCropwiseErrorMessageEntityType,
  EOrgIntegraCropwiseErrorMessageOperationType,
  IOrgIntegraCropwiseErrorMessage,
} from '../../models/orgIntegraCropwiseErrorMessage';
import { IOrgIntegraEmployee } from '../../../OrgIntegraCropwiseEmployees/models';

const TYPE_ENTITY_SCHEME: Record<
  EOrgIntegraCropwiseErrorMessageEntityType,
  { text: string; color: string }
> = {
  [EOrgIntegraCropwiseErrorMessageEntityType.Checklist]: { text: 'Шаблон', color: 'green' },
  [EOrgIntegraCropwiseErrorMessageEntityType.ChecklistInstance]: {
    text: 'Чек-лист',
    color: 'yellow',
  },
  [EOrgIntegraCropwiseErrorMessageEntityType.Task]: { text: 'Задача', color: 'blue' },
  [EOrgIntegraCropwiseErrorMessageEntityType.Field]: { text: 'Поле', color: 'red' },
};

const TYPE_OPERATION_SCHEME: Record<
  EOrgIntegraCropwiseErrorMessageOperationType,
  { text: string; color: string }
> = {
  [EOrgIntegraCropwiseErrorMessageOperationType.Create]: { text: 'Создание', color: 'green' },
  [EOrgIntegraCropwiseErrorMessageOperationType.Delete]: {
    text: 'Удаление',
    color: 'red',
  },
  [EOrgIntegraCropwiseErrorMessageOperationType.Update]: { text: 'Обновление', color: 'blue' },
};

const ErrorMessageTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();
  const filterConfigRef = useRef<Map<string, TOrganizationIntegraTableFilterItemUnion>>(null);

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteErrorMessageList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = (_, selectionList: string[]) => {
    openModalByModalId(
      selectionList?.length > 0 ? ERROR_MESSAGE_MULTIPLE_PUSH_ID : ERROR_MESSAGE_SINGLE_PUSH_ID,
      {
        mode: 'create',
        organizationId,
        selectionList: selectionList || null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraCropwiseErrorMessage) => {
    openModalByModalId(
      ERROR_MESSAGE_DETAILS_ID,
      {
        mode: 'update',
        organizationId,
        errorMessage: value,
      },
      () => {
        // TODO придумать более оптимальный апдейт
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraCropwiseErrorMessage> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);
      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      ...modifiedArgs,
      page,
      size,
      organizationIds: [organizationId],
    };

    return service.fetchErrorMessageList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const entityTypeList = useMemo(
    () =>
      Object.values(EOrgIntegraCropwiseErrorMessageEntityType).map(value => ({
        label: TYPE_ENTITY_SCHEME[value].text,
        value,
      })),
    []
  );

  const operationTypeList = useMemo(
    () =>
      Object.values(EOrgIntegraCropwiseErrorMessageOperationType).map(value => ({
        label: TYPE_OPERATION_SCHEME[value].text,
        value,
      })),
    []
  );

  const getType = (value: IOrgIntegraCropwiseErrorMessage['entityType']) => {
    const scheme = TYPE_ENTITY_SCHEME[value];

    return scheme?.text ?? value;
  };

  const getOperation = (value: IOrgIntegraCropwiseErrorMessage['operationType']) => {
    const scheme = TYPE_OPERATION_SCHEME[value];

    return scheme?.text ?? value;
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwiseErrorMessage>
          actionButton={{ text: 'Обработка', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterConfigRef,
            filterCollection: [
              {
                type: 'select',
                label: 'Сущность',
                propertyName: 'entityTypes',
                placeholder: 'Выберите сущность',
                optionList: entityTypeList,
                isMultiple: true,
              },
              {
                type: 'select',
                label: 'Операция',
                propertyName: 'operationTypes',
                placeholder: 'Выберите операцию',
                optionList: operationTypeList,
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID сущности “Помощник”',
                propertyName: 'entityIds',
                placeholder: 'Введите ID “Помощник”',
                isMultiple: true,
              },
              {
                type: 'select',
                label: 'Автор',
                propertyName: 'userIds',
                optionListFetchHandler: () =>
                  service.fetchEmployeeList({ organizationId, page: 0, size: 1000 }),
                optionMapHandler: (data: IOrgIntegraEmployee) => {
                  return {
                    label: data?.userName || data?.assigneeName || data?.id,
                    value: data?.id,
                    rawData: data,
                  };
                },
                isMultiple: true,
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'startTime',
                    placeholder: 'Введите дату',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'endTime',
                    placeholder: 'Введите дату',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                type: 'number',
                label: 'Максимальное количество попыток',
                propertyName: 'maxAttempts',
                placeholder: 'Введите максимальное количество попыток',
              },
            ],
          }}
          columnConfig={[
            {
              field: 'userName',
              headerName: 'Автор',
              flex: 1,
              valueGetter: row => row.row?.employee?.userName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'entityType',
              headerName: 'Сущность',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row?.entityType,
              renderCell: row => getType(row?.value),
            },
            {
              field: 'operationType',
              headerName: 'Операция',
              flex: 1,
              valueGetter: row => row.row?.operationType,
              renderCell: row => getOperation(row?.value),
            },
            {
              field: 'entityId',
              headerName: 'ID “Помощник”',
              flex: 1,
              maxWidth: 200,
              valueGetter: row => row.row.entityId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'countAttempts',
              headerName: 'Кол-во попыток',
              flex: 1,
              maxWidth: 50,
              valueGetter: row => row.row.countAttempts,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ErrorMessageTable;
