import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AdminRoutes } from '../../routes';
import { Layout } from '../../components';

import { AlertsList, AlertTask, CheckListTask } from './pages';

export const AlertsRouter: FC<any> = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path={AdminRoutes.Alerts} component={AlertsList} />
        <Route path={AdminRoutes.AlertTask} component={AlertTask} />
        <Route path={AdminRoutes.CheckListTask} component={CheckListTask} />
        <Redirect to={AdminRoutes.Alerts} />
      </Switch>
    </Layout>
  );
};
