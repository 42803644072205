import { EChecklistType, IChecklistSettingsEntityShort } from '../../models/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
  type?: EChecklistType;
  name?: string;
  checkListSettings?: IChecklistSettingsEntityShort[];
  organizationIds?: string[];
  machinery?: boolean;
  isDefault?: boolean;
  isNested?: boolean;
  isActive?: boolean;
};

type TypeResponse = string;

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const changeChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/checklist/${params.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
