import { Box } from '@mui/system';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { observer } from 'mobx-react';
import { orderBy } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, Divider } from '@mui/material';
import FunctionsIcon from '@mui/icons-material/Functions';
import { generatePath, useHistory } from 'react-router-dom';

import { IChecklistAttribute } from '../../../../../../../../../../api/models/checklist.model';
import { ChecklistsAttributeController } from '../../../../../../../../../controllers/checklistAttribute.controller';
import { useStore } from '../../../../../../../../../shared/utils';
import AdornmentButtons from '../AdornmentButtons/AdornmentButtons';
import { CREATE_ATTRIBUTE_MODAL } from '../../modals';
import { Colors } from '../../../../../../../../../../constants/colors';
import { ChecklistCRUDController } from '../../../../../../../../../controllers/checklist.CRUD.controller';
import { EChecklistAttributeType } from '../../../../../../../../../../api/models/checklist.attribute.model';
import { ChecklistAttributesStore } from '../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';
import { AdminRoutes } from '../../../../../../../../routes';
import { LockAndSyncButton } from '../LockAndSyncButton';

import Styled from './styled';

interface IProps {
  data: IChecklistAttribute;
  nestedIndex: number;
  parentMap?: Map<string, IChecklistAttribute[]>;
}

const Attribute: FC<IProps> = observer(({ data: attributeData, nestedIndex, parentMap }) => {
  const { setCurrentChecklistAttributeEdit, changeAttributeInChecklistActivity } = useStore(
    ChecklistsAttributeController
  );
  const { setCurrentStageData, currentChecklistStageList, currentChecklist } = useStore(
    ChecklistCRUDController
  );
  const { isBasicAttributeExistedInStage } = useStore(ChecklistAttributesStore);
  const [data, setData] = useState(attributeData);
  const [isAllowToActiveAnother, setIsAllowToActiveAnother] = useState(true);

  const { openModalByModalId } = useModal();
  const history = useHistory();

  const childAttributeList = parentMap?.get(attributeData.id);

  const isBasicAttributeExisted =
    data.attribute?.type !== EChecklistAttributeType.File_link &&
    isBasicAttributeExistedInStage(
      attributeData?.stageId ?? 'noStage',
      attributeData?.attribute.id
    );

  useEffect(() => {
    setData(attributeData);
  }, [attributeData]);

  // useEffect(() => {
  //   setIsAllowToActiveAnother(
  //     !parentMap?.has(data?.position?.parentId) ||
  //       parentMap
  //         ?.get(data?.position?.parentId)
  //         ?.filter(
  //           attr => attr.attribute.type === EChecklistAttributeType.File_link && attr?.isActive
  //         )?.length === 0
  //   );
  // }, [parentMap]);

  const subtitleList = useMemo<string[]>(() => {
    const subtitle = [`№ ${data.order}`, data.attribute.type];

    const formulas = [];

    if (data?.calculate) formulas.push('calculate');
    if (data?.visibility) formulas.push('visibility');
    if (data?.dependency?.dependencies?.length > 0) formulas.push('dependency');

    if (data?.attribute?.dictionaryLink) subtitle.push(data?.attribute?.dictionaryLink);
    if (data?.displayParent) subtitle.push('Выводить родителя');
    if (data?.isTitle) subtitle.push('Как заголовок');
    if (data?.attribute?.isMultiselect) subtitle.push('Мультивыбор');
    // if (data?.fileRequired) subtitle.push('Есть фото');
    if (data?.calculationType) subtitle.push('Агрегация');
    if (data?.dependencyType) subtitle.push(data?.dependencyType);
    if (
      data?.attribute?.type === EChecklistAttributeType.File_link &&
      data?.position?.separate === false
    )
      subtitle.push('Зависимый');
    if (data?.calculate || data?.visibility || data?.dependency?.dependencies?.length > 0)
      subtitle.push(`Есть формулы (${formulas?.join(', ')})`);
    if (
      data?.validationGreenCriteria ||
      data?.validationRedCriteria ||
      data?.validationYellowCriteria
    )
      subtitle.push('Есть ОДЗ');

    return subtitle;
  }, [data]);

  const handleEditAttribute = useCallback(() => {
    setCurrentChecklistAttributeEdit(data, currentChecklist?.nested);
    setCurrentStageData(currentChecklistStageList.find(item => item.id === data?.stageId));
    openModalByModalId(CREATE_ATTRIBUTE_MODAL);
  }, [data, currentChecklistStageList, currentChecklist?.nested]);

  const handleUnlockAttribute = (syncSto?: boolean) => {
    changeAttributeInChecklistActivity(data.id, true, syncSto).then(value => {
      setData(value);
    });
  };

  const handleLockAttribute = (syncSto?: boolean) => {
    changeAttributeInChecklistActivity(data.id, false, syncSto).then(value => {
      setData(value);
    });
  };

  const goToFormulas = useCallback(() => {
    history.push(
      generatePath(AdminRoutes.ChecklistAttributeFormulas, {
        checklistAttributeId: attributeData.id,
        checklistId: attributeData.checkListId,
      })
    );
  }, []);

  if (childAttributeList?.length) {
    return (
      <Accordion>
        <Styled.Summary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: Colors.lightnessGray }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Box display="flex" gap="2px">
                <Styled.Title fontWeight={600}>{data.attribute.name}</Styled.Title>
                {data.isRequired && <Styled.RequiredSign>*</Styled.RequiredSign>}
              </Box>

              <Styled.ActionButtonsWrapper>
                <AdornmentButtons
                  hideDelete
                  onEditClick={handleEditAttribute}
                  isLockOpen={data.isActive}
                  onUnlockClick={handleUnlockAttribute}
                  onLockClick={handleLockAttribute}
                  additionalButtonList={[
                    {
                      icon: FunctionsIcon,
                      tooltip: 'Открыть редактирование формул',
                      onClick: goToFormulas,
                    },
                  ]}
                />
                <LockAndSyncButton
                  isLockOpen={data.isActive}
                  onLockClick={() => handleLockAttribute(true)}
                  onUnlockClick={() => handleUnlockAttribute(true)}
                />
              </Styled.ActionButtonsWrapper>
            </Box>
            <Box>
              <Styled.Subtitle>
                <SubdirectoryArrowRightIcon sx={{ fontSize: 16 }} /> {subtitleList.join(', ')}
              </Styled.Subtitle>
            </Box>
          </Box>
        </Styled.Summary>
        <Divider />
        <AccordionDetails>
          {orderBy(childAttributeList, ['order', 'id'])?.map(attribute => (
            <Attribute
              data={attribute}
              nestedIndex={nestedIndex + 1}
              key={attribute.id}
              parentMap={parentMap}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Styled.AttributeContainer padding="0 16px" marginBottom={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
        <Box display="flex" gap="2px">
          <Styled.Title fontWeight={600}>{data.attribute.name}</Styled.Title>
          {data.isRequired && <Styled.RequiredSign>*</Styled.RequiredSign>}
        </Box>
        <Styled.ActionButtonsWrapper>
          <AdornmentButtons
            hideDelete
            onEditClick={handleEditAttribute}
            isLockOpen={data.isActive}
            onUnlockClick={handleUnlockAttribute}
            onLockClick={handleLockAttribute}
            additionalButtonList={[
              {
                icon: FunctionsIcon,
                tooltip: 'Открыть редактирование формул',
                onClick: goToFormulas,
              },
            ]}
            {...(isBasicAttributeExisted
              ? {
                  disableLockToggler: { isLockClose: true },
                  tooltipConfig: {
                    lockToggler: { isLockClose: 'Базовый атрибут с таким id уже активен' },
                  },
                }
              : {})}
            {...(isAllowToActiveAnother
              ? {}
              : {
                  disableLockToggler: { isLockClose: true },
                  // tooltipConfig: {
                  //   lockToggler: { isLockClose: 'FILE_LINK уже активен' },
                  // },
                })}
          />
          <LockAndSyncButton
            isLockOpen={data.isActive}
            onLockClick={() => handleLockAttribute(true)}
            onUnlockClick={() => handleUnlockAttribute(true)}
          />
        </Styled.ActionButtonsWrapper>
      </Box>
      <Styled.Subtitle>
        <SubdirectoryArrowRightIcon sx={{ fontSize: 16 }} /> {subtitleList.join(', ')}
      </Styled.Subtitle>
    </Styled.AttributeContainer>
  );
});

export default memo(Attribute);
