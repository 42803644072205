import { TypeApiRoute } from '../../models/type.api.request';
import { ISeason } from '../../models/season.model';

type TypeRequest = {
  organizationId: string;
  allowStub: boolean;
  sort: string;
};

type TypeResponse = ISeason[];

export const getSeasons: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/seasons/fetch`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
