import { IOrgIntegraAgrosignalPushMessage } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/models';
import { IOrgIntegraAgrosignalErrorMessage } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalErrorMessage/models/orgIntegraAgrosignalErrorMessage';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraAgrosignalPushMessage>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraAgrosignalErrorMessage[] };

type TypeError = {};

export const pushOrgIntegraAgrosignalMessage: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/pushMessage`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
