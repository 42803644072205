import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
  entityId: string;
};

type TypeResponse = {};

type TypeError = {};

export const deleteDictionaryItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-dictionary/admin/dictionary/${request.remoteName}/delete`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
