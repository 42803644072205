import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import FontStyle from './modules/shared/styles/fonts';
import Normalize from './modules/shared/styles/normalize';
import { App } from './modules/App';
import reportWebVitals from './reportWebVitals';

export const lsAppSettingsKey = 'appSettingsKey';

export enum EAppSettingsType {
  Login = 'login',
  Organization = 'organization',
  Season = 'season',
}

ReactDOM.render(
  <StrictMode>
    <FontStyle />
    <Normalize />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: repor
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
