import { ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@mui/material';
import { debounce, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import { ISelectOption } from '../../../../../../types/selectOption';
import { CheckListsController } from '../../../../../controllers/check.list.controller';
import { useStore } from '../../../../../shared/utils';
import { isValidUUID } from '../../helpers/isValidUUID';
import { getNotificatorProps } from '../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../shared/utils/downloadBlobAsFile';

import Styled from './styled';

const ChecklistExportModalContainer = () => {
  const { getChecklistListByParams, exportChecklistByIds } = useStore(CheckListsController);
  const { closeModal } = useModal();
  const { setNotification } = useNotificator();

  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [selectedOptionList, setSelectedOptionList] = useState<ISelectOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [moreData, setMoreData] = useState<number | false>(false);

  const addSelectedOption = useCallback(
    (option: ISelectOption) => {
      setSelectedOptionList(uniq([...selectedOptionList, option]));
      setInputValue('');
    },
    [selectedOptionList]
  );

  const removeSelectedOption = useCallback(
    (option: ISelectOption) => {
      setSelectedOptionList(
        selectedOptionList.filter(optionItem => optionItem.value !== option.value)
      );
    },
    [selectedOptionList]
  );

  const exportChecklists = useCallback(() => {
    exportChecklistByIds({
      ids: selectedOptionList.flatMap(item => item.value),
      prettyPrint: true,
    })
      .then(data => {
        setNotification(
          getNotificatorProps('Чек-лист успешно экспортирован', ENotificationStyles.Success)
        );
        downloadBlobAsFile(data as Blob, 'exportedChecklist.json');
      })
      .catch(() => {
        setNotification(
          getNotificatorProps('Ошибка экспортирования чек-листа', ENotificationStyles.Error)
        );
      });
  }, [selectedOptionList]);

  const handleFetch = (value: string) => {
    setIsLoading(true);

    const payload = isValidUUID(value) ? { id: value } : { name: value };

    return getChecklistListByParams(payload)
      .then(data => {
        if (data?.content && data?.totalElements > data?.size) {
          setMoreData(data?.totalElements);
        } else {
          setMoreData(false);
        }

        setOptionList(
          data.content.map(item => ({
            label: `${item?.name ? `${item.name} / ` : ''}${item.id}`,
            value: item.id,
          }))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFetchOrganization = useCallback(debounce(handleFetch, 500), []);

  useEffect(() => {
    handleFetchOrganization(inputValue);
  }, [inputValue]);

  return (
    <Styled.Wrapper>
      <Autocomplete
        options={optionList}
        disablePortal
        renderInput={props => (
          <TextField
            {...props}
            label="Чек-лист"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {moreData ? (
                    <Tooltip title={`Найдено записей: ${moreData}. Сузьте поисковой запрос.`}>
                      <ReadMoreIcon />
                    </Tooltip>
                  ) : null}
                  {props.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(e, value, reason) => {
          if (typeof value === 'object' && reason !== 'clear') {
            addSelectedOption(value);
          }
        }}
        inputValue={inputValue}
        value={inputValue}
        onInputChange={(event, value) => {
          setInputValue(value);
        }}
        fullWidth
      />
      <Styled.SelectedItemList spacing={1} direction="column">
        {selectedOptionList.length > 0
          ? selectedOptionList.map(selectedItem => (
              <Styled.SelectedItem
                label={selectedItem.label}
                key={selectedItem.value}
                onDelete={() => removeSelectedOption(selectedItem)}
              />
            ))
          : null}
      </Styled.SelectedItemList>
      <ModalFooter
        successButton={{
          title: 'Выгрузить',
          color: 'primary',
          handler: () => exportChecklists(),
        }}
        denyButton={{
          title: 'Отменить',
          color: 'secondary',
          handler: () => closeModal(),
        }}
      />
    </Styled.Wrapper>
  );
};

export default ChecklistExportModalContainer;
