import { useMemo } from 'react';
import { has } from 'lodash';

import {
  EChecklistAttributePositionWidth,
  EChecklistAttributeType,
} from '../../../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { TFormikDataType } from '../../../../models/form.model';
import {
  EPositionOptionProp,
  TPositionOptionConfig,
} from '../../../../models/positionOptionConfig.model';

interface IUsePositionOptionOptions {
  values: TFormikDataType;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isPreviousFileLink?: boolean;
}

const TYPE_NEW_LINE_ONLY: EChecklistAttributeType[] = [
  EChecklistAttributeType.ChecklistInstanceLink,
  EChecklistAttributeType.Boolean,
  EChecklistAttributeType.Longstring,
];

const TYPE_FULL_WIDTH_ONLY: EChecklistAttributeType[] = [
  EChecklistAttributeType.ChecklistInstanceLink,
  EChecklistAttributeType.Boolean,
  EChecklistAttributeType.Longstring,
];

const usePositionOption = ({ values, setValue, isPreviousFileLink }: IUsePositionOptionOptions) => {
  const isAverageCalculation = values.calculationType === 'AVERAGE';
  const type = values?.type;
  const isNewAttribute = !has(values, 'id');

  // Если type = CHECKLIST_INSTANCE_LINK / BOOLEAN / LONGSTRING / DOUBLE с AVERAGE = true / INT с AVERAGE = true,
  const isNewLineOnly =
    TYPE_NEW_LINE_ONLY.includes(type) ||
    ((type === EChecklistAttributeType.Double || type === EChecklistAttributeType.Int) &&
      isAverageCalculation);

  // Если type = CHECKLIST_INSTANCE_LINK / BOOLEAN / LONGSTRING / DOUBLE с AVERAGE = true / INT с AVERAGE = true, то строго FULL
  const isFullWidthOnly =
    TYPE_FULL_WIDTH_ONLY.includes(type) ||
    ((type === EChecklistAttributeType.Double || type === EChecklistAttributeType.Int) &&
      isAverageCalculation);

  const isNumberNoAverage =
    (type === EChecklistAttributeType.Double || type === EChecklistAttributeType.Int) &&
    !isAverageCalculation;

  const isSeparateOnly = type === EChecklistAttributeType.File_link && isPreviousFileLink;

  const isSeparateFileLink =
    type === EChecklistAttributeType.File_link && values?.position?.separate;

  const isChildrenFileLink = values?.position?.parentId?.length > 0;

  const checkAndSetValue = () => {
    if (type !== EChecklistAttributeType.File_link || isSeparateOnly) {
      setValue(EPositionOptionProp.Separate, true);
    }

    if (isNewLineOnly) {
      setValue(EPositionOptionProp.NewLine, true);
    }

    if (isFullWidthOnly) {
      setValue(EPositionOptionProp.Width, EChecklistAttributePositionWidth.Full);
    }

    if (isChildrenFileLink) {
      setValue(EPositionOptionProp.NewLine, isNewLineOnly);
      setValue(
        EPositionOptionProp.Width,
        isFullWidthOnly
          ? EChecklistAttributePositionWidth.Full
          : EChecklistAttributePositionWidth.Half
      );
    }
  };

  const positionOptionConfig = useMemo<Partial<TPositionOptionConfig>>(() => {
    switch (values.type) {
      case EChecklistAttributeType.Int:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.Double:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.String:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.Longstring:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.Enum:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.Boolean:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.File_link:
        return {
          [EPositionOptionProp.NewLine]: {
            isDisabled: isNewLineOnly || Boolean(values?.position?.parentId?.length > 0),
          },
          [EPositionOptionProp.Separate]: { isDisabled: isSeparateOnly || isNewAttribute },
          [EPositionOptionProp.Width]: {
            isDisabled: isFullWidthOnly || Boolean(values?.position?.parentId?.length > 0),
          },
        };

      case EChecklistAttributeType.ChecklistInstanceLink:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.UserDictionaryLink:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.DictionaryLink:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      case EChecklistAttributeType.Date:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };

      default:
        return {
          [EPositionOptionProp.NewLine]: { isDisabled: isNewLineOnly },
          [EPositionOptionProp.Separate]: { isDisabled: true },
          [EPositionOptionProp.Width]: { isDisabled: isFullWidthOnly },
        };
    }
  }, [
    values,
    isAverageCalculation,
    isChildrenFileLink,
    isFullWidthOnly,
    isNewLineOnly,
    isNumberNoAverage,
    isSeparateFileLink,
  ]);

  return { positionOptionConfig, checkAndSetValue };
};

export default usePositionOption;
