import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem } from '@mui/material';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { uniq } from 'lodash';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';

import { EImportOperationStatus } from '../../../../../../../../../../api/models/importOperation.model';
import { PAGE_SIZE_LIST } from '../../../../hooks';
import { useAssistanceFieldsReportHistory } from '../../../../hooks/useAssistanceFieldsReportHistory';

const STATUS_IN_OPTION_LIST: EImportOperationStatus[] = [
  EImportOperationStatus.PENDING,
  EImportOperationStatus.IN_PROGRESS,
  EImportOperationStatus.COMPLETED,
  EImportOperationStatus.ERROR,
];

interface IProps {
  clientId: string;
  organizationId: string;
}

const OrganizationReportsAssistanceHistory: FC<IProps> = ({ clientId, organizationId }) => {
  const { fromDate, toDate } = useMemo(() => {
    const currentDate = new Date();

    return {
      fromDate: moment(currentDate).subtract(6, 'd').format('YYYY-MM-DD'),
      toDate: moment(currentDate).format('YYYY-MM-DD'),
    };
  }, []);

  const controller = useAssistanceFieldsReportHistory({
    clientId,
    organizationId,
    fromDate,
    toDate,
  });

  const onSubmit = data => {
    controller.setPage(0);
    controller.fetchFieldHistory(data);
  };

  const onReset = () => {
    controller.fetchFieldHistory({
      statusIn: [],
      configId: null,
      organizationId,
      page: 0,
      size: PAGE_SIZE_LIST[0],
    });
  };

  const formik = useFormik({
    initialValues: {
      fromDate,
      toDate,
      statusIn: [],
      configId: clientId,
    },
    onSubmit,
    onReset,
  });

  const { values } = formik;
  const setFieldValue = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };

  return (
    <Box>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <FormControl fullWidth>
              <Field
                component={Select}
                id="statusIn"
                name="statusIn"
                label="Статус"
                onChange={() => null}
                renderValue={() => values.statusIn?.join(', ')}
                disabled={false}
              >
                {STATUS_IN_OPTION_LIST.map(status => (
                  <MenuItem
                    key={status}
                    value={status}
                    onClickCapture={event => {
                      event.stopPropagation();
                      if (values.statusIn.includes(status)) {
                        setFieldValue(
                          'statusIn',
                          uniq(values.statusIn.filter(item => item !== status))
                        );
                      } else {
                        setFieldValue('statusIn', uniq([...values.statusIn, status]));
                      }
                    }}
                  >
                    <Checkbox checked={values?.statusIn.includes(status)} />
                    <ListItemText primary={status} />
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid xs={5} item>
            <Field
              component={TextField}
              value={values.configId ?? ''}
              disabled={false}
              name="configId"
              type="text"
              label="ID конфигурации"
              fullWidth
            />
          </Grid>
          <Grid xs={4} item>
            <CalendarComponent
              label="Дата заказа отчета с"
              onChange={date =>
                setFieldValue('fromDate', moment(date as Date).format('YYYY-MM-DD'))
              }
              value={(values?.fromDate && moment(values.fromDate).format('DD.MM.YYYY')) ?? ''}
              datePickerOptions={{ dateRange: { maxDate: moment(values?.toDate).toDate() } }}
            />
          </Grid>
          <Grid xs={4} item>
            <CalendarComponent
              label="Дата заказа отчета по"
              onChange={date => setFieldValue('toDate', moment(date as Date).format('YYYY-MM-DD'))}
              value={(values?.toDate && moment(values.toDate).format('DD.MM.YYYY')) ?? ''}
              datePickerOptions={{ dateRange: { minDate: moment(values?.fromDate).toDate() } }}
            />
          </Grid>
          <Grid xs={4} item></Grid>
          <Grid xs={5} item>
            <Box display={'flex'} flexDirection={'row'} gap={2}>
              <Button
                sx={{ maxWidth: '120px' }}
                color="primary"
                variant="contained"
                type="submit"
                onClick={e => formik.handleSubmit()}
              >
                Применить
              </Button>
              <Button
                sx={{ maxWidth: '120px' }}
                color="error"
                variant="text"
                type="reset"
                onClick={e => {
                  formik.resetForm();
                  setFieldValue('configId', null);
                  setFieldValue('fromDate', null);
                  setFieldValue('toDate', null);
                }}
              >
                Сбросить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormikProvider>
      <Box height={`calc(100vh - 495px)`} marginTop={2}>
        <DataGrid
          columns={controller.gridColumnConfig}
          rows={controller.valueList}
          pagination={true}
          paginationMode={'server'}
          onPageChange={controller.setPage}
          onPageSizeChange={controller.setSize}
          loading={controller.isLoading}
          rowsPerPageOptions={PAGE_SIZE_LIST}
          page={controller.page}
          pageSize={controller.size}
          rowCount={controller.totalElements}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
        />
      </Box>
    </Box>
  );
};

export default OrganizationReportsAssistanceHistory;
