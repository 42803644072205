import { Box, IconButton, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import PlaceIcon from '@mui/icons-material/Place';
import RuleIcon from '@mui/icons-material/Rule';
import StraightenIcon from '@mui/icons-material/Straighten';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraCropwiseChecklistInstance } from '../../models/OrgIntegraCropwiseChecklistInstance';
import { MAP_POINT_MODAL_ID } from '../../modals';
import useIntegraCropwiseNavigation from '../../../../hooks/useIntegraCropwiseNavigation';
import { IOrgIntegraEmployee } from '../../../OrgIntegraCropwiseEmployees/models';
import { IOrgIntegraCropwiseField } from '../../../../../../../../../../../../api/models/integra/config.model';

const ChecklistInstanceTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();
  const { goToChecklistTab, goToMeasurementTab, goToPhotosTab } = useIntegraCropwiseNavigation({
    organizationId,
  });

  const searchParams = useMemo(() => new URLSearchParams(search), []);
  const filterConfigRef = useRef<Map<string, any>>(new Map());

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });

  const { openModalByModalId } = useModal();

  const fetchData: TFetchHandler<IOrgIntegraCropwiseChecklistInstance> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);

      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    return service
      .fetchChecklistInstanceList({
        ...modifiedArgs,
        organizationIdIn: [organizationId],
        page,
        size,
      })
      .then(data => {
        setLastPageable({ page, size });

        return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
      });
  };

  const openMapPointModal = (instance: IOrgIntegraCropwiseChecklistInstance) => {
    openModalByModalId(MAP_POINT_MODAL_ID, { instance });
  };

  return (
    <Box>
      <OrganizationIntegraTable<IOrgIntegraCropwiseChecklistInstance>
        isDisableSelection
        fetchHandler={fetchData}
        controlledPage={lastPageable?.page}
        controlledSize={lastPageable?.size}
        filter={{
          filterConfigRef,
          filterCollection: [
            {
              type: 'select',
              label: 'Автор',
              propertyName: 'userIds',
              optionListFetchHandler: () =>
                service.fetchEmployeeList({ organizationId, page: 0, size: 1000 }),
              optionMapHandler: (data: IOrgIntegraEmployee) => {
                return {
                  label: data?.userName || data?.assigneeName || data?.id,
                  value: data?.id,
                  rawData: data,
                };
              },
              isMultiple: true,
            },

            {
              type: 'select',
              label: 'Поле',
              propertyName: 'fieldId',
              placeholder: 'Выберите поле',
              optionListFetchHandler: (payload: any) =>
                service.fetchFieldList({ organizationId, page: 0, size: 1000, ...payload }),
              optionMapHandler: (data: IOrgIntegraCropwiseField) => {
                // @ts-ignore
                const name = data?.name || data?.cropwiseFieldName;

                return {
                  label: `${name || data?.id}${name ? ` (${data?.id})` : ''}`,
                  value: data?.id,
                  rawData: data,
                };
              },
              searchKey: 'fieldName',
              isMultiple: true,
            },
            {
              type: 'text',
              label: 'ID чек-лист точки',
              propertyName: 'checkListInstanceId',
              placeholder: 'Введите ID чек-лист точки',
            },
            {
              type: 'number',
              label: 'ID точки “Cropwise”',
              propertyName: 'cropwisePointId',
              placeholder: 'Введите ID точки “Cropwise”',
            },
            {
              type: 'number',
              label: 'ID чек-лист точки “Cropwise”',
              propertyName: 'cropwiseCheckListInstanceId',
              placeholder: 'Введите ID чек-лист точки',
            },
            {
              isDateGroupConstraints: true,
              itemList: [
                {
                  type: 'date',
                  label: 'Старт создания',
                  propertyName: 'createDateTimeFrom',
                  placeholder: 'Введите дату создания',
                  isGroupMin: true,
                  format: 'ISO',
                },
                {
                  type: 'date',
                  label: 'Окончание создания',
                  propertyName: 'createDateTimeTo',
                  placeholder: 'Введите дату создания',
                  isGroupMax: true,
                  format: 'ISO',
                },
              ],
            },
            {
              isDateGroupConstraints: true,
              itemList: [
                {
                  type: 'date',
                  label: 'Старт обновления',
                  propertyName: 'updateDateTimeFrom',
                  placeholder: 'Введите дату обновления',
                  isGroupMin: true,
                  format: 'ISO',
                },
                {
                  type: 'date',
                  label: 'Окончание обновления',
                  propertyName: 'updateDateTimeTo',
                  placeholder: 'Введите дату обновления',
                  isGroupMax: true,
                  format: 'ISO',
                },
              ],
            },
          ],
        }}
        columnConfig={[
          {
            field: 'id',
            headerName: 'ID Адаптер',
            flex: 1,
            valueGetter: row => row.row.id,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'cropwisePointId',
            headerName: 'ID “Cropwise”',
            flex: 1,
            valueGetter: row => row.row.cropwisePointId,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'latitude',
            headerName: 'Координаты',
            flex: 1,
            minWidth: 145,
            valueGetter: row => row.row.latitude,
            renderCell: row => (
              <Box display={'flex'} alignItems={'center'}>
                <Tooltip title="Посмотреть на карте">
                  <IconButton onClick={() => openMapPointModal(row.row)}>
                    <PlaceIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <TooltipedValue
                  typographyStyles={{ whiteSpace: 'pre-wrap' }}
                  fontSize={10}
                  value={`${row.row.latitude}\n${row.row.longitude}`}
                />
              </Box>
            ),
          },
          {
            field: 'checklistInstanceId',
            headerName: 'ID чек-листа “Помощник”',
            flex: 1,
            valueGetter: row => row.row.checkListInstanceId,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'cropwiseChecklistInstanceId',
            headerName: 'ID чек-листа “Cropwise”',
            flex: 1,
            valueGetter: row => row.row.scoutReportInstance.cropwiseCheckListInstanceId,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'fieldName',
            headerName: 'Поле в “Помощник”',
            flex: 1,
            valueGetter: row => row.row.scoutReportInstance.field.fieldName,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'cropwiseFieldName',
            headerName: 'Поле в “Cropwise”',
            flex: 1,
            valueGetter: row => row.row.scoutReportInstance.field.cropwiseFieldName,
            renderCell: row => <TooltipedValue value={row.value} />,
          },

          {
            field: 'username',
            headerName: 'Автор',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.employee.userName,
            renderCell: row => <TooltipedValue value={row.value} />,
          },
          {
            field: 'createDateTime',
            headerName: 'Дата и время создания',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.createDateTime,
            renderCell: row => <DateParsedValue value={row.value} />,
          },
          {
            field: 'updateDateTime',
            headerName: 'Дата и время обновления',
            flex: 1,
            maxWidth: 200,
            valueGetter: row => row.row.updateDateTime,
            renderCell: row => <DateParsedValue value={row.value} />,
          },
          {
            field: 'checklist',
            headerName: 'Шаблон',
            maxWidth: 60,
            valueGetter: row => row.row.id,
            renderCell: row => (
              <Tooltip title="Перейти к шаблону">
                <IconButton
                  onClick={() =>
                    goToChecklistTab({
                      query: { idIn: row.row.scoutReportInstance.mapCheckListId },
                    })
                  }
                >
                  <RuleIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: 'measurement',
            headerName: 'Измерения',
            maxWidth: 60,
            valueGetter: row => row.row.id,
            renderCell: row => (
              <Tooltip title="Перейти к измерениям">
                <IconButton
                  onClick={() => goToMeasurementTab({ query: { pointIdIn: row.row.id } })}
                >
                  <StraightenIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: 'images',
            headerName: 'Фотографии',
            maxWidth: 60,
            valueGetter: row => row.row.id,
            renderCell: row => (
              <Tooltip title="Перейти к фотографиям">
                <IconButton
                  onClick={() =>
                    goToPhotosTab({
                      query: { scoutReportInstanceIdIn: row.row.scoutReportInstance.id },
                    })
                  }
                >
                  <InsertPhotoIcon />
                </IconButton>
              </Tooltip>
            ),
          },
        ]}
      />
    </Box>
  );
};

export default ChecklistInstanceTable;
