import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {};

type TypeResponse = {
  newsId: string;
};

type TypeError = {};

export const createNewsItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-news/news/create`,
  headers: {},
  method: 'POST',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
