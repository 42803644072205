import { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { IAggregationFormulaType, IFormulaError, IFormulaFunction } from '../../../../../../models';
import { EErrorCollection, errorCollection } from '../../../../../../configs';

interface IUseValidationArgs {
  blockConfig: IAggregationFormulaType;
  formulaFunctionConfig: IFormulaFunction;
  parentBlockConfig: IAggregationFormulaType;
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const useValidation = ({
  blockConfig,
  formulaFunctionConfig,
  handleSetError,
  handleRemoveError,
}: IUseValidationArgs) => {
  const [argErrorId] = useState(v4());

  const isSmallerArgs = useMemo(
    () => blockConfig?.args?.length < formulaFunctionConfig?.minArguments,
    [blockConfig?.args?.length, formulaFunctionConfig?.minArguments]
  );
  const isBiggerArgs = useMemo(
    () => blockConfig?.args?.length > formulaFunctionConfig?.maxArguments,
    [blockConfig?.args?.length, blockConfig?.args?.length > formulaFunctionConfig?.maxArguments]
  );

  useEffect(() => {
    return () => {
      handleRemoveError(argErrorId);
    };
  }, []);

  useEffect(() => {
    if (isSmallerArgs || isBiggerArgs) {
      handleSetError?.(
        {
          text: errorCollection.ARGUMENT_NUMBER,
          errorType: EErrorCollection.ARGUMENT_NUMBER,
          HTMLElementId: `${argErrorId}-argCount`,
        },
        argErrorId
      );
      return;
    }

    handleRemoveError(argErrorId);
  }, [isBiggerArgs, isSmallerArgs, argErrorId]);
};

export default useValidation;
