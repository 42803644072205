import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  userId: string;
};

type TypeResponse = {
  emails: string[];
  phoneNumbers: string[];
};

type TypeError = {};

export const getPendingContractsUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-profile/users/admin/pendingContacts/${params.userId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
