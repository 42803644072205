import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router';
import { Button, Grid, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { isEmpty, includes } from 'lodash';

import { useStore, getLink } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { InterestStore } from '../../../../../stores/users/interest.store';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { InterestType } from '../../../../../../api/models/interest.model';
import { AdminRoutes, ERegexp } from '../../../../routes';
import IconDelete from '../../../../../shared/assets/images/delete.svg';
import { ExpertiseWrapper, Competition, DeleteIcon } from '../../style';
import { Layout } from '../../../../components';

export const UserInterests: FC = observer(() => {
  const { fetchUser, fetchInterests, saveInterestsUserAdmin } = useStore(UsersController);
  const {
    interests,
    searchInterest,
    setSearchInterest,
    isLoading,
    interest,
    setInterest,
    selectedInterests,
    setSelectedInterests,
    setUserInterestsToSelected,
    deleteExpertise,
    reset,
  } = useStore(InterestStore);
  const history = useHistory();
  const { userId } = useParams<{ userId?: string }>();

  const handleSearch = (_, value: string) => setSearchInterest(value);
  const handleChange = (_, value: InterestType) => setInterest(value);
  const handleSetSelectedInterests = () => {
    setSelectedInterests(interest);
    setInterest(null);
  };

  const handleSaveExpertises = async () => {
    const ids = selectedInterests.map(item => item.interestId);
    await saveInterestsUserAdmin({ userId, ids });
    reset();
    history.push(
      getLink(AdminRoutes.UserEdit, {
        [userId]: ERegexp.USERID,
      })
    );
  };

  const handleCancel = () => {
    reset();
    history.push(
      getLink(AdminRoutes.UserEdit, {
        [userId]: ERegexp.USERID,
      })
    );
  };

  useEffect(() => {
    fetchUser({ userId }).then(user => {
      if (!isEmpty(user)) {
        setUserInterestsToSelected(user.interests);
      }
    });
  }, []);

  useEffect(() => {
    fetchInterests();
  }, [searchInterest]);

  const isOptionDisabled = (option: InterestType) => {
    if (isEmpty(selectedInterests)) {
      return false;
    }

    const disabledInterests = selectedInterests.map(({ interestId }) => interestId);
    return includes(disabledInterests, option.interestId);
  };

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Интересы</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <Grid container spacing={2} mb={20}>
            <Grid item xs={4}>
              <Autocomplete
                options={interests}
                getOptionLabel={(option: InterestType) => option.interestName}
                isOptionEqualToValue={(option: InterestType, value) =>
                  option.interestName === value.interestName
                }
                getOptionDisabled={(option: InterestType) => isOptionDisabled(option)}
                loading={isLoading}
                loadingText="Загрузка..."
                noOptionsText="Интересов не найдено"
                value={interest}
                onChange={handleChange}
                onInputChange={handleSearch}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Добавление нового интереса"
                    placeholder={'Например, “Садоводство”'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8}>
              <Button
                variant="contained"
                color="primary"
                disabled={isEmpty(interest)}
                onClick={handleSetSelectedInterests}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>

          <ExpertiseWrapper data-test-id="edit-competitions-list">
            {!isEmpty(selectedInterests)
              ? selectedInterests.map(item => (
                  <Competition key={item.interestId} data-test-id="edit-competitions-item">
                    <DeleteIcon
                      src={IconDelete}
                      onClick={() => deleteExpertise(item.interestId)}
                      data-test-id="edit-competitions-item-icon"
                    />
                    <p data-test-id="edit-competitions-item-caption">{item.interestName}</p>
                  </Competition>
                ))
              : 'Не выбраны компетенции'}
          </ExpertiseWrapper>

          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSaveExpertises}>
                Сохранить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleCancel}>
                Отменить
              </Button>
            </Grid>
          </Grid>
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
