import styled, { css } from 'styled-components';
import { Box, Button } from '@mui/material';

import { Colors } from '../../../../../../constants/colors';

const Wrapper = styled.form`
  width: 100%;
`;

const InputRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 0;
`;

const Output = styled.div`
  width: 100%;
  height: 256px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid ${Colors.gray};
  border-radius: 5px;

  & > p {
    white-space: pre-line;
  }
`;

const ImportStatus = styled.span<{ $isError?: boolean }>`
  font-weight: bold;

  ${({ $isError }) =>
    $isError
      ? css`
          color: ${Colors.red};
        `
      : css`
          color: ${Colors.green};
        `};
`;

const CloseButton = styled(Button)`
  position: absolute !important;
  right: 0;
  top: -30px;
`;

const Styled = {
  Wrapper,
  InputRow,
  Output,
  ImportStatus,
  CloseButton,
};

export default Styled;
