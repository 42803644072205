import { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Grid, Button, TextField, Box } from '@mui/material';
import moment from 'moment'

import { getValues } from '../AlertsList/components/CheckListTable/utils/getValues';
import { EFormFields } from '../../../../../../api/models/alert.model';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1, H2 } from '../../../../style';
import { useStore } from '../../../../../shared/utils';
import { CheckListStore } from '../../../../../stores/checkLists/check.list.store';
import { CheckListsController } from '../../../../../controllers/check.list.controller';

export const CheckListTask: FC = observer(() => {

  const { fetchCheckList, changeCheckListStatus } = useStore(CheckListsController);
    
  const { checkList } = useStore(CheckListStore);

  const { checkListId } = useParams<{ checkListId?: string }>();

  const values = useMemo(() => getValues(checkList), [checkList]);

  const ButtonTypes = ['Ошибка', 'Отмена', 'Выполнено', 'Новая']

  useEffect(() => {
    fetchCheckList({ checkListId }); 
  }, []);

  const handleChangeStatus = e => {
    switch (e.target.innerText) {
      case 'ОШИБКА':
        changeCheckListStatus({
          checkListId,
          status: 'toError',
        });
        break;
      case 'ОТМЕНА':
        changeCheckListStatus({
          checkListId,
          status: 'toCanceled',
        });
        break;
      case 'ВЫПОЛНЕНО':
        changeCheckListStatus({
          checkListId,
          status: 'toCompleted',
        });
        break;
      case 'НОВАЯ':
        changeCheckListStatus({
          checkListId,
          status: 'toNew',
        });
        break;
      default:
        break;
    }
  };

  return (
    <MainContainer>
      <HeaderWrapper>
        <TopSection>
          <H1>Информация о чек листе</H1>
        </TopSection>
      </HeaderWrapper>
      <MainBody>
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={values[EFormFields.name]}
                placeholder="Введите имя"
                label="Имя"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                value={values[EFormFields.status]}
                placeholder="Введите статус"
                label="Статус"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                value={values[EFormFields.error]}
                placeholder="Введите имя ошибки"
                label="Имя ошибки"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                value={values[EFormFields.id]}
                placeholder="Введите айди"
                label="Айди"
                disabled
                variant="outlined"
              />
            </Grid>
        
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={moment(values[EFormFields.timestamp]).format('DD.MM.YYYY')}
                placeholder="Введите последнее обновление"
                label="Последнее обновление"
                disabled
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt={2}>
          <H2> Смена статуса </H2>
          <Box display={'flex'}>
            {ButtonTypes.map((type) => (
              <Box ml={2} mt={1}>
                <Button onClick={handleChangeStatus} variant="contained">{type}</Button>
              </Box>
            ))}
          </Box>
        </Box>
      </MainBody>
    </MainContainer>
  );
});
