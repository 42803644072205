import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';
import { has } from 'lodash';
import moment from 'moment';

import {
  IOrganizationIntegraTableFilter,
  IOrganizationIntegraTableFilterGroup,
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../types/organizationIntegraTable.types';
import { getNotificatorProps } from '../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../shared/constanst/notifications';

const useDataGrid = <T,>({
  fetchHandler,
  controlledPage,
  controlledSize,
  filter,
  isFilterInitialized,
}: {
  fetchHandler: TFetchHandler<T>;
  controlledPage?: number;
  controlledSize?: number;
  filter?: IOrganizationIntegraTableFilter;
  isFilterInitialized?: boolean;
}) => {
  const [page, setPage] = useState(controlledPage || 0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState<number>(controlledSize || 25);
  const [content, setContent] = useState<T[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>(null);
  const [additionalParams, setAdditionalParams] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isAdditionalParamsReady, setIsAdditionalParamsReady] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lastFetchedParams, setLastFetchedParams] = useState({});

  const history = useHistory();
  const { setNotification } = useNotificator();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const currentSearchParams = Object.fromEntries(searchParams.entries());
    delete currentSearchParams.page;
    delete currentSearchParams.size;

    const currentAdditionalParams = { ...additionalParams } as Record<string, any>;
    delete currentAdditionalParams.page;
    delete currentAdditionalParams.size;

    if (
      !isFirstLoad &&
      searchParams.get('page') === String(page) &&
      searchParams.get('size') === String(size) &&
      JSON.stringify(currentSearchParams) === JSON.stringify(currentAdditionalParams)
    ) {
      return;
    }

    const paramsCollection = {};
    const flatFilterCollection: TOrganizationIntegraTableFilterItemUnion[] = [];

    filter?.filterCollection.forEach(item => {
      if (has(item, 'itemList')) {
        flatFilterCollection.push(...(item as IOrganizationIntegraTableFilterGroup).itemList);
      } else {
        flatFilterCollection.push(item as TOrganizationIntegraTableFilterItemUnion);
      }
    });

    flatFilterCollection?.forEach((item: TOrganizationIntegraTableFilterItemUnion) => {
      if (searchParams.has(item.propertyName)) {
        switch (item.type) {
          case 'date':
            if (item.format) {
              paramsCollection[item.propertyName] =
                item.format === 'ISO'
                  ? moment(searchParams.get(item.propertyName)).toISOString(true)
                  : moment(searchParams.get(item.propertyName)).format(item.format);
            } else {
              paramsCollection[item.propertyName] = searchParams.get(item.propertyName);
            }

            break;

          default:
            paramsCollection[item.propertyName] = searchParams.get(item.propertyName);
            break;
        }
      }
    });

    setIsAdditionalParamsReady(true);
    setAdditionalParams(paramsCollection);
  }, [location.search]);

  const updateQueryParams = newParams => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(newParams).forEach(key => {
      const value = newParams[key];
      if (value === null || value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const fetchData = (_page: number, _size: number) => {
    if (isLoading || !isAdditionalParamsReady) {
      return;
    }

    if (
      JSON.stringify(lastFetchedParams) ===
      JSON.stringify({ page: _page, size: _size, ...additionalParams })
    ) {
      return;
    }

    setIsLoading(true);

    return fetchHandler?.(_page || page || 0, _size || size || 25, additionalParams)
      .then(data => {
        setContent(data.content);
        setCount(data.count);
        setPage(data.page);

        updateQueryParams({ page: data.page, size: _size || size || 25 });
        setLastFetchedParams({ page: data.page, size: _size || size || 25, ...additionalParams });

        return data;
      })
      .catch(() => {
        setNotification(getNotificatorProps('Ошибка получения данных', ENotificationStyles.Error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isFilterInitialized) return;

    if (!isFirstLoad) setIsFirstLoad(true);

    fetchData(page, size);
  }, [page, size, additionalParams, isFilterInitialized]);

  return {
    page,
    setPage,
    count,
    size,
    setSize,
    content,
    isLoading,
    rowSelectionModel,
    setRowSelectionModel,
  };
};

export { useDataGrid };
