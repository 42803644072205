import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import { TooltipedValue } from '../../../../../../shared/components';
import { IImportOperationModel } from '../../../../../../../api/models/importOperation.model';

const agrosignalGridColumns: GridColDef<IImportOperationModel>[] = [
  {
    field: 'fromDate',
    type: 'string',
    headerName: 'Дата начала для выборки данных',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    minWidth: 120,
  },
  {
    field: 'toDate',
    type: 'string',
    headerName: 'Дата окончания для выборки данных',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    minWidth: 130,
  },
  {
    field: 'host',
    type: 'string',
    headerName: 'URL',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'reportsLocation',
    type: 'string',
    headerName: 'Папка выгрузки отчёта',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    minWidth: 120,
  },
  {
    field: 'filesLocation',
    type: 'string',
    headerName: 'Папка выгрузки фотоматериалов',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    minWidth: 120,
  },
  {
    field: 'creationDate',
    type: 'string',
    headerName: 'Дата заказа выгрузки',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    minWidth: 120,
  },
  {
    field: 'operationStatus',
    type: 'string',
    headerName: 'Статус',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    minWidth: 120,
  },
  {
    field: 'message',
    type: 'string',
    headerName: 'Ошибка',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'isAuto',
    type: 'boolean',
    headerName: 'Автоматическая выгрузка',
    minWidth: 80,
  },
  {
    field: 'type',
    type: 'string',
    headerName: 'Тип запроса',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    minWidth: 130,
  },
];

export default agrosignalGridColumns;
