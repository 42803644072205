import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../../routes';
import { ServiceList } from '../components';
import { serviceConfig } from '../configs';

export const ServiceRouter: FC = () => {
  return (
    <Switch>
      {serviceConfig.map(({ component, entryPath, title }) => (
        <Route key={title} path={entryPath} component={() => component as any} />
      ))}
      <Route path={[AdminRoutes.Services]} component={ServiceList} />
    </Switch>
  );
};

export default ServiceRouter;
