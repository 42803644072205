import { FC } from 'react';
import { isEmpty } from 'lodash';

import { getLink } from '../../../shared/utils';
import { AdminRoutes, ERegexp } from '../../routes';
import { ProfileWidgetWrapper } from '../ProfileWidgetWrapper/ProfileWidgetWrapper';
import { UserExpertiseType } from '../../../../api/models/user.model';
import Checkbox from '../../../shared/assets/images/checkbox.svg';
import CheckboxGreen from '../../../shared/assets/images/checkbox-green.svg';

import { Expertise, WrapperExpertise, ExpertiseEmptyStyled } from './styled';
import { ReactComponent as StarIcon } from './assets/star.svg';

type ExpertiseWidgetProps = {
  userId: string;
  items: Array<UserExpertiseType>;
};

export const ExpertiseWidget: FC<ExpertiseWidgetProps> = ({ userId, items }) => (
  <ProfileWidgetWrapper
    title="Компетенции"
    editLink={getLink(AdminRoutes.EditExpertises, {
      [userId]: ERegexp.USERID,
    })}
    editCaption={isEmpty(items) ? 'Добавить' : 'Редактировать'}
    dataTestIdAction={
      isEmpty(items) ? 'user-widget-expertise-action-add' : 'user-widget-expertise-action-edit'
    }
    dataTestId="user-widget-expertise"
  >
    <WrapperExpertise data-test-id="user-widget-expertise-content">
      {items?.length ? (
        items.map(item => (
          <Expertise key={item.expertiseId} data-test-id="user-widget-expertise-item">
            <p data-test-id="user-widget-expertise-item-name">{item.expertiseName}</p>{' '}
            <img
              src={item.confirmations ? CheckboxGreen : Checkbox}
              alt=""
              data-test-id="user-widget-expertise-item-checkbox"
            />{' '}
            <p data-test-id="user-widget-expertise-item-confirmations">{item.confirmations}</p>
          </Expertise>
        ))
      ) : (
        <ExpertiseEmptyStyled data-test-id="user-widget-expertise-content-empty">
          <ExpertiseEmptyStyled.Icon data-test-id="user-widget-expertise-content-empty-icon-wrapper">
            <StarIcon data-test-id="user-widget-expertise-content-empty-icon" />
          </ExpertiseEmptyStyled.Icon>
          <ExpertiseEmptyStyled.Text data-test-id="user-widget-expertise-content-empty-text">
            У вас ещё нет компетенций,
            <br />
            но вы можете их добавить прямо сейчас
          </ExpertiseEmptyStyled.Text>
        </ExpertiseEmptyStyled>
      )}
    </WrapperExpertise>
  </ProfileWidgetWrapper>
);
