import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import { FC } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { IOrganizationIntegraTableFilterItemDate } from '../../../../../types/organizationIntegraTable.types';
import { OrgIntegraTableFilterStore } from '../../../mobx';
import { useStore } from '../../../../../../../../../../../../../shared/utils';

import 'moment/locale/ru';
const OrgIntegraTableFilterItemDate: FC<{
  item: IOrganizationIntegraTableFilterItemDate;
  id: string;
}> = ({ item, id }) => {
  const { getComponentValue, addComponentValue, removeComponentValue } = useStore(
    OrgIntegraTableFilterStore
  );

  const value = getComponentValue(item.propertyName)?.value;

  const onChange = (date: Moment) => {
    if (!date) {
      removeComponentValue(item.propertyName);
      return;
    }

    addComponentValue(item.propertyName, {
      value: moment(date),
      type: item.type,
      propertyName: item.propertyName,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
      <DateTimePicker
        value={value || null}
        onChange={onChange}
        renderInput={props => <TextField {...props} label={item.label} fullWidth />}
        disabled={item.disabled}
      />
    </LocalizationProvider>
  );
};

export default observer(OrgIntegraTableFilterItemDate);
