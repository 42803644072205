import styled from 'styled-components';

const Wrapper = styled.div``;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  & button {
    display: block;
    text-align: center;
    width: 240px;
    cursor: pointer;
  }
  & > button:first-child {
    //  margin-right: 20%;
  }
  margin-top: 40px;
`;

const EditQuoteStyle = {
  Wrapper,
  ActionsBlock,
};

export default EditQuoteStyle;
