import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { SynchroniesModal } from './components';

const ORG_INTEGRA_SYNCHRONIES_MODAL_ID = 'org-integra-synchronies-modal';

const synchroniesModalConfig: TModalConfig = {
  id: ORG_INTEGRA_SYNCHRONIES_MODAL_ID,
  name: '',
  title: '',
  type: EModalType.Custom,
  styledProps: {
    $size: '600px',
    $isFullContentWidth: true,
    $modalPaddings: '40px',
    $height: '680px',
  },

  children: SynchroniesModal,
};

export { ORG_INTEGRA_SYNCHRONIES_MODAL_ID, synchroniesModalConfig };
