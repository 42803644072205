import { Autocomplete, Box, TextField } from '@mui/material';
import { FC, memo, useState } from 'react';
import { v4 } from 'uuid';

import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../../../../../api/models/checklist.model';
import { HighlightBox } from '../../../../../../../../../../../shared/components';

import useAvailablePropKeys from './useAvaliablePropKeys';

interface IProps {
  value: string;
  onChangeProperty: (value: string) => void;
  attrId?: string;
  selectedBlockAttribute?: ISelectOptionExtended<IChecklistAttribute>;
}

const FormulaProperty: FC<IProps> = ({
  value,
  onChangeProperty,
  selectedBlockAttribute,
  attrId,
}) => {
  const [uuid] = useState(v4());

  const { uniqueKeys } = useAvailablePropKeys(selectedBlockAttribute, attrId);

  return (
    <HighlightBox marginTop={2} width={300} id={`${uuid}-propValue`}>
      <Autocomplete
        value={value ?? null}
        options={uniqueKeys}
        data-test-id="formula-block-property-selector"
        renderInput={params => (
          <>
            <TextField
              {...params}
              data-test-id="formula-block-property-input"
              label={'Наименование свойства'}
            />
          </>
        )}
        renderOption={(props, option) => {
          const isNested = option?.includes('|');

          return (
            <li {...props} key={String(option)}>
              <Box>{isNested ? option.split('|').join(' | ') : option}</Box>
            </li>
          );
        }}
        onChange={(e, option) => {
          onChangeProperty(option);
        }}
        disableClearable
      />
    </HighlightBox>
  );
};

export default memo(FormulaProperty);
