import { Autocomplete, Box, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useMessagePushForm } from '../../hooks';
import { ISelectOption } from '../../../../../../../../../../../../../../types/selectOption';
import {
  EOrgIntegraAgrosignalErrorMessageEntityType,
  EOrgIntegraAgrosignalErrorMessageOperationType,
} from '../../../../models/orgIntegraAgrosignalErrorMessage';

const OPERATION_LIST: ISelectOption[] = [
  {
    label: 'Создание',
    value: EOrgIntegraAgrosignalErrorMessageOperationType.Create,
  },
  {
    label: 'Удаление',
    value: EOrgIntegraAgrosignalErrorMessageOperationType.Delete,
  },
  {
    label: 'Обновление',
    value: EOrgIntegraAgrosignalErrorMessageOperationType.Update,
  },
];

const ENTITY_LIST: ISelectOption[] = [
  {
    label: 'Шаблон',
    value: EOrgIntegraAgrosignalErrorMessageEntityType.Checklist,
  },
  {
    label: 'Чек-лист',
    value: EOrgIntegraAgrosignalErrorMessageEntityType.ChecklistInstance,
  },
  {
    label: 'Поле',
    value: EOrgIntegraAgrosignalErrorMessageEntityType.Field,
  },
];

const ErrorMessageSinglePushForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();

  const {
    employeeList,
    setSelectedEmployeeList,
    minDate,
    maxDate,
    setMinDate,
    setMaxDate,
    pushMessage,
    setEntityTypeList,
    setOperationTypeList,
  } = useMessagePushForm(orgStore?.organization?.organizationId);

  const [isProcessing, setIsProcessing] = useState(false);

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    selectionList: string[];
  };
  const handlers = getModalRuntimeHandlers();

  const handleSuccess = () => {
    setIsProcessing(true);

    pushMessage()
      .then(() => {
        handlers?.onSuccess?.();

        closeModal();
      })
      .finally(() => {
        setIsProcessing(false);
        closeModal();
      });
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Обработка ошибок</ModalTitle>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />
        <Autocomplete
          options={employeeList}
          fullWidth
          renderInput={props => (
            <TextField {...props} label={'Пользователь'} placeholder={'Выберите пользователей'} />
          )}
          disableClearable
          disablePortal
          multiple
          onChange={(e, value) => setSelectedEmployeeList(value as ISelectOption[])}
        />
        <Autocomplete
          options={ENTITY_LIST}
          fullWidth
          renderInput={props => (
            <TextField {...props} label={'Сущность'} placeholder={'Выберите сущности'} />
          )}
          disableClearable
          disablePortal
          multiple
          onChange={(e, value) => setEntityTypeList(value as ISelectOption[])}
        />
        <Autocomplete
          options={OPERATION_LIST}
          fullWidth
          renderInput={props => (
            <TextField {...props} label={'Операция'} placeholder={'Выберите операции'} />
          )}
          disableClearable
          disablePortal
          multiple
          onChange={(e, value) => setOperationTypeList(value as ISelectOption[])}
        />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <DateTimePicker
            value={minDate ? moment(minDate) : null}
            onChange={(date: Moment) => {
              setMinDate(date ? date?.toISOString(true) : null);
            }}
            maxDate={maxDate ? moment(maxDate) : null}
            renderInput={props => <TextField {...props} label="Дата и время начала *" fullWidth />}
            PopperProps={{
              style: { zIndex: 1000000 },
            }}
          />
          <DateTimePicker
            value={maxDate ? moment(maxDate) : null}
            onChange={(date: Moment) => {
              setMaxDate(date ? date?.toISOString(true) : null);
            }}
            minDate={minDate ? moment(minDate) : null}
            renderInput={props => <TextField {...props} label="Дата и время окончания" fullWidth />}
            PopperProps={{
              style: { zIndex: 1000000 },
            }}
          />
        </LocalizationProvider>
      </Box>

      <ModalFooter
        successButton={{
          title: 'Подтвердить',
          disabled: isProcessing || !minDate,
          handler: () => {
            handleSuccess();
          },
        }}
        denyButton={{
          title: 'Отменить',
          handler: () => {
            handleCancel();
          },
        }}
      />
    </Box>
  );
};

export default observer(ErrorMessageSinglePushForm);
