import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalculateIcon from '@mui/icons-material/Calculate';
import ErrorIcon from '@mui/icons-material/Error';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import { AdminRoutes } from '../../admin/routes';

import { MenuItemsProps } from './type';

function getHeaderMenu(): MenuItemsProps {
  return {
    main: {
      name: 'Главная',
      link: AdminRoutes.Main,
      icon: HomeIcon,
      slug: 'main',
    },

    news: {
      name: 'Новости',
      link: AdminRoutes.News,
      icon: NewspaperIcon,
      slug: 'news',
    },

    users: {
      name: 'Пользователи',
      link: AdminRoutes.Users,
      icon: PeopleIcon,
      slug: 'users',
    },

    quotes: {
      name: 'Котировки',
      link: AdminRoutes.Quotes,
      icon: PeopleIcon,
      slug: 'quotes',
    },

    orgs: {
      name: 'Организации',
      link: AdminRoutes.Organizations,
      icon: CorporateFareIcon,
      slug: 'organizations',
    },

    alerts: {
      name: 'Алерты',
      link: AdminRoutes.Alerts,
      icon: ErrorIcon,
      slug: 'alerts',
    },

    services: {
      name: 'Сервисы',
      link: AdminRoutes.Services,
      icon: AssessmentIcon,
      slug: 'services',
    },

    calculator: {
      name: 'Калькулятор',
      link: AdminRoutes.Calculator,
      icon: CalculateIcon,
      slug: 'calculator',
    },

    dictionary: {
      name: 'Справочники',
      link: AdminRoutes.Dictionary,
      icon: AutoStoriesIcon,
      slug: 'dictionary',
    },

    checklists: {
      name: 'Чек-листы',
      link: AdminRoutes.Checklists,
      icon: FactCheckIcon,
      slug: 'checklists',
    },
  };
}

export { getHeaderMenu };
