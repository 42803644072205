import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import {
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraCropwisePhoto } from '../../models/orgIntegraCropwisePhoto';
import useIntegraCropwiseNavigation from '../../../../hooks/useIntegraCropwiseNavigation';
import { IOrgIntegraEmployee } from '../../../OrgIntegraCropwiseEmployees/models';

const PhotosTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), []);
  const { goToChecklistInstanceTab } = useIntegraCropwiseNavigation({ organizationId });
  const filterConfigRef = useRef<Map<string, TOrganizationIntegraTableFilterItemUnion>>(null);

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });
  const [isSideLoadUpdate] = useState(false);

  const onActionButtonClick = (value: IOrgIntegraCropwisePhoto) => {
    goToChecklistInstanceTab({
      query: {
        checkListInstanceId: value?.report.checkListInstanceId,
      },
    });
  };

  const fetchData: TFetchHandler<IOrgIntegraCropwisePhoto> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);
      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      ...modifiedArgs,
      organizationIdIn: [organizationId],
      page,
      size,
    };

    return service.fetchPhotoList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwisePhoto>
          fetchHandler={fetchData}
          isDisableSelection
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterConfigRef,
            filterCollection: [
              {
                type: 'text',
                label: 'ID точки Адаптера',
                propertyName: 'scoutReportInstanceIdIn',
                isMultiple: true,
              },
              {
                type: 'select',
                label: 'Автор',
                propertyName: 'employeeId',
                optionListFetchHandler: () =>
                  service.fetchEmployeeList({ organizationId, page: 0, size: 1000 }),
                optionMapHandler: (data: IOrgIntegraEmployee) => {
                  return {
                    label: data?.userName || data?.assigneeName || data?.id,
                    value: data?.id,
                    rawData: data,
                  };
                },
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createDateTimeFrom',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createDateTimeTo',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateDateTimeFrom',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateDateTimeTo',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'checklistId',
              headerName: 'ID “Помощник”',
              flex: 1,

              valueGetter: row => row.row.fileId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },

            {
              field: 'cropwiseChecklistId',
              headerName: 'ID “Cropwise”',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.cropwiseFileId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'fileName',
              headerName: 'Название',
              flex: 1,

              valueGetter: row => row.row.fileName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'URL',
              headerName: 'URL',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.fileDownloadUrl,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'author',
              headerName: 'Автор',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.employee.userName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'toChecklist',
              headerName: 'Чек-лист',
              maxWidth: 80,
              valueGetter: row => row.row.id,
              renderCell: row => (
                <Tooltip title="Перейти к чек-листу">
                  <IconButton onClick={() => onActionButtonClick(row.row)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default PhotosTable;
