import { TextField, Autocomplete, Box, Typography } from '@mui/material';
import { Field } from 'formik';
import { debounce } from 'lodash';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TDictionaryItem } from '../../../../../../../../../../../../api/models/dictionaries.model';
import { Colors } from '../../../../../../../../../../../../constants/colors';
import { DictionariesController } from '../../../../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../../../../shared/utils';

import Styled from './styled';

type TProps = {
  value: string;
  name: string;
  label?: string;
  isDisabled?: boolean;
  error?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  changeValueCallback?: (...unknown) => void;
};

const SelectNameOrId: FC<TProps> = ({
  value,
  isDisabled,
  label,
  error,
  setFieldValue,
  changeValueCallback,
  ...props
}) => {
  const [options, setOptions] = useState<TDictionaryItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<TDictionaryItem | string>(value);
  const [inputValue, setInputValue] = useState(value);
  const [isLoading, setIsLoading] = useState(true);
  const [isShouldFetch, setIsShouldFetch] = useState(true);
  const [title, setTitle] = useState<string | null>(null);

  const { getDictionaryItemListByNameOrId } = useStore(DictionariesController);
  const { name: remoteName } = useParams<{ name: string }>();

  const debouncedValue = useCallback(
    debounce(val => {
      if (isShouldFetch) {
        setOptions([]);
        setIsLoading(true);
        getDictionaryItemListByNameOrId(remoteName, val)
          .then(data => {
            setOptions(data.content);
            setIsShouldFetch(false);

            const selectedTitle = data.content.find(dataValue => dataValue.id === inputValue);

            if (selectedTitle) {
              setTitle(`${selectedTitle.name} / ${selectedTitle.id}`);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    }, 500),
    [isShouldFetch]
  );

  useEffect(() => {
    return () => {
      setIsShouldFetch(false);
    };
  }, []);

  useEffect(() => {
    debouncedValue(inputValue);
  }, [inputValue]);

  const onInputChange = useCallback((e, newValue) => {
    setIsShouldFetch(true);
    setInputValue(newValue);
    setTitle(null);
  }, []);

  return (
    <Styled.SelectorWrapper>
      <Field
        component={Autocomplete}
        name={props.name}
        label={label}
        fullWidth
        options={options}
        value={title || selectedValue}
        onChange={(event: any, newValue: TDictionaryItem | null) => {
          setSelectedValue(newValue);
          changeValueCallback?.(props.name, newValue?.id);

          setFieldValue(props.name, newValue?.id);
        }}
        inputValue={title || inputValue}
        onInputChange={onInputChange}
        getOptionLabel={(option: TDictionaryItem | string) =>
          typeof option === 'string' ? option : `${option.name} / ${option.id}`
        }
        renderInput={params => <TextField {...params} label={label} />}
        loading={isLoading}
        isOptionEqualToValue={(option: TDictionaryItem, valId: string) => option.id === valId}
        renderOption={(optionProps, option: TDictionaryItem) => (
          <li {...optionProps}>
            <Box>
              <Typography>{option.name}</Typography>
              <Typography sx={{ fontSize: 12, color: Colors.gray }}>{option.id}</Typography>
            </Box>
          </li>
        )}
        {...(isDisabled ? { readOnly: true, disabled: true } : {})}
      />
      <Styled.ErrorText $visibility={Boolean(error)}>{error}</Styled.ErrorText>
    </Styled.SelectorWrapper>
  );
};

SelectNameOrId.displayName = 'SelectNameOrId';

export default memo(SelectNameOrId);
