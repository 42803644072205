import * as Yup from 'yup';

import { isEmptyContent } from '../utils';
import { EFormFields } from '../../../api/models/news.model';

export const newsFormSchema = Yup.object().shape({
  [EFormFields.newsTitle]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.newsDescription]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.newsContent]: Yup.string()
    .test({
      name: 'newsContent',
      exclusive: true,
      message: `Поле обязательно для заполнения`,
      test: value => isEmptyContent(value),
    })
    .required('Поле обязательно для заполнения'),

  [EFormFields.publicationDate]: Yup.string().required('Поле обязательно для заполнения'),

  [EFormFields.pictureSmall]: Yup.string(),
  [EFormFields.pictureBig]: Yup.string(),
  [EFormFields.pinned]: Yup.bool(),
  [EFormFields.newsTags]: Yup.array(),
  [EFormFields.status]: Yup.string().nullable(),
  [EFormFields.isChange]: Yup.bool(),
  // [EFormFields.authorId]: Yup.string(),
});
