import { TypeQuote } from '../../models/quotes.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = TypeQuote;

type TypeError = {};

export const deleteQuote: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/da-quotes/quotes/${request.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
