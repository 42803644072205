import { Moment } from 'moment';

import { TypeTag } from './tags.model';
import { TypeStatus, EStatus } from './status.model';

export type TypeNewsSort = {
  field: string;
  sort: string;
};

export type TypeSort = {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
};

export type FormValueError = {
  source: string;
  title: string;
};

export interface Avatar {
  id: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  downloadUrl: string;
}

export interface Specialization {
  id: number;
  name: string;
  description: string;
}

export interface Author {
  publicId: string;
  firstName: string;
  secondName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  avatar: Avatar;
  specialization: Specialization;
}

export interface Permissions {
  canEdit: boolean;
  editLink: string;
  canDelete: boolean;
  canDeleteToArchive: boolean;
  canSetPublicationDate: boolean;
}

export type TypeNews = {
  newsId: string;
  pictureSmall: Avatar;
  pictureBig: Avatar;
  newsTitle: string;
  newsDescription: string;
  newsContent: string;
  pinned: boolean;
  publicationDate: string;
  views: number;
  likes: number;
  likedByMe: boolean;
  comments: number;
  status: TypeStatus;
  author: Author;
  newsTags: TypeTag[];
  permissions: Permissions;
};

export enum EFormFields {
  newsTitle = 'newsTitle',
  pictureSmall = 'pictureSmall',
  pictureBig = 'pictureBig',
  newsDescription = 'newsDescription',
  newsContent = 'newsContent',
  pinned = 'pinned',
  publicationDate = 'publicationDate',
  newsTags = 'newsTags',
  status = 'status',
  isChange = 'isChange',
  // authorId = 'authorId',
}

export interface IFormValues {
  [EFormFields.newsTitle]: string;
  [EFormFields.pictureSmall]: string;
  [EFormFields.pictureBig]: string;
  [EFormFields.newsDescription]: string;
  [EFormFields.newsContent]: any;
  [EFormFields.pinned]: boolean;
  [EFormFields.publicationDate]: Moment;
  [EFormFields.newsTags]: TypeTag[];
  [EFormFields.status]: EStatus;
  [EFormFields.isChange]: boolean;
  // [EFormFields.authorId]: string;
}
