import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../../../../../../../../../constants/colors';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 8px;
`;

const ErrorText = styled.div<{ visibility?: boolean }>`
  font-size: 12px;
  color: ${Colors.red};

  ${({ visibility }) =>
    visibility
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

const Styled = {
  Row,
  ButtonGroup,
  ErrorText,
};

export default Styled;
