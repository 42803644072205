import * as Yup from 'yup';

export enum EInvitationCreateForm {
  email = 'email',
  role = 'role',
  comment = 'comment',
}

export const invitationCreateFormSchema = Yup.object().shape({
  [EInvitationCreateForm.email]: Yup.string()
    .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
    .email('Невалидный адрес почты')
    .required('Поле обязательно для заполнения'),

  [EInvitationCreateForm.role]: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })
    .nullable()
    .required('Поле обязательно для заполнения'),

  [EInvitationCreateForm.comment]: Yup.string().max(
    200,
    ({ max }) => `Максимальное значение поля ${max} символов`
  ),
});
