import {
  ICreateOrgIntegraAgrosignalConfig,
  IUpdateOrgIntegraAgrosignalConfig,
} from '../../../../../../../../../../api/models/integra/config.model';
import { ERequestStatus } from '../../../../../../../../../shared/types/requests';
import {
  Axios,
  TypeApiRequest,
  lazyInject,
  provide,
} from '../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalStore } from '../stores';

@provide.transient()
class OrgIntegraAgrosignalService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrgIntegraAgrosignalStore)
  protected store: OrgIntegraAgrosignalStore;

  fetchConfigById = (organizationId: string) => {
    return this.axios.api
      .getOrgIntegraConfigByOrgId({ organizationId }, { omit: ['organizationId'] })
      .then(data => {
        this.store.setOrgIntegraConfig(data);

        this.store.setConfigRequestStatus(ERequestStatus.Fulfilled);
        this.store.setConfigExists(true);

        return data;
      })
      .catch(e => {
        this.store.setConfigRequestStatus(ERequestStatus.Rejected);
        this.store.setConfigExists(false);

        return e;
      });
  };

  createConfig = (data: ICreateOrgIntegraAgrosignalConfig) => {
    return this.axios.api.createOrgIntegraAgrosignalConfig(data);
  };

  updateConfig = (data: Partial<IUpdateOrgIntegraAgrosignalConfig>) => {
    return this.axios.api.updateOrgIntegraAgrosignalConfig(data);
  };

  fetchFieldList = (payload: TypeApiRequest<'getOrgIntegraFieldListByOrgId'>) => {
    return this.axios.api.getOrgIntegraFieldListByOrgId(payload);
  };

  createField = (payload: TypeApiRequest<'createOrgIntegraAgrosignalField'>) => {
    return this.axios.api.createOrgIntegraAgrosignalField(payload);
  };

  updateField = (payload: TypeApiRequest<'updateOrgIntegraAgrosignalField'>) => {
    return this.axios.api.updateOrgIntegraAgrosignalField(payload);
  };

  deleteFieldList = (payload: TypeApiRequest<'deleteOrgIntegraAgrosignalFieldList'>) => {
    return this.axios.api.deleteOrgIntegraAgrosignalFieldList(payload);
  };

  fetchEmployeeList = (payload: TypeApiRequest<'getOrgIntegraEmployeeListByOrgId'>) => {
    return this.axios.api.getOrgIntegraEmployeeListByOrgId(payload, {
      omit: ['organizationId'],
    });
  };

  createEmployee = (payload: TypeApiRequest<'createOrgIntegraAgrosignalEmployee'>) => {
    return this.axios.api.createOrgIntegraAgrosignalEmployee(payload);
  };

  updateEmployee = (payload: TypeApiRequest<'updateOrgIntegraAgrosignalEmployee'>) => {
    return this.axios.api.updateOrgIntegraAgrosignalEmployee(payload);
  };

  deleteEmployeeList = (payload: TypeApiRequest<'deleteOrgIntegraAgrosignalEmployeeList'>) => {
    return this.axios.api.deleteOrgIntegraAgrosignalEmployeeList(payload);
  };

  fetchChecklistList = (payload: TypeApiRequest<'getOrgIntegraAgrosignalChecklistByOrgId'>) => {
    return this.axios.api.getOrgIntegraAgrosignalChecklistByOrgId(payload);
  };

  createChecklist = (payload: TypeApiRequest<'createOrgIntegraAgrosignalChecklist'>) => {
    return this.axios.api.createOrgIntegraAgrosignalChecklist(payload);
  };

  updateChecklist = (payload: TypeApiRequest<'updateOrgIntegraAgrosignalChecklist'>) => {
    return this.axios.api.updateOrgIntegraAgrosignalChecklist(payload);
  };

  deleteChecklistList = (payload: TypeApiRequest<'deleteOrgIntegraAgrosignalChecklistList'>) => {
    return this.axios.api.deleteOrgIntegraAgrosignalChecklistList(payload);
  };

  fetchChecklistInstanceList = (
    payload: TypeApiRequest<'getOrgIntegraAgrosignalChecklistInstanceListByOrgId'>
  ) => {
    return this.axios.api.getOrgIntegraAgrosignalChecklistInstanceListByOrgId(payload);
  };

  fetchErrorMessageList = (
    payload: TypeApiRequest<'getOrgIntegraAgrosignalErrorMessagesByOrgId'>
  ) => {
    return this.axios.api.getOrgIntegraAgrosignalErrorMessagesByOrgId(payload, {
      omit: ['organizationId'],
    });
  };

  deleteErrorMessageList = (
    payload: TypeApiRequest<'deleteOrgIntegraAgrosignalErrorMessageList'>
  ) => {
    return this.axios.api.deleteOrgIntegraAgrosignalErrorMessageList(payload);
  };

  updateErrorMessage = (payload: TypeApiRequest<'updateOrgIntegraAgrosignalMessage'>) => {
    return this.axios.api.updateOrgIntegraAgrosignalMessage(payload);
  };

  refreshAgrosignalFields = (payload: TypeApiRequest<'getOrgIntegraAgrosignalAgroFields'>) => {
    return this.axios.api.getOrgIntegraAgrosignalAgroFields(payload);
  };

  getOrgIntegraAgrosignalAgroFieldList = (
    payload: TypeApiRequest<'getOrgIntegraAgrosignalAgroFieldList'>
  ) => {
    return this.axios.api.getOrgIntegraAgrosignalAgroFieldList(payload, {
      omit: ['organizationId'],
    });
  };

  getOrgIntegraAgrosignalAssistanceFieldList = (
    payload: TypeApiRequest<'getOrgIntegraAgrosignalAssistanceFieldList'>
  ) => {
    return this.axios.api.getOrgIntegraAgrosignalAssistanceFieldList(payload, {
      omit: ['organizationId'],
    });
  };

  fetchErrorFileList = (payload: TypeApiRequest<'getOrgIntegraAgrosignalErrorFileByOrgId'>) => {
    return this.axios.api.getOrgIntegraAgrosignalErrorFileByOrgId(payload);
  };

  deleteErrorFileList = (payload: TypeApiRequest<'deleteOrgIntegraAgrosignalFileErrorList'>) => {
    return this.axios.api.deleteOrgIntegraAgrosignalFileErrorList(payload);
  };

  updateErrorFile = (payload: TypeApiRequest<'updateOrgIntegraAgrosignalErrorFile'>) => {
    return this.axios.api.updateOrgIntegraAgrosignalErrorFile(payload);
  };
}

export default OrgIntegraAgrosignalService;
