import { TFileEntity } from '../../modules/shared/types/requests';

import { EEmployeeRole, RoleInfo } from './employee.model';

export enum EUserPhoneStatus {
  NOTCONFIRMED = 'NOT_CONFIRMED',
  REQUESTSENT = 'REQUEST_SENT',
  CONFIRMED = 'CONFIRMED',
}

export enum EInvitationStatus {
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
}

export interface PhoneNumberStatus {
  statusId: EUserPhoneStatus;
  statusName: string;
}

export interface IInvitationUserPhone {
  phoneNumberCode: string;
  phoneNumber: string;
  phoneNumberStatus: PhoneNumberStatus;
}

interface IInvitationUserAvatar extends TFileEntity {
  downloadUrl: string;
  fileUploadDate: string;
  fileUploadUserId: string;
}

/**
 * Модель приглашения в организацию
 *
 * @DTO InvitationForListDto
 * @interface IInvitation
 */
export interface IInvitation {
  invitationId: string;
  status: EInvitationStatus;
  userName: string;
  userEmail: string;
  phone: IInvitationUserPhone;
  userAvatar: IInvitationUserAvatar;
  employeeRole: EEmployeeRole;
  role: RoleInfo;
  comment: string;
  creationDate: string;
  createdBy: {
    userName: string;
    userId: string;
  };
  updatedDate: string;
}
