import { IFieldReportConfig } from '../../../../models/reportConfig.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = Partial<IFieldReportConfig>;

type TypeResponse = string;

export const createAssistanceFieldReportsConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/reports/field/config/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
