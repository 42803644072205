import { ApiNames } from './../../../../../../../../shared/utils/axios2';
import { ISelectOption } from './../../../../../../../../../types/selectOption';
export interface IAttributeFormItem {
  name?: string;
  type: EFormItemType;
  placeholder?: string;
  label?: string;
  value?: any;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export enum EFormItemType {
  String = 'String',
  Number = 'Number',
  Switch = 'Switch',
  Textarea = 'Textarea',
  Date = 'Date',
  Select = 'Select',
  Autocomplete = 'Autocomplete',
  Custom = 'Custom',
}

export type TAttributeFormItemUnion =
  | IAttributeFormItemString
  | IAttributeFormItemNumber
  | IAttributeFormItemSwitch
  | IAttributeFormItemTextarea
  | IAttributeFormItemDate
  | IAttributeFormItemSelect
  | IAttributeFormItemAutocomplete
  | IAttributeFormItemCustom;

interface IAttributeFormItemString extends IAttributeFormItem {
  value?: string;
  onChange?: (value?: string) => void;

  type: EFormItemType.String;
}

interface IAttributeFormItemNumber extends IAttributeFormItem {
  value?: number | string;
  onChange?: (value?: number | string) => void;

  type: EFormItemType.Number;
}

interface IAttributeFormItemSwitch extends IAttributeFormItem {
  value?: boolean;
  onChange?: (value?: boolean) => void;

  type: EFormItemType.Switch;
}

interface IAttributeFormItemTextarea extends IAttributeFormItem {
  value?: string;
  onChange?: (value?: string) => void;

  type: EFormItemType.Textarea;
}

interface IAttributeFormItemDate extends IAttributeFormItem {
  value?: Date;
  onChange?: (value?: Date) => void;

  type: EFormItemType.Date;
}

interface IAttributeFormItemSelect extends IAttributeFormItem {
  value?: ISelectOption | string;
  onChange?: (value?: ISelectOption | string) => void;
  optionList: ISelectOption[];

  type: EFormItemType.Select;
  isClearable?: boolean;
}

interface IAttributeFormItemAutocomplete extends IAttributeFormItem {
  value?: ISelectOption | string;
  onChange?: (value?: ISelectOption | string) => void;
  fetchHandlerApiName?: ApiNames;

  type: EFormItemType.Autocomplete;
  isClearable?: boolean;
}

interface IAttributeFormItemCustom extends IAttributeFormItem {
  value?: ISelectOption | string;
  onChange?: (value?: ISelectOption | string) => void;

  type: EFormItemType.Custom;
}
