import { FC } from 'react';

import { IChecklistStage } from '../../../../../../../../../../api/models/checklist.stage.model';
import Stage from '../Stage/Stage';

interface IProps {
  stageList: IChecklistStage[];
}

const StageList: FC<IProps> = ({ stageList }) => {
  return (
    <div>
      {stageList.map(stage => (
        <Stage key={stage.id} stageData={stage} />
      ))}
    </div>
  );
};

export default StageList;
