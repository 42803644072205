import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridEvents } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import { TDictionaryItem } from '../../../../../../../../../../../../../../api/models/dictionaries.model';
import { AdminRoutes } from '../../../../../../../../../../../../routes';

const History = () => {
  const { name: remoteName, itemId } = useParams<{ name: string; itemId: string }>();
  const history = useHistory();
  const { getAllDictionariesByParams } = useStore(DictionariesController);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAllDictionariesByParams(remoteName, {
      remoteName,
      idIn: [itemId],
      fetchAttributes: true,
      withAuthor: true,
      latestVersion: false,
    }).then((data: TDictionaryItem[]) =>
      setRows(data.map(item => ({ ...item, id: item.version })))
    );
  }, [itemId]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Наименование',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'code',
        headerName: 'Код',
        minWidth: 60,
      },
      {
        field: 'path',
        headerName: 'Path',
        minWidth: 70,
      },
      {
        field: 'status',
        headerName: 'Статус',
        width: 100,
      },
      {
        field: 'attrs',
        headerName: 'Доп. атрибуты',
        minWidth: 150,
        maxWidth: 300,
        renderCell: params => {
          if (!params.value) {
            return null;
          }

          return (
            <Tooltip title={JSON?.stringify(params.value, null, 2)?.slice(1, -1)}>
              {<Typography noWrap>{Object.keys(params.value).join(', ')}</Typography>}
            </Tooltip>
          );
        },
      },
      {
        field: 'original',
        headerName: 'Оригинал',
        width: 100,
        renderCell: params => {
          if (params.value) {
            return <CheckRoundedIcon />;
          } else {
            return <CloseRoundedIcon />;
          }
        },
      },
      {
        field: 'version',
        headerName: 'Версия',
        width: 80,
      },
      {
        field: 'authorFullName',
        headerName: 'Автор',
        width: 150,
        flex: 1,
      },
      {
        field: 'editDate',
        headerName: 'Дата обновления',
        width: 150,
      },
    ],
    []
  );

  const handleRowClick = useCallback<GridEventListener<GridEvents.rowClick>>(params => {
    history.push(
      generatePath(AdminRoutes.DictionarySelectedItemHistory, {
        itemId,
        name: remoteName,
        version: params.id,
      })
    );
  }, []);

  return (
    <div style={{ display: 'flex', height: '95%', minHeight: '500px' }}>
      <div style={{ flexGrow: 1 }}>
        {rows.length > 0 ? (
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            localeText={{ noRowsLabel: 'Нет результатов' }}
            onRowClick={handleRowClick}
          />
        ) : (
          'Нет записей в истории'
        )}
      </div>
    </div>
  );
};

export default History;
