import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { isObject, maxBy } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';

import { Colors } from '../../../../../../../../../../../constants/colors';
import { ChecklistCRUDController } from '../../../../../../../../../../controllers/checklist.CRUD.controller';
import { useStore } from '../../../../../../../../../../shared/utils';

const CreateStageModal: FC = observer(() => {
  const {
    changeStageData,
    stageData,
    createChecklistStage,
    changeChecklistStage,
    currentChecklistStageList,
    changeCurrentEditStage,
  } = useStore(ChecklistCRUDController);
  const { closeModal } = useModal();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!stageData.id) {
      const stageHighestOrder =
        Number(maxBy(currentChecklistStageList, item => item.order)?.order) + 1 || 0;

      changeCurrentEditStage('order', stageHighestOrder.toString());
    }
  }, []);

  const createStage = useCallback(() => {
    if (stageData?.isCreationMode) {
      createChecklistStage()
        .then(() => {
          closeModal();
        })
        .catch((e: Error) => {
          setError(e?.message || 'Ошибка создания группировки');
        });
    } else {
      changeChecklistStage()
        .then(() => {
          closeModal();
        })
        .catch((e: Error) => {
          setError(e?.message || 'Ошибка редактирования группировки');
        });
    }
  }, [stageData?.isCreationMode]);

  return (
    <div>
      <Typography>
        Наименование группировки будет выводиться пользователю в WEB и мобильном приложении в
        порядке который будет указан в поле "Номер по порядку"
      </Typography>
      <Divider />
      <Box display="flex" gap={2} margin="12px 0">
        <TextField
          label="Номер по порядку"
          placeholder="Введите N"
          type="number"
          required
          fullWidth
          onChange={changeStageData}
          value={stageData?.order || '0'}
          defaultValue={0}
          name="order"
          sx={{ maxWidth: '155px' }}
        />
        <TextField
          label="Наименование группировки"
          placeholder="Введите наименование группировки"
          type="text"
          required
          fullWidth
          name="name"
          onChange={changeStageData}
          value={stageData?.name || ''}
        />
        <TextField
          label="ID группировки"
          type="text"
          onChange={changeStageData}
          disabled
          fullWidth
          value={stageData?.id || ''}
        />
      </Box>
      <Box display="flex" gap={2} margin="12px 0">
        <TextField
          label="Иконка для дефолтного состояния"
          type="text"
          onChange={changeStageData}
          name="picLinkDefault"
          fullWidth
          value={
            (isObject(stageData?.picLinkDefault)
              ? stageData?.picLinkDefault.id
              : stageData?.picLinkDefault) || ''
          }
        />
        <TextField
          label="Иконка для заполненного состояния"
          type="text"
          onChange={changeStageData}
          name="picLinkSuccess"
          fullWidth
          value={
            (isObject(stageData?.picLinkSuccess)
              ? stageData?.picLinkSuccess.id
              : stageData?.picLinkSuccess) || ''
          }
        />
        <TextField
          label="Иконка для некорректно заполненного состояния"
          type="text"
          onChange={changeStageData}
          name="picLinkError"
          fullWidth
          value={
            (isObject(stageData?.picLinkError)
              ? stageData?.picLinkError.id
              : stageData?.picLinkError) || ''
          }
        />
      </Box>
      <Box>
        <Typography color={Colors.red} fontSize={12}>
          {error}
        </Typography>
      </Box>

      <ModalFooter
        successButton={{
          title: 'Сохранить',
          handler: () => {
            createStage();
          },
        }}
        denyButton={{
          title: 'Закрыть',
          handler: () => {
            closeModal();
          },
        }}
      />
    </div>
  );
});

export default CreateStageModal;
