import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { CROPWISE_REPORT_MODAL_ID } from '../../../modals';
import { useStore } from '../../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../../../../shared/utils/downloadBlobAsFile';
import { CropwiseReportController } from '../mobx';
import { ICropwiseConfig } from '../models';

const useUploadTriggers = ({ organizationId }: { organizationId: string }) => {
  const controller = useStore(CropwiseReportController);

  const { openModalByModalId, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const downloadReport = () => {
    openModalByModalId(
      CROPWISE_REPORT_MODAL_ID,
      {
        title: 'Формирование отчёта',
      },
      ({ fromDate, toDate, seasons }) => {
        controller
          .downloadCropwiseReport({
            organizationId,
            fromDate,
            toDate,
            seasons: seasons.map(Number),
          })
          .then(data => {
            downloadBlobAsFile(
              data,
              `Отчёт Cropwise за ${seasons.join('_')} ${fromDate} - ${toDate}.zip`
            );
            closeModal();
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка формирования отчёта', ENotificationStyles.Error)
            );
          });
      }
    );
  };

  return { downloadReport };
};

export default useUploadTriggers;
