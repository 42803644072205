import { FC, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  List,
  ListItem,
  Checkbox,
  Avatar,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Box,
  CircularProgress,
} from '@mui/material';

import { useStore } from '../../../../../../../shared/utils';
import { EmployeeController } from '../../../../../../../controllers/employees.controller';
import { EmployeeStore } from '../../../../../../../stores/employee/employee.store';
import { FilterStore } from '../../../../../../../stores/employee/filter.store';
import { ReactComponent as Success } from '../../../../../../../shared/assets/images/success.svg';
import { ReactComponent as Cancel } from '../../../../../../../shared/assets/images/cancel.svg';

type Props = {
  organizationID: string;
  onClose: () => void;
};

enum Mode {
  FORM = 'FORM',
  RESULT = 'RESULT',
}

const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: '#FAF9FD',
      color: '#151F32',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

export const EmployeesDialogContent: FC<Props> = observer(({ organizationID, onClose }) => {
  const { fetchEmployees, setFilterFullname } = useStore(EmployeeController);
  const { employees, clearEmployee, isLoading } = useStore(EmployeeStore);
  const {
    filters: { fullname },
  } = useStore(FilterStore);

  const [mode, setMode] = useState<Mode>(Mode.FORM);
  const [success, setSuccess] = useState<boolean>(false);

  const [userId, setUserId] = useState('');

  const handleToggle = (v: string) => () => {
    const value = userId !== v ? v : '';

    setUserId(value);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      clearEmployee();
      setUserId('');
      setFilterFullname('');
      setMode(Mode.FORM);
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    fetchEmployees({ organizationID });
  }, [fullname]);

  const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFullname(event.target.value);
  };

  const handleAppoint = useCallback(() => {
    // Нужна ручка для смены владельца
    const payload = {
      userId,
      organizationId: organizationID,
    };

    setMode(Mode.RESULT);
    setSuccess(true);

    console.log('payload', payload);
  }, []);

  const renderResult = () => (
    <>
      {!success ? (
        <>
          <DialogTitle>
            <Cancel />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Ошибка смены пользователя</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Закрыть</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <Success />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Пользователь сменен</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Закрыть</Button>
          </DialogActions>
        </>
      )}
    </>
  );

  const renderEmployees = () => {
    return (
      <>
        <DialogTitle>Сменить пользователя</DialogTitle>
        <Box sx={{ padding: '6px 60px 16px 60px', borderBottom: '1px solid #E9E6F0' }}>
          <TextField
            fullWidth
            value={fullname}
            placeholder="Введите ФИО сотрудника"
            label="Поиск сотрудника"
            onChange={handleFullnameChange}
            variant="outlined"
            disabled={isLoading}
          />
        </Box>
        <DialogContent sx={{ borderBottom: '1px solid #E9E6F0' }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <List dense sx={{ width: '100%' }}>
              {employees.map(item => (
                <ListItem
                  key={item.userId}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(item.userId)}
                      checked={userId === item.userId}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton onClick={handleToggle(item.userId)}>
                    <ListItemAvatar>
                      {item.avatar && item.avatar.downloadUrl ? (
                        <Avatar src={item.avatar.downloadUrl} />
                      ) : (
                        <Avatar {...stringAvatar(item.userName)} />
                      )}
                    </ListItemAvatar>
                    <ListItemText id={item.userId} primary={item.userName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Отменить
          </Button>
          <Button onClick={handleAppoint} disabled={isLoading || isEmpty(userId)}>
            Назначить
          </Button>
        </DialogActions>
      </>
    );
  };

  return <>{mode === Mode.FORM ? renderEmployees() : renderResult()}</>;
});
