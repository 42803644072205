import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { FieldTable } from '../components';
import { fieldLoadModalConfig, fieldModalConfig } from '../modals';

const OrgIntegraAgrosignalFields = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList([fieldModalConfig, fieldLoadModalConfig], 'OrgIntegraAgrosignalFields');
  }, []);

  return (
    <Box>
      <FieldTable />
    </Box>
  );
};

export default OrgIntegraAgrosignalFields;
