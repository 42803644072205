import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { AdminRoutes, ERegexp } from '../../admin/routes';
import { getLink } from '../utils';

import { MenuItemsProps } from './type';

function getUserMenu(userId: string): MenuItemsProps {
  return {
    back: {
      name: 'Вернуться назад',
      link: AdminRoutes.Users,
      icon: KeyboardArrowLeftIcon,
      slug: 'users-back',
    },

    edit: {
      name: 'Персональные данные',
      link: getLink(AdminRoutes.UserEdit, { [userId]: ERegexp.USERID }),
      slug: 'profile',
    },

    orgs: {
      name: 'Организации',
      link: getLink(AdminRoutes.UserOrganizations, { [userId]: ERegexp.USERID }),
      slug: 'organizations',
    },

    news: {
      name: 'Публикации',
      link: getLink(AdminRoutes.UserNews, { [userId]: ERegexp.USERID }),
      slug: 'news',
    },

    journal: {
      name: 'Журнал сессий',
      link: getLink(AdminRoutes.UserJournal, { [userId]: ERegexp.USERID }),
      slug: 'journal',
    },
  };
}

export { getUserMenu };
