import { TypeFilters, TypeQuote } from '../../models/quotes.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = TypeFilters & {
  size: number;
  page: number;
  noCultureClass?: boolean;
};

type TypeResponse = { content: TypeQuote[] } & TypeResponsePageable;

type TypeError = {};

export const getQuotesFulllist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request =>
    `/api/da-quotes/quotes/fullList?page=${request.page}&size=${
      request.size
    }&noCultureClass=${Boolean(request.noCultureClass)}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
