import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const DELETE_INVITATION_MODAL_ID = 'delete-invitation-modal';

const deleteInvitationModalConfig: TModalConfig = {
  id: DELETE_INVITATION_MODAL_ID,
  name: DELETE_INVITATION_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value: 'Вы уверены, что хотите отозвать приглашение?',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Отозвать',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { DELETE_INVITATION_MODAL_ID, deleteInvitationModalConfig };
