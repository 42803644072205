export enum ERequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export type TFileEntity = {
  id: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  downloadUrl: string;
};

export interface IPaginationOptions {
  /**
   * Номер страницы
   */
  page: number;
  /**
   * Кол-во элементов на странице
   *
   * default: 20
   */
  pageSize: 10 | 20 | 50 | 100 | number;
  /**
   * Общее кол-во сущностей на беке
   */
  rowCount: number;
}
