import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CopyChecklistModal } from '../components';

export const COPY_CHECKLIST_MODAL = 'copyChecklistModal';

export const copyChecklistModal: TModalConfig = {
  id: COPY_CHECKLIST_MODAL,
  name: COPY_CHECKLIST_MODAL,
  type: EModalType.Custom,
  title: 'Копирование чек-листа',
  children: CopyChecklistModal,
  closeOnClickOutside: true,

  styledProps: {
    $modalPaddings: '20px',
    $size: '90%',
    $isFullContentWidth: true,
    $height: '100%',
  },
};
