import { FC, memo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';

import { AdminRoutes } from '../../../../routes';
import { useStore } from '../../../../../shared/utils';

import { ReferencesContainer } from './containers';
import { beforeExitServiceReferenceModalConfig } from './modals';
import { ReferenceStore } from './mobx';

export const ReferencesRouter: FC = () => {
  const { registerModalList } = useModal();
  const { clearStore } = useStore(ReferenceStore);

  useEffect(() => {
    registerModalList([beforeExitServiceReferenceModalConfig], 'referenceService');

    return () => {
      clearStore();
    };
  }, []);

  return (
    <Switch>
      <Route path={[AdminRoutes.ReferencesService]} component={ReferencesContainer} />
    </Switch>
  );
};

export default memo(ReferencesRouter);
