import { Tooltip, Typography } from '@mui/material';
import { memo, useMemo } from 'react';

interface ITableCellProps {
  value: string;
  isDisplayTooltip?: boolean;
  isMiddleEllipsis?: boolean;
}

const TableCell = ({ value, isDisplayTooltip, isMiddleEllipsis }: ITableCellProps) => {
  const preparedData = useMemo(() => {
    if (!isMiddleEllipsis) {
      return value;
    }

    const ellipsisTuplePosition: [number, number] = [0, 0];
    ellipsisTuplePosition[0] = Math.round(
      value.length / (value.length > 10 ? value.length / 6 : 3)
    );
    ellipsisTuplePosition[1] = ellipsisTuplePosition[0] * 2;

    return `${value.slice(0, ellipsisTuplePosition[0])}...${value.slice(
      -ellipsisTuplePosition[1]
    )}`;
  }, [isMiddleEllipsis, value]);

  if (isDisplayTooltip) {
    return (
      <Tooltip title={value}>
        <Typography fontSize={'0.875rem'}>{preparedData}</Typography>
      </Tooltip>
    );
  }

  return <Typography fontSize={'0.875rem'}>{preparedData}</Typography>;
};

export default memo(TableCell);
