import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';

@provide.singleton()
export class AuthorizationStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
    document.addEventListener('logout', () => {
      this.logout();
    });
  }

  loading = false;
  logged = false;
  loaded = false;
  accessToken = false;
  checkUser = false;
  admin = false;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get isLoggedIn() {
    return !this.loading && this.loaded && this.logged;
  }

  get isLoggedOut() {
    return !this.loading && this.loaded && !this.logged;
  }

  get isAdmin() {
    return !this.loading && this.loaded && this.admin;
  }

  setLoaded = () => {
    setTimeout(() => {
      runInAction(() => {
        this.loading = false;
        this.loaded = true;
      });
    }, 2000);
  };

  passVerifyProfile = (isAdmin: boolean) => {
    if (!isAdmin) {
      this.admin = false;
      this.loading = false;
      this.loaded = true;
      this.logged = true;
      return;
    }

    this.admin = true;
    this.loading = false;
    this.loaded = true;
    this.logged = true;
    this.checkUser = true;
  };

  verifyAccessToken = async () => {
    try {
      await this.axios.api.verifyAccessToken({});
      this.accessToken = true;
    } catch (e) {
      this.accessToken = false;
      this.logout();
    }

    return this.accessToken;
  };

  checkIsUserLoginned = async () => {
    this.loading = true;
    this.loaded = false;

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      this.setLoaded();
      return false;
    }

    try {
      const isToken = await this.verifyAccessToken();

      if (isToken) {
        const profile = await this.axios.api.getProfile({});
        this.passVerifyProfile(profile.isAdmin);
      }
    } catch (e) {
      this.logout();
    } finally {
      this.setLoaded();
    }

    return this.logged;
  };

  logout = () => {
    this.loading = false;
    this.loaded = false;
    this.logged = false;
    this.accessToken = false;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };
}
