import { useModal } from '@farmlink/farmik-ui';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

import { EDictionaryItemStatus } from '../../../../../../api/models/dictionaries.model';
import { ISelectOption } from '../../../../../../types/selectOption';
import { CheckListsController } from '../../../../../controllers/check.list.controller';
import { ChecklistsAttributeController } from '../../../../../controllers/checklistAttribute.controller';
import { DictionariesController } from '../../../../../controllers/dictionaries.controller';
import { OrganizationsController } from '../../../../../controllers/organizations.controller';
import { useStore } from '../../../../../shared/utils';
import { Layout } from '../../../../components';
import { AdminRoutes } from '../../../../routes';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../style';
import { AdvancedGrid } from '../../common';
import { AdvancedGridStore } from '../../common/AdvancedGrid/mobx';
import { getChecklistAttributesFilterConfig } from '../../helpers/getChecklistAttributesFilterConfig';
import { getChecklistAttributesListingGridColumns } from '../../helpers/getChecklistAttributesListingGridColumns';
import { getChecklistFilterConfig } from '../../helpers/getChecklistFilterConfig';
import { getChecklistListingGridColumns } from '../../helpers/getChecklistListingGridColumns';
import ChecklistSidebarContainer from '../ChecklistSidebarContainer/ChecklistSidebarContainer';

import { getChecklistExportModalConfig, getChecklistImportModalConfig } from './modals';
import Styled from './styled';

interface ISelectedTabEntity extends ISelectOption {
  slug: string;
}

const LISTING_TABS: ISelectedTabEntity[] = [
  {
    label: 'Чек-листы',
    value: 'checklists',
    slug: AdminRoutes.Checklists,
  },
  { label: 'Атрибуты', value: 'attributes', slug: AdminRoutes.ChecklistAttributes },
];

const ListingContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { registerModalList, openModalByModalId } = useModal();
  const { getChecklistAttributesByParams } = useStore(ChecklistsAttributeController);
  const { getChecklistListByParams } = useStore(CheckListsController);
  const { setGridMapItem, setCurrentGridId, setIsLoading } = useStore(AdvancedGridStore);
  const { getAllDictionaries, getAllDictionariesByParams } = useStore(DictionariesController);
  const { fetchOrganizations, fetchOrganizationListByParams } = useStore(OrganizationsController);

  const [advancedGridConfig, setAdvancedGridConfig] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const IOModalsList = useMemo(
    () => [getChecklistImportModalConfig(), getChecklistExportModalConfig()],
    []
  );

  useEffect(() => {
    registerModalList(IOModalsList, 'ChecklistIOModals');
  }, []);

  const currentTab = useMemo<ISelectedTabEntity>(
    () =>
      LISTING_TABS.find(tab =>
        matchPath(location.pathname, {
          path: tab.slug,
          exact: true,
        })
      ),
    [location.pathname]
  );

  const handleChangeTab = useCallback<(item: ISelectedTabEntity) => void>(item => {
    history.push(generatePath(item.slug));
  }, []);

  const handleAddClick = useCallback(() => {
    if (currentTab.value === 'attributes') {
      return history.push(generatePath(AdminRoutes.ChecklistBasicAttributeCreate));
    }

    if (currentTab.value === 'checklists') {
      return history.push(generatePath(AdminRoutes.ChecklistCreate));
    }
  }, [currentTab.value]);

  const fetchOrganizationsOnChange = (value: string): Promise<ISelectOption[]> => {
    return fetchOrganizationListByParams({ page: 0, nameOrInn: value, size: 50 }).then(data =>
      data.map(item => ({
        label: `${item.name}${item?.INN ? `/ ${item?.INN}` : ''}`,
        value: item.organizationId,
      }))
    );
  };

  useEffect(() => {
    setIsDataLoading(true);
    if (currentTab.value === 'attributes') {
      getAllDictionaries().then(data => {
        const filterConfig = getChecklistAttributesFilterConfig(
          getChecklistAttributesByParams,
          data.content.map(dictionary => ({
            label: dictionary.title,
            value: dictionary.remoteName,
          })),
          id => {
            window
              .open(generatePath(AdminRoutes.ChecklistBasicAttribute, { attributeId: id }))
              .focus();
          }
        );

        setGridMapItem('attributes', {
          gridColumns: getChecklistAttributesListingGridColumns(),
          filter: filterConfig.filter,
        });
        setCurrentGridId('attributes');
        setIsLoading(true);
        setAdvancedGridConfig(filterConfig);
        setIsDataLoading(false);
      });
    }

    if (currentTab.value === 'checklists') {
      Promise.all([
        getAllDictionariesByParams('techOperationType', {
          remoteName: 'techOperationType',
          latestVersion: true,
          fetchAttributes: true,
          status: EDictionaryItemStatus.Active,
          originalOnly: true,
        }),
        getAllDictionariesByParams('culture', {
          remoteName: 'culture',
          latestVersion: true,
          fetchAttributes: true,
          attrs: { useInAssistance: true },
          status: EDictionaryItemStatus.Active,
          originalOnly: true,
        }),
        getAllDictionariesByParams('phenophase', {
          remoteName: 'phenophase',
          latestVersion: true,
          status: EDictionaryItemStatus.Active,
          originalOnly: true,
        }),
        fetchOrganizations(),
      ]).then(([techOperationList, cultureList, phenophaseList, organizationsList]) => {
        const operationTypeOptions: ISelectOption[] = techOperationList?.map(item => ({
          label: item.name,
          value: item.id,
        }));

        const cultureOptions: ISelectOption[] = cultureList?.map(item => ({
          label: item.name,
          value: item.id,
        }));

        const phenophaseOptions: ISelectOption[] = phenophaseList?.map(item => ({
          label: item.name,
          value: item.id,
        }));

        const organizationOptions: ISelectOption[] = organizationsList?.map(item => ({
          label: item.name,
          value: item.organizationId,
        }));

        const filterConfig = getChecklistFilterConfig(
          getChecklistListByParams,
          {
            cultureOptions,
            operationTypeOptions,
            organizationOptions,
            phenophaseOptions,
          },
          id => {
            window
              .open(generatePath(AdminRoutes.SelectedChecklist, { checklistId: id }), '_blank')
              .focus();
          },
          fetchOrganizationsOnChange
        );

        setGridMapItem('checklists', {
          gridColumns: getChecklistListingGridColumns(),
          filter: filterConfig.filter,
        });
        setCurrentGridId('checklists');
        setIsLoading(true);
        setAdvancedGridConfig(filterConfig);
        setIsDataLoading(false);
      });
    }
  }, [currentTab.value]);

  return (
    <div>
      <Styled.SubHeader>
        <HeaderWrapper>
          <TopSection>
            <ButtonGroup disableElevation>
              {LISTING_TABS.map(tab => (
                <Button
                  key={tab.value}
                  onClick={() => {
                    handleChangeTab(tab);
                  }}
                  variant={tab.value === currentTab.value ? 'contained' : 'outlined'}
                >
                  {tab.label}
                </Button>
              ))}
            </ButtonGroup>
            <Styled.ItemGroup>
              <Button
                onClick={() => {
                  openModalByModalId('checklistModalImport');
                }}
                variant="contained"
                startIcon={<FileUploadIcon />}
              >
                Импорт
              </Button>
              <Button
                onClick={() => {
                  openModalByModalId('checklistModalExport');
                }}
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                Экспорт
              </Button>
              <Button variant="contained" onClick={handleAddClick}>
                Добавить
              </Button>
            </Styled.ItemGroup>
          </TopSection>
        </HeaderWrapper>
      </Styled.SubHeader>
      <Layout menuItems={null} sidebarComponent={ChecklistSidebarContainer}>
        <MainContainer>
          <HeaderWrapper>
            <TopSection>
              <H1>{currentTab?.label}</H1>
            </TopSection>
          </HeaderWrapper>
          <MainBody minHeight="calc(100vh - 268px)">
            <Styled.GridWrapper>
              {isDataLoading ? (
                <CircularProgress />
              ) : (
                advancedGridConfig && <AdvancedGrid gridConfig={advancedGridConfig} />
              )}
            </Styled.GridWrapper>
          </MainBody>
        </MainContainer>
      </Layout>
    </div>
  );
};

export default ListingContainer;
