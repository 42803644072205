import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  ownerId: string;
};
type TypeResponse = string;

export const deleteAgropogodaReportsConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ ownerId }: TypeRequest) => `/api/as-agropogoda/owners/${ownerId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
