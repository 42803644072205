import { Box } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';

const SubHeader = styled(Box)`
  background-color: ${Colors.white};
  padding: 13.75px 0;
  margin-bottom: 12px;
  box-shadow: 0 0 10px 0 rgb(23 73 77 / 15%);
`;

const ItemGroup = styled(Box)`
  display: flex;
  gap: 8px;
`;

const GridWrapper = styled.div`
  height: calc(100vh - 305px);
`;

const Styled = {
  SubHeader,
  ItemGroup,
  GridWrapper,
};

export default Styled;
