import * as Yup from 'yup';

import { EFormFields } from '../../../api/models/user.admin.model';

export const userFormEditSchema = Yup.object().shape({
  [EFormFields.lastName]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.firstName]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.secondName]: Yup.string().min(
    2,
    ({ min }) => `Минимальное значение поля ${min} символов`
  ),

  [EFormFields.dateOfBirth]: Yup.string().required('Поле обязательно для заполнения'),
  [EFormFields.gender]: Yup.object({
    genderId: Yup.string().required(),
    genderName: Yup.string().required(),
  })
    .nullable()
    .required('Поле обязательно для заполнения'),

  [EFormFields.phoneNumber]: Yup.string(),
  [EFormFields.email]: Yup.string(),
  [EFormFields.avatar]: Yup.string(),
});
