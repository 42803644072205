import { IReportConfig } from '../../models/reportConfig.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};
type TypeResponse = IReportConfig;

export const getReportsConfigByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/as-agrosignal/reports/config/byOrganizationId/${params.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
