import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const REMOVE_DEVICE_MODAL_ID = 'remove-device-modal';

const removeDeviceOwnerModalConfig: TModalConfig = {
  id: REMOVE_DEVICE_MODAL_ID,
  name: REMOVE_DEVICE_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value: 'Вы уверены, что хотите удалить привязку метеостанции?',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { REMOVE_DEVICE_MODAL_ID, removeDeviceOwnerModalConfig };
