import styled, { css } from 'styled-components';

const FormRow = styled.div<{ $maxWidth?: string }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
`;

const Styled = {
  FormRow,
};

export default Styled;
