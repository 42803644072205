import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthorizationStore } from '../stores/authorization/authorization.store';
import { useStore } from '../shared/utils';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../shared/styles/dotsLoader';
import { ApplicationRoutes } from '../routes';
import { withBrowserTitle } from '../../hocs/withBrowserTitle';
import PageNotFound from '../shared/components/PageNotFound/PageNotFound';
import { EErrorStatusName } from '../../constants/errorStatus';

import { AdminRoutes } from './routes';
import { MainPage, NewsRouter, UsersRouter, OrganizationsRouter, AlertsRouter } from './pages';
import { AdminWrapper } from './AdminWrapper';
import { DictionaryRouter } from './modules';
import { QuotesRouter } from './pages/QuotesPage';
import { ChecklistRouter } from './modules/checklists';
import { ServiceRouter } from './modules/services';

const NewsRouterWithTitle = withBrowserTitle('Новости')(NewsRouter);
const UsersRouterWithTitle = withBrowserTitle('Пользователи')(UsersRouter);
const QuotesRouterWithTitle = withBrowserTitle('Котировки')(QuotesRouter);
const OrganizationsRouterWithTitle = withBrowserTitle('Организации')(OrganizationsRouter);
const AlertsRouterWithTitle = withBrowserTitle('Алерты')(AlertsRouter);
const DictionaryRouterWithTitle = withBrowserTitle('Справочники')(DictionaryRouter);
const ChecklistRouterWithTitle = withBrowserTitle('Чек-листы')(ChecklistRouter);
const MainRouterWithTitle = withBrowserTitle('Главная')(MainPage);

export const AdminRouter: FC = observer(() => {
  const {
    checkIsUserLoginned,
    logout,
    isLoggedOut,
    isLoading,
    isLoaded,
    checkUser,
    isAdmin,
  } = useStore(AuthorizationStore);

  useEffect(() => {
    if (isLoaded && (checkUser || !isAdmin)) return;

    checkIsUserLoginned();
  }, [isLoaded, checkUser]);

  const onButtonClick = () => {
    logout();
  };

  if (isLoggedOut) {
    return <Redirect to={ApplicationRoutes.Authorization} />;
  }

  if (isLoading) {
    return (
      <SpinnerLoaderWrapper>
        <SpinnerLoader data-test-id="dots-loader">
          <span />
        </SpinnerLoader>
      </SpinnerLoaderWrapper>
    );
  }

  if (!isAdmin) {
    return <PageNotFound type={EErrorStatusName.NotEnoughRights} onButtonClick={onButtonClick} />;
  }

  return (
    <>
      {isLoaded && checkUser && (
        <AdminWrapper>
          <Switch>
            <Route path={AdminRoutes.News} component={NewsRouterWithTitle} />
            <Route path={AdminRoutes.Users} component={UsersRouterWithTitle} />
            <Route path={AdminRoutes.Quotes} component={QuotesRouterWithTitle} />
            <Route path={AdminRoutes.Organizations} component={OrganizationsRouterWithTitle} />
            <Route path={AdminRoutes.Alerts} component={AlertsRouterWithTitle} />
            <Route path={AdminRoutes.Dictionary} component={DictionaryRouterWithTitle} />
            <Route path={AdminRoutes.Checklists} component={ChecklistRouterWithTitle} />
            <Route path={AdminRoutes.Services} component={ServiceRouter} />
            <Route path={AdminRoutes.Main} component={MainRouterWithTitle} />

            <Redirect to={AdminRoutes.Main} />
          </Switch>
        </AdminWrapper>
      )}
    </>
  );
});
