import { IAttributeItem } from '../../models/attribute.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = { remoteName } & IAttributeItem;

type TypeResponse = {};

export const createDictionaryAttribute: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/admin/dictionary/${params.remoteName}/attr/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
