import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.singleton()
export class AccessService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchAccessRoleList = async (payload: TypeApiRequest<'getAccessRoleList'>) => {
    try {
      const response = await this.axios.api.getAccessRoleList(payload);

      return response;
    } catch (error) {
      console.log('fetch access role list error', error);
    }
  };

  createAccessRole = async (payload: TypeApiRequest<'createAccessRole'>) => {
    try {
      const response = await this.axios.api.createAccessRole(payload, {
        omit: ['organizationId'],
        query: { organizationId: payload.organizationId },
      });

      return response;
    } catch (error) {
      console.log('create access role list error', error);
    }
  };

  updateAccessRole = async (payload: TypeApiRequest<'updateAccessRole'>) => {
    try {
      const response = await this.axios.api.updateAccessRole(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      console.log('update access role list error', error);
    }
  };

  deleteAccessRole = async (payload: TypeApiRequest<'deleteAccessRole'>) => {
    try {
      const response = await this.axios.api.deleteAccessRole(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      console.log('update access role list error', error);
    }
  };

  fetchAccessModuleList = async (payload: TypeApiRequest<'getAccessModuleAll'>) => {
    try {
      const response = await this.axios.api.getAccessModuleAll(payload);

      return response;
    } catch (error) {
      console.log('fetch access module list error', error);
    }
  };

  fetchAccessRoleById = async (payload: TypeApiRequest<'getAccessRoleById'>) => {
    try {
      const response = await this.axios.api.getAccessRoleById(payload);

      return response;
    } catch (error) {
      console.log('fetch access role error', error);
    }
  };

  assignUserToRole = async (payload: TypeApiRequest<'assignUserToRole'>) => {
    try {
      const response = await this.axios.api.assignUserToRole(payload, { omit: ['roleId'] });

      return response;
    } catch (error) {
      console.log('assign error', error);
    }
  };

  assignScoutToRole = async (payload: TypeApiRequest<'assignScoutToRole'>) => {
    try {
      const response = await this.axios.api.assignScoutToRole(payload);

      return response;
    } catch (error) {
      console.log('assign error', error);
    }
  };
}
