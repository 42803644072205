import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../shared/utils';
import { CropwiseReportController } from '../mobx';
import { ICropwiseConfig, ICropwiseConfigForm, ICropwiseCreateConfig } from '../models';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';
import { DEFAULT_FORM_CROPWISE_CONFIG } from '../constants';
import { REMOVE_AGROSIGNAL_CONFIG_MODAL_ID } from '../../../modals';

const useCropwiseConfig = ({
  config,
  organizationId,
}: {
  config?: Partial<ICropwiseConfigForm>;
  organizationId?: string;
}) => {
  const controller = useStore(CropwiseReportController);

  const { setNotification } = useNotificator();
  const { openModalByModalId } = useModal();

  const fetchConfig = () => {
    controller.getCropwiseReportsConfigList({ orgId: organizationId, page: 0, size: 1000 });
  };

  const handleSubmit = () => {
    if (config.state === 'edit') {
      controller
        .updateReportConfig((config as ICropwiseConfig).id, config, DEFAULT_FORM_CROPWISE_CONFIG)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно обновлена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch((error: Error) => {
          setNotification(
            getNotificatorProps(
              `Ошибка обновления конфигурации${error.message ? `. ${error.message}` : ''}`,
              ENotificationStyles.Error
            )
          );
        });
    } else {
      controller
        .createReportConfig(organizationId, config as ICropwiseCreateConfig)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно создана', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка создания конфигурации', ENotificationStyles.Error)
          );
        });
    }
  };

  const handleDelete = () => {
    if (config.state === 'create') {
      controller.removeNewConfigPlaceholder(config.id);
    }

    if (config.state === 'edit') {
      openModalByModalId(REMOVE_AGROSIGNAL_CONFIG_MODAL_ID, null, () =>
        controller
          .deleteReportConfig(config?.id)
          .then(() => {
            setNotification(
              getNotificatorProps('Конфигурация успешно удалена', ENotificationStyles.Success)
            );
            fetchConfig();
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка удаления конфигурации', ENotificationStyles.Error)
            );
          })
      );
    }
  };

  const toggleAutoUpload = (state: boolean) => {
    if (config.state === 'create') {
      return null;
    }

    controller
      .toggleAutoUpload(config.id, state)
      .then(() => {
        setNotification(
          getNotificatorProps(
            `Автоматическая выгрузка успешно ${state ? 'включена' : 'выключена'}`,
            ENotificationStyles.Success
          )
        );
      })
      .catch(() => {
        setNotification(
          getNotificatorProps(
            `Ошибка ${state ? 'включения' : 'выключения'} автоматической выгрузки`,
            ENotificationStyles.Error
          )
        );
      });
  };

  return { handleSubmit, handleDelete, toggleAutoUpload, fetchConfig };
};

export default useCropwiseConfig;
