import { styled } from '@mui/material/styles';

import { Colors } from '../../../../constants/colors';

export const ProfileWidgetInner = styled('div')`
  padding-left: 0;
  padding-right: 0;
  padding-top: 24px;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    padding-bottom: 24px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
    padding-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 20px;
    padding-bottom: 8px;
  }
`;

export const ProfileWidgetHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.darknessGray};

  @media (max-width: 767px) {
    padding: 0 16px 16px 16px;
  }

  @media (min-width: 768px) {
    padding: 0 24px 16px 24px;
  }
`;

export const ProfileWidgetTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
`;

export const ProfileWidgetEditLink = styled('div')`
  color: ${Colors.darkGray};
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  p {
    color: ${Colors.darkGray};
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  @media (max-width: 767px) {
    p {
      display: none;
    }
  }
`;

export const ProfileImage = styled('img')`
  width: 12px;
  height: 12px;
`;
