import * as Yup from 'yup';

export enum EInvitationEditForm {
  role = 'role',
  comment = 'comment',
}

export const invitationEditFormSchema = Yup.object().shape({
  [EInvitationEditForm.role]: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  })
    .nullable()
    .required('Поле обязательно для заполнения'),

  [EInvitationEditForm.comment]: Yup.string().max(
    200,
    ({ max }) => `Максимальное значение поля ${max} символов`
  ),
});
