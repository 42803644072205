import { Autocomplete, Box, Chip, IconButton, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import AddIcon from '@mui/icons-material/Add';

import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useMessagePushForm } from '../../hooks';
import { ISelectOption } from '../../../../../../../../../../../../../../types/selectOption';

const ErrorFileSinglePushForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();

  const {
    employeeList,
    setSelectedEmployeeList,
    minDate,
    maxDate,
    setMinDate,
    setMaxDate,
    pushMessage,
    addChecklistInstanceId,
    removeChecklistInstanceId,
    checklistInstanceIdList,
  } = useMessagePushForm(orgStore?.organization?.organizationId);

  const [isProcessing, setIsProcessing] = useState(false);
  const [checklistInstanceId, setChecklistInstanceId] = useState('');

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    selectionList: string[];
  };
  const handlers = getModalRuntimeHandlers();

  const handleSuccess = () => {
    setIsProcessing(true);

    pushMessage()
      .then(() => {
        handlers?.onSuccess?.();
      })
      .finally(() => {
        setIsProcessing(false);
        closeModal();
      });
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleAddChecklistInstanceId = () => {
    addChecklistInstanceId(checklistInstanceId);
    setChecklistInstanceId('');
  };

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Обработка ошибок</ModalTitle>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />
        <Box display={'flex'} flexDirection="row" gap={1}>
          <TextField
            label="ID чек-лист точки “Помощник”"
            fullWidth
            value={checklistInstanceId}
            onChange={e => setChecklistInstanceId(e.target.value)}
          />
          <IconButton onClick={handleAddChecklistInstanceId}>
            <AddIcon />
          </IconButton>
        </Box>

        <Box
          maxHeight={100}
          overflow={'auto'}
          display="flex"
          gap={1}
          flexDirection="row"
          flexWrap="wrap"
        >
          {checklistInstanceIdList.map(id => (
            <Chip label={id} variant="outlined" onDelete={() => removeChecklistInstanceId(id)} />
          ))}
        </Box>

        <Autocomplete
          options={employeeList}
          fullWidth
          renderInput={props => (
            <TextField {...props} label={'Пользователь'} placeholder={'Выберите пользователей'} />
          )}
          disableClearable
          disablePortal
          multiple
          onChange={(e, value) => setSelectedEmployeeList(value as ISelectOption[])}
        />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <DateTimePicker
            value={minDate ? moment(minDate) : null}
            onChange={(date: Moment) => {
              setMinDate(date ? date?.toISOString(true) : null);
            }}
            maxDate={moment(maxDate)}
            renderInput={props => <TextField {...props} label="Дата и время начала *" fullWidth />}
            PopperProps={{
              style: { zIndex: 1000000 },
            }}
          />
          <DateTimePicker
            value={maxDate ? moment(maxDate) : null}
            onChange={(date: Moment) => {
              setMaxDate(date ? date?.toISOString(true) : null);
            }}
            minDate={moment(minDate)}
            renderInput={props => <TextField {...props} label="Дата и время окончания" fullWidth />}
            PopperProps={{
              style: { zIndex: 1000000 },
            }}
          />
        </LocalizationProvider>
      </Box>

      <ModalFooter
        successButton={{
          title: 'Подтвердить',
          disabled: isProcessing || !minDate,
          handler: () => {
            handleSuccess();
          },
        }}
        denyButton={{
          title: 'Отменить',
          handler: () => {
            handleCancel();
          },
        }}
      />
    </Box>
  );
};

export default observer(ErrorFileSinglePushForm);
