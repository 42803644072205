import { TextField, OutlinedTextFieldProps, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IProps extends OutlinedTextFieldProps {
  value: string | number | null | boolean;
  onChangeHandler: (value: string) => void;
}

const IntegerField: FC<IProps> = ({ value, onChangeHandler }) => {
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState(value === null ? '' : value);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const targetValue = event.currentTarget.value;

    if (RegExp(/^-?\d+$/).test(targetValue) || targetValue === '' || targetValue === '-') {
      setIsError(false);
      onChangeHandler(targetValue);
      setInputValue(targetValue);
    } else {
      setIsError(true);
      setTimeout(() => setIsError(false), 1000);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={onChange}
      label="INTEGER"
      inputProps={{ maxLength: 16 }}
      inputMode="numeric"
      error={isError}
      InputProps={{
        endAdornment: inputValue ? (
          <IconButton
            size="small"
            onClick={() => {
              setInputValue('');
              onChangeHandler(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : undefined,
      }}
    />
  );
};

export default IntegerField;
