import styled, { css } from 'styled-components';

import { BorderRadius } from '../../../../../../../../constants/sizes';

import { Colors } from './../../../../../../../shared/constanst/colors';

const FormRow = styled.div<{ $isGroup?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  padding: 8px 4px;

  ${({ $isGroup }) =>
    $isGroup &&
    css`
      border-radius: ${BorderRadius.default};
      flex-direction: column;
      background-color: ${Colors.gray};
    `}
`;

export const Styled = { FormRow };
