import styled from 'styled-components';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  padding: 16px 0;
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const ActionRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Styled = {
  FormRow,
  ActionsRow,
  ActionRowGroup,
};

export default Styled;
