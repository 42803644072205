import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  operId: string;
  code: string;
};

type TypeResponse = {
  needAdditionalInfo: boolean;
  'access-token': string;
  'access-token-type': string;
  'refresh-token': string;
  'rt-expires-on': string;
};

export const verifySmsCodeV2: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/phoneConfirm/v2/checkSmsCode`,
  method: 'POST',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  headers: {},
};
