import styled from 'styled-components';

const EditorWrapper = styled.div`
  #mui-rte-root {
    height: calc(100vh - 430px);
    max-height: unset;
    position: relative;
    border-color: rgba(0, 0, 0, 0.23);

    #mui-rte-editor {
      max-height: 400px;
      overflow: auto;
    }
  }
`;

const Styled = { EditorWrapper };

export default Styled;
