import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const GridWrapper = styled.div`
  height: calc(100vh - 303px);
`;

const PhoneLink = styled.a`
  color: ${Colors.black};
  padding-left: 4px;
`;

export { GridWrapper, PhoneLink };
