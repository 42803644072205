import { TypeQuoteSimple } from '../../../modules/stores/quotes/add.quotes.store';
import { TypeFilters, TypeQuote } from '../../models/quotes.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = TypeQuoteSimple;

type TypeResponse = {};

type TypeError = {};

export const updateQuote: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/da-quotes/quotes/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
