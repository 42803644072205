import { FC, memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';

import { IDeviceModel } from '../../../../../../../../../../api/models/device.model';

import getColumnConfig from './dataGridColumns';

interface IProps {
  deviceList: IDeviceModel[];
  removeDeviceHandler: (device: IDeviceModel) => void;
}

const WeatherStationList: FC<IProps> = ({ deviceList, removeDeviceHandler }) => {
  const columnConfig = useMemo(() => getColumnConfig(removeDeviceHandler), []);

  return (
    <Box height={'calc(100vh - 420px)'} marginTop={2}>
      <DataGrid
        columns={columnConfig}
        rows={deviceList}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        hideFooter
      />
    </Box>
  );
};

export default memo(WeatherStationList);
