import { TextField, OutlinedTextFieldProps, IconButton } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IProps extends OutlinedTextFieldProps {
  value: string | number | null | boolean;
  onChangeHandler: (value: string) => void;
}

const StringField: FC<IProps> = ({ value, onChangeHandler }) => {
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState(value === null ? '' : value);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onChangeHandler(event.currentTarget.value);
      setInputValue(event.target.value);
    },
    []
  );

  return (
    <TextField
      value={inputValue}
      onChange={onChange}
      label="STRING"
      inputProps={{ maxLength: 100 }}
      error={isError}
      InputProps={{
        endAdornment: inputValue ? (
          <IconButton
            size="small"
            onClick={() => {
              setInputValue('');
              onChangeHandler(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : undefined,
      }}
    />
  );
};

export default StringField;
