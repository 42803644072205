import { ReactNode } from 'react';

import { EFormulaReturnType, EFormulaType } from './DAOFormula.types';

/**
 * Сущность конфигурации формулы
 */
export interface IChecklistFormula {
  /**
   * raw-название функции
   */
  name: string;
  /**
   * Человекочитаемое название
   */
  label: string;
  /**
   * Список возвращаемых типов
   */
  returnType: EFormulaReturnType[];
  /**
   * Список типов аргументов
   */
  argsType: EFormulaType[];
  icon?: ReactNode;
}

export enum EFormulaTypes {
  Calculation = 'calculate',
  Visibility = 'visibility',
  Dependency = 'dependency',
  ODZ = 'ODZ',
}
