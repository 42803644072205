import styled from 'styled-components';

const FilterWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Styled = {
  FilterWrapper,
};

export default Styled;
