import { ReactElement } from 'react';
import { GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';

import { EUserAdminStatus, TypeUserAdminRole } from '../../../../../api/models/user.admin.model';

export const getColumns = (
  renderActions: (params: GridRowParams) => ReactElement[],
  renderStatus: (params: GridRenderCellParams<EUserAdminStatus>) => ReactElement,
  renderRole: (params: GridRenderCellParams<TypeUserAdminRole>) => string
) => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    width: 150,
    sortable: false,
    renderCell: renderStatus,
  },
  {
    field: 'fullName',
    type: 'string',
    headerName: 'Имя',
    flex: 1,
    minWidth: 300,
    sortable: false,
  },
  {
    field: 'role',
    type: 'string',
    headerName: 'Роль',
    flex: 1,
    minWidth: 200,
    sortable: false,
    renderCell: renderRole,
  },
  {
    field: 'regDate',
    type: 'string',
    headerName: 'Дата подключения',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Действия',
    width: 200,
    sortable: false,
    getActions: renderActions,
  },
];
