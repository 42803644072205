import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_EXIT_SERVICE_REFERENCE = 'before-exit-service-reference';

const beforeExitServiceReferenceModalConfig: TModalConfig = {
  id: BEFORE_EXIT_SERVICE_REFERENCE,
  name: BEFORE_EXIT_SERVICE_REFERENCE,
  type: EModalType.Warning,
  title: 'Вы точно хотите переключить подсказку?',
  description: {
    value: 'При переключении подсказки данные будут утеряны',
    $styles: {
      textAlign: 'center',
      margin: '24px 0',
    },
  },
  closeOnClickOutside: true,
  successButton: {
    title: 'Переключить',
  },
  denyButton: {
    title: 'Остаться',
  },

  styledProps: {
    $modalPaddings: '20px',
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '100%',
  },
};

export { BEFORE_EXIT_SERVICE_REFERENCE, beforeExitServiceReferenceModalConfig };
