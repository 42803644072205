import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import FieldGroupImportModalForm from './components/FieldGroupImportModalForm/FieldGroupImportModalForm';

const FIELD_GROUP_IMPORT_MODAL_ID = 'field-group-import-modal';

const fieldGroupImportModalConfig: TModalConfig = {
  id: FIELD_GROUP_IMPORT_MODAL_ID,
  name: FIELD_GROUP_IMPORT_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: FieldGroupImportModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};
export { FIELD_GROUP_IMPORT_MODAL_ID, fieldGroupImportModalConfig };
