import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const REMOVE_AGROSIGNAL_CONFIG_MODAL_ID = 'remove-agrosignal-config-modal';

const removeAgrosignalConfig: TModalConfig = {
  id: REMOVE_AGROSIGNAL_CONFIG_MODAL_ID,
  name: REMOVE_AGROSIGNAL_CONFIG_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value: 'Вы уверены, что хотите удалить конфигурацию?',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { REMOVE_AGROSIGNAL_CONFIG_MODAL_ID, removeAgrosignalConfig };
