import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../stores/organizations/organizations.store';
import { OrganizationsController } from '../../../../../../../../../../../controllers/organizations.controller';
import { UsersController } from '../../../../../../../../../../../controllers/users.controller';
import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { OrganizationRoleController, OrganizationRoleStore } from '../../../../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';

import Styled from './RoleItemAddUser.styles';

const RoleItemAddUser = () => {
  const organizationStore = useStore(OrganizationsStore);
  const organizationController = useStore(OrganizationsController);
  const usersController = useStore(UsersController);
  const store = useStore(OrganizationRoleStore);
  const controller = useStore(OrganizationRoleController);

  const { roleId } = useParams<{ organizationId: string; roleId: string }>();
  const { setNotification } = useNotificator();

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [name, setName] = useState('');
  const [selectedUser, setSelectedUser] = useState<ISelectOption>(null);

  const { isScout, organizationId } = organizationStore?.organization;

  const fetchData = async () => {
    let options: ISelectOption[] = [];

    setIsLoading(true);

    if (isScout) {
      options = await usersController
        .fetchScoutList({
          size: 50,
          fullName: name,
          notCurrentUser: true,
          notRoleId: roleId,
          notOwnerOrganizationId: organizationId,
        })
        .then(data => data.content?.map(item => ({ label: item.fullName, value: item.id })));
    } else {
      options = await organizationController
        .fetchUserOrganizationList({
          organizationID: organizationId,
          fullName: name,
          notRoleId: roleId,
          isOwner: false,
          isScout: false,
          size: 50,
        })
        .then(data => data.content.map(item => ({ label: item.userName, value: item.userId })));
    }

    setOptionList(options);
    setIsLoading(false);
  };

  const handleAddUser = async () => {
    if (!selectedUser?.value) {
      return;
    }

    setIsSubmitting(true);

    try {
      if (isScout) {
        await controller.assignScoutToRole({ roleId, userIds: [selectedUser?.value] });
      } else {
        await controller.assignUserToRole({ roleId, employeeIds: [selectedUser?.value] });
      }

      setNotification(getNotificatorProps('Роль успешно присвоена', ENotificationStyles.Success));
      store.clearUserListGridParams();
      controller.fetchRoleUserList(organizationId, roleId);
      controller.fetchRoleList(organizationId, true);
    } catch (error) {
      setNotification(getNotificatorProps('Ошибка присвоения роли', ENotificationStyles.Error));
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    fetchData();
  }, [organizationId, roleId, name]);

  return (
    <Box height={64}>
      {!isActive ? (
        <Button disabled={!store?.currentRoleData?.canAssign} onClick={() => setIsActive(true)}>
          + Добавить сотрудника
        </Button>
      ) : (
        <Styled.SearchForm>
          <Styled.SearchWrapper>
            <Autocomplete
              fullWidth
              options={optionList}
              loading={isLoading}
              renderInput={params => <TextField {...params} label="Поиск" />}
              onChange={(event, value) =>
                typeof value === 'object' ? setSelectedUser(value) : setSelectedUser(null)
              }
              onInputChange={(event, value) => {
                setName(value ?? '');
              }}
            />
          </Styled.SearchWrapper>

          <Button
            variant="contained"
            disabled={isSubmitting || !selectedUser?.value}
            onClick={handleAddUser}
          >
            Сохранить
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsActive(false);
              setIsLoading(false);
              setName('');
              setOptionList([]);
            }}
          >
            Отменить
          </Button>
        </Styled.SearchForm>
      )}
    </Box>
  );
};

export default observer(RoleItemAddUser);
