import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  newsId: string;
};

type TypeResponse = { content: Array<string> } & TypeResponsePageable;

type TypeError = {};

export const getNewsItemFiles: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-news/files/byNewsId/${request.newsId}`,
  headers: {},
  method: 'GET',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
