import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.singleton()
export class UserService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchScoutList = async (payload: TypeApiRequest<'getScoutList'>) => {
    try {
      const response = await this.axios.api.getScoutList(payload);

      return response;
    } catch (error) {
      console.log('fetch scout list error', error);
    }
  };
}
