import { isEmpty, trim } from 'lodash';

import { TypeOrganization } from '../../../../../api/models/organization.model';

export const getRows = (organizations: TypeOrganization[]) =>
  organizations.map(item => {
    const orgType = !isEmpty(item.type) ? item.type.OrgType : '';
    const name = item.name || '';
    const fullName = trim(`${orgType} ${name}`);

    return {
      id: item.organizationId,
      status: item.status,
      name: fullName,
      userName: !isEmpty(item.owner) ? item.owner.userName : '',
      countEmployees: item.countEmployees,
      countFields: !isEmpty(item.countFields) ? item.countFields : 0,
      adressFull: !isEmpty(item.addressJur) ? item.addressJur.addressfull : '',
    };
  });
