import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  & input {
    height: 23px;
  }
`;

const SelectWrapper = styled.div`
  max-width: 25%;
  min-width: 100px;
  width: 100%;
`;

const Styled = { Row, SelectWrapper };

export default Styled;
