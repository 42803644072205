import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  fieldId: string;
  agrosFieldId: number;
};

type TypeResponse = string;

type TypeError = {};

export const createOrgIntegraAgrosignalField: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/field`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
