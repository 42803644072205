import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

import { useStore, getLink } from '../../../../../shared/utils';
import { NewsController } from '../../../../../controllers/news.controller';
import { AdminRoutes, ERegexp } from '../../../../routes';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../../../../../shared/styles/dotsLoader';

export const NewsCreate = observer(() => {
  const { createNews } = useStore(NewsController);

  const history = useHistory();

  const getNewsId = async () => {
    const data = await createNews();

    if (!isEmpty(data)) {
      const link = getLink(AdminRoutes.NewsEdit, {
        [data.newsId]: ERegexp.NEWSID,
      });

      setTimeout(() => {
        history.push(link);
      }, 2000);
    }
  };

  useEffect(() => {
    getNewsId();
  }, []);

  return (
    <SpinnerLoaderWrapper>
      <SpinnerLoader data-test-id="create-news-loader">
        <span />
      </SpinnerLoader>
    </SpinnerLoaderWrapper>
  );
});
