import { TypeApiRoute } from '../../../models/type.api.request';
import { IUserDictionaryItemValue } from '../../../models/checklist.attribute.model';

type TypeRequest = Array<Partial<IUserDictionaryItemValue>>;

type InputData = {
  list: TypeRequest;
  attributeId: string;
  organizationId: string;
};

type TypeResponse = IUserDictionaryItemValue[];

type TypeError = {};

export const changeChecklistAttributeUserDictionaryList: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
  error: TypeError;
} = {
  url: request =>
    `/api/as-fields/admin/attribute/${request.attributeId}/userDictionaryList/${request.organizationId}`,
  method: 'PUT',
  headers: {},
  request: request => request.list,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
