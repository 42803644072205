import { TreeItem } from '@mui/lab';
import { LinearProgress } from '@mui/material';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TDictionaryItem } from '../../../../../../../../api/models/dictionaries.model';
import { TreeNodeTitle } from '../TreeNodeTitle';

type TTreeNodeProps = {
  id: string;
  nodeData: TDictionaryItem;
  remoteName: string;
  currentId: string;
  setCurrentId: Dispatch<SetStateAction<string>>;
  isSelected?: boolean;
  isIndex?: boolean;
  onSelectItem: (item: TDictionaryItem) => void;
  getChildrenData?: (remoteName: string, parentId: string) => Promise<TDictionaryItem[]>;
};

const TreeNode: FC<TTreeNodeProps> = ({
  id,
  nodeData,
  onSelectItem,
  getChildrenData,
  remoteName,
  currentId,
  setCurrentId,
  isIndex,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [childrenData, setChildrenData] = useState<TDictionaryItem[] | null>(null);

  useEffect(() => {
    if (!childrenData && isExpanded && nodeData.hasChild) {
      getChildrenData(remoteName, nodeData.id).then(data => {
        setChildrenData(data);
      });
    }
  }, [isExpanded]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, nodeId: string) => {
      if ((e.target as HTMLElement).parentElement.id !== 'long-menu') {
        setIsExpanded(!isExpanded);
      }

      if (nodeId !== currentId) {
        // setCurrentId(nodeId);
        onSelectItem(nodeData);
      }
    },
    [isExpanded, currentId]
  );

  const renderChildNodes = useMemo(() => {
    if (childrenData && childrenData.length) {
      return childrenData.map(node => (
        <TreeNode
          key={node.id}
          id={node.id}
          nodeData={node}
          remoteName={remoteName}
          getChildrenData={getChildrenData}
          onSelectItem={onSelectItem}
          currentId={currentId}
          setCurrentId={setCurrentId}
        />
      ));
    }
  }, [childrenData, currentId]);

  return (
    <>
      {nodeData?.hasChild ? (
        <TreeItem
          nodeId={id}
          label={
            <TreeNodeTitle
              isSelected={currentId === id}
              id={id}
              remoteName={remoteName}
              value={nodeData}
              isMenuDisabled={isIndex}
            >
              {nodeData.name}
            </TreeNodeTitle>
          }
          key={id}
          id={id}
          onClick={e => handleClick(e, id)}
        >
          {renderChildNodes ? (
            renderChildNodes.length ? (
              renderChildNodes
            ) : null
          ) : (
            <LinearProgress />
          )}
        </TreeItem>
      ) : (
        <TreeItem
          nodeId={id}
          label={
            <TreeNodeTitle
              isSelected={currentId === id}
              id={id}
              remoteName={remoteName}
              value={nodeData}
              isMenuDisabled={isIndex}
            >
              {nodeData.name}
            </TreeNodeTitle>
          }
          key={id}
          id={id}
          onClick={e => handleClick(e, id)}
        />
      )}
    </>
  );
};

export default memo(TreeNode);
