import { Colors, ModalFooter } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Footer = styled(ModalFooter)``;

const Styled = {
  Footer,
};

export default Styled;
