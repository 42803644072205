import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import moment from 'moment';

import { useStore } from '../../../../../../../shared/utils';
import { IAgrosignalHistoryFilter } from '../../components/OrganizationReportsAgrosignal/models';
import {
  EImportOperationStatus,
  IAssistanceFieldsOperationHistoryModel,
} from '../../../../../../../../api/models/importOperation.model';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { ReportService } from '../../../../../../../services';
import { TooltipedValue } from '../../../../../../../shared/components';

type TFilterConfig = {
  size: number;
  page: number;
  configId: string;
} & Partial<{
  organizationId: string;
  fromDate: string;
  toDate: string;
  statusIn: EImportOperationStatus[];
}>;

const PAGE_SIZE_LIST = [10, 20, 50, 100];

const useAssistanceFieldsReportHistory = ({
  clientId,
  organizationId,
  fromDate,
  toDate,
}: {
  clientId?: string;
  organizationId?: string;
  fromDate?: string;
  toDate?: string;
}) => {
  const service = useStore(ReportService);
  const { setNotification } = useNotificator();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formikValue, setFormikValue] = useState<Partial<TFilterConfig>>({
    fromDate,
    toDate,
    statusIn: [],
    configId: clientId,
    organizationId,
  });
  const [valueList, setValueLst] = useState<IAssistanceFieldsOperationHistoryModel[]>([]);

  const gridColumnConfig = useMemo<GridColDef<IAssistanceFieldsOperationHistoryModel>[]>(
    () => [
      {
        field: 'fromDate',
        type: 'string',
        headerName: 'Дата начала для выборки данных',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 120,
      },
      {
        field: 'toDate',
        type: 'string',
        headerName: 'Дата окончания для выборки данных',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 130,
      },
      {
        field: 'reportsLocation',
        type: 'string',
        headerName: 'Папка выгрузки отчёта',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'filesLocation',
        type: 'string',
        headerName: 'Папка выгрузки фотоматериалов',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'creationDate',
        type: 'string',
        headerName: 'Дата заказа выгрузки',
        renderCell: params => (
          <TooltipedValue
            value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
          />
        ),
        minWidth: 120,
      },
      {
        field: 'operationStatus',
        type: 'string',
        headerName: 'Статус',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        minWidth: 120,
      },
      {
        field: 'message',
        type: 'string',
        headerName: 'Ошибка',
        renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
        flex: 1,
        minWidth: 120,
      },
    ],
    []
  );

  useEffect(() => {
    fetchFieldHistory(formikValue as TFilterConfig);
  }, [page, size]);

  const fetchFieldHistory = (data: TFilterConfig, forcedPage?: number) => {
    setIsLoading(true);

    service
      .getAssistanceFieldsHistoryReport({
        configId: data?.configId,
        organizationId,
        page: forcedPage || page,
        size,
        statusIn: data?.statusIn,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
      })
      .then(response => {
        setValueLst(response.content);
        setTotalElements(response.totalElements);
        setFormikValue(data);
      })
      .catch(error => {
        console.error(error);
        setNotification(getNotificatorProps('Ошибка получения истории', ENotificationStyles.Error));
      })
      .finally(() => setIsLoading(false));
  };

  return {
    page,
    setPage,
    size,
    setSize,
    totalElements,
    isLoading,
    valueList,
    gridColumnConfig,
    fetchFieldHistory,
  };
};

export { useAssistanceFieldsReportHistory, PAGE_SIZE_LIST };
