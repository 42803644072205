import { Box, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import { FormulaContext } from '../../../../../../../../../modules/Formulas/contexts';
import {
  IAttribute,
  IChecklistAttributeODZ,
} from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';
import { ODZCopyDropdown, ODZItem, ODZToggler } from '../../components';
import { AttributeODZController, AttributeODZStore } from '../../mobx';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { useODZContainer } from '../../hooks';
import { EODZCriteria } from '../../models';
import { FormulaErrorLog } from '../../components/FormulaErrorLog';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';

interface IProps {
  attribute?: IAttribute;
  checklistAttribute?: IChecklistAttribute;
  checklistAttributeList?: ISelectOptionExtended<IChecklistAttribute>[];
  setIsChanged?: (state: boolean) => void;
  isCopyODZ?: boolean;
}

const ODZContainer: FC<IProps> = ({
  attribute,
  checklistAttribute,
  setIsChanged,
  checklistAttributeList,
  isCopyODZ,
}) => {
  const store = useStore(AttributeODZStore);
  const controller = useStore(AttributeODZController);

  const [sidebarNode, setSidebarNode] = useState<Element>(null);

  const controls = useODZContainer();

  useEffect(() => {
    const sidebarContainer = document.querySelector('#aside-menu-inner');

    sidebarContainer.replaceChildren();

    setSidebarNode(sidebarContainer);
  }, []);

  const onCopyDropdown = (value: IChecklistAttributeODZ) => {
    controller.copyODZData(value);
  };

  const isDisplayODZConsistencyError = useMemo(() => {
    if (!store.isODZEnabled) return false;

    if (store.redCriteria || store.yellowCriteria || store.greenCriteria) return false;
    if (
      store.redSerializedCriteria ||
      store.yellowSerializedCriteria ||
      store.greenSerializedCriteria
    )
      return false;

    return true;
  }, [store.isODZEnabled, store.redCriteria, store.yellowCriteria, store.greenCriteria]);

  if (!sidebarNode) {
    return null;
  }

  return (
    <Box>
      {createPortal(<FormulaErrorLog errorCollection={store.errorLog} />, sidebarNode)}

      <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <ODZToggler
          isChecked={store.isODZEnabled}
          setToggle={state => {
            store.setFormulaFieldDirty('isODZEnabled');
            store.setODZEnabled(state);
          }}
        />
        {isCopyODZ && (
          <ODZCopyDropdown
            attributeId={checklistAttribute?.attribute?.id ?? attribute?.id}
            checklistAttributeId={checklistAttribute?.id}
            onSelect={onCopyDropdown}
          />
        )}
      </Box>

      <Box height={20}>
        {isDisplayODZConsistencyError && (
          <Typography color={'warning'} fontSize={'small'}>
            При включённом ОДЗ должна быть заполнена хотя бы одна формула
          </Typography>
        )}
      </Box>

      <FormulaContext.Provider
        value={{
          checklistAttributeList: checklistAttributeList ?? [],
          checklistAttribute,
          attribute,
        }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <ODZItem
            data={store?.redCriteria}
            setData={store?.setRedCriteria}
            setSerializedData={value => {
              store.setRedSerializedCriteria(value);
              setIsChanged?.(true);
            }}
            handleUserChange={() => {
              store.setFormulaFieldDirty('redCriteria');
            }}
            comment={store?.redCriteriaComment}
            setComment={value => {
              store?.setRedCriteriaComment(value);
              store.setFormulaFieldDirty('redCriteriaComment');
              setIsChanged?.(true);
            }}
            removeData={() => {
              controls.removeODZ(EODZCriteria.Red);
            }}
            type={EODZCriteria.Red}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Red, error, id)}
          />
          <ODZItem
            data={store?.yellowCriteria}
            setData={store?.setYellowCriteria}
            setSerializedData={value => {
              store?.setYellowSerializedCriteria(value);
              setIsChanged?.(true);
            }}
            handleUserChange={() => store.setFormulaFieldDirty('yellowCriteria')}
            comment={store?.yellowCriteriaComment}
            setComment={value => {
              store?.setYellowCriteriaComment(value);
              store.setFormulaFieldDirty('yellowCriteriaComment');
              setIsChanged?.(true);
            }}
            removeData={() => controls.removeODZ(EODZCriteria.Yellow)}
            type={EODZCriteria.Yellow}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Yellow, error, id)}
          />
          <ODZItem
            data={store?.greenCriteria}
            setData={store?.setGreenCriteria}
            setSerializedData={value => {
              store?.setGreenSerializedCriteria(value);
              setIsChanged?.(true);
            }}
            handleUserChange={() => store.setFormulaFieldDirty('greenCriteria')}
            comment={store?.greenCriteriaComment}
            setComment={value => {
              store?.setGreenCriteriaComment(value);
              store.setFormulaFieldDirty('greenCriteriaComment');
              setIsChanged?.(true);
            }}
            removeData={() => controls.removeODZ(EODZCriteria.Green)}
            type={EODZCriteria.Green}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Green, error, id)}
          />
        </Box>
      </FormulaContext.Provider>
    </Box>
  );
};

ODZContainer.displayName = 'ODZContainer';

export default observer(ODZContainer);
