import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.transient()
class DevicesService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchDeviceList = async (payload?: TypeApiRequest<'getDeviceList'>) => {
    try {
      const response = await this.axios.api.getDeviceList(payload, { omit: ['page', 'size'] });

      return response;
    } catch (error) {
      console.log('fetch device list error', error);
    }
  };

  setDeviceOwner = async (payload: TypeApiRequest<'setDeviceOwner'>) => {
    try {
      const response = await this.axios.api.setDeviceOwner(payload);

      return response;
    } catch (error) {
      console.log('set device owner error', error);
    }
  };

  removeDeviceOwner = async (payload: TypeApiRequest<'removeDeviceOwner'>) => {
    try {
      const response = await this.axios.api.removeDeviceOwner(payload);

      return response;
    } catch (error) {
      console.log('remove device owner error', error);
    }
  };
}

export default DevicesService;
