import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, FormEvent, useCallback, useEffect } from 'react';

import { ISelectOption } from '../../../../../../../../types/selectOption';
import { useStore } from '../../../../../../../shared/utils';
import { EAdvancedGridFilterItemType } from '../../AdvancedGrid.type';
import { AdvancedGridStore } from '../../mobx';

import BooleanField from './components/BooleanField';
import SelectField from './components/SelectField';
import StringField from './components/StringField';
import Styled from './styled';

const AdvancedGridFilter: FC = observer(() => {
  const {
    getGridMapItem,
    currentGridId,
    currentGridFilterValues,
    setCurrentGridFilterValue,
    clearFilters,
    setIsLoading,
    removeValueFromFilter,
  } = useStore(AdvancedGridStore);

  const filterConfig = getGridMapItem(currentGridId)?.filter;

  const onSearch = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
  }, []);

  const onClear = useCallback(() => {
    clearFilters();
  }, []);

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  return (
    <Styled.FilterWrapper onSubmit={onSearch}>
      {filterConfig && filterConfig.length ? (
        <>
          <Button variant="text" type="reset" onClick={onClear}>
            Очистить фильтр
          </Button>
          {filterConfig.map(filterItem => {
            const { [filterItem.key]: value } = currentGridFilterValues;

            if (filterItem.type === EAdvancedGridFilterItemType.String) {
              return (
                <StringField
                  label={filterItem.label}
                  key={filterItem.key}
                  name={filterItem.key}
                  value={value}
                  onChange={setCurrentGridFilterValue}
                />
              );
            }

            if (filterItem.type === EAdvancedGridFilterItemType.Select) {
              return (
                <SelectField
                  label={filterItem.label}
                  key={filterItem.key}
                  name={filterItem.key}
                  value={typeof value === 'object' ? value.label : value}
                  onSelect={setCurrentGridFilterValue}
                  options={filterItem.config.select.itemList}
                  onClear={removeValueFromFilter}
                  isToggler={filterItem.config.select.isToggler}
                  onInputChange={filterItem?.config?.select?.onInputChange}
                  fetchOrganizationOnChange={filterItem?.config?.select?.onInputChangeSearch}
                />
              );
            }

            if (filterItem.type === EAdvancedGridFilterItemType.Boolean) {
              return (
                <BooleanField
                  label={filterItem.label}
                  key={filterItem.key}
                  name={filterItem.key}
                  value={value as ISelectOption}
                  onChange={setCurrentGridFilterValue}
                />
              );
            }

            return null;
          })}
          <Button variant="contained" type="submit">
            Поиск
          </Button>
        </>
      ) : null}
    </Styled.FilterWrapper>
  );
});

export default AdvancedGridFilter;
