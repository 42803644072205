import { TreeView, TreeItem } from '@mui/lab';
import { Box, Button, LinearProgress } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { IAbstractTreeViewProps, ITreeNodeExtended } from './AbstractTreeView.types';

const AbstractTreeView: FC<IAbstractTreeViewProps> = ({ config, treeElementList }) => {
  const { treeNodeComponent: TreeNode } = config;

  const [treeItemList, setTreeItemList] = useState<ITreeNodeExtended[]>(treeElementList);

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  // const handleExpandClick = () => {
  //   setExpanded(oldExpanded => (oldExpanded.length === 0 ? ['1', '5', '6', '7'] : []));
  // };

  // const handleSelectClick = () => {
  //   setSelected(oldSelected =>
  //     oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : []
  //   );
  // };

  const handleAddNewElementToTree = (path: string[], newNodeList: ITreeNodeExtended[]) => {
    const treeCopy: ITreeNodeExtended[] = [
      {
        label: 'ROOT',
        nodeId: 'ROOT',
        parentNode: null,
        path: [],
        getTreeChild: null,
        children: treeItemList,
      },
    ];
    let childrenFinal: ITreeNodeExtended = treeCopy.find(node => node.nodeId === path[0]);

    if (!childrenFinal || !childrenFinal?.children?.length) {
      return;
    }

    for (let index = 1; index < path.length; index++) {
      childrenFinal = childrenFinal.children.find(node => node.nodeId === path[index]);
    }

    if (!childrenFinal) {
      return;
    }

    childrenFinal.isLoaded = true;
    childrenFinal.children = newNodeList;

    setTreeItemList(treeCopy[0].children);
    setExpanded([...expanded, childrenFinal.nodeId]);
  };

  const treeNodes = (list: ITreeNodeExtended[]) => {
    const getTreeNodeChildren = (children: ITreeNodeExtended[], hasChild: boolean) => {
      if (hasChild && (!children || children?.length === 0)) {
        return ' ';
      }
      return children && children.length > 0 ? treeNodes(children) : null;
    };

    return list.map(item => (
      <TreeItem
        key={item.nodeId}
        nodeId={item.nodeId}
        label={
          <TreeNode
            key={item.nodeId}
            nodeId={item.nodeId}
            title={item.label}
            isHideButton={item.parentNode === 'ROOT'}
            remoteName={item.alias}
          />
        }
        onClick={() => {
          if (item.hasChild && item?.getTreeChild && !item?.isLoaded) {
            (async () => {
              const newElem = await item?.getTreeChild(item);
              handleAddNewElementToTree(item.path, newElem);
            })();
          }
        }}
        children={getTreeNodeChildren(item.children, item.hasChild)}
      />
    ));
  };

  if (!treeItemList?.length) {
    return (
      <Box sx={{ flexGrow: 1, maxWidth: '400px', overflowY: 'auto', margin: 'auto' }}>
        Нет элементов дерева
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: '400px', overflowY: 'auto', margin: 'auto' }}>
      {/* <Box sx={{ mb: 1 }}>
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? 'Развернуть все' : 'Свернуть все'}
        </Button>
        <Button onClick={handleSelectClick}>
          {selected.length === 0 ? 'Выделить все' : 'Снять выделение'}
        </Button>
      </Box> */}
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        // selected={selected}
        onNodeToggle={handleToggle}
        // onNodeSelect={handleSelect}
      >
        {treeItemList && treeItemList.length && treeNodes(treeItemList)}
      </TreeView>
    </Box>
  );
};

export default AbstractTreeView;
