import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';

enum EEnumDepsTab {
  // Кто зависит
  Depends = 'depends',
  // От кого зависит
  Depend = 'depend',
}

type TFormData = {
  attribute?: ISelectOptionExtended;
  currentEnumValue?: ISelectOptionExtended;
  selectedEnumValue?: ISelectOptionExtended;
};

export { EEnumDepsTab };
export type { TFormData };
