import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  checkListId: string;
  status: string;
};

type TypeResponse = {
    status: string;
};

type TypeError = {};

export const changeCheckListStatus: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/as-fields/queue/checkListInstance/admin/${request.checkListId}/${request.status}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
