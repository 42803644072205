import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { MapPoint } from './components';

const MAP_POINT_MODAL_ID = 'map-point-modal';

const mapPointModalConfig: TModalConfig = {
  id: MAP_POINT_MODAL_ID,
  name: MAP_POINT_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  children: MapPoint,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
  closeOnClickOutside: true,
};

export { mapPointModalConfig, MAP_POINT_MODAL_ID };
