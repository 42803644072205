import { Autocomplete, Box, FormControlLabel, Switch, TextField } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { useModalForm } from '../../hooks';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { IOrgIntegraEmployee } from '../../../../models';
import { ISelectOption } from '../../../../../../../../../../../../../../types/selectOption';

const EmployeeModalForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const { getModalPayload, closeModal, getModalRuntimeHandlers } = useModal();
  const runtimeHandlers = getModalRuntimeHandlers();

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId: string;
    user: IOrgIntegraEmployee;
  };

  const {
    optionList,
    password,
    agroUserName,
    setPassword,
    setAgroUserName,
    astUser,
    isIntegrated,
    setAstUser,
    setIsIntegrated,
    createEmployee,
    updateEmployee,
    isDisabled,
    addDirty,
  } = useModalForm(orgStore?.organization?.organizationId, payload.mode, payload?.user?.id);

  useEffect(() => {
    if (payload.mode === 'update') {
      setPassword('********');

      if (payload?.user?.userName) setAgroUserName(payload.user.userName);
      if (payload?.user?.assigneeName) setAgroUserName(payload.user.assigneeName);
      if (payload?.user?.isIntegrated) setIsIntegrated(payload.user.isIntegrated);
      if (payload?.user?.userId)
        setAstUser({ label: payload?.user?.userName, value: payload?.user?.userId });
    }

    if (payload?.user?.userName && payload?.user?.userId) {
      setAstUser({ label: payload.user.userName, value: payload.user.userId });
    }
  }, []);

  const onSuccess = () => {
    if (payload.mode === 'create') {
      createEmployee().then(() => {
        runtimeHandlers?.onSuccess?.();
      });
    } else {
      updateEmployee().then(() => {
        runtimeHandlers?.onSuccess?.();
      });
    }
  };

  const onDeny = () => {
    closeModal();
  };

  const titleText = `${payload.mode === 'create' ? 'Добавление' : 'Детали'} сотрудника`;

  return (
    <Box>
      <ModalTitle>{titleText}</ModalTitle>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <Autocomplete
          options={optionList}
          fullWidth
          renderInput={props => (
            <TextField
              {...props}
              label={'Поиск по ФИО или ID пользователя'}
              placeholder={'Введите ФИО или ID поля из системы “Помощник”'}
            />
          )}
          disableClearable
          disablePortal
          value={astUser}
          onChange={(e, val) => {
            addDirty('astUser');
            setAstUser(val as ISelectOption);
          }}
        />
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />
        <TextField
          label="Логин в системе Cropwise"
          placeholder="Введите логин в системе Cropwise"
          value={agroUserName}
          onChange={e => {
            addDirty('agroUserName');
            setAgroUserName(e.target.value);
          }}
          fullWidth
        />

        <TextField
          label="Пароль в системе Cropwise"
          placeholder="Введите пароль в системе Cropwise"
          value={password}
          onChange={e => {
            let { value } = e.target;

            if (value.startsWith('*') && value.endsWith('*')) {
              value = value.replaceAll('*', '');
            }

            setPassword(value);
            addDirty('agroApiKey');
          }}
          fullWidth
        />
        <FormControlLabel
          sx={{ marginLeft: 2 }}
          control={
            <Switch
              checked={isIntegrated}
              onChange={e => {
                addDirty('isIntegrated');
                setIsIntegrated(e.target.checked);
              }}
            />
          }
          label="Включить интеграцию"
        />
        <ModalFooter
          successButton={{
            title: 'Сохранить',
            color: 'primary',
            handler: onSuccess,
            disabled: isDisabled,
          }}
          denyButton={{ title: 'Отменить', color: 'default', handler: onDeny }}
        />
      </Box>
    </Box>
  );
};

export default observer(EmployeeModalForm);
