import styled from 'styled-components';

const ActionItem = styled.label<{ $disabled?: boolean }>`
  display: flex;
  gap: 56px;
  align-items: center;
  width: 100%;
  max-width: 290px;
  justify-content: space-between;
  line-height: 1;
  ${({ $disabled }) =>
    $disabled &&
    `opacity: 0.8; 
      pointer-events: none;`};
`;

const Styled = {
  ActionItem,
};

export default Styled;
