import { TypeApiRoute } from '../../models/type.api.request';
import { TypeTag } from '../../models/tags.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  search?: string;
  size: number;
};

type TypeResponse = { content: TypeTag[] } & TypeResponsePageable;

type TypeError = {};

export const getTags: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-news/tags/list?size=${request.size}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
