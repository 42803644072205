import { ModalFooter, Scrollbar, useModal } from '@farmlink/farmik-ui';
import { useState } from 'react';
import { uniqBy } from 'lodash';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, LinearProgress, TextField } from '@mui/material';

import { SelectOrgByNameOrInn } from '../../../../../../modules/checklists/containers/ChecklistImportModalContainer/components';
import { ISelectOption } from '../../../../../../../../types/selectOption';
import { TooltipedValue } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils';
import { OrganizationFieldsController } from '../../../../pages/OrganizationFields/mobx';

import Styled from './ExportOrganizationsFieldsModal.styles';

const MAX_CHARS = 4;

const ExportOrganizationsFieldsModal = () => {
  const controller = useStore(OrganizationFieldsController);

  const { closeModal } = useModal();

  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOption>(null);
  const [organizationList, setOrganizationsList] = useState<ISelectOption[]>([]);
  const [season, setSeason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddOrg = (org: ISelectOption) => {
    setOrganizationsList(uniqBy([...organizationList, org], 'value'));
    setSelectedOrganization(null);
  };

  const handleRemoveOrg = (org: ISelectOption) => {
    setOrganizationsList(organizationList.filter(item => item.value !== org.value));
  };

  const handleChangeSeason = e => {
    if (e.target.value.toString().length <= MAX_CHARS) {
      setSeason(e.target.value);
    }
  };

  const handleExport = () => {
    setIsLoading(true);
    controller
      .fetchFieldsReport(
        {
          seasonYear: season,
          organizationIdIn: organizationList?.flatMap(item => item.value),
        },
        `exportedFields_${season}.csv`
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Styled.Title>Экспорт информации по полям</Styled.Title>

      <TextField
        sx={{ marginBottom: 2 }}
        label="Сезон"
        onChange={handleChangeSeason}
        value={season}
        autoComplete="none"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'left' } }}
      />

      <SelectOrgByNameOrInn
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={handleAddOrg}
        options={{ isClearOnSelect: true }}
      />

      <Styled.OrgList>
        <Scrollbar>
          {organizationList.map(item => (
            <Styled.OrgItem>
              <TooltipedValue value={item?.label} />
              <IconButton
                onClick={() => {
                  handleRemoveOrg(item);
                }}
                size="small"
              >
                <Close />
              </IconButton>
            </Styled.OrgItem>
          ))}
        </Scrollbar>
      </Styled.OrgList>

      {isLoading ? <LinearProgress /> : <Box height={5} />}
      <br />

      <ModalFooter
        denyButton={{ title: 'Отменить', handler: closeModal }}
        successButton={{
          title: 'Выгрузить',
          disabled: isLoading || season?.length < 4 || organizationList.length === 0,
          handler: handleExport,
        }}
      />
    </div>
  );
};

export default ExportOrganizationsFieldsModal;
