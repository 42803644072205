import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = { id: string };
type TypeResponse = string;

export const deleteReportConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/as-agrosignal/reports/config/${params.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
