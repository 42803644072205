import { FC } from 'react';
import { isEmpty } from 'lodash';

import { getLink } from '../../../shared/utils';
import { AdminRoutes, ERegexp } from '../../routes';
import { UserInterestType } from '../../../../api/models/user.model';
import { Chips } from '../Chips/Chips';
import { ProfileWidgetWrapper } from '../ProfileWidgetWrapper';

import { WrapperInteres, InteresEmptyStyled } from './styled';
import { ReactComponent as InteresIcon } from './assets/interes.svg';

type InteresWidgetProps = {
  userId: string;
  items: Array<UserInterestType>;
};

export const InteresWidget: FC<InteresWidgetProps> = ({ userId, items }) => (
  <ProfileWidgetWrapper
    title="Интересы"
    editLink={getLink(AdminRoutes.EditInterests, {
      [userId]: ERegexp.USERID,
    })}
    editCaption={isEmpty(items) ? 'Добавить' : 'Редактировать'}
    dataTestIdAction={
      isEmpty(items) ? 'user-widget-interest-action-add' : 'user-widget-interest-action-edit'
    }
    dataTestId="user-widget-interest"
  >
    <WrapperInteres data-test-id="user-widget-interest-content">
      {items?.length ? (
        items.map(item => (
          <Chips
            dataTestId="user-widget-interest-item"
            key={item.interestId}
            title={item.interestName}
          />
        ))
      ) : (
        <InteresEmptyStyled data-test-id="user-widget-interest-content-empty">
          <InteresEmptyStyled.Icon data-test-id="user-widget-interest-content-empty-icon-wrapper">
            <InteresIcon data-test-id="user-widget-interest-content-empty-icon" />
          </InteresEmptyStyled.Icon>
          <InteresEmptyStyled.Text data-test-id="user-widget-interest-content-empty-text">
            У вас ещё нет интересов,
            <br />
            но вы можете их добавить прямо сейчас
          </InteresEmptyStyled.Text>
        </InteresEmptyStyled>
      )}
    </WrapperInteres>
  </ProfileWidgetWrapper>
);
