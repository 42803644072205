import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { Chip, useModal, useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import { TooltipedValue } from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import {
  IOrganizationIntegraTableFilter,
  TFetchHandler,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraEmployee } from '../../models';
import { ORG_INTEGRA_EMPLOYEE_MODAL_ID } from '../../modals';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';

const EmployeeTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );
      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service.deleteEmployeeList({ ids: actionIdList }).then(() => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      })
    );

    return Promise.resolve();
  };

  const onOpenModal = () => {
    openModalByModalId(
      ORG_INTEGRA_EMPLOYEE_MODAL_ID,
      {
        mode: 'create',
        organizationId,
        user: null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraEmployee) => {
    openModalByModalId(
      ORG_INTEGRA_EMPLOYEE_MODAL_ID,
      {
        mode: 'update',
        organizationId,
        user: value,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraEmployee> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchEmployeeList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const integraTableFilterConfig: IOrganizationIntegraTableFilter = {
    filterCollection: [
      {
        type: 'text',
        label: 'ID Адаптер',
        propertyName: 'id',
        placeholder: 'Введите ID Адаптера',
      },
      {
        type: 'text',
        label: 'ID “Агроид”',
        propertyName: 'userId',
        placeholder: 'Введите ID “Агроид”',
      },
      {
        type: 'text',
        label: 'Имя сотрудника “Агросигнал”',
        propertyName: 'agroName',
        placeholder: 'Введите имя сотрудника “Агросигнал”',
      },
      {
        type: 'text',
        label: 'ID “Агросигнал”',
        propertyName: 'agroId',
        placeholder: 'Введите ID “Агросигнал”',
      },
      {
        type: 'select',
        label: 'Статус',
        propertyName: 'isIntegrated',
        optionList: [
          { label: 'Активный', value: 'true' },
          { label: 'Неактивный', value: 'false' },
        ],
      },
    ],
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraEmployee>
          actionButton={{ text: 'Добавить сотрудника', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          columnConfig={[
            {
              field: 'integrated',
              headerName: 'Статус',
              width: 110,
              valueGetter: row => row.row.integrated,
              renderCell: row => (
                <Chip size="medium" color={row.value ? 'primary' : 'secondary'}>
                  {row.value ? 'Активен' : 'Не активен'}
                </Chip>
              ),
            },
            {
              field: 'userName',
              headerName: 'Имя сотрудника “Агроид”',
              flex: 1,
              width: 100,
              valueGetter: row => row.row.userName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'userId',
              headerName: 'ID “Агроид”',
              flex: 1,
              valueGetter: row => row.row.userId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'agroName',
              headerName: 'Имя сотрудника “Агросигнал”',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.agroName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'agroId',
              headerName: 'ID “Агросигнал”',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.agroId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
          filter={integraTableFilterConfig}
        />
      )}
    </Box>
  );
};

export default EmployeeTable;
