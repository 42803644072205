export enum EReferenceType {
  Tips = 'TIPS',
  Modal = 'MODAL',
}

/**
 *
 *
 * @DTO ReferenceDto
 * @interface IReference
 */
export interface IReference {
  path: string;
  id: string;
  parentPath: string;
  type: EReferenceType;
  title: string;
  text: string;
  active: boolean;
  description: string;
  creationDate: string;
  updateDate: string;
  createdBy: {
    fullName: string;
    id: string;
  };
}
