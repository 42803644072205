import { FC, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

import { ISelectOption } from '../../../../../../../../../../../types/selectOption';
import { useStore } from '../../../../../../../../../../shared/utils';
import { ChecklistsAttributeController } from '../../../../../../../../../../controllers/checklistAttribute.controller';

const LabelSelector: FC<{
  value: (string | ISelectOption)[];
  setValue: (value: string | ISelectOption[]) => void;
}> = ({ value, setValue }) => {
  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);

  const controller = useStore(ChecklistsAttributeController);

  useEffect(() => {
    controller
      .getAttributeLabelList({})
      .then(data => {
        setOptionList(
          data.map(item => ({
            label: `${item.display} (${item.name})`,
            value: item.name,
          }))
        );
      })
      .finally(() => {
        setIsOptionsLoaded(true);
      });
  }, []);

  const parsedValueList = useMemo<ISelectOption[]>(() => {
    if (!isOptionsLoaded) {
      return value?.map((valueItem: string) => ({
        label: valueItem,
        value: valueItem,
      }));
    }

    return value?.map(valueItem => {
      if (typeof valueItem === 'string') {
        return optionList?.find(option => option.value === valueItem);
      }

      return valueItem;
    });
  }, [value, optionList, isOptionsLoaded]);

  return (
    <div>
      <Autocomplete
        options={optionList}
        value={parsedValueList}
        sx={{ minWidth: 400 }}
        multiple
        fullWidth
        onChange={(_, newValue) => {
          setValue(newValue as ISelectOption[]);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} id={`item-${option}`}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option?.label}
          </li>
        )}
        renderInput={params => <TextField {...params} fullWidth label="Список меток" />}
      />
    </div>
  );
};

export default LabelSelector;
