import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Menu, MenuItem, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  ENotificationType,
  ENotificatorTheme,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

import { Layout } from '../../../../components';
import { useStore } from '../../../../../shared/utils';
import { DictionariesController } from '../../../../../controllers/dictionaries.controller';
import { DictionariesStore } from '../../../../../stores';
import { SubHeader } from '../../../../style';
import { AdminRoutes } from '../../../../routes';
import { SpinnerLoaderWrapper } from '../../../../../shared/styles/dotsLoader';
import DictionarySidebar from '../DictionarySidebar/DictionarySidebar';
import { DropdownButton } from '../../../../../shared/components';
import {
  dictionaryExportModalConfig,
  DICTIONARY_MODAL_EXPORT,
  dictionaryImportModalConfig,
  DICTIONARY_MODAL_IMPORT,
} from '../../modals';

import Styled from './styled';
import { DictionaryMainSectionRouter } from './routes';

interface IImportMenuItem {
  title: string;
  handler: () => void;
}

type TAutocompleteParsedRemoteName = {
  label: string;
  remoteName: string;
};

export const DictionaryListContainer: FC = observer(() => {
  const { getAllDictionaries, cleanDictionaryCache } = useStore(DictionariesController);
  const {
    dictionaryNameList,
    setSelectedDictionaryListRemoteName,
    selectedDictionaryListRemoteName,
    getDictionaryNameByRemoteName,
  } = useStore(DictionariesStore);

  const history = useHistory();
  const { name } = useParams<{ name: string }>();
  const { setNotification } = useNotificator();
  const { registerModalList, openModalByModalId } = useModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectRemoteName = (_, value: TAutocompleteParsedRemoteName | null) => {
    setSelectedDictionaryListRemoteName(
      value ? { title: value.label, remoteName: value.remoteName } : null
    );

    if (value) {
      history.push(generatePath(AdminRoutes.DictionaryName, { name: value.remoteName }));
    } else {
      history.push(generatePath(AdminRoutes.Dictionary));
    }
  };

  const handleAutocompleteEquality = useCallback(
    (option: TAutocompleteParsedRemoteName, value: TAutocompleteParsedRemoteName) => {
      return option.remoteName === value.remoteName;
    },
    []
  );

  useEffect(() => {
    registerModalList(
      [dictionaryExportModalConfig, dictionaryImportModalConfig],
      'dictionaryListModals'
    );

    getAllDictionaries().then(() => {
      // TODO сделать сброс при клике на пункт меню
      if (name) {
        const dictRemoteName = getDictionaryNameByRemoteName(name);

        if (dictRemoteName) {
          setSelectedDictionaryListRemoteName(dictRemoteName);
        } else {
          history.push(generatePath(AdminRoutes.Dictionary));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (name) {
      const dictRemoteName = getDictionaryNameByRemoteName(name);

      setSelectedDictionaryListRemoteName(dictRemoteName);
    }
  }, [name]);

  const onCleanCacheClick = useCallback(() => {
    cleanDictionaryCache(selectedDictionaryListRemoteName?.remoteName)
      .then(() => {
        setNotification({
          message: `Кеш справочника "${selectedDictionaryListRemoteName?.title}" успешно сброшен`,
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .catch(() => {
        setNotification({
          message: `Ошибка сброса кеша справочника "${selectedDictionaryListRemoteName?.title}"`,
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      });
  }, [selectedDictionaryListRemoteName?.remoteName]);

  const parsedDictionaryNameList = useMemo(
    () =>
      dictionaryNameList
        ? dictionaryNameList.map(({ title, remoteName }) => ({
            label: title,
            remoteName,
          }))
        : null,
    [dictionaryNameList]
  );

  if (!dictionaryNameList) {
    return <SpinnerLoaderWrapper></SpinnerLoaderWrapper>;
  }

  return (
    <Styled.DictionaryMain>
      <SubHeader>
        {parsedDictionaryNameList ? (
          <Styled.Selector
            id="select-dictionary"
            options={parsedDictionaryNameList}
            renderInput={params => <TextField {...params} label="Справочник" />}
            onChange={handleSelectRemoteName}
            value={
              selectedDictionaryListRemoteName
                ? {
                    label: selectedDictionaryListRemoteName.title,
                    remoteName: selectedDictionaryListRemoteName.remoteName,
                  }
                : null
            }
            isOptionEqualToValue={handleAutocompleteEquality}
            placeholder="Выберите справочник"
            noOptionsText="Подходящих элементов не найдено"
            autoComplete
            autoHighlight
          />
        ) : (
          'loader'
        )}
        <Styled.ButtonGroup>
          {selectedDictionaryListRemoteName?.remoteName && (
            <Button type="button" variant="contained" color="info" onClick={onCleanCacheClick}>
              Сброс кэша справочника
            </Button>
          )}
          <DropdownButton
            title="Импорт"
            type="button"
            variant="contained"
            startIcon={<FileUploadIcon />}
            optionList={[
              {
                title: 'Импорт справочника',
                handler: () =>
                  openModalByModalId(DICTIONARY_MODAL_IMPORT, { isDependencies: false }),
                color: 'info',
              },
              {
                title: 'Импорт зависимостей справочника',
                handler: () =>
                  openModalByModalId(DICTIONARY_MODAL_IMPORT, { isDependencies: true }),
                color: 'info',
              },
            ]}
          />
          <DropdownButton
            title="Экспорт"
            type="button"
            variant="contained"
            startIcon={<DownloadIcon />}
            optionList={[
              {
                title: 'Экспорт справочника',

                handler: () =>
                  openModalByModalId(DICTIONARY_MODAL_EXPORT, { isDependencies: false }),
                color: 'info',
              },
              {
                title: 'Экспорт зависимостей справочника',
                handler: () =>
                  openModalByModalId(DICTIONARY_MODAL_EXPORT, { isDependencies: true }),
                color: 'info',
              },
            ]}
          />
        </Styled.ButtonGroup>
      </SubHeader>
      <Layout
        style={{ height: '90%' }}
        id="layout"
        menuItems={null}
        sidebarComponent={DictionarySidebar}
      >
        <DictionaryMainSectionRouter />
      </Layout>
    </Styled.DictionaryMain>
  );
});
