import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
};
type TypeResponse = string;

export const removeOwnerZond: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ id }: TypeRequest) => `/api/as-agropogoda/zonds/${id}/removeOwner`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
