import { useEffect, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';

import { TypeApiRequest, useStore } from '../../../../../../../../../../../../shared/utils';
import { ChecklistService } from '../../../../../../../../../../../../services';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { IChecklist } from '../../../../../../../../../../../../../api/models/checklist.model';
import { OrgIntegraAgrosignalService } from '../../../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';

const useModalForm = (organizationId: string, entityId: string) => {
  const checklistService = useStore(ChecklistService);
  const service = useStore(OrgIntegraAgrosignalService);

  const [optionList, setOptionList] = useState<ISelectOptionExtended<IChecklist>[]>([]);
  const [checklist, setChecklist] = useState<ISelectOptionExtended<IChecklist>>(null);
  const [agroChecklistId, setAgroChecklistId] = useState<number>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const { setNotification } = useNotificator();

  const fetchHandler = () => {
    checklistService
      .fetchChecklistListByParams({ organizationIds: [organizationId], size: 3000 })
      .then(data => {
        setOptionList(
          data.content?.map(item => ({
            label: `${item.name}/${item.id}`,
            value: item.id,
            rawData: item,
          }))
        );
      });
  };

  const createChecklist = () => {
    if (!(agroChecklistId > 0 && (checklist?.value as string)?.length > 0)) {
      setNotification(
        getNotificatorProps('Проверьте заполненность полей', ENotificationStyles.Error)
      );

      return Promise.reject(new Error('VALIDATION_ERROR'));
    }

    const payload: TypeApiRequest<'createOrgIntegraAgrosignalChecklist'> = {
      organizationId,
      checklistId: String(checklist.value),
      agrosChecklistId: agroChecklistId,
    };

    return service
      .createChecklist(payload)
      .then(data => {
        setNotification(
          getNotificatorProps('Чек-лист успешно создан', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(e => {
        setNotification(
          getNotificatorProps('Ошибка создания чек-листа', ENotificationStyles.Error)
        );

        return e;
      });
  };

  const updateChecklist = () => {
    if (!(agroChecklistId > 0 && (checklist?.value as string)?.length > 0)) {
      setNotification(
        getNotificatorProps('Проверьте заполненность полей', ENotificationStyles.Error)
      );

      return Promise.reject(new Error('VALIDATION_ERROR'));
    }

    const payload: TypeApiRequest<'updateOrgIntegraAgrosignalChecklist'> = {
      organizationId,
      id: entityId,
      checklistId: String(checklist.value),
      agrosChecklistId: agroChecklistId,
    };

    return service
      .updateChecklist(payload)
      .then(data => {
        setNotification(
          getNotificatorProps('Чек-лист успешно обновлён', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(e => {
        setNotification(
          getNotificatorProps('Ошибка обновления чек-листа', ENotificationStyles.Error)
        );

        return e;
      });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  useEffect(() => {
    setIsDisabled(!(agroChecklistId > 0 && (checklist?.value as string)?.length > 0));
  }, [checklist, agroChecklistId]);

  return {
    optionList,
    checklist,
    setChecklist,
    agroChecklistId,
    setAgroChecklistId,
    isDisabled,
    createChecklist,
    updateChecklist,
  };
};

export { useModalForm };
