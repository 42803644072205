/* eslint-disable no-console */
import { Axios, lazyInject, provide, TypeApiRequest } from '../../shared/utils';

@provide.singleton()
export class ChecklistService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchChecklistListByParams = (payload: TypeApiRequest<'getChecklistListByParams'>) => {
    try {
      return this.axios.api
        .getChecklistListByParams(payload, { omit: ['page', 'size'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Fetch checklist error`, error);
    }
  };

  exportChecklistByIds = (payload: TypeApiRequest<'exportChecklistByIds'>) => {
    try {
      return this.axios.api
        .exportChecklistByIds(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Export checklist error`, error);
    }
  };

  importChecklistDryRun = (payload: TypeApiRequest<'importChecklistDryRun'>, file: FormData) => {
    try {
      const { createNewBaseAttributes, createNewCheckLists, organizationId } = payload;

      return this.axios.api
        .importChecklistDryRun(payload, {
          omit: ['createNewBaseAttributes', 'createNewCheckLists'],
          formData: file,
          query: {
            createNewBaseAttributes,
            createNewCheckLists,
            organizationId,
          },
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import DryRun checklist error`, error);
    }
  };

  importChecklist = (payload: TypeApiRequest<'importChecklist'>, file: FormData) => {
    try {
      const { createNewBaseAttributes, createNewCheckLists, organizationId } = payload;

      return this.axios.api
        .importChecklist(payload, {
          omit: ['createNewBaseAttributes', 'createNewCheckLists'],
          formData: file,
          query: {
            createNewBaseAttributes,
            createNewCheckLists,
            organizationId,
          },
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import checklist error`, error);
    }
  };

  createChecklist = (payload: TypeApiRequest<'createChecklist'>) => {
    try {
      return this.axios.api
        .createChecklist(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create checklist error`, error);
    }
  };

  getChecklist = (payload: TypeApiRequest<'getChecklist'>) => {
    try {
      return this.axios.api
        .getChecklist(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist error`, error);
    }
  };

  createChecklistStage = (payload: TypeApiRequest<'createChecklistStage'>) => {
    try {
      return this.axios.api
        .createChecklistStage(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create checklist attribute error`, error);
    }
  };

  getChecklistStageList = (payload: TypeApiRequest<'getChecklistStageList'>) => {
    try {
      return this.axios.api
        .getChecklistStageList(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist attribute list error`, error);
    }
  };

  changeChecklistStage = (payload: TypeApiRequest<'changeChecklistStage'>) => {
    try {
      return this.axios.api
        .changeChecklistStage(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change checklist stage error`, error);
    }
  };

  deleteChecklistStage = (payload: TypeApiRequest<'deleteChecklistStage'>) => {
    try {
      return this.axios.api
        .deleteChecklistStage(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Delete checklist stage error`, error);
    }
  };

  changeChecklist = (payload: TypeApiRequest<'changeChecklist'>) => {
    try {
      return this.axios.api
        .changeChecklist(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change checklist error`, error);
    }
  };

  deleteChecklist = (payload: TypeApiRequest<'deleteChecklist'>) => {
    try {
      return this.axios.api
        .deleteChecklist(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          return e;
        });
    } catch (error) {
      console.error(`Delete checklist error`, error);
    }
  };

  copyChecklist = (payload: TypeApiRequest<'copyChecklist'>) => {
    try {
      return this.axios.api
        .copyChecklist(payload as any)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Copy checklist error`, error);
    }
  };
}
