import { AccordionDetails, Typography } from '@mui/material';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Colors } from '@farmlink/farmik-ui';

import { IFormulaError } from '../../../../models';

import Styled from './FormulaErrorLog.styles';

interface IProps {
  errorItemList: IFormulaError[];
}

const FormulaErrorLog: FC<IProps> = ({ errorItemList }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const errorCount = useMemo(() => errorItemList?.length, [errorItemList]);
  const hasErrors = errorCount > 0;

  useEffect(() => {
    setIsExpanded(errorCount > 0);
  }, [errorCount]);

  const onErrorItemClick = useCallback((error: IFormulaError) => {
    const elem = error?.ref?.current || document.getElementById(`${error.HTMLElementId}`);

    if (elem) {
      elem?.scrollIntoView({ behavior: 'smooth' });
      elem?.classList.add('highlight');

      setTimeout(() => {
        elem.classList.remove('highlight');
      }, 3000);
    }
  }, []);

  return (
    <Styled.Accordion
      $isError={hasErrors}
      disabled={!hasErrors}
      expanded={isExpanded}
      onChange={(e, value) => {
        setIsExpanded(value);
      }}
    >
      <Styled.AccordionSummary>
        {hasErrors ? (
          <Typography variant="body2" color={Colors.pink}>
            Найдено ошибок: {errorCount > 9 ? '9+' : errorCount}
          </Typography>
        ) : (
          <Typography variant="body2" color={Colors.green}>
            Ошибок не найдено
          </Typography>
        )}
      </Styled.AccordionSummary>
      <AccordionDetails>
        <Styled.List>
          {errorItemList?.map((item, i) => (
            <Styled.Item key={i} onClick={() => onErrorItemClick(item)}>
              {<Typography variant="caption">{item.text}</Typography>}
            </Styled.Item>
          ))}
        </Styled.List>
      </AccordionDetails>
    </Styled.Accordion>
  );
};

export default memo(FormulaErrorLog);
