import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = {
  token: string;
  expirationDate: string;
};

type TypeError = {};

export const loginAs: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/da-profile/users/admin/loginAs/${request.id}`,
  method: 'POST',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
  headers: {
    'DA-HOST': 'da-services',
  },
};
