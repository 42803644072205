import { FormHelperText } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../../constants/colors';
import { FontSizes } from '../../../../../../../../../../../../constants/sizes';

const ErrorText = styled(FormHelperText)<{ $visibility: boolean }>`
  color: ${Colors.red} !important;
  display: ${({ $visibility }) => ($visibility ? 'block' : 'none')};
`;

const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.p`
  ${FontSizes.default};
  margin: 0;
  padding: 0;
  margin-top: 8px;
`;

const Styled = {
  ErrorText,
  SelectorWrapper,
  Title,
};

export default Styled;
