import { Autocomplete, TextField } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

import {
  ISelectOption,
  ISelectOptionExtended,
} from '../../../../../../../../../types/selectOption';

interface IProps {
  options?: ISelectOptionExtended[];
  onSelect: (value: Record<string, boolean | string | number | ISelectOptionExtended>) => void;
  onClear: (name: string) => void;
  onInputChange?: (value: string) => void;
  fetchOrganizationOnChange?: (value: string) => Promise<ISelectOption[]>;
  value: boolean | string | number;
  label: string;
  name: string;
  isToggler?: boolean;
}

const SelectField: FC<IProps> = ({
  label,
  name,
  onSelect,
  options,
  value,
  onClear,
  isToggler,
  onInputChange,
  fetchOrganizationOnChange,
}) => {
  const [optionList, setOptionList] = useState<ISelectOptionExtended[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<ISelectOptionExtended>(null);

  useEffect(() => {
    if (Array.isArray(options)) {
      setOptionList(options || []);
    }
  }, [options]);

  useEffect(() => {
    const valueOption =
      typeof value === 'object'
        ? value
        : optionList?.find(item => String(item.value) === String(value));

    setInputValue(valueOption?.label ?? '');
    setSelectedOption(valueOption ?? null);
  }, [value]);

  const handleInputChange = useCallback(
    (changedValue: string) => {
      setInputValue(changedValue);
      onInputChange?.(changedValue);

      fetchOrganizationOnChange?.(changedValue)?.then(data => {
        setOptionList(data);
      });
    },
    [onInputChange, fetchOrganizationOnChange]
  );

  return (
    <Autocomplete
      options={optionList}
      renderInput={params => <TextField {...params} label={label} />}
      onChange={(v, option, reason) => {
        if (reason === 'clear') {
          return onClear(name);
        }

        if (isToggler) {
          return onSelect({ [name]: (option as ISelectOptionExtended).value === 'true' });
        }

        setInputValue((option as ISelectOptionExtended).label);
        onSelect({ [name]: (option as ISelectOptionExtended).value });
      }}
      value={selectedOption as ISelectOption}
      inputValue={inputValue}
      onInputChange={(e, changedInputValue) => handleInputChange(changedInputValue)}
    />
  );
};

export default SelectField;
