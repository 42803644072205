import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { PogodaVPoleReportForm } from './components';

const POGODAVPOLE_REPORT_MODAL_ID = 'pogodavpole-report-modal';

const pogodavpoleReportModalConfig: TModalConfig = {
  id: POGODAVPOLE_REPORT_MODAL_ID,
  name: POGODAVPOLE_REPORT_MODAL_ID,
  type: EModalType.Custom,
  title: 'Сформировать отчёт',
  children: PogodaVPoleReportForm,
  closeOnClickOutside: true,

  styledProps: {
    $modalPaddings: '20px',
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '100%',
  },
};

export { POGODAVPOLE_REPORT_MODAL_ID, pogodavpoleReportModalConfig };
