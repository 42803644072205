import { EChecklistAttributeType } from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { ChecklistAttributeService } from '../../../../../../../../../../../../services';
import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import { EEnumDepsTab, TFormData } from '../../models';
import { EnumDependencyStore } from '../stores';

@provide.singleton()
class EnumDependencyController {
  @lazyInject(EnumDependencyStore)
  protected store: EnumDependencyStore;

  @lazyInject(ChecklistAttributeService)
  protected checklistAttributeService: ChecklistAttributeService;

  fetchDependencyList = async (
    type: EEnumDepsTab,
    options: {
      dependencyAttributeId?: string;
      dependencyAttributeName?: string;
      targetAttributeId?: string;
      targetAttributeName?: string;
    }
  ) => {
    try {
      this.store.setIsDepLinkListLoading(true);
      const response = await this.checklistAttributeService.getEnumDependencyLinkList({
        ...options,
        size: 1000,
      });

      this.store.setDepCollectionByType(type, response.content);
    } catch (error) {
      console.log('Ошибка запроса списка связей enum листа');
    } finally {
      this.store.setIsDepLinkListLoading(false);
    }
  };

  fetchActiveEnumAttributeList = async (page?: number) => {
    try {
      const response = await this.checklistAttributeService.getActiveChecklistAttributeList({
        type: EChecklistAttributeType.Enum,
        size: 1000,
        page,
      });

      this.store.setActiveEnumList(response.content);
    } catch (error) {
      console.log('Ошибка запроса списка enum листа');
    }
  };

  fetchCurrentEnumValueList = async (id: string) => {
    try {
      const response = await this.checklistAttributeService.getChecklistAttributeEnumList({ id });

      this.store.setCurrentEnumItemList(response);
    } catch (error) {
      console.log('Ошибка запроса списка значений enum листа текущего атрибута');
    }
  };

  fetchSelectedEnumValueList = async (id: string) => {
    const response = await this.checklistAttributeService.getChecklistAttributeEnumList({ id });

    this.store.setSelectedEnumItemList(response);
  };

  createEnumDependencyLink = async (type: EEnumDepsTab, data: TFormData) => {
    if (!data || !type) {
      Promise.reject(new Error('NO_DATA'));
    }

    const payload: { dependencyValueId: string; targetValueId: string } = {
      dependencyValueId: null,
      targetValueId: null,
    };

    switch (type) {
      case EEnumDepsTab.Depends:
        payload.dependencyValueId = String(data.currentEnumValue.value);
        payload.targetValueId = String(data.selectedEnumValue.value);

        break;

      case EEnumDepsTab.Depend:
        payload.dependencyValueId = String(data.selectedEnumValue.value);
        payload.targetValueId = String(data.currentEnumValue.value);

        break;

      default:
        Promise.reject(new Error('NO_DATA'));
        break;
    }

    const response = await this.checklistAttributeService.createEnumDependencyLink(payload);

    return response;
  };

  deleteEnumDependencyLink = (id: string) => {
    return this.checklistAttributeService.deleteEnumDependencyLink({ id });
  };
}

export default EnumDependencyController;
