import { Box, Tab, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useMemo, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { set } from 'lodash';

import { AttributeODZController, AttributeODZStore } from '../../../../mobx';
import { ModalTitle } from '../../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../../shared/utils';
import { EODZCriteria } from '../../../../models';

import Styled from './FormulaPreview.styled';

const FormulaPreview = () => {
  const store = useStore(AttributeODZStore);
  const controller = useStore(AttributeODZController);

  const [currentTab, setCurrentTab] = useState<EODZCriteria>(null);

  const { closeModal } = useModal();

  const data = useMemo(() => {
    switch (currentTab) {
      case EODZCriteria.Red:
        return JSON.stringify(controller.removeClientId(store.redSerializedCriteria), null, 2);

      case EODZCriteria.Yellow:
        return JSON.stringify(controller.removeClientId(store.yellowSerializedCriteria), null, 2);

      case EODZCriteria.Green:
        return JSON.stringify(controller.removeClientId(store.greenSerializedCriteria), null, 2);

      default:
        return null;
    }
  }, [
    store.redSerializedCriteria,
    store.yellowSerializedCriteria,
    store.greenSerializedCriteria,
    currentTab,
  ]);

  // const onDownload = () => {
  //   const blob = new Blob([data]);

  //   downloadBlobAsFile(blob, `formula(${store.serializedFormula?.type}).json`);
  // };

  return (
    <Box minHeight={400} width={'100%'}>
      <ModalTitle>Предпросмотр формулы</ModalTitle>
      <TabContext value={currentTab}>
        <TabList onChange={(e, v) => setCurrentTab(v)}>
          <Tab color="error" value={EODZCriteria.Red} label="Красный" />
          <Tab value={EODZCriteria.Yellow} label="Жёлтый" />
          <Tab value={EODZCriteria.Green} label="Зелёный" />
        </TabList>
        <Box height={'100%'} display={'flex'} flexDirection={'column'} gap="20px">
          <Box height={300} overflow={'scroll'}>
            {currentTab ? (
              <SyntaxHighlighter language="json" wrapLines>
                {data}
              </SyntaxHighlighter>
            ) : (
              <Typography>Выберите границу для предпросмотра</Typography>
            )}
          </Box>

          <Styled.Footer
            // successButton={{ title: 'Скачать как файл', handler: onDownload }}
            denyButton={{ title: 'Закрыть', handler: closeModal }}
          />
        </Box>
      </TabContext>
    </Box>
  );
};

export default observer(FormulaPreview);
