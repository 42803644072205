import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import FormulaPreview from './components/FormulaPreview/FormulaPreview';

const FORMULA_ODZ_PREVIEW_MODAL_ID = 'formula-odz-preview-modal-id';

const formulaODZPreviewModalConfig: TModalConfig = {
  id: FORMULA_ODZ_PREVIEW_MODAL_ID,
  name: FORMULA_ODZ_PREVIEW_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
    $size: EModalSize.Large,
  },
  children: FormulaPreview,
};

export { FORMULA_ODZ_PREVIEW_MODAL_ID, formulaODZPreviewModalConfig };
