import { Axios, TypeApiRequest, lazyInject, provide } from '../../../../../../../shared/utils';

@provide.transient()
class ReferenceService {
  @lazyInject(Axios)
  protected axios: Axios;

  getReferenceList = (request: TypeApiRequest<'getReferenceList'>) => {
    try {
      const response = this.axios.api.getReferenceList(request);

      return response;
    } catch (error) {
      console.log('Reference fetch error', error);
    }
  };

  updateReference = async (request: TypeApiRequest<'updateReference'>) => {
    try {
      const response = await this.axios.api.updateReference(request, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('Update reference error', error);
    }
  };
}

export default ReferenceService;
