import { TypeCheckList } from '../../../../../../../../../api/models/check.list.model';
import { STATUSES } from '../../../../../../../../../constants/statuses'

export const getValues = (checkList: TypeCheckList) => ({
    name: checkList.name || '',
    id: checkList.id || '',
    timestamp: checkList.timestamp || '',
    status: STATUSES[checkList.status] || '',
    error: checkList.error || '',
});
