import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { useStore } from '../../../../../shared/utils';
import { Layout } from '../../../../components';
import { getOrganizationMenu } from '../../../../../shared/menu';

import {
  OrganizationFieldsFilter,
  OrganizationFieldsInfo,
  OrganizationFieldsTable,
} from './components';
import { OrganizationFieldsController, OrganizationFieldsStore } from './mobx';

export const OrganizationFields: FC = observer(() => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const controller = useStore(OrganizationFieldsController);

  const store = useStore(OrganizationFieldsStore);

  useEffect(() => {
    return () => store.clearStore();
  }, []);

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  const onDownloadReportClick = () => {
    if (!store.seasonsFilterValue?.value) {
      return;
    }

    controller.fetchFieldsReport({
      seasonYear: store.seasonsFilterValue?.value,
      organizationIdIn: [organizationId],
    });
  };

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Список полей</H1>
            <Button onClick={onDownloadReportClick} startIcon={<Download />} variant="contained">
              Экспорт
            </Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <OrganizationFieldsFilter />
          <OrganizationFieldsInfo />
          <OrganizationFieldsTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
