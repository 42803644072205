import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationIdIn: string[];
  seasonYear: string;
};

type TypeResponse = Blob;

export const getFieldsInfoReportCsvBySeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/admin/fields/fieldsInfoBySeason/csv`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
