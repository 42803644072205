import { observer } from 'mobx-react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { Box, Button, LinearProgress, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Formik } from 'formik';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { trim } from 'lodash';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { OrganizationRolesSidebar } from '../OrganizationRolesSidebar';
import { useStore } from '../../../../../../../shared/utils';
import { OrganizationRoleController, OrganizationRoleStore } from '../../mobx';
import { ROLE_BASE_INPUT_SCHEME, getBaseFormDataFromRole } from '../../helpers';
import { IRoleInputBase } from '../../../../../../../../api/models/role.model';
import { AdminRoutes } from '../../../../../../routes';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { DELETE_ROLE_MODAL_ID } from '../../modals';

import { RoleItemActionList, RoleItemForm, RoleItemUserList } from './components';

enum ETab {
  Actions = 'actions',
  Users = 'users',
}

const OrganizationRoleItem = () => {
  const store = useStore(OrganizationRoleStore);
  const controller = useStore(OrganizationRoleController);

  const { organizationId, roleId } = useParams<{ organizationId: string; roleId: string }>();
  const { setNotification } = useNotificator();
  const history = useHistory();
  const { openModalByModalId } = useModal();

  const isCreation = Boolean(useRouteMatch(AdminRoutes.OrganizationRoleCreate));
  const isNoRole = store.roleItemByRoleId.size > 0 && !store.roleItemByRoleId.has(roleId);

  const [tab, setTab] = useState<ETab>(ETab.Actions);

  const roleInfo = store.getRoleInfo(roleId);
  const roleFormData = useMemo(() => getBaseFormDataFromRole(roleInfo?.role), [roleInfo]);

  const onChangeTab = useCallback((e, key: ETab) => {
    setTab(key);
  }, []);

  const handleSubmit = (data: IRoleInputBase, { setSubmitting }) => {
    if (isCreation) {
      controller
        .createRole({ organizationId, ...data, name: trim(data.name) })
        .then(response => {
          setNotification(
            getNotificatorProps(
              `Роль «${response.name}» успешно создана`,
              ENotificationStyles.Success
            )
          );

          controller.fetchRoleList(organizationId, true);

          history.push(
            generatePath(AdminRoutes.OrganizationRoleItem, {
              organizationId,
              roleId: response.id,
            })
          );
        })
        .catch(() => {
          setNotification(getNotificatorProps(`Ошибка создания роли`, ENotificationStyles.Error));
        });
    } else {
      controller
        .updateRole({ id: roleId, ...data, name: trim(data.name) })
        .then(response => {
          setNotification(
            getNotificatorProps(
              `Роль «${response.name}» успешно изменена`,
              ENotificationStyles.Success
            )
          );

          controller.fetchRoleList(organizationId, true);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps(`Ошибка редактирования роли`, ENotificationStyles.Error)
          );
        });
    }

    setSubmitting(false);
  };

  const handleCancel = useCallback(() => {
    history.push(generatePath(AdminRoutes.OrganizationRoleList, { organizationId }));
  }, [organizationId]);

  const handleDelete = useCallback(() => {
    openModalByModalId(DELETE_ROLE_MODAL_ID, null, () =>
      controller
        .deleteRole({ id: roleId })
        .then(() => {
          setNotification(getNotificatorProps(`Роль успешно удалена`, ENotificationStyles.Success));

          controller.fetchRoleList(organizationId, true).then(() => {
            history.push(generatePath(AdminRoutes.OrganizationRoleList, { organizationId }));
          });
        })
        .catch(() => {
          setNotification(getNotificatorProps(`Ошибка удаления роли`, ENotificationStyles.Error));
        })
    );
  }, [roleId]);

  if (store.isRoleListLoading) {
    return <LinearProgress />;
  }

  return (
    <Layout menuItems={null} sidebarComponent={OrganizationRolesSidebar}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={isCreation ? {} : roleFormData}
        validationSchema={ROLE_BASE_INPUT_SCHEME}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <MainContainer sx={{ minHeight: 'calc(100vh - 116px)' }}>
            <HeaderWrapper>
              <TopSection>
                {isCreation ? <H1>Создание роли</H1> : <H1>Общие настройки роли</H1>}
              </TopSection>

              <br />
              <Box display={'flex'} justifyContent={'space-between'}>
                {isCreation ? (
                  <>
                    <Button disabled={isSubmitting} onClick={submitForm} variant="contained">
                      Создать
                    </Button>
                    <Button
                      onClick={handleCancel}
                      disabled={isSubmitting}
                      variant="contained"
                      color="error"
                    >
                      Отменить
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={isSubmitting || isNoRole || !roleInfo?.role.canEdit}
                      onClick={submitForm}
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button
                      disabled={isSubmitting || isNoRole || !roleInfo?.role.canDelete}
                      variant="contained"
                      color="error"
                      onClick={handleDelete}
                    >
                      Удалить
                    </Button>
                  </>
                )}
              </Box>
              <Typography align="right" fontSize={12}>
                {!isCreation &&
                  'При удалении, всем сотрудникам этой роли будет назначена роль «Сотрудник»'}
              </Typography>
            </HeaderWrapper>

            {isNoRole && !isCreation ? (
              <MainBody
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                height="40%"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <PeopleOutlineIcon color="error" />
                <Typography>Роль не найдена</Typography>
              </MainBody>
            ) : (
              <MainBody>
                <RoleItemForm isCreation={isCreation} />

                <TabContext value={tab}>
                  {!isCreation && (
                    <>
                      <Box>
                        <TabList onChange={onChangeTab}>
                          <Tab label="Доступные действия" value={ETab.Actions} />
                          <Tab label="Сотрудники" value={ETab.Users} />
                        </TabList>
                      </Box>
                      <TabPanel value={ETab.Actions}>
                        <RoleItemActionList roleId={roleId} />
                      </TabPanel>
                      <TabPanel value={ETab.Users}>
                        <RoleItemUserList />
                      </TabPanel>
                    </>
                  )}
                </TabContext>
              </MainBody>
            )}
          </MainContainer>
        )}
      </Formik>
    </Layout>
  );
};

export default observer(OrganizationRoleItem);
