import styled from 'styled-components';

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  gap: 4px 8px;
`;

const GridWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
`;

const Styled = { GridGroup, GridWrapper };

export default Styled;
