import _, { has, isEmpty } from 'lodash';

import { EReferenceType } from '../../../../../../../../api/models/reference.model';
import {
  TypeApiRequest,
  deepObjectsDiff,
  lazyInject,
  provide,
} from '../../../../../../../shared/utils';
import {
  EFilterFormActivity,
  EFilterFromType,
  IFilterForm,
} from '../../../../types/filterForm.types';
import { ReferenceService } from '../services';
import { ReferenceStore } from '../stores';

@provide.transient()
class ReferenceController {
  @lazyInject(ReferenceStore)
  protected store: ReferenceStore;

  @lazyInject(ReferenceService)
  protected service: ReferenceService;

  fetchReferenceList = async (filterState: Partial<IFilterForm>, isClear?: boolean) => {
    const parseIsActive = (): boolean => {
      if (filterState?.activity === EFilterFormActivity.None) {
        return null;
      }

      if (has(filterState, 'activity')) {
        return filterState.activity === EFilterFormActivity.Active;
      } else {
        return null;
      }
    };

    const parseType = (): EReferenceType => {
      if (filterState?.type === EFilterFromType.All) {
        return null;
      }

      if (filterState?.type === EFilterFromType.Modal) {
        return EReferenceType.Modal;
      }

      if (filterState?.type === EFilterFromType.Tips) {
        return EReferenceType.Tips;
      }
    };

    const payload: TypeApiRequest<'getReferenceList'> = _.omitBy(
      {
        path: filterState?.path ?? null,
        parentPath: filterState?.parentPath ?? null,
        active: parseIsActive(),
        type: parseType(),
        empty: filterState?.empty ?? null,
        size: 500,
        page: filterState?.page || 0,
      },
      _.isNull || _.isEmpty
    );

    if (isClear) {
      this.store.clearReferenceCollection();
    }

    const data = await this.service.getReferenceList(payload);

    this.store.setReferenceCollection(data.content);

    return data;
  };

  setActiveRef = async (path: string) => {
    const ref = this.store.getReferenceByPath(path);

    if (ref) {
      this.store.setActiveReference(ref);
      return Promise.resolve(ref);
    } else {
      const fetchedRef = (await this.fetchReferenceList({ path })).content;

      this.store.setActiveReference(fetchedRef[0]);
    }

    this.store.clearIsPreventChangeRef();
  };

  updateRef = async () => {
    const changedRef = await this.service.updateReference({
      id: this.store.activeReference.id,
      ...(typeof this.store.activeReference?.active === 'boolean' && {
        active: this.store.activeReference.active,
      }),
      ...(this.store.activeReference.title && { title: this.store.activeReference.title }),
      ...(this.store.activeEditorState && {
        text: this.normalizeEditorContent(this.store.activeEditorState),
      }),
    });

    this.store.addReferenceToCollection(changedRef);

    this.store.clearIsPreventChangeRef();
  };

  normalizeEditorContent = (data: string) => {
    let normalizedData = data;

    normalizedData = normalizedData.replaceAll(`href="`, `target="_blank" href="`);

    return normalizedData;
  };
}

export default ReferenceController;
