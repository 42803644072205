import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../../../../../../../shared/utils';

import { OrganizationIntegraTableButton } from './components';
import { IOrganizationIntegraTableProps } from './types/organizationIntegraTable.types';
import { useDataGrid } from './hooks';
import OrganizationIntegraTableFilter from './modules/OrganizationIntegraTableFilter/components/OrganizationIntegraTableFilter/OrganizationIntegraTableFilter';
import { OrganizationIntegraTableFilterActiveButton } from './modules/OrganizationIntegraTableFilter/components/OrganizationIntegraTableFilter/components';
import { OrgIntegraTableFilterStore } from './modules/OrganizationIntegraTableFilter/mobx';

const OrganizationIntegraTable = <T,>({
  actionButton,
  additionalActionButtonList,
  deleteButton,
  columnConfig,
  fetchHandler,
  controlledPage,
  controlledSize,
  isDisableSelection,
  filter,
}: IOrganizationIntegraTableProps<T>) => {
  const { isInitialized } = useStore(OrgIntegraTableFilterStore);

  const {
    isLoading,
    content,
    count,
    page,
    size,
    setPage,
    setSize,
    rowSelectionModel,
    setRowSelectionModel,
  } = useDataGrid({
    fetchHandler,
    controlledPage,
    controlledSize,
    filter,
    isFilterInitialized: filter ? isInitialized : true,
  });

  const history = useHistory();

  const isNoButtons = !(actionButton || deleteButton || additionalActionButtonList);

  return (
    <Box>
      <Box
        display={'flex'}
        gap={2}
        justifyContent={isNoButtons ? 'flex-end' : 'space-between'}
        marginBottom={isNoButtons ? 0 : 2}
        width={'100%'}
        position={'relative'}
      >
        {deleteButton && (
          <OrganizationIntegraTableButton<T>
            {...deleteButton}
            handler={e => {
              return deleteButton?.handler?.(e, rowSelectionModel);
            }}
          />
        )}

        <Box display={'flex'} gap={2}>
          {filter && <OrganizationIntegraTableFilterActiveButton />}

          <Box display={'flex'} gap={2}>
            {additionalActionButtonList?.map((button, i) => (
              <OrganizationIntegraTableButton<T>
                {...button}
                key={`actionButton-${i}`}
                handler={e => {
                  return button?.handler?.(e, rowSelectionModel);
                }}
              />
            ))}
            {actionButton && (
              <OrganizationIntegraTableButton<T>
                {...actionButton}
                handler={e => {
                  return actionButton?.handler?.(e, rowSelectionModel);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      {filter && <OrganizationIntegraTableFilter filter={filter} />}

      <div style={{ height: '540px', width: '100%' }}>
        <DataGrid
          columns={columnConfig}
          rows={content}
          rowCount={count}
          loading={isLoading}
          page={page}
          pageSize={size}
          rowsPerPageOptions={[25, 50, 100]}
          onPageChange={_page => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('page', _page.toString());

            history.replace({
              pathname: location.pathname,
              search: searchParams.toString(),
            });

            setPage(_page);
          }}
          onPageSizeChange={_size => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('size', _size.toString());

            history.replace({
              pathname: location.pathname,
              search: searchParams.toString(),
            });

            setSize(_size);
          }}
          onSelectionModelChange={setRowSelectionModel}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          density="compact"
          paginationMode="server"
          pagination
          {...(isDisableSelection
            ? {}
            : { checkboxSelection: true, disableSelectionOnClick: true })}
        />
      </div>
    </Box>
  );
};

export default observer(OrganizationIntegraTable);
