import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeAlert } from '../../../api/models/alert.model';

@provide.singleton()
export class AlertsTypesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  alertsTypes: TypeAlert[] = [];

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setAlertsTypes = (types: TypeAlert[]) => {
    runInAction(() => {
      this.alertsTypes = types;
    });
  };

  clearAlertsTypes = () => {
    this.setAlertsTypes([]);
  };
}
