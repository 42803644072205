import { TypeApiRoute } from '../../models/type.api.request';
import { IFieldsInfoBySeason } from '../../models/field.model';

type TypeRequest = {
  seasonYear: string;
  organizationId: string;
};

type TypeResponse = IFieldsInfoBySeason;

type TypeError = {};

export const getFieldsInfoBySeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/fields/fieldsInfoBySeason`,
  headers: {},
  method: 'GET',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
