import { FC } from 'react';

import { PaperWrapper } from './style';

export type PaperBlockProps = {
  hoverable?: boolean;
  size?: any;
  dataTestId?: string;
};

export const PaperBlock: FC<PaperBlockProps> = ({
  children,
  hoverable = false,
  dataTestId = '',
}) => (
  <PaperWrapper hoverable={hoverable} data-test-id={dataTestId}>
    {children}
  </PaperWrapper>
);
