import {
  Chip,
  ENotificationType,
  ENotificatorTheme,
  ModalFooter,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { Divider, TextField as MTextField, Tooltip } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { Autocomplete, TextField } from 'formik-mui';
import { has } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import moment from 'moment';

import { H1, HeaderWrapper, TopSection } from '../../../../../../../style';
import {
  EInvitationEditForm,
  invitationEditFormSchema,
} from '../../../../../../../../shared/schema/invitationEditFormSchema';
import { useStore } from '../../../../../../../../shared/utils';
import { OrganizationInvitesStore } from '../../../mobx';
import {
  EUserPhoneStatus,
  IInvitation,
} from '../../../../../../../../../api/models/invitation.model';
import { STATUS_SCHEME } from '../../../mobx/stores/OrganizationInviteStore/OrganizationInvite.store';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { OrganizationInvitesController } from '../../../mobx/controllers';

import Styled from './EditInvitationModal.styles';

const EditInvitationModal = () => {
  const store = useStore(OrganizationInvitesStore);
  const controller = useStore(OrganizationInvitesController);

  const { closeModal, getModalPayload } = useModal();
  const { setNotification } = useNotificator();

  const payload = getModalPayload() as { invitation: IInvitation; organizationId: string };
  const { invitation, organizationId } = payload;

  const roleOption = useMemo<ISelectOption>(
    () => ({ label: invitation.role.name, value: invitation.role.id }),
    []
  );

  const status: {
    text: string;
    color: 'info' | 'primary' | 'warning' | 'secondary' | 'default';
  } = has(STATUS_SCHEME, invitation.status)
    ? STATUS_SCHEME[invitation.status]
    : { text: invitation.status ?? 'Другое', color: 'default' };

  const handleSubmit = (data: { role?: ISelectOption; comment?: string }, { setSubmitting }) => {
    if (data.comment === invitation.comment && data.role.value === invitation.role.id) {
      setNotification({
        message: 'Данные не изменились',
        style: {
          placement: 'bottom-left',
          type: ENotificationType.Warning,
          theme: ENotificatorTheme.Dark,
        },
      });

      setSubmitting(false);

      return;
    }

    controller
      .editInvite(invitation.invitationId, data?.role.value, data?.comment)
      .then(() => {
        setNotification({
          message: 'Приглашение обновлено',
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });

        controller.fetchInviteList(organizationId);
        closeModal();
      })
      .catch(error => {
        const errorTitle = error?.response?.data?.error?.title || error?.response?.data?.title;

        setNotification({
          message: `Ошибка обновления приглашения${errorTitle ? `: ${errorTitle}` : ''}`,
          style: {
            placement: 'bottom-left',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <HeaderWrapper>
        <TopSection>
          <H1>Добавление сотрудника</H1>
        </TopSection>
      </HeaderWrapper>
      <Divider sx={{ margin: '16px 0 12px' }} />
      <Formik
        initialValues={{
          [EInvitationEditForm.role]: roleOption,
          [EInvitationEditForm.comment]: invitation.comment,
        }}
        onSubmit={handleSubmit}
        validationSchema={invitationEditFormSchema}
      >
        {({ values, touched, errors, submitForm, isSubmitting }) => {
          return (
            <Form>
              <Styled.Form>
                <Styled.DescriptionList>
                  <Styled.DescriptionPair>
                    <Styled.DescriptionKey>Статус:</Styled.DescriptionKey>
                    <Styled.DescriptionValue>
                      <Chip size="medium" color={status.color}>
                        {status.text}
                      </Chip>
                    </Styled.DescriptionValue>
                  </Styled.DescriptionPair>

                  <Styled.DescriptionPair>
                    <Styled.DescriptionKey>ФИО:</Styled.DescriptionKey>
                    <Styled.DescriptionValue>
                      {invitation.userName ?? 'Не указано'}
                    </Styled.DescriptionValue>
                  </Styled.DescriptionPair>

                  <Styled.DescriptionPair>
                    <Styled.DescriptionKey>Телефон:</Styled.DescriptionKey>
                    <Styled.DescriptionValue>
                      {invitation?.phone ? (
                        <Styled.PhoneWrapper>
                          {invitation?.phone?.phoneNumberStatus.statusId ===
                          EUserPhoneStatus.CONFIRMED ? (
                            <Tooltip title="Номер подтверждён">
                              <CheckIcon color="primary" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Номер не подтверждён">
                              <QuestionMarkIcon color="warning" />
                            </Tooltip>
                          )}
                          <Styled.Phone
                            href={`tel:${invitation?.phone.phoneNumberCode} ${invitation?.phone?.phoneNumber}`}
                          >
                            {`${invitation?.phone.phoneNumberCode} ${invitation?.phone?.phoneNumber}`}
                          </Styled.Phone>
                        </Styled.PhoneWrapper>
                      ) : (
                        'Не указано'
                      )}
                    </Styled.DescriptionValue>
                  </Styled.DescriptionPair>

                  <Styled.DescriptionPair>
                    <Styled.DescriptionKey>E-mail:</Styled.DescriptionKey>
                    <Styled.DescriptionValue>
                      {invitation.userEmail ?? 'Не указано'}
                    </Styled.DescriptionValue>
                  </Styled.DescriptionPair>

                  <Styled.DescriptionPair>
                    <Styled.DescriptionKey>Дата оправки:</Styled.DescriptionKey>
                    <Styled.DescriptionValue>
                      {invitation.creationDate
                        ? moment(invitation.creationDate).format('DD.MM.YYYY')
                        : 'Не указано'}
                    </Styled.DescriptionValue>
                  </Styled.DescriptionPair>
                </Styled.DescriptionList>
                <Field
                  component={Autocomplete}
                  fullWidth
                  name={EInvitationEditForm.role}
                  value={values[EInvitationEditForm.role]}
                  placeholder="Укажите роль"
                  label="Роль *"
                  error={touched[EInvitationEditForm.role] && errors[EInvitationEditForm.role]}
                  options={store.roleValueList ?? []}
                  disablePortal
                  renderInput={params => (
                    <MTextField
                      {...params}
                      fullWidth
                      name={EInvitationEditForm.role}
                      placeholder="Выберите роль"
                      label="Роль *"
                      error={
                        touched[EInvitationEditForm.role] &&
                        Boolean(errors[EInvitationEditForm.role])
                      }
                      variant="outlined"
                    />
                  )}
                />
                <Field
                  component={TextField}
                  fullWidth
                  name={'comment'}
                  value={values[EInvitationEditForm.comment]}
                  placeholder="Укажите комментарий"
                  label="Сообщение"
                  error={
                    touched[EInvitationEditForm.comment] && errors[EInvitationEditForm.comment]
                  }
                  multiline
                  sx={{ height: 125 }}
                  maxRows={3}
                />
                <ModalFooter
                  denyButton={{ title: 'Отменить', handler: closeModal }}
                  successButton={{
                    title: 'Сохранить',
                    handler: () => submitForm(),
                    disabled: isSubmitting,
                  }}
                />
              </Styled.Form>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(EditInvitationModal);
