import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
};

type TypeResponse = string;

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const importDictionary: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-dictionary/admin/dictionary/${request.remoteName}/importData`,
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
