import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import {
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import useIntegraCropwiseNavigation from '../../../../hooks/useIntegraCropwiseNavigation';
import { IOrgIntegraCropwiseMeasurement } from '../../models/orgIntegraCropwiseMeasurements';

const MeasurementsTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), []);
  const { goToAttributeTab } = useIntegraCropwiseNavigation({ organizationId });

  const filterConfigRef = useRef<Map<string, TOrganizationIntegraTableFilterItemUnion>>(null);

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });
  const [isSideLoadUpdate] = useState(false);

  const onActionButtonClick = (value: IOrgIntegraCropwiseMeasurement) => {
    goToAttributeTab({ checkListIdIn: value.pointId });
  };

  const fetchData: TFetchHandler<IOrgIntegraCropwiseMeasurement> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);
      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    return service
      .fetchMeasurements({ ...modifiedArgs, organizationIdIn: [organizationId], page, size })
      .then(data => {
        setLastPageable({ page, size });

        return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
      });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwiseMeasurement>
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterConfigRef,
            filterCollection: [
              {
                type: 'text',
                label: 'ID Адаптера',
                propertyName: 'idIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID точки',
                propertyName: 'pointIdIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID пользователя',
                propertyName: 'employeeId',
                isMultiple: true,
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createDateTimeFrom',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createDateTimeTo',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateDateTimeFrom',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateDateTimeTo',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'pointId',
              headerName: 'ID точки адаптер',
              flex: 1,
              valueGetter: row => row.row.pointId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwisePointMeasurementId',
              headerName: 'ID измерения “Cropwise”',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.cropwisePointMeasurementId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID измерения Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'author',
              headerName: 'Автор',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.employee.userName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },

            {
              field: 'attribute',
              headerName: 'Атрибут шаблона',
              maxWidth: 80,
              valueGetter: row => row.row.id,
              renderCell: row => (
                <Tooltip title="Перейти к атрибутам">
                  <IconButton onClick={() => onActionButtonClick(row.row)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default MeasurementsTable;
