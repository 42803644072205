import { EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const GT: IFormulaFunction = {
  name: 'GT',
  label: 'Больше чем',
  argsType: [
    EFormulaType.Attribute,
    EFormulaType.Const,
    EFormulaType.Function,
    EFormulaType.Property,
  ],
  minArguments: 2,
  maxArguments: 2,
  availableSiblings: {
    [EFormulaReturnType.Double]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
    [EFormulaReturnType.Integer]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
    [EFormulaReturnType.Date]: [EFormulaReturnType.Date],
  },
  primitiveType: [EFormulaReturnType.Double, EFormulaReturnType.Integer, EFormulaReturnType.Date],
  returnType: [EFormulaReturnType.Boolean],
};
