import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ChecklistModalForm } from './components';

const ORG_INTEGRA_CHECKLIST_MODAL_ID = 'org-integra-checklist-modal-id';

const checklistModalConfig: TModalConfig = {
  id: ORG_INTEGRA_CHECKLIST_MODAL_ID,
  name: ORG_INTEGRA_CHECKLIST_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: ChecklistModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { ORG_INTEGRA_CHECKLIST_MODAL_ID, checklistModalConfig };
