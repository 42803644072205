import { Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../../../../routes';
import { DictionaryItemDetails, DictionaryList } from '../components';

const DictionaryMainSectionRouter = () => {
  return (
    <Switch>
      <Route exact path={AdminRoutes.Dictionary} component={DictionaryList} />
      <Route exact path={AdminRoutes.DictionarySearch} component={DictionaryList} />
      <Route exact path={AdminRoutes.DictionaryName} component={DictionaryItemDetails} />
      <Route
        exact
        path={[
          AdminRoutes.DictionarySelectedItem,
          AdminRoutes.DictionarySelectedItemCopy,
          AdminRoutes.DictionarySelectedItemEdit,
          AdminRoutes.DictionarySelectedItemHistory,
        ]}
        component={DictionaryItemDetails}
      />
    </Switch>
  );
};

export default DictionaryMainSectionRouter;
