import { ICropwiseConfigForm } from '../models';

export const DEFAULT_FORM_CROPWISE_CONFIG: ICropwiseConfigForm = {
  username: '',
  enabled: false,
  filesLocation: '',
  organizationId: '',
  seasons: [],
  fromDate: null,
  toDate: null,
  reportsLocation: '',
  id: '',
  organizationName: '',
  passwordFilled: false,
  state: 'create',
};
