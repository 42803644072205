import { TypeApiRoute } from '../../models/type.api.request';
import {
  EUserAdminStatus,
  EUserAdminRole,
  EUserAdminPhoneStatus,
  EUserAdminEmailStatus,
} from '../../models/user.admin.model';

type TypeRequest = {
  firstName?: string;
  secondName?: string;
  lastName?: string;
  countryCode: string;
  phoneNum: string;
  phoneStatus: EUserAdminPhoneStatus;
  email?: string;
  emailStatus?: EUserAdminEmailStatus;
  password?: string;
  role?: EUserAdminRole;
  status: EUserAdminStatus;
  gender?: string;
  dateOfBirth?: string;
  avatar?: string;
  includedInStats: boolean;
  domain: string;
};

type TypeResponse = {
  userId: string;
};

type TypeError = {};

export const createUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/users/init`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
