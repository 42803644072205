import { Autocomplete, TextField } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';

import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { EFormulaDependencyType, IFormulaError } from '../../../../../../models';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { FormulasStore } from '../../../../../../mobx';
import { HighlightBox } from '../../../../../../../../../../../shared/components';
import { EErrorCollection, errorCollection } from '../../../../../../configs';

interface IProps {
  dependencyType: EFormulaDependencyType;
  onSelectDependencyType: (EFormulaType) => void;
  availableOptionList: ISelectOption[];
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const FormulaDependencyType: FC<IProps> = ({
  dependencyType,
  onSelectDependencyType,
  availableOptionList,
  handleSetError,
  handleRemoveError,
}) => {
  const { useAttributeDepType } = useStore(FormulasStore);

  const [error, setError] = useState<{ text: string; id: string }>(null);
  const [filteredOptionList, setFilteredOptionList] = useState([]);
  const [uuid] = useState(v4());

  const ref = useRef();

  useEffect(() => {
    const _filteredOptionList = availableOptionList.filter(
      item =>
        (item.value as EFormulaDependencyType) === EFormulaDependencyType.Attribute ||
        !useAttributeDepType.has(item.value as EFormulaDependencyType)
    );

    setFilteredOptionList(_filteredOptionList);

    if (
      dependencyType !== EFormulaDependencyType.Attribute &&
      useAttributeDepType.get(dependencyType) > 1
    ) {
      handleSetError?.(
        {
          errorType: EErrorCollection.DEP_TYPE_DUPLICATION,
          text: errorCollection.DEP_TYPE_DUPLICATION,
          HTMLElementId: `${uuid}-attrDepType`,
        },
        uuid
      );
    } else {
      handleRemoveError?.(uuid);
    }
  }, [dependencyType, useAttributeDepType, availableOptionList]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(uuid);
    };
  }, [error]);

  return (
    <HighlightBox width={300} marginTop={2} id={`${uuid}-attrDepType`}>
      <Autocomplete
        disableClearable
        value={dependencyType ?? null}
        isOptionEqualToValue={option => option.value === dependencyType}
        options={filteredOptionList || []}
        onChange={(e, value) => {
          onSelectDependencyType((value as ISelectOption).value);
        }}
        data-test-id={`formula-block-dependency-type`}
        renderInput={params => (
          <>
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error?.text}
              label={'Тип зависимости'}
              data-test-id={`formula-block-dependency-type-input`}
            />
          </>
        )}
      />
    </HighlightBox>
  );
};

export default observer(FormulaDependencyType);
