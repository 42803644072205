import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  ids: string[];
  prettyPrint?: boolean;
};

type TypeResponse = {};

type TypeError = {};

export const exportChecklistByIds: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/transfer/checklists/export`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
