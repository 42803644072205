import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeTag } from '../../../api/models/tags.model';
import { initialStatus, TypeStatus, EStatus } from '../../../api/models/status.model';
import { TagsStore } from '../tags/tags.store';

type TypeFilters = {
  tags: TypeTag[];
  status: TypeStatus[];
  context: string;
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(TagsStore)
  protected tagsStore: TagsStore;

  constructor() {
    makeAutoObservable(this);
  }

  statusOptions = initialStatus;

  filters: TypeFilters = {
    tags: [],
    status: this.statusOptions.filter(({ statusId }) => statusId !== EStatus.ARCHIVED),
    context: '',
  };

  setFilterTags = (tags: TypeTag[]) => {
    runInAction(() => {
      this.filters.tags = tags;
    });
  };

  setFilterStatus = (status: TypeStatus[]) => {
    runInAction(() => {
      this.filters.status = status;
    });
  };

  setFilterContext = (value: string) => {
    runInAction(() => {
      this.filters.context = value;
    });
  };
}
