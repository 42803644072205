import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { observer } from 'mobx-react';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { TabContext, TabPanel } from '@mui/lab';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { useStore } from '../../../../../../../shared/utils';
import {
  AGROPOGODA_REPORT_MODAL_ID,
  REMOVE_DEVICE_MODAL_ID,
  agropogodaReportModalConfig,
  removeDeviceOwnerModalConfig,
} from '../../modals';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import ZondService from '../../../../../../../services/devices/zond.service';
import { IZondModel } from '../../../../../../../../api/models/zond.model';
import { OrganizationsStore } from '../../../../../../../stores/organizations/organizations.store';

import {
  AddWeatherStationForm,
  AutoReportPath,
  ReportAgropogodaHistory,
  WeatherStationList,
} from './components';

const OrganizationReportsAgropogoda = () => {
  const devicesService = useStore(ZondService);
  const orgStore = useStore(OrganizationsStore);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [deviceList, setDeviceList] = useState<IZondModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState<'history' | 'config'>('config');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    setTab(newValue);
  };

  const history = useHistory();
  const { registerModalList, openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  const fetchDeviceList = useCallback(() => {
    setIsLoading(true);

    devicesService
      .fetchDeviceList({ ownerId: organizationId })
      ?.then(data => setDeviceList(data?.content ?? []))
      ?.finally(() => {
        setIsLoading(false);
      });
  }, [organizationId]);

  useEffect(() => {
    fetchDeviceList();

    registerModalList([removeDeviceOwnerModalConfig, agropogodaReportModalConfig], 'reports');
  }, []);

  const addDevice = useCallback(
    (device: IZondModel) => {
      devicesService
        .setDeviceOwner({ id: device.id, ownerId: organizationId })
        .then(() => {
          setNotification(
            getNotificatorProps('Зонд успешно привязан', ENotificationStyles.Success)
          );
          fetchDeviceList();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка привязки зонда', ENotificationStyles.Success)
          );
        });
    },
    [organizationId]
  );

  const removeDevice = useCallback((device: IZondModel) => {
    openModalByModalId(REMOVE_DEVICE_MODAL_ID, null, () => {
      devicesService
        .removeDeviceOwner({ id: device.id })
        .then(() => {
          setNotification(getNotificatorProps('Зонд успешно отвязан', ENotificationStyles.Success));
          fetchDeviceList();
        })
        .catch(() => {
          setNotification(getNotificatorProps('Ошибка отвязки зонда', ENotificationStyles.Success));
        });
    });
  }, []);

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex">
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <H1>Отчеты BI. Агропогода</H1>
            </Box>
            <Button
              variant="contained"
              onClick={() => openModalByModalId(AGROPOGODA_REPORT_MODAL_ID, { organizationId })}
            >
              Сформировать отчёт
            </Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          {isLoading ? (
            <CircularProgress size="30" />
          ) : (
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChangeTab}>
                  <Tab label="Настройки" value={'config'} />
                  <Tab label="История" value={'history'} />
                </Tabs>
              </Box>
              <TabPanel value="config">
                <AutoReportPath />
                <AddWeatherStationForm addDevice={addDevice} />
                <WeatherStationList
                  deviceList={deviceList}
                  removeDeviceHandler={removeDevice}
                  orgName={orgStore.organization.name}
                />
              </TabPanel>
              <TabPanel value="history">
                <ReportAgropogodaHistory />
              </TabPanel>
            </TabContext>
          )}
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationReportsAgropogoda);
