import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import ChecklistImportModalContainer from '../../ChecklistImportModalContainer/ChecklistImportModalContainer';

export const getChecklistImportModalConfig = (): TModalConfig => ({
  name: 'checklistModalImport',
  title: 'Импорт',
  type: EModalType.Custom,
  children: ChecklistImportModalContainer,
  styledProps: {
    $size: '600px',
    $modalPaddings: '30px 20px',
    $height: '820px',
    $fullHeight: true,
    $isFullContentWidth: true,
  },
  id: 'checklistModalImport',
});
