import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeCheckList } from '../../../api/models/check.list.model';

@provide.singleton()
export class CheckListsTypesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  checkListsTypes: TypeCheckList[] = [];

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setCheckListsTypes = (types: TypeCheckList[]) => {
    runInAction(() => {
      this.checkListsTypes = types;
    });
  };

  clearCheckListsTypes = () => {
    this.setCheckListsTypes([]);
  };
}
