import { styled } from '@mui/material/styles';

import { Colors } from '../../../../constants/colors';

export const FormWrapper = styled('div')`
  margin-top: -24px;
`;

export const LogoWrapper = styled('div')`
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1366px) {
    margin-bottom: 52px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 60px;
  }
`;

export const ModalWrapper = styled('div')`
  max-width: 412px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 44px;
  background: ${Colors.white};

  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  margin-top: 224px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled('h1')`
  font-weight: bold;
  font-size: 36px;
  margin: 0;
`;

export const LineWrapper = styled('div')`
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const PhoneWrapper = styled('div')`
  margin-bottom: 44px;
`;

export const PasswordWrapper = styled('div')`
  margin-bottom: 24px;
`;

export const ActionsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled('div')`
  margin-top: 12px;
  text-align: center;

  & > button {
    display: inline-block;
    width: 100%;
  }
`;

export const BottomLineSeparator = styled('div')`
  margin-top: 28px;
  margin-bottom: 28px;
`;
