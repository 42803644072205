import { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useParams } from 'react-router';

import { AdminRoutes } from '../../routes';
import { useStore } from '../../../shared/utils';
import { OrganizationsController } from '../../../controllers/organizations.controller';

import {
  Organization,
  OrganizationEmployees,
  OrganizationInvites,
  OrganizationNews,
  OrganizationFields,
} from './pages';
import { OrganizationRolesRouter } from './pages/OrganizationRoles';
import { OrganizationReportsRouter } from './pages/OrganizationReports';
import { OrganizationIntegrationsRouter } from './pages/OrganizationIntegrations/router';

export const OrganizationRouter: FC<any> = () => {
  const { fetchOrganization, fetchOrganizationsTypes } = useStore(OrganizationsController);
  const { organizationId } = useParams<{ organizationId?: string }>();

  useEffect(() => {
    fetchOrganizationsTypes();
    fetchOrganization({ organizationId });
  }, []);

  return (
    <Switch>
      <Route exact path={AdminRoutes.OrganizationRequisites} component={Organization} />
      <Route exact path={AdminRoutes.OrganizationEmployees} component={OrganizationEmployees} />
      <Route exact path={AdminRoutes.OrganizationInvites} component={OrganizationInvites} />
      <Route exact path={AdminRoutes.OrganizationNews} component={OrganizationNews} />
      <Route exact path={AdminRoutes.OrganizationFields} component={OrganizationFields} />
      <Route path={AdminRoutes.OrganizationRoleList} component={OrganizationRolesRouter} />
      <Route path={AdminRoutes.OrganizationReports} component={OrganizationReportsRouter} />

      <Route
        path={AdminRoutes.OrganizationIntegrations}
        component={OrganizationIntegrationsRouter}
      />

      <Redirect to={AdminRoutes.OrganizationRequisites} />
    </Switch>
  );
};
