import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AdminRoutes } from '../../routes';
import { Layout } from '../../components';

import { NewsList, NewsCreate, NewsEdit, NewsItem } from './pages';

export const NewsRouter: FC<any> = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path={AdminRoutes.NewsCreate} component={NewsCreate} />
        <Route exact path={AdminRoutes.NewsEdit} component={NewsEdit} />
        <Route exact path={AdminRoutes.NewsItem} component={NewsItem} />
        <Route exact path={AdminRoutes.News} component={NewsList} />
        <Redirect to={AdminRoutes.News} />
      </Switch>
    </Layout>
  );
};
