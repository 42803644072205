import { makeAutoObservable } from 'mobx';

import { TypeFilters } from '../../../api/models/quotes.model';
import { Axios, lazyInject, provide } from '../../shared/utils';

const clearedFilters: TypeFilters = {
  cultureId: undefined,
  cultureClassId: undefined,
  basisId: undefined,
  currencyId: undefined,
  economicRegionId: undefined,
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  filters: TypeFilters = { ...clearedFilters };
  clearFilters = () => {
    this.filters = { ...clearedFilters };
  };
}
