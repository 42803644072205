import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  dependencyId: string;
  dependencyRecordId?: string;
  targetPath: string;
  dependencyPath: string;
};

type TypeResponse = string;

export const createDependencyLink: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/admin/dependencyLinks/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
