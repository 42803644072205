import { FC } from 'react';

import { ChipWrapper } from './style';

type Props = {
  title: string;
  type?: 'error' | 'success' | 'default' | 'transparent';
  className?: string;
  dataTestId?: string;
};

export const Chips: FC<Props> = ({ title, type = 'default', className, dataTestId = '' }) => (
  <ChipWrapper className={className} type={type} data-test-id={dataTestId}>
    {title}
  </ChipWrapper>
);
