import { Switch, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { TAction } from '../../../../../../api/models/action.model';

import Styled from './styled';

type TShortAction = {
  name: string;
  code: string;
  id: string;
};

export interface IActionOptions {
  entityId: string;
  changeHandler: {
    forScout: {
      update: (
        entityId: string,
        action: TAction | TShortAction,
        state: boolean,
        isScout?: boolean
      ) => Promise<any>;
    };
    default: {
      update: (
        entityId: string,
        action: TAction | TShortAction,
        state: boolean,
        isScout?: boolean
      ) => Promise<any>;
    };
  };
}

export const ActionItem: FC<{
  action: TAction | TShortAction;
  getActionValue: (actionCode) => boolean;
  onChangeModalMessage?: React.Dispatch<
    React.SetStateAction<{
      message: string;
      code: string;
    }>
  >;
  options: IActionOptions;
  isBold?: boolean;
  message?: string;
  isScout?: boolean;
}> = ({ action, isBold, getActionValue, onChangeModalMessage, message, isScout, options }) => {
  const [isChecked, setIsChecked] = useState(getActionValue?.(action.code));
  const [isDisabled, setIsDisabled] = useState(false);

  const changeHandler = useMemo(
    () => ({
      updateScout: options?.changeHandler?.forScout?.update,
      updateDefault: options?.changeHandler?.forScout?.update,
    }),
    [options]
  );

  const handleToggle = (e, checked) => {
    if (onChangeModalMessage && checked === false) {
      return onChangeModalMessage?.({ message, code: action.id });
    }

    setIsChecked(checked);
    setIsDisabled(true);

    if (isScout) {
      changeHandler
        .updateScout(options.entityId, action, checked, isScout)
        .catch(error => {
          console.error('Ошибка установки экшена', error);
          setIsChecked(!checked);
        })
        .finally(() => {
          setIsDisabled(false);
        });
    } else {
      changeHandler
        .updateDefault(options.entityId, action, checked)
        .catch(error => {
          console.error('Ошибка установки экшена', error);
          setIsChecked(!checked);
        })
        .finally(() => {
          setIsDisabled(false);
        });
    }
  };

  return (
    <Styled.ActionItem key={action.code} $disabled={isDisabled}>
      <Typography fontWeight={isBold ? 700 : 400}>{action.name}</Typography>
      <Switch onChange={handleToggle} checked={isChecked} disabled={isDisabled} />
    </Styled.ActionItem>
  );
};
