import { EChecklistAttributeType } from '../../../../../../../api/models/checklist.attribute.model';
import { EFormulaReturnType } from '../models';

const getAvailableAttrTypeByReturnType = (returnType: EFormulaReturnType) => {
  switch (returnType) {
    case EFormulaReturnType.Boolean:
      return new Set([EChecklistAttributeType.Boolean]);

    case EFormulaReturnType.Date:
      return new Set([EChecklistAttributeType.Date]);
    case EFormulaReturnType.Integer:
      return new Set([
        EChecklistAttributeType.Int,
        EChecklistAttributeType.Double,
        EChecklistAttributeType.Enum,
      ]);
    case EFormulaReturnType.Double:
      return new Set([
        EChecklistAttributeType.Int,
        EChecklistAttributeType.Double,
        EChecklistAttributeType.Enum,
      ]);
    case EFormulaReturnType.String:
      return new Set([EChecklistAttributeType.String, EChecklistAttributeType.Enum]);

    case EFormulaReturnType.Uuid:
      return new Set([EChecklistAttributeType.Enum]);

    default:
      return null;
  }
};

export default getAvailableAttrTypeByReturnType;
