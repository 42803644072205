import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import {
  TypeAlertStatus,
  initialAlertStatus,
  EAlertStatus,
} from '../../../api/models/alerts.model';

type TypeFilters = {
  status: TypeAlertStatus[];
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  statusOptions = initialAlertStatus;

  filters: TypeFilters = {
    status: [],
  };

  setFilterStatus = (status: TypeAlertStatus[]) => {
    runInAction(() => {
      this.filters.status = status;
    });
  };

  clearFilter = () => {
    runInAction(() => {
      this.filters.status = []
    });
  };
}
