import { FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Button, Grid, LinearProgress, TextField as MuiTextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { DatePicker } from 'formik-mui-lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';

import { useStore, isFormValuesErrors, getLink } from '../../../../../shared/utils';
import { UsersController } from '../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../stores/users/users.store';
import { userFormCreateSchema } from '../../../../../shared/schema/userFormCreateSchema';
import {
  EFormFields,
  IFormCreateValues,
  TypeUserAdminGender,
  TypeUserAdminRole,
  EUserAdminStatus,
  EUserAdminPhoneStatus,
  EUserAdminEmailStatus,
} from '../../../../../../api/models/user.admin.model';
import { AdminRoutes, ERegexp } from '../../../../routes';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Uploader } from '../../../../../shared/components/Uploader';
import { TextMaskField } from '../../../../../shared/components/TextMaskField';
import { Layout } from '../../../../components';

export const UserCreate: FC = observer(() => {
  const history = useHistory();

  const { createUser, uploadFile } = useStore(UsersController);
  const { gendersOptions, rolesOptions, formValuesErrors } = useStore(UsersStore);

  const handleSubmitForm = (
    values: IFormCreateValues,
    action: FormikHelpers<IFormCreateValues>
  ): void => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      secondName: values.secondName,
      countryCode: '+7',
      phoneNum: values.phoneNum,
      phoneStatus: values.phoneNum ? EUserAdminPhoneStatus.CONFIRMED : null,
      email: values.email,
      emailStatus: values.email ? EUserAdminEmailStatus.CONFIRMED : null,
      password: values.password,
      gender: values.gender ? values.gender.genderId : null,
      role: values.role ? values.role.roleId : null,
      status: EUserAdminStatus.ACTIVE,
      dateOfBirth: values.dateOfBirth ? values.dateOfBirth.format('DD.MM.yyyy') : null,
      avatar: values.avatar,
      includedInStats: values.includedInStats,
      domain: values.domain,
    };

    createUser(payload)
      .then(response => {
        action.setSubmitting(false);

        if (!isEmpty(response)) {
          const link = getLink(AdminRoutes.UserEdit, {
            [response.userId]: ERegexp.USERID,
          });

          setTimeout(() => {
            history.push(link);
          }, 1000);
        }
      })
      .finally(() => {
        action.setSubmitting(false);
      });
  };

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Создание нового пользователя</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <Formik
            validationSchema={userFormCreateSchema}
            initialValues={{
              lastName: '',
              firstName: '',
              secondName: '',
              phoneNum: '',
              email: '',
              password: '',
              role: null,
              gender: null,
              dateOfBirth: moment(),
              avatar: '',
              includedInStats: false,
              domain: '',
            }}
            onSubmit={handleSubmitForm}
          >
            {({ values, touched, errors, setFieldValue, isSubmitting, submitForm }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Form>
                    <Grid container spacing={2}>
                      {isSubmitting && (
                        <Grid item xs={12}>
                          <LinearProgress />
                        </Grid>
                      )}
                      <Grid item xs={2}>
                        <Uploader
                          fileId={values.avatar}
                          onFileChosen={file =>
                            uploadFile(file).then(({ id }: { id: string }) =>
                              setFieldValue(EFormFields.avatar, id)
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={10} container spacing={2}>
                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.lastName}
                            value={values[EFormFields.lastName]}
                            placeholder="Введите фамилию пользователя"
                            label="Фамилия *"
                            error={
                              (touched[EFormFields.lastName] &&
                                Boolean(errors[EFormFields.lastName])) ||
                              isFormValuesErrors(EFormFields.lastName, formValuesErrors)
                            }
                            helperText={
                              (touched[EFormFields.lastName] && errors[EFormFields.lastName]) ||
                              (isFormValuesErrors(EFormFields.lastName, formValuesErrors) &&
                                formValuesErrors[EFormFields.lastName])
                            }
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.firstName}
                            value={values[EFormFields.firstName]}
                            placeholder="Введите имя пользователя"
                            label="Имя *"
                            error={
                              (touched[EFormFields.firstName] &&
                                Boolean(errors[EFormFields.firstName])) ||
                              isFormValuesErrors(EFormFields.firstName, formValuesErrors)
                            }
                            helperText={
                              (touched[EFormFields.firstName] && errors[EFormFields.firstName]) ||
                              (isFormValuesErrors(EFormFields.firstName, formValuesErrors) &&
                                formValuesErrors[EFormFields.firstName])
                            }
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.secondName}
                            value={values[EFormFields.secondName]}
                            placeholder="Введите отчество пользователя"
                            label="Отчество"
                            error={
                              touched[EFormFields.secondName] &&
                              Boolean(errors[EFormFields.secondName])
                            }
                            helperText={
                              touched[EFormFields.secondName] && errors[EFormFields.secondName]
                            }
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.phoneNum}
                            value={values[EFormFields.phoneNum]}
                            placeholder="Введите номер телефона"
                            label="Номер телефона"
                            error={
                              (touched[EFormFields.phoneNum] &&
                                Boolean(errors[EFormFields.phoneNum])) ||
                              isFormValuesErrors(EFormFields.phoneNum, formValuesErrors)
                            }
                            helperText={
                              (touched[EFormFields.phoneNum] && errors[EFormFields.phoneNum]) ||
                              (isFormValuesErrors(EFormFields.phoneNum, formValuesErrors) &&
                                formValuesErrors[EFormFields.phoneNum])
                            }
                            variant="outlined"
                            type="tel"
                            InputProps={{
                              inputComponent: TextMaskField as any,
                            }}
                            inputProps={{
                              mask: '+7 (000) 000-00-00',
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.email}
                            value={values[EFormFields.email]}
                            placeholder="Введите E-mail пользователя"
                            label="E-mail"
                            variant="outlined"
                            type="email"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            fullWidth
                            name={EFormFields.password}
                            value={values[EFormFields.password]}
                            placeholder="Введите пароль"
                            label="Пароль"
                            error={
                              (touched[EFormFields.password] &&
                                Boolean(errors[EFormFields.password])) ||
                              isFormValuesErrors(EFormFields.password, formValuesErrors)
                            }
                            helperText={
                              (touched[EFormFields.password] && errors[EFormFields.password]) ||
                              (isFormValuesErrors(EFormFields.password, formValuesErrors) &&
                                formValuesErrors[EFormFields.password])
                            }
                            variant="outlined"
                            type="password"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={DatePicker}
                            ampm
                            name={EFormFields.dateOfBirth}
                            value={values[EFormFields.dateOfBirth]}
                            onChange={newValue => setFieldValue(EFormFields.dateOfBirth, newValue)}
                            inputFormat="DD.MM.yyyy"
                            mask="__.__.____"
                            textField={{
                              fullWidth: true,
                              placeholder: 'Дата рождения',
                              label: 'Дата рождения',
                              variant: 'outlined',
                              error:
                                (touched[EFormFields.dateOfBirth] &&
                                  Boolean(errors[EFormFields.dateOfBirth])) ||
                                isFormValuesErrors(EFormFields.dateOfBirth, formValuesErrors),
                              helperText:
                                (touched[EFormFields.dateOfBirth] &&
                                  errors[EFormFields.dateOfBirth]) ||
                                (isFormValuesErrors(EFormFields.dateOfBirth, formValuesErrors) &&
                                  formValuesErrors[EFormFields.dateOfBirth]),
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            fullWidth
                            name={EFormFields.gender}
                            component={Autocomplete}
                            options={gendersOptions}
                            getOptionLabel={(option: TypeUserAdminGender) => option.genderName}
                            isOptionEqualToValue={(option, value) =>
                              option.genderName === value.genderName
                            }
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                fullWidth
                                name={EFormFields.gender}
                                placeholder="Ввыберите пол"
                                label="Пол"
                                error={
                                  (touched[EFormFields.gender] &&
                                    Boolean(errors[EFormFields.gender])) ||
                                  isFormValuesErrors(EFormFields.gender, formValuesErrors)
                                }
                                helperText={
                                  (touched[EFormFields.gender] && errors[EFormFields.gender]) ||
                                  (isFormValuesErrors(EFormFields.gender, formValuesErrors) &&
                                    formValuesErrors[EFormFields.gender])
                                }
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            fullWidth
                            name={EFormFields.role}
                            component={Autocomplete}
                            options={rolesOptions}
                            getOptionLabel={(option: TypeUserAdminRole) => option.roleName}
                            isOptionEqualToValue={(option, value) =>
                              option.roleName === value.roleName
                            }
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                fullWidth
                                name={EFormFields.role}
                                placeholder="Ввыберите роль"
                                label="Роль"
                                error={
                                  (touched[EFormFields.role] &&
                                    Boolean(errors[EFormFields.role])) ||
                                  isFormValuesErrors(EFormFields.role, formValuesErrors)
                                }
                                helperText={
                                  (touched[EFormFields.role] && errors[EFormFields.role]) ||
                                  (isFormValuesErrors(EFormFields.role, formValuesErrors) &&
                                    formValuesErrors[EFormFields.role])
                                }
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Создать
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </LocalizationProvider>
              );
            }}
          </Formik>
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
