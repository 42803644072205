import { lazyInject, provide } from '../shared/utils';
import { FilterStore } from '../stores/quotes/filter.store';
import { QuotesStore } from '../stores/quotes/quotes.store';

@provide.singleton()
export class QuotesController {
  @lazyInject(QuotesStore)
  protected quotesStore: QuotesStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  setCultureFilter = value => {
    this.filterStore.filters = { ...this.filterStore.filters, cultureId: value };
  };

  setCultureClassFilter = value => {
    this.filterStore.filters = { ...this.filterStore.filters, cultureClassId: value };
  };

  setBasisFilter = value => {
    this.filterStore.filters = { ...this.filterStore.filters, basisId: value };
  };

  setCurrencyFilter = value => {
    this.filterStore.filters = { ...this.filterStore.filters, currencyId: value };
  };

  setEconomicRegionFilter = value => {
    this.filterStore.filters = { ...this.filterStore.filters, economicRegionId: value };
  };
}
