import { IOrgIntegraCropwisePhoto } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwisePhotos/models/orgIntegraCropwisePhoto';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<{
  idIn: string[];
  scoutReportInstanceIdIn: string[];
  employeeId: string;
  organizationIdIn: string[];
  createDateTimeTo: string;
  createDateTimeFrom: string;
  updateDateTimeFrom: string;
  updateDateTimeTo: string;
}> & { page?: number; size?: number };

type TypeResponse = TypeResponsePageable<IOrgIntegraCropwisePhoto>;

type TypeError = {};

export const getOrgIntegraCropwisePhotoList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/mapping/photo/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
