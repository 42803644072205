import { Box } from '@mui/material';

import { ChecklistTable } from '../components';

const OrgIntegraCropwiseChecklists = () => {
  return (
    <Box>
      <ChecklistTable />
    </Box>
  );
};

export default OrgIntegraCropwiseChecklists;
