import { IDeviceModel } from '../../models/device.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  ownerId?: string;
  context?: string;
  withoutOwnerOnly?: boolean;

  size?: number;
  page?: number;
};

type TypeError = {};

type TypeResponse = TypeResponsePageable & { content: IDeviceModel[] };

export const getDeviceList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-weather/devices/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
