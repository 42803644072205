import { DataGrid, ruRU } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toJS } from 'mobx';

import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrganizationRoleController, OrganizationRoleStore } from '../../../../../../mobx';

import getColumns from './helpers/getColumns';

const RoleItemUserGrid = () => {
  const store = useStore(OrganizationRoleStore);
  const controller = useStore(OrganizationRoleController);

  const { organizationId, roleId } = useParams<{ organizationId: string; roleId: string }>();

  const columns = useMemo(() => getColumns(), []);
  const rows = useMemo(() => toJS(store.currentRoleUserList), [store.isUserListLoading]);

  useEffect(() => {
    controller.fetchRoleUserList(
      organizationId,
      roleId,
      store.userListGridParams.page,
      store.userListGridParams.pageSize
    );
  }, [store.userListGridParams.page, store.userListGridParams.pageSize, roleId, organizationId]);

  return (
    <Box height={'calc(100vh - 604px)'}>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={store.isUserListLoading}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginationMode="server"
        onPageChange={newPage => {
          store.updateUserListGridParams({ page: newPage });
        }}
        onPageSizeChange={newPageSize => {
          store.updateUserListGridParams({ pageSize: newPageSize });
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        pagination
        {...store.userListGridParams}
      />
    </Box>
  );
};

export default observer(RoleItemUserGrid);
