import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SearchIcon from '@mui/icons-material/Search';

import { ISelectOption } from '../../../../../../../../types/selectOption';
import { OrganizationsController } from '../../../../../../../controllers/organizations.controller';
import { useStore } from '../../../../../../../shared/utils';
import { UsersController } from '../../../../../../../controllers/users.controller';

interface IAutocompleteOptions {
  isClearOnSelect?: boolean;
}

interface IProps {
  selectedOrganization: ISelectOption;
  setSelectedOrganization: (value: ISelectOption) => void;
  userOrganizations?: {
    userId: string;
  };
  options?: IAutocompleteOptions;
}

export const SelectOrgByNameOrInn: FC<IProps> = ({
  selectedOrganization,
  setSelectedOrganization,
  userOrganizations,
  options,
}) => {
  const { fetchOrganizationListByParams } = useStore(OrganizationsController);
  const { fetchUserOrganizationsList } = useStore(UsersController);

  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback((e, value) => {
    setInputValue(value);
  }, []);

  const handleFetch = nameOrInn => {
    if (userOrganizations?.userId) {
      fetchUserOrganizationsList({ id: userOrganizations?.userId, nameOrInn })?.then(data =>
        setOptionList(
          data?.content.map(item => ({
            label: `${item?.INN ? `${item.INN} / ` : ''}${item?.name}`,
            value: item.organizationId,
          })) ?? []
        )
      );
    } else {
      fetchOrganizationListByParams({ nameOrInn }).then(data =>
        setOptionList(
          data.map(item => ({
            label: `${item?.INN ? `${item.INN} / ` : ''}${item?.name}`,
            value: item.organizationId,
          }))
        )
      );
    }
  };

  const handleFetchOrganization = useCallback(debounce(handleFetch, 500), []);

  const handleOnChange = (e, value: ISelectOption | string) => {
    if (typeof value === 'object') {
      setSelectedOrganization(value);
    }

    if (options?.isClearOnSelect) {
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    handleFetchOrganization(inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      options={optionList}
      fullWidth
      renderInput={props => (
        <TextField
          {...props}
          inputRef={inputRef}
          label="Организация"
          placeholder="Введите название или ИНН"
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <CorporateFareIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      disableClearable
      disablePortal
      inputValue={inputValue ?? ''}
      value={selectedOrganization?.label ?? null}
      onChange={handleOnChange}
      onInputChange={onInputChange}
      key="selectOrgByNameOrINN"
    />
  );
};
