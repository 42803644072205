import { IRoleModel } from '../../models/role.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
  name?: string;
  order?: number;
  isScoutForUser?: boolean;
  isScoutForOrganization?: boolean;
  isExperimentForUser?: boolean;
  isExperimentForOrganization?: boolean;
  actionCodes?: string[];
};

type TypeResponse = IRoleModel;

type TypeError = {};

export const updateAccessRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-profile/access/role/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
