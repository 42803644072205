import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
  id: string;
};

type TypeResponse = {};

type TypeError = {};

export const markDictionaryAsLeader: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-dictionary/admin/dictionary/${request.remoteName}/makeLeader/${request.id}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
