import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';

export const QuotesAddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 40px;
`;

export const AddNewQuoteButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.green};
  cursor: pointer;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & button {
    display: block;
    text-align: center;
    width: 240px;
    cursor: pointer;
  }
  & > button:first-child {
    //  margin-right: 20%;
  }
  margin-top: 40px;
`;
