import { useEffect, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';

import { IOrgIntegraEmployee } from '../../../../OrgIntegraAgrosignalEmployees/models';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { TypeApiRequest, useStore } from '../../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../../mobx';
import { OrgIntegraAgrosignalErrorMessageService } from '../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';
import {
  EOrgIntegraAgrosignalErrorMessageEntityType,
  EOrgIntegraAgrosignalErrorMessageOperationType,
} from '../../../models/orgIntegraAgrosignalErrorMessage';

const useMessagePushForm = (organizationId: string) => {
  const service = useStore(OrgIntegraAgrosignalService);
  const pushService = useStore(OrgIntegraAgrosignalErrorMessageService);

  const [employeeList, setEmployeeList] = useState<ISelectOptionExtended<IOrgIntegraEmployee>[]>(
    []
  );
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<
    ISelectOptionExtended<IOrgIntegraEmployee>[]
  >([]);
  const [maxDate, setMaxDate] = useState<string>(null);
  const [minDate, setMinDate] = useState<string>(null);
  const [entityTypeList, setEntityTypeList] = useState<ISelectOptionExtended[]>(null);
  const [operationTypeList, setOperationTypeList] = useState<ISelectOptionExtended[]>(null);

  const { setNotification } = useNotificator();

  useEffect(() => {
    if (!organizationId) return;

    service.fetchEmployeeList({ organizationId }).then(data => {
      setEmployeeList(
        data.content.map(item => ({
          label: item.userName || item.agroName || item.userId,
          value: item.userId,
          rawData: item,
        }))
      );
    });
  }, []);

  const pushMessage = () => {
    if (!minDate) {
      setNotification(getNotificatorProps('Не заполнена дата начала', ENotificationStyles.Error));

      return Promise.reject(new Error());
    }

    const payload: TypeApiRequest<'pushOrgIntegraAgrosignalMessage'> = {
      startTime: minDate,
    };

    if (maxDate) {
      payload.endTime = maxDate;
    }

    if (selectedEmployeeList?.length > 0) {
      payload.userIds = selectedEmployeeList?.flatMap(item => item.value as string);
    }

    if (entityTypeList?.length > 0) {
      payload.entityTypes = entityTypeList?.flatMap(
        item => item.value as EOrgIntegraAgrosignalErrorMessageEntityType
      );
    }

    if (operationTypeList?.length > 0) {
      payload.operationTypes = operationTypeList?.flatMap(
        item => item.value as EOrgIntegraAgrosignalErrorMessageOperationType
      );
    }

    return pushService
      .pushMessage(payload)
      .then(data => {
        setNotification(
          getNotificatorProps('Сообщения успешно отправлены', ENotificationStyles.Success)
        );

        return data;
      })
      .catch(e => {
        setNotification(
          getNotificatorProps('Ошибка отправки сообщений', ENotificationStyles.Error)
        );

        return e;
      });
  };

  return {
    employeeList,
    setSelectedEmployeeList,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    pushMessage,
    setEntityTypeList,
    setOperationTypeList,
  };
};

export { useMessagePushForm };
