import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { useStore } from '../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../shared/constanst/notifications';
import { FIELD_GROUP_IMPORT_MODAL_ID } from '../modals';
import { EOrgIntegraCropwiseFieldAutoMapMode } from '../models';

const useFieldService = ({ organizationId }: { organizationId: string }) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isMapping, setIsMapping] = useState(false);

  const { setNotification } = useNotificator();
  const { openModalByModalId, closeModal } = useModal();

  const service = useStore(OrgIntegraCropwiseService);

  const handleCropwiseImport = () => {
    if (isSyncing) return;

    setIsSyncing(true);

    service
      .syncCropwiseFieldsByOrgId(organizationId)
      .then(() => {
        setNotification(getNotificatorProps('Поля загружены', ENotificationStyles.Success));
      })
      .catch(() => {
        setNotification(getNotificatorProps('Поля загружены', ENotificationStyles.Error));
      })
      .finally(() => setIsSyncing(false));
  };

  const handleGroupAutomap = () => {
    if (isMapping) return;

    openModalByModalId(
      FIELD_GROUP_IMPORT_MODAL_ID,
      null,
      (value: EOrgIntegraCropwiseFieldAutoMapMode) => {
        setIsMapping(true);

        service
          .automapCropwiseFieldsByOrgId(organizationId, value)
          .then(() => {
            setNotification(
              getNotificatorProps(
                `${
                  value === EOrgIntegraCropwiseFieldAutoMapMode.Map ? 'Маппинг' : 'Сброс маппинга'
                } выполнен`,
                ENotificationStyles.Success
              )
            );
          })
          .catch(() => {
            setNotification(getNotificatorProps('Ошибка маппинга', ENotificationStyles.Error));
          })
          .finally(() => {
            setIsMapping(false);
            closeModal();
          });
      }
    );
  };

  return {
    isSyncing,
    isMapping,
    handleCropwiseImport,
    handleGroupAutomap,
  };
};

export default useFieldService;
