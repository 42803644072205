import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistAttributeEnumItem } from '../../../models/checklist.attribute.model';

type TypeRequest = Array<Partial<IChecklistAttributeEnumItem>>;

type InputData = {
  list: TypeRequest;
  id: string;
};

type TypeResponse = IChecklistAttributeEnumItem[];

type TypeError = {};

export const changeChecklistAttributeEnumList: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/${request.id}/enumList`,
  method: 'PUT',
  headers: {},
  request: request => request.list,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
