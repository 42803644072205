import styled, { css } from 'styled-components';

const SwitchLabel = styled.label<{ $disabled?: boolean }>`
  display: flex;
  flex: 1;
  min-width: 164px;
  max-width: 512px;
  align-items: center;

  ${({ $disabled }) => $disabled && 'opacity: 0.6;'}
`;

const Styled = {
  SwitchLabel,
};

export default Styled;
