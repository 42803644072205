import { IOrgIntegraCropwiseField } from '../../../../../models/integra/config.model';
import {
  IPageableRequest,
  TypeResponsePageable,
} from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IPageableRequest &
  Partial<{
    name: string;
    organizationId: string;
    isMapped: boolean;
  }>;

type TypeResponse = TypeResponsePageable<IOrgIntegraCropwiseField>;

type TypeError = {};

export const getOrgIntegraAssistanceFieldList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/assistance/field/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
