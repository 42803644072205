import { useHistory, generatePath } from 'react-router-dom';

import { DictionariesController } from '../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../shared/utils';
import { AdvancedFilterStore, EFormModule } from '../../../../../../../../stores';

import { AdminRoutes } from './../../../../../../../routes';
import {
  EDictionaryItemStatus,
  TDictionaryItem,
} from './../../../../../../../../../api/models/dictionaries.model';

export enum EActionType {
  AddChild,
  Edit,
  Copy,
  TagAsDouble,
  TagAsOriginal,
  TagAsOriginalInGroup,
  Export,
  IOArchive,
  Delete,
}

export type TContextItem = {
  action: EActionType;
  label: string;
  onClick: () => void;
  isDanger?: boolean;
};

export const useContextMenuOptions = (remoteName: string, id: string, value: TDictionaryItem) => {
  const {
    setParentId,
    changeFormModule,
    deleteDictionaryItem,
    archiveDictionaryItem,
    activateDictionaryData,
    markDictionaryAsLeader,
    markDictionaryAsOriginal,
    copyFromDictionary,
  } = useStore(DictionariesController);
  const { setFetchStatusResult } = useStore(AdvancedFilterStore);
  const history = useHistory();

  const options: TContextItem[] = [
    {
      action: EActionType.AddChild,
      label: 'Добавить дочернюю',
      onClick: () => {
        setParentId(value);
        changeFormModule(EFormModule.Create);
        history.push(generatePath(AdminRoutes.DictionaryCreate, { name: remoteName }));
      },
    },
    {
      action: EActionType.Edit,
      label: 'Редактировать',
      onClick: () => {
        history.push(
          generatePath(AdminRoutes.DictionarySelectedItemEdit, { itemId: id, name: remoteName })
        );
        setFetchStatusResult(false);
      },
    },
    {
      action: EActionType.Copy,
      label: 'Копировать',
      onClick: () => {
        copyFromDictionary(value);
        changeFormModule(EFormModule.Copy);
        history.push(
          generatePath(AdminRoutes.DictionarySelectedItemCopy, { itemId: id, name: remoteName })
        );
        setFetchStatusResult(false);
      },
    },
    {
      action: EActionType.TagAsDouble,
      label: 'Пометить как дубль',
      onClick: () => {
        console.log();
      },
    },
    {
      action: EActionType.TagAsOriginal,
      label: 'Пометить как оригинал',
      onClick: () => {
        markDictionaryAsOriginal(value);
      },
    },
    {
      action: EActionType.TagAsOriginalInGroup,
      label: 'Пометить как оригиналом в группе',
      onClick: () => {
        markDictionaryAsLeader(value);
      },
    },
    // {
    //   action: EActionType.Export,
    //   label: 'Экспорт',
    //   onClick: () => {
    //     console.log();
    //   },
    // },
    {
      action: EActionType.IOArchive,
      label: 'В архив / Вернуть из архива',
      onClick: () => {
        if (value.status === EDictionaryItemStatus.Active) {
          archiveDictionaryItem(value);
        } else {
          activateDictionaryData(value);
        }
      },
    },
    {
      action: EActionType.Delete,
      label: 'Удалить',
      onClick: () => {
        deleteDictionaryItem(value);
      },
    },
  ];

  return { options };
};
