import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CreateInvitationModal } from './components';

const CREATE_INVITATION_MODAL_ID = 'create-invitation-modal';

const createInvitationModalConfig: TModalConfig = {
  id: CREATE_INVITATION_MODAL_ID,
  name: CREATE_INVITATION_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  styledProps: {
    $size: EModalSize.Large,
    $isFullContentWidth: true,
  },
  children: CreateInvitationModal,
};

export { CREATE_INVITATION_MODAL_ID, createInvitationModalConfig };
