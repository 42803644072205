import { IOrgIntegraCropwisePushMessage } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/models';
import { IOrgIntegraCropwiseErrorMessage } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseErrorMessage/models/orgIntegraCropwiseErrorMessage';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraCropwisePushMessage>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraCropwiseErrorMessage[] };

type TypeError = {};

export const pushOrgIntegraCropwiseMessage: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/integration/postProcess/pushMessage`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
