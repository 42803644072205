import { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import {
  Button,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../stores/organizations/organizations.store';
import { OrganizationsTypesStore } from '../../../../../stores/organizationsTypes/organizationsTypes.store';
import { getValues } from '../../utils';
import { EFormFields, TypeOrganizationType } from '../../../../../../api/models/organization.model';
import { Uploader } from '../../../../../shared/components/Uploader';
import { TextMaskField } from '../../../../../shared/components/TextMaskField';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { getOrganizationMenu } from '../../../../../shared/menu';
import { Layout } from '../../../../components';
import { OrganizationsController } from '../../../../../controllers/organizations.controller';
import { getNotificatorProps } from '../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../shared/constanst/notifications';

import { EmployeesDialog } from './components';
import Styled from './Organization.styles';

export const Organization: FC = observer(() => {
  const { organization, isLoaded, isLoading: isLoadingOrganization } = useStore(OrganizationsStore);
  const { organizationsTypes, isLoading: isLoadingOrganizationType } = useStore(
    OrganizationsTypesStore
  );
  const controller = useStore(OrganizationsController);

  const [showEmployeesModal, setShowEmployeesModal] = useState<boolean>(false);
  const [isScout, setIsScout] = useState(Boolean(organization.isScout));
  const [isExperiment, setIsExperiment] = useState(Boolean(organization.isExperiment));
  const [isSTOEnabled, setIsSTOEnabled] = useState(Boolean(organization.isSTOEnabled));

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { setNotification } = useNotificator();

  const values = useMemo(() => getValues(organization), [organization]);

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  useEffect(() => {
    setIsScout(Boolean(organization.isScout));
    setIsExperiment(Boolean(organization.isExperiment));
    setIsSTOEnabled(Boolean(organization?.isSTOEnabled));
  }, [organization.isExperiment, organization.isScout, organization.isSTOEnabled]);

  const handleShowEmployeesModal = useCallback(() => {
    setShowEmployeesModal(true);
  }, []);
  const handleCloseEmployeesModal = useCallback(() => {
    setShowEmployeesModal(false);
  }, []);

  const handleUpdateOrganizationToggler = useCallback(
    (param: 'isScout' | 'isExperiment' | 'isSTOEnabled', state: boolean) => {
      controller
        .updateOrganizationById(organizationId, { [param]: state })
        .then(() => {
          if (param === 'isScout') {
            setIsScout(state);
          }

          if (param === 'isExperiment') {
            setIsExperiment(state);
          }

          if (param === 'isSTOEnabled') {
            setIsSTOEnabled(state);
          }
        })
        .catch(error => {
          setNotification(
            getNotificatorProps('Ошибка обновления организации', ENotificationStyles.Error)
          );
        });
    },
    [organizationId]
  );

  if (isLoadingOrganization) {
    return <p>Загрузка</p>;
  }

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        {isLoaded && (
          <>
            <HeaderWrapper>
              <TopSection>
                <H1>Реквизиты организации</H1>
              </TopSection>
            </HeaderWrapper>

            <MainBody>
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Uploader fileId={values.logo} disabled />
                  </Grid>

                  <Grid item xs={10} container spacing={2}>
                    <Grid item xs={2}>
                      <Autocomplete
                        fullWidth
                        value={values[EFormFields.organizationType]}
                        options={organizationsTypes}
                        getOptionLabel={(option: TypeOrganizationType) => option.OrgType}
                        isOptionEqualToValue={(option, value) =>
                          option.orgTypeId === value.orgTypeId
                        }
                        loading={isLoadingOrganizationType}
                        loadingText="Загрузка..."
                        noOptionsText="Типов организации не найдено"
                        disabled
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            name={EFormFields.organizationType}
                            placeholder="Ввыберите тип организации"
                            label="Типы организаций"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingOrganizationType ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.name]}
                        placeholder="Введите название организации"
                        label="Название организации *"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.inn]}
                        placeholder="Введите ИНН"
                        label="ИНН"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.ogrn]}
                        placeholder="Введите ОГРН"
                        label="ОГРН"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.okved]}
                        placeholder="Введите ОКВЭД"
                        label="ОКВЭД"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.okpo]}
                        placeholder="Введите ОКПО"
                        label="ОКПО"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.kpp]}
                        placeholder="Введите КПП"
                        label="КПП"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.addressJur]}
                        placeholder="Введите юридический адрес"
                        label="Юридический адрес"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.addressFact]}
                        placeholder="Введите фактический адрес"
                        label="Фактический адрес"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6} alignSelf="flex-end">
                      <TextField
                        fullWidth
                        value={values[EFormFields.phone]}
                        placeholder="Введите номер телефона"
                        label="Номер телефона"
                        variant="outlined"
                        inputProps={{
                          mask: '+0 (000) 000-00-00',
                        }}
                        InputProps={{
                          inputComponent: TextMaskField as any,
                        }}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} alignSelf="flex-end">
                      <TextField
                        fullWidth
                        value={values[EFormFields.email]}
                        placeholder="Введите E-mail пользователя"
                        label="E-mail"
                        variant="outlined"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={values[EFormFields.contact]}
                        placeholder="Введите представителя"
                        label="Представитель"
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Button variant="outlined" color="primary" onClick={handleShowEmployeesModal}>
                        Изменить основателя
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <EmployeesDialog
                organizationID={organizationId}
                open={showEmployeesModal}
                onClose={handleCloseEmployeesModal}
                dataTestId="employees-dialog"
              />
            </MainBody>
          </>
        )}
      </MainContainer>
      <MainContainer>
        <FormGroup>
          <Styled.SwitchGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isScout}
                  value={isScout}
                  onChange={(e, value) => handleUpdateOrganizationToggler('isScout', value)}
                />
              }
              labelPlacement="start"
              label="Добавить организацию в скаутинг"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isExperiment}
                  value={isExperiment}
                  onChange={(e, value) => handleUpdateOrganizationToggler('isExperiment', value)}
                />
              }
              labelPlacement="start"
              label="Добавить организацию в опыты"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isSTOEnabled}
                  value={isSTOEnabled}
                  onChange={(e, value) => handleUpdateOrganizationToggler('isSTOEnabled', value)}
                />
              }
              labelPlacement="start"
              label="Включить СТО для организации"
            />
          </Styled.SwitchGroup>
        </FormGroup>
      </MainContainer>
    </Layout>
  );
});
