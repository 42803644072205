import {
  EImportOperationStatus,
  EImportOperationType,
  IImportOperationModel,
} from '../../../models/importOperation.model';
import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;

  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  organizationId: string;
  type: EImportOperationType;
  isAuto: boolean;
  statusIn: EImportOperationStatus[];
};
type TypeResponse = TypeResponsePageable & { content: IImportOperationModel[] };

export const getCropwiseReportImportOperationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-cropwise/importOperations/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
