import { FC } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useModal } from '@farmlink/farmik-ui';

import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Layout } from '../../../../components';
import { EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID } from '../../modals';

import { OrganizationsFilter, OrganizationsTable } from './components';

export const OrganizationsList: FC = observer(() => {
  const { openModalByModalId } = useModal();

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Организации</H1>
            <Button
              onClick={() => openModalByModalId(EXPORT_ORGANIZATIONS_FIELDS_MODAL_ID)}
              startIcon={<Download />}
              variant="contained"
            >
              Экспорт
            </Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <OrganizationsFilter />
          <OrganizationsTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
