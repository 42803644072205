import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { ETypesEntities, ICoreSynchronizationForm, ICoreIntegrationForm } from '../../../models';
import { useStore } from '../../../../../../../../shared/utils';
import { OrgIntegraController } from '../../../mobx/controllers';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';

import { UserSearch } from './UserSearch';

const SynchroniesModal: FC = () => {
  const { integraPushEvents } = useStore(OrgIntegraController);

  const { getModalPayload, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const [isLoading, setIsLoading] = useState(false);

  const config: Partial<ICoreIntegrationForm> = getModalPayload();

  const onSubmit = (formData: ICoreSynchronizationForm) => {
    setIsLoading(true);

    integraPushEvents(formData)
      .then(() => {
        setNotification(
          getNotificatorProps(
            'Запрос синхронизации данных успешно отправлен',
            ENotificationStyles.Success
          )
        );

        closeModal();
      })
      .catch((e: Error) => {
        setNotification(
          getNotificatorProps(
            e?.message ?? 'Ошибка создания конфигурации',
            ENotificationStyles.Error
          )
        );

        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik<ICoreSynchronizationForm>({
    onSubmit,
    initialValues: {
      startTime: config?.startTime,
      endTime: config?.endTime,
      organizationId: config?.organizationId
        ? [{ label: config.organizationName, value: config.organizationId }]
        : [],
      typesEntities: new Set(),
      userId: [],
    },
  });
  const { values } = formik;

  const orgNameList = useMemo(() => values.organizationId.flatMap(item => item.label).join(', '), [
    values.organizationId,
  ]);

  const toggleType = (type: ETypesEntities) => {
    const newSet = new Set(Array.from(values.typesEntities.values()));
    if (!values.typesEntities.has(type)) {
      newSet.add(type);
    } else {
      newSet.delete(type);
    }
    formik.setFieldValue('typesEntities', newSet);
  };

  return (
    <Box>
      <Typography textAlign={'center'} variant="h6" fontSize={16} fontWeight={600}>
        Синхронизация данных
      </Typography>

      <FormControl fullWidth>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <InputLabel id="action-type">Тип события</InputLabel>
          <Select
            labelId="action-type"
            value={values.typesEntities}
            label="Тип события"
            renderValue={selected => Array.from(selected.values())?.join(', ')}
            onChange={e => toggleType(e.target.value as ETypesEntities)}
          >
            <MenuItem value={ETypesEntities.CHECKLIST_INSTANCE}>
              <Checkbox checked={values.typesEntities.has(ETypesEntities.CHECKLIST_INSTANCE)} />
              Чек-лист точки
            </MenuItem>
            <MenuItem value={ETypesEntities.CHECKLIST}>
              <Checkbox checked={values.typesEntities.has(ETypesEntities.CHECKLIST)} />
              Шаблоны
            </MenuItem>
            <MenuItem value={ETypesEntities.FIELD}>
              <Checkbox checked={values.typesEntities.has(ETypesEntities.FIELD)} />
              Поля
            </MenuItem>
          </Select>

          <UserSearch formik={formik} config={config} />

          <TextField
            label="Поиск по названию или ИНН организации"
            disabled={true}
            value={orgNameList}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CorporateFareIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box display={'flex'} gap={2}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
              <DateTimePicker
                value={values?.startTime ? moment(values?.startTime) : null}
                onChange={(date: Moment) => {
                  formik.setFieldValue('startTime', date ? date?.toISOString(true) : null);
                }}
                maxDateTime={formik?.values?.endTime ? moment(formik?.values?.endTime) : null}
                maxTime={formik?.values?.endTime ? moment(formik?.values?.endTime) : null}
                renderInput={props => (
                  <TextField {...props} label="Дата и время активации" fullWidth />
                )}
                PopperProps={{
                  style: { zIndex: 1000000 },
                }}
                disabled={!config?.isEnabled}
              />
              <DateTimePicker
                value={values?.endTime ? moment(values?.endTime) : null}
                onChange={(date: Moment) => {
                  formik.setFieldValue('endTime', date ? date?.toISOString(true) : null);
                }}
                minDateTime={formik?.values?.startTime ? moment(formik?.values?.startTime) : null}
                minTime={formik?.values?.startTime ? moment(formik?.values?.startTime) : null}
                renderInput={props => (
                  <TextField {...props} label="Дата и время деактивации" fullWidth />
                )}
                PopperProps={{
                  style: { zIndex: 1000000 },
                }}
                disabled={!config?.isEnabled}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </FormControl>
      {isLoading ? <LinearProgress /> : <Box height={5} />}
      <Box marginTop={2}>
        <ModalFooter
          denyButton={{
            title: 'Отменить',
            color: 'default',
            handler: closeModal,
          }}
          successButton={{
            title: 'Синхронизация',
            color: 'primary',
            handler: formik.submitForm,
          }}
        />
      </Box>
    </Box>
  );
};

export default SynchroniesModal;
