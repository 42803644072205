import {
  Axios,
  TypeApiRequest,
  lazyInject,
  provide,
} from '../../../../../../../../../shared/utils';

@provide.transient()
class OwnerConfigService {
  @lazyInject(Axios)
  protected axios: Axios;

  getOwnerConfigByOwnerId = async (
    payload: TypeApiRequest<'getAgropogodaReportsConfigByOrgId'>
  ) => {
    try {
      const response = await this.axios.api.getAgropogodaReportsConfigByOrgId(payload, {
        omit: ['ownerId'],
      });

      return response;
    } catch (error) {
      return null;
    }
  };

  createOwnerConfigByOwnerId = async (payload: TypeApiRequest<'createAgropogodaReportsConfig'>) => {
    try {
      const response = await this.axios.api.createAgropogodaReportsConfig(payload);

      return response;
    } catch (error) {
      console.log('create owner config error', error);
    }
  };

  setOwnerConfigByOwnerId = async (payload: TypeApiRequest<'updateAgropogodaReportsConfig'>) => {
    try {
      const response = await this.axios.api.updateAgropogodaReportsConfig(payload);

      return response;
    } catch (error) {
      console.log('set owner config error', error);
    }
  };

  deleteOwnerConfigByOwnerId = async (payload: TypeApiRequest<'deleteAgropogodaReportsConfig'>) => {
    try {
      const response = await this.axios.api.deleteAgropogodaReportsConfig(payload, {
        omit: ['ownerId'],
      });

      return response;
    } catch (error) {
      console.log('delete owner config error', error);
    }
  };
}

export default OwnerConfigService;
