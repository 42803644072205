import { TypeFilters, TypeQuote } from '../../models/quotes.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = FormData;

type TypeResponse = any;

type TypeError = {};

export const quotesImport: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/da-quotes/quotes/import`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
