import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_DELETE_ORG_INTEGRA = 'before-delete-org-integra';

const beforeDeleteModalOrgIntegra: TModalConfig = {
  id: BEFORE_DELETE_ORG_INTEGRA,
  name: BEFORE_DELETE_ORG_INTEGRA,
  type: EModalType.Warning_Before_Living,
  title: 'Вы уверены, что хотите удалить?',

  successButton: {
    title: 'Удалить',
    color: 'primary',
  },
  denyButton: {
    title: 'Закрыть',
    color: 'secondary',
  },
};

export { beforeDeleteModalOrgIntegra, BEFORE_DELETE_ORG_INTEGRA };
