import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { ReactComponent as Success } from '../../../../../../../shared/assets/images/success.svg';
import { ReactComponent as Cancel } from '../../../../../../../shared/assets/images/cancel.svg';
import { EEmployeeStatus, TypeEmployee } from '../../../../../../../../api/models/employee.model';
import { useStore } from '../../../../../../../shared/utils';
import { EmployeeController } from '../../../../../../../controllers/employees.controller';

type Props = {
  employee: TypeEmployee;
  status: EEmployeeStatus;
  open: boolean;
  onClose: () => void;
  message: string;
  successMessage: string;
  closeButtonName?: string;
  actionButtonName: string;
  successButtonName?: string;
  className?: string;
  dataTestId?: string;
};

export const BlockEmployeeDialog: FC<Props> = ({
  employee,
  status,
  open,
  onClose,
  message,
  successMessage,
  closeButtonName = 'Отменить',
  actionButtonName,
  successButtonName = 'Хорошо',
  className,
  dataTestId = '',
}) => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const { blockEmployee, unblockEmployee, fetchEmployees } = useStore(EmployeeController);

  const [success, setSuccess] = useState<boolean>(false);

  const onCloseSuccessDialog = useCallback(() => {
    setSuccess(false);
    onClose();
  }, []);

  const handleBlockEmployee = () => {
    if (status === EEmployeeStatus.ACTIVE) {
      blockEmployee({ employeeId: employee.id }).then(() => {
        onCloseSuccessDialog();
        fetchEmployees({ organizationID: organizationId });
      });
    } else {
      unblockEmployee({ employeeId: employee.id }).then(() => {
        onCloseSuccessDialog();
        fetchEmployees({ organizationID: organizationId });
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={className} data-test-id={dataTestId}>
      {!success ? (
        <>
          <DialogTitle>
            <div style={{ display: 'block', textAlign: 'center' }}>
              <Cancel style={{ width: '38px', height: '57px' }} />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{closeButtonName}</Button>
            <Button onClick={handleBlockEmployee}>{actionButtonName}</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <div style={{ display: 'block', textAlign: 'center' }}>
              <Success style={{ width: '38px', height: '57px' }} />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{successMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseSuccessDialog}>{successButtonName}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
