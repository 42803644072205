import { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Layout } from '../../../../components';
import { getOrganizationMenu } from '../../../../../shared/menu';
import { useStore } from '../../../../../shared/utils';
import { EmployeeStore } from '../../../../../stores/employee/employee.store';

import { EmployeesFilter, EmployeesTable } from './components';

export const OrganizationEmployees: FC = observer(() => {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const { clearStore } = useStore(EmployeeStore);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Сотрудники</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <EmployeesFilter />
          <EmployeesTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
