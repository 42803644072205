import { log } from 'console';

import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Snackbar, Tab, TextField as MTextField } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from 'formik-mui';
import { isEmpty, isEqual, merge } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import {
  EChecklistAttributeCalculationType,
  EChecklistAttributeType,
  IAttribute,
  IChecklistAttributeEnumItemExtended,
  IODZ,
} from '../../../../../../../../../api/models/checklist.attribute.model';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { CheckListsController } from '../../../../../../../../controllers/check.list.controller';
import { ChecklistsAttributeController } from '../../../../../../../../controllers/checklistAttribute.controller';
import { DictionariesController } from '../../../../../../../../controllers/dictionaries.controller';
import { getFormikDirtyValues, useStore } from '../../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../../routes';
import { FormInput } from '../../../../../common';
import {
  IChecklistAttributeLabel,
  IChecklistShort,
} from '../../../../../../../../../api/models/checklist.model';
import {
  beforeChangeFormulaModalConfig,
  beforeDeleteArgumentFormulaModalConfig,
} from '../../../../../modules/Formulas/modals';
import { LEAVE_BEFORE_SAVE, leaveBeforeSaveModal } from '../../../../../../../../shared/modals';
import SplitDropdownButton from '../../../../../../../../shared/components/SplitDropdownButton/SplitDropdownButton';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';

import {
  attributeFormItemsSchemeDefaultValues,
  checklistsAttributeBuilderSchemeMap,
  EAttributeBuilderFormScheme,
  getSchemeValidation,
} from './AttributeBuilderForm.config';
import {
  ChecklistList,
  EnumDependency,
  EnumElementList,
  FormRow,
  LabelSelector,
  ODZContainer,
} from './components';
import Styled from './styled';
import ALLOWED_ODZ_TYPES from './configs/ODZ.config';
import { AttributeODZController, AttributeODZStore } from './components/ODZ/mobx';
import {
  beforeDeleteODZModal,
  beforeToggleODZModal,
  formulaODZPreviewModalConfig,
} from './components/ODZ/modals';

export enum EAttributeBuilderFormTab {
  Params = 'Params',
  Values = 'Values',
  Checklists = 'Checklists',
  Links = 'Links',
  ODZ = 'ODZ',
}

const AttributeBuilderFrom: FC<{ attributeData?: IAttribute }> = ({ attributeData }) => {
  const { getChecklistListByParams } = useStore(CheckListsController);
  const { getAllDictionaries } = useStore(DictionariesController);
  const {
    createChecklistAttribute,
    changeChecklistAttribute,
    changeChecklistAttributeActivity,
    deleteChecklistAttribute,
    getChecklistAttributeEnumList,
    changeChecklistAttributeEnumList,
    getAttributeLabelList,
  } = useStore(ChecklistsAttributeController);
  const { getPreparedODZData, provideODZData, clearDirtyFields } = useStore(AttributeODZController);
  const { clearStore } = useStore(AttributeODZStore);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [enumList, setEnumList] = useState<IChecklistAttributeEnumItemExtended[]>([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [unsavedTabSet, setUnsavedTabSet] = useState<Set<EAttributeBuilderFormTab>>(new Set());

  const history = useHistory();
  const { registerModalList, openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const isChangeAttribute = Boolean(attributeData);

  const [currentAttribute, setCurrentAttribute] = useState<
    keyof typeof checklistsAttributeBuilderSchemeMap | null
  >(null);
  const [currentTab, setCurrentTab] = useState<EAttributeBuilderFormTab>(
    EAttributeBuilderFormTab.Params
  );
  const [lastSavedValues, setLastSavedValues] = useState<IAttribute>(null);

  useEffect(() => {
    registerModalList(
      [
        beforeDeleteODZModal,
        formulaODZPreviewModalConfig,
        beforeToggleODZModal,
        beforeChangeFormulaModalConfig,
        beforeDeleteArgumentFormulaModalConfig,
        leaveBeforeSaveModal,
      ],
      'baseAttributeModals'
    );

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (isChangeAttribute) {
      setCurrentAttribute(attributeData.type as keyof typeof checklistsAttributeBuilderSchemeMap);
      const data: any = { ...attributeData };

      if (attributeData.type === 'ENUM') {
        getChecklistAttributeEnumList({ id: attributeData.id }).then(itemList => {
          setEnumList(itemList);
        });
      }

      setValues(data);
      provideODZData(attributeData);
    }
  }, [attributeData, isChangeAttribute]);

  const updateAttribute = (payload, setSubmitting, ODZData?: Partial<IODZ>) => {
    delete payload.validationComment;
    delete payload.validationGreenCriteria;
    delete payload.validationRedCriteria;
    delete payload.validationYellowCriteria;

    const changedValues = getFormikDirtyValues(payload, lastSavedValues || attributeData) as any;
    const { syncSto } = payload;

    if (changedValues?.labels?.length) {
      changedValues.labels = changedValues.labels
        .map(item => (typeof item === 'string' ? item : item.value))
        .filter(Boolean);
    }

    if (ODZData && !isEmpty(ODZData)) {
      merge(changedValues, ODZData);
    }

    return changeChecklistAttribute({ id: payload.id, syncSto, ...changedValues })
      .then(data => {
        const hasEnumValues = enumList.findIndex(item => item?.isNew) >= 0;

        provideODZData(data);

        setSnackbarMessage(
          `Атрибут успешно изменён!${
            hasEnumValues
              ? ' Есть несохранённые значения атрибута! Для сохранения нужно перейти во вкладку "Значения атрибута".'
              : ''
          }`
        );
        setShowSnackbar(true);
        setLastSavedValues(data);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка редактирования атрибута!');
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik<IAttribute & { usingDictionary?: string }>({
    initialValues: isChangeAttribute
      ? (attributeData as IAttribute)
      : (attributeFormItemsSchemeDefaultValues as IAttribute),
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: yup
      .object()
      .shape(getSchemeValidation(currentAttribute as EAttributeBuilderFormScheme)),
    onSubmit: (formData, { setSubmitting }) => {
      setIsProcessing(true);

      if (isChangeAttribute) {
        const payload = formData as any;

        if (currentTab === EAttributeBuilderFormTab.ODZ) {
          const ODZPayload = getPreparedODZData();

          updateAttribute(payload, setSubmitting, ODZPayload)
            .then(() => {
              clearDirtyFields();
            })
            .finally(() => {
              setIsProcessing(false);
            });

          setFieldValue('syncSto', false);
          return;
        }

        updateAttribute(payload, setSubmitting).finally(() => {
          setIsProcessing(false);
        });

        setFieldValue('syncSto', false);

        return;
      }

      if (formData?.labels?.length) {
        formData.labels = ((formData.labels.filter(Boolean) as unknown) as ISelectOption[]).map(
          item => item.value
        );
      }

      createChecklistAttribute(formData as any)
        .then(data => {
          setSnackbarMessage('Атрибут успешно создан!');
          setShowSnackbar(true);

          history.push(generatePath(AdminRoutes.ChecklistBasicAttribute, { attributeId: data.id }));
        })
        .catch(() => {
          setSnackbarMessage('Ошибка создания атрибута!');
          setShowSnackbar(true);
        })
        .finally(() => {
          setSubmitting(false);
          setIsProcessing(false);
        });
    },
  });

  const { submitForm, setFieldValue, setValues, values, resetForm, setFieldError, errors } = formik;

  const changeAttributeActivity = useCallback(() => {
    changeChecklistAttributeActivity({
      id: values.id,
      activeValue: !values.isActive,
    })
      .then(data => {
        setSnackbarMessage('Статус успешно изменён!');
        setShowSnackbar(true);

        setFieldValue('isActive', data.isActive);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка изменения статуса!');
        setShowSnackbar(true);
      });
  }, [values.isActive]);

  const attributeBuilderTypes = useMemo<ISelectOption[]>(
    () => Object.keys(checklistsAttributeBuilderSchemeMap).map(key => ({ label: key, value: key })),
    [checklistsAttributeBuilderSchemeMap]
  );

  const selectedAttributeScheme = useMemo(
    () => checklistsAttributeBuilderSchemeMap[currentAttribute],
    [currentAttribute]
  );

  const onSelectAttributeType = useCallback((e, value: ISelectOption | string) => {
    if (typeof value === 'object') {
      resetForm();
      setFieldValue('type', value.value);
      setCurrentAttribute(value.value as keyof typeof checklistsAttributeBuilderSchemeMap);
    }
  }, []);

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const changeEnumList = useCallback(() => {
    changeChecklistAttributeEnumList({
      id: values.id,
      list: enumList
        .filter(item => !item?.isHide)
        .map(item => {
          if (item.isNew) {
            const cleanedItem = { ...item };

            delete cleanedItem.id;
            delete cleanedItem.isNew;
            delete cleanedItem.isHide;

            return cleanedItem;
          }

          delete item.isHide;

          return item;
        }),
    })
      .then(data => {
        setSnackbarMessage('Значения атрибутов успешно изменены!');
        setShowSnackbar(true);
        setEnumList(data);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка сохранения значений атрибута!');
        setShowSnackbar(true);
      });
  }, [enumList, values]);

  const handleDeleteAttribute = useCallback(() => {
    deleteChecklistAttribute({ id: values.id })
      .then(() => {
        setIsDeleted(true);
        history.push(generatePath(AdminRoutes.ChecklistAttributes));
      })
      .catch(() => {
        setSnackbarMessage('Ошибка удаления атрибута!');
        setShowSnackbar(true);
      });
  }, [values]);

  const isUnsavedSummary = useMemo(() => !isEqual(attributeData, values), [attributeData, values]);

  const setUnsaved = (tab: EAttributeBuilderFormTab, state: boolean) => {
    if (state) {
      setUnsavedTabSet(unsavedTabSet.add(tab));
    } else {
      unsavedTabSet.delete(tab);
      setUnsavedTabSet(unsavedTabSet);
    }
  };

  useEffect(() => {
    if (formik?.values?.isMultiselect && !formik?.values?.calculationType) {
      setFieldValue('calculationType', EChecklistAttributeCalculationType.AVERAGE);

      setNotification(
        getNotificatorProps(
          'Выбрано значение типа агрегации по умолчанию',
          ENotificationStyles.Success
        )
      );
    }
  }, [formik?.values?.isMultiselect]);

  const onChangeTab = (e, key: EAttributeBuilderFormTab) => {
    if (isUnsavedSummary || unsavedTabSet.size > 0) {
      return openModalByModalId(LEAVE_BEFORE_SAVE, null, () => {
        setUnsavedTabSet(new Set());
        setCurrentTab(key);
      });
    }

    setCurrentTab(key);
  };

  const isEnumAttr = useMemo(() => attributeData?.type === EChecklistAttributeType.Enum, [
    attributeData?.type,
  ]);
  const isODZAllowed = useMemo(() => ALLOWED_ODZ_TYPES.includes(attributeData?.type), [
    attributeData?.type,
  ]);

  return (
    <TabContext value={currentTab}>
      {/* <Prompt
        when={isViewAttribute && isUnsavedChanges && !isDeleted}
        message="Введенные данные будут потеряны"
      /> */}
      <Styled.ButtonGroup>
        <SplitDropdownButton
          buttonList={[
            {
              label: 'Сохранить',
              isDisabled: isProcessing,
              onClickHandler:
                currentTab === EAttributeBuilderFormTab.Values ? changeEnumList : submitForm,
            },
            {
              label: 'Сохранить и применить в СТО',
              isDisabled: isProcessing,
              isHidden: currentTab === EAttributeBuilderFormTab.Values || !isChangeAttribute,
              onClickHandler: () => {
                setFieldValue('syncSto', true);
                submitForm();
              },
            },
          ]}
        />
        {isChangeAttribute && currentTab === EAttributeBuilderFormTab.Params && (
          <Styled.ButtonGroup>
            <Button
              onClick={changeAttributeActivity}
              color={(values.isActive as boolean) ? 'error' : 'primary'}
              variant="contained"
            >
              {(values.isActive as boolean) ? 'Блокировать' : 'Разблокировать'}
            </Button>
            <Button onClick={handleDeleteAttribute} color="error" variant="contained">
              Удалить
            </Button>
          </Styled.ButtonGroup>
        )}
      </Styled.ButtonGroup>
      <Box>
        <TabList onChange={onChangeTab}>
          <Tab label="Параметры атрибута" value={EAttributeBuilderFormTab.Params} />
          {currentAttribute === 'ENUM' && isChangeAttribute ? (
            <Tab label="Значения атрибута" value={EAttributeBuilderFormTab.Values} />
          ) : null}
          {isEnumAttr && isChangeAttribute ? (
            <Tab label="Связи" value={EAttributeBuilderFormTab.Links} />
          ) : null}

          {isChangeAttribute && (
            <Tab label="Чек-листы" value={EAttributeBuilderFormTab.Checklists} />
          )}

          {isODZAllowed && <Tab label="ОДЗ" value={EAttributeBuilderFormTab.ODZ} />}
        </TabList>
      </Box>
      <Styled.TabPanel value={EAttributeBuilderFormTab.Params}>
        <FormikProvider value={formik}>
          <Styled.Form>
            <FormRow>
              <Field
                name="type"
                component={Autocomplete}
                options={attributeBuilderTypes ?? []}
                onChange={onSelectAttributeType}
                style={{ minWidth: 300 }}
                disabled={isChangeAttribute}
                disableClearable
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <MTextField {...params} label="Тип" variant="outlined" />
                )}
              />
              {selectedAttributeScheme && selectedAttributeScheme.has('name') && (
                <>
                  <FormInput itemKey="name" />
                  <FormInput itemKey="id" disabled />
                </>
              )}
            </FormRow>
            {selectedAttributeScheme && (
              <>
                <FormRow>
                  {selectedAttributeScheme.has('isTitle') && <FormInput itemKey="isTitle" />}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isRequired') && <FormInput itemKey="isRequired" />}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isMultiselect') && (
                    <FormInput itemKey="isMultiselect" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('calculationType') && (
                    <FormInput
                      itemKey="calculationType"
                      onChangeHandler={value => setFieldValue('calculationType', value)}
                    />
                  )}
                </FormRow>
                <FormRow $maxWidth="300px">
                  {selectedAttributeScheme.has('precision') && (
                    <FormInput
                      itemKey="precision"
                      onChangeHandler={value => setFieldValue('precision', value)}
                      stringConfig={{ regExp: /^\d$/ }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('nestedChecklistName') && (
                    <FormInput
                      itemKey="nestedChecklistName"
                      autocompleteConfig={{
                        optionsFetchHandler: getChecklistListByParams,
                        searchingKey: 'name',
                        optionsMappingScheme: (item: IChecklistShort) => ({
                          label: item?.name,
                          value: item?.id,
                          rawData: item,
                        }),
                        onChangeMappingKey: 'checklistLink',
                        isOnMountOptionsFetch: true,
                        searchingParams: { isNested: true },
                        onSelect: value =>
                          setFieldValue('nestedChecklistName', value?.label || null),
                        isClearable: true,

                        ...(isChangeAttribute
                          ? { fetchValueOnMount: { id: values?.checklistLink } }
                          : {}),
                      }}
                    />
                  )}

                  {selectedAttributeScheme.has('checklistLink') && (
                    <FormInput itemKey="checklistLink" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('usingDictionary') && (
                    <FormInput
                      itemKey="usingDictionary"
                      autocompleteConfig={{
                        optionsFetchHandler: getAllDictionaries,
                        searchingKey: 'name',
                        onChangeMappingKey: 'dictionaryLink',
                        optionsMappingScheme: { label: 'title', value: 'remoteName' },
                        isOnMountOptionsFetch: true,
                        onSelect: value => {
                          if (value !== null) {
                            setFieldError('dictionaryLink', null);
                          }
                        },
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('dictionaryLink') && (
                    <FormInput itemKey="dictionaryLink" />
                  )}
                  {selectedAttributeScheme.has('displayParent') && (
                    <FormInput itemKey="displayParent" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('toolTip') && (
                    <FormInput
                      itemKey="toolTip"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 4000 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('placeholder') && (
                    <FormInput
                      itemKey="placeholder"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 50 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('description') && (
                    <FormInput
                      itemKey="description"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 100 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  <LabelSelector
                    value={formik.values.labels}
                    setValue={value => setFieldValue('labels', value)}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={((formik.values as any)?.author as string) ?? ''}
                    label="Дата создания"
                    disabled
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={((formik.values as any)?.date as string) ?? ''}
                    label="Автор"
                    disabled
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={
                      ((formik.values as any)?.isActive as string) ? 'Активный' : 'Неактивный' ?? ''
                    }
                    label="Статус"
                    disabled
                    fullWidth
                  />
                </FormRow>
              </>
            )}
          </Styled.Form>
          <FormRow></FormRow>
        </FormikProvider>
      </Styled.TabPanel>
      <Styled.TabPanel value={EAttributeBuilderFormTab.Values}>
        <EnumElementList enumList={enumList} setEnumList={setEnumList} />
      </Styled.TabPanel>
      <Styled.TabPanel value={EAttributeBuilderFormTab.Checklists}>
        <ChecklistList attributeId={attributeData?.id} />
      </Styled.TabPanel>
      <Styled.TabPanel value={EAttributeBuilderFormTab.Links}>
        <EnumDependency />
      </Styled.TabPanel>
      <Styled.TabPanel value={EAttributeBuilderFormTab.ODZ}>
        <ODZContainer
          attribute={attributeData}
          setIsChanged={state => setUnsaved(EAttributeBuilderFormTab.ODZ, state)}
          isCopyODZ
        />
      </Styled.TabPanel>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </TabContext>
  );
};

export default observer(AttributeBuilderFrom);
