import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TDependencySelectOption } from '../../../../../../../../../../../../../../../../api/models/dependendency.model';
import { DictionariesController } from '../../../../../../../../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../../../../../../../../shared/utils';

import { DependenciesGrid, NewDependency } from './components';
import DependencyFilter, {
  IAdditionalFilterItem,
} from './components/DependencyFilter/DependencyFilter';
import { getDepListColumns } from './helpers/getDepListColumns';

interface IDependenciesContainerConfig {
  linkedDictionaryList: TDependencySelectOption[];
  mode: 'depend' | 'dependent';
  additionalFilters?: IAdditionalFilterItem[];
  refetchDictionaryDeps: () => Promise<void>;
}

interface IProps {
  config: IDependenciesContainerConfig;
}

const DependenciesContainer: FC<IProps> = ({ config }) => {
  const { linkedDictionaryList, mode, refetchDictionaryDeps } = config;
  const { name: remoteName, itemId } = useParams<{ name: string; itemId: string }>();
  const {
    getDependencyLinksByDependencyRecord,
    getDependencyLinksByTargetRecord,
    getDictionaryTitleByName,
    deleteDependencyLink,
  } = useStore(DictionariesController);

  const [selectedDictionary, setSelectedDictionary] = useState<TDependencySelectOption | null>(
    null
  );
  const [depList, setDepList] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!selectedDictionary) {
      return;
    }

    setFilterValues({});

    fetchData(selectedDictionary, filterValues);
  }, [selectedDictionary]);

  const fetchData = useCallback((dict, additionalParams) => {
    if (!dict) {
      return;
    }

    setIsLoading(true);
    setDepList([]);

    if (mode === 'dependent') {
      const requestPayload: Parameters<typeof getDependencyLinksByDependencyRecord>[0] = {
        dependencyId: dict.id,
        dependencyRecordId: itemId,
        ...additionalParams,
      };

      getDependencyLinksByDependencyRecord(requestPayload)
        .then(data => {
          setDepList(
            data.map(dep => ({
              ...dep,
              dictionaryName: getDictionaryTitleByName(dep.dictionaryName),
              dependencyName: getDictionaryTitleByName(dep.dependencyName),
            }))
          );
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }

    if (mode === 'depend') {
      const requestPayload: Parameters<typeof getDependencyLinksByTargetRecord>[0] = {
        dependencyId: dict.id,
        targetId: itemId,
        ...additionalParams,
      };

      getDependencyLinksByTargetRecord(requestPayload)
        .then(data => {
          setDepList(
            data.map(dep => ({
              ...dep,
              dictionaryName: getDictionaryTitleByName(dep.dictionaryName),
              dependencyName: getDictionaryTitleByName(dep.dependencyName),
            }))
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleChangeSelectedDictionary = useCallback((event, option: TDependencySelectOption) => {
    setSelectedDictionary(option);
  }, []);

  const handleFilterSubmit = useCallback(() => {
    fetchData(selectedDictionary, filterValues);
  }, [selectedDictionary, filterValues]);

  const handleDeleteDependency = useCallback(params => {
    deleteDependencyLink(params.id);
  }, []);

  const dependencyColumns = useMemo(() => getDepListColumns(handleDeleteDependency), []);

  const refetchDepsList = useCallback(() => {
    refetchDictionaryDeps().then(() => {
      handleFilterSubmit();
    });
  }, [handleFilterSubmit, refetchDictionaryDeps, selectedDictionary, filterValues]);

  return (
    <div>
      <NewDependency mode={mode} refetchDepsList={refetchDepsList} />
      <Box sx={{ display: 'flex', gap: '8px', padding: '18px 0', justifyContent: 'space-between' }}>
        <Box flexBasis="50%">
          <Autocomplete
            options={linkedDictionaryList}
            renderInput={params => <TextField {...params} label="Справочник" />}
            onChange={handleChangeSelectedDictionary}
            sx={{ maxWidth: '320px' }}
            disableClearable
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <DependencyFilter
            additionalFilters={config.additionalFilters}
            onFilterSubmit={handleFilterSubmit}
            selectedDictionary={selectedDictionary}
            setValue={setFilterValues}
            filterValues={filterValues}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DependenciesGrid columns={dependencyColumns} mode={mode} rows={depList} />
      )}
    </div>
  );
};

export default DependenciesContainer;
