import { TypeApiRoute } from '../../models/type.api.request';
import { ICultureAndVariety } from '../../models/culture.model';

type TypeRequest = {
  year: string;
  organizationId: string;
};

type TypeResponse = {
  fieldsWithoutCulturesCount: number;
  cultureAndVarietyList: ICultureAndVariety[];
};

type TypeError = {};

export const getCultureZoneListBySeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/as-fields/seasons/${request.year}/cultureList`,
  headers: {},
  method: 'GET',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
