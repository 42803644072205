import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.transient()
class OrganizationService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchOrganizationApplicationList = async (
    payload: TypeApiRequest<'getOrganizationApplicationList'>
  ) => {
    try {
      const response = await this.axios.api.getOrganizationApplicationList(payload);

      return response;
    } catch (error) {
      console.log('fetch organization application list error', error);
    }
  };

  inviteUserByEmail = async (payload: TypeApiRequest<'inviteUserByEmail'>) => {
    try {
      const response = await this.axios.api.inviteUserByEmail(payload, {
        omit: ['organizationId'],
      });

      return response;
    } catch (error) {
      console.log('invite user by email error', error);

      throw error;
    }
  };

  cancelUserInvite = async (payload: TypeApiRequest<'cancelUserInvite'>) => {
    try {
      const response = await this.axios.api.cancelUserInvite(payload, {
        omit: ['invitationId'],
      });

      return response;
    } catch (error) {
      console.log('cancel user email error', error);
    }
  };

  updateUserInvite = async (payload: TypeApiRequest<'updateUserInvite'>) => {
    try {
      const response = await this.axios.api.updateUserInvite(payload, {
        omit: ['invitationId'],
      });

      return response;
    } catch (error) {
      console.log('update user email error', error);

      throw error;
    }
  };

  updateOrganizationById = async (payload: TypeApiRequest<'updateOrganizationById'>) => {
    try {
      const response = await this.axios.api.updateOrganizationById(payload, {
        omit: ['organizationId'],
      });

      return response;
    } catch (error) {
      console.log('update organization error', error);

      throw error;
    }
  };
}

export default OrganizationService;
