import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select } from 'formik-mui';
import { uniq } from 'lodash';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { observer } from 'mobx-react';

import { EImportOperationStatus } from '../../../../../../../../../../api/models/importOperation.model';
import { PAGE_SIZE_LIST, useAgropogodaReportHistory } from '../../../../hooks';
import { IAgropogodaHistoryFilter } from '../../models';

const STATUS_IN_OPTION_LIST: EImportOperationStatus[] = [
  EImportOperationStatus.PENDING,
  EImportOperationStatus.IN_PROGRESS,
  EImportOperationStatus.COMPLETED,
  EImportOperationStatus.ERROR,
];

const ReportAgropogodaHistory: FC = () => {
  const controller = useAgropogodaReportHistory();

  const { startDate, endDate } = useMemo(() => {
    const currentDate = new Date();

    return {
      startDate: moment(currentDate).subtract(6, 'd').format('YYYY-MM-DD'),
      endDate: moment(currentDate).format('YYYY-MM-DD'),
    };
  }, []);

  const onSubmit = (data: IAgropogodaHistoryFilter) => {
    controller.fetchHistory(data, 0);
  };

  const onReset = () => {
    controller.fetchHistory(
      {
        startDate,
        endDate,
        statuses: [],
      },
      0,
      20
    );
  };

  useEffect(() => {
    controller.fetchHistory({ startDate, endDate, statuses: [] });
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{
          startDate,
          endDate,
          statuses: [],
        }}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    id="statuses"
                    name="statuses"
                    label="Статус"
                    onChange={() => null}
                    renderValue={() => values.statuses?.join(', ')}
                    disabled={false}
                  >
                    {STATUS_IN_OPTION_LIST.map(status => (
                      <MenuItem
                        key={status}
                        value={status}
                        onClickCapture={event => {
                          event.stopPropagation();
                          if (values.statuses.includes(status)) {
                            setFieldValue(
                              'statuses',
                              uniq(values.statuses.filter(item => item !== status))
                            );
                          } else {
                            setFieldValue('statuses', uniq([...values.statuses, status]));
                          }
                        }}
                      >
                        <Checkbox checked={values?.statuses.includes(status)} />
                        <ListItemText primary={status} />
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <br />
              <Grid xs={4} item>
                <CalendarComponent
                  label="Дата заказа отчета с"
                  onChange={date => setFieldValue('startDate', date)}
                  value={(values?.startDate && moment(values.startDate).format('DD.MM.YYYY')) ?? ''}
                  datePickerOptions={{ dateRange: { maxDate: moment(values?.endDate).toDate() } }}
                />
              </Grid>
              <Grid xs={4} item>
                <CalendarComponent
                  label="Дата заказа отчета по"
                  onChange={date => setFieldValue('endDate', date)}
                  value={(values?.endDate && moment(values.endDate).format('DD.MM.YYYY')) ?? ''}
                  datePickerOptions={{ dateRange: { minDate: moment(values?.startDate).toDate() } }}
                />
              </Grid>
              <Grid xs={5} item>
                <Box display={'flex'} flexDirection={'row'} gap={2}>
                  <Button
                    sx={{ maxWidth: '120px' }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Применить
                  </Button>
                  <Button sx={{ maxWidth: '120px' }} color="error" variant="text" type="reset">
                    Сбросить
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Box height={`calc(100vh - 480px)`} marginTop={2}>
        <DataGrid
          columns={controller.gridColumnConfig}
          rows={controller.valueList}
          pagination={true}
          paginationMode={'server'}
          onPageChange={controller.setPage}
          onPageSizeChange={controller.setSize}
          loading={controller.isLoading}
          rowsPerPageOptions={PAGE_SIZE_LIST}
          page={controller.page}
          pageSize={controller.size}
          rowCount={controller.totalElements}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
        />
      </Box>
    </Box>
  );
};

export default observer(ReportAgropogodaHistory);
