import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { AGROSIGNAL_REPORT_MODAL_ID } from '../../modals';
import { IAgrosignalConfig } from '../../components/OrganizationReportsAgrosignal/models';
import { useStore } from '../../../../../../../shared/utils';
import { AgrosignalReportController } from '../../components/OrganizationReportsAgrosignal/mobx';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../../../shared/utils/downloadBlobAsFile';

const useUploadTriggers = ({
  organizationId,
  config,
}: {
  organizationId: string;
  config: IAgrosignalConfig;
}) => {
  const controller = useStore(AgrosignalReportController);

  const { openModalByModalId, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const downloadData = () => {
    openModalByModalId(
      AGROSIGNAL_REPORT_MODAL_ID,
      {
        title: 'Заказ выгрузки данных',
      },
      ({ fromDate, toDate }) => {
        return controller
          .triggerDataReport({ clientId: config.clientId, fromDate, toDate })
          .then(data => {
            setNotification(
              getNotificatorProps('Выгрузка данных запрошена успешно', ENotificationStyles.Success)
            );
            closeModal();

            return data;
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка запроса выгрузки данных', ENotificationStyles.Error)
            );
          });
      }
    );
  };

  const downloadPhotos = () => {
    openModalByModalId(
      AGROSIGNAL_REPORT_MODAL_ID,
      {
        title: 'Заказ выгрузки фотоматериалов',
      },
      ({ fromDate, toDate }) => {
        controller
          .triggerFilesReport({ clientId: config.clientId, fromDate, toDate })
          .then(() => {
            setNotification(
              getNotificatorProps(
                'Выгрузка фотоматериалов запрошена успешно',
                ENotificationStyles.Success
              )
            );
            closeModal();
          })
          .catch(() => {
            setNotification(
              getNotificatorProps(
                'Ошибка запроса выгрузки фотоматериалов',
                ENotificationStyles.Error
              )
            );
          });
      }
    );
  };

  const downloadReport = () => {
    openModalByModalId(
      AGROSIGNAL_REPORT_MODAL_ID,
      {
        title: 'Формирование отчёта',
      },
      ({ fromDate, toDate }) => {
        controller
          .downloadAgrosignalReport({ clientId: config.clientId, fromDate, toDate })
          .then(data => {
            downloadBlobAsFile(data, `Отчёт «Агросигнал» ${fromDate} - ${toDate}.zip`);
            closeModal();
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка формирования отчёта', ENotificationStyles.Error)
            );
          });
      }
    );
  };

  return { downloadData, downloadPhotos, downloadReport };
};

export default useUploadTriggers;
