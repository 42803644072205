import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { FieldLoadModalForm } from './components';

const ORG_INTEGRA_FIELD_LOAD_MODAL_ID = 'org-integra-field-modal-modal-id';

const fieldLoadModalConfig: TModalConfig = {
  id: ORG_INTEGRA_FIELD_LOAD_MODAL_ID,
  name: ORG_INTEGRA_FIELD_LOAD_MODAL_ID,
  title: '',
  type: EModalType.Custom,
  children: FieldLoadModalForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { ORG_INTEGRA_FIELD_LOAD_MODAL_ID, fieldLoadModalConfig };
