import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import { TooltipedValue } from '../../../../../../shared/components';
import { IPogodaVPoleImportOperationModel } from '../../../../../../../api/models/importOperation.model';

const pogodaVPoleHistoryGridColumns: GridColDef<IPogodaVPoleImportOperationModel>[] = [
  {
    field: 'dateFrom',
    type: 'string',
    headerName: 'Дата начала для выборки данных',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    flex: 1,
    maxWidth: 240,
  },
  {
    field: 'dateTo',
    type: 'string',
    headerName: 'Дата окончания для выборки данных',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    flex: 1,
    maxWidth: 240,
  },
  {
    field: 'creationDate',
    type: 'string',
    headerName: 'Дата заказа выгрузки',
    renderCell: params => (
      <TooltipedValue
        value={params?.value ? moment(params?.value).format('DD.MM.YYYY') : 'Не указано'}
      />
    ),
    flex: 1,
    maxWidth: 240,
  },

  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    minWidth: 120,
  },
  {
    field: 'message',
    type: 'string',
    headerName: 'Ошибка',
    renderCell: params => <TooltipedValue value={params.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 120,
  },
];

export default pogodaVPoleHistoryGridColumns;
