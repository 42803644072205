import { TypeApiRoute } from '../../models/type.api.request';

import { TDictionaryNameList } from './../../models/dictionaries.model';

type TypeRequest = {};

type TypeResponse = TDictionaryNameList;

export const getAllDictionaries: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-dictionary/dictionary/`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
