import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  newsId: string;
};

type TypeResponse = {};

type TypeError = {};

export const deleteNewsItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/${request.newsId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
