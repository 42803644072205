import { useEffect, useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';
import { throttle } from 'lodash';

import { useStore } from '../../../../../../../../../../../../shared/utils';
import { FieldsService } from '../../../../../../../../../../../../services';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { OrgIntegraAgrosignalService } from '../../../../../mobx';
import { getNotificatorProps } from '../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../shared/constanst/notifications';
import {
  IOrgIntegraAgrosignalAgroField,
  IOrgIntegraAgrosignalAssistanceField,
} from '../../../models';

const useModalForm = organizationId => {
  const fieldService = useStore(FieldsService);
  const service = useStore(OrgIntegraAgrosignalService);

  const { setNotification } = useNotificator();

  const [assistanceFieldList, setAssistanceFieldList] = useState<
    ISelectOptionExtended<IOrgIntegraAgrosignalAssistanceField>[]
  >([]);
  const [agroFieldList, setAgroFieldList] = useState<
    ISelectOptionExtended<IOrgIntegraAgrosignalAgroField>[]
  >([]);

  const [assistanceField, setAssistanceField] = useState<
    ISelectOptionExtended<IOrgIntegraAgrosignalAssistanceField>
  >(null);
  const [agrosignalField, setAgrosignalField] = useState<
    ISelectOptionExtended<IOrgIntegraAgrosignalAgroField>
  >(null);

  const [searchAssistanceField, setSearchAssistanceField] = useState<string>('');
  const [searchAgrosignalField, setSearchAgrosignalField] = useState<string>('');

  const [assistanceFieldError, setAssistanceFieldError] = useState<string>(null);
  const [agrosignalFieldError, setAgrosignalFieldError] = useState<string>(null);

  const [isAssistanceFieldLoading, setIsAssistanceFieldLoading] = useState<boolean>(false);
  const [isAgrosignalFieldLoading, setIsAgrosignalFieldLoading] = useState<boolean>(false);

  const fetchAssistanceFieldHandler = ({ name }: { name?: string }) => {
    setIsAssistanceFieldLoading(true);

    service
      .getOrgIntegraAgrosignalAssistanceFieldList({
        organizationId,
        isMapped: false,
        name,
        size: 2000,
      })
      .then(data => {
        setAssistanceFieldList(
          data.content?.map(item => ({
            label: `${item.name}`,
            value: item.id,
            rawData: item,
          }))
        );
      })
      .finally(() => setIsAssistanceFieldLoading(false));
  };

  const fetchAgroFieldHandler = ({ name }: { name?: string }) => {
    setIsAgrosignalFieldLoading(true);

    service
      .getOrgIntegraAgrosignalAgroFieldList({
        organizationId,
        isMapped: false,
        name,
        size: 2000,
      })
      .then(data => {
        setAgroFieldList(
          data.content?.map(item => ({
            label: `${item.name}`,
            value: item.id,
            rawData: item,
          }))
        );
      })
      .finally(() => setIsAgrosignalFieldLoading(false));
  };

  useEffect(() => {
    if (assistanceField && assistanceFieldError === null && assistanceField?.rawData?.name) {
      fetchAgroFieldHandler({ name: assistanceField?.rawData?.name });
    }
  }, [assistanceField]);

  useEffect(() => {
    if (agrosignalField && agrosignalFieldError === null && agrosignalField?.rawData?.name) {
      fetchAssistanceFieldHandler({ name: agrosignalField?.rawData?.name });
    }
  }, [agrosignalField]);

  const createField = () => {
    if (!organizationId || !assistanceField || !agrosignalField) {
      setNotification(
        getNotificatorProps('Проверьте заполненность полей', ENotificationStyles.Error)
      );

      return Promise.reject(new Error());
    }

    return service
      .createField({
        organizationId,
        fieldId: assistanceField?.value as string,
        agrosFieldId: Number(agrosignalField?.rawData.agrosFieldId),
      })
      .then(data => {
        setNotification(getNotificatorProps('Поле успешно создано', ENotificationStyles.Success));

        return data;
      })
      .catch(e => {
        setNotification(getNotificatorProps('Ошибка создания поля', ENotificationStyles.Error));
      });
  };

  const updateField = (id?: string) => {
    if (!organizationId || !assistanceField || !agrosignalField || !id) {
      setNotification(
        getNotificatorProps('Проверьте заполненность полей', ENotificationStyles.Error)
      );

      return Promise.reject(new Error());
    }

    return service
      .updateField({
        organizationId,
        fieldId: assistanceField?.value as string,
        agrosFieldId: Number(agrosignalField.rawData.agrosFieldId),
        id,
      })
      .then(data => {
        setNotification(getNotificatorProps('Поле успешно обновлено', ENotificationStyles.Success));

        return data;
      })
      .catch(e => {
        setNotification(getNotificatorProps('Ошибка обновления поля', ENotificationStyles.Error));
      });
  };

  const throttledFetch = useMemo(
    () =>
      throttle((request: () => any, onFetch?: (data: any) => void) => {
        request()?.then(data => {
          onFetch?.(data);
        });
      }, 1000),
    []
  );

  useEffect(() => {
    throttledFetch(() => fetchAgroFieldHandler({ name: searchAgrosignalField }));
  }, [searchAgrosignalField]);

  useEffect(() => {
    throttledFetch(() => fetchAssistanceFieldHandler({ name: searchAssistanceField }));
  }, [searchAssistanceField]);

  return {
    assistanceFieldList,
    agroFieldList,
    assistanceField,
    setAssistanceField,
    agrosignalField,
    setAgrosignalField,
    createField,
    updateField,
    assistanceFieldError,
    agrosignalFieldError,
    setSearchAssistanceField,
    setSearchAgrosignalField,
    isAgrosignalFieldLoading,
    isAssistanceFieldLoading,
  };
};

export { useModalForm };
