import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TDependencySelectOption } from '../../../../../../../../../../../../../../api/models/dependendency.model';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { DictionariesStore } from '../../../../../../../../../../../../../stores';

import { DependenciesContainer } from './components';

const Dependencies = () => {
  const { getDictionaryDependenciesList } = useStore(DictionariesController);
  const { dictionaryNameList } = useStore(DictionariesStore);
  const { name: remoteName } = useParams<{ name: string; itemId: string }>();

  const [currentTab, setCurrentTab] = useState('1');
  /**
   *  Кто зависит
   */
  const [dependentList, setDependentList] = useState<TDependencySelectOption[]>([]);
  /**
   * От кого зависит
   */
  const [dependList, setDependList] = useState<TDependencySelectOption[]>([]);

  useEffect(() => {
    fetchDependencyList();
  }, []);

  const fetchDependencyList = () => {
    return Promise.all([
      getDictionaryDependenciesList({ dependencyName: remoteName }),
      getDictionaryDependenciesList({ dictionaryName: remoteName }),
    ]).then(([dependentItems, dependItems]) => {
      setDependentList(
        dependentItems.content.map(item => ({
          ...item,
          label: dictionaryNameList.find(name => name.remoteName === item.dictionaryName).title,
          value: item.id,
        }))
      );

      setDependList(
        dependItems.content.map(item => ({
          ...item,
          label: dictionaryNameList.find(name => name.remoteName === item.dependencyName).title,
          value: item.id,
        }))
      );
    });
  };

  const onChangeTab = (e, key: string) => {
    setCurrentTab(key);
  };

  const dependConfig = useMemo<ComponentProps<typeof DependenciesContainer>['config']>(
    () => ({
      linkedDictionaryList: dependList,
      mode: 'depend',
      additionalFilters: [
        { title: 'От кого зависит', attribute: 'dependencyRecordName' },
        { title: 'Path от кого', attribute: 'dependencyPath' },
      ],
      refetchDictionaryDeps: fetchDependencyList,
    }),
    [dependList]
  );

  const dependentConfig = useMemo<ComponentProps<typeof DependenciesContainer>['config']>(
    () => ({
      linkedDictionaryList: dependentList,
      mode: 'dependent',
      additionalFilters: [
        { title: 'Кто зависит', attribute: 'targetPathName' },
        { title: 'Path кто зависит', attribute: 'targetPath' },
      ],
      refetchDictionaryDeps: fetchDependencyList,
    }),
    [dependentList]
  );

  return (
    <TabContext value={currentTab}>
      <Box>
        <TabList onChange={onChangeTab}>
          <Tab label="От кого зависит" value="1" />
          <Tab label="Кто зависит" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <DependenciesContainer config={dependConfig} />
      </TabPanel>
      <TabPanel value="2">
        <DependenciesContainer config={dependentConfig} />
      </TabPanel>
    </TabContext>
  );
};

export default Dependencies;
