import { FormControlLabel, Switch } from '@mui/material';

const BooleanField = ({ label, name, value, onChange }) => {
  return (
    <FormControlLabel
      control={<Switch id={name} onChange={(event, checked) => onChange({ [name]: checked })} />}
      label={label}
      checked={Boolean(value)}
      name="isChild"
    />
  );
};

export default BooleanField;
