import { ENotificationType, useNotificator, useShowTooltip } from '@farmlink/farmik-ui';
import { Tooltip, Typography } from '@mui/material';
import { FC, memo, useCallback } from 'react';

const TooltipedValue: FC<{ value: string; dblClickToCopy?: boolean; fontSize?: number }> = ({
  value,
  fontSize,
}) => {
  const { setNotification } = useNotificator();
  const { ref, isShowTooltip } = useShowTooltip();

  const handleDoubleClick = useCallback(() => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(value).then(() => {
        setNotification({
          message: 'Данные скопированы',
          style: { placement: 'top-right', type: ENotificationType.Info },
        });
      });
    }
  }, [value]);

  return (
    <Tooltip title={isShowTooltip ? value : ''}>
      <Typography fontSize={fontSize ?? 14} noWrap onDoubleClick={handleDoubleClick} ref={ref}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export default memo(TooltipedValue);
