import { Autocomplete, Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Colors } from '@farmlink/farmik-ui';

import { FormulaContext } from '../../../../../../../../contexts';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../../../../../../../api/models/checklist.model';
import { EChecklistAttributeType } from '../../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import { ChecklistsAttributeController } from '../../../../../../../../../../../../../controllers/checklistAttribute.controller';
import { EFormulaType, IAggregationFormulaType } from '../../../../../../../../models';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  argList: IAggregationFormulaType[];
  isBasicAttributeODZ?: boolean;
}

const DictionarySelector: FC<IProps> = ({ value, onChange, argList, isBasicAttributeODZ }) => {
  const { checklistAttributeList, checklistAttribute } = useContext(FormulaContext);
  const { getAllDictionariesByParams } = useStore(DictionariesController);
  const { getChecklistAttributeEnumList } = useStore(ChecklistsAttributeController);

  const [isExpanded, setIsExpanded] = useState(false);
  const [attrValue, setAttrValue] = useState<ISelectOptionExtended<IChecklistAttribute>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [attrOptionList, setAttrOptionList] = useState<ISelectOptionExtended[]>([]);

  const optionList = useMemo(() => {
    const allowedAttrIdList = argList?.flatMap(item => item?.attrId) ?? [];

    // Проверка на наличие уже выбранных атрибутов в аргументах
    const allowedOptionList = checklistAttributeList?.filter(
      item =>
        item?.rawData?.attribute &&
        (item.rawData.attribute.type === EChecklistAttributeType.Enum ||
          item.rawData.attribute.type === EChecklistAttributeType.DictionaryLink) &&
        allowedAttrIdList.includes(item?.rawData?.id)
    );

    // Проверка на наличие self-value в чек-лист аттрибутах
    argList.forEach(item => {
      if (item.type === EFormulaType.SelfValue && checklistAttribute && !isBasicAttributeODZ) {
        allowedOptionList.push({
          label: `Своё значение`,
          value: checklistAttribute.id,
          rawData: checklistAttribute,
        });
      }
    });

    return allowedOptionList;
  }, [checklistAttributeList, checklistAttribute, argList]);

  const attrType = useMemo(() => attrValue?.rawData?.attribute?.type, [attrValue]);

  useEffect(() => {
    const type = attrValue?.rawData?.attribute?.type;

    if (type === EChecklistAttributeType.DictionaryLink) {
      setIsLoading(true);

      getAllDictionariesByParams(attrValue?.rawData?.attribute.dictionaryLink, {
        remoteName: attrValue?.rawData?.attribute.dictionaryLink,
      })
        .then(data => {
          setAttrOptionList(
            data.map(item => ({ value: item.id, label: item.name, rawData: item }))
          );
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    if (type === EChecklistAttributeType.Enum) {
      setIsLoading(true);

      getChecklistAttributeEnumList({ id: attrValue?.rawData?.attribute?.id })
        .then(data => {
          setAttrOptionList(
            data.map(item => ({ value: item.id, label: item.value, rawData: item }))
          );
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [attrValue]);

  useEffect(() => {
    // Проверяем, что выбранное значение есть в списке допустимых,
    // т.к. может быть изменено извне H15-4177
    const valueInOptionList = optionList?.find(item => item?.rawData.id === attrValue?.rawData?.id);

    if (!valueInOptionList && attrValue) {
      setAttrValue(null);
      onChange(null);
    }
  }, [optionList, attrValue, value]);

  const onSelectUUID = (_value: ISelectOptionExtended) => {
    onChange(String(_value.value));
  };

  if (!isBasicAttributeODZ && (!optionList || optionList?.length === 0)) {
    return (
      <Typography sx={{ fontSize: 12, color: Colors.pink }}>
        Добавьте аргумент с атрибутом типа ENUM или DICTIONARY_LINK.
      </Typography>
    );
  }

  return (
    <Box>
      <TextField
        label="Значение константы"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        fullWidth
        disabled={isExpanded}
      />
      {!isExpanded ? (
        <Button onClick={() => setIsExpanded(true)}>Подставить из атрибута или ENUM</Button>
      ) : (
        <Button onClick={() => setIsExpanded(false)}>Ввести вручную</Button>
      )}

      {isExpanded && (
        <Box marginTop={2} data-test-id={`formula-block-constant-value-dictionary-expanded`}>
          <Autocomplete
            value={attrValue ?? null}
            isOptionEqualToValue={option => option.value === attrValue.value}
            options={optionList}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  data-test-id={`formula-block-constant-value-dictionary-input`}
                  label={'Атрибут'}
                />
              </>
            )}
            onChange={(e, option: ISelectOptionExtended<IChecklistAttribute>) =>
              setAttrValue(option)
            }
            disableClearable
            renderOption={(props, option: ISelectOptionExtended<IChecklistAttribute>) => (
              <li
                {...props}
                data-test-id={`formula-block-constant-value-dictionary-item-${option?.value}`}
              >
                <Tooltip title={option?.rawData?.description ?? ''}>
                  <Box>
                    <Typography>
                      {option.label}{' '}
                      {`(${option.rawData.attribute.type})${
                        option?.rawData?.attribute?.dictionaryLink
                          ? ` - ${option?.rawData?.attribute?.dictionaryLink}`
                          : ''
                      }`}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: Colors.grey }}>
                      {option.value}
                    </Typography>
                  </Box>
                </Tooltip>
              </li>
            )}
          />
          {(attrType === EChecklistAttributeType.DictionaryLink ||
            attrType === EChecklistAttributeType.Enum) && (
            <Box marginTop={2}>
              <Autocomplete
                options={attrOptionList}
                renderInput={params => (
                  <>
                    <TextField {...params} label={'Значение'} />
                  </>
                )}
                onChange={(e, option: ISelectOptionExtended) => onSelectUUID(option)}
                disableClearable
                loading={isLoading}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DictionarySelector;
