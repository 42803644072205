import moment from 'moment'

import { TypeAlert } from '../../../../../../../../../api/models/alert.model';
import { STATUSES } from '../../../../../../../../../constants/statuses';

export const getRows = (alerts: TypeAlert[]) => 
  alerts.map(item => ({
    createdBy: item.createdBy,
    error: item.error,
    name: item.name,
    fullName: item.fullName,
    errorDescription: item.errorDescription,
    id: item.id,
    status: STATUSES[item.status],
    taskUpdate: item.taskUpdate,
    timestamp: moment(item.timestamp).format('DD.MM.YYYY'),
  }));
