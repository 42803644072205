import { Divider, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { FC, memo, useMemo } from 'react';

import { TDictionaryItem } from '../../../../../../../../../../../../../../../../api/models/dictionaries.model';
import { TAttributeWithValue } from '../../../../../../../../../../../../../../../stores';

import ParamRowItem from './components/ParamRow/ParamRowItem';
import Styled from './styled';

interface IProps {
  validAttributeList: { [k: string]: TAttributeWithValue };
  invalidAttributeList: Record<string, any>;
  attributeValueChangeHandler: (
    value: TDictionaryItem['attrs'],
    key: string,
    removeValue?: boolean
  ) => void;
  isIndexDictionary?: boolean;
  isCreateMode?: boolean;
}

const ParamList: FC<IProps> = ({
  validAttributeList,
  invalidAttributeList,
  attributeValueChangeHandler,
  isIndexDictionary,
  isCreateMode,
}) => {
  const invalidItemList = useMemo(() => Array.from(Object.entries(invalidAttributeList || {})), [
    invalidAttributeList,
  ]);

  const validItemList = useMemo(
    () => orderBy(Array.from(Object.values(validAttributeList || {})), ['code'], ['asc']),
    [validAttributeList]
  );

  return (
    <div>
      {validItemList?.length > 0 && (
        <Styled.Grid>
          <Styled.HeadTitle>Название</Styled.HeadTitle>
          <Styled.HeadTitle>Код</Styled.HeadTitle>
          {!isIndexDictionary ? (
            <>
              <Styled.HeadTitle>Значение атрибута</Styled.HeadTitle>
              <Styled.HeadTitle>Наследование</Styled.HeadTitle>
            </>
          ) : (
            <Styled.HeadTitle>Тип</Styled.HeadTitle>
          )}
        </Styled.Grid>
      )}

      {validItemList.length
        ? validItemList.map(value => (
            <ParamRowItem
              key={value.code}
              data={value}
              onChange={attributeValueChangeHandler}
              isIndexDictionary={isIndexDictionary}
            />
          ))
        : null}
      {invalidItemList.length && !isCreateMode ? (
        <div>
          <Divider />
          <Typography>Значения без чёткой связи в атрибутах:</Typography>

          {invalidItemList.map(([key, value]) => (
            <Styled.Grid key={key}>
              <span>{key}</span>
              <span>{String(value)}</span>
            </Styled.Grid>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ParamList;
