import { isEmpty } from 'lodash';

import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import { TypeOrganizationStatus } from '../../api/models/organization.model';
import { OrganizationsStore } from '../stores/organizations/organizations.store';
import { OrganizationsTypesStore } from '../stores/organizationsTypes/organizationsTypes.store';
import { FilterStore } from '../stores/organizations/filter.store';
import OrganizationService from '../services/organization/organization.service';

@provide.singleton()
export class OrganizationsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(OrganizationsTypesStore)
  protected organizationsTypesStore: OrganizationsTypesStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  @lazyInject(OrganizationService)
  protected organizationService: OrganizationService;

  setPage = (v: number) => {
    this.organizationsStore.setPage(v);
  };

  setFilterNameOrInn = (value: string) => {
    this.organizationsStore.setPage(0);
    this.filterStore.setFilterNameOrInn(value);
  };

  setFilterStatus = (status: TypeOrganizationStatus) => {
    this.organizationsStore.setPage(0);
    this.filterStore.setFilterStatus(status);
  };

  setFilterId = (id: string) => {
    this.organizationsStore.setPage(0);
    this.filterStore.setFilterId(id);
  };

  fetchOrganizations = async () => {
    this.organizationsStore.setLoading(true);

    const payload = {
      status: !isEmpty(this.filterStore.filters.status)
        ? this.filterStore.filters.status.statusId
        : null,
      creationDate: this.filterStore.filters.creationDate
        ? this.filterStore.filters.creationDate.format('DD.MM.yyyy')
        : null,
      nameOrInn: this.filterStore.filters.nameOrInn,
      serviceProvider: this.filterStore.filters.serviceProvider,
      userId: this.filterStore.filters.userId,
      employeeId: this.filterStore.filters.employeeId,
      organizationType: this.filterStore.filters.organizationType,
      organizationIds: this.filterStore.filters.id ? [this.filterStore.filters.id] : null,
      size: this.organizationsStore.pageSize ?? 20,
      page: this.organizationsStore.page,
      sort: {
        field: 'name',
        sort: 'desc',
      },
    };

    try {
      const { content, totalPages, totalElements } = await this.axios.api.getOrganizations(payload);
      this.organizationsStore.setOrganizations(content, totalPages, totalElements);

      return content;
    } catch (e) {
      console.error('fetchOrganizations e.message', e.message);
      this.organizationsStore.clearOrganizations();
    } finally {
      this.organizationsStore.setLoading(false);
      this.organizationsStore.setLoaded(true);
    }
  };

  fetchOrganization = (
    data: TypeApiRequest<'getOrganizationById'>
  ): Promise<TypeApiResponse<'getOrganizationById'>> => {
    this.organizationsStore.setLoading(true);

    return this.axios.api
      .getOrganizationById(data, { omit: ['organizationId'] })
      .then(organization => {
        this.organizationsStore.setOrganization(organization);

        return organization;
      })
      .catch(e => {
        console.error('fetchOrganization e.message', e.message);

        return Promise.reject(e);
      })
      .finally(() => {
        this.organizationsStore.setLoading(false);
        this.organizationsStore.setLoaded(true);
      });
  };

  fetchOrganizationsTypes = async () => {
    this.organizationsTypesStore.setLoading(true);

    try {
      const organizationsTypes = await this.axios.api.getOrganizationsTypes({});
      this.organizationsTypesStore.setOrganizationsTypes(organizationsTypes);
    } catch (e) {
      console.log('fetchOrganizationsTypes e.message', e.message);
    } finally {
      this.organizationsTypesStore.setLoading(false);
      this.organizationsTypesStore.setLoaded(true);
    }
  };

  fetchOrganizationListByParams = async (payload: TypeApiRequest<'getOrganizations'>) => {
    this.organizationsStore.setLoading(true);

    try {
      const { content } = await this.axios.api.getOrganizations(payload);
      return content;
    } catch (e) {
      console.error('fetch organizations error', e.message);
      this.organizationsStore.clearOrganizations();
    } finally {
      this.organizationsStore.setLoading(false);
      this.organizationsStore.setLoaded(true);
    }
  };

  fetchUserOrganizationList = async (payload: TypeApiRequest<'getOrganizationsUserList'>) => {
    this.organizationsStore.setLoading(true);

    try {
      const response = await this.axios.api.getOrganizationsUserList(payload);
      return response;
    } catch (e) {
      console.error('fetch user organizations error', e.message);
    } finally {
      this.organizationsStore.setLoading(false);
      this.organizationsStore.setLoaded(true);
    }
  };

  setPageSize = (pageSize: number) => {
    this.organizationsStore.setPage(0);
    this.organizationsStore.setPageSize(pageSize);
  };

  updateOrganizationById = (
    organizationId: string,
    payload: Omit<TypeApiRequest<'updateOrganizationById'>, 'organizationId'>
  ) => {
    return this.organizationService.updateOrganizationById({ organizationId, ...payload });
  };
}
