import { TypeApiRoute } from '../../models/type.api.request';
import { TypeAlert } from '../../models/alert.model';

type TypeRequest = {
  alertId: string;
};
  
type TypeResponse = TypeAlert;

type TypeError = {};

export const getAlertById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/queue/task/admin/${params.alertId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
