import { styled } from '@mui/material/styles';

import { Colors } from '../../../../constants/colors';

export const Content = styled('div')`
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled('label')`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  padding: 10px 0;
  display: block;
  user-select: none;
  cursor: pointer;
  color: ${Colors.labelGray};
`;

type RowProps = {
  flexDirection?: 'column' | 'row';
};

export const Row = styled('div')<RowProps>(({ flexDirection }) => ({
  marginBottom: '15px',
  width: '100%',
  display: 'flex',
  flexDirection,
}));

export const BottomSection = styled('div')`
  height: 40px;
  width: 100%;
`;

export const RowSpaced = styled(Row)`
  margin-top: 15px;
  justify-content: space-between;
`;

export const DisabledSelection = styled('div')`
  user-select: none;
`;

export const ErrorText = styled('span')`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: ${Colors.darkRed};
`;
