import { Autocomplete, Box, Chip, InputAdornment, Stack, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { FC, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { unionBy } from 'lodash';

import { Axios, useStore } from '../../../../../../../../../shared/utils';
import { ICoreIntegrationForm, ICoreSynchronizationForm } from '../../../../models';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';

const UserSearch: FC<{
  formik: FormikProps<Partial<ICoreSynchronizationForm>>;
  config: Partial<ICoreIntegrationForm>;
}> = ({ formik, config }) => {
  const axios = useStore(Axios);

  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [selectedOptionList, setSelectedOptionList] = useState<ISelectOption[]>([]);

  const selectValue = (value: ISelectOption) => {
    setSelectedOption(null);
    setSelectedOptionList(unionBy([...selectedOptionList, value], 'id'));
    setInputValue('');
  };

  useEffect(() => {
    formik.setFieldValue('userId', selectedOptionList);
  }, [selectedOptionList]);

  const getUserList = () => {
    axios.api
      .getOrganizationsUserList({
        organizationID: config?.organizationId,
        context: inputValue,
        size: 100,
      })
      .then(data => {
        setOptionList(data.content.map(item => ({ label: item.userName, value: item.userId })));
      });
  };

  const removeOption = (value: ISelectOption) => {
    setSelectedOptionList(selectedOptionList.filter(item => item.value !== value.value));
  };

  useEffect(() => {
    getUserList();
  }, [inputValue, config?.organizationId]);

  return (
    <Box>
      <Autocomplete
        options={optionList}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Введите ФИО или id"
            label="Поиск по ФИО или id пользователя"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(v, option, reason) => {
          if (option) {
            selectValue(option as ISelectOption);
          }
        }}
        value={selectedOption as ISelectOption}
        inputValue={inputValue}
        onInputChange={(e, changedInputValue) => setInputValue(changedInputValue)}
        disablePortal
      />

      <Box minHeight={40} maxHeight={200}>
        <Stack direction={'row'} flexWrap={'wrap'} gap={1} marginTop={1} marginBottom={2}>
          {selectedOptionList.map(item => (
            <Chip key={item.value} label={item.label} onDelete={() => removeOption(item)} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default UserSearch;
