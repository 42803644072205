import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  & > .context-menu-button {
    visibility: hidden;
  }

  &:hover > .context-menu-button {
    visibility: visible;
  }
`;

const Styled = {
  TitleWrapper,
};

export default Styled;
