import { observer } from 'mobx-react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Colors } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../shared/utils';
import { EnumDependencyController, EnumDependencyStore } from '../../mobx';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import { EEnumDepsTab } from '../../models';

interface IProps {
  value: ISelectOptionExtended;
  onChange: (value: ISelectOptionExtended) => void;
  type: EEnumDepsTab;
}

const DependAttribute: FC<IProps> = ({ value, onChange, type }) => {
  const store = useStore(EnumDependencyStore);
  const controller = useStore(EnumDependencyController);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [listboxNode, setListboxNode] = useState<HTMLDivElement>(null);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (listboxNode) {
      listboxNode.scrollTop = position;
    } else {
      setPage(0);
    }
  }, [position, listboxNode]);

  const handleScroll = event => {
    if (event.currentTarget) {
      setListboxNode(event.currentTarget);
    }

    if (!listboxNode) {
      return;
    }

    const y = listboxNode?.scrollTop + listboxNode?.clientHeight;

    if (listboxNode.scrollHeight - y <= 1) {
      setPosition(y);
      setPage(page + 1);
      controller.fetchActiveEnumAttributeList(page + 1);
    }
  };

  const onSelectValue = (e, v: ISelectOptionExtended) => {
    if (typeof v === 'object') {
      onChange(v);

      store.setSelectedEnum(v);
    }
  };

  const optionList = useMemo<ISelectOptionExtended[]>(
    () =>
      store.activeEnumList?.map(item => ({ value: item?.id, label: item?.name, rawData: item })),
    [store.activeEnumList]
  );

  const title = type === EEnumDepsTab.Depends ? 'Зависимый атрибут' : 'От кого зависит';

  return (
    <Box width={'30%'}>
      <Autocomplete
        options={optionList}
        value={value}
        disablePortal
        fullWidth
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{
          // onScroll: handleScroll,
          role: 'list-box',
        }}
        renderOption={(props, option) => (
          <li {...props} key={String(option.value)}>
            <Box>
              <Typography>{option.label}</Typography>
              <Typography sx={{ fontSize: 12, color: Colors.grey }}>
                {option.rawData.description}
              </Typography>
            </Box>
          </li>
        )}
        onChange={onSelectValue}
      />
    </Box>
  );
};

export default observer(DependAttribute);
