import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { ChecklistInstanceTable } from '../components';
import { mapPointModalConfig } from '../modals';

const OrgIntegraCropwiseChecklistInstances = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList([mapPointModalConfig], 'OrgIntegraCropwiseChecklistInstances');
  }, []);

  return (
    <Box>
      <ChecklistInstanceTable />
    </Box>
  );
};

export default OrgIntegraCropwiseChecklistInstances;
