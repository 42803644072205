import { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Snackbar, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface SnackbarProps {
  open: boolean;
  autoHideDuration: number;
  onClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
  action?: ReactElement;
}

export const SnackbarComponent: FC<SnackbarProps> = observer(
  ({
    open,
    autoHideDuration,
    onClose,
    message,
    action = (
      <>
        <Button color="secondary" size="small" onClick={onClose}>
          UNDO
        </Button>
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    ),
  }) => {
    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={message}
        action={action}
      />
    );
  }
);
