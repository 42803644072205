import { Autocomplete, TextField } from '@mui/material';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import {
  EFormulaFunctionAggregation,
  EFormulaReturnType,
  IFormulaError,
} from '../../../../../../models';
import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { EErrorCollection, errorCollection } from '../../../../../../configs';
import { HighlightBox } from '../../../../../../../../../../../shared/components';
import { formulaFunctionScheme } from '../../../../../../helpers';

interface IProps {
  type: EFormulaReturnType;
  allowedOptionList?: EFormulaReturnType[];
  selectedFunction?: EFormulaFunctionAggregation;
  onSelectReturnType: (ISelectOption) => void;
  clientId: string;
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const RETURN_TYPE_LIST: ISelectOption[] = [
  { label: EFormulaReturnType.Boolean, value: EFormulaReturnType.Boolean },
  { label: EFormulaReturnType.Date, value: EFormulaReturnType.Date },
  { label: EFormulaReturnType.Double, value: EFormulaReturnType.Double },
  { label: EFormulaReturnType.Integer, value: EFormulaReturnType.Integer },
  { label: EFormulaReturnType.Object, value: EFormulaReturnType.Object },
  { label: EFormulaReturnType.String, value: EFormulaReturnType.String },
  { label: EFormulaReturnType.Uuid, value: EFormulaReturnType.Uuid },
];

const FormulaReturnType: FC<IProps> = ({
  type,
  allowedOptionList,
  onSelectReturnType,
  selectedFunction,
  handleRemoveError,
  handleSetError,
}) => {
  const [error, setError] = useState<{ text: string; id: string }>(null);
  const [uuid] = useState(v4());

  const ref = useRef();

  const availableOptionList = useMemo<ISelectOption[]>(
    () => allowedOptionList?.map(item => ({ label: item, value: item })),
    [allowedOptionList]
  );

  const formulaFunctionConfig = formulaFunctionScheme.get(selectedFunction);

  useEffect(() => {
    if (!type) {
      handleSetError?.(
        {
          text: errorCollection.MISSING_RETURN_TYPE,
          errorType: EErrorCollection.MISSING_RETURN_TYPE,
          HTMLElementId: `${uuid}-returnType`,
        },
        uuid
      );
      return;
    } else {
      handleRemoveError(uuid);
      handleRemoveError?.(`${uuid}-returnType`);
      setError(null);
    }

    if (
      (type && allowedOptionList?.length && !allowedOptionList?.includes(type)) ||
      (formulaFunctionConfig?.returnType && !formulaFunctionConfig?.returnType.includes(type))
    ) {
      handleSetError?.(
        {
          text: errorCollection.WRONG_TYPE,
          errorType: EErrorCollection.WRONG_TYPE,
          HTMLElementId: `${uuid}-returnType`,
        },
        uuid
      );

      setError({ id: uuid, text: errorCollection.WRONG_TYPE });
    } else {
      if (!error) {
        return;
      }

      handleRemoveError?.(uuid);
      handleRemoveError?.(`${uuid}-returnType`);
      setError(null);
    }
  }, [type, selectedFunction, allowedOptionList]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(`${uuid}-returnType`);
      handleRemoveError(uuid);
    };
  }, [error]);

  return (
    <HighlightBox width={250} ref={ref} id={`${uuid}-returnType`}>
      <Autocomplete
        disableClearable
        value={type ?? null}
        isOptionEqualToValue={(option, value) => option.value === type}
        options={availableOptionList || RETURN_TYPE_LIST || []}
        onChange={(e, value: ISelectOption) => {
          onSelectReturnType(value.value);
        }}
        data-test-id={`formula-block-return-type`}
        renderInput={params => (
          <>
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error?.text}
              label={'Тип возвращаемого значения'}
              data-test-id={`formula-block-return-type-input`}
            />
          </>
        )}
      />
    </HighlightBox>
  );
};

export default memo(FormulaReturnType);
