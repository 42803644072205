import { IAgropogodaConfig } from '../../../models/agropogoda';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = IAgropogodaConfig;
type TypeResponse = IAgropogodaConfig;

export const createAgropogodaReportsConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agropogoda/owners`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
