import { ChangeEvent, FC } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';

import { IOrganizationIntegraTableFilterItemNumber } from '../../../../../types/organizationIntegraTable.types';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrgIntegraTableFilterStore } from '../../../mobx';

const OrgIntegraTableFilterItemNumber: FC<{
  item: IOrganizationIntegraTableFilterItemNumber;
  id: string;
}> = ({ item, id }) => {
  const { getComponentValue, addComponentValue, removeComponentValue } = useStore(
    OrgIntegraTableFilterStore
  );

  const value = getComponentValue(item.propertyName)?.value;

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.validity.valid || event.target.value === 'e') {
      return;
    }

    if (!event.target.value || event.target.value === '') {
      removeComponentValue(item.propertyName);
      return;
    }

    addComponentValue(item.propertyName, {
      value: event.target.value,
      type: item.type,
      propertyName: item.propertyName,
    });
  };

  const onBlur = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value) {
      removeComponentValue(item.propertyName);
      event.target.value = '';
    }
  };

  return (
    <TextField
      label={item.label}
      value={value || ''}
      onChange={onChange}
      placeholder={item.placeholder}
      onBlur={onBlur}
      key={id}
      type="number"
      fullWidth
    />
  );
};

export default observer(OrgIntegraTableFilterItemNumber);
