import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { sortBy } from 'lodash';

import { EFilterFormActivity, EFilterFromType } from '../../../../types/filterForm.types';
import { useSidebarListing } from '../../helpers/hooks';
import { IReference } from '../../../../../../../../api/models/reference.model';
import { useStore } from '../../../../../../../shared/utils';
import { ReferenceStore } from '../../mobx';

import Styled from './styled';

const ReferenceSidebar = () => {
  const {
    filterState,
    updateState,
    clearForm,
    submitFilter,
    referenceList,
    isAllowedLoadMore,
    isLoading,
    setActiveRef,
  } = useSidebarListing();

  const { activeReference } = useStore(ReferenceStore);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const loadMore = () => {
    if (isLoading) {
      return;
    }

    submitFilter();
  };

  const redirectToRef = (ref: IReference) => {
    setActiveRef(ref.path, () => {
      const url = new URL(window.location.href);
      url.searchParams.delete('path');
      url.searchParams.append('path', ref.path);

      window.history.pushState({}, '', url);
    });
  };

  useEffect(() => {
    loadMore();

    const url = new URL(window.location.href);
    const path = url.searchParams.get('path');

    if (path) setActiveRef(path, null);
  }, []);

  const sortedRefList = useMemo(() => sortBy(referenceList, 'path'), [referenceList]);

  return (
    <Styled.SidebarWrapper>
      <Button
        onClick={() => setIsFilterActive(!isFilterActive)}
        fullWidth
        startIcon={isFilterActive ? <FilterAltOffIcon /> : <FilterAltIcon />}
      >
        Фильтры
      </Button>

      {isFilterActive ? (
        <Styled.FilterForm onSubmit={e => submitFilter(e, null, true)}>
          <TextField
            label="Путь"
            value={filterState?.path ?? ''}
            onChange={event => {
              updateState({ path: event.target.value });
            }}
            fullWidth
          />

          <TextField
            label="Путь до родителя"
            value={filterState?.parentPath ?? ''}
            onChange={event => {
              updateState({ parentPath: event.target.value });
            }}
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel id="activity-label">Активность</InputLabel>
            <Select
              labelId="activity-label"
              value={filterState?.activity || EFilterFormActivity.None}
              onChange={e => updateState({ activity: e.target.value as EFilterFormActivity })}
              label="Активность"
            >
              <MenuItem value={EFilterFormActivity.Active}>Активный</MenuItem>
              <MenuItem value={EFilterFormActivity.Inactive}>Неактивный</MenuItem>
              <MenuItem value={EFilterFormActivity.None}>Не важно</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="type-label">Тип</InputLabel>
            <Select
              labelId="type-label"
              value={filterState?.type || EFilterFromType.All}
              onChange={e => updateState({ type: e.target.value as EFilterFromType })}
              label="Тип"
            >
              <MenuItem value={EFilterFromType.Tips}>Типс</MenuItem>
              <MenuItem value={EFilterFromType.Modal}>Модальное окно</MenuItem>
              <MenuItem value={EFilterFromType.All}>Все</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <>
                <Switch
                  checked={Boolean(filterState?.empty)}
                  onChange={(e, checked) => {
                    updateState({
                      empty: checked,
                      ...(checked && { activity: EFilterFormActivity.None }),
                    });
                  }}
                />
              </>
            }
            label="Только незаполненные"
          />

          <Box display="flex">
            <Button type="submit" fullWidth variant="contained">
              Поиск
            </Button>
            <Tooltip title={'Сбросить фильтр'}>
              <IconButton onClick={clearForm}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Styled.FilterForm>
      ) : null}
      <Styled.RefList>
        {sortedRefList?.length > 0 ? (
          sortedRefList?.map(item => (
            <Styled.Item
              key={item.path}
              onClick={() => redirectToRef(item)}
              $selected={item?.path === activeReference?.path}
              disablePadding
            >
              <Styled.ItemContent alignItems="flex-start">
                <Box display="flex" flexDirection="row" gap={1} alignItems={'center'}>
                  <Tooltip title={item?.active ? 'Активен' : 'Неактивен'}>
                    {item?.active ? <DoneIcon color="primary" /> : <CloseIcon color="error" />}
                  </Tooltip>

                  {`Тип: ${item.type}`}
                </Box>
                <ListItemText
                  sx={{ lineBreak: 'anywhere' }}
                  primary={item.path}
                  secondary={item?.description}
                />
              </Styled.ItemContent>
            </Styled.Item>
          ))
        ) : (
          <Typography marginLeft={2}>Нет результатов поиска</Typography>
        )}

        {}
      </Styled.RefList>
    </Styled.SidebarWrapper>
  );
};

export default observer(ReferenceSidebar);
