export const downloadBlobAsFile = (blob: Blob, filename?: string) => {
  try {
    const url = URL.createObjectURL(blob as Blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'file.csv';

    a.style.display = 'none';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
  } catch (error) {
    console.error(error);
  }
};
