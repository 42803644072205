import { observer } from 'mobx-react';
import { Autocomplete, InputAdornment, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationInvitesStore } from '../../mobx';
import { OrganizationInvitesController } from '../../mobx/controllers';
import { STATUS_LIST } from '../../mobx/stores/OrganizationInviteStore/OrganizationInvite.store';

import Styled from './OrganizationInvitesFilter.styles';

const OrganizationInvitesFilter = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const store = useStore(OrganizationInvitesStore);

  const controller = useStore(OrganizationInvitesController);

  const [isRolesFetched, setIsRolesFetched] = useState(false);

  useEffect(() => {
    controller.fetchRoleValueList(organizationId).finally(() => setIsRolesFetched(true));

    return () => {
      store.clearStore();
    };
  }, []);

  const debouncedDataFetch = useCallback(
    debounce(() => {
      controller.fetchInviteList(organizationId, 'dateCreated,desc');
    }, 200),
    [organizationId]
  );

  useEffect(() => {
    if (isRolesFetched) {
      debouncedDataFetch();
    }
  }, [
    organizationId,
    isRolesFetched,
    store.nameFilterValue,
    store.roleFilterValue,
    store.statusFilterValue,
    store.page,
    store.pageSize,
  ]);

  return (
    <div>
      <Styled.Row>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Иванов Иван Иванович"
          label="Поиск по ФИО"
          value={store.nameFilterValue}
          onChange={controller.changeNameFilter}
          size="small"
          fullWidth
          sx={{ minHeight: '46px' }}
        />

        <Styled.SelectWrapper>
          <TextField
            name="status"
            defaultValue={store.statusFilterValue?.label}
            onChange={controller.changeStatusFilter}
            select
            fullWidth
            label="Статус"
            placeholder="Статус"
            size="small"
          >
            {STATUS_LIST.map(item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Styled.SelectWrapper>
        <Styled.SelectWrapper>
          <Autocomplete
            options={store.roleValueList}
            onChange={controller.changeRoleFilter}
            renderInput={params => <TextField {...params} label={'Роль'} />}
          />
        </Styled.SelectWrapper>
      </Styled.Row>
    </div>
  );
};

export default observer(OrganizationInvitesFilter);
