import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../../shared/utils';
import { EEnumDepsTab } from '../../models';
import { IEnumDependencyLink } from '../../../../../../../../../../../../../api/models/enumDependency.model';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';
import { IChecklistAttributeEnumItem } from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';

@provide.singleton()
class EnumDependencyStore {
  private _depCollection: Map<EEnumDepsTab, IEnumDependencyLink[]> = new Map();

  private _selectedEnum: ISelectOptionExtended = null;

  private _activeEnumList: IChecklistAttribute[] = [];
  private _currentEnumItemList: IChecklistAttributeEnumItem[] = [];
  private _selectedEnumItemList: IChecklistAttributeEnumItem[] = [];

  private _isDepLinkListLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isDepLinkListLoading() {
    return this._isDepLinkListLoading;
  }

  get depCollection() {
    return this._depCollection;
  }

  get activeEnumList() {
    return this._activeEnumList;
  }

  getDepCollectionByType = (type: EEnumDepsTab) => {
    return this._depCollection.get(type) ?? [];
  };

  get currentEnumItemList() {
    return this._currentEnumItemList;
  }

  get selectedEnumItemList() {
    return this._selectedEnumItemList;
  }

  get selectedEnum() {
    return this._selectedEnum;
  }

  setIsDepLinkListLoading = (state: boolean) => {
    this._isDepLinkListLoading = state;
  };

  setDepCollectionByType = (type: EEnumDepsTab, data: IEnumDependencyLink[]) => {
    this._depCollection.set(type, data);
  };

  setActiveEnumList = (data: IChecklistAttribute[]) => {
    this._activeEnumList = data;
  };

  setCurrentEnumItemList = (list: IChecklistAttributeEnumItem[]) => {
    this._currentEnumItemList = list;
  };

  setSelectedEnumItemList = (list: IChecklistAttributeEnumItem[]) => {
    this._selectedEnumItemList = list;
  };

  setSelectedEnum = (item: ISelectOptionExtended) => {
    this._selectedEnum = item;
  };

  clearDepCollection = () => {
    this._depCollection = new Map();
  };

  clearActiveEnumList = () => {
    this._activeEnumList = [];
  };

  clearCurrentEnumItemList = () => {
    this._currentEnumItemList = [];
  };

  clearSelectedEnumItemList = () => {
    this._selectedEnumItemList = [];
  };

  clearSelectedEnum = () => {
    this._selectedEnum = null;
  };

  clearOptions = () => {
    this.clearSelectedEnumItemList();
    this.clearSelectedEnum();

    this._isDepLinkListLoading = false;
  };

  clearStore = () => {
    this.clearDepCollection();
    this.clearActiveEnumList();
    this.clearCurrentEnumItemList();
    this.clearSelectedEnumItemList();
    this.clearSelectedEnum();
  };
}

export default EnumDependencyStore;
