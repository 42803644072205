import moment from 'moment';
import _ from 'lodash';
import { v4 } from 'uuid';

import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import { IFieldReportConfig } from '../../../../../../../../../../../api/models/reportConfig.model';
import { AssistanceReportService } from '../../../../../../../../../../services/reports/assistance/assistanceReports.service';
import { OrganizationReportAssistanceFieldsStore } from '../../stores';

@provide.transient()
class AssistanceFieldReportController {
  @lazyInject(OrganizationReportAssistanceFieldsStore)
  store: OrganizationReportAssistanceFieldsStore;

  @lazyInject(AssistanceReportService)
  reportService: AssistanceReportService;

  getReportsConfig = async (orgId: string): Promise<IFieldReportConfig | false> => {
    this.store.isLoading = true;

    const response = await this.reportService.getAssistanceFieldReportConfig({
      organizationId: orgId,
    });

    if (!response) {
      this.store.isLoading = false;
      return false;
    }

    response.content.forEach(config => {
      this.store.setConfigById(config.id, { ...config, state: 'update' });
    });

    this.store.isLoading = false;

    return response.content[0];
  };

  createReportConfig = async (organizationId: string, config: IFieldReportConfig) => {
    const response = await this.reportService.createAssistanceFieldReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    if (response) {
      this.store.deleteConfigById(config.id);
    }

    return response;
  };

  createNewConfigPlaceholder = () => {
    const id = v4();

    this.store.setConfigById(id, {
      seasons: [],
      reportsLocation: '',
      enabled: false,
      state: 'create',
      id,
    });
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<IFieldReportConfig>,
    defaultConfig: Partial<IFieldReportConfig>
  ) => {
    const changes: Partial<IFieldReportConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    const payload = {
      id: configId,
      ...changes,
    };

    const response = await this.reportService.updateAssistanceFieldReportConfigBy(payload);

    return response;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleAssistanceFieldReportAutoUpload(
      configId,
      state
    );

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteAssistanceFieldReportConfigBy({ id: configId });

    this.store.deleteConfigById(configId);

    return response;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: IFieldReportConfig
  ): TypeApiRequest<'createAssistanceFieldReportsConfig'> => {
    const output: TypeApiRequest<'createAssistanceFieldReportsConfig'> = {
      organizationId,
      ...config,
    };

    return output;
  };
}

export default AssistanceFieldReportController;
