import { ISeason } from '../../../../../../../../api/models/season.model';

export const calcActualSeason = (seasons: ISeason[]): number | null => {
  if (seasons.length === 0) return null;

  const [firstSeason] = seasons;
  const lastSeason = seasons[seasons.length - 1];

  const currentYear = new Date().getFullYear();

  if (seasons.find(({ year }) => year === currentYear)) {
    return currentYear;
  }

  if (lastSeason?.year && lastSeason?.year > currentYear) {
    const season = seasons.find(({ year }) => year > currentYear);
    return season?.year;
  }

  if (firstSeason?.year && firstSeason?.year < currentYear) {
    const season = seasons.find(({ year }) => year < currentYear);
    return season?.year;
  }

  return null;
};
