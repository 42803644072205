import { IconButton, Switch, SwitchProps } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IProps extends SwitchProps {
  value: boolean;
  onChangeHandler: (value: boolean) => void;
}

const BooleanField = ({ value, onChangeHandler }: IProps) => {
  const [isChecked, setIsChecked] = useState(value);

  const onChange = (event, checked) => {
    setIsChecked(checked);
    onChangeHandler(checked);
  };

  return (
    <div>
      <Switch checked={isChecked} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />
      {isChecked ? (
        <IconButton
          size="small"
          onClick={() => {
            setIsChecked(false);
            onChangeHandler(null);
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default BooleanField;
