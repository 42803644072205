import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.transient()
class ZondService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchDeviceList = async (payload?: TypeApiRequest<'getZondList'>) => {
    try {
      const response = await this.axios.api.getZondList(payload, {
        omit: ['page', 'size'],
        query: { page: payload?.page ?? 0, size: payload?.size ?? 100 },
      });

      return response;
    } catch (error) {
      console.log('fetch zond list error', error);
    }
  };

  setDeviceOwner = async (payload: TypeApiRequest<'setOwnerZond'>) => {
    try {
      const response = await this.axios.api.setOwnerZond(payload);

      return response;
    } catch (error) {
      console.log('set zond owner error', error);
    }
  };

  removeDeviceOwner = async (payload: TypeApiRequest<'removeOwnerZond'>) => {
    try {
      const response = await this.axios.api.removeOwnerZond(payload);

      return response;
    } catch (error) {
      console.log('remove zond owner error', error);
    }
  };
}

export default ZondService;
