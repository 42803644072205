import { Box } from '@mui/material';

import { AttributeTable } from '../components';

const OrgIntegraCropwiseAttributes = () => {
  return (
    <Box>
      <AttributeTable />
    </Box>
  );
};

export default OrgIntegraCropwiseAttributes;
