import { TypeApiRoute } from '../../models/type.api.request';
import {
  EOrganizationStatus,
  TypeOrganization,
  TypeOrganizationSort,
} from '../../models/organization.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  status?: EOrganizationStatus;
  creationDate?: string;
  nameOrInn?: string;
  serviceProvider?: boolean;
  userId?: string;
  employeeId?: string;
  organizationType?: string;
  size?: number;
  page?: number;
  sort?: TypeOrganizationSort;
};

type TypeResponse = { content: TypeOrganization[] } & TypeResponsePageable;

type TypeError = {};

export const getOrganizations: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/organizations/admin/search?page=${request.page}&size=${request.size}&sort=${
      request.sort ? request.sort.field : 'name'
    },${request.sort ? request.sort.sort : 'desc'}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
