import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const LayoutWrapper = styled('div')`
  flex-grow: 1;
`;

export const Main = styled(Box)`
  flex-grow: 1;
`;

export const LayoutContent = styled(Box)`
  display: flex;
`;
