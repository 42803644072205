import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../constants/colors';

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
`;

const DictionaryIndexLink = styled(Link)`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  padding: 6px 0;
  padding-left: 14px;
  color: ${Colors.black};
`;

const Styled = {
  ActionGroup,
  DictionaryIndexLink,
};

export default Styled;
