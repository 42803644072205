import { CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, useEffect, useMemo, useState } from 'react';

import { ChecklistsAttributeController } from '../../../../../../../../../../controllers/checklistAttribute.controller';
import { useStore } from '../../../../../../../../../../shared/utils';

interface IProps {
  attributeId?: string;
}

interface IChecklistPair {
  id: string;
  name: string;
}

const ChecklistList: FC<IProps> = ({ attributeId }) => {
  const { getChecklistAttributeList } = useStore(ChecklistsAttributeController);
  const [checklistList, setChecklistList] = useState<IChecklistPair[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (attributeId) {
      setIsLoading(true);
      getChecklistAttributeList({ attributeId })
        .then(data => {
          setChecklistList(
            data.content.map(item => ({ id: item.checkListId, name: item.checkList.name }))
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [attributeId]);

  const columns = useMemo<GridColDef<IChecklistPair>[]>(
    () => [
      { field: 'name', headerName: 'Название чек-листа', flex: 1 },
      { field: 'id', headerName: 'ID чек-листа', flex: 1 },
    ],
    []
  );

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ display: 'flex', height: '100%', minHeight: '500px' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={checklistList}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              localeText={{ noRowsLabel: 'Нет результатов' }}
              // onRowClick={handleRowClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChecklistList;
