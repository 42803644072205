import { CircularProgress, Snackbar, Typography } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ApiNames, useStore } from '../../../../../shared/utils';

import { IAdvanceGridConfig } from './AdvancedGrid.type';
import { AdvancedGridStore } from './mobx';

interface IProps<T extends ApiNames> {
  gridConfig: IAdvanceGridConfig<T>;
}

const DEFAULT_PAGE_SIZE = 20;

const AdvancedGrid: FC<IProps<ApiNames>> = observer(({ gridConfig }) => {
  const {
    getGridMapItem,
    isLoading,
    setIsLoading,
    currentGridFilterValues,
    clearFilters,
  } = useStore(AdvancedGridStore);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [size, setSize] = useState(DEFAULT_PAGE_SIZE);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const columns = useMemo(() => getGridMapItem(gridConfig.gridId)?.gridColumns || [], [
    gridConfig.gridId,
  ]);

  const transformMap = useMemo(() => {
    const filterTransformer = new Map<string, (value: any) => any>();

    getGridMapItem(gridConfig.gridId)?.filter.forEach(filterItem => {
      if (filterItem.filterValueTransform) {
        filterTransformer.set(filterItem.key, filterItem.filterValueTransform);
      }
    });

    return filterTransformer;
  }, [gridConfig.gridId]);

  const valueTransformer = useCallback(
    (filterValues: typeof currentGridFilterValues) => {
      const preTransformedValues = { ...filterValues };

      Object.entries(filterValues).forEach(([key, value]) => {
        if (transformMap.has(key)) {
          preTransformedValues[key] = transformMap.get(key)(value);
        }
      });

      return preTransformedValues;
    },
    [transformMap]
  );

  const handleCloseSnackbar = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setShowSnackbar(false);
    },
    []
  );

  useEffect(() => {
    if (isLoading || isTableLoading) {
      const preparedFilterData =
        transformMap.size > 0 ? valueTransformer(currentGridFilterValues) : currentGridFilterValues;

      gridConfig
        .dataFetchHandler({ ...preparedFilterData, page, size })
        .then(data => {
          setRows(data.content);
          setRowCount(data.totalElements);
        })
        .catch(() => {
          setSnackbarMessage('Ошибка в получении данных.');
          setShowSnackbar(true);
        })
        .finally(() => {
          setIsLoading(false);
          setIsTableLoading(false);
        });
    }
  }, [gridConfig.gridId, isLoading, isTableLoading]);

  useEffect(() => {
    setPage(0);
  }, [currentGridFilterValues]);

  const clearPageState = useCallback((clearSize?: boolean) => {
    setPage(0);
    setRowCount(0);
    if (clearSize) {
      setSize(DEFAULT_PAGE_SIZE);
    }
  }, []);

  useEffect(() => {
    if (rows.length === 0) {
      clearPageState();
    }
  }, [rows.length]);

  useEffect(() => {
    clearFilters();

    return () => {
      clearPageState(true);
    };
  }, []);

  return (
    <div style={{ display: 'flex', height: '95%' }}>
      <div style={{ flexGrow: 1 }}>
        {isLoading ? (
          <CircularProgress />
        ) : rows.length > 0 ? (
          <DataGrid
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={params => {
              if (gridConfig?.onSelectRow) gridConfig?.onSelectRow?.(params.id);
            }}
            loading={isTableLoading}
            columns={columns}
            rows={rows}
            page={page}
            rowCount={rowCount}
            pageSize={size}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={newPage => {
              setPage(newPage);
              setIsTableLoading(true);
            }}
            onPageSizeChange={newPageSize => {
              setSize(newPageSize);
              setIsTableLoading(true);
            }}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            density="compact"
            pagination
            paginationMode="server"
          />
        ) : (
          <Typography>По вашему запросу ничего не найдено</Typography>
        )}
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
});

export default AdvancedGrid;
