import { FC, useEffect, useMemo, useCallback, useState, ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  DataGrid,
  GridRowParams,
  GridActionsCellItem,
  GridRowId,
  GridRenderCellParams,
  ruRU,
} from '@mui/x-data-grid';
import { Container, Tooltip, Snackbar, Alert, Grid } from '@mui/material';
import Reply from '@mui/icons-material/Reply';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Check from '@mui/icons-material/Check';
import { Chip, Colors } from '@farmlink/farmik-ui';

import { EEmployeeStatus, TypeEmployee } from '../../../../../../../../api/models/employee.model';
import { useStore, getLink } from '../../../../../../../shared/utils';
import { EmployeeController } from '../../../../../../../controllers/employees.controller';
import { EmployeeStore } from '../../../../../../../stores/employee/employee.store';
import { FilterStore } from '../../../../../../../stores/employee/filter.store';
import { getColumns, getRows } from '../../utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';
import { BlockEmployeeDialog } from '../BlockEmployeeDialog/BlockEmployeeDialog';
import { EUserAdminPhoneStatus, Phone } from '../../../../../../../../api/models/user.admin.model';

import * as Styled from './EmployeesTable.styles';

export const EmployeesTable: FC = observer(() => {
  const history = useHistory();

  const { fetchEmployees, setPage, setPageSize } = useStore(EmployeeController);
  const {
    employees,
    error,
    showError,
    page,
    isLoading,
    pageSize,
    totalElements,
    setShowError,
    setError,
  } = useStore(EmployeeStore);
  const {
    filters: { fullname, status, role },
  } = useStore(FilterStore);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [currentEmployee, setCurrentEmployee] = useState<TypeEmployee>(null);
  const [showUnlockEmployeeModal, setShowUnlockEmployeeModal] = useState(false);
  const [showLockEmployeeModal, setShowLockEmployeeModal] = useState(false);

  useEffect(() => {
    fetchEmployees({ organizationID: organizationId });
  }, [page, fullname, status, role, pageSize]);

  const handleGoToUserPage = useCallback(
    (id: GridRowId) => () => {
      history.push(getLink(AdminRoutes.UserEdit, { [id]: ERegexp.USERID }));
    },
    []
  );

  const handleCloseUnlockEmployeeModal = () => {
    setShowUnlockEmployeeModal(false);
  };

  const handleCloseLockEmployeeModal = () => {
    setShowLockEmployeeModal(false);
  };

  const handleShowDialog = useCallback(
    (user: TypeEmployee) => () => {
      setCurrentEmployee(user);

      if (user.status === EEmployeeStatus.ACTIVE) {
        setShowLockEmployeeModal(true);
      } else {
        setShowUnlockEmployeeModal(true);
      }
    },
    []
  );

  const handleCloseError = () => {
    setShowError(false);
    setError('');
  };

  const renderActions = (param: GridRowParams<TypeEmployee>) => {
    return [
      <Tooltip title="Подробнее">
        <GridActionsCellItem
          icon={<Reply />}
          label="Подробнее"
          onClick={handleGoToUserPage(param.row.userId)}
        />
      </Tooltip>,
      <Tooltip
        title={param?.row.status === EEmployeeStatus.ACTIVE ? 'Заблокировать' : 'Разблокировать'}
      >
        <GridActionsCellItem
          icon={
            param?.row.status === EEmployeeStatus.ACTIVE ? (
              <LockOpen color="primary" />
            ) : (
              <Lock color="error" />
            )
          }
          label="Подробнее"
          onClick={handleShowDialog(param.row)}
        />
      </Tooltip>,
    ];
  };

  const renderStatus = (params: GridRenderCellParams<EEmployeeStatus>) => {
    if (!params.value) {
      return <span />;
    }

    const color = params.value === EEmployeeStatus.ACTIVE ? 'primary' : 'secondary';
    const label = params.value === EEmployeeStatus.ACTIVE ? 'Активен' : 'Исключен';

    return (
      <Chip size="medium" color={color}>
        {label}
      </Chip>
    );
  };

  const renderPhone = (params: GridRenderCellParams<Phone>): ReactElement => {
    if (!params.value) {
      return <span />;
    }

    const phone = params.value.phoneNumberCode + params.value.phoneNumber;

    return (
      <Grid container alignItems="center" flexWrap="nowrap">
        {params.value.phoneNumberStatus.statusId === EUserAdminPhoneStatus.CONFIRMED ? (
          <div>
            <Tooltip title="Номер подтверждён">
              <Check color="primary" />
            </Tooltip>
          </div>
        ) : null}
        <Styled.PhoneLink href={`tel:${phone}`}>{phone}</Styled.PhoneLink>
      </Grid>
    );
  };

  const rows = useMemo(() => getRows(employees), [employees]);

  const columns = useMemo(() => getColumns(renderActions, renderStatus, renderPhone), [employees]);

  const rowsPerPageOptions = [10, 20, 50, 100];

  return (
    <Container disableGutters maxWidth={false}>
      <Styled.GridWrapper>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          rows={rows}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          onPageSizeChange={param => setPageSize(param)}
          loading={isLoading}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pageSize={pageSize}
          rowCount={totalElements}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Styled.GridWrapper>

      <BlockEmployeeDialog
        employee={currentEmployee}
        status={EEmployeeStatus.ACTIVE}
        open={showLockEmployeeModal}
        onClose={handleCloseLockEmployeeModal}
        message={`Вы уверены, что хотите заблокировать ${currentEmployee?.userName}?`}
        successMessage="Пользователь заблокирован"
        actionButtonName="Заблокировать"
        dataTestId="block-user-dialog"
      />

      <BlockEmployeeDialog
        employee={currentEmployee}
        status={EEmployeeStatus.FIRED}
        open={showUnlockEmployeeModal}
        onClose={handleCloseUnlockEmployeeModal}
        message={`Вы уверены, что хотите разблокировать ${currentEmployee?.userName}?`}
        successMessage="Пользователь разблокирован"
        actionButtonName="Разблокировать"
        dataTestId="unlock-user-dialog"
      />

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseError} severity="error" variant="filled" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
});
