import { CalendarComponent } from '@farmlink/farmik-ui';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useCallback, useMemo } from 'react';

import { TypeBasis } from '../../../../../../../../api/models/basis.model';
import { TypeCulture } from '../../../../../../../../api/models/culture.model';
import { TypeCultureClass } from '../../../../../../../../api/models/cultureClass.model';
import { TypeEconomicRegion } from '../../../../../../../../api/models/economicRegion.model';
import { TypeProvider } from '../../../../../../../../api/models/provider.model';
import { TypeUnit } from '../../../../../../../../api/models/units.model';
import { useStore } from '../../../../../../../shared/utils';
import {
  AddQuotesStore,
  TypeQuoteSimple,
} from '../../../../../../../stores/quotes/add.quotes.store';
import { notSelectedOption } from '../../../QuotesList/components/QuotesFilter';
import binIcon from '../../../../assets/images/bin.svg';

import {
  BinIcon,
  DeleteBlock,
  DeleteLabel,
  Header,
  NewQuoteBlockTitle,
  NewQuoteBlockWrapper,
  StyledGridItem,
} from './NewQuoteBlock.style';

export type NewQuotesBlockProps = {
  quote: TypeQuoteSimple;
  index: number;
  allowDeleteAlways: boolean;
};

export const NewQuoteBlock: FC<NewQuotesBlockProps> = observer(
  ({ quote, index, allowDeleteAlways }) => {
    const quotesAddStore = useStore(AddQuotesStore);

    const cultureValue = useMemo(() => {
      const result = Boolean(quote.cultureId)
        ? {
            id: quote.cultureId,
            name: quotesAddStore.cultureList.find(item => item.id === quote.cultureId)?.name,
          }
        : notSelectedOption;
      return result;
    }, [quote.cultureId, quotesAddStore.cultureList]);

    const cultureClassValue = useMemo(
      () =>
        Boolean(quote.cultureClassId)
          ? {
              id: quote.cultureClassId,
              name: quotesAddStore.cultureClassList.find(item => item.id === quote.cultureClassId)
                ?.name,
            }
          : notSelectedOption,
      [quote.cultureClassId, quotesAddStore.cultureClassList]
    );

    const basisValue = useMemo(
      () =>
        Boolean(quote.basisId)
          ? {
              id: quote.basisId,
              name: quotesAddStore.basisList.find(item => item.id === quote.basisId)?.name,
            }
          : notSelectedOption,
      [quote.basisId, quotesAddStore.basisList]
    );

    const regionValue = useMemo(
      () =>
        Boolean(quote.economicRegionId)
          ? {
              id: quote.economicRegionId,
              name: quotesAddStore.economicRegionsList.find(
                item => item.id === quote.economicRegionId
              )?.name,
            }
          : notSelectedOption,
      [quote.economicRegionId, quotesAddStore.economicRegionsList]
    );

    const providerValue = useMemo(
      () =>
        Boolean(quote.providerId)
          ? {
              id: quote.providerId,
              name: quotesAddStore.providersList.find(item => item.id === quote.providerId)?.name,
            }
          : notSelectedOption,
      [quote.providerId, quotesAddStore.providersList]
    );

    const unitValue = useMemo(
      () =>
        Boolean(quote.unitsId)
          ? {
              id: quote.unitsId,
              name: quotesAddStore.unitsList.find(item => item.id === quote.unitsId)?.name,
            }
          : notSelectedOption,
      [quote.unitsId, quotesAddStore.unitsList]
    );

    const currencyValue = useMemo(
      () =>
        Boolean(quote.currencyId)
          ? {
              id: quote.currencyId,
              name: quotesAddStore.currencyList.find(item => item.id === quote.currencyId)?.name,
            }
          : notSelectedOption,
      [quote.currencyId, quotesAddStore.currencyList]
    );

    const dateOfObsValue = useMemo(
      () =>
        quote.dateOfObs ? moment(quote.dateOfObs, 'YYYY-MM-DD').format('DD.MM.YYYY') : undefined,
      [quote.dateOfObs]
    );

    const cultureOptions = useMemo(() => {
      const result = quotesAddStore.cultureList.map(culture => ({
        id: culture.id,
        name: culture.name,
      }));
      return result;
    }, [quotesAddStore.cultureList]);

    const cultureClassOptions = useMemo(() => {
      const result = [notSelectedOption, ...quotesAddStore.cultureClassList];
      return result;
    }, [quotesAddStore.cultureClassList]);

    const basisOptions = useMemo(() => {
      const result = [...quotesAddStore.basisList];
      return result;
    }, [quotesAddStore.basisList]);

    const economicRegionsOptions = useMemo(() => {
      const result = [...quotesAddStore.economicRegionsList];
      return result;
    }, [quotesAddStore.economicRegionsList]);

    const currencyOptions = useMemo(() => {
      const result = [...quotesAddStore.currencyList];
      return result;
    }, [quotesAddStore.currencyList]);

    const providersOptions = useMemo(() => {
      const result = [...quotesAddStore.providersList];
      return result;
    }, [quotesAddStore.providersList]);

    const unitsOptions = useMemo(() => {
      const result = [...quotesAddStore.unitsList];
      return result;
    }, [quotesAddStore.unitsList]);

    const onSetCulture = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, cultureId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetCultureClass = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, cultureClassId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetCurrency = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, currencyId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetBasis = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, basisId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetEconomicRegion = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, economicRegionId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetProvider = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, providerId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetUnit = useCallback(
      (event, value) => {
        quotesAddStore.onChangeQuote({ ...quote, unitsId: value?.id || '' }, index);
      },
      [quote, index]
    );

    const onSetPrice = useCallback(
      event => {
        quotesAddStore.onChangeQuote(
          {
            ...quote,
            price: event.target.value
              ? parseFloat((event.target.value as string).replace(/\D/g, ''))
              : 0,
          },
          index
        );
      },
      [quote, index]
    );

    const onSetDateOfObs = useCallback(
      date => {
        quotesAddStore.onChangeQuote(
          { ...quote, dateOfObs: moment(date).format('YYYY-MM-DD') },
          index
        );
      },
      [quote, index]
    );

    const handleDeleteAddingQuote = useCallback(() => {
      quotesAddStore.deleteAddingQuote(index);
    }, []);

    return (
      <NewQuoteBlockWrapper>
        <Header>
          <NewQuoteBlockTitle>Котировка&nbsp;{index + 1}</NewQuoteBlockTitle>
          {(allowDeleteAlways || index > 0) && (
            <DeleteBlock onClick={handleDeleteAddingQuote}>
              <BinIcon src={binIcon} />
              <DeleteLabel>Удалить</DeleteLabel>
            </DeleteBlock>
          )}
        </Header>
        <Grid container spacing={2} mb={4}>
          <StyledGridItem item xs={3}>
            <Autocomplete
              options={cultureOptions}
              value={cultureValue}
              getOptionLabel={(option: TypeCulture) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Культуры не найдены"
              onChange={onSetCulture}
              renderInput={params => <TextField {...params} variant="outlined" label="Культуры" />}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={cultureClassOptions}
              value={cultureClassValue}
              getOptionLabel={(option: TypeCultureClass) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Классы не найдены"
              onChange={onSetCultureClass}
              renderInput={params => <TextField {...params} variant="outlined" label="Классы" />}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={currencyOptions}
              value={currencyValue}
              getOptionLabel={(option: any) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Валюты не найдены"
              onChange={onSetCurrency}
              renderInput={params => {
                return <TextField {...params} variant="outlined" label="Валюта" />;
              }}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={economicRegionsOptions}
              value={regionValue}
              getOptionLabel={(option: TypeEconomicRegion) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Регионы не найдены"
              onChange={onSetEconomicRegion}
              renderInput={params => (
                <TextField {...params} variant="outlined" label="Экономический регион" />
              )}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={providersOptions}
              value={providerValue}
              getOptionLabel={(option: TypeProvider) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Провайдеры не найдены"
              onChange={onSetProvider}
              renderInput={params => <TextField {...params} variant="outlined" label="Провайдер" />}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={unitsOptions}
              value={unitValue}
              getOptionLabel={(option: TypeUnit) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Единицы не найдены"
              onChange={onSetUnit}
              renderInput={params => <TextField {...params} variant="outlined" label="Единицы" />}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <TextField
              fullWidth
              name={'quotePrice'}
              variant="outlined"
              placeholder="Введите цену"
              label="Цена"
              value={quote.price}
              onChange={onSetPrice}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </StyledGridItem>

          <StyledGridItem item xs={3}>
            <Autocomplete
              options={basisOptions}
              value={basisValue}
              getOptionLabel={(option: TypeBasis) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText="Базисы не найдены"
              onChange={onSetBasis}
              renderInput={params => <TextField {...params} variant="outlined" label="Базис" />}
            />
          </StyledGridItem>
          <StyledGridItem item xs={3}>
            <CalendarComponent
              error={''}
              placeholder={'Дата'}
              label={''}
              value={dateOfObsValue}
              onChange={onSetDateOfObs}
              datePickerOptions={{}}
              dataTestId={'dateOfObs'}
              readOnly={false}
              isDisabled={false}
              isBlocked={false}
              isCloseOnChange={true}
            />
          </StyledGridItem>
        </Grid>
      </NewQuoteBlockWrapper>
    );
  }
);
