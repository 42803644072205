import { makeAutoObservable, runInAction } from 'mobx';

import { Axios, lazyInject, provide, checkIsEmail } from '../../../../shared/utils';
import { Field } from '../../../../shared/types/Field';
import { LOGIN_PAIR_NOT_FOUND } from '../../../../shared/constanst/error';
import { AuthorizationStore } from '../../../../stores/authorization/authorization.store';

@provide.singleton()
export class SignInViaEmailStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(AuthorizationStore)
  authStore: AuthorizationStore;

  constructor() {
    makeAutoObservable(this);
  }

  // Докер работай

  email: Field = {
    value: '',
    error: '',
  };

  password: Field = {
    value: '',
    error: '',
  };

  isLoggedIn = false;

  setFieldValue = (fieldName: 'email' | 'password') => (value: string) => {
    this[fieldName].value = value;
    this[fieldName].error = '';
  };

  attemptLogin = () => {
    this.axios.api
      .login({
        email: this.email.value,
        password: this.password.value,
      })
      .then((response: any) => {
        runInAction(() => {
          this.isLoggedIn = true;
          localStorage.setItem('accessToken', response['access-token']);
          localStorage.setItem('refreshToken', response['refresh-token']);
          setTimeout(() => this.authStore.checkIsUserLoginned(), 100);
        });
      })
      .catch(() => {
        this.email.error = LOGIN_PAIR_NOT_FOUND;
      });
  };

  get isFormButtonAvailable() {
    return checkIsEmail(this.email.value) && this.password.value.length >= 6;
  }

  reset = () => {
    this.email.value = '';
    this.password.value = '';
    this.isLoggedIn = false;
  };
}
