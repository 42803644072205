import { isEmpty } from 'lodash';

import { TypeApiRequest, lazyInject, provide, Axios } from '../shared/utils';
import { EmployeeStore } from '../stores/employee/employee.store';
import { FilterStore } from '../stores/employee/filter.store';
import { TypeEmployeeStatus, TypeRoleOption } from '../../api/models/employee.model';

@provide.singleton()
export class EmployeeController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(EmployeeStore)
  protected employeeStore: EmployeeStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  setPage = (v: number) => {
    this.employeeStore.setPage(v);
  };

  setFilterStatus = (status: TypeEmployeeStatus) => {
    this.filterStore.setFilterStatus(status);
  };

  setFilterRole = (role: TypeRoleOption) => {
    this.filterStore.setFilterRole(role);
  };

  setFilterRoleID = (value: string) => {
    this.filterStore.setFilterRoleID(value);
  };

  setFilterNotRoleId = (value: string) => {
    this.filterStore.setFilterNotRoleId(value);
  };

  setFilterFullname = (value: string) => {
    this.filterStore.setFilterFullname(value);
  };

  setFilterContext = (value: string) => {
    this.filterStore.setFilterContext(value);
  };

  fetchEmployees = async (data: TypeApiRequest<'getOrganizationsUserList'>) => {
    this.employeeStore.setLoading(true);

    const payload = {
      organizationID: data.organizationID,
      status: !isEmpty(this.filterStore.filters.status)
        ? this.filterStore.filters.status.statusId
        : null,
      roleId: !isEmpty(this.filterStore.filters.role) ? this.filterStore.filters.role.id : null,
      notRoleId: !isEmpty(this.filterStore.filters.notRoleId)
        ? this.filterStore.filters.notRoleId
        : null,
      fullName: !isEmpty(this.filterStore.filters.fullname)
        ? this.filterStore.filters.fullname
        : null,
      context: !isEmpty(this.filterStore.filters.context) ? this.filterStore.filters.context : null,
      page: this.employeeStore.page,
      size: this.employeeStore.pageSize,
    };

    try {
      const { content, totalPages, totalElements } = await this.axios.api.getOrganizationsUserList(
        payload
      );
      this.employeeStore.setEmployees(content, totalPages, totalElements);

      return content;
    } catch (e) {
      console.error('fetchOrganizations e.message', e.message);
      this.employeeStore.clearEmployees();
    } finally {
      this.employeeStore.setLoading(false);
      this.employeeStore.setLoaded(true);
    }
  };

  fetchEmployeesRoles = async ({ organizationId }: TypeApiRequest<'getOrganizationUserRoles'>) => {
    try {
      this.employeeStore.setLoading(true);

      const response = await this.axios.api.getOrganizationUserRoles({
        organizationId,
      });

      const roles = response.map(({ role }) => role);

      this.employeeStore.setRoles(roles);
    } catch (e) {
      console.error('fetchEmployeesRoles e.message', e.message);
      this.employeeStore.clearRoles();
    } finally {
      this.employeeStore.setLoading(false);
      this.employeeStore.setLoaded(true);
    }
  };

  blockEmployee = async ({ employeeId }: TypeApiRequest<'blockUserOrganization'>) => {
    this.employeeStore.setLoading(true);
    try {
      await this.axios.api.blockUserOrganization({ employeeId });

      console.log('deleteOrganizationUser:', 'success');
    } catch (e) {
      if (e?.response?.status === 422) {
        this.employeeStore.setError('Невозможно удалить владельца');
        this.employeeStore.setShowError(true);
      }
      console.log('deleteOrganizationUser:', 'error', e);
    } finally {
      this.employeeStore.setLoaded(true);
      this.employeeStore.setLoading(false);
    }
  };

  unblockEmployee = async ({ employeeId }: TypeApiRequest<'unblockUserOrganization'>) => {
    this.employeeStore.setLoading(true);
    try {
      await this.axios.api.unblockUserOrganization({ employeeId });

      console.log('deleteOrganizationUser:', 'success');
    } catch (e) {
      console.log('deleteOrganizationUser:', 'error', e);
    } finally {
      this.employeeStore.setLoaded(true);
      this.employeeStore.setLoading(false);
    }
  };

  setPageSize = (pageSize: number) => {
    this.employeeStore.setPage(0);
    this.employeeStore.setPageSize(pageSize);
  };
}
