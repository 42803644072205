import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';
import { useMemo, useRef, useState } from 'react';
import GrassIcon from '@mui/icons-material/Grass';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { OrganizationIntegraTable, ParsedActivityCell } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import {
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraCropwiseChecklist } from '../../models/orgIntegraCropwiseChecklist';
import useIntegraCropwiseNavigation from '../../../../hooks/useIntegraCropwiseNavigation';
import { CULTURE_LIST_MODAL_ID } from '../../modals/CultureList/CultureListModal.config';

const ChecklistTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();

  const filterConfigRef = useRef<Map<string, TOrganizationIntegraTableFilterItemUnion>>();

  const searchParams = useMemo(() => new URLSearchParams(search), []);
  const { goToAttributeTab } = useIntegraCropwiseNavigation({ organizationId });

  const { openModalByModalId } = useModal();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });
  const [isSideLoadUpdate] = useState(false);

  const onActionButtonClick = (value: IOrgIntegraCropwiseChecklist) => {
    goToAttributeTab({ checkListIdIn: value.checkListId });
  };

  const openCultureModal = (cultureList: IOrgIntegraCropwiseChecklist['cultures']) => {
    openModalByModalId(CULTURE_LIST_MODAL_ID, { cultureList });
  };

  const fetchData: TFetchHandler<IOrgIntegraCropwiseChecklist> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);
      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      ...modifiedArgs,
      page,
      size,
      organizationIdIn: [organizationId],
    };

    return service.fetchChecklistList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwiseChecklist>
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterConfigRef,
            filterCollection: [
              {
                type: 'select',
                label: 'Активность',
                propertyName: 'isActive',
                optionList: [
                  { label: 'Активный', value: true },
                  { label: 'Не активный', value: false },
                ],
              },
              {
                type: 'text',
                label: 'ID Адаптера',
                propertyName: 'idIn',
                isMultiple: true,
              },
              {
                type: 'number',
                label: 'ID чек-листа “Cropwise”',
                propertyName: 'cropwiseChecklistIdIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID чек-листа “Помощник”',
                propertyName: 'checkListIdIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID культуры “Помощник”',
                propertyName: 'cultureIdIn',
                isMultiple: true,
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createDateTimeFrom',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createDateTimeTo',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateDateTimeFrom',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateDateTimeTo',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'checklistId',
              headerName: 'ID “Помощник”',
              flex: 1,

              valueGetter: row => row.row.checkListId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwiseChecklistId',
              headerName: 'ID “Cropwise”',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.cropwiseChecklistId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'isActive',
              headerName: 'Активный',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row.isActive,
              renderCell: row => <ParsedActivityCell value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'culture',
              headerName: 'Культуры',
              maxWidth: 80,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => (
                <Tooltip title="Открыть список культур">
                  <IconButton onClick={() => openCultureModal(row.row.cultures)}>
                    <GrassIcon />
                  </IconButton>
                </Tooltip>
              ),
            },
            {
              field: 'attribute',
              headerName: 'Аттрибуты',
              maxWidth: 80,
              valueGetter: row => row.row.id,
              renderCell: row => (
                <Tooltip title="Перейти к атрибутам">
                  <IconButton onClick={() => onActionButtonClick(row.row)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ChecklistTable;
