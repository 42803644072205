import { Box } from '@mui/material';

import { MeasurementsTable } from '../components';

const OrgIntegraCropwiseMeasurements = () => {
  return (
    <Box>
      <MeasurementsTable />
    </Box>
  );
};

export default OrgIntegraCropwiseMeasurements;
