import { ICropwiseConfig } from '../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationReports/components/OrganizationReportsCropwise/models';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = Partial<ICropwiseConfig> & { id: string };
type TypeResponse = string;

export const updateCropwiseReportConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/as-cropwise/reports/config/${params.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
