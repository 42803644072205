import {
  CalendarComponent,
  Colors,
  ModalFooter,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { Box, Chip, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import { AddWeatherStationForm } from '../../../../components/OrganizationReportsPogodavpole/components';
import { IDeviceModel } from '../../../../../../../../../../api/models/device.model';
import { useStore } from '../../../../../../../../../shared/utils';
import { ReportService } from '../../../../../../../../../services';
import { getNotificatorProps } from '../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../../../../../shared/utils/downloadBlobAsFile';

const PogodaVPoleReportForm = () => {
  const reportsService = useStore(ReportService);

  // const [deviceList, setDeviceList] = useState<IDeviceModel[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<IDeviceModel>(null);
  const [dateFrom, setDateFrom] = useState<Date>(null);
  const [dateTo, setDateTo] = useState<Date>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { getModalPayload, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const { organizationId } = getModalPayload() as { organizationId: string };

  const isReportDisabled = !dateFrom || !dateTo || isLoading;

  // const addDevice = useCallback(
  //   (device: IDeviceModel) => {
  //     // после добавления поддержки беком вернуть работу с массивом станций

  //     if (deviceList.length > 0) {
  //       setNotification(
  //         getNotificatorProps(
  //           `Разрешена выгрузка по одной метеостанции. ${deviceList[0]?.name} удалена`,
  //           ENotificationStyles.Error
  //         )
  //       );
  //     }

  //     setDeviceList([device]);
  //   },
  //   [deviceList]
  // );

  // const removeDevice = useCallback(
  //   (deviceId: string) => {
  //     setDeviceList(deviceList.filter(device => device.id !== deviceId));
  //   },
  //   [deviceList]
  // );

  const generateReport = () => {
    const parsedDateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const parsedDateTo = moment(dateTo).format('YYYY-MM-DD');
    setIsLoading(true);

    reportsService
      .getReportPowerBIWeather({
        dateFrom: parsedDateFrom,
        dateTo: parsedDateTo,
        organizationId,
        // ...(deviceList?.[0] ? { deviceId: deviceList[0].id } : {}),
        ...(selectedDevice && { deviceId: selectedDevice.id }),
      })
      .then(blob => downloadBlobAsFile(blob, `Погода ${parsedDateFrom} - ${parsedDateTo}.zip`))
      .catch(() => {
        setNotification(
          getNotificatorProps('Ошибка формирования отчёта', ENotificationStyles.Error)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <AddWeatherStationForm
        organizationId={organizationId}
        onSelectDevice={setSelectedDevice}
        isAllOwners
        isHideAddButton
      />
      {/* <Box minHeight={140} maxHeight={200}>
        <Stack direction={'row'} flexWrap={'wrap'} gap={1} marginTop={1} marginBottom={2}>
          {deviceList.map(device => (
            <Chip
              key={device.id}
              label={`${device.name}${device?.label ? ` [${device.label}]` : ''}`}
              onDelete={() => removeDevice(device.id)}
            />
          ))}
        </Stack>
      </Box> */}

      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        width="100%"
        marginBottom={4}
        marginTop={2}
        justifyContent={'space-between'}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Box width={'100%'}>
            <DatePicker
              value={moment(dateFrom)}
              onChange={(date: Moment) => setDateFrom(date?.toDate())}
              maxDate={dateTo ? moment(dateTo) : null}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата начала" error={false} fullWidth />
              )}
            />
          </Box>
          <Box width={'100%'}>
            <DatePicker
              value={moment(dateTo)}
              onChange={(date: Moment) => setDateTo(date?.toDate())}
              minDate={dateFrom ? moment(dateFrom) : null}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата окончания" error={false} fullWidth />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <>
          <Typography sx={{ fontSize: 12, color: Colors.grey }}>
            Иногда выгрузка отчёта может занять время
          </Typography>
          <LinearProgress />
        </>
      ) : (
        <Box height={22} />
      )}
      <br />

      <ModalFooter
        successButton={{
          title: 'Сформировать отчёт',
          disabled: isReportDisabled,
          handler: generateReport,
        }}
        denyButton={{ title: 'Отменить', handler: closeModal }}
      />
    </Box>
  );
};

export default PogodaVPoleReportForm;
