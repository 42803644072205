import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../shared/utils';
import { ICropwiseConfig, ICropwiseConfigForm } from '../../models';

@provide.singleton()
export class CropwiseReportStore {
  private _configMap: Map<string, ICropwiseConfigForm> = new Map();
  private _isLoading = false;
  private _touchedConfigMap: Map<string, Set<keyof Partial<ICropwiseConfigForm>>> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get configMap(): Map<string, ICropwiseConfigForm> {
    return this._configMap;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  getConfigById = (id: string): ICropwiseConfigForm | undefined => {
    return this._configMap.get(id);
  };

  getTouchedConfigFields = (id: string) => {
    return this._touchedConfigMap.get(id);
  };

  get configList(): ICropwiseConfigForm[] {
    return Array.from(this._configMap.values());
  }

  setConfigById = (id: string, config: ICropwiseConfigForm) => {
    this._configMap.set(id, config);
  };

  addTouchedConfigFields = (configId: string, fieldName: keyof Partial<ICropwiseConfigForm>) => {
    if (!this._touchedConfigMap.has(configId)) {
      this._touchedConfigMap.set(configId, new Set());
    }

    this._touchedConfigMap.get(configId)?.add(fieldName);
  };

  cleanConfigTouchedFields = (configId: string) => {
    this._touchedConfigMap.delete(configId);
  };

  deleteConfigById = (id: string) => {
    this._configMap.delete(id);
  };

  clearConfigMap = () => {
    this._configMap = new Map();
  };

  clearStore = () => {
    this.clearConfigMap();
    this._isLoading = false;
    this._touchedConfigMap = new Map();
  };
}
