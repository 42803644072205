import { TypeAlert } from './alert.model'

export type TypeAlerts = { 
    content: Array<TypeAlert>; 
    empty: boolean;
    first: boolean; 
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        sort: TypeSort;
        unpaged: boolean;
    };
    size: number;
    sort: TypeSort;
    totalElements: number;
    totalPages: number;
};

export type TypeAlertStatus = {
    statusId: EAlertStatus; 
    statusName: string;
}

export type TypeAlertSort = {
    field: string;
    sort: string;
};

export enum EAlertStatus {
    NEW = 'NEW',
    COMPLETED  = 'COMPLETED',
    ERROR = 'ERROR',
    CANCELED = 'CANCELED'
}

export const initialAlertStatus: TypeAlertStatus[] = [
    {
      statusId: EAlertStatus.NEW,
      statusName: 'Новая',
    },
    {
      statusId: EAlertStatus.COMPLETED,
      statusName: 'Выполнено',
    },
    {
        statusId: EAlertStatus.ERROR,
        statusName: 'Ошибка',
      },
      {
        statusId: EAlertStatus.CANCELED,
        statusName: 'Отменено',
      },
];

type TypeSort = {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
};
