import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../../routes';
import { DictionaryListContainer } from '../containers';

export const DictionaryRouter: FC = () => {
  return (
    <Switch>
      <Route
        path={[
          AdminRoutes.Dictionary,
          AdminRoutes.DictionaryName,
          AdminRoutes.DictionarySelectedItem,
          AdminRoutes.DictionarySearch,
          AdminRoutes.DictionaryCreate,
          AdminRoutes.DictionarySelectedItemCopy,
          AdminRoutes.DictionarySelectedItemEdit,
          AdminRoutes.DictionarySelectedItemHistory,
        ]}
        exact
        component={DictionaryListContainer}
      />
    </Switch>
  );
};
