export type TypeAlert = { 
    createdBy: string; 
    error: string;
    errorDescription: string; 
    id: string;
    status: string; 
    taskUpdate: TaskUpdateType;
    timestamp: string; 
    name: string;
    fullName: string;
};

type TaskUpdateType = {
    id: string;
};

export enum EFormFields {
    createdBy = 'createdBy',
    error = 'error',
    name = 'name',
    fullName = 'fullName',
    errorDescription = 'errorDescription',
    id = 'id',
    status = 'status',
    taskUpdate = 'taskUpdate',
    timestamp = 'timestamp',
};
