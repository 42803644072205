export const getColumns = () => [
  {
    field: 'name',
    type: 'string',
    headerName: 'Имя',
    width: 150,
    sortable: false,
  },
  {
    field: 'error',
    type: 'string',
    headerName: 'Ошибка',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
  {
    field: 'timestamp',
    type: 'string',
    headerName: 'Создано',
    flex: 1,
    minWidth: 200,
    sortable: false,
  },
];
