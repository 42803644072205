import omitDeep from 'omit-deep-lodash';
import { isEmpty, trim } from 'lodash';

import {
  IAttribute,
  IChecklistAttributeODZ,
  IODZ,
} from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import {
  IAggregationFormulaType,
  TFormulaTypeUnion,
} from '../../../../../../../../../modules/Formulas/models';
import { AttributeODZStore } from '../stores';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';

@provide.singleton()
class AttributeODZController {
  @lazyInject(AttributeODZStore)
  protected store: AttributeODZStore;

  getPreparedODZData = () => {
    const result: Partial<IODZ> = {};

    result.isCriteriaEnabled = this.store.isODZEnabled;

    if (
      this.store.isFormulaFieldDirty('greenCriteriaComment') ||
      this.store.isFormulaFieldDirty('yellowCriteriaComment') ||
      this.store.isFormulaFieldDirty('redCriteriaComment')
    ) {
      const redComment = trim(this.store.redCriteriaComment);
      const yellowComment = trim(this.store.yellowCriteriaComment);
      const greenComment = trim(this.store.greenCriteriaComment);

      result.validationComment = {
        red: redComment?.length > 0 ? redComment : null,
        yellow: yellowComment?.length > 0 ? yellowComment : null,
        green: greenComment?.length > 0 ? greenComment : null,
      };
    }

    if (this.store.isFormulaFieldDirty('greenCriteria')) {
      result.validationGreenCriteria = this.sanitizeData(this.store.greenSerializedCriteria);
    }

    if (this.store.isFormulaFieldDirty('yellowCriteria')) {
      result.validationYellowCriteria = this.sanitizeData(this.store.yellowSerializedCriteria);
    }

    if (this.store.isFormulaFieldDirty('redCriteria')) {
      result.validationRedCriteria = this.sanitizeData(this.store.redSerializedCriteria);
    }

    return result;
  };

  provideODZData = (data: IAttribute | IChecklistAttribute) => {
    this.store.setODZEnabled(Boolean(data.isCriteriaEnabled));

    this.store.setGreenCriteria(data?.validationGreenCriteria);
    this.store.setYellowCriteria(data?.validationYellowCriteria);
    this.store.setRedCriteria(data?.validationRedCriteria);

    if (data.validationComment) {
      this.store.setGreenCriteriaComment(data.validationComment?.green);
      this.store.setYellowCriteriaComment(data.validationComment?.yellow);
      this.store.setRedCriteriaComment(data.validationComment?.red);
    }
  };

  copyODZData = (data: IChecklistAttributeODZ) => {
    this.store.setGreenCriteria(data?.validationGreenCriteria);
    this.store.setYellowCriteria(data?.validationYellowCriteria);
    this.store.setRedCriteria(data?.validationRedCriteria);

    if (data?.validationGreenCriteria === null) {
      this.store.setGreenSerializedCriteria(null);
    }
    if (data?.validationYellowCriteria === null) {
      this.store.setYellowSerializedCriteria(null);
    }
    if (data?.validationRedCriteria === null) {
      this.store.setRedSerializedCriteria(null);
    }

    if (data.validationComment) {
      this.store.setGreenCriteriaComment(data.validationComment?.green);
      this.store.setYellowCriteriaComment(data.validationComment?.yellow);
      this.store.setRedCriteriaComment(data.validationComment?.red);
    }

    this.store.setODZEnabled(true);

    this.store.dirtyFormulaFields.add('greenCriteria');
    this.store.dirtyFormulaFields.add('greenCriteriaComment');
    this.store.dirtyFormulaFields.add('redCriteria');
    this.store.dirtyFormulaFields.add('redCriteriaComment');
    this.store.dirtyFormulaFields.add('yellowCriteria');
    this.store.dirtyFormulaFields.add('yellowCriteriaComment');
    this.store.dirtyFormulaFields.add('isODZEnabled');
  };

  getSaveConsistency = () => {
    const data = this.getPreparedODZData();

    // Хотя бы одна формула включена или все выключены
    if (data.isCriteriaEnabled) {
      return Boolean(
        data.validationRedCriteria || data.validationYellowCriteria || data.validationGreenCriteria
      );
    } else {
      return (
        data.validationRedCriteria === null &&
        data.validationYellowCriteria === null &&
        data.validationGreenCriteria === null
      );
    }
  };

  private sanitizeData = (data: IAggregationFormulaType): TFormulaTypeUnion => {
    const sanitizedData = this.removeClientId(data);

    if (isEmpty(sanitizedData)) return null;

    return sanitizedData as TFormulaTypeUnion;
  };

  removeClientId = (obj: IAggregationFormulaType): TFormulaTypeUnion | {} => {
    return omitDeep(obj, 'clientId');
  };

  clearDirtyFields = () => {
    this.store.clearDirtyFields();
  };

  clearODZOnUnmount = () => {
    this.store.clearStore();
  };
}

export default AttributeODZController;
