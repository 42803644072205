import { TypeApiRoute } from '../../models/type.api.request';
import { EDictionaryItemStatus, TDictionaryItemResponse } from '../../models/dictionaries.model';

type TypeRequest = {
  idIn?: string[];
  remoteName: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  status?: EDictionaryItemStatus;
  level?: number;
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  attrs?: Record<string, any>;
  fetchAttributes?: boolean;
  dependencyName?: string;
  dependencyRecordId?: string;
  dependencyTargetName?: string;
  dependencyTargetPath?: string;
  directDependencies?: boolean;
  hasAttributes?: boolean;
  filterByAttributeKey?: string;
  withAuthor?: boolean;

  size?: number;
  page?: number;
};

type TypeResponse = TDictionaryItemResponse;

export const getAllDictionariesByParams: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => {
    let url = `/api/da-dictionary/admin/dictionary/${params.remoteName}/findAll`;

    if (params.size && params.page) {
      url += '?';
      if (params.size) {
        url += `size=${params.size}`;
      }

      if (params.page) {
        url += `${params.size ? `&` : ''}page=${params.page}`;
      }
    }

    return url;
  },
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
