import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeCheckList } from '../../../api/models/check.list.model';

import { IChecklist } from './../../../api/models/checklist.model';

@provide.singleton()
export class CheckListStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  error = null;
  loading = false;
  loaded = false;
  page = 0;
  totalPages = 0;

  checkLists: TypeCheckList[] = [];
  checkList: TypeCheckList = {} as TypeCheckList;

  private _checklistListingData: IChecklist[] = [];

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get checklistListingData() {
    return this._checklistListingData;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setCheckLists = (checkLists: TypeCheckList[], totalPages: number) => {
    runInAction(() => {
      this.checkLists = checkLists;
      this.totalPages = totalPages;
    });
  };

  setCheckList = (checkList: TypeCheckList) => {
    runInAction(() => {
      this.checkList = checkList;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setChecklistListingData = (data: IChecklist[]) => {
    runInAction(() => {
      this._checklistListingData = data;
    });
  };

  clearCheckLists = () => {
    this.setCheckLists([], 0);
  };

  clearCheckListListingData = () => {
    runInAction(() => {
      this._checklistListingData = [];
    });
  };

  clearStore = () => {
    runInAction(() => {
      this.clearCheckLists();
      this.clearCheckListListingData();
      this.page = 0;
      this.totalPages = 0;
      this.error = null;
    });
  };
}
