import { TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import { FC, memo, useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';

import { AdditionalParams, History, LinkedDictionaries, Dependencies } from './components';

type TProps = {
  attrOnly?: boolean;
  isCreateChild?: boolean;
};

const FormTabs: FC<TProps> = props => {
  const { attrOnly, isCreateChild } = props;

  const [currentTab, setCurrentTab] = useState('1');

  useEffect(() => {
    if (attrOnly) {
      setCurrentTab('1');
    }
  }, [attrOnly]);

  const onChangeTab = (e, key: string) => {
    setCurrentTab(key);
  };

  return (
    <TabContext value={currentTab}>
      <Box>
        <TabList onChange={onChangeTab}>
          <Tab label="Дополнительные параметры" value="1" />
          <Tab disabled={attrOnly} label="Связанные справочники" value="2" />
          <Tab disabled={attrOnly} label="Связи" value="3" />
          <Tab disabled={attrOnly} label="История Записи" value="4" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <AdditionalParams isCreateChild={isCreateChild} />
      </TabPanel>
      <TabPanel value="2">
        <LinkedDictionaries />
      </TabPanel>
      <TabPanel value="3">
        <Dependencies />
      </TabPanel>
      <TabPanel value="4">
        <History />
      </TabPanel>
    </TabContext>
  );
};

export default memo(FormTabs);
