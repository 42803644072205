import { forwardRef, RefCallback } from 'react';
import { IMaskInput } from 'react-imask';

type Definitions = { [key: string]: RegExp };

interface TextMaskFieldProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  mask: string;
  definitions: Definitions;
  unmask: boolean;
}

const TextMaskField = forwardRef<HTMLElement, TextMaskFieldProps>(function TextMaskField(
  props,
  ref
) {
  const {
    onChange,
    mask = '+# (000) 000-00-00',
    definitions = { '#': /[0-9]/ },
    unmask = true,
    ...other
  } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      unmask={unmask}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

export { TextMaskField };
