import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_TOGGLE_ODZ_MODAL = 'before-toggle-ODZ-modal-id';

const beforeToggleODZModal: TModalConfig = {
  id: BEFORE_TOGGLE_ODZ_MODAL,
  name: BEFORE_TOGGLE_ODZ_MODAL,
  type: EModalType.Warning_Before_Living,
  title: 'Вы уверены, что хотите сохранить ОДЗ?',
  description: {
    value: 'При включённом ОДЗ должна быть заполнена хотя бы одна формула',
    $styles: {
      textAlign: 'center',
      marginBottom: '12px',
    },
  },
  successButton: {
    title: 'Сохранить',
    color: 'primary',
  },
  denyButton: {
    title: 'Закрыть',
    color: 'secondary',
  },
};

export { BEFORE_TOGGLE_ODZ_MODAL, beforeToggleODZModal };
