import { RoleItemAddUser, RoleItemUserGrid } from './components';

const RoleItemUserList = () => {
  return (
    <div>
      <RoleItemAddUser />
      <RoleItemUserGrid />
    </div>
  );
};

export default RoleItemUserList;
