import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  seasons: number[];
};
type TypeResponse = Blob;

export const downloadCropwiseReport: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-cropwise/reports/create/csv/zip`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
