import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FC } from 'react';

import { useStore } from '../../../../../../../../../shared/utils';
import { CropwiseReportController } from '../../mobx';

const ReportCropwiseConfigEmptyState: FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const controller = useStore(CropwiseReportController);

  const createNewConfig = () => {
    controller.createNewConfigPlaceholder();

    onCreate();
  };

  return (
    <Box marginTop={3}>
      <Button sx={{ width: 300 }} onClick={createNewConfig}>
        <AddCircleIcon sx={{ marginRight: 1 }} color="primary" />
        <Typography variant="body2">Добавить конфигурацию</Typography>
      </Button>
    </Box>
  );
};

export default observer(ReportCropwiseConfigEmptyState);
