import { IOrgIntegraCropwiseErrorFile } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseErrorFile/models/orgIntegraCropwiseErrorFile';
import {
  IPageableRequest,
  TypeResponsePageable,
} from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IPageableRequest &
  Partial<{
    checkListInstanceIds: string[];
    organizationIds: string[];
    userIds: string[];
    startDateTime: string;
    endDateTime: string;
    maxAttempts: number;
  }>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraCropwiseErrorFile[] };

type TypeError = {};

export const getOrgIntegraCropwiseErrorFilesByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/integration/fileError/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
