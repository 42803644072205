import * as yup from 'yup';

import { ICreateOrgIntegraCropwiseConfig } from '../../../../../../../../../../../api/models/integra/config.model';

const orgIntegraCropwiseOrganizationSchema: yup.SchemaOf<
  Partial<ICreateOrgIntegraCropwiseConfig>
> = yup.object().shape({
  password: yup.string().min(1).required('Поле обязательно для заполнения'),
  name: yup.string().nullable(),
  username: yup.string().min(1).required('Поле обязательно для заполнения'),
  organizationId: yup.string().required('Поле обязательно для заполнения'),
});

export { orgIntegraCropwiseOrganizationSchema };
