import { EChecklistAttributeType } from '../../../../../../../../../../api/models/checklist.attribute.model';

const ALLOWED_ODZ_TYPES: EChecklistAttributeType[] = [
  EChecklistAttributeType.Int,
  EChecklistAttributeType.Double,
  EChecklistAttributeType.Enum,
  EChecklistAttributeType.DictionaryLink,
];

export default ALLOWED_ODZ_TYPES;
