import { isEmpty } from 'lodash';

const ERRORS_MAPPER = {
  FIELD_IS_EMPTY: 'Поле не должно быть пустым',
  INCORRECT_FORMAT: 'Некорректный формат поля',
  INCORRECT_DATE: 'Некорректный формат даты',
  PHONE_NUMBER_IS_BUSY: 'Номер телефона занят',
  VERIFICATION_FAILED: 'Некорректный код',
  SMS_CODE_INCORRECT: 'Неверный код',
  DATE_INCORRECT_VALUE: 'Некорректная дата',
  LOGIN_PAIR_NOT_FOUND: 'Неверная почта или пароль',
  NAME_USING_RESTRICTED_CHARS: 'Содержит запрещенный символ',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
  EMAIL_IS_BUSY: 'E-mail занят',
  CANNOT_BE_CHANGED: 'Поле не может быть изменено',
};

interface IformValuesErrors {
  [x: string]: string;
}

export function localizeValidationTitle(title: keyof typeof ERRORS_MAPPER | string) {
  return ERRORS_MAPPER[title as keyof typeof ERRORS_MAPPER] || title;
}

export function isFormValuesErrors(
  fieldName: string,
  formValuesErrors: IformValuesErrors
): boolean {
  return !isEmpty(formValuesErrors[fieldName]);
}
