import { EChecklistAttributePositionWidth } from '../../../../../../../../../../../../api/models/checklist.attribute.model';

export interface IPositionOptionConfigItem {
  isDisabled?: boolean;
}

export interface IPositionOptionConfigItemWidth extends IPositionOptionConfigItem {
  availableValues?: EChecklistAttributePositionWidth[];
}

export enum EPositionOptionProp {
  Separate = 'position.separate',
  NewLine = 'position.newLine',
  Width = 'position.width',
  ParentId = 'position.parentId',
}

export type TPositionOptionConfig = {
  [EPositionOptionProp.NewLine]: IPositionOptionConfigItem;
  [EPositionOptionProp.Separate]: IPositionOptionConfigItem;
  [EPositionOptionProp.ParentId]: IPositionOptionConfigItem;
  [EPositionOptionProp.Width]: IPositionOptionConfigItemWidth;
};
