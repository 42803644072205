import { Autocomplete, Box, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import { IChecklistAttributeODZ } from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { ChecklistAttributeService } from '../../../../../../../../../../../../services';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';

import { CopyODZItem } from './components';

const ODZCopyDropdown: FC<{
  attributeId: string;
  checklistAttributeId: string;
  onSelect: (value: IChecklistAttributeODZ) => void;
}> = ({ attributeId, checklistAttributeId, onSelect }) => {
  const service = useStore(ChecklistAttributeService);

  const [odzList, setOdzList] = useState<ISelectOptionExtended<IChecklistAttributeODZ>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    service
      .getChecklistAttributeODZ({ attributeId, checklistAttributeId })
      .then(resp => {
        setOdzList(
          resp.map(item => ({
            label: `${item?.sto?.name} ${item?.sto?.culture?.name} ${item?.sto?.organization?.name} 
            ${item?.stoChecklistAttributeId} ${item?.validationComment?.green} 
            ${item?.validationComment?.red} ${item?.validationComment?.yellow}`,
            value: item.checklistAttributeId,
            rawData: item,
          }))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Box maxWidth={500} width="100%">
      <Autocomplete
        options={odzList}
        loading={isLoading}
        fullWidth
        renderOption={(props, option, state) => (
          <CopyODZItem ODZ={option.rawData} onSelect={onSelect} state={state} />
        )}
        renderInput={props => <TextField {...props} fullWidth label="Скопировать ОДЗ" />}
      />
    </Box>
  );
};

export default observer(ODZCopyDropdown);
