import { useEffect, useMemo } from 'react';
import {
  Redirect,
  Route,
  Router,
  Switch,
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Box, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useModal } from '@farmlink/farmik-ui';

import {
  OrgIntegraAgrosignalChecklistInstances,
  OrgIntegraAgrosignalChecklists,
  OrgIntegraAgrosignalEmployees,
  OrgIntegraAgrosignalErrorMessage,
  OrgIntegraAgrosignalFields,
  OrgIntegraAgrosignalOrganization,
} from '../modules';
import { Layout } from '../../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../../style';
import { getOrganizationMenu } from '../../../../../../../../shared/menu';
import useOrgIntegraNavigation from '../../../hooks/useOrgIntegraNavigation';
import { useCurrentTab } from '../hooks';
import { useStore } from '../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalController } from '../mobx';
import { beforeDeleteModalOrgIntegra } from '../../../modals';
import { OrgIntegraAgrosignalErrorFile } from '../modules/OrgIntegraAgrosignalErrorFile';

import {
  EOrgIntegraAgrosignalRoutes,
  EOrgIntegraAgrosignalTabs,
} from './OrgIntegraAgrosignal.routes';

const OrgIntegraAgrosignalRouter = () => {
  const { path } = useRouteMatch();
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();
  const controller = useStore(OrgIntegraAgrosignalController);

  const { navigateToIntegrations } = useOrgIntegraNavigation();
  const { currentTab } = useCurrentTab();
  const { registerModalList } = useModal();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  useEffect(() => {
    controller.fetchConfig(organizationId);

    registerModalList([beforeDeleteModalOrgIntegra], 'OrgIntegraAgrosignal');

    return () => {
      controller.clearOnUnmount();
    };
  }, []);

  const getPath = (route: EOrgIntegraAgrosignalRoutes) => {
    return generatePath(`${path}/${route}`, { organizationId });
  };

  const getRoutePath = (route: EOrgIntegraAgrosignalRoutes) => {
    return `${path}/${route}`;
  };

  const onTabChange = (tabRoute: EOrgIntegraAgrosignalRoutes) => history.push(getPath(tabRoute));

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex">
              <Tooltip title="Назад к конфигурации">
                <IconButton
                  onClick={navigateToIntegrations}
                  sx={{ maxHeight: '28px', maxWidth: '28px' }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <H1>Интеграция. Агросигнал</H1>
            </Box>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <Tabs variant="scrollable" value={currentTab} sx={{ marginBottom: 2 }}>
            <Tab
              label="Организация"
              value={EOrgIntegraAgrosignalTabs.Organization}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.Organization);
              }}
            />
            <Tab
              label="Сотрудники"
              value={EOrgIntegraAgrosignalTabs.Employees}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.Employees);
              }}
            />
            <Tab
              label="Поля"
              value={EOrgIntegraAgrosignalTabs.Fields}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.Fields);
              }}
            />
            <Tab
              label="Шаблоны"
              value={EOrgIntegraAgrosignalTabs.Checklists}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.Checklists);
              }}
            />
            <Tab
              label="Чек-лист точки"
              value={EOrgIntegraAgrosignalTabs.ChecklistInstances}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.ChecklistInstances);
              }}
            />
            <Tab
              label="Ошибки обработки сообщений"
              value={EOrgIntegraAgrosignalTabs.MessagesError}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.MessagesError);
              }}
            />

            <Tab
              label="Ошибки обработки файлов"
              value={EOrgIntegraAgrosignalTabs.FilesError}
              onClick={() => {
                onTabChange(EOrgIntegraAgrosignalRoutes.FilesError);
              }}
            />
          </Tabs>

          <Switch>
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.Employees)}
              children={<OrgIntegraAgrosignalEmployees />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.Fields)}
              children={<OrgIntegraAgrosignalFields />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.Checklists)}
              children={<OrgIntegraAgrosignalChecklists />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.ChecklistInstances)}
              children={<OrgIntegraAgrosignalChecklistInstances />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.Organization)}
              children={<OrgIntegraAgrosignalOrganization />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.MessagesError)}
              children={<OrgIntegraAgrosignalErrorMessage />}
            />
            <Route
              path={getRoutePath(EOrgIntegraAgrosignalRoutes.FilesError)}
              children={<OrgIntegraAgrosignalErrorFile />}
            />

            <Redirect to={getRoutePath(EOrgIntegraAgrosignalRoutes.Organization)} />
          </Switch>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export { OrgIntegraAgrosignalRouter };
