import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { ErrorFileTable } from '../components';
import { errorMessageDetailsForm, errorFileMultiplePush, errorFileSinglePush } from '../modals';

const OrgIntegraCropwiseErrorFile = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList(
      [errorMessageDetailsForm, errorFileMultiplePush, errorFileSinglePush],
      'OrgIntegraAgrosignalErrorFile'
    );
  }, []);

  return (
    <Box>
      <ErrorFileTable />
    </Box>
  );
};

export default OrgIntegraCropwiseErrorFile;
