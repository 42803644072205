import { GridColDef } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Icon, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';

import { IRoleEmployeeCount } from '../../../../../../../../../api/models/role.model';
import { TooltipedValue } from '../../../../../../../../shared/components';

const getRoleListColumns = (
  goToRole: (roleId: string) => void
): GridColDef<IRoleEmployeeCount>[] => [
  {
    field: 'name',
    type: 'string',
    headerName: 'Название',
    valueGetter: params => params.row?.role?.name,
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    flex: 1,
    minWidth: 200,
    sortable: true,
  },
  {
    field: 'employeeCount',
    type: 'number',
    headerName: 'Кол-во сотрудников',
    renderCell: params => <TooltipedValue value={params?.value ?? 'Не указано'} />,
    width: 100,
    sortable: true,
  },
  {
    field: 'isDefault',
    type: 'boolean',
    headerName: 'Дефолтный',
    valueGetter: params => params.row?.role?.isDefault,
    renderCell: params => (
      <Icon>{params.value ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}</Icon>
    ),
    width: 100,
    sortable: true,
  },
  {
    field: 'isOwner',
    type: 'boolean',
    headerName: 'Владелец',
    valueGetter: params => params.row?.role?.isOwner,
    renderCell: params => (
      <Icon>{params.value ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}</Icon>
    ),
    width: 100,
    sortable: true,
  },
  {
    field: 'creationDate',
    type: 'date',
    headerName: 'Дата создания',
    valueGetter: params =>
      params.row?.role?.creationDate
        ? moment(params.row?.role?.creationDate).format('DD.MM.YYYY')
        : 'Не указано',
    width: 200,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'string',
    headerName: '',
    renderCell: params => (
      <Tooltip title="Настроить роль">
        <IconButton onClick={() => goToRole(params.row.role.id)}>
          <ArrowForwardIcon />
        </IconButton>
      </Tooltip>
    ),
    width: 60,
  },
];

export default getRoleListColumns;
