import { Box, CircularProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import {
  TFetchHandler,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { IOrgIntegraCropwiseAttribute } from '../../models/orgIntegraCropwiseAttribute';

const AttributeTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();

  const filterConfigRef = useRef<Map<string, TOrganizationIntegraTableFilterItemUnion>>(null);

  const searchParams = useMemo(() => new URLSearchParams(search), []);

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });
  const [isSideLoadUpdate] = useState(false);

  const fetchData: TFetchHandler<IOrgIntegraCropwiseAttribute> = (page, size, ...args) => {
    const additionalArgs = args[0];

    const modifiedArgs = Object.entries(additionalArgs).reduce((acc, [key, value]) => {
      const filterItem = filterConfigRef.current?.get(key);

      if (filterItem?.isMultiple) {
        acc[key] = Array.isArray(value) ? value : [value];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      ...modifiedArgs,
      page,
      size,
      organizationIdIn: [organizationId],
    };

    return service.fetchAttributeListList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const attributeTypeList = useMemo<ISelectOption[]>(
    () => [
      { value: 'BOOLEAN', label: 'BOOLEAN' },
      { value: 'INT', label: 'INT' },
      { value: 'DOUBLE', label: 'DOUBLE' },
      { value: 'DATE', label: 'DATE' },
      { value: 'STRING', label: 'STRING' },
      { value: 'LONGSTRING', label: 'LONGSTRING' },
      { value: 'DICTIONARY_LINK', label: 'DICTIONARY_LINK' },
      { value: 'ENUM', label: 'ENUM' },
      { value: 'USER_DICTIONARY_LINK', label: 'USER_DICTIONARY_LINK' },
      { value: 'FILE_LINK', label: 'FILE_LINK' },
      { value: 'CHECKLIST_INSTANCE_LINK', label: 'CHECKLIST_INSTANCE_LINK' },
    ],
    []
  );

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwiseAttribute>
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          isDisableSelection
          filter={{
            filterConfigRef,
            filterCollection: [
              {
                type: 'text',
                label: 'ID Адаптера',
                propertyName: 'idIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID атрибута “Помощник”',
                propertyName: 'attributeIdIn',
                isMultiple: true,
              },
              {
                type: 'number',
                label: 'ID типа замера “Cropwise”',
                propertyName: 'measurementTypeIdIn',
                isMultiple: true,
              },
              {
                type: 'text',
                label: 'ID чек-листа “Помощник”',
                propertyName: 'checkListIdIn',
                isMultiple: true,
              },
              {
                type: 'select',
                label: 'Тип атрибута',
                propertyName: 'attributeTypeIn',
                isMultiple: true,
                optionList: attributeTypeList,
              },
              {
                type: 'text',
                label: 'Системное имя',
                propertyName: 'systemName',
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createDateTimeFrom',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createDateTimeTo',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateDateTimeFrom',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateDateTimeTo',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'attributeId',
              headerName: 'ID Атрибута',
              flex: 1,
              valueGetter: row => row.row.attributeId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'mapMeasurementValueType',
              headerName: 'Тип замера',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row?.mapMeasurementValueType?.dataType,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'systemName',
              headerName: 'Наименование',
              flex: 1,
              maxWidth: 160,
              valueGetter: row => row.row?.systemName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
          ]}
        />
      )}
    </Box>
  );
};

export default AttributeTable;
