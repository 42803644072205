import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  code: string;
  operId: string;
};

type TypeResponse = {
  token: string;
  expirationDate: string;
};

type ErrorItem = {
  source: any;
  title: string;
};

type TypeError = {
  response?: {
    data?: {
      error?: ErrorItem;
      errors?: ErrorItem[];
    };
  };
};

export const checkSmsCodeUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/users/checkSmsCode`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
