import { Box } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../constants/colors';

const Sidebar = styled(Box)`
  width: 100%;
  height: calc(100vh - 180px);
  max-width: 340px;
  min-width: 300px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 16px 12px;
  margin-right: 12px;
  background-color: ${Colors.white};
`;

const Styled = {
  Sidebar,
};

export default Styled;
