import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { OrgIntegraTableFilterController } from '../../mobx';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { IOrganizationIntegraTableFilter } from '../../../../types/organizationIntegraTable.types';

import { OrganizationIntegraTableFilterContainer } from './components';

const OrganizationIntegraTableFilter: FC<{ filter: IOrganizationIntegraTableFilter }> = ({
  filter,
}) => {
  const { initiateFilter, clearOnUnmount } = useStore(OrgIntegraTableFilterController);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = {};

    for (const [key, value] of searchParams) {
      if (key !== 'page' && key !== 'size') {
        queryParams[key] = value;
      }
    }

    initiateFilter(filter, queryParams, filter?.filterConfigRef);

    return () => {
      clearOnUnmount();
    };
  }, []);

  return <OrganizationIntegraTableFilterContainer />;
};

export default observer(OrganizationIntegraTableFilter);
