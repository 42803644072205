import { TypeApiRoute } from '../../models/type.api.request';
import { TypeNews } from '../../models/news.model';

type TypeRequest = {
  newsId: string;
};

type TypeResponse = TypeNews;

type TypeError = {};

export const getNewsItem: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-news/news/${request.newsId}`,
  headers: {},
  method: 'GET',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
