import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistAttributeODZ } from '../../../models/checklist.attribute.model';

type TypeRequest = {
  attributeId: string;
  checklistAttributeId?: string;
};

type TypeResponse = IChecklistAttributeODZ[];

type TypeError = {};

export const getChecklistAttributeODZ: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ attributeId }: TypeRequest) =>
    `/api/as-fields/admin/attribute/${attributeId}/stoValidationCriteriaList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
