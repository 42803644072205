import { ENotificationType, ENotificatorTheme } from '@farmlink/farmik-ui';

export enum ENotificationStyles {
  Success = 'Success',
  Error = 'Error',
}

export const NOTIFICATION_SCHEME: Record<ENotificationStyles, any> = {
  Success: {
    placement: 'bottom-left',
    type: ENotificationType.Success,
    theme: ENotificatorTheme.Dark,
  },
  Error: {
    placement: 'bottom-left',
    type: ENotificationType.Warning,
    theme: ENotificatorTheme.Dark,
  },
};
