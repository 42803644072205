import { observer } from 'mobx-react';
import { FC } from 'react';
import { Button } from '@mui/material';
import { useNotificator } from '@farmlink/farmik-ui';
import { toJS } from 'mobx';

import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { ReferenceForm, ReferenceSidebar } from '../../components';
import { ReferenceController, ReferenceStore } from '../../mobx';
import { useStore } from '../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';

const ReferencesContainer: FC = () => {
  const store = useStore(ReferenceStore);
  const controller = useStore(ReferenceController);

  const { setNotification } = useNotificator();

  const saveReference = () => {
    setTimeout(
      () =>
        controller
          .updateRef()
          .then(() => {
            setNotification(
              getNotificatorProps('Подсказка успешно сохранена', ENotificationStyles.Success)
            );
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка обновления подсказки', ENotificationStyles.Error)
            );
          }),
      500
    );
  };

  return (
    <Layout menuItems={null} sidebarComponent={ReferenceSidebar}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Сервис подсказок</H1>
            <Button onClick={saveReference} variant="contained">
              Сохранить
            </Button>
          </TopSection>
        </HeaderWrapper>
        <MainBody minHeight="calc(100vh - 198px)">
          <ReferenceForm
            key={String(store.activeReference?.path)}
            reference={toJS(store.activeReference)}
            setValue={store.updateActiveReference}
          />
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(ReferencesContainer);
