import { TypeApiRoute } from '../../models/type.api.request';
import { UserInfoType } from '../../models/user.model';

type TypeRequest = {};

type TypeResponse = UserInfoType;

type TypeError = {};

export const getProfile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/users/myProfile/`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
