import { IImportOperationModel } from '../../models/importOperation.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  clientId: number;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
};
type TypeResponse = IImportOperationModel;

export const triggerDataReport: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agrosignal/reports/triggerImport`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
