import * as Yup from 'yup';

import { EFormFields } from '../../../api/models/user.admin.model';

export const userFormCreateSchema = Yup.object().shape({
  [EFormFields.lastName]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.firstName]: Yup.string()
    .min(2, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.secondName]: Yup.string().min(
    2,
    ({ min }) => `Минимальное значение поля ${min} символов`
  ),

  [EFormFields.phoneNum]: Yup.string()
    .matches(/^[0-9]+$/, {
      message: 'В поле должны быть только цифры',
      excludeEmptyString: true,
    })
    .length(10, ({ length }) => `Длина поля составляет ${length} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.email]: Yup.string()
    .max(100, ({ max }) => `Максимальное значение поля ${max} символов`)
    .email('Невалидный адрес почты')
    .required('Поле обязательно для заполнения'),

  [EFormFields.password]: Yup.string()
    .min(6, ({ min }) => `Минимальное значение поля ${min} символов`)
    .required('Поле обязательно для заполнения'),

  [EFormFields.dateOfBirth]: Yup.string().required('Поле обязательно для заполнения'),

  [EFormFields.gender]: Yup.object({
    genderId: Yup.string().required(),
    genderName: Yup.string().required(),
  })
    .nullable()
    .required('Поле обязательно для заполнения'),

  [EFormFields.role]: Yup.object({
    roleId: Yup.string().required(),
    roleName: Yup.string().required(),
  })
    .nullable()
    .required('Поле обязательно для заполнения'),

  [EFormFields.avatar]: Yup.string(),

  [EFormFields.includedInStats]: Yup.boolean(),

  [EFormFields.domain]: Yup.string(),
});
