import { generatePath, useHistory } from 'react-router-dom';

import { EOrgIntegraCropwiseRoutes } from '../routers';

const useIntegraCropwiseNavigation = ({ organizationId }) => {
  const history = useHistory();

  const getPath = (route: EOrgIntegraCropwiseRoutes) => {
    return `/organizations/:organizationId/integra/cropwise/${route}`;
  };

  const goToCultureTab = ({ page, size }: { page?: number; size?: number }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    history.push(generatePath(getPath(EOrgIntegraCropwiseRoutes.Cultures), { organizationId }), {});
  };

  const goToAttributeTab = ({
    page,
    size,
    checkListIdIn,
  }: {
    page?: number;
    size?: number;
    checkListIdIn?: string;
  }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    if (checkListIdIn) {
      searchParams.set('checkListIdIn', checkListIdIn);
    }

    history.push({
      pathname: generatePath(getPath(EOrgIntegraCropwiseRoutes.Attributes), { organizationId }),
      search: searchParams.toString(),
    });
  };

  const goToChecklistTab = ({
    page,
    size,
    query,
  }: {
    page?: number;
    size?: number;
    query?: Record<string, string>;
  }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    if (query) {
      Object.keys(query).forEach(key => {
        searchParams.set(key, query[key]);
      });
    }

    history.push({
      pathname: generatePath(getPath(EOrgIntegraCropwiseRoutes.Checklists), { organizationId }),
      search: searchParams.toString(),
    });
  };

  const goToChecklistInstanceTab = ({
    page,
    size,
    query,
  }: {
    page?: number;
    size?: number;
    query?: Record<string, string>;
  }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    if (query) {
      Object.keys(query).forEach(key => {
        searchParams.set(key, query[key]);
      });
    }

    history.push({
      pathname: generatePath(getPath(EOrgIntegraCropwiseRoutes.ChecklistInstances), {
        organizationId,
      }),
      search: searchParams.toString(),
    });
  };

  const goToMeasurementTab = ({
    page,
    size,
    query,
  }: {
    page?: number;
    size?: number;
    query?: Record<string, string>;
  }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    if (query) {
      Object.keys(query).forEach(key => {
        searchParams.set(key, query[key]);
      });
    }

    history.push({
      pathname: generatePath(getPath(EOrgIntegraCropwiseRoutes.Measurements), { organizationId }),
      search: searchParams.toString(),
    });
  };

  const goToPhotosTab = ({
    page,
    size,
    query,
  }: {
    page?: number;
    size?: number;
    query?: Record<string, string>;
  }) => {
    const searchParams = new URLSearchParams(location.search);

    if (page || size) {
      searchParams.set('page', page.toString());
      searchParams.set('size', size.toString());
    }

    if (query) {
      Object.keys(query).forEach(key => {
        searchParams.set(key, query[key]);
      });
    }

    history.push({
      pathname: generatePath(getPath(EOrgIntegraCropwiseRoutes.Photos), { organizationId }),
      search: searchParams.toString(),
    });
  };

  return {
    goToCultureTab,
    goToChecklistTab,
    goToMeasurementTab,
    goToPhotosTab,
    goToAttributeTab,
    goToChecklistInstanceTab,
  };
};

export default useIntegraCropwiseNavigation;
