import { FC } from 'react';

import { AdvancedGridFilter } from '../../common';

import Styled from './styled';

const ChecklistSidebarContainer: FC = () => {
  return (
    <Styled.Sidebar>
      <AdvancedGridFilter />
    </Styled.Sidebar>
  );
};

export default ChecklistSidebarContainer;
