import { MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-mui';
import { FC, memo } from 'react';

import { ISelectOption } from '../../../../../../../../types/selectOption';

import Styled from './styled';

const SelectInput: FC<{
  itemKey: string;
  disabled?: boolean;
  attributeFormItemsScheme: any;
  onChangeHandler?: (value: any) => void;
}> = ({ itemKey, disabled, attributeFormItemsScheme, onChangeHandler }) => {
  const { values } = useFormikContext();

  return (
    <Styled.SelectInputWrapper>
      <Field
        component={Select}
        name={itemKey}
        type="text"
        label={attributeFormItemsScheme[itemKey]?.label}
        disabled={attributeFormItemsScheme[itemKey]?.disabled || disabled}
        value={values[itemKey] || ''}
        onChange={(e: PointerEvent) => {
          const changedValue = (e.target as HTMLOptionElement).value;

          onChangeHandler?.(changedValue);
        }}
        fullWidth
        formControl={{ fullWidth: true }}
      >
        {attributeFormItemsScheme[itemKey]?.optionList.map((item: ISelectOption) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </Styled.SelectInputWrapper>
  );
};

export default memo(SelectInput);
