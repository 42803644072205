import { Badge, IconButton } from '@mui/material';
import { FC } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import EjectOutlinedIcon from '@mui/icons-material/EjectOutlined';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../../shared/utils';
import { OrgIntegraTableFilterStore, OrgIntegraTableFilterController } from '../../../../mobx';

const OrganizationIntegraTableFilterActiveButton: FC = () => {
  const { isOpen, appliedFiltersCount } = useStore(OrgIntegraTableFilterStore);

  const { openFilter, closeFilter } = useStore(OrgIntegraTableFilterController);

  const handleButtonClick = () => (isOpen ? closeFilter() : openFilter());

  return (
    <Badge
      badgeContent={appliedFiltersCount}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <IconButton onClick={handleButtonClick}>
        {isOpen ? <EjectOutlinedIcon /> : <FilterListIcon />}
      </IconButton>
    </Badge>
  );
};

export default observer(OrganizationIntegraTableFilterActiveButton);
