import { Box } from '@mui/material';
import styled from 'styled-components';

const InputGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: max-content;
`;

const SelectWrapper = styled.div`
  max-width: calc(100% - 212px);
  min-width: 100px;
  width: 100%;
`;

const Styled = {
  InputGroup,
  SwitchLabel,
  SelectWrapper,
};

export default Styled;
