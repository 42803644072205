import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  userId: string;
  email?: string;
  countryCode: string;
  phoneNumber: string;
  externalKey?: string;
};

type ErrorItem = {
  source: any;
  title: string;
};

type TypeResponse = {
  operId: string;
};

type TypeError = {
  response?: {
    data?: {
      error?: ErrorItem;
      errors?: ErrorItem[];
    };
  };
};

export const sendSmsCodeUserAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) =>
    `/api/da-profile/users/admin/phoneConfirm/sendCode/${request.userId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
