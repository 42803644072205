import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import IEnumDependency from '../../models/enumDependency.model';

type TypeRequest = {
  id: string;
};

type TypeResponse = TypeResponsePageable & { content: IEnumDependency[] };

export const deleteEnumDependency: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/attribute/enum/dependencyLinks/${params.id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
