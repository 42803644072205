import { v4 } from 'uuid';
import omitDeep from 'omit-deep-lodash';
import { assign, isEmpty, isNil } from 'lodash';

import { ChecklistAttributeService } from '../../../../../../../../services';
import { lazyInject, provide } from '../../../../../../../../shared/utils';
import { EFormulaTypes, IAggregationFormulaType } from '../../../models';
import { IFormulaError } from '../../../models/FormulaError.types';
import { FormulasStore } from '../../stores';
import { FormulaValidationService } from '../../services';
import { EErrorCollection, errorCollection } from '../../../configs';
import { IODZ } from '../../../../../../../../../api/models/checklist.attribute.model';
import { IChecklistAttribute } from '../../../../../../../../../api/models/checklist.model';

@provide.singleton()
class FormulasController {
  @lazyInject(FormulasStore)
  protected store: FormulasStore;

  @lazyInject(ChecklistAttributeService)
  protected attributeService: ChecklistAttributeService;

  @lazyInject(FormulaValidationService)
  protected validationService: FormulaValidationService;

  fetchFormula = async (attributeId: string) => {
    this.store.setIsAttributeLoading(true);

    const attribute = await this.attributeService.getAttributeInChecklist({ id: attributeId });

    this.store.setChecklistAttribute(attribute);

    if (attribute.calculate) {
      this.store.setFormulaCollection(EFormulaTypes.Calculation, attribute.calculate);
    }

    if (attribute.visibility) {
      this.store.setFormulaCollection(EFormulaTypes.Visibility, attribute.visibility);
    }

    if (attribute?.dependency?.dependencies) {
      attribute.dependency.dependencies.forEach(item => {
        const id = v4();
        this.store.setFormulaDepCollection(id, { ...item, clientId: id });
      });
    }

    this.store.setIsAttributeLoading(false);

    return attribute;
  };

  setFormulaSerializedData = (data: IAggregationFormulaType) => {
    this.store.setIsChanged(true);
    this.store.setSerializedFormula(data);
  };

  setFormulaDepSerializedData = (data: IAggregationFormulaType) => {
    this.store.setIsChanged(true);
    this.store.setSerializedDepFormulaCollection(data);
  };

  addError = (error: IFormulaError, id?: string): string => {
    const _id = id ?? v4();

    this.store.addItemToErrorLog(_id, error);

    return _id;
  };

  removeError = (id: string) => {
    this.store.removeItemFromErrorLog(id);
  };

  updateChecklistAttributeFormula = (
    id: string,
    type: EFormulaTypes,
    autoComplete?: boolean,
    odzPayload?: Partial<IODZ>,
    syncSto?: boolean
  ) => {
    try {
      if (type !== EFormulaTypes.Dependency) {
        this.validationService.validateFormula(this.store.serializedFormula);
      }

      const payload = {
        id,
        [type]: this.removeClientId(this.store.serializedFormula),

        ...(syncSto ? { syncSto } : {}),
      } as IChecklistAttribute;

      if (type === EFormulaTypes.Dependency) {
        const dependencies = Array.from(this.store.serializedDepFormulaCollection.values()).flatMap(
          formula => {
            return formula;
          }
        );

        payload[type] = {
          dependencies,
          autoComplete,
        };
      }

      if (type === EFormulaTypes.ODZ) {
        assign(payload, odzPayload);

        // зачищаем ОДЗ добавленное по общему принципу формул
        delete (payload as any).ODZ;
      }

      if (payload?.visibility && isEmpty(payload?.visibility)) payload.visibility = null;
      if (payload?.calculate && isEmpty(payload?.calculate)) payload.calculate = null;
      if (payload?.dependency && isEmpty(payload?.dependency)) payload.dependency = null;
      if (payload?.dependency && payload?.dependency?.dependencies.length === 0)
        payload.dependency = null;

      return this.attributeService.changeAttributeInChecklist(payload).then(() => {
        this.store.setIsChanged(false);
        this.store.clearFormulaDepCollection();
        this.store.clearSerializedDepFormulaCollection();
      });
    } catch (error) {
      console.log(error);

      return Promise.reject(error);
    }
  };

  deleteChecklistAttributeFormula = (id: string, type: EFormulaTypes) => {
    return this.attributeService
      .changeAttributeInChecklist(
        {
          ...this.store.checklistAttribute,
          [type]: null,
        },
        false
      )
      .then(() => {
        this.store.setIsChanged(false);
        this.store.removeFormulaItem(type);
        this.store.clearFormulaDepCollection();
        this.store.clearSerializedDepFormulaCollection();
      });
  };

  validateFormulaBlock = (formula: IAggregationFormulaType) => {
    try {
      this.validationService.validateFormulaBlock(formula);
    } catch (error) {
      const { message, cause } = error as Error & {
        cause: {
          elementId: string;
          type: EErrorCollection;
        };
      };

      if (!cause?.elementId || cause?.type === EErrorCollection.MISSING_TYPE) {
        return;
      }

      const errorItem = {
        text: message || errorCollection.UNKNOWN_ERROR,
        errorType: cause.type || EErrorCollection.UNKNOWN_ERROR,
        HTMLElementId: cause.elementId,
      };

      this.store.addItemToErrorLog(formula.clientId, errorItem);

      return errorItem;
    }
  };

  removeClientId = (obj: IAggregationFormulaType) => {
    return omitDeep(obj, 'clientId');
  };
}

export default FormulasController;
