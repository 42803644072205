import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistAttributeLabel } from '../../../models/checklist.model';

type TypeRequest = {};

type TypeResponse = IChecklistAttributeLabel[];

type TypeError = {};

export const getChecklistAttributeLabelList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/as-fields/admin/attribute/labelsList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
