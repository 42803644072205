import { MenuItem } from '@mui/material';
import { FC, memo } from 'react';

import { TDictionaryItem } from '../../../../../../../../api/models/dictionaries.model';

import { useContextMenuOptions } from './hooks/useContextMenuOptions';

const ContextMenu: FC<{ remoteName: string; id: string; value: TDictionaryItem }> = ({
  remoteName,
  id,
  value,
}) => {
  const { options } = useContextMenuOptions(remoteName, id, value);

  return (
    <div>
      {options.map(option => (
        <MenuItem key={option.label} onClick={option.onClick}>
          {option.label}
        </MenuItem>
      ))}
    </div>
  );
};

export default memo(ContextMenu);
