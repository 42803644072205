import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ErrorMessageSinglePushForm } from './components';

const ERROR_FILE_SINGLE_PUSH_ID = 'errorMessageSinglePush';

const errorFileSinglePush: TModalConfig = {
  id: ERROR_FILE_SINGLE_PUSH_ID,
  name: ERROR_FILE_SINGLE_PUSH_ID,
  title: '',
  type: EModalType.Custom,
  children: ErrorMessageSinglePushForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { errorFileSinglePush, ERROR_FILE_SINGLE_PUSH_ID };
