import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { useParams } from 'react-router-dom';
import { Colors } from '@farmlink/farmik-ui';
import { toJS } from 'mobx';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import useFormActions from '../../hooks/useFormActions';
import { OrganizationReportAssistanceFieldsStore } from '../../mobx/stores';
import { useStore } from '../../../../../../../../../shared/utils';
import { IFieldReportConfig } from '../../../../../../../../../../api/models/reportConfig.model';

interface IProps {
  config: Partial<IFieldReportConfig>;
  onOpenHistory: (id: string) => void;
}

const OrganizationReportsAssistanceForm: FC<IProps> = ({ config, onOpenHistory }) => {
  const store = useStore(OrganizationReportAssistanceFieldsStore);

  const [isCollapsed, setIsCollapsed] = useState(config?.state === 'update');

  const { organizationId } = useParams<{ organizationId?: string }>();

  const actions = useFormActions({ organizationId, defaultConfig: config });

  const onSubmit = (
    values: IFieldReportConfig,
    formikHelpers: FormikHelpers<Partial<IFieldReportConfig>>
  ) => {
    actions.submitConfig(values);
  };

  const formik = useFormik<Partial<IFieldReportConfig>>({
    initialValues: config,
    onSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const onCollapse = () => {
    setIsCollapsed(true);
  };

  const onDelete = () => {
    if (config?.state === 'update') {
      actions.deleteConfig();
    }
    if (config?.state === 'create') {
      store.deleteConfigById(config.id);
    }
  };

  const years = useMemo<number[]>(() => {
    const currentYear = moment().add(20, 'y').get('year');
    const _years = [];

    for (let i = 0; i < 41; i++) {
      _years.unshift(currentYear - i);
    }

    return _years;
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: Colors.secondaryGray,
        padding: '12px',
        borderRadius: 2,
        position: 'relative',
      }}
    >
      {isCollapsed ? (
        <Grid item xs={5}>
          <Box
            display={'flex'}
            gap={2}
            alignItems={'center'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            position={'relative'}
          >
            <Tooltip title="Развернуть">
              <IconButton>
                <AspectRatioIcon />
              </IconButton>
            </Tooltip>

            <Box display={'flex'} flexDirection={'column'}>
              <Typography>
                <b>Автоматическая:</b>
                {formik.values?.enabled ? ' да' : ' нет'}
              </Typography>

              <Typography>
                <b>Сезоны: </b>
                {formik?.values?.seasons?.length > 0
                  ? toJS(formik?.values?.seasons).sort().join(', ')
                  : 'Не указано'}
              </Typography>
              <Typography fontSize={12}>
                <b>ID: </b>
                {!formik?.values?.id || formik?.values?.id === ''
                  ? 'Не указано'
                  : formik?.values?.id}
              </Typography>
            </Box>
            {formik.values.state === 'create' && (
              <Tooltip title="Эта запись ещё не сохранена" placement="top">
                <FiberNewIcon sx={{ position: 'absolute', top: '-10px', left: '8px' }} />
              </Tooltip>
            )}
          </Box>
        </Grid>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Autocomplete
                options={years}
                value={formik.values.seasons}
                multiple
                disableCloseOnSelect
                getOptionLabel={option => String(option)}
                onOpen={() => {
                  setTimeout(() => {
                    const optionEl = document.querySelector(
                      `#report-task-years-${moment().get('year')}`
                    );
                    optionEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }, 1);
                }}
                onChange={(e, value) => {
                  formik.setFieldValue('seasons', value);
                  store.addTouchedConfigFields(config.id, 'seasons');
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} id={`report-task-years-${option}`}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Сезон"
                    placeholder="Выберите сезоны"
                    error={Boolean(formik.errors?.seasons)}
                    helperText={formik.errors?.seasons}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <Tooltip title="Свернуть">
                <IconButton onClick={onCollapse}>
                  <AspectRatioIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    name="enabled"
                    checked={Boolean(formik.values.enabled)}
                    onChange={(e, value) => {
                      actions.toggleAutoUpload?.(
                        formik?.values?.id,
                        value,
                        config.state === 'create'
                      );

                      formik.setFieldValue('enabled', value);
                    }}
                  />
                }
                label="Автоматическая выгрузка"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label="Путь до папки автоматической выгрузки клиента"
                onChange={event => formik.setFieldValue('reportsLocation', event.target.value)}
                value={formik.values.reportsLocation}
                fullWidth
                error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
                helperText={formik.errors?.reportsLocation}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button onClick={formik.submitForm} variant="contained" fullWidth>
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button
                disabled={formik.values.state === 'create'}
                onClick={() => onOpenHistory(config.id)}
                color="info"
                variant="text"
              >
                История
              </Button>
            </Grid>
            <Grid item xs={2} gap={2}>
              <Button onClick={onDelete} color="error" variant="text">
                Удалить
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default OrganizationReportsAssistanceForm;
