import { ICreateOrgIntegraAgrosignalConfig } from '../../../../models/integra/config.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = ICreateOrgIntegraAgrosignalConfig;

type TypeResponse = string;

type TypeError = {};

export const createOrgIntegraAgrosignalConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-as/integra/organization`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
