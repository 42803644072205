import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Form = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const DescriptionList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DescriptionPair = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const DescriptionKey = styled.dt`
  flex-grow: 1;
  font-size: 14px;
`;

const DescriptionValue = styled.dd`
  margin: 0;
  font-size: 14px;
`;

const Phone = styled.a`
  color: ${Colors.black};
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Styled = {
  Form,
  DescriptionList,
  DescriptionKey,
  DescriptionValue,
  DescriptionPair,
  Phone,
  PhoneWrapper,
};

export default Styled;
