import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import { IOrgIntegraField } from '../../models';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { ORG_INTEGRA_FIELD_LOAD_MODAL_ID, ORG_INTEGRA_FIELD_MODAL_ID } from '../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';

const FieldTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteFieldList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = () => {
    openModalByModalId(
      ORG_INTEGRA_FIELD_MODAL_ID,
      {
        mode: 'create',
        organizationId,
        fieldId: null,
        agroFieldId: null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraField) => {
    openModalByModalId(
      ORG_INTEGRA_FIELD_MODAL_ID,
      {
        mode: 'update',
        organizationId,
        fieldId: value?.fieldId,
        agroFieldId: value?.agrosFieldId,
        id: value.id,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraField> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchFieldList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const onOpenLoadModal = () => {
    openModalByModalId(ORG_INTEGRA_FIELD_LOAD_MODAL_ID, null, () => {
      setIsSideLoadUpdate(true);
      setTimeout(() => setIsSideLoadUpdate(false), 0);
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraField>
          actionButton={{ text: 'Добавить поле', handler: onOpenModal, variant: 'success' }}
          additionalActionButtonList={[
            { text: 'Загрузить', handler: onOpenLoadModal, variant: 'success' },
          ]}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID Адаптер',
                propertyName: 'id',
                placeholder: 'Введите ID Адаптера',
              },
              {
                type: 'text',
                label: 'ID “Помощник”',
                propertyName: 'fieldId',
                placeholder: 'Введите ID “Помощник”',
              },
              {
                type: 'text',
                label: 'ID “Агросигнал”',
                propertyName: 'agroFieldId',
                placeholder: 'Введите ID “Агросигнал”',
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createStartDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createEndDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateStartDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateEndDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
            ],
          }}
          columnConfig={[
            {
              field: 'id',
              headerName: 'ID "Помощник"',
              flex: 1,
              valueGetter: row => row.row.fieldId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id1',
              headerName: 'ID "Агросигнал"',
              flex: 1,
              width: 100,
              valueGetter: row => row.row.agrosFieldId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id2',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id3',
              headerName: 'Дата и время создания',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'id4',
              headerName: 'Дата и время обновления',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default FieldTable;
