import { TypeApiRoute } from '../../../models/type.api.request';

import { IChecklistStage } from './../../../models/checklist.stage.model';

type TypeRequest = IChecklistStage;

type TypeResponse = IChecklistStage;

type TypeError = {
  errors: Array<{
    source: string;
    title: string;
    description: string;
  }>;
};

export const createChecklistStage: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/checklist/stage`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
