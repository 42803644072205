import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../models/type.api.request';
import { IZondModel } from '../../../models/zond.model';

type TypeRequest = Partial<{
  page: number;
  size: number;
  ownerId: string;
  enabled: boolean;
  withoutOwnerOnly: boolean;
  context: string;
}>;
type TypeResponse = TypeResponsePageable & { content: Array<IZondModel> };

export const getZondList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agropogoda/zonds/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
