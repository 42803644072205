import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_CHANGE_FORMULA_MODAL_ID = 'before-change-formula-modal-id';

const beforeChangeFormulaModalConfig: TModalConfig = {
  id: BEFORE_CHANGE_FORMULA_MODAL_ID,
  name: BEFORE_CHANGE_FORMULA_MODAL_ID,
  type: EModalType.Warning,
  title: 'Внимание',
  description: {
    value: 'Вы уверены, что хотите изменить тип? Ранее введённые данные будут потеряны!',
    $styles: { textAlign: 'center', marginBottom: '16px' },
  },
  styledProps: {
    $size: EModalSize.Small,
  },
  successButton: {
    title: 'Изменить',
    color: 'primary',
  },
  denyButton: {
    title: 'Отмена',
  },
};

export { BEFORE_CHANGE_FORMULA_MODAL_ID, beforeChangeFormulaModalConfig };
