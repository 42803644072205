import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ErrorFileMultiplePushForm } from './components';

const ERROR_FILE_MULTIPLE_PUSH_ID = 'errorFileMultiplePush';

const errorFileMultiplePush: TModalConfig = {
  id: ERROR_FILE_MULTIPLE_PUSH_ID,
  name: ERROR_FILE_MULTIPLE_PUSH_ID,
  title: '',
  type: EModalType.Custom,
  children: ErrorFileMultiplePushForm,
  styledProps: {
    $modalPaddings: '20px',
    $isFullContentWidth: true,
  },
};

export { errorFileMultiplePush, ERROR_FILE_MULTIPLE_PUSH_ID };
