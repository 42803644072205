import { Box, Button, FormControlLabel, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { Field, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { TextField, Switch } from 'formik-mui';
import { useParams } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';

import { OrgIntegraAgrosignalController, OrgIntegraAgrosignalStore } from '../../../mobx';
import { useStore } from '../../../../../../../../../../shared/utils';
import {
  IOrgIntegraConfigExtended,
  IUpdateOrgIntegraAgrosignalConfig,
} from '../../../../../../../../../../../api/models/integra/config.model';
import { ERequestStatus } from '../../../../../../../../../../shared/types/requests';
import { OrganizationsStore } from '../../../../../../../../../../stores/organizations/organizations.store';
import { orgIntegraAgrosignalOrganizationSchema } from '../schemas';
import { getNotificatorProps } from '../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../shared/constanst/notifications';

const API_KEY_HIDER = '************';

const OrgIntegraAgrosignalOrganization = () => {
  const store = useStore(OrgIntegraAgrosignalStore);
  const orgStore = useStore(OrganizationsStore);
  const controller = useStore(OrgIntegraAgrosignalController);

  const { organizationId } = useParams<{ organizationId: string }>();
  const { setNotification } = useNotificator();

  const onSubmit = (
    data: Partial<IUpdateOrgIntegraAgrosignalConfig & IOrgIntegraConfigExtended>,
    helpers: FormikHelpers<IUpdateOrgIntegraAgrosignalConfig & IOrgIntegraConfigExtended>
  ) => {
    const handler = store.isConfigExists ? controller.updateConfig : controller.createConfig;
    const successText = store.isConfigExists ? 'обновлена' : 'создана';
    const errorText = store.isConfigExists ? 'обновления' : 'создания';

    return handler(data)
      .then(resp => {
        setNotification(
          getNotificatorProps(`Конфигурация успешно ${successText}`, ENotificationStyles.Success)
        );

        if (!store.isConfigExists) return resp;
      })
      .catch(error => {
        setNotification(
          getNotificatorProps(
            `Ошибка ${errorText} конфигурации. ${error?.message}`,
            ENotificationStyles.Error
          )
        );

        // eslint-disable-next-line no-console
        console.log(error);

        return error;
      });
  };

  const formik = useFormik<Partial<IUpdateOrgIntegraAgrosignalConfig & IOrgIntegraConfigExtended>>({
    initialValues: {
      agroId: 0,
      agroName: '',
      name: '',
      host: '',
      isIntegrated: false,
      organizationId,
      organizationInn: '',
      organizationName: '',
      apiKey: '',
    },
    validationSchema: orgIntegraAgrosignalOrganizationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (store.configRequestStatus === ERequestStatus.Fulfilled && store.orgIntegraConfig) {
      formik.setValues(store.orgIntegraConfig);

      if (store.isConfigExists) {
        formik.setFieldValue('apiKey', API_KEY_HIDER);
      }
    }
  }, [store.orgIntegraConfig]);

  useEffect(() => {
    if (store.orgIntegraConfig) {
      return;
    }

    formik.setFieldValue('organizationId', orgStore.organization.organizationId ?? null);

    if (orgStore.organization.INN) {
      formik.setFieldValue('organizationInn', orgStore.organization.INN ?? null);
    }

    if (orgStore.organization.name) {
      formik.setFieldValue('organizationName', orgStore.organization.name ?? null);
    }
  }, [orgStore.organization]);

  const actionButtonText = store.isConfigExists ? 'Сохранить' : 'Создать';

  const handleDirtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    if (!name) return;

    store.addToDirtyList(name);

    formik.setFieldValue(name, name === 'isIntegrated' ? e.target.checked : e.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Box marginTop={2} maxWidth={800}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field
              component={TextField}
              fullWidth
              label="Название организации"
              name="organizationName"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={TextField}
              fullWidth
              label="ИНН организации"
              name="organizationInn"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={<Field component={Switch} type="checkbox" name="isIntegrated" />}
              label="Включить интеграцию"
              labelPlacement="start"
              name="isIntegrated"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              fullWidth
              label="id клиента в Агросигнале"
              name="agroId"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              fullWidth
              label="Наименование клиента в Агросигнале"
              name="agroName"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              fullWidth
              label="URL сервиса Агросигнал клиента"
              name="host"
              onChange={handleDirtyChange}
            />
          </Grid>
          <Grid item xs={8}>
            <Field
              component={TextField}
              fullWidth
              label="API - ключ клиента"
              name="apiKey"
              onChange={event => {
                if (store.isConfigExists) {
                  store.addToDirtyList(event.target.name);
                  formik.setFieldValue('apiKey', event.target.value.replaceAll('*', ''));
                } else {
                  formik.setFieldValue('apiKey', event.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button onClick={formik.submitForm} variant="contained">
              {actionButtonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormikProvider>
  );
};

export default observer(OrgIntegraAgrosignalOrganization);
