import { IOrgIntegraCropwiseErrorFilePush } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseErrorFile/models/orgIntegraCropwiseErrorFile';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraCropwiseErrorFilePush>;

type TypeResponse = {
  files: [
    {
      checklistInstanceId: string;
      ids: string[];
    }
  ];
};

type TypeError = {};

export const pushOrgIntegraCropwiseErrorFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/integration/postProcess/pushFile`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
