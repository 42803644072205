import { TypeCultureClass, TypeCultureClassFilters } from '../../models/cultureClass.model';
import { TypeCurrency } from '../../models/currency.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size: number;
  page: number;
  latestVersion: boolean;
};

type TypeResponse = { content: TypeCurrency[] } & TypeResponsePageable;

type TypeError = {};

export const getCurrencyList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-dictionary/dictionary/currency/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
