import { Colors } from '@farmlink/farmik-ui';
import { List, ListItem, ListItemButton } from '@mui/material';
import styled, { css } from 'styled-components';

const SidebarWrapper = styled.aside`
  width: 40%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  background-color: ${Colors.generalWhite};
  margin-right: 1.5rem;
  padding: 1rem;
  flex-shrink: 0;
`;

const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const RefList = styled(List)`
  /* height: calc(100vh - 390px); */
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${Colors.secondaryBGGrey};
  margin-top: 0.5rem !important;

  border-radius: 1rem;
`;

const Item = styled(ListItem)<{ $selected?: boolean }>`
  ${({ $selected }) =>
    $selected &&
    css`
       {
        background-color: ${Colors.lightGreen};
      }
    `}
`;

const ItemContent = styled(ListItemButton)`
  display: flex;
  flex-direction: column;
`;

const Styled = {
  SidebarWrapper,
  FilterForm,
  RefList,
  Item,
  ItemContent,
};

export default Styled;
