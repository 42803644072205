import { TypeApiRoute } from '../../models/type.api.request';
import { EEmployeeRole } from '../../models/employee.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import { EInvitationStatus, IInvitation } from '../../models/invitation.model';

type TypeRequest = {
  organizationId: string;
  type?: EEmployeeRole;
  status?: EInvitationStatus;
  roleId?: string;
  fullName?: string;
  size?: number;
  page?: number;
  sort?: string;
};

type TypeResponse = TypeResponsePageable & { content: IInvitation[] };

type TypeError = {};

export const getOrganizationApplicationList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/organizations/applications/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
