import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeAlert } from '../../../api/models/alert.model';

@provide.singleton()
export class AlertsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  error = null;
  loading = false;
  loaded = false;
  page = 0;
  totalPages = 0;

  alerts: TypeAlert[] = [];
  alert: TypeAlert = {} as TypeAlert;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setAlerts = (alerts: TypeAlert[], totalPages: number) => {
    runInAction(() => {
      this.alerts = alerts;
      this.totalPages = totalPages;
    });
  };

  setAlert = (alert: TypeAlert) => {
    runInAction(() => {
      this.alert = alert;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  clearAlerts = () => {
    this.setAlerts([], 0);
  };
}
