import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeTag } from '../../../api/models/tags.model';

@provide.singleton()
export class TagsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  tags: TypeTag[] = [];

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setTags = tags => {
    runInAction(() => {
      this.tags = tags;
    });
  };

  clearTags = () => {
    this.setTags([]);
  };
}
