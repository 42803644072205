import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios, localizeValidationTitle } from '../../shared/utils';
import { TypeNews, FormValueError } from '../../../api/models/news.model';
import { TypeUserActive, TypeAuthors } from '../../../api/models/user.model';

import { FilterStore } from './filter.store';

@provide.singleton()
export class NewsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  news: TypeNews[] = [];
  newsItem: TypeNews = {} as TypeNews;
  authors: TypeAuthors[] = [];

  formValuesErrors = {};

  page = 0;
  totalPages = 0;
  sort = '&sort=publicationDate,desc';

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setNews = (news: TypeNews[], totalPages: number) => {
    runInAction(() => {
      this.news = news;
      this.totalPages = totalPages;
    });
  };

  setNewsItem = (newsItem: TypeNews) => {
    runInAction(() => {
      this.newsItem = newsItem;
    });
  };

  setAuthors = (authors: TypeUserActive[]) => {
    const list = authors.map(author => ({
      authorId: author.id,
      authorName: `${author.lastName} ${author.firstName} ${author.secondName}`,
    }));

    runInAction(() => {
      this.authors = list;
    });
  };

  setFormValueErrors = (error: FormValueError) => {
    runInAction(() => {
      this.formValuesErrors = {
        ...this.formValuesErrors,
        [error.source]: localizeValidationTitle(error.title),
      };
    });
  };

  setFormValuesErrors = (errors: FormValueError[]) => {
    runInAction(() => {
      errors.forEach(({ source, title }) => {
        this.formValuesErrors = {
          ...this.formValuesErrors,
          [source]: localizeValidationTitle(title),
        };
      });
    });
  };

  clearNews = () => {
    this.setNews([], 0);
  };

  clearNewsItem = () => {
    this.setNewsItem({} as TypeNews);
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };
}
