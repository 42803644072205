import { Box } from '@mui/material';

import { ChecklistInstanceTable } from '../components';

const OrgIntegraAgrosignalChecklistInstances = () => {
  return (
    <Box>
      <ChecklistInstanceTable />
    </Box>
  );
};

export default OrgIntegraAgrosignalChecklistInstances;
