import { Divider } from '@mui/material';
import { last, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TDictionaryItem } from '../../../../../../../../../../../../../../api/models/dictionaries.model';
import { DictionariesController } from '../../../../../../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { DictionariesStore } from '../../../../../../../../../../../../../stores';
import { AbstractTreeView } from '../../../../../../../../../../common';
import { useAsyncTreeElement } from '../../../../../../../../../../common/AbstractTreeView';
import {
  IAbstractTreeViewConfig,
  ITreeNodeExtended,
} from '../../../../../../../../../../common/AbstractTreeView/AbstractTreeView.types';

import { LinkedDictionaryItem } from './components';
import Styled from './styled';

type getParenCallback = (config: {
  mode: 'dependent' | 'depend';
  parentPath: string[];
  requestParams: {
    dependencyName?: string;
    dependencyRecordId?: string;
    dependencyTargetName?: string;
    dependencyTargetPath?: string;
    parentId?: string;
  };
  dictRemoteName: string;
}) => void;

const LinkedDictionaries = () => {
  const { name: remoteName, itemId } = useParams<{ name: string; itemId: string }>();
  const { getDictionaryDependenciesTreeRoot, getLinkedDictionaries, getDictionaryItem } = useStore(
    DictionariesController
  );
  const { currentDictionaryItem } = useStore(DictionariesStore);

  const { getAsyncTreeNode } = useAsyncTreeElement();

  /**
   * От кого зависит
   */
  const [dependList, setDependList] = useState([]);
  /**
   *  Кто зависит
   */
  const [dependentList, setDependentList] = useState([]);

  const getParentElement = useCallback<getParenCallback>(
    async config => {
      try {
        const { mode, parentPath, requestParams, dictRemoteName } = config;

        const likedDictionaries = await getLinkedDictionaries(mode, dictRemoteName, requestParams);

        return likedDictionaries.map((dictionaryItem: TDictionaryItem) => {
          const nodeData = {
            label: dictionaryItem.name,
            nodeId: dictionaryItem.id,
            parentNode: last(parentPath),
            path: uniq([...parentPath, dictionaryItem.id]),
            hasChild: dictionaryItem.hasChild,
            alias: dictRemoteName,
          };

          const getChildHandler = dictionaryItem.hasChild
            ? (parentNode: ITreeNodeExtended) => {
                const payload: typeof requestParams =
                  mode === 'dependent'
                    ? {
                        dependencyName: requestParams.dependencyName,
                        dependencyRecordId: dictionaryItem.id,
                      }
                    : {
                        dependencyTargetName: requestParams.dependencyName,
                        dependencyTargetPath: currentDictionaryItem.path,
                      };

                if (parentNode.path.length >= 2) {
                  payload.parentId = last(parentNode.path);
                  delete payload.dependencyName;
                  delete payload.dependencyRecordId;
                }

                return getParentElement({
                  mode,
                  parentPath: uniq([...parentPath, dictionaryItem.id]),
                  requestParams: payload,
                  dictRemoteName: parentNode.path.length >= 2 ? parentNode.alias : remoteName,
                });
              }
            : undefined;

          return getAsyncTreeNode({
            getChildHandler,
            nodeData,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    [remoteName]
  );

  useEffect(() => {
    Promise.all([
      getDictionaryDependenciesTreeRoot({ dependencyName: remoteName }),
      getDictionaryDependenciesTreeRoot({ dictionaryName: remoteName }),
    ]).then(([dependents, depends]) => {
      setDependentList(
        dependents.map(dictionaryDep => {
          const nodeData = {
            label: dictionaryDep.name,
            nodeId: dictionaryDep.id,
            parentNode: 'ROOT',
            path: ['ROOT', dictionaryDep.id],
            hasChild: true,
            alias: dictionaryDep.dictionaryName,
          };

          const getChildHandler = () =>
            getParentElement({
              dictRemoteName: dictionaryDep.dictionaryName,
              mode: 'dependent',
              parentPath: ['ROOT', dictionaryDep.id],
              requestParams: {
                dependencyName: remoteName,
                dependencyRecordId: itemId,
              },
            });

          return getAsyncTreeNode({
            getChildHandler,
            nodeData,
          });
        })
      );

      setDependList(
        depends.map(dictionaryDep => {
          const nodeData = {
            label: dictionaryDep.name,
            nodeId: dictionaryDep.id,
            parentNode: 'ROOT',
            path: ['ROOT', dictionaryDep.id],
            hasChild: true,
            alias: dictionaryDep.dependencyName,
          };

          const getChildHandler = () =>
            getParentElement({
              mode: 'depend',
              parentPath: ['ROOT', dictionaryDep.id],
              requestParams: {
                dependencyTargetName: remoteName,
                dependencyTargetPath: currentDictionaryItem.path,
              },
              dictRemoteName: dictionaryDep.dependencyName,
            });

          return getAsyncTreeNode({
            getChildHandler,
            nodeData,
          });
        })
      );
    });
  }, []);

  const abstractTreeViewConfig = useMemo<IAbstractTreeViewConfig>(
    () => ({
      treeNodeComponent: LinkedDictionaryItem,
    }),
    []
  );

  return (
    <Styled.ColumnGroup>
      <Styled.Column>
        {dependList.length ? (
          <>
            <Styled.ColumnTitle>От кого зависит</Styled.ColumnTitle>
            <AbstractTreeView config={abstractTreeViewConfig} treeElementList={dependList} />
          </>
        ) : null}
      </Styled.Column>
      <Divider orientation="vertical" flexItem />
      <Styled.Column>
        {dependentList.length ? (
          <>
            <Styled.ColumnTitle>Кто зависит</Styled.ColumnTitle>
            <AbstractTreeView config={abstractTreeViewConfig} treeElementList={dependentList} />
          </>
        ) : null}
      </Styled.Column>
    </Styled.ColumnGroup>
  );
};

export default LinkedDictionaries;
