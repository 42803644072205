import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../../../shared/utils';
import {
  IAggregationFormulaType,
  TFormulaTypeUnion,
} from '../../../../../../../../../modules/Formulas/models';
import { IODZError } from '../../models';

type TDirtyFormulaFieldsUnion =
  | 'greenCriteria'
  | 'yellowCriteria'
  | 'redCriteria'
  | 'greenCriteriaComment'
  | 'yellowCriteriaComment'
  | 'redCriteriaComment'
  | 'isODZEnabled';

@provide.singleton()
class AttributeODZStore {
  private _greenCriteria: TFormulaTypeUnion = null;
  private _yellowCriteria: TFormulaTypeUnion = null;
  private _redCriteria: TFormulaTypeUnion = null;

  private _greenSerializedCriteria: IAggregationFormulaType = null;
  private _yellowSerializedCriteria: IAggregationFormulaType = null;
  private _redSerializedCriteria: IAggregationFormulaType = null;

  private _greenCriteriaComment: string = null;
  private _yellowCriteriaComment: string = null;
  private _redCriteriaComment: string = null;

  private _dirtyFormulaFields: Set<TDirtyFormulaFieldsUnion> = new Set();

  private _isODZEnabled = false;

  private _errorLogCollection: Map<string, IODZError> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get greenCriteria() {
    return this._greenCriteria;
  }

  get yellowCriteria() {
    return this._yellowCriteria;
  }

  get redCriteria() {
    return this._redCriteria;
  }

  get greenSerializedCriteria() {
    return this._greenSerializedCriteria;
  }

  get yellowSerializedCriteria() {
    return this._yellowSerializedCriteria;
  }

  get redSerializedCriteria() {
    return this._redSerializedCriteria;
  }

  get greenCriteriaComment() {
    return this._greenCriteriaComment;
  }

  get yellowCriteriaComment() {
    return this._yellowCriteriaComment;
  }

  get redCriteriaComment() {
    return this._redCriteriaComment;
  }

  get isODZEnabled() {
    return this._isODZEnabled;
  }

  get errorLog() {
    return this._errorLogCollection;
  }

  get dirtyFormulaFields() {
    return this._dirtyFormulaFields;
  }

  isFormulaFieldDirty = (field: TDirtyFormulaFieldsUnion) => {
    return this._dirtyFormulaFields.has(field);
  };

  setGreenCriteria = (data: TFormulaTypeUnion) => {
    this._greenCriteria = data;
  };
  setYellowCriteria = (data: TFormulaTypeUnion) => {
    this._yellowCriteria = data;
  };
  setRedCriteria = (data: TFormulaTypeUnion) => {
    this._redCriteria = data;
  };

  setGreenSerializedCriteria = (data: IAggregationFormulaType) => {
    this._greenSerializedCriteria = data;
  };
  setYellowSerializedCriteria = (data: IAggregationFormulaType) => {
    this._yellowSerializedCriteria = data;
  };
  setRedSerializedCriteria = (data: IAggregationFormulaType) => {
    this._redSerializedCriteria = data;
  };

  setGreenCriteriaComment = (data: string) => {
    this._greenCriteriaComment = data;
  };
  setYellowCriteriaComment = (data: string) => {
    this._yellowCriteriaComment = data;
  };
  setRedCriteriaComment = (data: string) => {
    this._redCriteriaComment = data;
  };

  setFormulaFieldDirty = (field: TDirtyFormulaFieldsUnion) => {
    this._dirtyFormulaFields.add(field);
  };

  setODZEnabled = (state: boolean) => {
    this._isODZEnabled = state;
  };

  addErrorToLog = (errorId: string, error: IODZError) => {
    this._errorLogCollection.set(errorId, error);
  };

  removeErrorFromLog = (errorId: string) => {
    this._errorLogCollection.delete(errorId);
  };

  clearDirtyFields = () => {
    this._dirtyFormulaFields = new Set();
  };

  clearStore = () => {
    this._greenCriteria = null;
    this._yellowCriteria = null;
    this._redCriteria = null;

    this._greenSerializedCriteria = null;
    this._yellowSerializedCriteria = null;
    this._redSerializedCriteria = null;

    this._greenCriteriaComment = null;
    this._yellowCriteriaComment = null;
    this._redCriteriaComment = null;

    this._isODZEnabled = false;

    this._dirtyFormulaFields = new Set();

    this._errorLogCollection = new Map();
  };
}

export default AttributeODZStore;
