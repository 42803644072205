import { IOrgIntegraAgrosignalChecklist } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalChecklists/models/orgIntegraAgrosignalChecklist';
import {
  IPageableRequest,
  TypeResponsePageable,
} from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = IPageableRequest & { organizationId: string };

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraAgrosignalChecklist[] };

type TypeError = {};

export const getOrgIntegraAgrosignalChecklistByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ organizationId }) =>
    `/api/integra-as/integra/checklist/byOrganizationId/${organizationId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
