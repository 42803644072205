import { Typography } from '@mui/material';
import { FC } from 'react';

import { TModule } from '../../../../api/models/action.model';

import Styled from './styled';
import { ActionItem } from './components';
import { IActionOptions } from './components/ActionItem/ActionItem';

export const ActionList: FC<{
  actionList: TModule[];
  getActionValue: (actionCode) => boolean;
  setModalMessage?: React.Dispatch<
    React.SetStateAction<{
      message: string;
      code: string;
    }>
  >;

  options: {
    isDisableUserScoutActions?: boolean;
    itemOptions: IActionOptions;
  };
}> = ({ actionList, getActionValue, setModalMessage, options }) => {
  return (
    <>
      {options?.isDisableUserScoutActions ? null : (
        <Styled.ModuleList>
          <ActionItem
            action={{
              name: 'Сделать скаутом',
              code: 'scout',
              id: 'isScout',
            }}
            getActionValue={getActionValue}
            onChangeModalMessage={setModalMessage}
            message="При удалении признака Скаут у пользователя будут удалены соответствующие права. Продолжить?"
            options={options.itemOptions}
            isBold
            isScout
          />
          <ActionItem
            action={{
              name: 'Сделать скаутом УХ',
              code: 'experiment',
              id: 'isExperiment',
            }}
            getActionValue={getActionValue}
            onChangeModalMessage={setModalMessage}
            message="При удалении признака Сделать скаутом УХ у пользователя будут удалены соответствующие права. Продолжить?"
            options={options.itemOptions}
            isBold
            isScout
          />
        </Styled.ModuleList>
      )}

      <Styled.ModuleList>
        {actionList?.map(module => (
          <Styled.ModuleGroup key={module.id}>
            <Typography variant="h6">{module.name}</Typography>
            {module.actions.map(action => (
              <ActionItem
                getActionValue={getActionValue}
                action={action}
                key={action.id}
                options={options.itemOptions}
              />
            ))}
          </Styled.ModuleGroup>
        ))}
      </Styled.ModuleList>
    </>
  );
};
