import { TypeApiRoute } from '../../models/type.api.request';
import { TypeNews } from '../../models/news.model';
import { EStatus } from '../../models/status.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  tags: string[];
  status: EStatus[];
  context: string;
  publicationDate?: string;
  author?: string;
  authorId?: string;
  pinned?: boolean;
  size: number;
  page: number;
  sort: any;
};

type TypeResponse = { content: TypeNews[] } & TypeResponsePageable;

type TypeError = {};

export const getNews: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request =>
    `/api/da-news/news/fullList?size=${request.size}&page=${request.page}${request.sort}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
