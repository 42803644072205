import { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Grid, Button, TextField, Box } from '@mui/material';
import moment from 'moment'

import { getValues } from '../AlertsList/components/AlertsTable/utils';
import { EFormFields } from '../../../../../../api/models/alert.model';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1, H2 } from '../../../../style';
import { useStore } from '../../../../../shared/utils';
import { AlertsStore } from '../../../../../stores/alerts/alerts.store';
import { AlertsController } from '../../../../../controllers/alerts.controller';

export const AlertTask: FC = observer(() => {

  const { fetchAlert, changeAlertStatus } = useStore(AlertsController);
    
  const { alert } = useStore(AlertsStore);

  const { alertId } = useParams<{ alertId?: string }>();

  const values = useMemo(() => getValues(alert), [alert]);

  const ButtonTypes = ['Ошибка', 'Отмена', 'Выполнено', 'Новая']

  useEffect(() => {
    fetchAlert({ alertId }); 
  }, []);

  const handleChangeStatus = e => {
    switch (e.target.innerText) {
      case 'ОШИБКА':
        changeAlertStatus({
          alertId,
          status: 'toError',
        });
        break;
      case 'ОТМЕНА':
        changeAlertStatus({
          alertId,
          status: 'toCanceled',
        });
        break;
      case 'ВЫПОЛНЕНО':
        changeAlertStatus({
          alertId,
          status: 'toCompleted',
        });
        break;
      case 'НОВАЯ':
        changeAlertStatus({
          alertId,
          status: 'toNew',
        });
        break;
      default:
        break;
    }
  };

  return (
    <MainContainer>
      <HeaderWrapper>
        <TopSection>
          <H1>Информация о задаче</H1>
        </TopSection>
      </HeaderWrapper>
      <MainBody>
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={values[EFormFields.name]}
                placeholder="Введите имя"
                label="Имя"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                value={values[EFormFields.fullName]}
                placeholder="Введите полное имя"
                label="Полное имя"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                value={values[EFormFields.createdBy]}
                placeholder="Введите название создателя"
                label="Создатель"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                value={values[EFormFields.error]}
                placeholder="Введите ошибку"
                label="Имя ошибки"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                value={values[EFormFields.status]}
                placeholder="Введите статус"
                label="Статус"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                value={values[EFormFields.errorDescription]}
                placeholder="Введите описание ошибки"
                label="Описание ошибки"
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                value={values[EFormFields.id]}
                placeholder="Введите айди"
                label="Айди"
                disabled
                variant="outlined"
              />
            </Grid>
            {values[EFormFields.taskUpdate]?.id &&
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  value={values[EFormFields.taskUpdate].id}
                  placeholder="Обновлено пользователем"
                  label="Обновлено пользователем"
                  disabled
                  variant="outlined"
                />
              </Grid>
            }
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={moment(values[EFormFields.timestamp]).format('DD.MM.YYYY')}
                placeholder="Введите последнее обновление"
                label="Последнее обновление"
                disabled
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt={2}>
          <H2> Смена статуса ошибки </H2>
          <Box display={'flex'}>
            {ButtonTypes.map((type) => (
              <Box ml={2} mt={1}>
                <Button onClick={handleChangeStatus} variant="contained">{type}</Button>
              </Box>
            ))}
          </Box>
        </Box>
      </MainBody>
    </MainContainer>
  );
});
