import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../shared/constanst/notifications';

import { DepsGrid, EnumDepend, EnumDepends } from './components';
import { EEnumDepsTab, TFormData } from './models';
import { EnumDependencyController } from './mobx';

const EnumDependency = () => {
  const controller = useStore(EnumDependencyController);

  const [currentTab, setCurrentTab] = useState<EEnumDepsTab>(EEnumDepsTab.Depends);

  const { attributeId } = useParams<{ attributeId: string }>();
  const { setNotification } = useNotificator();

  useEffect(() => {
    controller.fetchActiveEnumAttributeList();
    controller.fetchCurrentEnumValueList(attributeId);
  }, []);

  const onSave = (data: TFormData) => {
    return controller
      .createEnumDependencyLink(currentTab, data)
      .then(() => {
        const payload =
          currentTab === EEnumDepsTab.Depends
            ? { dependencyAttributeId: attributeId }
            : { targetAttributeId: attributeId };

        setNotification(getNotificatorProps('Связь успешно создана', ENotificationStyles.Success));

        controller.fetchDependencyList(currentTab, payload);
      })
      .catch(() =>
        setNotification(getNotificatorProps('Ошибка создания связи', ENotificationStyles.Error))
      );
  };

  return (
    <Box>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setCurrentTab(v)}>
            <Tab label="Кто зависит" value={EEnumDepsTab.Depends} />
            <Tab label="От кого зависит" value={EEnumDepsTab.Depend} />
          </TabList>
        </Box>
        <TabPanel value={EEnumDepsTab.Depends}>
          <EnumDepends onSave={onSave} />
        </TabPanel>
        <TabPanel value={EEnumDepsTab.Depend}>
          <EnumDepend onSave={onSave} />
        </TabPanel>

        <Box marginTop={2}>
          <DepsGrid type={currentTab} currentAttrId={attributeId} />
        </Box>
      </TabContext>
    </Box>
  );
};

export default memo(EnumDependency);
