import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
  ownerId: string;
};
type TypeResponse = string;

export const setOwnerZond: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ id, ownerId }: TypeRequest) => `/api/as-agropogoda/zonds/${id}/setOwner/${ownerId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
