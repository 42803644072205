import { Button } from '@mui/material';

import { IOrganizationIntegraTableButton } from '../../types/organizationIntegraTable.types';

const OrganizationIntegraTableButton = <T,>({
  text,
  handler,
  isDisabled,
  variant,
}: IOrganizationIntegraTableButton<T>) => {
  return (
    <Button
      variant={variant === 'success' ? 'contained' : 'text'}
      color={variant === 'success' ? 'primary' : 'error'}
      onClick={e => {
        handler?.(e, []);
      }}
      disabled={isDisabled}
    >
      {text ?? 'Кнопка'}
    </Button>
  );
};

export default OrganizationIntegraTableButton;
