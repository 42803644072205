import { FC, useRef } from 'react';

import { getImageUrl } from '../../utils';

import HoveredImagePlaceholder from './hoveredImagePlaceholder.svg';
import CameraSvg from './camera.svg';
import UserAvatarSvg from './user.svg';
import {
  Avatar,
  AvatarMock,
  AvatarWrapper,
  AvatarMockImgHovered,
  AvatarMockImg,
  StyledUserAvatarSvg,
  StyledCameraSvg,
  CameraSvgWrapper,
} from './style';

type UploaderProps = {
  fileId?: string;
  onFileChosen?: (file: File) => any;
  disabled?: boolean;
};

export const Uploader: FC<UploaderProps> = ({ fileId, onFileChosen, disabled = false }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = () => {
    const input = inputRef.current;

    if (input?.files?.length) onFileChosen(input.files[0]);
  };

  const getAvatarMockImg = () => (
    <AvatarMockImg data-test-id="avatar-uploader-mock-wrapper">
      <StyledUserAvatarSvg src={UserAvatarSvg} data-test-id="avatar-uploader-mock-background" />
      <CameraSvgWrapper data-test-id="avatar-uploader-mock-camera-svg-wrapper">
        <StyledCameraSvg src={CameraSvg} data-test-id="avatar-uploader-mock-camera-svg" />
      </CameraSvgWrapper>
    </AvatarMockImg>
  );

  return (
    <AvatarWrapper
      onClick={handleClick}
      data-test-id="avatar-uploader-wrapper-preview"
      disabled={disabled}
    >
      <input
        onChange={handleChange}
        ref={inputRef}
        name="file"
        type="file"
        accept=".png, .jpg, .jpeg"
      />

      {Boolean(fileId) ? (
        <Avatar src={getImageUrl(fileId as string)} data-test-id="avatar-uploader-image" />
      ) : (
        <AvatarMock data-test-id="avatar-uploader-mock">
          {getAvatarMockImg()}
          <AvatarMockImgHovered
            src={HoveredImagePlaceholder}
            alt=""
            data-test-id="avatar-uploader-mock-hovered"
          />
        </AvatarMock>
      )}
    </AvatarWrapper>
  );
};
