import { IOrganizationForUserDto } from '../../models/organization.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  orgTypeId?: number;
} & Partial<IOrganizationForUserDto>;

type TypeResponse = string;

type TypeError = {};

export const updateOrganizationById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-profile/admin/organizations/${params.organizationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
