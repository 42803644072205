import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { Colors, ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ISelectOption } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { useModalForm } from '../../hooks';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../../types/selectOption';
import {
  IOrgIntegraAgrosignalAgroField,
  IOrgIntegraAgrosignalAssistanceField,
} from '../../../../models';

const FieldModalForm = () => {
  const orgStore = useStore(OrganizationsStore);

  const [isProcessing, setIsProcessing] = useState(false);

  const { getModalPayload, closeModal, getModalRuntimeHandlers } = useModal();
  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId: string;
    id?: string;
    fieldId?: string;
    agroFieldId?: string;
  };
  const {
    assistanceFieldList,
    assistanceField,
    setAssistanceField,
    agrosignalField,
    setAgrosignalField,
    createField,
    updateField,
    agroFieldList,
    agrosignalFieldError,
    assistanceFieldError,
    setSearchAgrosignalField,
    setSearchAssistanceField,
    isAgrosignalFieldLoading,
    isAssistanceFieldLoading,
  } = useModalForm(orgStore?.organization?.organizationId);

  const handlers = getModalRuntimeHandlers();

  useEffect(() => {
    if (payload.fieldId) setAssistanceField({ label: payload.fieldId, value: payload.fieldId });
    if (payload.agroFieldId)
      setAgrosignalField({ label: payload.agroFieldId, value: payload.agroFieldId });
  }, [payload]);

  const onSuccess = () => {
    setIsProcessing(true);

    const handler = payload.mode === 'create' ? createField : updateField;

    handler(payload?.id)
      .then(() => {
        handlers?.onSuccess?.();

        closeModal();
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onDeny = () => {
    closeModal();
  };

  const titleText = `${payload.mode === 'create' ? 'Добавление' : 'Детали'} поля`;

  return (
    <Box>
      <ModalTitle>{titleText}</ModalTitle>

      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <TextField
          label="Наименование организации"
          value={orgStore?.organization?.name || ''}
          fullWidth
          disabled
        />

        <Autocomplete
          options={assistanceFieldList}
          fullWidth
          renderInput={props => (
            <TextField
              {...props}
              label={'ID “Помощник”'}
              placeholder={'Введите ID поля из системы “Помощник”'}
              helperText={assistanceFieldError}
              error={Boolean(assistanceFieldError)}
            />
          )}
          loading={isAssistanceFieldLoading}
          loadingText={'Загрузка данных...'}
          disablePortal
          value={assistanceField}
          renderOption={(props, option) => (
            <li {...props} key={String(option.value)}>
              <Box>
                <Typography>{option.label}</Typography>
                <Typography sx={{ fontSize: 12, color: Colors.grey }}>
                  {option.rawData.id}
                </Typography>
              </Box>
            </li>
          )}
          onChange={(e, val) => setAssistanceField(val as ISelectOption)}
          onInputChange={(e, val) => setSearchAssistanceField(val)}
          isOptionEqualToValue={(
            option: ISelectOptionExtended<IOrgIntegraAgrosignalAssistanceField>,
            value
          ) => option.rawData?.id === value.value}
        />

        <Autocomplete
          options={agroFieldList}
          fullWidth
          renderInput={props => (
            <TextField
              {...props}
              label={'ID “Агросигнал”'}
              placeholder={'Введите ID поля из системы “Агросигнал”'}
              helperText={agrosignalFieldError}
              error={Boolean(agrosignalFieldError)}
            />
          )}
          loading={isAgrosignalFieldLoading}
          loadingText={'Загрузка данных...'}
          disablePortal
          value={agrosignalField}
          renderOption={(props, option) => (
            <li {...props} key={String(option.value)}>
              <Box>
                <Typography>{option.label}</Typography>
                <Typography sx={{ fontSize: 12, color: Colors.grey }}>
                  {option.rawData.id}
                </Typography>
              </Box>
            </li>
          )}
          onChange={(e, val) => setAgrosignalField(val as ISelectOption)}
          onInputChange={(e, val) => setSearchAgrosignalField(val)}
          isOptionEqualToValue={(
            option: ISelectOptionExtended<IOrgIntegraAgrosignalAgroField>,
            value
          ) => option.rawData?.id === value.value}
        />

        <ModalFooter
          successButton={{
            title: 'Сохранить',
            color: 'primary',
            handler: onSuccess,
            disabled: isProcessing,
          }}
          denyButton={{ title: 'Отменить', color: 'default', handler: onDeny }}
        />
      </Box>
    </Box>
  );
};

export default observer(FieldModalForm);
