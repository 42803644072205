import { useEffect, useState } from 'react';

import {
  ChecklistAttributeService,
  DictionariesService,
} from '../../../../../../../../../../../services';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../../../../../api/models/checklist.model';
import { EChecklistAttributeType } from '../../../../../../../../../../../../api/models/checklist.attribute.model';
import collectDeepObjectKeys from '../../../../../../../../../../../shared/utils/collectDeepObjectKeys';

const useAvailablePropKeys = (
  selectedBlockAttribute?: ISelectOptionExtended<IChecklistAttribute>,
  attrId?: string
) => {
  const { getUniqueDictionaryAttributeList, getDictionaryItemListByNameOrId } = useStore(
    DictionariesService
  );

  const { getAttributeInChecklist } = useStore(ChecklistAttributeService);

  const [uniqueKeys, setUniqueKeys] = useState<string[]>([]);
  const [requestedAttr, setRequestedAttr] = useState<IChecklistAttribute>(null);

  useEffect(() => {
    (async () => {
      if (attrId && requestedAttr?.id !== attrId) {
        const attribute = await getAttributeInChecklist({ id: attrId });

        setRequestedAttr(attribute);
      }
    })();
  }, [attrId, requestedAttr?.id]);

  useEffect(() => {
    const attribute = requestedAttr;

    if (attribute?.attribute?.type === EChecklistAttributeType.Enum) {
      setUniqueKeys(['value', 'id']);
    }

    if (attribute?.attribute?.type === EChecklistAttributeType.DictionaryLink) {
      const fetchAdditionalAttributes = getUniqueDictionaryAttributeList({
        remoteName: attribute.attribute?.dictionaryLink,
        page: 0,
        size: 1000,
      }).then(data => data.content);

      const fetchDictionaryItemPropCodes = getDictionaryItemListByNameOrId(
        attribute.attribute?.dictionaryLink,
        null,
        { page: 0, size: 1 }
      ).then(data => data.content);

      const keySet = new Set<string>();

      Promise.all([fetchAdditionalAttributes, fetchDictionaryItemPropCodes]).then(
        ([additionalAttrList, dictItemLst]) => {
          additionalAttrList.forEach(item => {
            keySet.add(`attrs|${item.code}`);
          });

          if (dictItemLst?.length > 0) collectDeepObjectKeys(dictItemLst[0], null, keySet, '|');

          setUniqueKeys(Array.from(keySet.values()));
        }
      );
    }
  }, [requestedAttr?.id]);

  return { uniqueKeys };
};

export default useAvailablePropKeys;
