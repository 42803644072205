import { TypeBasis } from './basis.model';
import { TypeCulture } from './culture.model';
import { TypeCultureClass } from './cultureClass.model';
import { TypeCurrency } from './currency.model';
import { TypeEconomicRegion } from './economicRegion.model';
import { TypeProvider } from './provider.model';
import { TypeUnit } from './units.model';

export type TypeQuote = {
  id: string;
  culture: TypeCulture;
  cultureClass: TypeCultureClass;
  currency: TypeCurrency;
  provider: TypeProvider;
  basis: TypeBasis;
  economicRegion: TypeEconomicRegion;
  dateOfObs: string;
  units: TypeUnit;
  price: number;
  qualityMin?: number;
  qualityMax?: number;
  vat?: boolean;
};

export type TypeFilters = {
  cultureId?: any;
  cultureClassId?: any;
  basisId?: any;
  currencyId?: any;
  economicRegionId?: any;
};

export const quotesPageSize = 20;
