import { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Box, Drawer, Toolbar, List, ListItem } from '@mui/material';
import { isEmpty, has } from 'lodash';

import { MenuItemsProps } from '../../../shared/menu/type';

export interface SidebarProps extends HTMLAttributes<HTMLDivElement> {
  menuItems: MenuItemsProps;
  sidebarComponent?: FC;
}

export const Sidebar: FC<SidebarProps> = observer(
  ({ menuItems, sidebarComponent: SidebarComponent }) => {
    if (menuItems === null && SidebarComponent) {
      return <SidebarComponent />;
    }

    return (
      <div id="aside-menu">
        {!isEmpty(menuItems) && (
          <Drawer variant="permanent">
            {has(menuItems, 'back') && (
              <Toolbar>
                <NavLink
                  to={menuItems.back.link}
                  data-test-id={`sidebar-nav-item-${menuItems.back.slug}-back`}
                >
                  <menuItems.back.icon /> {menuItems.back.name}
                </NavLink>
              </Toolbar>
            )}

            <Box sx={{ overflow: 'auto' }} id="aside-menu-inner">
              <List>
                {Object.entries(menuItems)
                  .filter(([key]) => key !== 'back')
                  .map(([key, { name, link, icon: Icon, slug }]) => (
                    <NavLink to={link} data-test-id={`sidebar-nav-item-${slug}-link`} key={key}>
                      <ListItem button>
                        {Icon && <Icon />}
                        {name}
                      </ListItem>
                    </NavLink>
                  ))}
              </List>
            </Box>
          </Drawer>
        )}
      </div>
    );
  }
);
