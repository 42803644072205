import { TypeApiRoute } from '../../models/type.api.request';
import { IDependencyLink } from '../../models/dependendency.model';

type TypeRequest = {
  dependencyId: string;
  dependencyRecordId: string;
  targetPath?: string;
  targetPathName?: string;
};

type TypeResponse = IDependencyLink[];

export const getDependencyLinksByDependencyRecord: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/admin/dependencyLinks/byDependencyRecord`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
