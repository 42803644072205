import CloudIcon from '@mui/icons-material/Cloud';
import { Colors, Icon } from '@farmlink/farmik-ui';

import { AdminRoutes } from '../../../../../routes';
import {
  OrganizationReportsAgropogoda,
  OrganizationReportsAgrosignal,
  OrganizationReportsAssistanceFields,
  OrganizationReportsAssistanceTasks,
  OrganizationReportsCropwise,
  OrganizationReportsPogodavpole,
} from '../components';
import { IServiceItem } from '../models';
import { AgrosignalIcon, CropwiseIcon } from '../../../../../../shared/components';

const serviceListConfig: IServiceItem[] = [
  {
    name: 'Погодавполе',
    component: OrganizationReportsPogodavpole,
    path: AdminRoutes.OrganizationReportsPogodavpole,
    icon: CloudIcon,
  },
  {
    name: 'Агросигнал',
    component: OrganizationReportsAgrosignal,
    path: AdminRoutes.OrganizationReportsAgrosignal,
    icon: AgrosignalIcon,
  },
  {
    name: 'Помощник',
    icon: () => <Icon icon="sidebarFields" size={'medium'} fill={Colors.green} />,
    isGroup: true,
    childrenList: [
      {
        name: 'Поля',
        component: OrganizationReportsAssistanceFields,
        path: AdminRoutes.OrganizationReportsAssistanceFields,
      },
      {
        name: 'Задачи',
        component: OrganizationReportsAssistanceTasks,
        path: AdminRoutes.OrganizationReportsAssistanceTasks,
      },
    ],
  },
  {
    name: 'Cropwise',
    component: OrganizationReportsCropwise,
    path: AdminRoutes.OrganizationReportsCropwise,
    icon: CropwiseIcon,
  },
  {
    name: 'Агропогода',
    component: OrganizationReportsAgropogoda,
    path: AdminRoutes.OrganizationReportsAgropogoda,
  },
];

export default serviceListConfig;
