import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { AgrosignalReportForm } from './components';

const AGROSIGNAL_REPORT_MODAL_ID = 'agrosignal-report-modal';

const agrosignalReportModalConfig: TModalConfig = {
  id: AGROSIGNAL_REPORT_MODAL_ID,
  name: AGROSIGNAL_REPORT_MODAL_ID,
  type: EModalType.Custom,
  title: null,
  children: AgrosignalReportForm,

  styledProps: {
    $modalPaddings: '20px',
    $size: EModalSize.Large,
    $isFullContentWidth: true,
    $height: '100%',
  },
};

export { AGROSIGNAL_REPORT_MODAL_ID, agrosignalReportModalConfig };
