import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

import { Colors } from '../../../../../../../../../../../../../../../../../../constants/colors';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../types/selectOption';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils';
import { DictionariesStore } from '../../../../../../../../../../../../../../../../../stores';
import { AutocompleteSearchByNameOrId } from '../../../../../../../../../../../../../../common';
import { TDictionaryOption } from '../../../../../../../../../../../../../../common/AutocompleteSearchByNameOrId/AutocompleteSearchByNameOrId';
import { TDependencySelectOption } from '../../../../../../../../../../../../../../../../../../api/models/dependendency.model';
import { DictionariesController } from '../../../../../../../../../../../../../../../../../controllers/dictionaries.controller';

const NewDependency = observer(
  ({ mode, refetchDepsList }: { mode: 'depend' | 'dependent'; refetchDepsList: () => void }) => {
    const { name: remoteName, itemId } = useParams<{ name: string; itemId: string }>();
    const { dictionaryNameList, currentDictionaryItem } = useStore(DictionariesStore);
    const { saveNewDependency } = useStore(DictionariesController);

    const [selectedDictionary, setSelectedDictionary] = useState<ISelectOption>(null);
    const [nameOrId, setNameOrId] = useState<TDictionaryOption>(null);
    const [isDisplayFull, setIsDisplayFull] = useState(false);
    const [isError, setIsError] = useState<string | false>(false);

    useEffect(() => {
      setIsError(false);
    }, [selectedDictionary, nameOrId, remoteName]);

    const dictOptions = useMemo<ISelectOption[]>(
      () => dictionaryNameList.map(item => ({ value: item.remoteName, label: item.title })),
      [dictionaryNameList]
    );

    const handleClear = useCallback(() => {
      setSelectedDictionary(null);
      setNameOrId(null);
      setIsError(false);
      setIsDisplayFull(false);
    }, []);

    const handleSave = () => {
      const dictionaryName = mode === 'depend' ? remoteName : selectedDictionary.value;
      const dependencyName = mode === 'depend' ? selectedDictionary.value : remoteName;

      const payload = {
        dependencyPath: mode === 'depend' ? nameOrId.rawData.path : currentDictionaryItem.path,
        targetPath: mode === 'depend' ? currentDictionaryItem.path : nameOrId.rawData.path,
      };

      if (!dictionaryName || !dependencyName || !payload?.dependencyPath || !payload?.targetPath) {
        setIsError('Заполните поля');
        return;
      }

      saveNewDependency(dictionaryName, dependencyName, payload).then(() => {
        refetchDepsList();
        setIsDisplayFull(false);
      });
    };

    if (!isDisplayFull) {
      return (
        <Button
          startIcon={<AddIcon />}
          sx={{ fontSize: '16px' }}
          onClick={() => setIsDisplayFull(true)}
        >
          Добавить зависимость
        </Button>
      );
    }
    return (
      <Box
        sx={{
          paddingBottom: '6px',
          borderBottom: `1px solid ${Colors.gray}`,
        }}
      >
        <Typography variant="h6">Новая зависимость</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            maxWidth: '100%',
          }}
        >
          <Autocomplete
            options={dictOptions}
            renderInput={params => <TextField {...params} label="Справочник" />}
            onChange={(event, value) => {
              setSelectedDictionary(value as ISelectOption);
            }}
            value={selectedDictionary}
            fullWidth
          />
          {selectedDictionary ? (
            <AutocompleteSearchByNameOrId
              label="Имя или id записи"
              remoteName={selectedDictionary.value || null}
              onChange={(event, value) => setNameOrId(value as TDictionaryOption)}
              isDisplayId
            />
          ) : (
            <TextField disabled label="Имя или id записи" fullWidth />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Button onClick={handleSave} variant="contained">
              Сохранить
            </Button>
            <Button onClick={handleClear}>Отменить</Button>
          </Box>
        </Box>
        <Typography minHeight={18} paddingTop="12px" fontSize={12} color={Colors.red}>
          {isError ? isError : ''}
        </Typography>
      </Box>
    );
  }
);

export default NewDependency;
