import { TypeApiRoute } from '../../models/type.api.request';
import { TAction } from '../../models/action.model';

type TypeRequest = {
  userId: string;
  actionCode: string;
  active: boolean;
};

type TypeResponse = TAction[];

type TypeError = {};

export const updateUserAction: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params =>
    `/api/da-profile/admin/access/action/global/assign?userId=${params.userId}&actionCode=${params.actionCode}&active=${params.active}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
