import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';

import { IOrganizationIntegraTableFilterItemSelect } from '../../../../../types/organizationIntegraTable.types';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrgIntegraTableFilterStore } from '../../../mobx';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../../types/selectOption';

const OrgIntegraTableFilterItemSelect: FC<{
  item: IOrganizationIntegraTableFilterItemSelect;
  id: string;
}> = ({ item }) => {
  const { getComponentValue, addComponentValue, removeComponentValue } = useStore(
    OrgIntegraTableFilterStore
  );

  const [localOptions, setLocalOptions] = useState<ISelectOptionExtended<any>[]>(
    item?.optionList || []
  );
  const [searchValue, setSearchValue] = useState<string>(null);
  const [isClearState, setIsClearState] = useState<boolean>(false);

  const fetchData = () => {
    const payload = { ...item.defaultFetchValues };

    if (item?.searchKey) {
      payload[item.searchKey] = searchValue;
    }

    item?.optionListFetchHandler?.(payload)?.then(res => {
      if (item.optionMapHandler) {
        setLocalOptions(res.content.map(item.optionMapHandler));
      } else {
        setLocalOptions(res.content);
      }
    });
  };

  useEffect(() => {
    if (!item?.optionListFetchHandler) return;

    fetchData?.();
  }, []);

  useEffect(() => {
    if (!searchValue || !item?.searchKey) return;

    fetchData?.();
  }, [searchValue]);

  useEffect(() => {
    if (isClearState) {
      fetchData?.();
    }
  }, [isClearState]);

  const value = getComponentValue(item.propertyName)?.value;

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    _value: NonNullable<string | ISelectOptionExtended<any>>,
    reason: AutocompleteChangeReason
  ) => {
    setIsClearState(reason === 'clear');

    if (reason === 'clear' || !_value) {
      removeComponentValue(item.propertyName);
      return;
    }

    addComponentValue(item.propertyName, {
      value: _value,
      type: item.type,
      propertyName: item.propertyName,
    });
  };

  return (
    <Autocomplete
      options={localOptions}
      disabled={item.disabled}
      renderInput={params => <TextField {...params} label={item.label} />}
      onChange={onChange}
      value={value || null}
      inputValue={searchValue || ''}
      onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
      isOptionEqualToValue={(option: ISelectOptionExtended, _value: ISelectOptionExtended) =>
        option.value === _value.value
      }
    />
  );
};

export default observer(OrgIntegraTableFilterItemSelect);
