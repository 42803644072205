import { Box } from '@mui/material';
import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { ErrorMessageTable } from '../components';
import {
  errorMessageDetailsForm,
  errorMessageMultiplePush,
  errorMessageSinglePush,
} from '../modals';

const OrgIntegraAgrosignalErrorMessage = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList(
      [errorMessageDetailsForm, errorMessageMultiplePush, errorMessageSinglePush],
      'OrgIntegraAgrosignalErrorMessage'
    );
  }, []);

  return (
    <Box>
      <ErrorMessageTable />
    </Box>
  );
};

export default OrgIntegraAgrosignalErrorMessage;
