import { has } from 'lodash';

import {
  ICreateOrgIntegraCropwiseConfig,
  IOrgIntegraConfigExtended,
  IUpdateOrgIntegraCropwiseConfig,
} from '../../../../../../../../../../api/models/integra/config.model';
import { lazyInject, provide } from '../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../services';
import { OrgIntegraCropwiseStore } from '../stores';

@provide.transient()
class OrgIntegraCropwiseController {
  @lazyInject(OrgIntegraCropwiseStore)
  protected store: OrgIntegraCropwiseStore;

  @lazyInject(OrgIntegraCropwiseService)
  protected service: OrgIntegraCropwiseService;

  fetchConfig = (orgId: string) => {
    return this.service.fetchConfigById(orgId);
  };

  createConfig = (
    formData: Partial<IUpdateOrgIntegraCropwiseConfig & IOrgIntegraConfigExtended>
  ) => {
    console.log(formData);

    if (
      !has(formData, 'username') ||
      !has(formData, 'organizationId') ||
      !has(formData, 'password')
    ) {
      return Promise.reject(new Error('Проверьте заполненность полей'));
    }

    const payload: ICreateOrgIntegraCropwiseConfig = {
      ...(formData as ICreateOrgIntegraCropwiseConfig),
      name: formData?.organizationName,
    };

    return this.service.createConfig(payload).then(data => {
      this.fetchConfig(formData.organizationId);
      this.store.clearDirtyList();

      return data;
    });
  };

  updateConfig = (
    formData: Partial<IUpdateOrgIntegraCropwiseConfig & IOrgIntegraConfigExtended>
  ) => {
    const { dirtyFieldList } = this.store;

    const payload: Partial<IUpdateOrgIntegraCropwiseConfig> = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => dirtyFieldList.has(key as string))
    );

    payload.organizationId = formData.organizationId;
    payload.id = formData.id;

    Object.keys(payload).forEach(key => {
      if (payload[key] === '') {
        payload[key] = null;
      }
    });

    return this.service.updateConfig(payload).then(data => {
      this.fetchConfig(formData.organizationId);
      this.store.clearDirtyList();

      return data;
    });
  };

  deleteConfig = (id: string, organizationId) => {
    return this.service.deleteConfig({ id }).then(data => {
      this.fetchConfig(organizationId);
      this.store.clearDirtyList();

      return data;
    });
  };

  clearOnUnmount = () => {
    this.store.clearStore();
  };
}

export default OrgIntegraCropwiseController;
