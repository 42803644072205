import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, Button, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useFormik } from 'formik';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TabContext, TabPanel } from '@mui/lab';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { useUploadTriggers } from '../../hooks';
import { DropdownButton } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { AGROSIGNAL_REPORT_SCHEME } from '../../configs';
import {
  REMOVE_AGROSIGNAL_CONFIG_MODAL_ID,
  agrosignalReportModalConfig,
  removeAgrosignalConfig,
} from '../../modals';

import { IAgrosignalConfig } from './models';
import { ReportAgrosignalConfigForm, ReportAgrosignalHistory } from './components';
import { AgrosignalReportController } from './mobx';

const DEFAULT_FORM_CONFIG = {
  apiKey: '',
  clientId: null,
  filesLocation: '',
  fromDate: null,
  toDate: null,
  host: '',
  name: '',
  reportsLocation: '',
  isAuto: false,
};

const OrganizationReportsAgrosignal = () => {
  const controller = useStore(AgrosignalReportController);

  const [defaultConfig, setDefaultConfig] = useState<IAgrosignalConfig>(DEFAULT_FORM_CONFIG);
  const [hasValue, setHasValue] = useState(false);
  const [tab, setTab] = useState<'history' | 'config'>('config');

  const handleChange = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    setTab(newValue);
  };

  const { organizationId } = useParams<{ organizationId?: string }>();

  const { setNotification } = useNotificator();

  const history = useHistory();

  const { openModalByModalId, registerModalList } = useModal();

  const { downloadData, downloadPhotos, downloadReport } = useUploadTriggers({
    organizationId,
    config: defaultConfig,
  });

  const fetchConfig = () => {
    controller.getReportsConfigByOrgId(organizationId).then(data => {
      if (data) {
        setDefaultConfig(data);
        setHasValue(true);
      } else {
        setDefaultConfig(DEFAULT_FORM_CONFIG);
        setHasValue(false);
      }
    });
  };

  const submitConfig = (data: IAgrosignalConfig) => {
    if (hasValue || data.id) {
      controller
        .updateReportConfig(data.id, data, defaultConfig)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно обновлена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch((error: Error) => {
          setNotification(
            getNotificatorProps(
              `Ошибка обновления конфигурации${error.message ? `. ${error.message}` : ''}`,
              ENotificationStyles.Error
            )
          );
        });
    } else {
      controller
        .createReportConfig(organizationId, data)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно создана', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка создания конфигурации', ENotificationStyles.Error)
          );
        });
    }
  };

  const deleteConfig = () => {
    openModalByModalId(REMOVE_AGROSIGNAL_CONFIG_MODAL_ID, null, () =>
      controller
        .deleteReportConfig(defaultConfig?.id)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно удалена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления конфигурации', ENotificationStyles.Error)
          );
        })
    );
  };

  useEffect(() => {
    fetchConfig();

    registerModalList([removeAgrosignalConfig, agrosignalReportModalConfig], 'agrosignal-reports');
  }, []);

  const formik = useFormik<IAgrosignalConfig>({
    initialValues: defaultConfig,
    validationSchema: AGROSIGNAL_REPORT_SCHEME,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: submitConfig,
  });

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);
  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex" width={'100%'}>
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Box flexGrow={1}>
                <H1>Отчеты BI. Агросигнал</H1>
              </Box>
              <Box display={'flex'} gap={2} alignItems={'center'}>
                {!defaultConfig?.id && (
                  <Tooltip title="Для выгрузки необходимо заполнить форму ниже">
                    <InfoOutlinedIcon color="info" />
                  </Tooltip>
                )}
                <DropdownButton
                  title="Заказать выгрузку"
                  type="button"
                  variant="contained"
                  disabled={!defaultConfig?.id}
                  optionList={[
                    {
                      title: 'Заказать выгрузку данных',

                      handler: downloadData,
                      color: 'info',
                    },
                    {
                      title: 'Заказать выгрузку фотоматериалов',
                      handler: downloadPhotos,
                      color: 'info',
                    },
                  ]}
                />
                <Button
                  disabled={!defaultConfig?.id}
                  onClick={downloadReport}
                  variant="contained"
                  size="small"
                >
                  Сформировать отчёт
                </Button>
              </Box>
            </Box>
          </TopSection>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Настройки" value={'config'} />
                <Tab
                  label="История"
                  value={'history'}
                  disabled={!Boolean(defaultConfig?.clientId)}
                />
              </Tabs>
            </Box>
            <TabPanel value="config">
              <ReportAgrosignalConfigForm formik={formik} handleDelete={deleteConfig} />
            </TabPanel>
            <TabPanel value="history">
              <ReportAgrosignalHistory clientId={defaultConfig?.clientId} />
            </TabPanel>
          </TabContext>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default OrganizationReportsAgrosignal;
