import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.transient()
class OwnerConfigService {
  @lazyInject(Axios)
  protected axios: Axios;

  getOwnerConfigByOwnerId = async (payload: TypeApiRequest<'getOwnerConfigByOwnerId'>) => {
    try {
      const response = await this.axios.api.getOwnerConfigByOwnerId(payload, { omit: ['ownerId'] });

      return response;
    } catch (error) {
      console.log('get owner config error', error);
    }
  };

  setOwnerConfigByOwnerId = async (payload: TypeApiRequest<'setOwnerConfigByOwnerId'>) => {
    try {
      const response = await this.axios.api.setOwnerConfigByOwnerId(payload, { omit: ['ownerId'] });

      return response;
    } catch (error) {
      console.log('get owner config error', error);
    }
  };

  deleteOwnerConfigByOwnerId = async (payload: TypeApiRequest<'deleteOwnerConfigByOwnerId'>) => {
    try {
      const response = await this.axios.api.deleteOwnerConfigByOwnerId(payload, {
        omit: ['ownerId'],
      });

      return response;
    } catch (error) {
      console.log('get owner config error', error);
    }
  };
}

export default OwnerConfigService;
