import { Box, Typography } from '@mui/material';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

const NoOrganizations = () => {
  return (
    <Box
      height={'calc(100vh - 228px)'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={2}
    >
      <DomainDisabledIcon color="primary" sx={{ fontSize: 48 }} />
      <Typography>Пользователь еще не состоит ни в одной организации</Typography>
    </Box>
  );
};

export default NoOrganizations;
