import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const isEmptyContent = content => {
  const res = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
  return res.getCurrentContent().hasText();
};

export const convertStateFromHTML = content => {
  if (isJSON(content)) {
    return content;
  }

  const contentState = stateFromHTML(content);

  return JSON.stringify(convertToRaw(contentState));
};

export const convertStateToHTML = content => {
  if (!isJSON(content)) {
    return content;
  }

  return stateToHTML(convertFromRaw(JSON.parse(content)));
};

export const convertStateToJSON = (state: EditorState) => {
  return JSON.stringify(convertToRaw(state.getCurrentContent()));
};
