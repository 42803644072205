import { IOrgIntegraAgrosignalCreateUpdateChecklist } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsAgrosignal/modules/OrgIntegraAgrosignalChecklists/models/orgIntegraAgrosignalChecklist';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<IOrgIntegraAgrosignalCreateUpdateChecklist> & { id: string };

type TypeResponse = string;

type TypeError = {};

export const updateOrgIntegraAgrosignalChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: ({ id }: TypeRequest) => `/api/integra-as/integra/checklist/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
