import { TModule } from '../../models/action.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = TModule[];

type TypeError = {};

export const getAccessModuleAll: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-profile/access/module/all`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
