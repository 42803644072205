import { TypeApiRoute } from '../../models/type.api.request';
import { IField } from '../../models/field.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = Partial<{
  organizationId: string;
  name: string;
}>;

type TypeResponse = { content: Array<IField> } & TypeResponsePageable;

type TypeError = {};

export const getFieldList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/fields/list`,
  headers: {},
  method: 'GET',
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
