import { Box, IconButton, InputLabel, TextField as MTextField } from '@mui/material';
import { ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import { FormikProvider, useFormik } from 'formik';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../../../shared/utils';
import { OrganizationsStore } from '../../../../../../../../../../../../../stores/organizations/organizations.store';
import { getNotificatorProps } from '../../../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../../../shared/constanst/notifications';
import { OrgIntegraCropwiseService } from '../../../../../../mobx';
import { IOrgIntegraCropwiseErrorMessage } from '../../../../models/orgIntegraCropwiseErrorMessage';

const ErrorMessageDetailsForm = () => {
  const orgStore = useStore(OrganizationsStore);
  const service = useStore(OrgIntegraCropwiseService);

  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const [isProcessing, setIsProcessing] = useState(false);

  const payload = getModalPayload() as {
    mode: 'create' | 'update';
    organizationId;
    errorMessage: IOrgIntegraCropwiseErrorMessage;
  };
  const handlers = getModalRuntimeHandlers();

  const onSubmit = (data: IOrgIntegraCropwiseErrorMessage) => {
    console.log(data);
  };

  const formik = useFormik<IOrgIntegraCropwiseErrorMessage>({
    initialValues: payload?.errorMessage,
    onSubmit,
  });

  const errorBody = useMemo(
    () => (formik?.values?.data ? JSON.stringify(formik.values.data, null, 2) : ''),
    [formik?.values?.data]
  );

  const onSuccessCopy = () => {
    setNotification(
      getNotificatorProps('Содержимое успешно скопировано', ENotificationStyles.Success)
    );
  };

  return (
    <Box>
      <Box display={'flex'} gap={2} marginBottom={2} flexDirection={'column'}>
        <ModalTitle>Детали</ModalTitle>
        <FormikProvider value={formik}>
          <MTextField
            label="Наименование организации"
            value={orgStore?.organization?.name || ''}
            disabled
          />
          <Box position={'relative'}>
            <Box position={'absolute'} right={16} top={0}>
              <CopyToClipboard text={errorBody} onCopy={onSuccessCopy}>
                <IconButton size="small" sx={{ backgroundColor: 'white' }}>
                  <ContentCopyIcon fontSize={'small'} color="secondary" />
                </IconButton>
              </CopyToClipboard>
            </Box>
            <Box maxHeight={250} overflow={'auto'}>
              <InputLabel htmlFor="error-body" sx={{ maxWidth: 'fit-content' }}>
                Тело сообщения
              </InputLabel>
              <SyntaxHighlighter language="json" wrapLines>
                {errorBody}
              </SyntaxHighlighter>
            </Box>
          </Box>
          <MTextField
            label="Описание исключения"
            value={formik?.values?.exceptionText}
            multiline
            minRows={1}
            maxRows={5}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyToClipboard text={formik?.values?.exceptionText} onCopy={onSuccessCopy}>
                  <IconButton size="small">
                    <ContentCopyIcon fontSize="small" color="secondary" />
                  </IconButton>
                </CopyToClipboard>
              ),
            }}
          />
          <MTextField
            label={'Количество попыток'}
            value={formik?.values?.countAttempts}
            InputProps={{
              readOnly: true,
            }}
            type="number"
            onChange={e => formik.setFieldValue('countAttempts', e.target.value)}
          />
        </FormikProvider>
      </Box>
      <ModalFooter
        denyButton={{
          title: 'Закрыть',
          handler: () => {
            closeModal();
          },
        }}
      />
    </Box>
  );
};

export default observer(ErrorMessageDetailsForm);
