import { Button, EModalType, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useStore } from '../../../../../shared/utils';
import { AddQuotesStore } from '../../../../../stores/quotes/add.quotes.store';
import { QuotesStore } from '../../../../../stores/quotes/quotes.store';
import { Layout } from '../../../../components';
import { AdminRoutes } from '../../../../routes';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../style';

import { QuotesFilter, QuotesTable } from './components';
import { ButtonsBlocks, FileUploader } from './QuotesList.style';

export const QuotesList: FC = observer(() => {
  const quotesStore = useStore(QuotesStore);
  const addQuotesStore = useStore(AddQuotesStore);

  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const history = useHistory();

  const fileUploaderRef = useRef(null);

  useEffect(() => {
    quotesStore.fetchQuotes();
    quotesStore.fetchBasis();
    quotesStore.fetchCultureClassList();
    quotesStore.fetchCultureList();
    quotesStore.fetchEconomicRegionsList();

    registerModalList(
      [
        {
          name: 'ImportSuccessModal',
          title: 'Импорт котировок прошел успешно',
          type: EModalType.Success,
          styledProps: { $size: '600px', $modalPaddings: '30px 20px', $height: '820px' },
          id: 'ImportSuccessModal',
          successButton: {
            title: 'OK',
            color: 'primary',
            handler: () => {
              closeModal();
              history.push(AdminRoutes.QuotesAddByImport);
            },
          },
        },
      ],
      'ImportSuccessModal'
    );

    registerModalList(
      [
        {
          name: 'ImportRejectModal',
          title: 'Ошибка импорта котировок!',
          type: EModalType.Warning,
          styledProps: { $size: '600px', $modalPaddings: '30px 20px', $height: '820px' },
          id: 'ImportRejectModal',
          successButton: {
            title: 'OK',
            color: 'secondary',
            handler: () => closeModal(),
          },
        },
      ],
      'ImportRejectModal'
    );
  }, []);

  const handleAddQuotes = useCallback(() => {
    history.push(AdminRoutes.QuotesAdd);
  }, []);

  const handleImportQuotes = useCallback(() => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.click();
    }
  }, []);

  const onImportSuccess = useCallback(() => {
    console.log('onImportSuccess');
    openModalByModalId('ImportSuccessModal');
  }, []);

  const onImportReject = useCallback(() => {
    console.log('onImportReject');
    openModalByModalId('ImportRejectModal');
  }, []);

  const onChangeFile = useCallback(async event => {
    event.stopPropagation();

    console.log(event.target.files[0]);
    if (event.target.files.length === 1) {
      const fileForUpload: File = event.target.files[0];
      await addQuotesStore.importQuotes(fileForUpload, onImportSuccess, onImportReject);
    }
  }, []);

  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Котировки</H1>
            <ButtonsBlocks>
              <Button
                color={'default'}
                type={'button'}
                styleType={'outlined'}
                alignment={'center'}
                onClick={handleImportQuotes}
              >
                Импорт
              </Button>
              <Button
                color={'primary'}
                type={'button'}
                onClick={handleAddQuotes}
                alignment={'center'}
              >
                Добавить
              </Button>
            </ButtonsBlocks>
          </TopSection>
        </HeaderWrapper>
        <FileUploader
          type="file"
          name="file"
          ref={fileUploaderRef}
          // accept="image/jpeg,image/png,image/jpg"
          onChange={onChangeFile}
          onClick={event => event.stopPropagation()}
          data-test-id="image-upload-dialog-file-uploader"
        />
        <MainBody>
          <QuotesFilter />
          <br />
          <QuotesTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
