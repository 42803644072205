import { ITaskReportConfig } from '../../../../models/reportConfig.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = Partial<ITaskReportConfig> & {
  id: string;
};
type TypeResponse = ITaskReportConfig;

export const updateAssistanceTaskReportsConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/da-reports/reports/task/config/${params.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
