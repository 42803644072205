import { TypeResponsePageable } from '../../models/response.pageable.model';
import { TypeApiRoute } from '../../models/type.api.request';
import { TypeUnit } from '../../models/units.model';

type TypeRequest = {
  size: number;
  page: number;
  latestVersion: boolean;
};

type TypeResponse = { content: TypeUnit[] } & TypeResponsePageable;

type TypeError = {};

export const getUnitsList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/da-dictionary/dictionary/measure/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
