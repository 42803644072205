import {
  EImportOperationStatus,
  EImportOperationType,
} from '../../../../../../../../../api/models/importOperation.model';

export enum ECropwiseFilterIsAuto {
  Auto = 'Авто',
  NotAuto = 'Не авто',
  Null = 'Не выбрано',
}

export interface ICropwiseHistoryFilter {
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  statusIn: EImportOperationStatus[];
  type: EImportOperationType;
  isAuto: ECropwiseFilterIsAuto | boolean;
  configId: string;
  organizationId?: string;
}
