import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem } from '@mui/material';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { uniq } from 'lodash';
import { CalendarComponent, useModal } from '@farmlink/farmik-ui';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';

import { ECropwiseFilterIsAuto, ICropwiseHistoryFilter } from '../../models';
import {
  EImportOperationStatus,
  EImportOperationType,
} from '../../../../../../../../../../api/models/importOperation.model';
import { PAGE_SIZE_LIST, useCropwiseReportHistory } from '../../../../hooks';
import { BEFORE_DELETE_MODAL_ID } from '../../../../../../../../../shared/modals';

const NOT_SELECTED = 'Не выбрано';

const STATUS_IN_OPTION_LIST: EImportOperationStatus[] = [
  EImportOperationStatus.PENDING,
  EImportOperationStatus.IN_PROGRESS,
  EImportOperationStatus.COMPLETED,
  EImportOperationStatus.ERROR,
];

const TYPE_OPTION_LIST: EImportOperationType | string[] = [
  EImportOperationType.FILES_IMPORT,
  EImportOperationType.FULL_IMPORT,
  EImportOperationType.NOTES_IMPORT,
  NOT_SELECTED,
];

const ReportCropwiseHistory: FC<{ configId: string }> = ({ configId }) => {
  const controller = useCropwiseReportHistory(configId);

  const { openModalByModalId } = useModal();

  const [selectionModel, setSelectionModel] = useState(null);

  const { fromDate, toDate } = useMemo(() => {
    const currentDate = new Date();

    return {
      fromDate: moment(currentDate).subtract(6, 'd').format('YYYY-MM-DD'),
      toDate: moment(currentDate).format('YYYY-MM-DD'),
    };
  }, []);

  const onSubmit = (data: ICropwiseHistoryFilter) => {
    controller.setPage(0);

    controller.fetchHistory(data);
  };

  const onDelete = (data: ICropwiseHistoryFilter) => {
    openModalByModalId(BEFORE_DELETE_MODAL_ID, null, () => {
      controller.deleteOperations(selectionModel).then(() => {
        controller.fetchHistory(data);
      });
    });
  };

  const onReset = () => {
    controller.fetchHistory({
      fromDate: undefined,
      toDate: undefined,
      statusIn: [],
      type: NOT_SELECTED as EImportOperationType,
      isAuto: false,
      configId: null,
    });
  };

  const formik = useFormik({
    initialValues: {
      fromDate,
      toDate,
      statusIn: [],
      type: NOT_SELECTED as EImportOperationType,
      isAuto: ECropwiseFilterIsAuto.Null,
      configId,
    },
    onSubmit,
    onReset,
  });

  const { values } = formik;
  const setFieldValue = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };

  return (
    <Box>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid xs={4} item>
            <FormControl fullWidth>
              <Field
                component={Select}
                id="statusIn"
                name="statusIn"
                label="Статус"
                onChange={() => null}
                renderValue={() => values.statusIn?.join(', ')}
                disabled={false}
              >
                {STATUS_IN_OPTION_LIST.map(status => (
                  <MenuItem
                    key={status}
                    value={status}
                    onClickCapture={event => {
                      event.stopPropagation();
                      if (values.statusIn.includes(status)) {
                        setFieldValue(
                          'statusIn',
                          uniq(values.statusIn.filter(item => item !== status))
                        );
                      } else {
                        setFieldValue('statusIn', uniq([...values.statusIn, status]));
                      }
                    }}
                  >
                    <Checkbox checked={values?.statusIn.includes(status)} />
                    <ListItemText primary={status} />
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid xs={4} item>
            <FormControl fullWidth>
              <Field
                component={Select}
                renderValue={() => values.type}
                id="type"
                name="type"
                label="Тип запроса"
                disabled={false}
              >
                {TYPE_OPTION_LIST.map(type => (
                  <MenuItem key={type} value={type}>
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth sx={{ minWidth: 120 }}>
              <Field
                component={Select}
                renderValue={value => value}
                id="isAuto"
                name="isAuto"
                label="Тип выгрузки"
                disabled={false}
              >
                <MenuItem value={ECropwiseFilterIsAuto.Auto}>
                  <ListItemText primary={'Авто'} />
                </MenuItem>
                <MenuItem value={ECropwiseFilterIsAuto.NotAuto}>
                  <ListItemText primary={'Не авто'} />
                </MenuItem>
                <MenuItem value={ECropwiseFilterIsAuto.Null}>
                  <ListItemText primary={NOT_SELECTED} />
                </MenuItem>
              </Field>
            </FormControl>
          </Grid>
          <Grid xs={10} item>
            <Field
              component={TextField}
              value={values.configId ?? ''}
              disabled={false}
              name="configId"
              type="text"
              label="ID конфигурации"
              fullWidth
            />
          </Grid>
          <Grid xs={4} item>
            <CalendarComponent
              label="Дата заказа отчета с"
              onChange={date => setFieldValue('fromDate', date)}
              value={values?.fromDate ? moment(values.fromDate).format('DD.MM.YYYY') : null}
              datePickerOptions={{ dateRange: { maxDate: moment(values?.toDate).toDate() } }}
            />
          </Grid>
          <Grid xs={4} item>
            <CalendarComponent
              label="Дата заказа отчета по"
              onChange={date => setFieldValue('toDate', date)}
              value={values?.toDate ? moment(values.toDate).format('DD.MM.YYYY') : null}
              datePickerOptions={{ dateRange: { minDate: moment(values?.fromDate).toDate() } }}
            />
          </Grid>
          <Grid xs={12} item>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Box display={'flex'} flexDirection={'row'} gap={2}>
                <Button
                  sx={{ maxWidth: '120px' }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={e => formik.handleSubmit()}
                >
                  Применить
                </Button>
                <Button
                  sx={{ maxWidth: '120px' }}
                  color="error"
                  variant="text"
                  type="reset"
                  onClick={e => {
                    formik.resetForm();
                    setFieldValue('configId', null);
                    setFieldValue('fromDate', null);
                    setFieldValue('toDate', null);
                  }}
                >
                  Сбросить
                </Button>
              </Box>
              <Button color="error" variant="text" onClick={() => onDelete(values)}>
                Удалить выбранное
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box height={600} marginTop={2}>
          <DataGrid
            columns={controller.gridColumnConfig}
            rows={controller.valueList}
            pagination={true}
            paginationMode={'server'}
            onPageChange={controller.setPage}
            onPageSizeChange={controller.setSize}
            loading={controller.isLoading}
            rowsPerPageOptions={PAGE_SIZE_LIST}
            page={controller.page}
            pageSize={controller.size}
            rowCount={controller.totalElements}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            checkboxSelection
            onSelectionModelChange={setSelectionModel}
            isRowSelectable={params => params.row.canDelete}
            disableSelectionOnClick
            hideFooterSelectedRowCount
            disableColumnMenu
          />
        </Box>
      </FormikProvider>
    </Box>
  );
};

export default ReportCropwiseHistory;
