import { ReactElement } from 'react';
import { GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';

import { TypeOrganizationStatus } from '../../../../../api/models/organization.model';

export const getColumns = (
  renderActions: (params: GridRowParams) => ReactElement[],
  renderStatus: (params: GridRenderCellParams<TypeOrganizationStatus>) => ReactElement
) => [
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    minWidth: 150,
    sortable: false,
    renderCell: renderStatus,
  },

  {
    field: 'name',
    type: 'string',
    headerName: 'Имя',
    minWidth: 300,
    sortable: false,
  },

  {
    field: 'userName',
    type: 'string',
    headerName: 'Владелец',
    width: 200,
    sortable: false,
  },

  {
    field: 'countEmployees',
    type: 'number',
    headerName: 'Сотрудники',
    width: 150,
    sortable: false,
  },

  {
    field: 'countFields',
    type: 'number',
    headerName: 'Поля',
    width: 150,
    sortable: false,
  },

  {
    field: 'adressFull',
    type: 'string',
    headerName: 'Адрес',
    width: 200,
    sortable: false,
  },

  {
    field: 'actions',
    type: 'actions',
    headerName: 'Действия',
    width: 200,
    sortable: false,
    getActions: renderActions,
  },
];
