import { Chip, Container, Tooltip } from '@mui/material';
import { EModalType, useModal } from '@farmlink/farmik-ui';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router';

import { quotesPageSize } from '../../../../../../../api/models/quotes.model';
import { useStore } from '../../../../../../shared/utils';
import { QuotesStore } from '../../../../../../stores/quotes/quotes.store';
import { getColumns } from '../utils/getColumns';
import { getRows } from '../utils/getRows';
import { AdminRoutes } from '../../../../../routes';

export const QuotesTable: FC = observer(() => {
  const { quotes, isLoading, setPage, page, totalPages, fetchQuotes, deleteQuote } = useStore(
    QuotesStore
  );

  const { registerModalList, openModalByModalId, closeModal } = useModal();

  const history = useHistory();

  useEffect(() => {
    console.log('use effect page:', page);
    if (page) {
      fetchQuotes();
    }
  }, [page]);

  const handleEditQuote = useCallback(
    id => () => {
      console.log('handleEditQuote.id=', id);
      console.log('istory.push: ', `${AdminRoutes.EditQuote}/${id}`);
      history.push(`${AdminRoutes.EditQuote}/${id}`);
    },
    []
  );

  const hanldeDeleteQuote = (id: string) => deleteQuote(id);

  const handleDeleteQuote = useCallback(
    id => () => {
      console.log('handleDeleteQuote.id=', id);
      registerModalList(
        [
          {
            name: 'DeleteQuoteConfirmModal',
            title: 'Вы действительно хотите удалить котировку?',
            type: EModalType.Warning,
            styledProps: { $size: '600px', $modalPaddings: '30px 20px', $height: '820px' },
            id: 'DeleteQuoteConfirmModal',
            successButton: {
              title: 'Подтвердить',
              color: 'primary',
              handler: () => hanldeDeleteQuote(id),
            },
            denyButton: {
              title: 'Отменить',
              color: 'secondary',
              handler: () => closeModal(),
            },
          },
        ],
        'DeleteQuoteConfirmModal'
      );
      openModalByModalId('DeleteQuoteConfirmModal');
    },
    []
  );

  const renderActions = (param: GridRowParams) => {
    return [
      <Tooltip title="Редактировать">
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Редактировать"
          onClick={handleEditQuote(param.id)}
        />
      </Tooltip>,
      <Tooltip title="Удалить">
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Удалить"
          onClick={handleDeleteQuote(param.id)}
        />
      </Tooltip>,
    ];
  };

  const rows = useMemo(() => getRows(quotes), [quotes]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(() => getColumns(renderActions), []);

  const onPageChange = useCallback(pageNumber => {
    console.log('onPageChange: ', pageNumber);
    setPage(pageNumber);
    // fetchQuotes();
  }, []);

  const rowsPerPageOptions = [20];
  const pageSize = 20;
  const rowCount = totalPages >= pageSize ? 10 * totalPages : pageSize;

  return (
    <Container disableGutters maxWidth={false}>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          // @ts-ignore
          rows={rows}
          pagination={true}
          paginationMode={'server'}
          onPageChange={onPageChange}
          loading={isLoading}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pageSize={quotesPageSize}
          rowCount={rowCount}
        />
      </div>
    </Container>
  );
});
