import { FC, PropsWithChildren } from 'react';

import Styled from './styled';

const FormRow: FC<PropsWithChildren<{ $maxWidth?: string }>> = ({ children, $maxWidth }) => {
  const isEmpty = !children || (Array.isArray(children) && children.filter(Boolean).length === 0);

  return !isEmpty ? <Styled.FormRow $maxWidth={$maxWidth}>{children}</Styled.FormRow> : null;
};

export default FormRow;
