import { setTimeout } from 'timers';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { Box } from '@mui/system';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import { orderBy } from 'lodash';

import { FormInput, FormRow } from '../../../../../../../../../common';
import { attributeInChecklistFormItemsScheme } from '../../../../../AttributeBuilderForm.config';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { ChecklistsAttributeController } from '../../../../../../../../../../../../controllers/checklistAttribute.controller';
import { ISelectOptionExtended } from '../../../../../../../../../../../../../types/selectOption';
import {
  IAttribute,
  IFileLinkInstanceClientOnly,
} from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { CREATE_ATTRIBUTE_MODAL } from '../../../..';

import Styled from './styled';
import { FileLinkInstance } from './components';

const DEFAULT_NEW_FILE_LINK = {
  imageAttributeId: null,
  imageAttributeName: null,
  imageFileRequired: false,
};

/**
 *  DEPRECATED COMPONENT
 */
const FileSubform: FC = () => {
  const {
    getActiveChecklistAttributeList,
    currentChecklistAttributeEditFileList,
    addFileListToCurrentFileList,
    currentChecklistAttributeActiveFile,
    setCurrentChecklistAttributeActiveFileId,
    setCurrentChecklistAttributeEdit,
    clearCurrentChecklistAttributeEdit,
    sanitizeActiveNestedFileLink,
    activateNestedFileLink,
  } = useStore(ChecklistsAttributeController);
  const [newFileLinkInstance, setNewFileLinkInstance] = useState<IFileLinkInstanceClientOnly>(
    DEFAULT_NEW_FILE_LINK
  );
  const [isAddMode, setAddMode] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isMultipleActive, setIsMultipleActive] = useState(false);

  const { setFieldValue, resetForm, setFieldError } = useFormikContext();
  const { closeModal, openModalByModalId } = useModal();

  const changeNewFileLinkInstance = useCallback(
    (value: Partial<Record<keyof IFileLinkInstanceClientOnly, any>>) => {
      setNewFileLinkInstance({ ...newFileLinkInstance, ...value });
    },
    [newFileLinkInstance]
  );

  useEffect(() => {
    const _activeFileList = currentChecklistAttributeEditFileList?.filter(
      item => item?.isActive || item?.attribute?.isActive
    );

    setCurrentChecklistAttributeActiveFileId(_activeFileList.flatMap(item => item?.attribute?.id));
  }, [currentChecklistAttributeEditFileList]);

  useEffect(() => {
    if (
      Array.isArray(currentChecklistAttributeActiveFile) &&
      currentChecklistAttributeActiveFile?.length > 1
    ) {
      setFieldError('fileRequired', 'Обнаружено несколько активных FILE_LINK атрибутов!');
      setIsMultipleActive(true);
    } else {
      setFieldError('fileRequired', null);
      setIsMultipleActive(false);
    }
  }, [currentChecklistAttributeActiveFile]);

  const isNewFileLinkValid = useMemo(() => Boolean(newFileLinkInstance?.imageAttributeId), [
    newFileLinkInstance,
  ]);

  const clearSubform = useCallback(() => {
    setFieldValue('imageAttributeId', null);
    setFieldValue('imageAttributeName', null);
    setFieldValue('imageFileRequired', null);

    changeNewFileLinkInstance(DEFAULT_NEW_FILE_LINK);
  }, []);

  const handleChangeFileInstance = useCallback((fileInstance: IFileLinkInstanceClientOnly) => {
    if (Boolean(fileInstance?.attribute?.id)) {
      closeModal();
      clearCurrentChecklistAttributeEdit();
      resetForm();
      setCurrentChecklistAttributeEdit(fileInstance?.attribute);
      setTimeout(() => openModalByModalId(CREATE_ATTRIBUTE_MODAL), 100);
    } else {
      setFieldValue('imageAttributeId', fileInstance?.imageAttributeId || null);
      setFieldValue('imageAttributeName', fileInstance?.imageAttributeName || null);
      setFieldValue('imageFileRequired', fileInstance?.isRequired || null);
      changeNewFileLinkInstance(fileInstance);
      setEditMode(true);
    }
  }, []);

  const addNewFileInstance = useCallback(() => {
    addFileListToCurrentFileList([{ ...newFileLinkInstance, clientOnlyId: v4(), isDirty: true }]);
    clearSubform();
    setAddMode(false);
  }, [newFileLinkInstance]);

  const saveChangedFileInstance = useCallback(() => {
    addFileListToCurrentFileList([newFileLinkInstance]);
    setEditMode(false);
  }, [newFileLinkInstance]);

  const handleDisableCreation = () => {
    setAddMode(false);
    setEditMode(false);
    clearSubform();
  };

  const handleSetActive = useCallback(
    (item: IFileLinkInstanceClientOnly) => {
      activateNestedFileLink(item?.attribute?.id, currentChecklistAttributeActiveFile);
    },
    [currentChecklistAttributeActiveFile]
  );

  const isActive = useCallback(
    (value: IFileLinkInstanceClientOnly) => {
      if (Array.isArray(currentChecklistAttributeActiveFile)) {
        return currentChecklistAttributeActiveFile.includes(value?.attribute?.id);
      } else {
        return currentChecklistAttributeActiveFile === value?.attribute?.id;
      }
    },
    [currentChecklistAttributeActiveFile, currentChecklistAttributeEditFileList]
  );

  if (isMultipleActive) {
    return (
      <Styled.FileSubFormWrapper>
        Активных атрибуты FILE_LINK:{' '}
        <ul>
          {Array.isArray(currentChecklistAttributeActiveFile) &&
            currentChecklistAttributeActiveFile?.map(item => <li>{item}</li>)}
        </ul>
        Можно деактивировать вручную или
        <Button onClick={sanitizeActiveNestedFileLink}>Деактивировать все</Button>
      </Styled.FileSubFormWrapper>
    );
  }

  return (
    <Styled.FileSubFormWrapper>
      {isAddMode || isEditMode ? (
        <Box>
          <FormRow>
            <FormInput
              itemKey="imageAttribute"
              attributeScheme={attributeInChecklistFormItemsScheme}
              autocompleteConfig={{
                optionsFetchHandler: getActiveChecklistAttributeList,
                isOnMountOptionsFetch: true,
                searchingKey: 'name',
                isClearable: true,
                onChangeMappingKey: 'imageAttributeId',
                searchingParams: { type: 'FILE_LINK' },
                value: newFileLinkInstance.imageAttributeName,
                optionItem: {
                  tooltip: {
                    fieldName: 'description',
                    placement: 'top',
                  },
                },
                onSelect: (value: ISelectOptionExtended<IAttribute>) => {
                  if (value === null) {
                    return clearSubform();
                  }

                  changeNewFileLinkInstance({
                    imageAttributeId: value.rawData.id,
                    imageAttributeName: value.rawData.name,
                    isActive: false,
                  });
                },
              }}
            />
            <FormInput
              itemKey="imageAttributeId"
              attributeScheme={attributeInChecklistFormItemsScheme}
            />
            <FormInput
              itemKey="imageFileRequired"
              attributeScheme={attributeInChecklistFormItemsScheme}
              onChangeHandler={value => changeNewFileLinkInstance({ isRequired: value })}
            />
          </FormRow>
          {isEditMode ? (
            <Button onClick={saveChangedFileInstance} variant="contained">
              Сохранить
            </Button>
          ) : (
            <Button disabled={!isNewFileLinkValid} onClick={addNewFileInstance} variant="contained">
              Добавить
            </Button>
          )}
          <Button onClick={handleDisableCreation} variant="text">
            Отменить
          </Button>
        </Box>
      ) : (
        <Button variant="text" onClick={() => setAddMode(true)}>
          + Добавить файл
        </Button>
      )}

      <Box marginTop={1}>
        {orderBy(
          currentChecklistAttributeEditFileList?.map((instance, key) => (
            <FileLinkInstance
              key={instance.clientOnlyId}
              order={key}
              instance={instance}
              setActive={handleSetActive}
              isActive={isActive(instance)}
              changeFile={handleChangeFileInstance}
            />
          )),
          ['attribute.order', 'attribute.id']
        )}
      </Box>
    </Styled.FileSubFormWrapper>
  );
};

export default observer(FileSubform);
