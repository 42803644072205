import { IOwnerConfigModel } from '../../models/ownerConfig.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  ownerId: string;
};

type TypeError = {};

type TypeResponse = IOwnerConfigModel;

export const getOwnerConfigByOwnerId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (params: TypeRequest) => `/api/as-weather/ownerConfig/byOwnerId/${params.ownerId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
