interface ILink {
  [x: string]: string;
}

function getLink(path: string, regexp: ILink): string {
  const keys = Object.keys(regexp);
  return keys.reduce((acc: string, key: any) => acc.replace(regexp[key], key), path);
}

export { getLink };
