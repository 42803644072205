import * as yup from 'yup';

const FIELD_REPORT_SCHEME = yup.object().shape({
  seasonYear: yup
    .number()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  enabled: yup
    .boolean()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  reportsLocation: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
});

export default FIELD_REPORT_SCHEME;
