import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const BEFORE_DELETE_MODAL_ID = 'before-delete-modal';

const beforeDeleteModal: TModalConfig = {
  id: BEFORE_DELETE_MODAL_ID,
  name: BEFORE_DELETE_MODAL_ID,
  type: EModalType.Warning_Before_Living,
  title: 'Вы уверены, что хотите удалить?',

  successButton: {
    title: 'Удалить',
    color: 'primary',
  },
  denyButton: {
    title: 'Закрыть',
    color: 'secondary',
  },
};

export { beforeDeleteModal, BEFORE_DELETE_MODAL_ID };
