import { Autocomplete, Box, TextField, Tooltip, Typography } from '@mui/material';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Colors } from '@farmlink/farmik-ui';
import { v4 } from 'uuid';

import { FormulaContext } from '../../../../../../contexts';
import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../../../../../api/models/checklist.model';
import { HighlightBox } from '../../../../../../../../../../../shared/components';
import { EChecklistAttributeType } from '../../../../../../../../../../../../api/models/checklist.attribute.model';
import { EErrorCollection, errorCollection } from '../../../../../../configs';
import { EFormulaReturnType, IFormulaError } from '../../../../../../models';
import getAvailableAttrTypeByReturnType from '../../../../../../helpers/getAvailableAttrTypeByReturnType';

interface IProps {
  value?: string;
  returnType?: EFormulaReturnType;
  onAttributeSelect: (value: string, option: ISelectOptionExtended<IChecklistAttribute>) => void;
  clientId: string;
  filters?: {
    type?: EChecklistAttributeType[];
    ignoredIdList?: string[];
  };
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
}

const FormulaAttributeSelector: FC<IProps> = ({
  value,
  onAttributeSelect,
  clientId,
  filters,
  returnType,
  handleSetError,
  handleRemoveError,
}) => {
  const { checklistAttributeList } = useContext(FormulaContext);

  const [error, setError] = useState<{ text: string; id: string }>(null);
  const [warning, setWarning] = useState(null);

  const [uuid] = useState(v4());

  useEffect(() => {
    if (!value) {
      handleSetError?.(
        {
          errorType: EErrorCollection.ATTR_NOT_SELECTED,
          text: errorCollection.ATTR_NOT_SELECTED,
          HTMLElementId: `${uuid}-attrValue`,
        },
        uuid
      );

      setWarning(null);
    } else {
      handleRemoveError?.(uuid);
    }
  }, [value]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(uuid);
    };
  }, [error]);

  const optionList = useMemo<ISelectOptionExtended<IChecklistAttribute>[]>(
    () =>
      checklistAttributeList.filter(item => {
        const isIgnoredId =
          filters?.ignoredIdList?.length > 0 &&
          filters?.ignoredIdList?.includes(item.value as string);

        const isAllowedType =
          filters?.type?.length > 0 && !filters?.type?.includes(item?.rawData?.attribute?.type);

        return !isIgnoredId && !isAllowedType;
      }),
    [value, filters, checklistAttributeList]
  );

  const parsedValue = useMemo(() => checklistAttributeList.find(item => item.value === value), [
    value,
    checklistAttributeList,
  ]);

  const validateAttrOnSelect = (option: ISelectOptionExtended<IChecklistAttribute>) => {
    const allowedTypeList = getAvailableAttrTypeByReturnType(returnType);

    allowedTypeList?.delete(EChecklistAttributeType.Enum);

    if (allowedTypeList && option?.rawData) {
      if (allowedTypeList.has(option?.rawData?.attribute?.type)) {
        setWarning(null);
      } else {
        setWarning(errorCollection.ATTR_POTENTIALLY_TYPE_MISMATCH);
      }

      return;
    }

    setWarning(null);
  };

  return (
    <HighlightBox marginTop={2} id={`${uuid}-attrValue`}>
      <Autocomplete
        value={parsedValue ?? null}
        isOptionEqualToValue={option => option.value === value}
        options={optionList}
        data-test-id="formula-block-attribute-selector"
        renderInput={params => (
          <>
            <TextField
              {...params}
              data-test-id="formula-block-attribute-selector-input"
              label={'Атрибут'}
            />
          </>
        )}
        onChange={(e, option: ISelectOptionExtended<IChecklistAttribute>) => {
          validateAttrOnSelect(option);

          onAttributeSelect(String(option.value), option);
        }}
        disableClearable
        renderOption={(props, option: ISelectOptionExtended<IChecklistAttribute>) => (
          <li
            {...props}
            data-test-id={`formula-block-attribute-selector-attribute-item-${option?.value}`}
          >
            <Tooltip title={option?.rawData?.description ?? ''}>
              <Box>
                <Typography>
                  {option.label} {`(${option.rawData.attribute.type})`}
                </Typography>
                <Typography sx={{ fontSize: 12, color: Colors.grey }}>{option.value}</Typography>
              </Box>
            </Tooltip>
          </li>
        )}
      />
      {warning && (
        <Typography paddingLeft={2} fontSize={12} color={Colors.accentOrange}>
          {warning}
        </Typography>
      )}
    </HighlightBox>
  );
};

export default memo(FormulaAttributeSelector);
