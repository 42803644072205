import { Box } from '@mui/material';
import styled from 'styled-components';

const TitleGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Styled = {
  TitleGroup,
};

export default Styled;
