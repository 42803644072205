import {
  EAdvancedGridFilterItemType,
  IAdvanceGridConfig,
} from '../common/AdvancedGrid/AdvancedGrid.type';

import { ISelectOption } from './../../../../../types/selectOption';

export const getChecklistAttributesFilterConfig: (
  dataFetchHandler: IAdvanceGridConfig<'getChecklistAttributeListByParams'>['dataFetchHandler'],
  dictionaryLinkOptions: ISelectOption[],
  onSelectRow?: (id: string) => void
) => IAdvanceGridConfig<'getChecklistAttributeListByParams'> = (
  dataFetchHandler,
  dictionaryLinkOptions,
  onSelectRow
) => {
  return {
    dataFetchHandler,
    onSelectRow,
    gridId: 'attributes',
    filter: [
      {
        key: 'name',
        label: 'Наименование',
        type: EAdvancedGridFilterItemType.String,
      },
      { key: 'id', label: 'ID', type: EAdvancedGridFilterItemType.String },

      {
        key: 'type',
        label: 'Тип',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: [
              { value: 'BOOLEAN', label: 'BOOLEAN' },
              { value: 'INT', label: 'INT' },
              { value: 'DOUBLE', label: 'DOUBLE' },
              { value: 'DATE', label: 'DATE' },
              { value: 'STRING', label: 'STRING' },
              { value: 'LONGSTRING', label: 'LONGSTRING' },
              { value: 'DICTIONARY_LINK', label: 'DICTIONARY_LINK' },
              { value: 'ENUM', label: 'ENUM' },
              { value: 'USER_DICTIONARY_LINK', label: 'USER_DICTIONARY_LINK' },
              { value: 'FILE_LINK', label: 'FILE_LINK' },
              { value: 'CHECKLIST_INSTANCE_LINK', label: 'CHECKLIST_INSTANCE_LINK' },
            ],
          },
        },
      },
      {
        key: 'checklistLink',
        label: 'ID вложенного чек-листа',
        type: EAdvancedGridFilterItemType.String,
      },
      {
        key: 'checklistLinkPublicId',
        label: 'PublicId вложенного чек-листа',
        type: EAdvancedGridFilterItemType.String,
      },
      {
        key: 'dictionaryLink',
        label: 'Справочник',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            itemList: dictionaryLinkOptions,
          },
        },
      },
      {
        key: 'isActive',
        label: 'Статус',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            isToggler: true,
            itemList: [
              { value: 'true', label: 'Активная' },
              { value: 'false', label: 'Неактивная' },
            ],
          },
        },
      },
      {
        key: 'isRequired',
        label: 'Обязательность',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            isToggler: true,
            itemList: [
              { value: 'true', label: 'Да' },
              { value: 'false', label: 'Нет' },
            ],
          },
        },
      },
      {
        key: 'isMultiselect',
        label: 'Мультивыбор',
        type: EAdvancedGridFilterItemType.Select,
        config: {
          select: {
            isToggler: true,
            itemList: [
              { value: 'true', label: 'Да' },
              { value: 'false', label: 'Нет' },
            ],
          },
        },
      },
    ],
  };
};
