import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { ETextColorScheme } from './helpers/getGridColumns';

const TableWrapper = styled.div`
  height: calc(100vh - 288px);
  width: 100%;

  .${ETextColorScheme.ColorWarning} {
    color: ${Colors.orange};
  }
  .${ETextColorScheme.ColorPrimary} {
    color: ${Colors.green};
  }
`;

const Styled = { TableWrapper };

export default Styled;
