import { GridColDef } from '@mui/x-data-grid';

import { TooltipedValue } from '../../../../../../../../../ChecklistContainer/components/Settings/components';
import { IEnumDependencyLink } from '../../../../../../../../../../../../../../api/models/enumDependency.model';
import AdornmentButtons from '../../../../../../../../../ChecklistContainer/components/Attributes/components/AdornmentButtons/AdornmentButtons';

const getGridColumConfig = (
  onDelete: (id: string) => Promise<any>
): GridColDef<IEnumDependencyLink>[] => [
  {
    field: 'dependencyAttribute.name',
    headerName: 'Атрибут от',
    flex: 1,
    valueGetter: params => params.row.dependency.dependencyAttribute.name,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'dependencyAttribute.value',
    headerName: 'Значение от кого зависит',
    flex: 1,
    valueGetter: params => params.row.dependencyValue.value,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'targetAttribute.name',
    headerName: 'Атрибут кто',
    flex: 1,
    valueGetter: params => params.row.dependency.targetAttribute.name,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'targetAttribute.value',
    headerName: 'Значение которое зависит',
    flex: 1,
    valueGetter: params => params.row.targetValue.value,
    renderCell: params => <TooltipedValue value={params.value} />,
  },
  {
    field: 'actions',
    headerName: '',
    width: 100,
    renderCell: params => (
      <AdornmentButtons
        hideEdit
        hideLockToggler
        isReverseDeleteButton
        onDeleteClick={() => {
          onDelete(String(params.id));
        }}
      />
    ),
  },
];

export default getGridColumConfig;
