import { CircularProgress, Typography } from '@mui/material';
import { has, last } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import {
  TDictionaryItem,
  EDictionaryItemStatus,
  TDictionaryItemCrumbs,
} from '../../../../../../../../api/models/dictionaries.model';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../shared/utils';
import {
  AdvancedFilterStore,
  DictionariesStore,
  EFormModule,
  TAttributeWithValue,
  TCurrentDictionaryExtended,
} from '../../../../../../../stores';
import { AdminRoutes } from '../../../../../../routes';
import { SearchResult } from '../SearchResult';

import { Breadcrumbs, CreateDictElementForm, DictForm } from './components';
import { AdditionalParams } from './components/DictForm/components/FormTabs/components';
import Styled from './styled';

enum ELoadingStatus {
  Idle,
  Loading,
  Success,
}

const DictionaryItemDetails: FC = observer(() => {
  const {
    getDictionaryItem,
    getDictionaryItemBreadcrumbs,
    changeFormModule,
    getAllDictionariesByParams,
    fetchAttributeListByOwnerId,
  } = useStore(DictionariesController);
  const {
    setCurrentDictionaryItem,
    currentDictionaryItem,
    formModule,
    parentId,
    setCurrentDictionaryItemHistory,
  } = useStore(DictionariesStore);
  const { isFilterActive, isFetched } = useStore(AdvancedFilterStore);
  const location = useLocation();

  const isIndexPage = Boolean(
    matchPath(location.pathname, {
      path: AdminRoutes.DictionaryName,
      exact: true,
    })
  );

  const { name: remoteName, itemId, version } = useParams<{
    name: string;
    itemId: string;
    version: string;
  }>();

  const [loadingStatus, setLoadingStatus] = useState<ELoadingStatus>(ELoadingStatus.Idle);
  const [dictData, setDictData] = useState<{
    breadcrumbs: TDictionaryItemCrumbs;
    data: TDictionaryItem;
  }>({
    data: {
      parentId: '',
      code: '',
      description: '',
      name: '',
      originalLink: '',
      status: EDictionaryItemStatus.Active,
      level: 0,
      id: '',
      version: 0,
      path: '',
      hasChild: false,
      authorFullName: '',
      editDate: '',
      original: true,
    },
    breadcrumbs: null,
  });

  useEffect(() => {
    if (
      !itemId ||
      (itemId === currentDictionaryItem?.id && !version) ||
      version === String(currentDictionaryItem?.version)
    ) {
      setDictData({ data: null, breadcrumbs: null });
      setCurrentDictionaryItem(null);

      return;
    }

    if (itemId === 'create') {
      changeFormModule(EFormModule.Create);
      setLoadingStatus(ELoadingStatus.Success);
      return;
    }

    setLoadingStatus(ELoadingStatus.Loading);

    Promise.all([
      version
        ? getAllDictionariesByParams(remoteName, {
            remoteName,
            idIn: [itemId],
            latestVersion: false,
          })
        : getDictionaryItem(remoteName, itemId),
      getDictionaryItemBreadcrumbs(remoteName, itemId),
      fetchAttributeListByOwnerId(remoteName, itemId),
    ])
      .then(([data, breadcrumbs, attributeList]) => {
        const dictItem: TDictionaryItem = Array.isArray(data)
          ? data.find(item => item.version === Number(version)) || last(data)
          : data;

        const validList = new Map<string, TAttributeWithValue>();
        const valuesWithoutAttribute = { ...dictItem.attrs };

        if (version && Array.isArray(data)) {
          const historyMap = new Map();
          data.forEach(dItem => historyMap.set(dItem.version, dItem));

          setCurrentDictionaryItemHistory(historyMap);
        }

        attributeList.map((value: TAttributeWithValue) => {
          const isValueExist = has(valuesWithoutAttribute, value.code);

          if (isValueExist) {
            validList.set(value.code, { ...value, value: valuesWithoutAttribute[value.code] });
            delete valuesWithoutAttribute[value.code];
            value.isExist = true;
          }

          return value;
        });

        const attributeWIthoutValues = attributeList.filter(
          (value: TAttributeWithValue) => !value.isExist
        );

        attributeWIthoutValues.forEach(attrItem => {
          validList.set(attrItem.code, { ...attrItem, value: null });
        });

        const parsedDictItem: TCurrentDictionaryExtended = {
          ...dictItem,
          invalidAttribute: valuesWithoutAttribute,
          validAttributes: validList,
          attributeWIthoutValues,
        };

        setDictData({
          breadcrumbs,
          data: parsedDictItem,
        });

        setCurrentDictionaryItem(parsedDictItem);
        changeFormModule(EFormModule.Edit);
      })
      .finally(() => {
        setLoadingStatus(ELoadingStatus.Success);
      });
  }, [itemId, version]);

  const onSubmit = e => {
    console.log('submit', e);
  };

  if (isFilterActive && isFetched) {
    return (
      <Styled.ItemDetails>
        <SearchResult />
      </Styled.ItemDetails>
    );
  }

  if (isIndexPage && !isFilterActive) {
    return (
      <Styled.ItemDetails>
        <Breadcrumbs crumbList={dictData.breadcrumbs} remoteName={remoteName} />
        <AdditionalParams isIndexDictionary />
      </Styled.ItemDetails>
    );
  }

  return (
    <Styled.ItemDetails>
      {loadingStatus === ELoadingStatus.Loading && <CircularProgress />}
      {(loadingStatus === ELoadingStatus.Success || loadingStatus === ELoadingStatus.Idle) && (
        <>
          <Breadcrumbs crumbList={dictData.breadcrumbs} remoteName={remoteName} />
          <Typography variant="h6">Общая информация</Typography>
          {formModule === EFormModule.Edit ? (
            <DictForm data={dictData.data} onSubmit={onSubmit} />
          ) : formModule === EFormModule.Create || formModule === EFormModule.Copy ? (
            <CreateDictElementForm
              data={dictData.data}
              onSubmit={onSubmit}
              parentId={parentId}
              isCopyMode={formModule === EFormModule.Copy}
            />
          ) : (
            <Typography>Выберите элемент справочника в боковом меню</Typography>
          )}
        </>
      )}
    </Styled.ItemDetails>
  );
});

export default DictionaryItemDetails;
