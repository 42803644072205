import {
  Axios,
  TypeApiRequest,
  lazyInject,
  provide,
} from '../../../../../../../../../../../shared/utils';

@provide.transient()
class OrgIntegraCropwiseErrorMessageService {
  @lazyInject(Axios)
  protected axios: Axios;

  pushMessage = (payload: TypeApiRequest<'pushOrgIntegraCropwiseMessage'>) => {
    return this.axios.api.pushOrgIntegraCropwiseMessage(payload);
  };
}

export { OrgIntegraCropwiseErrorMessageService };
