import { useMemo, memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { generatePath, useHistory } from 'react-router-dom';

import { MainContainer } from '../../../../style';
import { serviceConfig } from '../../configs';
import { IServiceItem } from '../../types/serviceItem.type';

import Styled from './styled';

export const ServiceList = observer(() => {
  const history = useHistory();

  const handleClickOnItem = (item: IServiceItem) => {
    return history.push(generatePath(item.entryPath));
  };

  const renderDictionaryList = useMemo(() => {
    return serviceConfig?.map(item => (
      <Grid item key={item.title}>
        <Styled.DictionaryNameItem variant="outlined" onClick={() => handleClickOnItem(item)}>
          <Typography fontWeight={700}>{item.title}</Typography>
        </Styled.DictionaryNameItem>
      </Grid>
    ));
  }, [serviceConfig]);

  return (
    <MainContainer>
      <Styled.DictionaryNameGrid>
        <Grid spacing={4} container>
          {renderDictionaryList}
        </Grid>
      </Styled.DictionaryNameGrid>
    </MainContainer>
  );
});

export default memo(ServiceList);
