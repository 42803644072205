import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  clientId: number;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
};
type TypeResponse = Blob;

export const downloadAgrosignalReport: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-agrosignal/reports/create/csv/zip`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
