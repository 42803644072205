import { FormControlLabel, Switch, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import 'moment/locale/ru';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  EFormulaReturnType,
  IAggregationFormulaType,
  IFormulaError,
} from '../../../../../../models';
import { HighlightBox } from '../../../../../../../../../../../shared/components';

import { DictionarySelector } from './components';

const INT_REGEXP = new RegExp(/^-?[0-9]*$/);
const FLOAT_REGEXP = new RegExp(/^[-.,0-9]*$/);

interface IProps {
  value: string;
  onChangeConstValue: (value: string) => void;
  blockConfig: IAggregationFormulaType;
  siblingArgumentList: IAggregationFormulaType[];
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
  isBasicAttributeODZ?: boolean;
}

const FormulaConstValue: FC<IProps> = ({
  value,
  onChangeConstValue,
  blockConfig,
  siblingArgumentList,
  handleRemoveError,
  handleSetError,
  isBasicAttributeODZ,
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const [error, setError] = useState<{ text: string; id: string }>(null);

  const isDoubleAwaits = blockConfig?.returnType === EFormulaReturnType.Double;
  const isIntAwaits = blockConfig?.returnType === EFormulaReturnType.Integer;
  const isNumberType = isDoubleAwaits || isIntAwaits;

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      handleRemoveError?.(error?.id);
    };
  }, [error]);

  const onChange = (_value: string) => {
    setInnerValue(_value);
    debouncedChangeHandler(_value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(_value => onChangeConstValue(_value), 100),
    [onChangeConstValue]
  );

  const Input = useMemo(() => {
    switch (blockConfig?.returnType) {
      case EFormulaReturnType.Boolean:
        return (
          <HighlightBox width={300} marginTop={2}>
            <FormControlLabel
              control={<Switch data-test-id={`formula-block-constant-value-boolean`} id="bool" />}
              label="Значение константы"
              sx={{ width: '100%' }}
              checked={innerValue === 'true'}
              name="bool"
              onChange={(e, checked) => {
                onChange(checked ? 'true' : 'false');
              }}
            />
          </HighlightBox>
        );

      case EFormulaReturnType.Uuid:
        return (
          <HighlightBox width={500} marginTop={2} id={`${blockConfig.clientId}-consValue`}>
            <DictionarySelector
              value={innerValue}
              onChange={onChange}
              argList={siblingArgumentList}
              isBasicAttributeODZ={isBasicAttributeODZ}
            />
          </HighlightBox>
        );

      case EFormulaReturnType.Date:
        return (
          <HighlightBox width={300} marginTop={2} id={`${blockConfig.clientId}-consValue`}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
              <DatePicker
                value={value ? moment(value) : null}
                onChange={date => onChange(date?.format('YYYY-MM-DD'))}
                renderInput={props => (
                  <TextField
                    {...props}
                    label="Значение константы"
                    error={Boolean(error)}
                    data-test-id={`formula-block-constant-value-date`}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </HighlightBox>
        );

      default:
        return (
          <HighlightBox width={200} marginTop={2} id={`${blockConfig.clientId}-consValue`}>
            <TextField
              label="Значение константы"
              value={innerValue || ''}
              onChange={event => {
                const _value = event.target.value;

                if (isIntAwaits && _value !== '' && !INT_REGEXP.test(_value)) {
                  return;
                }

                if (isDoubleAwaits && _value !== '' && !FLOAT_REGEXP.test(_value)) {
                  return;
                }

                onChange(_value);
              }}
              type={'text'}
              data-test-id={`formula-block-constant-value-${isNumberType ? 'number' : 'text'}`}
              fullWidth
            />
          </HighlightBox>
        );
    }
  }, [blockConfig?.returnType, innerValue, onChange, isBasicAttributeODZ]);

  return Input;
};

export default memo(FormulaConstValue);
