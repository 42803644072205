import { FC, memo } from 'react';
import { Switch, TextField, Tooltip } from '@mui/material';

import { FormRow } from '../../../../../../../../../../../common';
import AdornmentButtons from '../../../../../../../components/AdornmentButtons/AdornmentButtons';
import { IFileLinkInstanceClientOnly } from '../../../../../../../../../../../../../../../api/models/checklist.attribute.model';

import Styled from './styled';

interface IProps {
  instance: IFileLinkInstanceClientOnly;
  order: number;
  setActive: (item: IFileLinkInstanceClientOnly) => void;
  changeFile?: (value: IFileLinkInstanceClientOnly) => void;
  deleteFile?: (value: IFileLinkInstanceClientOnly) => void;
  isActive?: boolean;
}

const FileLinkInstance: FC<IProps> = ({
  instance,
  isActive,
  setActive,
  changeFile,
  deleteFile,
}) => {
  const isNew = !Boolean(instance?.attribute?.id);

  return (
    <FormRow>
      <AdornmentButtons
        onEditClick={() => changeFile(instance)}
        onDeleteClick={() => deleteFile(instance)}
        hideLockToggler
        hideDelete
      />
      <TextField
        label="Атрибут для фотографии"
        value={instance.imageAttributeName}
        disabled
        fullWidth
      />
      <TextField
        label="ID атрибутаФотографии"
        value={instance.imageAttributeId}
        disabled
        fullWidth
      />
      <Styled.SwitchLabel $disabled>
        <Switch checked={instance.isRequired} />
        Фото обязательно
      </Styled.SwitchLabel>

      <Tooltip title={isNew ? 'Сделать активным атрибут можно только после сохранения' : ''}>
        <Styled.SwitchLabel>
          <Switch
            disabled={isNew}
            onChange={(_, value) => {
              if (!isNew) setActive(value ? instance : null);
            }}
            checked={isActive}
          />
          Активный
        </Styled.SwitchLabel>
      </Tooltip>
    </FormRow>
  );
};

export default memo(FileLinkInstance);
