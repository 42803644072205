import { Button as MButton } from '@mui/material';
import styled from 'styled-components';

const Button = styled(MButton)`
  width: 160px;
`;

const Styled = { Button };

export default Styled;
