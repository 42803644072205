import { isEmpty } from 'lodash';
import { response } from 'express';

import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import { CheckListsTypesStore } from '../stores/checkListTypes/checkListTypes.store';
import { CheckListStore } from '../stores/checkLists/check.list.store';
import { TypeCheckListStatus } from '../../api/models/check.list.model';
import { FilterStore } from '../stores/checkLists/filter.store';

import { ChecklistService } from './../services/checklist/checklist.service';

@provide.singleton()
export class CheckListsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(CheckListStore)
  protected checkListStore: CheckListStore;

  @lazyInject(CheckListsTypesStore)
  protected checkListsTypesStore: CheckListsTypesStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  @lazyInject(ChecklistService)
  protected checklistService: ChecklistService;

  setPage = (v: number) => {
    this.checkListStore.setPage(v);
  };

  setFilterStatus = (status: TypeCheckListStatus[]) => {
    this.filterStore.setFilterStatus(status);
  };

  fetchCheckLists = async () => {
    this.checkListStore.setLoading(true);
    let statuses = '';
    if (!isEmpty(this.filterStore.filters.status)) {
      this.filterStore.filters.status.forEach(status => {
        statuses += `${status.statusId},`;
      });
    }
    const payload = {
      statusIn: statuses,
      size: 10,
      page: this.checkListStore.page,
    };

    try {
      const { content, totalPages } = await this.axios.api.getCheckLists(payload);
      this.checkListStore.setCheckLists(content, totalPages);
    } catch (e) {
      this.checkListStore.error = e.message;
      this.checkListStore.clearCheckLists();
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };

  fetchCheckList = async (
    checkListId: TypeApiRequest<'getCheckListById'>
  ): Promise<TypeApiResponse<'getCheckListById'>> => {
    this.checkListStore.setLoading(true);
    try {
      const checkList = await this.axios.api.getCheckListById(checkListId, {
        omit: ['checkListId'],
      });

      this.checkListStore.setCheckList(checkList);
      return checkList;
    } catch (e) {
      this.checkListStore.error = e.message;
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };

  changeCheckListStatus = async (
    payload: TypeApiRequest<'changeCheckListStatus'>
  ): Promise<TypeApiResponse<'changeCheckListStatus'>> => {
    this.checkListStore.setLoading(true);
    try {
      const checkList = await this.axios.api.changeCheckListStatus(payload);

      this.checkListStore.setCheckList({
        ...this.checkListStore.checkList,
        status: checkList.status,
      });
      return checkList;
    } catch (e) {
      this.checkListStore.error = e.message;
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };

  getChecklistListByParams = (payload: TypeApiRequest<'getChecklistListByParams'>) => {
    this.checkListStore.setLoading(true);

    return this.checklistService.fetchChecklistListByParams(payload).then(data => {
      this.checkListStore.setChecklistListingData(data.content);
      return data;
    });
  };

  exportChecklistByIds = (payload: TypeApiRequest<'exportChecklistByIds'>) => {
    return this.checklistService.exportChecklistByIds(payload);
  };

  importChecklistDryRun = (payload: TypeApiRequest<'importChecklistDryRun'>, file: FormData) => {
    return this.checklistService.importChecklistDryRun(payload, file);
  };

  importChecklist = (payload: TypeApiRequest<'importChecklist'>, file: FormData) => {
    return this.checklistService.importChecklist(payload, file);
  };
}
