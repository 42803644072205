import {
  IPushEventsForm,
  IPushEventsResponse,
} from '../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/models';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = Partial<IPushEventsForm>;

type TypeResponse = IPushEventsResponse;

type TypeError = {};

export const pushEventsOrgIntegraConfig: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/integra/pushevents`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
