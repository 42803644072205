import { observer } from 'mobx-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Skeleton, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import { NewScrollbar, useModal, useNotificator } from '@farmlink/farmik-ui';

import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { FormulaSidebar } from '../FormulaSidebar';
import { FormulaBuilder } from '../../components';
import { useStore } from '../../../../../../../shared/utils';
import { FormulasController, FormulasStore } from '../../mobx';
import { EFormulaTypes } from '../../models';
import { ChecklistsAttributeController } from '../../../../../../../controllers/checklistAttribute.controller';
import { FormulaContext } from '../../contexts';
import { parseChecklistDataToOptionList } from '../../helpers';
import {
  beforeChangeFormulaModalConfig,
  beforeDeleteArgumentFormulaModalConfig,
  formulaPreviewModalConfig,
} from '../../modals';
import { IChecklistAttribute } from '../../../../../../../../api/models/checklist.model';
import {
  AVAILABLE_CALCULATE_ATTRIBUTE_TYPE,
  AVAILABLE_DEPENDENCY_ATTRIBUTE_TYPE,
  AVAILABLE_ODZ_ATTRIBUTE_TYPE,
} from '../../configs';
import { EChecklistAttributeType } from '../../../../../../../../api/models/checklist.attribute.model';
import { ODZContainer } from '../../../../containers/atttibutes/AttributeContainer/components/AttributeBuilderFrom/components';
import {
  beforeDeleteODZModal,
  beforeToggleODZModal,
  formulaODZPreviewModalConfig,
} from '../../../../containers/atttibutes/AttributeContainer/components/AttributeBuilderFrom/components/ODZ/modals';
import { useFormulas } from '../../hooks';
import { AttributeODZController } from '../../../../containers/atttibutes/AttributeContainer/components/AttributeBuilderFrom/components/ODZ/mobx';
import SplitDropdownButton from '../../../../../../../shared/components/SplitDropdownButton/SplitDropdownButton';

const FormulaContainer = () => {
  const store = useStore(FormulasStore);
  const controller = useStore(FormulasController);
  const checklistAttributeController = useStore(ChecklistsAttributeController);
  const { provideODZData, clearODZOnUnmount } = useStore(AttributeODZController);

  const { checklistAttributeId, checklistId } = useParams<{
    checklistId: string;
    checklistAttributeId: string;
  }>();

  const [value, setValue] = useState<EFormulaTypes>(EFormulaTypes.Visibility);
  const [checklistAttributeList, setChecklistAttributeList] = useState([]);
  const [attribute, setAttribute] = useState<IChecklistAttribute>(null);
  const [autoComplete, setAutoComplete] = useState(false);
  const [rawAttributeList, setRawAttributeList] = useState<IChecklistAttribute[]>([]);

  const { registerModalList } = useModal();
  const { setNotification } = useNotificator();

  const controls = useFormulas({
    checklistAttributeId,
    checklistAttribute: attribute,
    formulaType: value,
    autoComplete,
  });

  const fetchFormula = () => {
    controller.fetchFormula(checklistAttributeId).then(attr => {
      setAttribute(attr);

      setAutoComplete(Boolean(attr?.dependency?.autoComplete));
      provideODZData(attr);

      checklistAttributeController
        .getChecklistAttributeList({ checkListId: checklistId, size: 1000 })
        .then(data => {
          setRawAttributeList(data.content.sort((a, b) => a.order - b.order));
        });
    });
  };

  useEffect(() => {
    registerModalList(
      [
        beforeChangeFormulaModalConfig,
        beforeDeleteArgumentFormulaModalConfig,
        formulaPreviewModalConfig,
        beforeToggleODZModal,
        beforeDeleteODZModal,
        formulaODZPreviewModalConfig,
      ],
      'attribute-formula-modals'
    );

    fetchFormula();

    return () => {
      store.clearStore();
      clearODZOnUnmount();
    };
  }, []);

  useEffect(() => {
    setChecklistAttributeList(
      parseChecklistDataToOptionList(
        rawAttributeList.filter(item => {
          const isChecklistInstanceLink =
            item.attribute.type === EChecklistAttributeType.ChecklistInstanceLink;
          const isSameAttr = item.id === attribute.id;
          const isOrderHigher = item.order >= attribute.order;

          return !isSameAttr && !isChecklistInstanceLink && !isOrderHigher;
        })
      )
    );
  }, [rawAttributeList, value, attribute]);

  const saveFormula = (syncSto?: boolean) => {
    controls.saveFormula({ onSuccessCallback: fetchFormula, syncSto });
  };

  const deleteFormula = () => {
    controls.deleteFormula({ onSuccessCallback: fetchFormula });
  };

  useEffect(() => {
    store.setActiveEditor(value);
  }, [value]);

  const errorList = useMemo(() => store.errorLog, [store.errorLog]);

  const isCalculateAvailable = useMemo(
    () =>
      AVAILABLE_CALCULATE_ATTRIBUTE_TYPE.includes(attribute?.attribute?.type) &&
      !attribute?.attribute.isMultiselect,
    [attribute?.attribute?.type, attribute?.attribute.isMultiselect]
  );

  const isDependencyAvailable = useMemo(
    () => AVAILABLE_DEPENDENCY_ATTRIBUTE_TYPE.includes(attribute?.attribute?.type),
    [attribute?.attribute?.type]
  );

  const isODZAvailable = useMemo(
    () => AVAILABLE_ODZ_ATTRIBUTE_TYPE.includes(attribute?.attribute?.type),
    [attribute?.attribute?.type]
  );

  const isDeleteButtonDisabled = value === EFormulaTypes.ODZ;

  const handleChange = (event, newValue: EFormulaTypes) => {
    setValue(newValue);
  };

  return (
    <Layout menuItems={null} sidebarComponent={FormulaSidebar}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1 data-test-id={'formula-title'}>Формулы. {attribute?.attribute?.name ?? ''}</H1>
            <Box display={'flex'} gap={2}>
              <SplitDropdownButton
                buttonList={[
                  {
                    label: 'Сохранить',
                    isDisabled: Boolean(errorList?.length),
                    onClickHandler: () => saveFormula(),
                    dataTestId: 'save-formula-button',
                  },
                  {
                    label: 'Сохранить и применить в СТО',
                    isDisabled: Boolean(errorList?.length),
                    onClickHandler: () => saveFormula(true),
                    dataTestId: 'save-formula-button__sync-sto',
                  },
                ]}
              />
              <Button
                color="error"
                disabled={isDeleteButtonDisabled}
                onClick={deleteFormula}
                data-test-id="delete-formula-button"
              >
                Удалить
              </Button>
            </Box>
          </TopSection>
        </HeaderWrapper>
        <MainBody>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab
                  label={EFormulaTypes.Visibility}
                  value={EFormulaTypes.Visibility}
                  data-test-id={`select-tab-${EFormulaTypes.Visibility}`}
                />

                <Tab
                  label={EFormulaTypes.Calculation}
                  value={EFormulaTypes.Calculation}
                  disabled={!isCalculateAvailable}
                  data-test-id={`select-tab-${EFormulaTypes.Calculation}`}
                />

                <Tab
                  label={EFormulaTypes.Dependency}
                  value={EFormulaTypes.Dependency}
                  disabled={!isDependencyAvailable}
                  data-test-id={`select-tab-${EFormulaTypes.Dependency}`}
                />
                <Tab
                  label={'VALIDATION CRITERIA'}
                  value={EFormulaTypes.ODZ}
                  disabled={!isODZAvailable}
                  data-test-id={`select-tab-${EFormulaTypes.Dependency}`}
                />
              </TabList>
            </Box>
            <FormulaContext.Provider
              value={{ checklistAttributeList, checklistAttribute: attribute, attribute: null }}
            >
              <NewScrollbar>
                <Box height="calc(100vh - 287px)">
                  <TabPanel value={EFormulaTypes.Calculation}>
                    {store.isAttributeLoading ? (
                      <Skeleton width={210} height={60} />
                    ) : (
                      <FormulaBuilder implementationType={EFormulaTypes.Calculation} />
                    )}
                  </TabPanel>

                  <TabPanel value={EFormulaTypes.Visibility}>
                    {store.isAttributeLoading ? (
                      <Skeleton width={210} height={60} />
                    ) : (
                      <FormulaBuilder implementationType={EFormulaTypes.Visibility} />
                    )}
                  </TabPanel>

                  <TabPanel value={EFormulaTypes.Dependency}>
                    {store.isAttributeLoading ? (
                      <Skeleton width={210} height={60} />
                    ) : (
                      <FormulaBuilder
                        autoComplete={autoComplete}
                        setAutoComplete={setAutoComplete}
                        implementationType={EFormulaTypes.Dependency}
                        attributeType={attribute?.attribute?.type}
                      />
                    )}
                  </TabPanel>

                  <TabPanel value={EFormulaTypes.ODZ}>
                    {store.isAttributeLoading ? (
                      <Skeleton width={210} height={60} />
                    ) : (
                      <ODZContainer
                        checklistAttribute={attribute}
                        checklistAttributeList={checklistAttributeList}
                        isCopyODZ
                      />
                    )}
                  </TabPanel>
                </Box>
              </NewScrollbar>
            </FormulaContext.Provider>
          </TabContext>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(FormulaContainer);
