import { computed, makeAutoObservable, runInAction } from 'mobx';
import { ReactNode } from 'react';
import { cloneDeep } from 'lodash';

import { provide } from '../../../../../../../../../../../../shared/utils';
import {
  IOrganizationIntegraTableFilter,
  IOrganizationIntegraTableFilterItemValue,
  TOrganizationIntegraTableFilterItemUnion,
} from '../../../../types/organizationIntegraTable.types';

@provide.singleton()
class OrgIntegraTableFilterStore {
  private _isOpen = false;
  private _isInitializing = false;
  private _isInitialized = false;
  private _isBuilded = false;

  private _rawFilterConfig: IOrganizationIntegraTableFilter = null;
  private _propertyNameToConfig: Map<string, TOrganizationIntegraTableFilterItemUnion> = new Map();

  private _buildedComponentsCollection: Map<string, ReactNode> = new Map();
  private _buildedComponentsValues: Map<
    string,
    IOrganizationIntegraTableFilterItemValue
  > = new Map();

  private _appliedFilters: Map<string, IOrganizationIntegraTableFilterItemValue> = new Map();

  constructor() {
    makeAutoObservable(this, {
      appliedFiltersCount: computed,
    });
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value) {
    this._isOpen = value;
  }

  get isInitializing() {
    return this._isInitializing;
  }

  set isInitializing(value) {
    this._isInitializing = value;
  }

  get isInitialized() {
    return this._isInitialized;
  }

  set isInitialized(value) {
    this._isInitialized = value;
  }

  get isBuilded() {
    return this._isBuilded;
  }

  set isBuilded(value) {
    this._isBuilded = value;
  }

  get rawFilterConfig() {
    return this._rawFilterConfig;
  }

  set rawFilterConfig(value: IOrganizationIntegraTableFilter) {
    this._rawFilterConfig = value;
  }

  get propertyNameToConfig() {
    return this._propertyNameToConfig;
  }

  getConfigByPropertyName = (propertyName: string) => {
    return this._propertyNameToConfig.get(propertyName);
  };

  setConfigByPropertyName = (
    propertyName: string,
    config: TOrganizationIntegraTableFilterItemUnion
  ) => {
    this._propertyNameToConfig.set(propertyName, config);
  };

  get buildedComponentsCollection() {
    return this._buildedComponentsCollection;
  }

  set buildedComponentsCollection(value: Map<string, ReactNode>) {
    this._buildedComponentsCollection = value;
  }
  getBuildedComponentsCollectionAsArray = () => {
    return Array.from(this._buildedComponentsCollection.values());
  };

  addComponentToCollection = (key: string, component: ReactNode) => {
    this._buildedComponentsCollection.set(key, component);
  };

  get buildedComponentsValues() {
    return this._buildedComponentsValues;
  }

  set buildedComponentsValues(value: Map<string, IOrganizationIntegraTableFilterItemValue>) {
    this._buildedComponentsValues = value;
  }

  addComponentValue = (key: string, value: IOrganizationIntegraTableFilterItemValue) => {
    this._buildedComponentsValues.set(key, value);
  };

  removeComponentValue = (key: string) => {
    this._buildedComponentsValues.delete(key);
  };

  getComponentValue = (key: string) => {
    return this._buildedComponentsValues.get(key);
  };

  get countActiveFilterValues() {
    return this._buildedComponentsValues.size;
  }

  get appliedFilters() {
    return this._appliedFilters;
  }

  @computed
  get appliedFiltersCount() {
    return this._appliedFilters.size;
  }

  setAppliedFilters = (value: Map<string, IOrganizationIntegraTableFilterItemValue>) => {
    const newAppliedFilters = cloneDeep(value);

    runInAction(() => {
      this._appliedFilters = newAppliedFilters;
    });
  };

  clearAppliedFilters() {
    this._appliedFilters = new Map();
  }

  clearFilterValues() {
    this._buildedComponentsValues = new Map();
  }

  clearFilterStore() {
    this._isOpen = false;
    this._isInitializing = false;
    this._isInitialized = false;
    this._isBuilded = false;
    this._buildedComponentsCollection = new Map();
    this._propertyNameToConfig = new Map();
    this._buildedComponentsValues = new Map();
    this._appliedFilters = new Map();
    this._rawFilterConfig = null;
  }
}

export default OrgIntegraTableFilterStore;
