import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  dependencyId?: string;
  dependencyValueId: string;
  targetValueId: string;
};

type TypeResponse = string;

export const createEnumDependencyLink: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/attribute/enum/dependencyLinks/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
