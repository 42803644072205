import { IOrgIntegraField } from '../../../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationIntegrations/modules/OrganizationIntegrationsCropwise/modules/OrgIntegraCropwiseFields/models';
import { TypeResponsePageable } from '../../../../../models/response.pageable.model';
import { TypeApiRoute } from '../../../../../models/type.api.request';

type TypeRequest = Partial<{
  organizationId: string;
  page: number;
  size: number;
  fieldId: string;
  fieldName: string;
}>;

type TypeResponse = TypeResponsePageable & { content: IOrgIntegraField[] };

type TypeError = {};

export const getOrgIntegraCropwiseFieldListByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: () => `/api/integra-cw/admin/mapping/field/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
