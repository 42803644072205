import { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';

import { AdminRoutes } from '../../routes';

import { OrganizationsList } from './pages';
import { OrganizationRouter } from './OrganizationRouter';
import { exportOrganizationsFieldsModalConfig } from './modals';

export const OrganizationsRouter: FC<any> = () => {
  const { registerModalList } = useModal();

  useEffect(() => {
    registerModalList([exportOrganizationsFieldsModalConfig], 'organizationModals');
  }, []);

  return (
    <Switch>
      <Route path={AdminRoutes.OrganizationItem} component={OrganizationRouter} />
      <Route path={AdminRoutes.Organizations} component={OrganizationsList} />
      <Redirect to={AdminRoutes.Organizations} />
    </Switch>
  );
};
