import { Chip, Stack } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const SelectedItemList = styled(Stack)`
  margin: 12px 0;
  height: 300px;
  overflow-y: auto;
`;

const SelectedItem = styled(Chip)`
  justify-content: space-between !important;
`;

const Styled = {
  Wrapper,
  SelectedItemList,
  SelectedItem,
};

export default Styled;
