import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../../../constants/colors';

const FileSubFormWrapper = styled.div`
  background-color: ${Colors.lightnessGray};
  border-radius: 5px;
  padding: 12px 6px 6px;
  width: 100%;
`;

const Styled = {
  FileSubFormWrapper,
};

export default Styled;
