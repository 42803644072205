import { isEmpty } from 'lodash';

import { TypeAlert } from '../../../../../../../../../api/models/alert.model';
import { STATUSES } from '../../../../../../../../../constants/statuses'

export const getValues = (alert: TypeAlert) => ({
    createdBy: alert.createdBy || '',
    error: alert.error || '',
    name: alert.name || '',
    fullName: alert.fullName || '',
    errorDescription: alert.errorDescription || '',
    id: alert.id || '',
    status: STATUSES[alert.status] || '',
    taskUpdate: !isEmpty(alert.taskUpdate) ? alert.taskUpdate : null,
    timestamp: alert.timestamp || '',
});
