import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CreateAttributeModal } from '../components';

export const CREATE_ATTRIBUTE_MODAL = 'createAttributeModal';

export const createAttributeModalConfig: TModalConfig = {
  id: CREATE_ATTRIBUTE_MODAL,
  name: CREATE_ATTRIBUTE_MODAL,
  type: EModalType.Custom,
  title: 'Атрибут чек-листа',
  children: CreateAttributeModal,

  styledProps: {
    $modalPaddings: '20px',
    $size: '90%',
    $isFullContentWidth: true,
    $height: '100%',
  },
};
