import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import { IOrgIntegraField } from '../../models';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { ORG_INTEGRA_FIELD_MODAL_ID } from '../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import useFieldService from '../../hooks/useFieldService';

const FieldTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();
  const { isSyncing, isMapping, handleCropwiseImport, handleGroupAutomap } = useFieldService({
    organizationId,
  });

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteFieldList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = () => {
    openModalByModalId(
      ORG_INTEGRA_FIELD_MODAL_ID,
      {
        mode: 'create',
        organizationId,
        fieldId: null,
        cropwiseFieldId: null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraField) => {
    openModalByModalId(
      ORG_INTEGRA_FIELD_MODAL_ID,
      {
        mode: 'update',
        organizationId,
        fieldId: value?.fieldId,
        cropwiseFieldId: value?.cropwiseFieldId,
        cropwiseFieldName: value?.cropwiseFieldName,
        id: value.id,
        fieldName: value?.fieldName,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraField> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchFieldList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const onGroupMappingClick = () => {
    handleGroupAutomap();
  };

  const onCropwiseImportClick = () => {
    handleCropwiseImport();
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraField>
          actionButton={{ text: 'Добавить поле', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          additionalActionButtonList={[
            {
              text: 'Импорт полей Cropwise',
              handler: onCropwiseImportClick,
              variant: 'success',
              isDisabled: isSyncing,
            },
            {
              text: 'Групповой маппинг',
              handler: onGroupMappingClick,
              variant: 'success',
              isDisabled: isMapping,
            },
          ]}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID поля "Помощник"',
                propertyName: 'fieldId',
                placeholder: 'Введите ID поля',
              },
              {
                type: 'text',
                label: 'Название поля "Помощник"',
                propertyName: 'fieldName',
                placeholder: 'Введите название поля',
              },
              {
                type: 'number',
                label: 'ID поля "Cropwise"',
                propertyName: 'cropwiseFieldId',
                placeholder: 'Введите ID поля',
              },
              {
                type: 'text',
                label: 'Название поля "Cropwise"',
                propertyName: 'cropwiseFieldName',
                placeholder: 'Введите название поля',
              },
            ],
          }}
          columnConfig={[
            {
              field: 'fieldId',
              headerName: 'ID "Помощник"',
              flex: 1,
              valueGetter: row => row.row.fieldId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'fieldName',
              headerName: 'Имя "Помощник"',
              flex: 1,
              valueGetter: row => row.row.fieldName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwiseFieldId',
              headerName: 'ID "Cropwise"',
              flex: 1,
              valueGetter: row => row.row.cropwiseFieldId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwiseFieldName',
              headerName: 'Имя "Cropwise"',
              flex: 1,
              valueGetter: row => row.row.cropwiseFieldName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              minWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default FieldTable;
