import { EFormulaReturnType, EFormulaType } from '../../../models';
import { IFormulaFunction } from '../../../models/FormulaFunction.types';

export const SUBTRACT: IFormulaFunction = {
  name: 'SUBTRACT',
  label: 'Вычитание',
  argsType: [
    EFormulaType.Attribute,
    EFormulaType.Const,
    EFormulaType.Function,
    EFormulaType.Property,
  ],
  minArguments: 2,
  availableSiblings: {
    [EFormulaReturnType.Double]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
    [EFormulaReturnType.Integer]: [EFormulaReturnType.Double, EFormulaReturnType.Integer],
  },
  primitiveType: [EFormulaReturnType.Integer, EFormulaReturnType.Double],
  returnType: [EFormulaReturnType.Integer, EFormulaReturnType.Double],
};
