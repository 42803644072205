import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { SpinnerLoader, SpinnerLoaderWrapper } from '../../../../../shared/styles/dotsLoader';
import { useStore } from '../../../../../shared/utils/IoC';
import { EditQuoteStore } from '../../../../../stores/quotes/edit.quote.store';
import { AdminRoutes } from '../../../../routes';

import { EditQuoteBlock } from './components/EditQuoteBlock/EditQuoteBlock';
import Style from './EditQuote.style';

export const EditQuote: FC = observer(() => {
  const editQuoteStore = useStore(EditQuoteStore);
  const params = useParams();
  const history = useHistory();

  console.log('params:', params);

  useEffect(() => {
    const quoteId = (params as any)?.quoteId;
    if (quoteId) {
      editQuoteStore.fetchQuote(quoteId);
    }
  }, []);

  const onCancel = useCallback(() => {
    history.push(AdminRoutes.Quotes);
  }, []);

  const onPublish = useCallback(async () => {
    console.log('onPublish: ', editQuoteStore.quoteForEdit);
    await editQuoteStore.updateQuote();
    history.push(AdminRoutes.Quotes);
  }, []);

  const isPublishButtonDisabled = useMemo(
    () =>
      !(
        editQuoteStore.quoteForEdit &&
        editQuoteStore.quoteForEdit.cultureId &&
        editQuoteStore.quoteForEdit.basisId &&
        editQuoteStore.quoteForEdit.economicRegionId &&
        editQuoteStore.quoteForEdit.providerId &&
        editQuoteStore.quoteForEdit.currencyId &&
        editQuoteStore.quoteForEdit.dateOfObs &&
        editQuoteStore.quoteForEdit.unitsId &&
        editQuoteStore.quoteForEdit.price
      ),
    [editQuoteStore.quoteForEdit]
  );

  return !editQuoteStore.quoteForEdit ? (
    <SpinnerLoaderWrapper>
      <SpinnerLoader data-test-id="dots-loader">
        <span />
      </SpinnerLoader>
    </SpinnerLoaderWrapper>
  ) : (
    <Style.Wrapper>
      <EditQuoteBlock quote={editQuoteStore.quoteForEdit} onChange={editQuoteStore.onQuoteChange} />
      <Style.ActionsBlock>
        <Button
          color={'default'}
          type={'button'}
          styleType={'outlined'}
          alignment={'center'}
          onClick={onCancel}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          styleType={'outlined'}
          alignment={'center'}
          onClick={onPublish}
          disabled={isPublishButtonDisabled}
        >
          Опубликовать
        </Button>
      </Style.ActionsBlock>
    </Style.Wrapper>
  );
});
