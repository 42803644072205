import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useMemo, useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraAgrosignalService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import {
  ERROR_MESSAGE_DETAILS_ID,
  ERROR_MESSAGE_MULTIPLE_PUSH_ID,
  ERROR_MESSAGE_SINGLE_PUSH_ID,
} from '../../modals';
import {
  EOrgIntegraAgrosignalErrorMessageEntityType,
  EOrgIntegraAgrosignalErrorMessageOperationType,
  IOrgIntegraAgrosignalErrorMessage,
} from '../../models/orgIntegraAgrosignalErrorMessage';
import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';

const TYPE_ENTITY_SCHEME: Record<
  EOrgIntegraAgrosignalErrorMessageEntityType,
  { text: string; color: string }
> = {
  [EOrgIntegraAgrosignalErrorMessageEntityType.Checklist]: { text: 'Шаблон', color: 'green' },
  [EOrgIntegraAgrosignalErrorMessageEntityType.ChecklistInstance]: {
    text: 'Чек-лист',
    color: 'yellow',
  },
  [EOrgIntegraAgrosignalErrorMessageEntityType.Task]: { text: 'Задача', color: 'blue' },
  [EOrgIntegraAgrosignalErrorMessageEntityType.Field]: { text: 'Поле', color: 'red' },
};

const TYPE_OPERATION_SCHEME: Record<
  EOrgIntegraAgrosignalErrorMessageOperationType,
  { text: string; color: string }
> = {
  [EOrgIntegraAgrosignalErrorMessageOperationType.Create]: { text: 'Создание', color: 'green' },
  [EOrgIntegraAgrosignalErrorMessageOperationType.Delete]: {
    text: 'Удаление',
    color: 'red',
  },
  [EOrgIntegraAgrosignalErrorMessageOperationType.Update]: { text: 'Обновление', color: 'blue' },
};

const ErrorMessageTable = () => {
  const service = useStore(OrgIntegraAgrosignalService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>(null);
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteErrorMessageList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = (_, selectionList: string[]) => {
    openModalByModalId(
      selectionList?.length > 0 ? ERROR_MESSAGE_MULTIPLE_PUSH_ID : ERROR_MESSAGE_SINGLE_PUSH_ID,
      {
        mode: 'create',
        organizationId,
        selectionList: selectionList || null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraAgrosignalErrorMessage) => {
    openModalByModalId(
      ERROR_MESSAGE_DETAILS_ID,
      {
        mode: 'update',
        organizationId,
        errorMessage: value,
      },
      () => {
        // TODO придумать более оптимальный апдейт
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraAgrosignalErrorMessage> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchErrorMessageList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  const entityTypeList = useMemo(
    () =>
      Object.values(EOrgIntegraAgrosignalErrorMessageEntityType).map(value => ({
        label: TYPE_ENTITY_SCHEME[value].text,
        value,
      })),
    []
  );

  const operationTypeList = useMemo(
    () =>
      Object.values(EOrgIntegraAgrosignalErrorMessageOperationType).map(value => ({
        label: TYPE_OPERATION_SCHEME[value].text,
        value,
      })),
    []
  );

  const getType = (value: IOrgIntegraAgrosignalErrorMessage['entityType']) => {
    const scheme = TYPE_ENTITY_SCHEME[value];

    return scheme?.text ?? value;
  };

  const getOperation = (value: IOrgIntegraAgrosignalErrorMessage['operationType']) => {
    const scheme = TYPE_OPERATION_SCHEME[value];

    return scheme?.text ?? value;
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraAgrosignalErrorMessage>
          actionButton={{ text: 'Обработка', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID “Помощник”',
                propertyName: 'entityId',
                placeholder: 'Введите ID “Помощник”',
              },
              {
                type: 'select',
                label: 'Сущность',
                propertyName: 'entityType',
                placeholder: 'Выберите сущность',
                optionList: entityTypeList,
              },
              {
                type: 'select',
                label: 'Операция',
                propertyName: 'operationType',
                placeholder: 'Выберите операцию',
                optionList: operationTypeList,
              },
              {
                itemList: [
                  {
                    type: 'number',
                    label: 'Минимальное количество попыток',
                    propertyName: 'countAttemptsMin',
                    placeholder: 'Введите минимальное количество попыток',
                  },
                  {
                    type: 'number',
                    label: 'Максимальное количество попыток',
                    propertyName: 'countAttemptsMax',
                    placeholder: 'Введите максимальное количество попыток',
                  },
                ],
              },

              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт создания',
                    propertyName: 'createStartDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание создания',
                    propertyName: 'createEndDateTime',
                    placeholder: 'Введите дату создания',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                isDateGroupConstraints: true,
                itemList: [
                  {
                    type: 'date',
                    label: 'Старт обновления',
                    propertyName: 'updateStartDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMin: true,
                    format: 'ISO',
                  },
                  {
                    type: 'date',
                    label: 'Окончание обновления',
                    propertyName: 'updateEndDateTime',
                    placeholder: 'Введите дату обновления',
                    isGroupMax: true,
                    format: 'ISO',
                  },
                ],
              },
              {
                type: 'text',
                label: 'Автор',
                propertyName: 'userId',
                placeholder: 'Введите ID автора',
              },
            ],
          }}
          columnConfig={[
            {
              field: 'userName',
              headerName: 'Автор',
              flex: 1,
              valueGetter: row => row.row?.employee?.userName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'entityType',
              headerName: 'Сущность',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row?.entityType,
              renderCell: row => getType(row?.value),
            },
            {
              field: 'operationType',
              headerName: 'Операция',
              flex: 1,
              valueGetter: row => row.row?.operationType,
              renderCell: row => getOperation(row?.value),
            },
            {
              field: 'entityId',
              headerName: 'ID “Помощник”',
              flex: 1,
              maxWidth: 200,
              valueGetter: row => row.row.entityId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'countAttempts',
              headerName: 'Кол-во попыток',
              flex: 1,
              maxWidth: 50,
              valueGetter: row => row.row.countAttempts,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ErrorMessageTable;
