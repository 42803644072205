import { EDictionaryItemStatus } from '../../../../../../../../../api/models/dictionaries.model';
import {
  EHasAdditionalAttribute,
  ESearchPath,
  IAdvancedFilter,
} from '../../../../../../../../../types/advancedFilter.types';
import { EInputType } from '../AdvancedFilter';

import { ISelectOptionExtended } from './../../../../../../../../../types/selectOption';

export interface IFormConfigItem {
  id: keyof IAdvancedFilter;
  value?: string | boolean | number | ISelectOptionExtended;
  isAdditionalData?: boolean;
  type: EInputType;
  label?: string;
  selectValues?: ISelectOptionExtended[];
  disableClear?: boolean;
  onSelectValue?: (value: string | boolean | number | ISelectOptionExtended) => void;
}

const getFilterFormConfig = (
  additionalAttributeOptionList: ISelectOptionExtended[],
  onSelectAdditionalAttribute: (value: ISelectOptionExtended) => void
): Array<IFormConfigItem | Array<IFormConfigItem>> => [
  {
    id: 'id',
    label: 'ID',
    type: EInputType.String,
    value: '',
  },
  {
    id: 'parentId',
    label: 'ID Родителя',
    type: EInputType.String,
    value: '',
  },
  {
    id: 'name',
    label: 'Наименование',
    type: EInputType.String,
    value: '',
  },
  {
    id: 'code',
    label: 'Код',
    type: EInputType.String,
    value: '',
  },
  [
    {
      id: 'path',
      label: 'Path',
      type: EInputType.String,
      value: '',
    },
    {
      id: 'searchPath',
      type: EInputType.Select,
      label: 'Способ поиска',
      value: '',
      selectValues: [
        { value: ESearchPath.PathEquals, label: 'Точное совпадение' },
        { value: ESearchPath.PathPrefix, label: 'Все дерево' },
      ],
    },
  ],
  {
    id: 'isLastVersion',
    label: 'Только последняя версия',
    type: EInputType.Switch,
    value: false,
  },
  {
    id: 'isOriginal',
    label: 'Только оригинальные',
    type: EInputType.Switch,
    value: false,
  },
  {
    id: 'level',
    label: 'Уровень записи',
    type: EInputType.String,
    value: '',
  },
  {
    id: 'status',
    type: EInputType.Select,
    label: 'Статус',
    value: '',
    selectValues: [
      { value: EDictionaryItemStatus.Active, label: 'Активная' },
      { value: EDictionaryItemStatus.Inactive, label: 'Неактивная' },
    ],
  },
  {
    id: 'hasAdditionalAttribute',
    type: EInputType.Select,
    label: 'Наличие атрибута',
    selectValues: [
      { value: EHasAdditionalAttribute.HasAttribute, label: 'Есть' },
      { value: EHasAdditionalAttribute.NoAttribute, label: 'Нет' },
      { value: EHasAdditionalAttribute.NotSelected, label: 'Не выбрано' },
    ],
    disableClear: true,
  },
  [
    {
      id: 'filterAttribute',
      type: EInputType.Select,
      label: 'Атрибут',
      selectValues: additionalAttributeOptionList,
      onSelectValue: onSelectAdditionalAttribute,
    },
    {
      id: 'additionalAttribute',
      label: 'Значение доп. параметра',
      type: EInputType.String,
      value: '',
    },
  ],
];

export default getFilterFormConfig;
