import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { ExpertiseType, ExpertiseSelectedType } from '../../../api/models/expertise.model';
import { UserExpertiseType } from '../../../api/models/user.model';

@provide.singleton()
export class ExpertiseStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  expertises: ExpertiseType[] = [];
  expertise: ExpertiseType = null as ExpertiseType;
  selectedExpertises: ExpertiseSelectedType[] = [];
  searchExpertise = '';

  formValuesErrors = {};

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setExpertises = (expertises: ExpertiseType[]) => {
    runInAction(() => {
      this.expertises = expertises;
    });
  };

  setExpertise = (expertise: ExpertiseType) => {
    runInAction(() => {
      this.expertise = expertise;
    });
  };

  setSearchExpertise = (value: string) => {
    runInAction(() => {
      this.searchExpertise = value;
    });
  };

  setSelectedExpertises = (expertise: ExpertiseType) => {
    runInAction(() => {
      this.selectedExpertises = [...this.selectedExpertises, { ...expertise, confirmations: 0 }];
    });
  };

  setUserExpertisesToSelected = (userExpertises: UserExpertiseType[]) => {
    runInAction(() => {
      this.selectedExpertises = userExpertises.map(item => ({
        expertiseId: item.expertiseId,
        expertiseName: item.expertiseName,
        confirmations: item.confirmations,
      }));
    });
  };

  deleteExpertise = (id: string) => {
    this.selectedExpertises = this.selectedExpertises.filter(item => item.expertiseId !== id);
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };

  reset = () => {
    runInAction(() => {
      this.expertises = [];
      this.expertise = null;
      this.selectedExpertises = [];
      this.searchExpertise = '';
    });
  };
}
