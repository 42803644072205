import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/response.pageable.model';
import IEnumDependency from '../../models/enumDependency.model';

type TypeRequest = {
  dependencyAttributeId?: string;
  dependencyAttributeName?: string;
  targetAttributeId?: string;
  targetAttributeName?: string;
  page?: number;
  size?: number;
};

type TypeResponse = TypeResponsePageable & { content: IEnumDependency[] };

export const getEnumDependency: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/attribute/enum/dependency/search`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
