import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios, localizeValidationTitle } from '../../shared/utils';
import { TypeEmployee, FormValueError, RoleInfo } from '../../../api/models/employee.model';

import { FilterStore } from './filter.store';

@provide.singleton()
export class EmployeeStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  employees: TypeEmployee[] = [];
  employee: TypeEmployee = {} as TypeEmployee;

  error = '';
  showError = false;

  roles: RoleInfo[] = [];

  formValuesErrors = {};

  page = 0;
  totalPages = 0;

  private _pageSize = 20;
  private _totalElements = 0;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get pageSize() {
    return this._pageSize;
  }

  get totalElements() {
    return this._totalElements;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setEmployees = (employees: TypeEmployee[], totalPages: number, totalElements: number) => {
    runInAction(() => {
      this.employees = employees;
      this.totalPages = totalPages;
      this._totalElements = totalElements;
    });
  };

  setEmployee = (employee: TypeEmployee) => {
    runInAction(() => {
      this.employee = employee;
    });
  };

  setError = (error: string) => {
    runInAction(() => {
      this.error = error;
    });
  };

  setShowError = (show: boolean) => {
    runInAction(() => {
      this.showError = show;
    });
  };

  setRoles = (roles: RoleInfo[]) => {
    this.roles = roles;
    this.filterStore.setRoleOptions(roles);
  };

  setFormValueErrors = (error: FormValueError) => {
    runInAction(() => {
      this.formValuesErrors = {
        ...this.formValuesErrors,
        [error.source]: localizeValidationTitle(error.title),
      };
    });
  };

  setFormValuesErrors = (errors: FormValueError[]) => {
    runInAction(() => {
      errors.forEach(({ source, title }) => {
        this.formValuesErrors = {
          ...this.formValuesErrors,
          [source]: localizeValidationTitle(title),
        };
      });
    });
  };

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  setTotalElements = (count: number) => {
    this._totalElements = count;
  };

  clearEmployees = () => {
    this.setEmployees([], 0, 0);
  };

  clearEmployee = () => {
    this.setEmployee({} as TypeEmployee);
  };

  clearRoles = () => {
    this.setRoles([]);
    this.filterStore.setRoleOptions([]);
  };

  clearStore = () => {
    this.clearEmployee();
    this.clearEmployees();
    this.setPage(0);
    this.setPageSize(20);
    this.setTotalElements(0);
    this.clearRoles();
  };
}
