import { isEmpty } from 'lodash';
import moment from 'moment';

import { TypeEmployee } from '../../../../../../../api/models/employee.model';

export const getRows = (employees: TypeEmployee[]) =>
  employees.map(
    ({ id, status, userName, roleInfo, phone, email, organizationJoinDate, userId }) => {
      const role = !isEmpty(roleInfo) ? roleInfo.name : '';
      const joinDate = moment(organizationJoinDate).format('DD.MM.YYYY') || '';

      return {
        id,
        status,
        userName,
        role,
        phone,
        email,
        joinDate,
        userId,
      };
    }
  );
