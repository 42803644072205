import { IOrgIntegraConfigCropwise } from '../../../../../../../../../../../api/models/integra/config.model';
import { IOrgIntegraEmployee } from '../../OrgIntegraCropwiseEmployees/models';

export interface IOrgIntegraCropwiseErrorMessage {
  id: string;
  entityType: EOrgIntegraCropwiseErrorMessageEntityType;
  entityId: string;
  operationType: EOrgIntegraCropwiseErrorMessageOperationType;
  data: {
    additionalProp1: {};
    additionalProp2: {};
    additionalProp3: {};
  };
  employee: IOrgIntegraEmployee;
  organization: IOrgIntegraConfigCropwise;
  exceptionText: string;
  countAttempts: number;
  createDateTime: string;
  updateDateTime: string;
}

export interface IOrgIntegraCropwiseErrorMessageUpdate {
  entityType: EOrgIntegraCropwiseErrorMessageEntityType;
  entityId: string;
  operationType: EOrgIntegraCropwiseErrorMessageOperationType;
  data: Record<string, string>;
  employeeId: string;
  organizationId: string;
  exceptionText: string;
  id: string;
  countAttempts: number;
}

export enum EOrgIntegraCropwiseErrorMessageEntityType {
  ChecklistInstance = 'CHECKLIST_INSTANCE',
  Checklist = 'CHECKLIST',
  Task = 'TASK',
  Field = 'FIELD',
}

export enum EOrgIntegraCropwiseErrorMessageOperationType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export const ORG_INTEGRA_CROPWISE_OPERATION_TYPE_NAME = {
  [EOrgIntegraCropwiseErrorMessageOperationType.Create]: 'Создание',
  [EOrgIntegraCropwiseErrorMessageOperationType.Delete]: 'Удаление',
  [EOrgIntegraCropwiseErrorMessageOperationType.Update]: 'Обновление',
};
