import { FC, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { isFunction } from 'lodash';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

enum Placement {
  'left-start' = 'left-start',
  'left' = 'left',
  'left-end' = 'left-end',
  'right-start' = 'right-start',
  'right' = 'right',
  'right-end' = 'right-end',
  'bottom-start' = 'bottom-start',
  'bottom' = 'bottom',
  'bottom-end' = 'bottom-end',
  'top-start' = 'top-start',
  'top' = 'top',
  'top-end' = 'top-end',
}

interface Option {
  title: string;
  disabled?: boolean;
  onClick?: any;
}

interface Props {
  title: string;
  options: Option[];
  placement?: Placement;
  transition?: boolean;
  disablePortal?: boolean;
  selectedIndex?: number;
  popperStyle?: any;
}

export const ButtonGroupComponent: FC<Props> = observer(
  ({
    title,
    placement = Placement['bottom-start'],
    transition = true,
    disablePortal = true,
    options,
    selectedIndex = null,
    popperStyle = {},
  }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState(selectedIndex);

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
      fn: any
    ) => {
      setSelected(index);

      if (isFunction(fn)) {
        fn();
      }

      setOpen(false);
    };

    const handleClick = (index, fn) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      handleMenuItemClick(event, index, fn);
    };

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup variant="contained" ref={anchorRef}>
          <Button onClick={handleToggle}>{title}</Button>
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition={transition}
          disablePortal={disablePortal}
          placement={placement}
          sx={popperStyle}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.title}
                        disabled={option.disabled || false}
                        selected={selected ? index === selected : false}
                        onClick={handleClick(index, option.onClick)}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
);
