import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { EUserAdminResetPasswordStatus } from '../../../../../../../../api/models/user.admin.model';
import { ReactComponent as Success } from '../../../../../../../shared/assets/images/success.svg';
import { ReactComponent as Cancel } from '../../../../../../../shared/assets/images/cancel.svg';

type Props = {
  status: EUserAdminResetPasswordStatus;
  open: boolean;
  onClose: () => void;
  className?: string;
  dataTestId?: string;
};

export const ResetPasswordDialog: FC<Props> = ({
  status,
  open,
  onClose,
  className,
  dataTestId = '',
}) => {
  const message =
    status === EUserAdminResetPasswordStatus.SUCCESS
      ? 'Пароль успешно создан'
      : 'Не удалось сбросить пароль';

  const buttonLable = status === EUserAdminResetPasswordStatus.SUCCESS ? 'Хорошо' : 'Закрыть';

  const Icon = status === EUserAdminResetPasswordStatus.SUCCESS ? Success : Cancel;

  const testId =
    status === EUserAdminResetPasswordStatus.SUCCESS
      ? `${dataTestId}-success`
      : `${dataTestId}-error`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      className={className}
      data-test-id={testId}
    >
      <DialogTitle>
        <Icon />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{buttonLable}</Button>
      </DialogActions>
    </Dialog>
  );
};
