import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import * as L from 'leaflet';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import markerIcon from 'leaflet/dist/images/marker-icon.png';

import { IOrgIntegraCropwiseChecklistInstance } from '../../../../models/OrgIntegraCropwiseChecklistInstance';

const MapPoint = () => {
  const { getModalPayload, closeModal } = useModal();

  const { instance } = getModalPayload() as { instance: IOrgIntegraCropwiseChecklistInstance };

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current === null) {
      mapRef.current = L.map('map', {
        center: L.latLng(instance.latitude, instance.longitude),
        zoom: 13,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mapRef.current);

      const marker = L.marker(L.latLng(instance.latitude, instance.longitude), {
        icon: L.icon({
          iconUrl: markerIcon,
          popupAnchor: [instance.latitude, instance.longitude],
        }),
      });

      marker.addTo(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [instance]);

  return (
    <Box>
      <div id="map" style={{ height: '440px', width: '376px', marginBottom: '8px' }} />
      <ModalFooter denyButton={{ title: 'Закрыть', handler: closeModal }} />
    </Box>
  );
};

export default MapPoint;
