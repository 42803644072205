import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';

import { useStore } from '../../../../../../../shared/utils';
import { OrganizationFieldsController, OrganizationFieldsStore } from '../../mobx';

import * as Styled from './OrganizationFieldsTable.styles';

const columns = [
  {
    field: 'cultureName',
    type: 'string',
    headerName: 'Название культуры',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },

  {
    field: 'totalArea',
    type: 'string',
    headerName: 'Площадь (Га)',
    minWidth: 50,
    flex: 1,
    sortable: false,
  },

  {
    field: 'cultureZoneCount',
    type: 'string',
    headerName: 'Количество КЗ',
    minWidth: 50,
    flex: 1,
    sortable: false,
  },
];

export const OrganizationFieldsTable: FC = observer(() => {
  const { organizationId } = useParams<{ organizationId?: string }>();

  const controller = useStore(OrganizationFieldsController);

  const store = useStore(OrganizationFieldsStore);

  useEffect(() => {
    if (!store.seasonsFilterValue?.value) {
      return;
    }

    controller.fetchCultureList({ organizationId, year: store.seasonsFilterValue?.value });
  }, [organizationId, store.seasonsFilterValue?.value]);

  const rows = useMemo(() => {
    return store.cultureList.map(({ culture, totalArea, cultureZoneCount }) => ({
      id: culture.id,
      cultureName: culture.name,
      totalArea,
      cultureZoneCount,
    }));
  }, [store.cultureList]);

  return (
    <Container disableGutters maxWidth={false}>
      <Styled.GridWrapper>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          rows={rows}
          columnBuffer={2}
          columnThreshold={2}
          loading={store.isLoadingCultureList}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Styled.GridWrapper>
    </Container>
  );
});
