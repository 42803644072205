import { FC } from 'react';
import { Link as LinkFarmik } from '@farmlink/farmik-ui';
import styled from 'styled-components';

type BackButtonProps = {
  onClick: () => void;
};

export const BackButtonWrapper = styled.div`
  position: absolute;
  left: 60px;
  top: 24px;

  @media (max-width: 767px) {
    left: 16px;
  }

  p {
    font-weight: 400;
  }
`;

export const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <BackButtonWrapper>
    <LinkFarmik
      dataTestId="back-button"
      color="default"
      label="Назад"
      onClick={onClick}
      prefixIcon="arrow_left"
    />
  </BackButtonWrapper>
);
