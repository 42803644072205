import { IChecklistAttribute } from '../../../../../../../api/models/checklist.model';
import { ISelectOptionExtended } from '../../../../../../../types/selectOption';

export const parseChecklistDataToOptionList = (
  list: IChecklistAttribute[]
): ISelectOptionExtended<IChecklistAttribute>[] =>
  list.map(item => {
    return {
      label: item.attribute?.name,
      value: item.id,
      rawData: item,
    };
  });
