import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { MenuItemsProps } from '../../../../shared/menu/type';
import { AdminRoutes } from '../../../routes';

type TGetBasicLayoutMenu = (config: { goBackLink: AdminRoutes }) => MenuItemsProps;

export const getBasicChecklistLayoutMenu: TGetBasicLayoutMenu = config => ({
  back: {
    name: 'Вернуться к списку',
    link: config.goBackLink,
    icon: ArrowBackIosIcon,
    slug: 'back',
  },
});
