import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import { getOrganizationMenu } from '../../../../../../shared/menu';
import { Layout } from '../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../style';
import useOrgIntegraNavigation from '../hooks/useOrgIntegraNavigation';
import { AgrosignalIcon, CropwiseIcon } from '../../../../../../shared/components';
import { CoreIntegrationForm } from '../components';
import { ICoreIntegrationForm } from '../models';
import { useStore } from '../../../../../../shared/utils';
import OrgIntegraConfigService from '../mobx/services/orgIntegraConfig.service';
import useIntegraConfigAction from '../hooks/useIntegraConfigAction';
import { OrganizationsStore } from '../../../../../../stores/organizations/organizations.store';
import { ORG_INTEGRA_SYNCHRONIES_MODAL_ID, synchroniesModalConfig } from '../modals';

const OrganizationIntegrations = () => {
  const { fetchOrgIntegraConfig } = useStore(OrgIntegraConfigService);
  const orgStore = useStore(OrganizationsStore);

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState<'create' | 'update' | 'delete'>(null);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const navigate = useOrgIntegraNavigation();
  const { createConfig, deleteConfig, updateConfig } = useIntegraConfigAction();
  const { registerModalList, openModalByModalId } = useModal();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  useEffect(() => {
    registerModalList([synchroniesModalConfig], 'orgIntegraConfig');
  }, []);

  const onSubmit = (formData: Partial<ICoreIntegrationForm>) => {
    if (action === 'create') {
      return createConfig(formData)?.then(() => {
        fetchConfig();
      });
    }

    if (action === 'update') {
      return updateConfig(formData)?.then(() => {
        fetchConfig();
      });
    }

    if (action === 'delete') {
      return deleteConfig()?.then(() => {
        fetchConfig();
      });
    }
  };

  const fetchConfig = () => {
    setIsLoading(true);

    if (!orgStore?.organization || !orgStore.isLoaded) return;

    formik.setValues({
      ...formik.values,
      organizationId: orgStore.organization.organizationId,
      organizationINN: orgStore.organization.INN,
      organizationName: orgStore.organization.name,
      endTime: null,
      startTime: null,
      isEnabled: false,
    });

    fetchOrgIntegraConfig(organizationId)
      .then(data => {
        setAction('update');
        formik.setFieldValue('isEnabled', true);
        formik.setFieldValue('startTime', data?.startTime ?? null);
        formik.setFieldValue('endTime', data?.endTime ?? null);
      })
      .catch(() => {
        setAction('create');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openSyncModal = () => {
    openModalByModalId(ORG_INTEGRA_SYNCHRONIES_MODAL_ID, formik.values);
  };

  const formik = useFormik<Partial<ICoreIntegrationForm>>({
    initialValues: {},
    onSubmit,
  });

  console.log(formik.values);

  useEffect(() => {
    fetchConfig();
  }, [organizationId, orgStore.loaded]);

  useEffect(() => {
    if (action === 'create') return;

    setAction(formik?.values?.isEnabled ? 'update' : 'delete');
  }, [action, formik?.values?.isEnabled, isLoading]);

  const actionButtonText = useMemo(() => {
    switch (action) {
      case 'create':
        return 'Создать';

      case 'delete':
        return 'Удалить';

      default:
        return 'Сохранить';
    }
  }, [action]);

  const isSaveButtonEnabled =
    action === 'delete' ||
    ((action === 'create' || action === 'update') && formik.values.startTime);

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1 sx={{ marginLeft: '28px' }}>Интеграция. Сервисы</H1>
            <Box display={'flex'} gap={2}>
              <Button
                variant="contained"
                color={action === 'delete' ? 'error' : 'primary'}
                onClick={formik.submitForm}
                disabled={!isSaveButtonEnabled}
              >
                {actionButtonText}
              </Button>
              <Button variant="contained" onClick={openSyncModal}>
                Синхронизация
              </Button>
            </Box>
          </TopSection>
          <Box marginTop={2} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} gap={2}>
              <Button
                variant="outlined"
                onClick={navigate.navigateToAgrosignal}
                startIcon={<AgrosignalIcon />}
              >
                Агросигнал
              </Button>
              <Button
                variant="outlined"
                onClick={navigate.navigateToCropwise}
                startIcon={<CropwiseIcon />}
              >
                Cropwise
              </Button>
            </Box>
          </Box>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          {isLoading ? <CircularProgress /> : <CoreIntegrationForm formikConfig={formik} />}
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationIntegrations);
