import { Box, Button, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FC } from 'react';

import { useStore } from '../../../../../../../../../shared/utils';
import { AssistanceFieldReportController } from '../../mobx';
import { OrganizationReportAssistanceFieldsStore } from '../../mobx/stores';

const OrganizationReportsAssistanceEmptyState: FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const controller = useStore(AssistanceFieldReportController);
  const { hasActiveCreation } = useStore(OrganizationReportAssistanceFieldsStore);

  const createNewConfig = () => {
    controller.createNewConfigPlaceholder();

    onCreate();
  };

  return (
    <Tooltip
      placement="top-start"
      title={hasActiveCreation ? 'Закончите создание конфигурации' : ''}
    >
      <Box marginTop={3}>
        <Button disabled={hasActiveCreation} sx={{ width: 300 }} onClick={createNewConfig}>
          <AddCircleIcon
            sx={{ marginRight: 1 }}
            color={hasActiveCreation ? 'disabled' : 'primary'}
          />
          <Typography variant="body2">Добавить конфигурацию</Typography>
        </Button>
      </Box>
    </Tooltip>
  );
};

export default observer(OrganizationReportsAssistanceEmptyState);
