import { lazyInject, provide } from '../../../../../../../shared/utils';
import { ICoreIntegrationForm, ICoreSynchronizationForm } from '../../models';
import OrgIntegraConfigService from '../services/orgIntegraConfig.service';

@provide.transient()
class OrgIntegraController {
  @lazyInject(OrgIntegraConfigService)
  protected service: OrgIntegraConfigService;

  createIntegrationConfig = (formData: Partial<ICoreIntegrationForm>) => {
    this.service.createOrgIntegraConfig(formData);
  };

  fetchIntegrationConfig = (orgId: string) => {
    return this.service.fetchOrgIntegraConfig(orgId);
  };

  updateIntegrationConfig = (formData: Partial<ICoreIntegrationForm>) => {
    return this.service.updateIntegraConfig(formData);
  };

  deleteIntegrationConfig = (organizationId: string) => {
    return this.service.deleteOrgIntegraConfig(organizationId);
  };

  integraPushEvents = (formData: ICoreSynchronizationForm) => {
    return this.service.pushIntegraConfig(formData);
  };
}

export default OrgIntegraController;
