import { Box, CircularProgress, IconButton } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { useMemo, useState } from 'react';

import { OrganizationIntegraTable } from '../../../../../../components';
import {
  DateParsedValue,
  TooltipedValue,
} from '../../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { OrgIntegraCropwiseService } from '../../../../mobx';
import { TFetchHandler } from '../../../../../../components/common/OrganizationIntegraTable/types/organizationIntegraTable.types';
import { BEFORE_DELETE_ORG_INTEGRA } from '../../../../../../modals';
import { getNotificatorProps } from '../../../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../../../shared/constanst/notifications';
import { CULTURE_CROPWISE_MODAL_ID } from '../../modals';
import { IOrgIntegraCropwiseCulture } from '../../models/orgIntegraCropwiseCulture';

const CultureTable = () => {
  const service = useStore(OrgIntegraCropwiseService);

  const { organizationId } = useParams<{ organizationId?: string }>();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), []);

  const [lastPageable, setLastPageable] = useState<{ page: number; size: number }>({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    size: searchParams.has('size') ? Number(searchParams.get('size')) : 25,
  });
  const [isSideLoadUpdate, setIsSideLoadUpdate] = useState(false);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const onDelete = (e, actionIdList: string[]) => {
    if (!actionIdList?.length) {
      setNotification(
        getNotificatorProps('Выберите хотя бы одну запись', ENotificationStyles.Error)
      );

      return;
    }

    openModalByModalId(BEFORE_DELETE_ORG_INTEGRA, null, () =>
      service
        .deleteCultureList({ ids: actionIdList })
        .then(() => {
          setNotification(
            getNotificatorProps('Записи успешно удалены', ENotificationStyles.Success)
          );

          setIsSideLoadUpdate(true);
          setTimeout(() => setIsSideLoadUpdate(false), 0);
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления записей', ENotificationStyles.Error)
          );
        })
    );

    return Promise.resolve();
  };

  const onOpenModal = (_, selectionList: string[]) => {
    openModalByModalId(
      CULTURE_CROPWISE_MODAL_ID,
      {
        mode: 'create',
        organizationId,
        selectionList: selectionList || null,
      },
      () => {
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );

    return Promise.resolve();
  };

  const onActionButtonClick = (value: IOrgIntegraCropwiseCulture) => {
    openModalByModalId(
      CULTURE_CROPWISE_MODAL_ID,
      {
        mode: 'update',
        organizationId,
        culture: value,
      },
      () => {
        // TODO придумать более оптимальный апдейт
        setIsSideLoadUpdate(true);
        setTimeout(() => setIsSideLoadUpdate(false), 0);
      }
    );
  };

  const fetchData: TFetchHandler<IOrgIntegraCropwiseCulture> = (page, size, ...args) => {
    const payload = {
      ...args[0],
      organizationId,
      page,
      size,
    };

    return service.fetchCulturesList(payload).then(data => {
      setLastPageable({ page, size });

      return { content: data.content, count: data.totalElements, page: data.pageable.pageNumber };
    });
  };

  return (
    <Box>
      {isSideLoadUpdate ? (
        <CircularProgress />
      ) : (
        <OrganizationIntegraTable<IOrgIntegraCropwiseCulture>
          actionButton={{ text: 'Добавить культуру', handler: onOpenModal, variant: 'success' }}
          deleteButton={{ text: 'Удалить выбранное', handler: onDelete }}
          fetchHandler={fetchData}
          controlledPage={lastPageable?.page}
          controlledSize={lastPageable?.size}
          filter={{
            filterCollection: [
              {
                type: 'text',
                label: 'ID культуры "Помощник"',
                propertyName: 'cultureId',
                placeholder: 'Введите ID культуры',
              },
              {
                type: 'text',
                label: 'Название культуры "Помощник"',
                propertyName: 'cultureName',
                placeholder: 'Введите название культуры',
              },
              {
                type: 'text',
                label: 'ID культуры "Cropwise"',
                propertyName: 'cropwiseCropId',
                placeholder: 'Введите ID культуры',
              },
              {
                type: 'text',
                label: 'Название культуры "Cropwise"',
                propertyName: 'cropwiseHeader',
                placeholder: 'Введите название культуры',
              },
            ],
          }}
          columnConfig={[
            {
              field: 'cultureId',
              headerName: 'ID “Помощник”',
              flex: 1,
              valueGetter: row => row.row.cultureId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cultureName',
              headerName: 'Наименование “Помощник”',
              flex: 1,
              valueGetter: row => row.row.cultureName,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwiseCropId',
              headerName: 'ID “Cropwise”',
              flex: 1,
              valueGetter: row => row.row.cropwiseCropId,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'cropwiseHeader',
              headerName: 'Наименование “Cropwise”',
              flex: 1,
              valueGetter: row => row.row.cropwiseHeader,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'id',
              headerName: 'ID Адаптер',
              flex: 1,
              valueGetter: row => row.row.id,
              renderCell: row => <TooltipedValue value={row.value} />,
            },
            {
              field: 'createDateTime',
              headerName: 'Дата и время создания',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.createDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'updateDateTime',
              headerName: 'Дата и время обновления',
              flex: 1,
              maxWidth: 150,
              valueGetter: row => row.row.updateDateTime,
              renderCell: row => <DateParsedValue value={row.value} />,
            },
            {
              field: 'action',
              headerName: '',
              minWidth: 50,
              filterable: false,
              sortable: false,
              renderCell: row => (
                <IconButton onClick={() => onActionButtonClick(row.row)}>
                  <ReplyIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Box>
  );
};

export default CultureTable;
