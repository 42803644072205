import { FC, useEffect, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../../shared/utils';
import { OrganizationsTypesStore } from '../../../../../stores/organizationsTypes/organizationsTypes.store';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../style';
import { getOrganizationMenu } from '../../../../../shared/menu';
import { Layout } from '../../../../components';

import { OrganizationInvitesFilter, OrganizationInvitesList } from './components';
import Styled from './OrganizationInvites.styles';
import {
  CREATE_INVITATION_MODAL_ID,
  createInvitationModalConfig,
  deleteInvitationModalConfig,
} from './modals';
import { editInvitationModalConfig } from './modals/EditInvitation/EditInvitation.modal';

const OrganizationInvites: FC = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const { isLoading } = useStore(OrganizationsTypesStore);

  const { registerModalList, openModalByModalId } = useModal();

  useEffect(() => {
    registerModalList(
      [createInvitationModalConfig, deleteInvitationModalConfig, editInvitationModalConfig],
      'organizationInvites'
    );
  }, []);

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  const handleCreateInvite = () => {
    openModalByModalId(CREATE_INVITATION_MODAL_ID, { organizationId });
  };

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Приглашения организации</H1>
            <Styled.Button
              color="primary"
              type="button"
              variant="contained"
              onClick={handleCreateInvite}
            >
              Добавить
            </Styled.Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <OrganizationInvitesFilter />
              <br />
              <OrganizationInvitesList />
            </>
          )}
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationInvites);
