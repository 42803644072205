import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Button,
  Grid,
  FormControlLabel,
  LinearProgress,
  TextField as MuiTextField,
  CircularProgress,
} from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { DateTimePicker } from 'formik-mui-lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import MUIRichTextEditor from 'mui-rte';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Autocomplete, TextField, Switch } from 'formik-mui';

import {
  useStore,
  isFormValuesErrors,
  convertStateFromHTML,
  convertStateToHTML,
  convertStateToJSON,
} from '../../../../../shared/utils';
import { NewsController } from '../../../../../controllers/news.controller';
import { TagsController } from '../../../../../controllers/tags.controller';
import { NewsStore } from '../../../../../stores/news/news.store';
import { TagsStore } from '../../../../../stores/tags/tags.store';
import { newsFormSchema } from '../../../../../shared/schema/newsFormSchema';
import { EFormFields, IFormValues } from '../../../../../../api/models/news.model';
import { EStatus } from '../../../../../../api/models/status.model';
import { TypeTag } from '../../../../../../api/models/tags.model';
import { AdminRoutes } from '../../../../routes';
import { Label, ErrorText } from '../../style';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Uploader } from '../../../../../shared/components/Uploader';

export const NewsEdit = observer(() => {
  const { fetchNewsItem, updateNews, uploadFile } = useStore(NewsController);
  const { fetchTags } = useStore(TagsController);
  const newsStore = useStore(NewsStore);
  const tagsStore = useStore(TagsStore);

  const history = useHistory();
  const { newsId } = useParams<{ newsId?: string }>();

  const isDraft =
    newsStore.newsItem.status?.statusId === EStatus.LITE_DRAFT ||
    newsStore.newsItem.status?.statusId === EStatus.DRAFT;
  const isPublication = newsStore.newsItem.status?.statusId !== EStatus.LITE_DRAFT;

  const handleFieldChange = (
    value: boolean,
    setFieldValue: (field: string, value: boolean) => void
  ) => {
    if (!value) {
      setFieldValue(EFormFields.isChange, true);
    }
  };

  const handleFileChosen = (
    field: EFormFields,
    setFieldValue: (field: string, value: string | boolean) => void,
    isChange: boolean
  ) => (file: File) => {
    uploadFile(file).then(({ id }: { id: string }) => {
      setFieldValue(field, id);
      handleFieldChange(isChange, setFieldValue);
    });
  };

  const handleSubmitForm = (values: IFormValues, action: FormikHelpers<IFormValues>) => {
    const payload = {
      newsId,
      newsStatus: values.status,
      newsTitle: values.newsTitle,
      pictureSmall: values.pictureSmall || null,
      pictureBig: values.pictureBig || null,
      newsDescription: values.newsDescription,
      newsContent: convertStateToHTML(values.newsContent),
      pinned: values.pinned,
      publicationDate: isDraft ? values.publicationDate.format() : undefined,
      tags: values.newsTags.map(option => option.tagId),
    };

    updateNews(payload).finally(() => {
      action.setSubmitting(false);
    });
  };

  useEffect(() => {
    fetchTags();
    fetchNewsItem({ newsId });
  }, []);

  if (newsStore.isLoading) {
    return <p>Загрузка</p>;
  }

  return (
    <MainContainer>
      {newsStore.isLoaded && (
        <>
          <HeaderWrapper>
            <TopSection>
              <H1>
                {newsStore.newsItem.status?.statusId !== 'LITE_DRAFT'
                  ? 'Редактирование новости'
                  : 'Создание новости'}
              </H1>
            </TopSection>
          </HeaderWrapper>

          <MainBody>
            <Grid container>
              <Formik
                validationSchema={newsFormSchema}
                initialValues={{
                  newsTitle: newsStore.newsItem.newsTitle || '',
                  pictureSmall: newsStore.newsItem.pictureSmall
                    ? newsStore.newsItem.pictureSmall.id
                    : '',
                  pictureBig: newsStore.newsItem.pictureBig ? newsStore.newsItem.pictureBig.id : '',
                  newsDescription: newsStore.newsItem.newsDescription || '',
                  newsContent: convertStateFromHTML(newsStore.newsItem.newsContent || ''),
                  pinned: newsStore.newsItem.pinned,
                  publicationDate: newsStore.newsItem.publicationDate
                    ? moment(newsStore.newsItem.publicationDate)
                    : moment(),
                  newsTags: !isEmpty(newsStore.newsItem.newsTags)
                    ? newsStore.newsItem.newsTags
                    : [],
                  status: null,
                  isChange: false,
                }}
                enableReinitialize
                onSubmit={handleSubmitForm}
              >
                {({ setFieldValue, submitForm, values, touched, errors, isSubmitting }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Form>
                        <Grid container spacing={2}>
                          {isSubmitting && (
                            <Grid item xs={12}>
                              <LinearProgress />
                            </Grid>
                          )}

                          <Grid item xs={6}>
                            <Label>Изображение обложки</Label>
                            <Uploader
                              fileId={values.pictureBig}
                              onFileChosen={handleFileChosen(
                                EFormFields.pictureBig,
                                setFieldValue,
                                values[EFormFields.isChange]
                              )}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Label>Изображение превью</Label>
                            <Uploader
                              fileId={values.pictureSmall}
                              onFileChosen={handleFileChosen(
                                EFormFields.pictureSmall,
                                setFieldValue,
                                values[EFormFields.isChange]
                              )}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              component={DateTimePicker}
                              ampm
                              name={EFormFields.publicationDate}
                              value={values[EFormFields.publicationDate]}
                              disabled={isPublication}
                              onChange={newValue => {
                                setFieldValue(EFormFields.publicationDate, newValue);
                                handleFieldChange(values[EFormFields.isChange], setFieldValue);
                              }}
                              inputFormat="DD.MM.yyyy HH:mm"
                              textField={{
                                fullWidth: true,
                                placeholder: 'Ввыберите время публикации',
                                label: 'Время публикации',
                                variant: 'outlined',
                                error:
                                  (touched[EFormFields.publicationDate] &&
                                    Boolean(errors[EFormFields.publicationDate])) ||
                                  isFormValuesErrors(
                                    EFormFields.publicationDate,
                                    newsStore.formValuesErrors
                                  ),
                                helperText:
                                  (touched[EFormFields.publicationDate] &&
                                    errors[EFormFields.publicationDate]) ||
                                  (isFormValuesErrors(
                                    EFormFields.publicationDate,
                                    newsStore.formValuesErrors
                                  ) &&
                                    newsStore.formValuesErrors[EFormFields.publicationDate]),
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              multiple
                              limitTags={2}
                              disableCloseOnSelect
                              name={EFormFields.newsTags}
                              value={values[EFormFields.newsTags]}
                              onChange={(_, value: TypeTag[]) => {
                                setFieldValue(EFormFields.newsTags, value);
                                handleFieldChange(values[EFormFields.isChange], setFieldValue);
                              }}
                              component={Autocomplete}
                              options={tagsStore.tags}
                              getOptionLabel={(option: TypeTag) => option.tagName}
                              isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
                              loading={tagsStore.isLoading}
                              loadingText="Загрузка..."
                              noOptionsText="Тегов не найдено"
                              renderInput={params => (
                                <MuiTextField
                                  {...params}
                                  fullWidth
                                  name={EFormFields.newsTags}
                                  placeholder="Ввыберите теги"
                                  label="Теги"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {tagsStore.isLoading ? (
                                          <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={5}>
                            <Field
                              component={TextField}
                              fullWidth
                              name={EFormFields.newsTitle}
                              value={values[EFormFields.newsTitle]}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(EFormFields.newsTitle, event.target.value);
                                handleFieldChange(values[EFormFields.isChange], setFieldValue);
                              }}
                              placeholder="Введите заголовок новости"
                              label="Заголовок *"
                              error={
                                (touched[EFormFields.newsTitle] &&
                                  Boolean(errors[EFormFields.newsTitle])) ||
                                isFormValuesErrors(
                                  EFormFields.newsTitle,
                                  newsStore.formValuesErrors
                                )
                              }
                              helperText={
                                (touched[EFormFields.newsTitle] && errors[EFormFields.newsTitle]) ||
                                (isFormValuesErrors(
                                  EFormFields.newsTitle,
                                  newsStore.formValuesErrors
                                ) &&
                                  newsStore.formValuesErrors[EFormFields.newsTitle])
                              }
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={7}>
                            <Field
                              component={TextField}
                              fullWidth
                              name={EFormFields.newsDescription}
                              value={values[EFormFields.newsDescription]}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(EFormFields.newsDescription, event.target.value);
                                handleFieldChange(values[EFormFields.isChange], setFieldValue);
                              }}
                              placeholder="Введите краткое описание новости"
                              label="Краткое описание *"
                              error={
                                (touched[EFormFields.newsDescription] &&
                                  Boolean(errors[EFormFields.newsDescription])) ||
                                isFormValuesErrors(
                                  EFormFields.newsDescription,
                                  newsStore.formValuesErrors
                                )
                              }
                              helperText={
                                (touched[EFormFields.newsDescription] &&
                                  errors[EFormFields.newsDescription]) ||
                                (isFormValuesErrors(
                                  EFormFields.newsDescription,
                                  newsStore.formValuesErrors
                                ) &&
                                  newsStore.formValuesErrors[EFormFields.newsDescription])
                              }
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MUIRichTextEditor
                              error={
                                (touched[EFormFields.newsContent] &&
                                  Boolean(errors[EFormFields.newsContent])) ||
                                isFormValuesErrors(
                                  EFormFields.newsContent,
                                  newsStore.formValuesErrors
                                )
                              }
                              label="Введите текст новости"
                              value={values[EFormFields.newsContent]}
                              /* onSave={(value: string) => {
                                setFieldValue(EFormFields.newsContent, value);
                              }} */
                              onChange={state => {
                                const value = convertStateToJSON(state);
                                if (value !== values[EFormFields.newsContent]) {
                                  setFieldValue(EFormFields.newsContent, value);
                                  handleFieldChange(values[EFormFields.isChange], setFieldValue);
                                }
                              }}
                              inlineToolbar={true}
                            />

                            {touched[EFormFields.newsContent] && (
                              <ErrorText>{errors[EFormFields.newsContent]}</ErrorText>
                            )}

                            {isFormValuesErrors(
                              EFormFields.newsContent,
                              newsStore.formValuesErrors
                            ) && (
                              <ErrorText>
                                {newsStore.formValuesErrors[EFormFields.newsContent]}
                              </ErrorText>
                            )}
                          </Grid>

                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Switch}
                                  type="checkbox"
                                  name={EFormFields.pinned}
                                  checked={values[EFormFields.pinned]}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(EFormFields.pinned, event.target.checked);
                                    handleFieldChange(values[EFormFields.isChange], setFieldValue);
                                  }}
                                />
                              }
                              label="Закрепленная"
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => history.push(AdminRoutes.News)}
                            >
                              Отменить
                            </Button>
                          </Grid>

                          {isDraft && (
                            <Grid item xs={3}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setFieldValue(EFormFields.status, EStatus.DRAFT);
                                  submitForm();
                                }}
                                disabled={!values[EFormFields.isChange]}
                              >
                                Сохранить черновик
                              </Button>
                            </Grid>
                          )}

                          {isPublication && (
                            <Grid item xs={3}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setFieldValue(EFormFields.status, EStatus.PUBLIC);
                                  submitForm();
                                }}
                              >
                                Опубликовать
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Form>
                    </LocalizationProvider>
                  );
                }}
              </Formik>
            </Grid>
          </MainBody>
        </>
      )}
    </MainContainer>
  );
});
