import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { useState } from 'react';

import { ModalTitle } from '../../../../../../../../../../../../../shared/components';
import { EOrgIntegraCropwiseFieldAutoMapMode } from '../../../../models';

const FieldGroupImportModalForm = () => {
  const [value, setValue] = useState<EOrgIntegraCropwiseFieldAutoMapMode>(
    EOrgIntegraCropwiseFieldAutoMapMode.Map
  );

  const { closeModal, getModalRuntimeHandlers } = useModal();

  const handlers = getModalRuntimeHandlers();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as EOrgIntegraCropwiseFieldAutoMapMode);
  };

  const onSubmit = () => {
    handlers.onSuccess(value);
  };

  return (
    <Box>
      <ModalTitle>Групповой маппинг Cropwise</ModalTitle>
      <FormControl sx={{ paddingLeft: '12px', marginBottom: 2 }}>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value={EOrgIntegraCropwiseFieldAutoMapMode.Map}
            control={<Radio />}
            label="Маппинг новых полей"
          />
          <FormControlLabel
            value={EOrgIntegraCropwiseFieldAutoMapMode.Remap}
            control={<Radio />}
            label="Обновление всех маппингов"
          />
          <FormControlLabel
            value={EOrgIntegraCropwiseFieldAutoMapMode.Unmap}
            control={<Radio />}
            label="Сброс всего маппинга"
          />
        </RadioGroup>
      </FormControl>
      <ModalFooter
        successButton={{ title: 'Выполнить', color: 'primary', handler: onSubmit }}
        denyButton={{ title: 'Отмена', color: 'default', handler: closeModal }}
      />
    </Box>
  );
};

export default FieldGroupImportModalForm;
